import styled from "styled-components";
import { colors } from "@mui/material";

interface Props {
  deliveryDate: string;
  statusStr: string;
}

export const SelectedMenuContainer = ({ deliveryDate, statusStr }: Props) => {
  return (
    <Wrapper>
      <Context>
        {deliveryDate} 배송예정{" "}
        {statusStr && <StatusStr>({statusStr})</StatusStr>}
      </Context>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  margin-right: 4px;
  border-right: solid 1px #c4c4c4;
  width: fit-content;
  justify-content: center;
  white-space: nowrap;
`;

const Context = styled.div`
  color: #000;
  font-size: 12px;
  font-weight: 700;
  height: 17px;
`;

const StatusStr = styled.div`
  color: blue;
`;
