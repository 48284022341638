import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import classNames from "classnames/bind";
import iconStyles from '../../../styles/common/Icon.module.scss';
import styles from '@styles/common/PrescriptionFileAttach.module.scss';

interface Props {
    onFileUpload: (e: any) => void,
}

export const BeforeAttachFileTable = ({onFileUpload}: Props) => {
    const ix = classNames.bind(iconStyles);
    const cx = classNames.bind(styles);
    const BeforeFileRow = () => {
        return (
            <BeforeDiv>
                <AttachDiv>
                    <label htmlFor="uploadFiles" className={cx('upload-label')}>
                        <FontAwesomeIcon icon={faCloudArrowUp} className={ix('custom-cloud-icon')}/>
                        <div>파일 첨부하기</div>
                    </label>
                    <input type="file"
                           id="uploadFiles"
                           accept=".pdf,.jpeg,.jpg"
                           multiple={true}
                           onChange={onFileUpload}/>
                </AttachDiv>
                <ImgNone>파일을 첨부하려면 첨부하기 버튼을 눌러주세요</ImgNone>
            </BeforeDiv>
        );
    }

    return (
        <table>
            <thead>
            <tr>
                <th>
                    <div>파일첨부</div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td><BeforeFileRow/></td>
            </tr>
            </tbody>
        </table>
    )
}

const BeforeDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
`;

const AttachDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const ImgNone = styled.p`
  color: #C4C4C4;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 13px;
  margin-top: 6px;
`;