import styled from "styled-components";
import React, {useState} from "react";
import {useRecoilValue} from "recoil";
import _ from "lodash";
import {PharmacySearchField} from "../../../pharmacySearchField";
import {IOoHospital} from "api";
import {editSelectorFamily} from "@recoils/status";
import {CustomInputText} from "@components/custom";

interface Props {
    targetHospital: IOoHospital,
    componentName: string,
}

export const HospitalTargetPharmacy = ({targetHospital, componentName}: Props) => {
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const [item, setItem] = useState()
    const onPharmacyChange = (targetId: string) => {

    };

    // // 전송 모드 일때, 쿼리 trigger
    // useEffect(() => {
    //     if (_.isEqual(editStatus.isSubmit,false)) {
    //         return;
    //     }
    //     if (window.confirm('수정하시겠습니까?')) {
    //         const param = new OoParamHospitalUpdate();
    //         param.id = targetHospital.id || '';
    //         param.zipCode = items.zipCode;
    //         param.address = items.address;
    //         param.regionKeywordList = items.regionKeywordList;
    //         param.pictures = items.pictures;
    //         updateHospital(param);
    //     }
    // }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <div className="select-container">
                {
                    editStatus.isEdit ?
                        <PharmacySearchField onChange={onPharmacyChange}
                                             targetPharmacy={_.isUndefined(_.head(targetHospital.pharmacies)) ? "없음" : _.head(targetHospital.pharmacies)!.name}/>
                        :
                        <div style={{display: "flex", width: "100%"}}>
                            <CustomInputText size="large"
                                             value={_.isUndefined(_.head(targetHospital.pharmacies)) ? "없음" : _.head(targetHospital.pharmacies)!.name}
                                             disabled={editStatus.isEdit}/>
                        </div>
                }
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  .select-container {
    display: flex;
    justify-content: flex-start;
    position: relative;
  }
`;
