export const formatTel = (tel: string) => {
    const trimData = tel.trim().replace(/ /g,"");
    try {
        switch (trimData.length) {
            case 11:
                const regex = /\d{3}\d{4}\d{4}/;
                if (regex.test(tel)) {
                    return `${trimData.substring(0, 3)}-${trimData.substring(3, 7)}-${trimData.substring(7, 11)}`
                }
                break;
            case 10:
                const regex1 = /\d{3}\d{3}\d{4}/;
                if (regex1.test(tel)) {
                    return `${trimData.substring(0, 3)}-${trimData.substring(3, 6)}-${trimData.substring(6, 11)}`
                }
                break;
            case 9:
                const regex2 = /\d{2}\d{3}\d{4}/;
                if (regex2.test(tel)) {
                    return `${trimData.substring(0, 2)}-${trimData.substring(2, 5)}-${trimData.substring(5, 9)}`
                }
                break;
            case 8:
                const regex3 = /\d{4}\d{4}/;
                if (regex3.test(tel)) {
                    return `${trimData.substring(0, 4)}-${trimData.substring(4, 9)}`
                }
                break;
            default:
                return tel;
        }
    } catch (e: any) {
        console.log(e);
    }

}