import {useModalHook} from "@hooks/modal";
import {Card, Wrapper} from "@components/customModal/style";
import React from "react";
import {CloseButton} from "react-bootstrap";
import styled from "styled-components";

export const NoButtonModal = ({
                                  data: {
                                      title = '',
                                      contents = '',
                                      buttons = [{title: "닫기", callback: (e: any) => false}]
                                  }
                              }) => {
    const {removeCurrentModal} = useModalHook();
    return (
        <Wrapper>
            <Card>
                <TitleContainer>
                    {
                        title.length > 0 &&
                        <div>
                            {title}
                        </div>
                    }
                    <div>
                        <CloseButton onClick={removeCurrentModal}/>
                    </div>
                </TitleContainer>
                <ContentContainer>
                    {contents}
                </ContentContainer>
            </Card>
        </Wrapper>
    )
}

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 20px;
`;

const ContentContainer = styled.div`
  & > div {
    & > div:nth-child(1) {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-weight: 700;
      font-size: 12px;
    }
  }
`;
