import {Dispatch, SetStateAction, useEffect, useState} from "react";
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {hospitalListState} from "../../recoils";
import _ from "lodash";
import {IOoHospital} from "../../api";
import {useBasicInfoSettingHook} from "@components/registerForm";
import {OoParamDoctorCreate} from "@params/doctor";
import { theme } from '@styles/theme';

interface Props {
    targetHospital?: string,
    onChange: (targetId: string) => void,
    setParam?: Dispatch<SetStateAction<OoParamDoctorCreate>>,
    param?: OoParamDoctorCreate
}

export const HospitalSearchField = ({onChange, targetHospital, setParam, param}: Props) => {
    const list = useRecoilValue(hospitalListState);
    const [filteredList, setFilteredList] = useState<Array<IOoHospital>>([]);
    const [searchResultList, setSearchResultList] = useState<boolean>(true);
    const [searchHospitalName, setSearchHospitalName] = useState<string>('');

    const getList = (list: Array<IOoHospital>, value: string) => {
        return list.filter((item) => _.includes(item.code, value));
    };

    const onChangeInput = (e: any) => {
        const text = e.target.value;
        setSearchHospitalName(text);
        const hospitalList = getList(list, e.target.value);
        if (_.isEqual(text.length, 0) && _.gt(hospitalList.length, 0)) {
            setFilteredList(list)
            setSearchResultList(true);
        } else {
            setFilteredList(hospitalList);
            setSearchResultList(false);
        }
    };

    const onClickInput = (e: any) => {
        setFilteredList(list);
        setSearchResultList(!searchResultList);
    };

    const onTargetNameClick = (code:string) => {
        
        const targetData = (list.filter(item => item.code === code))[0];
        
            const targetObject = new OoParamDoctorCreate();
            targetObject.name=targetData.name;
            targetObject.mobile=targetData.tel.split('-').join(''),
            targetObject.tel=targetData.tel.split('-').join(''),
            targetObject.email=`${targetData.code.toLocaleLowerCase()}@doctoron.kr`,
            targetObject.password='opusone'+targetData.code.toLocaleLowerCase().toLocaleLowerCase()+'!@',
            targetObject.gender = "male",
            targetObject.education=`${targetData.name}공용 계정`,
            targetObject.career=`${targetData.name}공용 계정`,
            targetObject.bio=`안녕하세요 ${targetData.name} 입니다.`,
            targetObject.departments=targetData.doctors && targetData.doctors![0] ? targetData.doctors?.map(item => item.departments).join().split(','):['내과'],
            targetObject.theme= ['감기'],
            targetObject.bank=targetData.bank ? targetData.bank : '하나은행',
            targetObject.bankAccount=targetData.bankAccount ? targetData.bankAccount : '1234567890',
            targetObject.bankHolderName=targetData.bankHolderName ? targetData.bankHolderName : '홍길동';
            targetObject.isCalculateAccount = true;

            if(setParam){
                setParam(targetObject);
            }
        setSearchHospitalName(code);
        setSearchResultList(!searchResultList);
        onChange(targetData.id!);
    };

    useEffect(() => {
        if (!_.isUndefined(targetHospital)) {
            setSearchHospitalName(targetHospital);
            const targetHospitalList = getList(list, targetHospital);
            targetHospitalList.length > 0 && setFilteredList(targetHospitalList);
        }
    }, [targetHospital]);

    return (
        <Wrapper>
            <input className="search-input"
                   type="text"
                   placeholder="병원명을 검색하세요."
                   value={searchHospitalName}
                   onChange={onChangeInput}
                   onClick={onClickInput}/>
            <div hidden={searchResultList} className="search-result-div">
                {
                    filteredList.map((item, index) => (
                        <div key={index} className="search-result-row">
                            <span className="search-result-name" id={item.id}
                                  onClick={() => onTargetNameClick(item.code)}>{item.code}</span>
                        </div>
                    ))}
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  .search-input {
    width: 472px;
    height: 36px;
    border: 1px solid #2176F6;
    border-radius: 8px;
    font-size: 12px;
    padding: 1%;
    margin-bottom: 8px;
  }

  .search-result-div {
    width: 472px;
    height: auto;
    max-height: 120px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    font-size: 13px;
    overflow: auto;
    position: absolute;
    z-index: 10;
  }

  .search-result-row {
    padding: 5px;
  }

  .search-result-name {
    cursor: pointer;
    padding: 5px;

    &:hover {
      color: #0a53be;
    }
  }

`;
