import React, {useEffect, useState} from "react";
import _ from "lodash";
import classNames from "classnames/bind";
import timeTableStyle from "../../../../../styles/common/TimeTable.module.scss";
import {LunchDropDown} from "../../../../lunchDropDown";
import {splitCloseTime, splitOpenTime} from "@api/models/pharmacy";

interface InitialProps {
    lunchHourArr: string[],
    onChange: (item: string, checked: boolean) => void,
    disabled?: boolean,
    isEdit: boolean,
}


export const PharmacyWorkLunchHours = ({lunchHourArr, onChange, disabled, isEdit}: InitialProps) => {
    const tx = classNames.bind(timeTableStyle);
    const [checked, setChecked] = useState<boolean>(true);

    const [lunchTime, setLunchTime] = useState({
        startLunchHour: _.head(splitOpenTime(`${lunchHourArr}`)) as string,
        startLunchMinute: _.last(splitOpenTime(`${lunchHourArr}`)) as string,
        endLunchHour: _.head(splitCloseTime(`${lunchHourArr}`)) as string,
        endLunchMinute: _.last(splitCloseTime(`${lunchHourArr}`)) as string,
        isChecked: true,
    });

    const onOpenTimeChanged = (time: string) => {
        if (_.lte(_.head(_.split(time, ":")), lunchTime.endLunchHour)) {
            setLunchTime({
                ...lunchTime,
                startLunchHour: _.head(_.split(time, ":")) || "",
                startLunchMinute: _.last(_.split(time, ":")) || "",
                isChecked: checked
            });
        }
    };

    const onCloseTimeChanged = (time: string) => {
        if (_.gte(_.head(_.split(time, ":")), lunchTime.startLunchHour)) {
            setLunchTime({
                ...lunchTime,
                endLunchHour: _.head(_.split(time, ":")) as string,
                endLunchMinute: _.last(_.split(time, ":")) as string,
                isChecked: checked
            });
        }
    };

    const onCheckChanged = (e: any) => {
        e.target.checked ? setChecked(!checked) : setChecked(false);
        setLunchTime({...lunchTime, isChecked: e.target.checked});
    };

    useEffect(() => {
        onChange(`${lunchTime.startLunchHour}:${lunchTime.startLunchMinute},${lunchTime.endLunchHour}:${lunchTime.endLunchMinute}`, checked);
    }, [lunchTime.startLunchHour, lunchTime.startLunchMinute, lunchTime.endLunchHour, lunchTime.endLunchMinute, checked]);

    useEffect(() => {
        setLunchTime({
            ...lunchTime,
            startLunchHour: _.head(splitOpenTime(`${lunchHourArr}`)) as string,
            startLunchMinute: _.last(splitOpenTime(`${lunchHourArr}`)) as string,
            endLunchHour: _.head(splitCloseTime(`${lunchHourArr}`)) as string,
            endLunchMinute: _.last(splitCloseTime(`${lunchHourArr}`)) as string,
        });
    }, [lunchHourArr]);

    return (
        <>
            {
                isEdit ?
                    <div style={{display: "flex"}}>
                        <div className={tx('time-table')}>
                            점심시간
                        </div>
                        <div className={tx('time-table', 'openHours')}>
                            <LunchDropDown h={lunchTime.startLunchHour} m={lunchTime.startLunchMinute}
                                           onChanged={onOpenTimeChanged} disabled={lunchTime.isChecked}/>
                        </div>
                        <div className={tx('time-table', 'closeHours')}>
                            <LunchDropDown h={lunchTime.endLunchHour} m={lunchTime.endLunchMinute}
                                           onChanged={onCloseTimeChanged} disabled={lunchTime.isChecked}/>
                        </div>
                        <div className={tx('time-table')}>
                            <input type="checkbox"
                                   defaultChecked={lunchTime.isChecked}
                                   id={`${lunchTime}`}
                                   onChange={onCheckChanged}
                                   disabled={!isEdit}/>
                        </div>
                    </div>
                    :
                    <div style={{display: "flex"}}>
                        <div className={tx('time-table')}>
                            점심시간
                        </div>
                        {
                            lunchTime.isChecked ?
                                <>
                                    <div className={tx('time-table', 'openHours')}>
                                        -
                                    </div>
                                    <div className={tx('time-table', 'closeHours')}>
                                        -
                                    </div>
                                </>
                                :
                                <>
                                    <div className={tx('time-table', 'openHours')}>
                                        {lunchTime.startLunchHour} : {lunchTime.startLunchMinute}
                                    </div>
                                    <div className={tx('time-table', 'closeHours')}>
                                        {lunchTime.endLunchHour} : {lunchTime.endLunchMinute}
                                    </div>
                                </>
                        }
                        <div className={tx('time-table')}>
                            <input type="checkbox"
                                   defaultChecked={lunchTime.isChecked}
                                   id={`${lunchTime}`}
                                   onChange={onCheckChanged}
                                   disabled={!isEdit}/>
                        </div>
                    </div>
            }
        </>
    )

};

