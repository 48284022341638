import { useQuery } from "react-query";
import { useSuspenseHook } from "../../hooks";
import { DoctorOnAPI, isRequestSucceed } from "../../api";
import { OoUnospayReadDeliveryInfoRange } from "@api/params";
import _ from "lodash";
import { toast } from "react-toastify";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import {
  unospayCalendarRangeListState,
  unospayDailySelectedDateState,
} from "../../recoils";
import { unopayCalendarParamState } from "@recoils/unospay/unopayCalendarParamState";

interface Props {
  beginTimestamp: string;
  endTimestamp: string;
  count?: string;
  id?: string;
  updateQueryTrigger: boolean;
}

export const useReadRangeUnospayQuery = ({
  beginTimestamp,
  endTimestamp,
  count,
  id,
  updateQueryTrigger,
}: Props) => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
  const resetDiagnoisSelectedDate = useResetRecoilState(
    unospayDailySelectedDateState
  );
  const setList = useSetRecoilState(unospayCalendarRangeListState);
  const setParam = useSetRecoilState(unopayCalendarParamState);
  const resetParam = useResetRecoilState(unopayCalendarParamState);
  return useQuery(
    "readRangeUnospay",
    async () => {
      try {
        setLoading();
        const params = new OoUnospayReadDeliveryInfoRange(
          beginTimestamp,
          endTimestamp,
          id,
          count
        );
        setParam({
          id: id || "",
          count: count,
          beginTimestamp: beginTimestamp,
          endTimestamp: endTimestamp,
        });
        const response = await DoctorOnAPI.shared.unospay.readRecordRange(
          params
        );
        setStopLoading();
        if (isRequestSucceed(response)) {
          if (
            _.isUndefined(response.data) ||
            _.isEqual(response.data.length, 0)
          ) {
            toast.error("검색 결과가 없습니다.");
            resetDiagnoisSelectedDate();
            resetParam();
          }
          setList(response.data || []);
          return response.data;
        }
        return undefined;
      } catch (e: any) {
        setErrorStatus(e);
      }
    },
    {
      enabled: updateQueryTrigger,
      retry: true,
      refetchOnWindowFocus: false,
      refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
    }
  );
};
