import styled from "styled-components";

interface Props {
  title: string;
  iconSrc: string;
  onClick: () => void;
  disabled?: boolean;
}
export const DownloadButton = ({ title, iconSrc, onClick, disabled }: Props) => {
  return (
    <DownloadThemeButton onClick={onClick} disabled={disabled}>
      <ButtonIcon src={iconSrc} alt={title} />
      {title}
    </DownloadThemeButton>
  );
};

const DownloadThemeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  font-weight: 700;
  font-size: 13px;
  color: black;
  border-radius: 8px;
  border: 0.5px solid #c2c1bd;
  background: white;
  padding: 4px 12px 4px 13px;
`;

const ButtonIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;
