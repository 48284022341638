import {useSuspenseHook} from "@hooks/suspense";
import {useMutation} from "react-query";
import {OoParamReservationDiagnosisStatus} from "@api/params";
import {DoctorOnAPI} from "@api/request";
import {isRequestSucceed} from "@api/reponses";

export const useChangeReservationDiagnosisStatus = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation('changeReservationDiagnosisStatus',
        async (param: OoParamReservationDiagnosisStatus) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.reservation.testTodayConfirm(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response;
                }
                return null;
            } catch (e: any) {
                throw e;
            }
        })

}