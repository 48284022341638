import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import _ from "lodash";

declare module 'axios' {
    interface AxiosResponse<T = any> extends Promise<T> {
    }
}

class HttpClient {
    readonly instance: AxiosInstance;

    private readonly authorization: IOoAuthorizationHeader;

    constructor(baseURL: string, authorization: IOoAuthorizationHeader) {
        this.authorization = authorization;
        this.instance = axios.create({
            baseURL,
        });

        this.initializeResponseInterceptor();
    }

    private initializeResponseInterceptor = () => {
        this.instance.interceptors.request.use(
            this.handleRequest,
            this.handleError,
        );

        this.instance.interceptors.response.use(
            this.handleResponse,
            this.handleError,
        );
    };

    private handleRequest = (config: AxiosRequestConfig) => {
        if (_.isUndefined(config.headers)) {
            config.headers = {};
        }
        config.headers['authorization'] = this.authorization.authorization();
        config.headers['Content-Type'] = 'application/json';
        return config;
    };

    private handleResponse = ({data}: AxiosResponse) => data;

    protected handleError = (error: any) => Promise.reject(error);
}

export {
    HttpClient,
};

export interface IOoAuthorizationHeader {
    authorization: () => string;
}

