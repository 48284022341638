import {useMutation} from "react-query";
import {DoctorOnAPI, isRequestSucceed, OoParamId} from "../../api";
import {useSuspenseHook} from "../../hooks";

export const useDoctorDeleteQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'operator/doctorDelete',
        async (id: string) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.doctor.delete(new OoParamId(id));
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.status;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        },
    )
};