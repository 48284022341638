import { atom, atomFamily, selectorFamily } from "recoil";
import _ from "lodash";

export interface EditInfo {
  id: string;
  isCancel: boolean;
  isEdit: boolean;
  isSubmit: boolean;
}

// export interface EditId extends EditInfo {
//     //type 에러로 인해 인덱스 시그니처 속성 추가, ESLint any 오류 피하기 위해 ignore 주석 추가 - bsh
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     [key: string]: any,
//     id: string,
//
// }

const editAtom = atom<Array<string>>({
  key: "editAtom",
  default: [],
});

// atom 과 atomFamily의 차이점은 default 값에 특정한 파라미터를 받을 수 있는 함수가 될 수 있음
// 동일한 형태의 atom을 생성해주는 팩토리 함수 제공 ( => 팩토리 함수를 리턴하는 함수를 리턴?)

// atomFamily는 어떤 atom들을 생성했는지 알 수가 없음 => atomFamily를 통해 생성된 atom의 키를 별도로 관리해줘야함
const editAtomFamily = atomFamily<EditInfo, string>({
  key: "editAtomFamily",
  default: (id) => {
    const info: EditInfo = {
      id,
      isCancel: true,
      isEdit: false,
      isSubmit: false,
    };
    return info;
  },
});

// selector는 set값을 이용해 쓰기 가능한 상태, selectorFamily는 set, get 모두 가능한 상태이며
// selectorFamily는 한 파라미터를 받아 이 파라미터를 이용해 작업을 수행하는 selector를 리턴하는 팩토리 함수를 리턴?

const editSelectorFamily = selectorFamily<EditInfo, string>({
  key: "editSelectorFamily",
  get:
    (id: string) =>
    ({ get }) =>
      get(editAtomFamily(id)),
  set:
    (id: string) =>
    ({ get, set, reset }, editInfo) => {
      if (_.isEmpty(editInfo)) {
        // editAtomFamily 기본값으로 초기화
        reset(editAtomFamily(id));
        // 키 값을 해당 editAtom에서 제거
        set(editAtom, (prevValue) => prevValue.filter((item) => item !== id));
        return;
      }

      // 훅을 통해서 값을 할당하려는 경우
      set(editAtomFamily(id), editInfo);
      set(editAtom, (prev) => Array.from(new Set([...prev, id])));
    },
});

export { editSelectorFamily };
