import {ModalOverlay} from "@components/modal";
import {CloseButton} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {filteredReservationType, IOoDiagnosis} from "@api/models";
import {formatDateYYYYMDHHmm, formatDateYyyyMMDHHmmss} from "@hooks/format";
import {ReservationCancelModal} from "../ReservationCancelModal";
import styled from "styled-components";
import {useHospitalCancelQuery} from "@queries/reservation";
import {ChangeDiagnosisReservationModal} from "../ChangeDiagnosisReservationModal";
import {useModalHook} from "@hooks/modal";
import {useDoctorFunctionHook} from "@hooks/doctor";

interface Props {
    visible: boolean,
    unVisible: (e: boolean) => void,
    id: string,
    list: Array<IOoDiagnosis>,
}

export const ReservationUpdateModal = ({visible, unVisible, id, list}: Props) => {
    const {addModal} = useModalHook();
    const {updateReservationConfirm} = useDoctorFunctionHook();
    const target = list.find((item) => _.isEqual(item.id, id));

    const [show, setShow] = useState<boolean>(visible);
    const [cancelModalShow, setCancelModalShow] = useState<boolean>(false);
    const [changeReservationShow, setChangeReservationShow] = useState<boolean>(false);
    const [cancelTrigger, setCancelTrigger] = useState<boolean>(false);
    const [reason, setReason] = useState<string>('');
    const targetId = _.isUndefined(target) ? '' : (target.id ?? '');

    const response = useHospitalCancelQuery({id: targetId, comment: reason, updateQueryTrigger: cancelTrigger});

    const onChangeCancelReason = (e: any) => setReason(e.target.value);
    const onClickCloseButton = () => setShow(false);
    const onClickChangeReservationButton = (e: any) => {
        e.preventDefault();
        setChangeReservationShow(true);
    };
    const onClickReservationCancel = (e: any) => {
        e.preventDefault();
        reason.length > 0 ?
            setCancelTrigger(true) :
            addModal({
                type: 'errorModal',
                data: {
                    title: "예약 취소",
                    contents:
                        <div>취소 사유를 입력해주세요.</div>,
                    buttons: [
                        {title: "닫기"}],
                }
            })

    };

    const onClickReservationConfirm = () => {
        if (_.isUndefined(target)) return;
        updateReservationConfirm(id);
        // addModal({
        //     type: 'onBtnModal',
        //     data: {
        //         title: "예약 확정",
        //         contents:
        //             <div>
        //                 <div>
        //                     접수 방식 : {filteredReservationType(target)}
        //                 </div>
        //                 <div>
        //                     접수 번호 : {target.timestamp}
        //                 </div>
        //                 <div>
        //                     예약 일시
        //                     : {formatDateYYYYMDHHmm(target.wapleReservationTimestamp ?? 0)}
        //                 </div>
        //                 <div>
        //                     이름 : {target.patientName}
        //                 </div>
        //                 <BottomContainer>
        //                     <div> 상기 예약이 확정되었습니다.</div>
        //                 </BottomContainer>
        //             </div>,
        //         buttons: [
        //             {title: "닫기"}],
        //     }
        // })
    };

    useEffect(() => {
        unVisible(show);
    }, [show]);

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    useEffect(() => {
        _.isEqual(response.status, "success") && setShow(false);
        setCancelTrigger(false);
    }, [response.data]);

    return (
        <>
            {
                !_.isUndefined(target) &&
                <ModalOverlay visible={show}>
                    <ReservationCancelModal visible={cancelModalShow}
                                            unVisible={(e) => setCancelModalShow(e)}
                                            target={target}/>
                    <ChangeDiagnosisReservationModal visible={changeReservationShow}
                                                     unVisible={e => setChangeReservationShow(e)}
                                                     target={target}/>
                    <Wrapper>
                        <TopContainer>
                            <div> 예약 확정</div>
                            <div>
                                <CloseButton onClick={onClickCloseButton}/>
                            </div>
                        </TopContainer>
                        <MiddleContainer>
                            <div>
                                진료방식 : {filteredReservationType(target)}
                            </div>
                            <div>
                                접수 번호 : {formatDateYyyyMMDHHmmss(target.timestamp)}
                            </div>
                            <div>
                                예약 일시 : {formatDateYYYYMDHHmm(target.wapleReservationTimestamp ?? 0)}
                            </div>
                            <div>
                                이름 : {target.patientName}
                            </div>
                        </MiddleContainer>
                        <CancelContainer>
                            <div> 예약 취소시, 사유를 입력해주세요.</div>
                            <div><input type="text"
                                        placeholder="예)병원사정으로 해당 일시 예약이 불가합니다."
                                        onChange={onChangeCancelReason}/></div>
                        </CancelContainer>
                        <ButtonContainer>
                            <CancelButton type="button" onClick={onClickReservationCancel}>예약 취소</CancelButton>
                            <UpdateButton type="button" onClick={onClickChangeReservationButton}>
                                일정 변경 후 확정
                            </UpdateButton>
                            <ConfirmButton type="button"
                                           onClick={onClickReservationConfirm}>예약 확정</ConfirmButton>
                        </ButtonContainer>
                    </Wrapper>
                </ModalOverlay>
            }
        </>
    )
}

const Wrapper = styled.div`
  width: 550px;
  height: 384px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 36px;
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > div:nth-child(1) {
    font-weight: 700;
    font-size: 20px;
  }

  & > div:nth-child(2) {
    & > button {
      width: 14px;
      height: 14px;
      color: #949390;
    }
  }
`;

const MiddleContainer = styled.div`
  font-weight: 500;
  font-size: 13px;
  padding-top: 20px;
  text-align: left;

  & > div:nth-child(3) {
    color: #2452B1;
  }
`;

const CancelContainer = styled.div`
  font-weight: 700;
  font-size: 12px;
  padding-top: 20px;

  & > div:nth-child(1) {
    text-align: left;
  }

  & > div:nth-child(2) {
    padding-top: 10px;
    display: flex;

    & > input[type="text"] {
      padding: 1%;
      border: 1px solid #CBCBCB;
      width: 100%;
      height: 47px;
      border-radius: 8px;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  width: 150px;
  height: 60px;
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 16px;
`;

const CancelButton = styled(Button)`
  background: #CF5D5D;
`;

const UpdateButton = styled(Button)`
  background: #4CA555;
`;

const ConfirmButton = styled(Button)`
  background: #6E8ED1;
`;

const BottomContainer = styled.div`
  font-weight: 700;
  font-size: 12px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
  text-align: left;
`;
