import _ from "lodash";
import {IOoHospital} from "../../hospital";
import moment from "moment";

export const filteredHospitalName = (hospitalId: string, list: IOoHospital[]) => {
    const result = list.filter(item => _.isEqual(item.id, hospitalId)) as IOoHospital[];
    return _.isEmpty(result) ? '병원 이름 없음' : result[0].name
};

export const filteredHospitalTel = (hospitalId: string, list: IOoHospital[]) => {
    const result = list.filter(item => _.isEqual(item.id, hospitalId)) as IOoHospital[];
    return _.isEmpty(result) ? '전화 번호 없음' : result[0].tel
};

export const filteredHospitalAddress = (hospitalId: string, list: IOoHospital[]) => {
    const result = list.filter(item => _.isEqual(item.id, hospitalId)) as IOoHospital[];
    return _.isEmpty(result) ? '주소 없음' : result[0].address
};


export const filteredHospitalImage = (hospitalId: string, list: IOoHospital[]) => {
    const result = list.filter(item => _.isEqual(item.id, hospitalId)) as IOoHospital[];
    return _.isEmpty(result) ? '사진 없음' : result[0].pictures;
};

export const filteredHospitalTargetPharmacy = (hospitalId: string, list: IOoHospital[]) => {
    const result = list.filter(item => _.isEqual(item.id, hospitalId)) as IOoHospital[];
    return _.isEmpty(result) || _.isUndefined(result) ? [] : result[0].pharmacies;
};

export const filteredHospitalBank = (hospitalId: string, list: IOoHospital[]) => {
    const result = list.filter(item => _.isEqual(item.id, hospitalId)) as IOoHospital[];
    return _.isEmpty(result) ? '없음' : result[0].bank;
};

export const filteredHospitalBankHolder = (hospitalId: string, list: IOoHospital[]) => {
    const result = list.filter(item => _.isEqual(item.id, hospitalId)) as IOoHospital[];
    return _.isEmpty(result) ? '없음' : result[0].bankHolderName;
};

export const filteredHospitalBankAccount = (hospitalId: string, list: IOoHospital[]) => {
    const result = list.filter(item => _.isEqual(item.id, hospitalId)) as IOoHospital[];
    return _.isEmpty(result) ? '없음' : result[0].bankAccount;
};

export const filteredTime = (timestamp: number) => {
    moment().locale();
    return moment(timestamp).format('YYYY-MM-DD');
};
