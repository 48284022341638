import styled from "styled-components";
import { DeliveryDetailInfo } from "./components/DeliveryDetailInfo";
import { ChangeOrderPlatform, RecipientInfo } from "./components/RecipientInfo";
import classNames from "classnames/bind";
import buttonStyles from "../../../styles/common/Button.module.scss";
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { OoDeliveryStatus } from "@models/unospay";
import { useUnospayFunctionHook } from "@hooks/unospay";
import { CustomCardNoBorder } from "@components/custom/customCardNoBorder";
import { UnospayModal } from "@components/modal/unospayModal";
import { DeliveryInfo } from "./components/DeliveryInfo";
import { useUnosOrderDetailPageSettingHook } from "./hooks/useUnosOrderDetailPageSettingHook";
import { useUnospayRtdbHook } from "@hooks/realtimeDatabase";
import { right } from "@popperjs/core";
import { OrderDetailInfo } from "./components/OrderDetailInfo";
import { OrderPlatformModal } from "./components/CustomModal/OrderPlatformModal";
import { OrderCancelModal } from "./components/CustomModal/OrderCancelModal";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import { IOoUnospayGfOrder } from "@models/unospay/gfOrder";
import { IOoHospital } from "@api/models";
import { OrderCsInfo } from "./components/OrderCsInfo";
import OrderChangeStatusModal from "./components/CustomModal/OrderChangeStateModal";

export interface CancelOrderComment {
  id: string;
  comment: string;
}
export interface ChangeDeliveryStatus {
  id: string;
  status: number;
}

export const UnospayOrderDetailPage = () => {
  const bx = classNames.bind(buttonStyles);
  const {
    data,
    calendarParam,
    searchParam,
    queryTrigger,
    setQueryTrigger,
    cancelOrderQueryTrigger,
    setCancelOrderQueryTrigger,
    resendQueryTrigger,
    setResendQueryTrigger,
    rangeResponse,
    findKeywordResponse,
    findTargetResponse,
    changeDeliveryStatusTrigger,
    handleChangeStatusOrder, 
    changeStatusModalVisible,
    setChangeStatusModalVisible,
    setChangeStatusOrderQueryTrigger,
    cancelModalVisible,
    setCancelModalVisible,
    kakaoResendModalVisible,
    setKakaoResendModalVisible,
    handleCancelOrder,
    handleResendAlimtalk,
    rtdbList,
    cancelOrderCommentValue,
    setCancelOrderCommentValue,
    gfOrderList,
  } = useUnosOrderDetailPageSettingHook();

  const { cancelOrder, resendKakaoAlimtalk, displayList, updateDisplay } =
    useUnospayFunctionHook(data.id || "");

  const { connect, disconnect } = useUnospayRtdbHook();

  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const [gfList, setGfList] = useState<Array<IOoUnospayGfOrder>>([]);

  useEffect(() => {
    connect("unosPay");
    return () => disconnect();
  }, []);

  useEffect(() => {
    if (rtdbList.length > 0) {
      const findTarget = rtdbList.find((item) =>
        _.isEqual(data.id || "", item.id || "")
      );
      _.isUndefined(findTarget)
        ? updateDisplay([data])
        : updateDisplay([findTarget]);
    } else {
      updateDisplay([data]);
    }
  }, [data, rtdbList]);

  useEffect(() => {
    if (
      !_.every(calendarParam, (param) => _.isEqual(param, "")) &&
      _.isEqual(calendarParam.id, "")
    ) {
      setQueryTrigger({ ...queryTrigger, calendarQueryTrigger: true });
      return;
    } else if (!_.every(searchParam, (param) => _.isEqual(param, ""))) {
      setQueryTrigger({ ...queryTrigger, searchQueryTrigger: true });
      return;
    } else {
      setQueryTrigger({ ...queryTrigger, findQueryTrigger: true });
      return;
    }
  }, []);

  useEffect(() => {
    if (!_.isUndefined(rangeResponse.data)) {
      const findTarget = rangeResponse.data.find((item) =>
        _.isEqual(data.id || "", item.id || "")
      );
      if (_.isEmpty(findTarget)) return;
      updateDisplay([findTarget]);
      setQueryTrigger({ ...queryTrigger, searchQueryTrigger: false });
      return;
    }
    if (!_.isEmpty(findKeywordResponse.data)) {
      const findTarget = (findKeywordResponse.data || []).find((item) =>
        _.isEqual(data.id || "", item.id || "")
      );
      if (_.isEmpty(findTarget)) return;
      updateDisplay([findTarget]);
      setQueryTrigger({ ...queryTrigger, calendarQueryTrigger: false });
      return;
    }

    updateDisplay([findTargetResponse.data!]);
    setQueryTrigger({ ...queryTrigger, findQueryTrigger: false });
  }, [rangeResponse.data, findKeywordResponse.data, findTargetResponse.data]);

  const changeStatusParam: ChangeDeliveryStatus = {
    id: data.id || "",
    status: data.status || 0,
  }

  const cancelParam: CancelOrderComment = {
    id: data.id || "",
    comment: cancelOrderCommentValue,
  };

  useEffect(() => {
    cancelOrderQueryTrigger && cancelOrder(cancelParam);
    resendQueryTrigger && resendKakaoAlimtalk(data.id || "");
    // cancelOrderQueryTrigger && cancelOrderComment(param);
    setCancelOrderQueryTrigger(false);
    setResendQueryTrigger(false);
  }, [cancelOrderQueryTrigger, resendQueryTrigger]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setShowCopyMessage(true);
        setTimeout(() => {
          setShowCopyMessage(false);
        }, 1000);
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  };

  // console.log('111', findGfOrderResponse.data)
  
  return (
    <Wrapper>
      {/*<UnospayModal*/}
      {/*  modalVisible={cancelModalVisible}*/}
      {/*  contents="주문을 취소하시겠습니까?"*/}
      {/*  onChangedVisible={(status) => setCancelModalVisible(status)}*/}
      {/*  onChangedCancel={(status) => setCancelOrderQueryTrigger(status)}*/}
      {/*  cancelButtonName="닫기"*/}
      {/*  submitButtonName="취소하기"*/}
      {/*/>*/}
      <OrderChangeStatusModal 
      title='주문상태변경' 
      cancelButtonName="취소"
      submitButtonName="변경하기"
      contents="상태를 변경하시겠습니까?"
      modalVisible={changeStatusModalVisible} 
      onChangedVisible = {setChangeStatusModalVisible}
      />
      <OrderCancelModal
        modalVisible={cancelModalVisible}
        selectValue={"배송불가지역"}
        commentValue={""}
        cancelButtonName="취소"
        submitButtonName="취소하기"
        contents="주문을 취소하시겠습니까? (취소사유를 입력해 주세요.)"
        onChangedVisible={(status) => setCancelModalVisible(status)}
        onChangedTrigger={function (status, value) {
          setCancelOrderQueryTrigger(status);
          setCancelOrderCommentValue(value);
        }}
        title="구매경로 수정"
      ></OrderCancelModal>
      <UnospayModal
        modalVisible={kakaoResendModalVisible}
        contents="알림톡을 재전송하시겠습니까?"
        onChangedVisible={(status) => setKakaoResendModalVisible(status)}
        onChangedCancel={(status) => setResendQueryTrigger(status)}
        cancelButtonName="취소"
        submitButtonName="전송"
      />
      {!_.isUndefined(_.head(displayList.list)) && (
        <Container key={displayList.id}>
          <ButtonContainer>
            <input type="button" 
            className={bx("blue-button")} 
            value="상태변경" 
            disabled={_.isEqual(_.head(displayList.list)!.status, 2)} 
            onClick={handleChangeStatusOrder}
            />
            <input
              type="button"
              className={bx("cancel-button")}
              value="주문취소"
              disabled={_.isEqual(_.head(displayList.list)!.status, 2)}
              onClick={handleCancelOrder}
            />
            <input
              type="button"
              className={bx("blue-button")}
              value="알림톡 재전송"
              disabled={_.isEqual(_.head(displayList.list)!.status, 2)}
              onClick={handleResendAlimtalk}
            />
          </ButtonContainer>
          <CustomCardNoBorder
            title="주문정보"
            children={<RecipientInfo data={_.head(displayList.list)!} />}
            buttonHidden={true}
          />
          <OrderDetailInfo
            data={data}
            gfOrderList={gfOrderList || []}
          ></OrderDetailInfo>
          <CustomCardNoBorder
            title="배송지 정보"
            children={
              <DeliveryDetailInfo
                data={_.head(displayList.list)!}
                componentName="deliveryDetailInfo"
              />
            }
            editButtonTitle="배송지 수정"
            buttonHidden={_.isEqual(
              _.head(displayList.list)!.status,
              OoDeliveryStatus.cancelOrder
            )}
            submitButtonTitle="수정 완료"
            component="deliveryDetailInfo"
          />
          <CustomCardNoBorder
            title="배송정보"
            children={
              <DeliveryInfo
                data={_.head(displayList.list)!}
                componentName="deliveryInfo"
              />
            }
            editButtonTitle="배송정보 수정"
            buttonHidden={
              _.isUndefined(_.head(displayList.list)!.transportInfo) ||
              _.isEqual(
                _.head(displayList.list)!.status,
                OoDeliveryStatus.cancelOrder
              )
            }
            submitButtonTitle="수정 완료"
            component="deliveryInfo"
          />
          <CustomCardNoBorder
            title="CS 메모"
            children={
              <OrderCsInfo
                data={_.head(displayList.list)!}
                componentName="orderCsInfo"
              />
            }
            editButtonTitle="수정"
            // buttonHidden={_.isEqual(
            //   _.head(displayList.list)!.status,
            //   OoDeliveryStatus.cancelOrder
            // )}
            buttonHidden={false}
            submitButtonTitle="완료"
            component="orderCsInfo"
          />
          <DebugContainer>
            <a
              href={`https://api.doctoron.kr:12002/${
                _.head(displayList.list)!.id
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <AutoFixHighOutlinedIcon
                fontSize="small"
                style={{
                  color: "#2176F6",
                  border: "1px solid",
                  borderRadius: "0.25em",
                  marginRight: "0.25em",
                }}
              />
            </a>
            <div
              onClick={() =>
                copyToClipboard(String(_.head(displayList.list)!.id))
              }
            >
              (debug Id : {_.head(displayList.list)!.id})
            </div>
          </DebugContainer>
          {showCopyMessage && (
            <CopyMessageContainer>Copied to clipboard</CopyMessageContainer>
          )}
        </Container>
      )}
    </Wrapper>
  );
};

const CopyMessageContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 0.5em;
  overflow-y: scroll;
  color: #2176f6;
`;

const DebugContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 0.5em;
  overflow-y: scroll;
`;

const Wrapper = styled.div`
  width: 1224px;
  height: 100%;
  padding: 70px 0px;
`;

const Container = styled.div`
  height: auto;
  width: 100%;
  background: #fff;
  border: 1px solid #c4c4c4;
  padding: 2%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
`;

const Table = styled.table`
  width: 100%;
  height: auto;
  border: 2px solid #c8d6f8;

  & > tbody tr:first-child {
    border-bottom: 1px solid #c8d6f8;
  }

  & > tbody tr td:nth-child(2n-1) {
    width: 100px;
    height: 48px;
    background: #6e8ed1;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    color: #ffffff;
  }

  & > tbody tr td:nth-child(2n) {
    background: #fafbff;
    padding-left: 24px;
    font-weight: 400;
    font-size: 13px;
    color: #000000;
  }
`;
