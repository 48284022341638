import React, {useEffect} from "react";
import _ from "lodash";
import styled from "styled-components";
import {DoctorAlimTalkInfo} from "interfaces";
import {CustomInputText} from "@components/custom";
import {useAlimTalkSettingHook} from "@components/registerForm";
import { OoParamDoctorCreate } from "@api/params";

interface Props {
    onChanged: (data: DoctorAlimTalkInfo) => void;
    param:OoParamDoctorCreate
}

export const DoctorRegisterAlimInfo = ({onChanged , param}: Props) => {
    const {
        data,
        setData,
        alimTalk,
        onChangeAlimtalk,
    } = useAlimTalkSettingHook();
    
    useEffect(() => {
        onChanged(data);
    }, [data]);

    useEffect(() => {
        let newArr = _.concat(
            alimTalk.alimtalk1,
            alimTalk.alimtalk2,
            alimTalk.alimtalk3
        );
        setData({...data, alimTalkReceivers: newArr});
    }, [alimTalk]);

    return (
        <Wrapper>
            <table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>
                        <CustomInputText value={alimTalk.alimtalk1}
                                         onChanged={onChangeAlimtalk}
                                         color="blue"
                                         size="large"
                                         name='alimtalk1'
                                         placeholder="알림톡 수신번호를 입력하세요."
                                         disabled={true}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <CustomInputText value={alimTalk.alimtalk2}
                                         onChanged={onChangeAlimtalk}
                                         color="blue"
                                         size="large"
                                         name='alimtalk2'
                                         placeholder="알림톡 수신번호를 입력하세요."
                                         disabled={true}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <CustomInputText value={alimTalk.alimtalk3}
                                         onChanged={onChangeAlimtalk}
                                         color="blue"
                                         size="large"
                                         name='alimtalk3'
                                         placeholder="알림톡 수신번호를 입력하세요."
                                         disabled={true}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;
