import React, {useEffect} from "react";
import styled, {css} from "styled-components";
import _ from "lodash";
import packageJson from "../../../../package.json";
import {StatusFilterCard} from "./components/StatusFilterCard";
import {useDashBoardSettingHook} from "./hooks/useDashBoardSettingHook";
import {DiagnosisListContainer} from "./components/DiagnosisListContainer";
import {ListTitleContainer} from "@components/layout";
import {MainListTitle} from "@components/mainListTitle";
import moment from "moment";
import {DiagnosisCalendar} from "@components/calendar";
import {DiagnosisSearch} from "../../Operator/DiagnosisDashBoardPage/components";

interface FlexProps {
    flex: number;
}

export const DoctorDashBoardPage = () => {
    const {
        connect,
        disconnect,
        setQueryTrigger,
        selectedDoctorId,
        diagnosisList,
        selectedUserId,
        target,
        dailyDiagnosisSelectedDate,
        userName,
        setDailyDiagnosisList,
    } = useDashBoardSettingHook();

    useEffect(() => {
        setQueryTrigger(true);
    }, [selectedUserId]);

    useEffect(() => {
        if (!_.isUndefined(selectedDoctorId))
            connect(`teleMedicine/${selectedDoctorId}`);
        return () => {
            disconnect();
            setQueryTrigger(false);
        };
    }, [selectedDoctorId]);

    return (
        <Wrapper>
            <Container>
                <SearchContainer>
                    <div>v{packageJson.version}</div>
                    <DiagnosisSearch target={target} id={selectedDoctorId}/>
                </SearchContainer>
                <MainListTitle title={`${userName} 원장님`} maxWidth={1280} minWidth={1024}>
                    <CalendarContainer>
                        <TodayDiagnosis id={_.head(dailyDiagnosisSelectedDate.date)}>
                            당일진료
                        </TodayDiagnosis>
                        <DiagnosisCalendar target={target} id={selectedDoctorId}/>
                    </CalendarContainer>
                </MainListTitle>
                <StatusFilterCard list={diagnosisList}/>
                <ListTitleContainer top="367px">
                    <>
                        <Title flex={1.3}>상태</Title>
                        <Title flex={0.9}>이름</Title>
                        <Title flex={1.2}>전화번호</Title>
                        <Title flex={1.2}>접수 방식</Title>
                        <Title flex={0.9}>진료 방식</Title>
                        <Title flex={1.3}>진료비 결제 내역</Title>
                        <Title flex={1.1}>약제비 결제 내역</Title>
                        <Title flex={1.1}>약배송</Title>
                        <Title flex={0.9}>대기 알림</Title>
                    </>
                </ListTitleContainer>
            </Container>
            <DiagnosisListContainer list={diagnosisList}/>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  top: 150px;
  position: fixed;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 26px;
`;

const SearchContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  color: gray;
  font-size: 13px;
  font-weight: 700;
`;

const TodayDiagnosis = styled.div`
  width: 96px;
  height: 36px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  ${(props) =>
          props.id === `${moment().format(`YYYY.MM.DD`)}`
                  ? css`
                    color: ${(props) => props.theme.colors.statusFontColor.success};
                    border: 1px solid ${(props) => props.theme.colors.statusFontColor.success};
                    background: ${(props) =>
                            props.theme.colors.statusBgButtonColor.success};
                  `
                  : css`
                    color: ${(props) => props.theme.colors.fontColor.black};
                    border: 1px solid ${(props) => props.theme.colors.borderColor.grey};
                    background: ${(props) => props.theme.colors.bgColor.white};
                  `}
  \`;
`;

const Title = styled.div<FlexProps>`
  flex: ${(props) => props.flex};
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

