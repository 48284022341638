import { IOoUnospayDelivery, IOoUnospaySalesMenu, OoDeliveryStatus, OoProvider } from '@api/models'
import { IOoProducts } from '@api/models/productSetting/product'
import React from 'react'
import { ExcelExportModel } from '../model';
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import _ from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
const ExcelJS = require("exceljs");

const duldaOrder = async (list:IOoUnospayDelivery[], productList:IOoProducts[]) => {
    let data_array: Array<ExcelExportModel> = [];
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.duldaProvider));

    const findProduct = (externalCode: string) => {
        const product =  productList.find(product => product.externalCode == externalCode);
        return product;
    }
 
    const addCommasToPrice = (price: string): string => {
      return price.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const findProductOptionName = (externalCode: string, optionCode: string) => {
        const product =  productList.find(product => product.externalCode == externalCode);
        const option = product?.optionList.find(option => option.optionCode == optionCode);
        return option?.optionName || '';
    }

    const findProductOptionCode = (externalCode: string, optionCode: string) => {
        const product =  productList.find(product => product.externalCode == externalCode);
        const option = product?.optionList.find(option => option.optionCode == optionCode);
        return option?.optionOrderCode || '';
    }

    const convTimestampDateFormat = (timestamp: number) => {
        const dateFormat = new Date(timestamp);
        return (
          dateFormat.getFullYear() +
          (dateFormat.getMonth() + 1).toString().padStart(2, "0") +
          dateFormat.getDate().toString().padStart(2, "0")
        );
      }

    const createModel = (e:IOoUnospayDelivery) => {
        const model = new ExcelExportModel();
        model.name = e.recipientName;
        model.address1 = e.address;
        model.address2 = e.addressDetail;
        model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
        model.zipCode = e.zipCode;
        model.memo = e.memo;
        model.subMemo = e.subMemo;
        model.quantity = "1";
        model.deliveryCost = e.id;
        return model;
    }
      
    excelList.forEach(e => {
        console.log('e :: ', e);
        if (e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage) {
            e.salesMenu?.forEach(salesItem => {
                const product = findProduct(salesItem.externalCode!);
                let model = createModel(e);
                
                model.orderDate = convTimestampDateFormat(e.timestamp);
                
                if (salesItem.salesOption && salesItem.salesOption.length > 0) {
                    const option = salesItem.salesOption[0];
                    model.product = `${product?.productName} ${findProductOptionName(salesItem.externalCode!, option.externalCode!)}`;
                    model.exCode = product?.productCode || findProductOptionCode(salesItem.externalCode!, option.externalCode!);
                    model.price = ((product?.supplyPrice ?? 0) + (product?.optionList[0].optionSupplyPrice ?? 0)).toString();
                } else {
                    model.product = product?.productName;
                    model.exCode = product?.productCode;
                    model.price = product?.supplyPrice?.toString() ?? '0';
                }
                
                model.deliveryPrice = product?.deliveryPrice?.toString() ?? '0';
                
                data_array.push(model);
                console.log('model :: ', model);
            });
        }
    });

    const idGroupData = _(data_array).groupBy(data => data.address1).map((value) => (value)).value();
    const exCodeGroupData = idGroupData.map(item => _(item).groupBy(data => data.product).map((value => value)).value());
  
    const groupingExcelData = exCodeGroupData.map((item, cnt) => {
      return item.map((list, index) => {
          const productCnt = list.length;
          return {...list[0], quantity:productCnt.toString(), deliveryCost:`${list[0].orderDate?.padEnd(10,'0')}${cnt+1}-${String(1).padStart(2,'0')}` , deliveryPrice: index === 0 ? list[index].deliveryPrice : ''}
      });
    }).flatMap(item => item);

  const multiplyPrice = (price?:string, quantity?:string) => {
    if (!price ||!quantity) return "";
    return _.multiply(Number(price), Number(quantity)).toString();
  }
  
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferDulda = await (await fetch("/assets/dulda.xlsx")).arrayBuffer();
    const workbookDulda = new ExcelJS.Workbook();
    await workbookDulda.xlsx.load(arrayBufferDulda);
    const ws_mmx = workbookDulda.getWorksheet("Sheet1");
    groupingExcelData.map((data: ExcelExportModel, index: number) => {
      const row = ws_mmx.getRow(index + 2);
      [
        data.deliveryCost,
        data.orderDate,
        data.name,
        "",
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}`,
        "",
        `${data.address1} ${data.address2}`,
        data.memo,
        data.exCode,
        data.product,
        "",
        data.quantity,
        addCommasToPrice(data.price ?? '0'),
        addCommasToPrice(multiplyPrice(data.price ?? '0', data.quantity ?? '0')),
        addCommasToPrice(data.deliveryPrice ?? '0'),
        "선결제"

    ].forEach((value, index) => {
        const cell = row.getCell(index + 1);
        cell.value = value;
      })
    });
    const excelBuffer = await workbookDulda.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName + "_" + moment().format("YYYYMMDD") + "(덜다)" + excelFileExtension
    );
}

export default duldaOrder