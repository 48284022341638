import { hospitalListState } from '@recoils/display';
import React, { ChangeEvent, useEffect } from 'react'
import { useState, MouseEventHandler, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useRecoilValue } from 'recoil';
import { Card, Container, InputGroup, Spinner, Table } from 'react-bootstrap';
import styled from 'styled-components';
import _ from 'lodash'
import { IOoHospital } from '@api/models';
import ProductOrderForm from '../\bProductOrderForm';
import moment from 'moment';
import { DoctorOnAPI } from '@api/request';
import ProductOrderList from '../ProductOrderList.tsx';
import { postCreateOrderInfo } from '@api/params/unospay/postCreateOrderInfo';


interface Props {
    showProductOrderAddModal:boolean;
    setShowProductOrderAddModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface LayoutStyle{
    width?:string;
}

interface SelectedProductInfo{
    providerName:string;
    providerCode:string;
    productName:string;
    externalCode:string;
    optionCode:string;
    optionName:string;
    productPrice:number;
    menuCount:number;
}

const ProductOrderAddModal = ({showProductOrderAddModal:show, setShowProductOrderAddModal}:Props) => {
    const outRef = useRef<HTMLDivElement>(null);
    const hospitalList = useRecoilValue(hospitalListState);
    const [loading, setLoading] = useState(true);
    const [hospitalListData , setHospitalListData] = useState<IOoHospital[]>([]);
    const [searchHospitalName, setSearchHospitalName] = useState('');
    const [isSearchResultListShow, setIsSearchResultListShow] = useState(true);
    const [ordererInfo, setOrdererInfo] = useState({hospitalName:'',hospitalCode:'',ordererName:'',phoneNumber:'', paymentType:'' , orderPlatform:''});
    const [isShowProductList, setIsShowProductList] = useState({isProviderList:true, isProductNameList:true, isProductOptionList:true});
    const [ordererInfoList , setOrdererInfoList] = useState<SelectedProductInfo[]>([]);

    const getList = (list: Array<IOoHospital>, value: string) => {
        return list.filter((item) => _.includes(item.name, value));
    };

    const onChangeInput = (e:ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setSearchHospitalName(text);
        const findHospitalList = getList(hospitalList, text);
        
        if (_.isEqual(text.length, 0) && _.gt(hospitalList.length, 0)) {
            setHospitalListData(hospitalList)
            setIsSearchResultListShow(true);
        } else {
            setHospitalListData(findHospitalList);
            setIsSearchResultListShow(false);
        }
    }

    const handleChangeSelect = (e:ChangeEvent<HTMLSelectElement>) => {
        const {name,  value} = e.target;
        setOrdererInfo({...ordererInfo, [name]: value});
    }

    const onChangeOrderInput = (e:ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setOrdererInfo({...ordererInfo, [name]: value});
    }

    const onClickInput = () => {
        setIsSearchResultListShow(false);
    }

    const mappingSalesMenu = () => {
        const ordererInfoListRes = ordererInfoList.map(item => {
            return {
                menuName:item.productName,
                menuPrice:`${item.productPrice}`,
                menuCount:`${item.menuCount}`,
                externalCode:item.providerCode,
                salesOption:[
                    {
                        externalCode:item.optionCode,
                        optionName:item.optionName,
                        optionNameCh:item.optionName,
                    }
                ]
            }
        });

        return ordererInfoListRes
    }

    const onTargetNameClick = (code: string) => {
        const findHospital = hospitalList.find(item => item.code === code);
        if(!_.isUndefined(findHospital)){
            setOrdererInfo({...ordererInfo , hospitalName: findHospital.name , hospitalCode:findHospital.code});
            setSearchHospitalName(findHospital.name);
        }

        setIsSearchResultListShow(true);
    }

    const handleClose = () => {
        setShowProductOrderAddModal(false);
    }
    
    const dataFormatting = () => {
        const date = new Date();
        const dateFormat = moment(date).format('YYMMDD').toString();
        return {
            affiliateId:`theopusone_${ordererInfo.hospitalCode.toLocaleLowerCase()}`,
            dailyCode:`${dateFormat}${Math.random().toString().slice(2, 6)}`,
            dateTime:`${date}`,
            paymentType: ordererInfo.paymentType, //결제타입
            paymentPrice:`${totalProductPrice()}`,
            phoneNumber:ordererInfo.phoneNumber.replaceAll('-',''),
            orderPlatform:Number(ordererInfo.orderPlatform),
            salesMenu:mappingSalesMenu(),
        }
    }

    const createOrderInfoApi = async (dataFormattingRes:postCreateOrderInfo) => {
        try {
            const response = await DoctorOnAPI.shared.unospay.postCreateOrderInfo(dataFormattingRes);
            if(response.status === 'ok'){
                setShowProductOrderAddModal(false);
            }
        } catch (error) {
            console.log(error);
            
        }
    }

    const handleSubmit = () => {
        if(ordererInfo.hospitalName.length === 0){
            alert('병원정보를 선택해주세요');
        }else if(ordererInfo.ordererName.length === 0){
            alert('주문자를 입력해주세요');
        }else if(ordererInfo.phoneNumber.length === 0 || ordererInfo.phoneNumber.length < 11){
            alert('전화번호를 입력해주세요');
        }else if(ordererInfo.paymentType.length === 0){
            alert('결제 타입을 선택해주세요');
        }else if(ordererInfo.orderPlatform.length === 0){
            alert('주문경로를 선택해주세요');
        }else if(ordererInfoList.length === 0){
            alert('상품을 추가해주세요.');
        }else{
            const dataFormattingRes = dataFormatting();
            createOrderInfoApi(dataFormattingRes);
        }
    }

    const totalProductPrice = () => {
        const result = ordererInfoList.reduce((acc, cur) => {
            return acc + cur.productPrice
        },0);
        return result;
    }

    const handleClickMinusProduct = (index:number) => {
        const filterList = ordererInfoList.filter((_,idx) => idx !== index);
        setOrdererInfoList(filterList);
    }

    const onClickOutside = () => {
        setIsShowProductList(prevState => ({...prevState, isProviderList:true, isProductNameList:true, isProductOptionList:true}));
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          // 해당 이벤트가 영역 밖이라면
          if (outRef.current && !outRef.current.contains(event.target as Node)) {
            onClickOutside && onClickOutside();
          }
        };
    
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      }, [outRef, onClickOutside]);

    useEffect(() => {
        if(hospitalList){
            setLoading(false);
            setHospitalListData(hospitalList);
        }
    },[])

  return (
    <Modal show={show} onHide={handleClose} size='xl' height='720px'>
        {loading ? <Spinner animation="border" variant="primary" /> : 
        <>
        <Modal.Header closeButton>
          <Modal.Title>주문정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <OrdererInfoContainer>
                <OrdererInfoLayout>
                    <Layout width='290px'>
                        <InputLayout>
                            <label>병원정보</label>
                            <input placeholder="병원명을 검색하세요."
                                            value={searchHospitalName}
                                            onChange={onChangeInput}
                                            onClick={onClickInput}/>
                        </InputLayout>
                        <HospitalListLayout hidden={isSearchResultListShow} className="search-result-div">
                            {
                                hospitalListData.map((item, index) => (
                                    <div key={index} className="search-result-row" onClick={() => onTargetNameClick(item.code)}>
                                        <span className="search-result-name" id={item.id}>{item.name}</span>
                                    </div>
                                ))}
                        </HospitalListLayout>
                    </Layout>
                    <Layout width='160px'>
                        <InputLayout>
                            <label>주문자명</label>
                            <input placeholder="주문자명"
                                            name='ordererName'
                                            value={ordererInfo.ordererName}
                                            onChange={onChangeOrderInput}/>
                        </InputLayout>
                    </Layout>
                    <Layout width='220px'>
                        <InputLayout>
                            <label>전화번호</label>
                            <input placeholder="전화번호를 입력하세요."
                                            name='phoneNumber'
                                            value={ordererInfo.phoneNumber}
                                            onChange={onChangeOrderInput}/>
                        </InputLayout>       
                    </Layout>
                    <InputLayout>
                        <label>결제타입</label>
                        <select onChange={handleChangeSelect} name='paymentType'>
                            <option value="">선택</option>
                            <option value="1">현금</option>
                            <option value="3">카드</option>
                        </select>
                    </InputLayout>       
                    <InputLayout>
                        <label>주문경로</label>
                        <select onChange={handleChangeSelect} name='orderPlatform'>
                            <option value="">선택</option>
                            <option value="0">키오스크</option>
                            <option value="1">MTM몰</option>
                            <option value="2">전화주문</option>
                        </select>
                    </InputLayout>    
                </OrdererInfoLayout> 
                <Card.Header>
                        <span>
                            상품 주문목록
                        </span>
                        <span>
                            총 금액 : {totalProductPrice().toLocaleString()}원
                        </span>
                </Card.Header>
            </OrdererInfoContainer>
            <ProductOrderListContainer >
                <ProductOrderForm outRef={outRef} ordererInfoList={ordererInfoList} setOrdererInfoList={setOrdererInfoList} isShowProductList={isShowProductList} setIsShowProductList={setIsShowProductList}/>
                <ProductOrderCard >
                    {_.sortBy(ordererInfoList).reverse().map((item,index) => (
                        <ProductOrderList item={item} index={ordererInfoList.length - (index+1)} handleClickMinusProduct={handleClickMinusProduct} />
                    ))}
                </ProductOrderCard>
            </ProductOrderListContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            취소
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            주문등록
          </Button>
        </Modal.Footer>
        </>
      }
      </Modal>
  )
}

export default ProductOrderAddModal

const Layout = styled.div<LayoutStyle>`
    position: relative;
    width: ${props => props.width};
`

const OrdererInfoContainer = styled.div`
    .card-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        font-weight: bold;
        border-bottom: 2px solid #cfdbf7;

        & > span:last-child{
            text-align: right;
        }
    }
`

const OrdererInfoLayout = styled.div`
    position: relative;
    max-width: 1100px;
    width: 1100px; 
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 15px 0px;
`

const InputLayout = styled.div`
    display: grid;
    grid-template-columns: 75px auto;
    align-items: center;

    border-radius: 5px;
    & > input {
        width: 100%;
        border:none;
        border: 1px solid #cfdbf7;
        padding: 5px 7px;
        box-sizing: border-box;
    }
    & > select {
        height: 35px;
        border:none;
        border: 1px solid #cfdbf7;
        padding: 5px 7px;
        box-sizing: border-box;
    }
    & > label {
        padding: 6px 7px;
        background-color: #cfdbf7;
    }
`

const HospitalListLayout = styled.div`
    position: absolute;
    background: #ffffff;
    width: 213px;
    height: 300px;
    top: 35px;
    right: 0px;
    border: 2px solid #C8D6F8;
    overflow-y: auto;
    z-index: 1;
    div {
        padding: 5px;
    }

    & > div:hover {
        background-color: #F4F9FC;
        cursor: pointer;
    }
`

const ProductOrderListContainer = styled.div`
    height: 720px;
    max-height: 720px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .col {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }
`

const ProductOrderCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% -135px);
    overflow-y: auto;
    .card-body{
        padding: 15px;
        display: flex;
        column-gap: 15px;
    }
    .row {
        display: grid;
        grid-template-columns: 20px 240px 400px 190px 130px;
        align-items: center;
        grid-gap: 10px;

        & > .col{
            padding: 0;
        }
    }
`