import {
  filterDiagnosisAcceptList,
  filterDiagnosisCancelList,
  filterDiagnosisCompleteList,
  filterDiagnosisMedicinePaySuccessList,
  filterDiagnosisMedicinePayWaitList,
  filterDiagnosisPayFail,
  filterDiagnosisRequestList,
  filterDiagnosisTotalList,
  filterDoctorDiagnosisCompleteList,
  filterDoctorDiagnosisRequestList,
  filterMedicineDeliveryFail,
  filterMedicineDeliveryStart,
  filterPharmacyPackageList,
  filterPickupWaitList,
  filterRequestMedicineDeliveryList,
  filterReservationList,
  filterTodayDeliveryFailList,
  filterTodayPackageDeliveryList,
  IOoDiagnosis,
} from "../../api";

interface Props {
  list: Array<IOoDiagnosis>;
}

export const useDisplayDiagnosisRtdbHook = ({list}: Props) => {
  // 전체진료
  const total = () => {
    return filterDiagnosisTotalList(list);
  };

  // 진료 취소
  const doctorCancel = () => {
    return filterDiagnosisCancelList(list);
  };

  // 예약 신청
  const requestReservation = () => {
    return filterReservationList(list);
  };

  // 진료신청
  const request = () => {
    return filterDiagnosisRequestList(list);
  };

  // 의사 진료신청
  const doctorRequest = () => {
    return filterDoctorDiagnosisRequestList(list);
  };

  // 진료 접수 완료
  const accept = () => {
    return filterDiagnosisAcceptList(list);
  };

  // 진료비 미결제
  const diagnosisPayFail = () => {
    return filterDiagnosisPayFail(list);
  };

  // 진료 완료
  const diagnosisComplete = () => {
    return filterDiagnosisCompleteList(list);
  };

  // 의사 진료 완료
  const doctorDiagnosisComplete = () => {
    return filterDoctorDiagnosisCompleteList(list);
  };

  // 약 배송 신청
  const medicineDeliveryRequest = () => {
    return filterRequestMedicineDeliveryList(list);
  };

  // 약제비 결제 완료
  const medicineDeliveryPaymentOk = () => {
    return filterDiagnosisMedicinePaySuccessList(list);
  };

  // 약제비 미결제
  const medicineDeliveryPaymentWait = () => {
    return filterDiagnosisMedicinePayWaitList(list);
  };

  // 픽업 대기
  const waitPickup = () => {
    return filterPickupWaitList(list);
  };

  // 약 배송 시작
  const medicineDeliveryStart = () => {
    return filterMedicineDeliveryStart(list);
  };

  const medicineDeliveryCancel = () => {
    return filterMedicineDeliveryFail(list);
  };
  // 당일 배송 실패건
  const todayDeliveryFail = () => {
    return filterTodayDeliveryFailList(list);
  };

  // 약 수령방식 미선택
  const noMedicineChoice = () => {
    return filterDiagnosisCompleteList(list);
  };

  // 방문수령
  const pharmacyPickup = () => {
    return filterPharmacyPackageList(list);
  };
  // 당일배송/택배
  const todayPackageDelivery = () => {
    return filterTodayPackageDeliveryList(list);
  };

  return {
    total,
    request,
    doctorRequest,
    accept,
    doctorCancel,
    noMedicineChoice,
    medicineDeliveryPaymentWait,
    medicineDeliveryPaymentOk,
    pharmacyPickup,
    todayPackageDelivery,
    todayDeliveryFail,
    medicineDeliveryRequest,
    requestReservation,
    diagnosisPayFail,
    diagnosisComplete,
    waitPickup,
    medicineDeliveryStart,
    medicineDeliveryCancel,
    doctorDiagnosisComplete,
  };
};
