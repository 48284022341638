import { AbstractOoParam } from "../base";

class OoUnospayChangeDeliveryStatus implements AbstractOoParam {
    id: string = '';
    status: number;
    
    getPathParams(): string {
        return "";
    }

    constructor(id:string , status:number){
        this.id = id;
        this.status = status;
    }
}

export {OoUnospayChangeDeliveryStatus}