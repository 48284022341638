import {atom, atomFamily, DefaultValue, selectorFamily} from "recoil";
import {IOoDiagnosis} from "@models/diagnosis";

interface DisplayInfo {
    id: string,
    list: Array<IOoDiagnosis>,
}

const displayAtom = atom<Array<string>>({
    key: 'displayAtom',
    default: [],
});

const displayAtomFamily = atomFamily<DisplayInfo, string>({
    key: 'displayAtomFamily',
    default: (id) => {
        const item: DisplayInfo = {
            id,
            list: [],
        }
        return item;
    }
});

export const displayListState = selectorFamily<DisplayInfo, string>({
    key: "displayListState",
    get: (id: string) => ({get}) => get(displayAtomFamily(id)),
    set: (id: string) => ({get, set, reset}, displayInfo) => {
        if (displayInfo instanceof DefaultValue) {
            reset(displayAtomFamily(id))
            set(displayAtom, (prevValue) => prevValue.filter((item) => item !== id))
            return
        }
        set(displayAtomFamily(id), displayInfo)
        set(displayAtom, (prev) => Array.from(new Set([...prev, id])))
    }
})
