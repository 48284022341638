import {useState} from "react";
import {DoctorServiceInfo} from "../../../../interfaces/Operator/OperatorDoctorCreateInterface";
import _ from "lodash";
import {toast} from "react-toastify";

export const useThemeSettingHook = () => {

    const [checkedTheme, setCheckedTheme] = useState<Array<string>>([]);
    const [data, setData] = useState<DoctorServiceInfo>({
        departments: [],
        theme: [],
    });

    const onCheckedTheme = (e: any) => {
        const index = _.findIndex(checkedTheme, (item) => _.isEqual(item, e.target.value));
        if (_.lt(checkedTheme.length, 3)) {
            if (!_.isEqual(index, -1)) {
                checkedTheme.splice(index, 1);
                setCheckedTheme(checkedTheme);
                setData({...data, theme: checkedTheme});
            } else {
                checkedTheme.push(e.target.value);
                setCheckedTheme(checkedTheme);
                setData({...data, theme: checkedTheme});
            }
        } else {
            if (!_.isEqual(index, -1)) {
                checkedTheme.splice(index, 1);
                setCheckedTheme(checkedTheme);
                setData({...data, theme: checkedTheme});
            } else {
                toast.error('진료 테마는 최대 3개까지 선택가능합니다.');
                return;
            }
        }
    };

    const isEqualTheme = (value: string) => {
        const temp = checkedTheme.map(item => _.isEqual(item, value));
        return _.includes(temp, true);
    };

    return {
        checkedTheme,
        data,
        onCheckedTheme,
        isEqualTheme,
    }

}