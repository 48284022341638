import { IOoCalculateMonthList } from '@api/models/calculate/calculateMonthList';
import { List } from '@recoils/display';
import { RawExcelExportModel } from 'pages/Operator/UnospayDashBoardPage/components/ExcelDownloadButton/rawModel';
import React from 'react'
import styled from 'styled-components'
import _ from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
import { OoOrderPlatformType } from '@api/models';
import { IOoProducts } from '@api/models/productSetting/product';
const ExcelJS = require("exceljs");

const RawCalculateOrder = async (list:Array<IOoCalculateMonthList> , productList:Array<IOoProducts>) => {
  let data_array: Array<RawExcelExportModel> = [];
  
  const findOrderPlatform = (type:number) => {
    switch (type) {
      case OoOrderPlatformType.kiosk:
        return '키오스크'
      case OoOrderPlatformType.mtmMall:
        return 'MTM몰'
      case OoOrderPlatformType.telephone:
        return '전화주문'
      case OoOrderPlatformType.test:
        return 'Test주문'
      default:
        return '-'
    }
  }

  const findProductType = (excode:string) => {
    const result = productList.find(item => item.externalCode === excode);
    return result?.productType;
  }

  const getProductType = (type:number|undefined)=> {
    switch (type) {
      case 0:
        return 'MTM';
      case 1:
        return 'MTE';
      case 2:
        return 'MTS';
      default:
        return '-';
    }
  }


  list.forEach(item => {
    const model = new RawExcelExportModel();
    const type = findProductType(item.externalCode);
    model.id = item.id;
    model.orderId = item.orderId;
    model.payerName = item.payerName;
    model.payerMobile = item.payerMobile
    model.dateTime = moment(item.dateTime).format('YYYY-MM-DD HH:mm:ss');
    model.hospitalCode = item.hospitalCode;
    model.hospitalName = item.hospitalName;
    model.orderPlatform = findOrderPlatform(item.orderPlatform);
    model.hospitalCalPrice = item.hospitalCalPrice ? item.hospitalCalPrice : 0;
    model.externalCode = item.externalCode;
    model.goods = item.goods;
    model.productType = getProductType(type)
    model.paymentPrice = item.paymentPrice;
    model.providerName = item.providerName;
    model.refererList = item.refererList ? item.refererList?.join(', ') : "";
    model.refererCalPrice = item.refererCalPrice ? item.refererCalPrice?.join(', ') : "";
    model.cancelDateTime = item.cancelDateTime;
    model.operatorCancelTimestamp = item.operatorCancelTimestamp;
    data_array.push(model);
  })

  const excelFileExtension = ".xlsx";
  const excelFileName = `tlc헬스케어 정산 엑셀파일`;
  
  const arrayBufferRaw = await (await fetch("/assets/raw.xlsx")).arrayBuffer();
  const workbookRaw = new ExcelJS.Workbook();

  await workbookRaw.xlsx.load(arrayBufferRaw);
  const worksheet = workbookRaw.getWorksheet('정산');

  const rowNumber = 2;
  data_array.forEach((data:RawExcelExportModel, index:number) => {
    const row = worksheet.getRow(rowNumber + index);
    [
      index+1,
      data.id,
      data.payerName,
      data.payerMobile,
      data.dateTime,
      data.hospitalCode,
      data.hospitalName,
      data.orderPlatform,
      data.hospitalCalPrice,
      data.productType,
      data.goods,
      data.paymentPrice,
      data.providerName,
      data.refererList,
      data.refererCalPrice,
      data.orderId
    ].forEach((value, index) => {
      const cell = row.getCell(index + 1);
        cell.value = value;
        if(data.cancelDateTime !== "" || data.operatorCancelTimestamp !== 0 ){
          cell.fill = {
              type	: "pattern",
              pattern	: "solid",
              fgColor	: { argb: "ff0000" }
            };
          }
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          }
    });
  });
  const excelBuffer = await workbookRaw.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFile,
    excelFileName +
      "_" +
      moment().format("YYYYMMDD") +
      excelFileExtension
  );
}

export default RawCalculateOrder

