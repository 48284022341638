class OoParamDoctorPatientRecord {
    public patientName: string;
    public doctorId: string;

    constructor(patientName: string, doctorId: string) {
        this.patientName = patientName;
        this.doctorId = doctorId;
    }

    getPathParams(): string {
        return `${this.patientName}/${this.doctorId}`;
    }
}

export  {
    OoParamDoctorPatientRecord
}