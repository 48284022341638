import {DefaultTheme} from "styled-components";

const colors = {
    reservationTypeBgColor: {
        reservation:"#00326A",
        noReservation:"#6E8ED1",
    },

    bgColor: {
        white: "#FFFFFF",
        grey: "#C2C1BD",
        blue:"#6E8ED1",
        red:"#F6CFD3",
    },

    fontColor: {
        grey:"#949390",
        black:"#000000",
        blue:"#3E7BCD",
        red:"#CF5D5D",
    },

    filterCardBgColor: {
        grey:"#949390",
        blue:"#3E7BCD",
        navy:"#28344D",
        red:"#CF5D5D",
    },

    filterCardFontColor: {
        white:"#FFFFFF",
        black:"#000000",
        blue:"#536DA3",
        red:"#B43831",
    },

    statusBgButtonColor: {
        success:"#EFF7FF",
        danger:"#FDEFEF",
    },

    statusFontColor: {
        success:"#2176F6",
        danger:"#EC2C2C",
    },

    borderColor: {
        grey:"#C2C1BD",
        blue:"#3E7BCD",
    },

    statusRowFontColor: {
        diagnosisTypePhone:"#5385EF",
        diagnosisTypeVideo:"#4CA555",
        diagnosisTypeOffLine:"#000000",
        diagnosisPay:"#536DA3",
    },

};

const fontSize = {
    fs12:12,
    fs13:13,
    fs16:16,
    fs20:20,
    fs33:33,
};

const fontBold = {
    fb400:400,
    fb500:500,
    fb600:600,
    fb700:700,
};

export type ColorTypes = typeof colors;
export type FontSizeTypes = typeof fontSize;
export type FontBoldTypes = typeof fontBold;

export const theme: DefaultTheme = {
    colors,
    fontSize,
    fontBold
};