import React, {useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import {DiagnosisDetailLayout} from "./components";
import styled from "styled-components";
import {IOoDiagnosis} from "@models/diagnosis";
import {useDiagnosisHook} from "@hooks/common";
import {TabButton} from "@components/custom";
import {SearchParamProps} from "@recoils/doctor";
import {CalendarParamProp} from "@recoils/unospay";

export const DiagnosisDetailPage = () => {
    const location = useLocation();
    const diagnosisList = _.get(location.state, 'diagnosisList') as Array<IOoDiagnosis>;
    const calendarParam = _.get(location.state, 'calendarParam') as CalendarParamProp;
    const searchParam = _.get(location.state, 'searchParam') as SearchParamProps;

    const {
        displayList
    } = useDiagnosisHook({list: diagnosisList, calendarParam: calendarParam, searchParam: searchParam});

    const [radioValue, setRadioValue] = useState<string>(_.head(diagnosisList)!.id || "");

    const targetData = useMemo(() => {
        return _.find(displayList.list, (d) => _.isEqual(d.id, radioValue || ""));
    }, [radioValue, displayList]);

    const onChanged = (e: any) => setRadioValue(e.currentTarget.value);

    return (
        <Wrapper>
            <div>
                {
                    (!_.isUndefined(diagnosisList) && !_.isUndefined(targetData)) &&
                    <>
                        <TabButton list={diagnosisList}
                                   radioValue={radioValue}
                                   onChanged={onChanged}/>
                        <DiagnosisDetailLayout diagnosis={targetData} list={diagnosisList}/>
                    </>

                }
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 1024px;
  height: 100%;
  padding: 70px 0px;

  & > div:first-child {
    display: flex;
    flex-direction: column;
  }
`;
