import {AbstractOoParam} from "@api/params";
import {OoWapleReservationType} from "@api/models";

class OoParamWapleEnableSlot extends AbstractOoParam {
    patientId: string = '';

    doctorId: string = '';

    hospitalId: string = '';

    reservationType: number = OoWapleReservationType.all;

    reservationDate: string = '';
}

export {
    OoParamWapleEnableSlot
}