import { IOoCalculate, Statistics } from '@api/models/calculate/calculate'
import React from 'react'
import { RawExcelExportModel } from '../rawModel';
import moment from 'moment';
const ExcelJS = require("exceljs");
import FileSaver from 'file-saver';

const getPrevMonth = (monthData?: string) => {
    if (monthData) {
      const year = moment().year();
      const month = monthData;
      const date = moment(`${year}-${month}`).format("YYYY-MM-DD");

      const startDate = moment(date).format("YYYY .MM .DD");
      const endDate = moment(date).endOf("month").format("YYYY .MM .DD");

      return `${startDate} ~ ${endDate}`;
    }
  };

const calculateListYearExcelDown = async (name:string,list: Statistics[]) => {
    let data_array: Array<RawExcelExportModel> = [];
    
    list.forEach(item => {
        const model = new RawExcelExportModel();
        model.dateTime = item.month;
        model.totalPrice = item.totalPrice;
        model.hospitalCalPrice = item.totalHospitalCalPrice;
        model.refererCalPrice = item.totalRefererCalPrice.toString();

        data_array.push(model);
    });

    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc ${name} 정산 리스트 `;

    const arrayBufferCalculate = await (await fetch("/assets/calculateListYear.xlsx")).arrayBuffer();
    const workbookCalculate = new ExcelJS.Workbook();
    await workbookCalculate.xlsx.load(arrayBufferCalculate);
    const ws_Calculate = workbookCalculate.getWorksheet("Worksheet");

    data_array.map((data: RawExcelExportModel, index: number) => {
        const row = ws_Calculate.getRow(index + 2); // 엑셀은 1부터 시작
        [
            index+1,
            getPrevMonth(data.dateTime),
            data.totalPrice?.toLocaleString(),
            data.hospitalCalPrice.toLocaleString(),
        ].forEach((value , index) => {
            const cell = row.getCell(index + 1);
            cell.value = value;
        });
    });
    const excelBuffer = await workbookCalculate.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
    excelFile,
    excelFileName + "_" + moment().format("YYYYMMDD") + excelFileExtension
    );
}

export default calculateListYearExcelDown