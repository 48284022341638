import {AxiosInstance} from "axios";
import {IOoDataResponse, IOoResponse} from "../reponses";
import {IOoCompactHospital, IOoHospital} from "../models";
import {baseUrl, endPoint} from "./base";
import {
    OoParamCode,
    OoParamHospitalCreate,
    OoParamHospitalDoctor,
    OoParamHospitalPharmacy,
    OoParamHospitalUpdate,
    OoParamName
} from "@api/params";
import {OoParamEmail, OoParamId} from "../params";

class DoctorOnHospitalApi {
    constructor(private readonly axiosInstance: AxiosInstance) {
    }

    async create(param: OoParamHospitalCreate): Promise<IOoDataResponse<IOoHospital>> {
        return await this.axiosInstance.post<IOoDataResponse<IOoHospital>>(endPoint.hospital.create, param);
    }

    async update(param: OoParamHospitalUpdate): Promise<IOoDataResponse<IOoHospital>> {
        return await this.axiosInstance.put<IOoDataResponse<IOoHospital>>(endPoint.hospital.update, param);
    }

    async findByEmail(param: OoParamEmail): Promise<IOoDataResponse<IOoHospital>> {
        const url = `${endPoint.hospital.findByEmail}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoHospital>>(url);
    }

    async findByName(param: OoParamName): Promise<IOoDataResponse<IOoHospital>> {
        const url = `${endPoint.hospital.findByName}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoHospital>>(url);
    }

    async find(param: string): Promise<IOoDataResponse<IOoHospital>> {
        const url = `${baseUrl.prod}${endPoint.hospital.find}/${param}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoHospital>>(url);
    }

    async read(param: OoParamId): Promise<IOoDataResponse<IOoHospital>> {
        const url = `${endPoint.hospital.read}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoHospital>>(url);
    }

    async list(): Promise<IOoDataResponse<IOoHospital[]>> {
        return await this.axiosInstance.get<IOoDataResponse<IOoHospital[]>>(endPoint.hospital.list);
    }

    async compactList(): Promise<IOoDataResponse<IOoCompactHospital[]>> {
        return await this.axiosInstance.get<IOoDataResponse<IOoCompactHospital[]>>(endPoint.hospital.compactList);
    }

    async delete(param: OoParamId): Promise<IOoResponse> {
        const url = `${endPoint.hospital.delete}/${param.getPathParams()}`;
        return await this.axiosInstance.delete<IOoResponse>(url);
        // console.log('hospital::delete ==>> fake delete sorry. from bsh');
        // return {
        //     status: 'ok',
        //     timestamp: Date.now()
        // };
    }

    async addDoctor(param: OoParamHospitalDoctor): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.hospital.addDoctor, param);
    }

    async removeDoctor(param: OoParamHospitalDoctor): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.hospital.removeDoctor, param);
    }

    async addPharmacy(param: OoParamHospitalPharmacy): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.hospital.addPharmacy, param);
    }

    async removePharmacy(param: OoParamHospitalPharmacy): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.hospital.removePharmacy);
    }
}

export {
    DoctorOnHospitalApi
};