import React, {useEffect, useState} from "react";
import styled from "styled-components";
import _ from "lodash";
import {DoctorProfileUploader, HospitalLogoUploader, PharmacyProfileUploader} from "@utils/storage";
import {toast} from "react-toastify";

interface ImageProps {
    title?: string,
    target: string,
    hospitalId?: string,
    initialImgSrc?: string,
    hiddenRemoveButton?: boolean,
    onChanged: (target: string, title?: string) => void,
}

const initialPicture = "https://pds.joongang.co.kr/news/component/htmlphoto_mmdata/201904/26/8c2a59da-f114-43dd-aaed-cb5fa3f9f7ee.jpg";

export const InitialImageUpload = ({
                                       target,
                                       hospitalId,
                                       initialImgSrc,
                                       onChanged,
                                       title,
                                       hiddenRemoveButton
                                   }: ImageProps) => {
    const [, setImgSrc] = useState<string>(initialImgSrc || initialPicture);
    const [enabledMode,] = useState<boolean>(false);
    const [attachment, setAttachment] = useState<string>();
    const [fileObject, setFileObject] = useState<File>();

    const onLoadPicture = (e: any) => {
        const reader = new FileReader();
        const target = e.target.files[0];
        setFileObject(target);

        reader.onload = (finishEvent) => {
            const result = finishEvent.target!.result as string
            setAttachment(result);
        };
        reader.readAsDataURL(target);
    };

    const convertUrl = async (target: string) => {
        if (_.isUndefined(fileObject)) return;
        let downloadUrl: string | null = '';
        switch (target) {
            case 'doctor':
                downloadUrl = await DoctorProfileUploader.upload(hospitalId || "", fileObject);
                break;
            case 'hospital' :
                downloadUrl = await HospitalLogoUploader.upload(fileObject);
                break;
            case 'pharmacy' :
                downloadUrl = await PharmacyProfileUploader.upload(fileObject);
                break;
        }

        if (_.isEmpty(downloadUrl)) {
            toast.error('오류가 발생했습니다.');
            return;
        }
        setImgSrc(downloadUrl || '');
    };

    useEffect(() => {
        onChanged(attachment || '', title || '');
    }, [attachment]);

    useEffect(() => {
        convertUrl(target);
    }, [attachment]);

    const onRemovePicture = (e: any) => {
        e.preventDefault();
        setAttachment('');
    };

    return (
        <UploadImage hidden={enabledMode}>
            <UploadInputContainer>
                {
                    _.isEmpty(attachment) ?
                        <>
                            <UploadLabel htmlFor={`imgUpload${title}`}>
                                <div>{`image +`}</div>
                                <div>
                                    <button type="button">
                                        <img alt="" src={`${process.env.PUBLIC_URL}/img/camera-icon.png`}/>
                                    </button>
                                </div>
                            </UploadLabel>
                            <input type="file"
                                   id={`imgUpload${title}`}
                                   name=""
                                   accept=".jpg,.png,.jpeg"
                                   onChange={onLoadPicture}
                                   hidden={true}
                            />
                        </>
                        :
                        <ButtonContainer>
                            <div>
                                <button type="button">
                                    <img alt="" src={`${process.env.PUBLIC_URL}/img/camera-icon.png`}/>
                                </button>
                            </div>
                            <AttachedImage src={attachment}/>
                        </ButtonContainer>
                }
            </UploadInputContainer>
        </UploadImage>
    )
}


const AttachedImage = styled.img`
  width: 146px;
  height: 146px;
  border-radius: 8px;
  border: 1px solid #DCDBD6;
  object-fit: cover;
  position: absolute;
  z-index: 1;
`;

const UploadImage = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const UploadLabel = styled.label`
  width: 146px;
  height: 146px;
  cursor: pointer;
  border: 1px solid #DCDBD6;
  border-radius: 8px;
  background: #EEEEEE;
  z-index: 10;
  position: relative;

  & > div:nth-child(1) {
    width: 146px;
    height: 146px;
    font-weight: 400;
    font-size: 14px;
    color: #828282;
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:nth-child(2) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 4px;

    & > button {
      width: 36px;
      height: 36px;
      background: #FFFFFF;
      border-radius: 8px;
      border: none;

      & > img {
        width: 20px;
        height: 18px;
      }
    }
  }
`;

const UploadInputContainer = styled.div`
  width: 146px;
  height: 146px;
  border: none;
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  height: 100%;
  position: relative;

  & > div {
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 4px;

    & > button {
      width: 36px;
      height: 36px;
      background: #FFFFFF;
      border-radius: 8px;
      border-color: #FFFFFF;
      padding: 4px;

      & > img {
        width: 20px;
        height: 18px;
      }
    }
  }
`;