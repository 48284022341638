import classNames from "classnames/bind";
import InputStyles from '../../../styles/common/Input.module.scss';
import _ from "lodash";
import { height } from "@fortawesome/free-solid-svg-icons/faClose";

interface Props {
    size: string,
    color?: string,
    value: string,
    defaultValue?: string,
    name?: string,
    placeholder?: string,
    disabled: boolean,
    onChanged?: (e: any) => void,
    title?: string,

}

export const CustomInputText = ({
    value,
    defaultValue,
    size,
    color,
    name,
    placeholder,
    disabled,
    onChanged,
    title,
}: Props) => {
    const ix = classNames.bind(InputStyles);
    return (
        <>
            {
                _.isUndefined(onChanged) ?
                    <input className={ix('Input', size, color)}
                        type="text"
                        name={name}
                        value={value}
                        onChange={(e) => e}
                        placeholder={placeholder}
                        readOnly={!disabled}
                        disabled={!disabled}
                        title={title}
                        text-overflow="ellipsis"
                    />
                    :
                    <input className={ix('Input', size, color)}
                        type="text"
                        name={name}
                        value={value}
                        onChange={(e) => onChanged(e)}
                        placeholder={placeholder}
                        readOnly={!disabled}
                        disabled={!disabled}
                        title={title}
                    />
            }
        </>
    );
}
