import {CovidTestType, HospitalType} from "@api/models";
import {AbstractOoParam} from "../base";
import {HospitalBankInfo, HospitalBasicInfo, HospitalPicture, HospitalTypeInfo} from "../../../interfaces";


class OoParamHospitalCreate extends AbstractOoParam {
    //이메일
    public email: string = '';

    //패스워드
    public password: string = '';

    //알림톡 사용여부
    public useOrderAlimtalk: boolean = true;

    //병원명
    public name: string = '';

    //주소
    public address: string = '';

    //우편변호
    public zipCode: string = '';

    //전화번호
    public tel: string = '';

    //팩스번호
    public fax?: string = '';

    //홈페이지
    public web?: string = '';

    //소개
    public bio?: string = '';

    //정산 은행명
    public bank: string = '';

    //계좌번호
    public bankAccount: string = '';

    //예금주
    public bankHolderName: string = '';

    //진료과
    public departments?: Array<string> = [];

    //사진
    public pictures: Array<string> = [];

    //지역 검색 키워드
    public regionKeywordList?: Array<string> = [];

    //병원 타입
    public type?: number = HospitalType.normal;

    //코로나 검사 가능 (all: rat, pcr)
    public covidTestType?: number = CovidTestType.unknown;

    //소아청소년 코로나 검사 전용
    public isOnlyChildrenCovid?: boolean = false;

    //소아청소년 코로나 검사 전용
    public logo?: string = "";

    //MTM 전용 병원 여부
    public isMtmHospital?: boolean = false;

    public referrer?: string = "";
    
    public referrerList?: string[] = [];

    public imgUrl : string = 'https://firebasestorage.googleapis.com/v0/b/opusone-doctor-on.appspot.com/o/hospitalLogo%2Flogo_tlc.png?alt=media&token=a4eb564d-a56a-4b87-a936-b1548b5bd595';

    createInfo(data: HospitalBasicInfo) {
        this.name = data.name;
        this.email = data.email;
        this.password = data.password;
        this.tel = data.tel;
        this.zipCode = data.zipCode;
        this.address = data.address;
        this.regionKeywordList = data.regionKeywordList;
        this.logo = data.logo === '' ? this.imgUrl : data.logo;
        this.isMtmHospital = data.isMtmHospital;
        this.type = data.type;
        this.referrerList = data.referrerList;
        this.useOrderAlimtalk = data.useOrderAlimtalk!;
    }

    createPictures(data: HospitalPicture) {
        this.pictures = data.pictures.length === 0 ? [this.imgUrl] : data.pictures;
    }

    createBankInfo(data: HospitalBankInfo) {
        this.bank = data.bank;
        this.bankAccount = data.bankAccount;
        this.bankHolderName = data.bankHolderName;
    }

    createTypeInfo(data: HospitalTypeInfo) {
        this.type = data.type;
    }
}

export {
    OoParamHospitalCreate
}
