import {IOoDiagnosis, OoDiagnosisStatus, OoWapleReservationStatus, OoWapleReservationType} from "@api/models";
import _ from "lodash";

interface DiagnosisProcessingState {
    title: string,
    color: DiagnosisStatusColor,
    background: DiagnosisBackgroundColor,
    timestamp: number,
}

enum DiagnosisStatusColor {
    default = '#28344D',
    cancel = '#CF5D5D',
    success = '#3E7BCD',
    wait = '#3E7BCD',
}

enum DiagnosisBackgroundColor {
    success = '#EFF7FF',
    fail = '#FDEFEF',
}


export function isReservation(diagnosis: IOoDiagnosis): boolean {
    return (_.isEqual(diagnosis.wapleReservationType, OoWapleReservationType.phoneReservation))
        || (_.isEqual(diagnosis.wapleReservationType, OoWapleReservationType.videoReservation))
        || (_.isEqual(diagnosis.wapleReservationType, OoWapleReservationType.visitReservation));
};

export function getDiagnosisReservationState(diagnosis: IOoDiagnosis): DiagnosisProcessingState {
    let statusData = {
        title: "진료 완료",
        color: DiagnosisStatusColor.default,
        background: DiagnosisBackgroundColor.success,
        timestamp: diagnosis.wapleReservationTimestamp ?? 0,
    };
    switch (diagnosis.wapleReservationStatus) {
        case OoWapleReservationStatus.reserved:
            return statusData = {
                title: "예약 접수",
                color: DiagnosisStatusColor.default,
                background: DiagnosisBackgroundColor.success,
                timestamp: diagnosis.timestamp,
            };
        case OoWapleReservationStatus.reservationComplete:
            return statusData = {
                title: "예약 확정",
                color: DiagnosisStatusColor.default,
                background: DiagnosisBackgroundColor.success,
                timestamp: diagnosis.wapleReservationTimestamp ?? 0,
            };
        case OoWapleReservationStatus.visitComplete:
        case OoWapleReservationStatus.onlineConfirm:
            if (_.gte(diagnosis.status, OoDiagnosisStatus.diagnosisComplete)) {
                return statusData = {
                    title: "진료 완료",
                    color: DiagnosisStatusColor.default,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.wapleReservationTimestamp ?? 0,
                };
            } else {
                return statusData = {
                    title: "진료 접수 완료",
                    color: DiagnosisStatusColor.success,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.wapleReservationTimestamp ?? 0,
                };
            }
        case OoWapleReservationStatus.reservationCancelBySelf:
            statusData = {
                title: "예약취소(본인취소)",
                color: DiagnosisStatusColor.cancel,
                background: DiagnosisBackgroundColor.fail,
                timestamp: diagnosis.timestamp,
            };
            break;
        case OoWapleReservationStatus.reservationCancelByHospital:
            statusData = {
                title: "예약취소(병원취소)",
                color: DiagnosisStatusColor.cancel,
                background: DiagnosisBackgroundColor.fail,
                timestamp: diagnosis.timestamp,
            };
            break;
        case OoWapleReservationStatus.reservationCancelByNoShow:
            statusData = {
                title: "예약취소(미방문)",
                color: DiagnosisStatusColor.cancel,
                background: DiagnosisBackgroundColor.fail,
                timestamp: diagnosis.timestamp,
            };
            break;
    }
    return statusData;
}