import { AbstractOoParam } from "../base";

class OoUnospayPostMealDetail implements AbstractOoParam{
    
        mealCode: string = '';
        itemCode: string = '';
        searchDate: string = '';

        getPathParams(): string {
            return "";
        }
}

export {OoUnospayPostMealDetail};