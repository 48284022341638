import { useQuery } from "react-query";
import { DoctorOnAPI, isRequestSucceed } from "../../api";
import { useSuspenseHook } from "../../hooks";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { providerListState } from "../../recoils";

export const useUnospayProviderListQuery = () => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
  const [list, setList] = useRecoilState(providerListState);
  return useQuery(
    "unospay/foodProviderList",
    async () => {
      try {
        // if (!_.isEmpty(list)) return list;
        setLoading();
        const response = await DoctorOnAPI.shared.unospay.foodProviderList();
        setStopLoading();
        if (isRequestSucceed(response)) {
          setList(response.data || []);
          return response.data;
        }
        return undefined;
      } catch (e: any) {
        setErrorStatus(e);
      }
    },
    {
      enabled: _.isEmpty(list), // 리스트가 비어있을 때만 요청 실행
      retry: true,
      refetchOnWindowFocus: false,
      refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
      staleTime: 0,
      cacheTime: Infinity,
    }
  );
};
