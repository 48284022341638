import React, {useEffect} from "react";
import {WorkHourRow} from "../../../workHourRow";
import classNames from "classnames/bind";
import timeTableStyle from "../../../../styles/common/TimeTable.module.scss";
import {usePharmacyHoursHook} from "../hooks/usePharmacyHoursHook";
import styled from "styled-components";
import {PharmacyHours} from "../../../../interfaces";


interface Props {
    onChanged: (data: PharmacyHours) => void,
}

export const PharmacyRegisterTime = ({onChanged}: Props) => {
    const tx = classNames.bind(timeTableStyle);
    const {
        defaultResult,
        data,
        onDefaultTimeChanged,
    } = usePharmacyHoursHook();

    useEffect(() => {
        onChanged(data);
    }, [data]);

    return (
        <Wrapper>
            <div className={tx('time-table-head')}>
                <div>요일</div>
                <div>진료시작</div>
                <div>진료종료</div>
                <div>휴무일 여부</div>
            </div>
            <div>
                {
                    defaultResult.map((item, index) => (
                        <WorkHourRow key={index}
                                     dayOfWeekNumber={item.dayOfWeek}
                                     hours={item.hours}
                                     breakHoursList={item.breakHoursList}
                                     isOff={item.isOff}
                                     onChange={onDefaultTimeChanged}
                                     isEdit={true}
                        />
                    ))
                }
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 16px;
`;
