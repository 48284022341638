import { Statistics, StatisticsInfo } from "@api/models/calculate/calculate";
import { DoctorOnAPI } from "@api/request";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import { useNavbarHooks } from "@components/layout/headerContainer/Header/Navbar/hooks/useNavbarHooks";
import calculateListYearExcelDown from "../../Operator/UnospayDashBoardPage/components/ExcelDownloadButton/components/calculateListYearExcelDown";
import { FaFileExcel } from "react-icons/fa";
import { Pie } from "@nivo/pie";
import PieChart from "@components/chart/pieChart";
import MyResponsivePie from "@components/chart/nivoChart";

interface FontSize {
  size: number;
}

interface ContainerStyle {
  height: number;
  overflow?: string;
}

interface Repeat {
  width?: number;
  rows?: number;
  columns?: number;
  justify_div?: string;
  justify_span?: string;
}

interface Article {
  flex: string;
  gap?: number;
  justifyContent?: string;
}

interface BarStyle {
  height?:number;
}

interface LayoutStyle{
  width?: string;
}

interface ArticleItemSubLayoutStyle {
  padding?: string;
}

const DoctorCalculateBoardPage = () => {
  const [statistics, setStatistics] = useState<Statistics[]>();
  const [statisticsInfo, setStatisticsInfo] = useState<StatisticsInfo>();
  const { cx, oauthList, selectedUser, navigate, onClickLogout, GetUserList } = useNavbarHooks();

  const getPrevMonth = (monthData?: string) => {
    if (monthData) {
      const year = moment().year();
      const month = monthData;
      const date = moment(`${year}-${month}`).format("YYYY-MM-DD");

      const startDate = moment(date).format("YYYY .MM .DD");
      const endDate = moment(date).endOf("month").format("YYYY .MM .DD");

      return `${startDate} ~ ${endDate}`;
    }
  };

  const handleClickYear = () => {
    calculateListYearExcelDown("", _.orderBy(statistics, "month"));
  };

  const formattingData = (list:Statistics[] | undefined) => {
    if(list){

    }
  }

  const getCalculateDetailData = async () => {
    try {
      if (!_.isUndefined(selectedUser!.user)) {
        const code = selectedUser!.user.email.split("@")[0];
        const response = await DoctorOnAPI.shared.calculate.getOrderStatisticsDataMonth(
          code.toLocaleUpperCase()
        );
        if (response.data) {
          const monthFiltered = response.data.statistics.filter((item) => {
            if (new Date().getFullYear() === 2024) {
              return Number(item.month) > 6 && item;
            } else {
              return item;
            }
          });
          setStatistics(monthFiltered);
          setStatisticsInfo(response.data.statisticsInfo);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCalculateDetailData();
  }, [selectedUser]);

  return (
    <Wrapper>
     
      <Body>
        <Nav>
          <TabItem
          >
            정산정보
          </TabItem>
        </Nav>
        <Article>
          <Layout>
            <ArticleRow flex="column" gap={30}>
              <ArticleColumn flex="row" gap={30}>
                <ArticleItemLayout>
                  <ArticleItemTitle>정산정보</ArticleItemTitle>
                  <ArticleItemSubLayout padding='20px 0px'>
                  <ArticleItem>
                    <span>은행명</span>
                    {statisticsInfo?.bank ? <h6>{statisticsInfo?.bank}</h6> : <p>등록된 데이터가 없습니다.</p>}
                  </ArticleItem>
                  <Bar height={statisticsInfo ? 70 : 30} />
                  <ArticleItem>
                    <span>계좌번호</span>
                    {statisticsInfo?.bankAccount ? <h6>{statisticsInfo?.bankAccount}</h6> : <p>등록된 데이터가 없습니다.</p>}
                  </ArticleItem>
                  <Bar height={statisticsInfo ? 70 : 30}/>
                  <ArticleItem>
                    <span>예금주</span>
                    {!_.isUndefined(statisticsInfo) ? <h6>{statisticsInfo.bankHolderName}</h6> : <p>등록된 데이터가 없습니다.</p>}
                  </ArticleItem>
                </ArticleItemSubLayout>
                </ArticleItemLayout>
                <ArticleItemLayout>
                  <ArticleItemTitle>공지사항</ArticleItemTitle>
                  <ArticleItemSubLayout padding='20px 35px'>
                    <div>{statisticsInfo?.memo ? statisticsInfo?.memo : '등록된 데이터가 없습니다.'}</div>
                  </ArticleItemSubLayout>
                </ArticleItemLayout>
              </ArticleColumn>
            </ArticleRow>
            <ArticleRow flex="column" gap={30}>
              <ArticleColumn flex="row" justifyContent='space-between'>
                <SubTitle size={20}>매출 및 정산 금액</SubTitle>
                <ExcelBtn onClick={handleClickYear}>
                  <FaFileExcel />
                  다운로드
                </ExcelBtn>
              </ArticleColumn>
              <Container height={80}>
                <ArticleColumn flex="column" gap={30}>
                  <Grid width={100} columns={3} justify_div="center">
                    <div>기간</div>
                    <div>월별 매출액</div>
                    <div>월별 정산 금액</div>
                  </Grid>
                  <Container height={90} overflow="scroll">
                    {!_.isUndefined(statistics) && statistics.length > 0 ? (
                      _.orderBy(statistics, "month").map((item) => {
                        return (
                          <LinkStyle
                            to={`/calculateMonthDetail`}
                            state={{
                              hospitalId: statisticsInfo?.hospitalId,
                              month: item.month,
                            }}
                          >
                            <Grid columns={3}>
                              <span>{getPrevMonth(item.month)}</span>
                              <span>{item.totalPrice.toLocaleString()}</span>
                              <span>{item.totalHospitalCalPrice.toLocaleString()}</span>
                            </Grid>
                          </LinkStyle>
                        );
                      })
                    ) : (
                      <Grid justify_span="center">
                        <span>데이터 없음</span>
                      </Grid>
                    )}
                  </Container>
                </ArticleColumn>
              </Container>
            </ArticleRow>
          </Layout>
          <Layout>
            {/* <MyResponsivePie data={formattingData(statistics)} /> */}
          </Layout>
        </Article>
      </Body>
    </Wrapper>
  );
};

export default DoctorCalculateBoardPage;

const Wrapper = styled.div`
  min-width: 1024px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`;
const Body = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;

  @media (max-width: 1024px) {
    width: 65%;
  }

  @media (max-width: 1280px) and (min-width: 1024px) {
    width: 85%;
  }

`;

const Nav = styled.div`
  width: 95%;
  display: flex;
`;

const Article = styled.div`
  width: 95%;
  display: grid;
  grid-template-columns: 1100px auto;

  @media (max-width: 1024px) {
    width: 80%;
    display: grid;
    grid-template-columns: 100% auto;
  }
  @media (max-width: 1280px) and (min-width: 1024px) {
    width: 95%;
    display: grid;
    grid-template-columns: 600px auto;
  }
  @media (max-width: 1366px) and (min-width: 1280px) {
    width: 95%;
    display: grid;
    grid-template-columns: 700px auto;
  }
  @media (max-width: 1420px) and (min-width: 1366px) {
    width: 95%;
    display: grid;
    grid-template-columns: 800px auto;
  }
  @media (max-width: 1600px) and (min-width: 1420px) {
    width: 95%;
    display: grid;
    grid-template-columns: 1000px auto;
  }
`

const TabItem = styled.div`
  width: 150px;
  padding: 25px 0px;
  font-size: 20px;
  font-weight: 700;
  background: #ffffff;
  color: #1c6fc9;
  border-top: none;
  border-left: none;
  border-right: none; 
  &:first-child {
    border-top-left-radius: 30px;
  }
`;

const Layout = styled.div<LayoutStyle>`
  width: ${props => props.width}%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  row-gap: 30px;
`;

const LinkStyle = styled(Link)`
  color: black;
  text-decoration: none;
  &:hover,
  &:focus {
    font-weight: bold;
  }
`;


const SubTitle = styled.span<FontSize>`
  font-size: ${(props) => props.size}px;
  font-weight: bold;
`;

const ArticleRow = styled.div<Article>`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.flex};
  row-gap: ${(props) => props.gap}px;
`;

const Container = styled.div<ContainerStyle>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height}%;
  overflow: ${(props) => props.overflow};
`;

const ArticleColumn = styled.div<Article>`
  display: flex;
  flex-direction: ${(props) => props.flex};
  column-gap: ${(props) => props.gap}px;
  justify-content: ${(props) => props.justifyContent};
`;

const ArticleItemLayout = styled.div`
  width: 100%;
  border-radius: 15px;
  border: 1px solid #e9ecef;
`;
const ArticleItemTitle = styled.div`
  background-color: #edf2f7;
  height: 50px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  align-items: center;
  padding: 20px 35px;
  font-weight: bold;
  font-size: 16px;
`;

const ArticleItemSubLayout = styled.div<ArticleItemSubLayoutStyle>`
  padding: ${props => props.padding};
  display: flex;
  justify-content: space-between;
`;

const ArticleItem = styled.div`
  width: 100%;
  text-align: center;

  p,h6{
    padding-top: 20px;
  }
`;

const Bar = styled.div<BarStyle>`
  width: 1px;
  height: ${props => props.height}px;
  background: #DBDCDF;
`;

const ExcelBtn = styled.button`
  width: 100px;
  height: 35px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;

  svg {
    color: #2176f6;
    font-size: 18px;
  }

  :hover {
    background-color: #2176f6;
    color: #ffffff;
    font-weight: 700;
    svg {
      color: #ffffff;
    }
  }
`;

const Grid = styled.div<Repeat>`
  width: ${(props) => `${props.width}%`};
  display: grid;
  grid-template-rows: ${(props) => `repeat(${props.rows}, 1fr)`};
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};

  div {
    padding: 10px;
    border: 1px solid #e9ecef;
    background: #f2f7fc;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify_div};
  }
  span {
    padding: 10px;
    border: 1px solid #e9ecef;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify_span};
  }
`;
