import {IOoDiagnosis, OoMedicineDeliveryType} from "@api/index";
import _ from "lodash";
import styled from "styled-components";

interface Props {
    diagnosis: IOoDiagnosis,
}

export const KakakoTest = ({diagnosis}: Props) => {
    return (
        <Wrapper>
            {
                _.isEqual(diagnosis.deliveryType, OoMedicineDeliveryType.kaKaoMobility)
                &&
                <>
                    <div className="title">카카오 테스트 기능</div>
                    <ButtonContainer>
                        <button className="button">배정 완료</button>
                        <button className="button">픽업 시작</button>
                        <button className="button">픽업 완료</button>
                        <button className="button">배송 시작</button>
                        <button className="button">배송 완료</button>
                    </ButtonContainer>
                </>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100%;

  .title {
    font-weight: 700;
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  padding-top: 8px;

  .button {
    width: 140px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D3D9E4;
    border-radius: 8px;
    font-weight: 700;
    font-size: 13px;
    margin-right: 8px;

    &:hover {
      border: 1px solid #2176F7;
    }
  }
`;