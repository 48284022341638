import React from "react";
import classNames from "classnames/bind";
import buttonStyles from "../../../../../../styles/common/Button.module.scss";
import {IOoDiagnosis, useFilteredReservationStatus} from "@api/models";
import styled from "styled-components";

interface Props {
    list: IOoDiagnosis,
    flex?: number,
}

export const ReservationType = ({list, flex}: Props) => {
    const bx = classNames.bind(buttonStyles);

    const {
        filteredReservationStatus
    } = useFilteredReservationStatus(list);

    return (
        <Wrapper flex={flex ?? 0}>
            {
                filteredReservationStatus().includes('예약') ?
                    <button className={bx('reservation-button')}
                            value={`${list.id}`}>
                        {filteredReservationStatus()}
                    </button>
                    :
                    <button className={bx('not-reservation-button')}
                            value={`${list.id}`}>
                        바로 접수
                    </button>

            }
        </Wrapper>
    )
}

const Wrapper = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex}
`;
