import styled from "styled-components";

interface Props {
  children: string;
  bgColor: string;
  color: string;
  id: string;
}

interface CardContainerProps {
  bgColor: string;
}

export const CustomStateCard = ({ children, color, id, bgColor }: Props) => {
  return (
    <CardContainer color={color} bgColor={bgColor} id={id}>
      {children}
    </CardContainer>
  );
};

const CardContainer = styled.div<CardContainerProps>`
  width: 80px;
  height: 24px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border-radius: 4px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  id: ${(props) => props.id};
  justify-content: center;
  display: flex;
  line-height: 24px;
  flex-shrink: 0;
`;
