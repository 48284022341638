import {useQuery} from "react-query";
import {DoctorOnAPI, isRequestSucceed} from "../../api";
import {useSuspenseHook} from "../../hooks";
import {OoParamWapleEnableSlot} from "@api/params";

interface Props {
    patientId: string,
    doctorId: string,
    hospitalId: string,
    reservationType: number,
    reservationDate: string,
    updateQueryTrigger: boolean,
}

export const useEnabledSlotQuery = ({
                                        patientId,
                                        doctorId,
                                        hospitalId,
                                        reservationType,
                                        reservationDate,
                                        updateQueryTrigger
                                    }: Props) => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();

    return useQuery(
        'reservation/getEnabledSlot',
        async () => {
            try {
                setLoading();
                const param = new OoParamWapleEnableSlot();
                param.patientId = patientId;
                param.doctorId = doctorId;
                param.hospitalId = hospitalId;
                param.reservationType = reservationType;
                param.reservationDate = reservationDate;
                const response = await DoctorOnAPI.shared.reservation.getEnableSlot(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        }, {
            enabled: updateQueryTrigger,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )
}