import {firebase, realtimeDatabase} from "../../firebaseConfig";
import _ from "lodash";
import {IOoDiagnosis} from "@models/diagnosis";

export class DiagnosisRtdbSubscriber {
    private static instance: DiagnosisRtdbSubscriber;
    private TAG = 'DiagnosisRtdbSubscriber';
    private ref?: firebase.database.Reference = undefined;
    private dataList: Array<IOoDiagnosis> = [];

    private constructor() {
    }

    static shared() {
        if (_.isEmpty(DiagnosisRtdbSubscriber.instance)) {
            DiagnosisRtdbSubscriber.instance = new DiagnosisRtdbSubscriber();
        }
        return DiagnosisRtdbSubscriber.instance;
    }

    getOriginList(): Array<IOoDiagnosis> {
        return this.dataList;
    }

    subscribe(path: string,
              onValue: OnDiagnosisDataListListener) {

        if (!_.isUndefined(this.ref)) {
            console.log(this.TAG, ':subscribe', ' =====>> already connected! ');
            return;
        }

        if (_.isEmpty(path)) {
            this.unsubscribe();
            console.log(this.TAG, ':subscribe', ' =====>> path is empty! ');
            return;
        }

        this.dataList = new Array<IOoDiagnosis>();
        this.ref = realtimeDatabase.ref(path);

        this.ref.on('value', snapshot => {
            _.chain(snapshot.val())
                .values()
                .map((d) => d as IOoDiagnosis)
                .each(d => this.dataList.push(d))
                .value();
            onValue(this.dataList);

            this.ref?.off('value');
            this.subscribeIndividual(onValue);
        });
    }

    unsubscribe() {
        this.ref?.off();
        this.ref = undefined;
    }

    private getDiagnosisIndex(diagnosis: IOoDiagnosis) {
        return _.findIndex(this.dataList, (d) => _.isEqual(d.id, diagnosis.id));
    }

    private subscribeIndividual(onValue: OnDiagnosisDataListListener) {

        this.ref?.on('child_added', snapshot => {
            const data = snapshot.val() as IOoDiagnosis;
            const index = this.getDiagnosisIndex(data);
            if (index >= 0) {
                return;
            }

            this.dataList.push(data);
            onValue(this.dataList);
        });

        this.ref?.on('child_changed', snapshot => {
            const data = snapshot.val() as IOoDiagnosis;
            const index = this.getDiagnosisIndex(data);
            if (index < 0) {
                return;
            }

            this.dataList[index] = data;
            onValue(this.dataList);
        });

        this.ref?.on('child_removed', snapshot => {
            const data = snapshot.val() as IOoDiagnosis;
            const index = this.getDiagnosisIndex(data);
            if (index < 0) {
                return;
            }
            this.dataList.splice(index, 1);
            onValue(this.dataList);
        });
    }
}

export type OnDiagnosisDataListListener = (diagnosis: Array<IOoDiagnosis>) => void;