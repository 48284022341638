import {useRecoilState} from "recoil";
import _, {findIndex} from "lodash";
import {useNavigate} from "react-router-dom";
import {formatTel} from "../format";
import {toast} from "react-toastify";
import {hospitalListState} from "@recoils/display";
import {useHospitalCreateQuery, useHospitalDeleteQuery} from "@queries/operator";
import {IOoHospital} from "@models/hospital";
import {OoParamHospitalCreate, OoParamHospitalUpdate} from "@params/hospital";
import {useHospitalListQuery, useHospitalUpdateQuery} from "@queries/hospital";

export const useHospitalFunctionHook = () => {
    const [hospitalList, setHospitalList] = useRecoilState(hospitalListState);
    const navigate = useNavigate();

    useHospitalListQuery();
    const createHospitalResp = useHospitalCreateQuery();
    const updateHospitalResp = useHospitalUpdateQuery();
    const deleteHospitalResp = useHospitalDeleteQuery();

    // 병원 리스트에서 병원 이름 정보 가져오기
    const getHospitalName = (id: string) => {
        const result = hospitalList.filter((item) => _.isEqual(item.id, id));
        return _.isEmpty(result) ? "병원 이름 없음" : _.head(result)!.name;
    };

    // 병원 리스트에서 병원 전화번호 정보 가져오기
    const getHospitalTel = (id: string) => {
        const result = hospitalList.filter((item) => _.isEqual(item.id, id));
        return _.isEmpty(result)
            ? "-"
            : _.includes(_.head(result)!.tel, "-")
                ? _.head(result)!.tel
                : formatTel(_.head(result)!.tel);
    };

    // 리코일에서 병원 정보 찾기
    const getHospitalById = (id: string): IOoHospital => {
        return _.chain(hospitalList)
            .filter((e: IOoHospital) => _.isEqual(e.id || "", id))
            .head()
            .value();
    };

    const getHospitalAddress = (code: string) => {
        // console.log('code', code.toUpperCase());
        // const result = hospitalList.filter((item) =>    _.isEqual(item.code, code));

        let result;
        hospitalList.forEach((e) => {
            if(e.code == code.toUpperCase()){
                result = e.address;
            }
        });

        // console.log('result', result);

        return result;

        // console.log(JSON.stringify(result));
        // return _.isEmpty(result)
        //     ? "-"
        //     : _.head(result)!.address;
    };

    const getHospitalSplitAddress = (code: string): Array<string> | undefined => {
        let result;
        hospitalList.forEach((e) => {
            if(e.code == code.toUpperCase()){
                result = e.regionKeywordList;
            }
        });
        return result;
    };

    const getHospitalType = (code: string): number | undefined => {
        let result;
        hospitalList.forEach((e) => {
            if(e.code == code.toUpperCase()){
                result = e.type;
            }
        });
        return result;
    };

    const getHospitalReferrer = (code: string): number | undefined => {
        let result;
        hospitalList.forEach((e) => {
            if(e.code == code.toUpperCase()){
                result = e.referrer;
            }
        });
        return result;
    };

    // 병원 정보 생성
    const createHospital = (param: OoParamHospitalCreate) => {
        createHospitalResp.mutate(param, {
            onSuccess: (data: IOoHospital | undefined) => {
                if (_.isUndefined(data)) {
                    toast.error("병원 정보 생성 실패");
                    return;
                }
                toast.success("병원 정보 생성 완료");
                const copied = _.cloneDeep(hospitalList);
                navigate("/hospital");
                setHospitalList(_.concat(copied, data));
            },
        });
    };

    // 병원 정보 수정
    const updateHospital = (param: OoParamHospitalUpdate) => {
        updateHospitalResp.mutate(param, {
            onSuccess: (data: IOoHospital | undefined) => {
                toast.success("병원 정보 수정 완료");
                if (_.isUndefined(data)) {
                    return;
                }
                const copied = _.cloneDeep(hospitalList);
                const index = findIndex(copied, (item) => _.isEqual(item.id, data.id));
                if (_.gte(index, 0)) {
                    copied[index] = data;
                    setHospitalList(copied);
                }
            },
        });
    };

    // 병원 정보 삭제
    const deleteHospital = (id: string) => {
        deleteHospitalResp.mutate(id, {
            onSuccess: (data: string | undefined) => {
                toast.success("병원 정보 삭제 완료");
                if (_.isUndefined(data)) {
                    return;
                }
                const copied = _.cloneDeep(hospitalList);
                const index = findIndex(copied, (item) => _.isEqual(item.id, id));
                copied.splice(index, 1);
                if (_.gte(index, 0)) {
                    navigate("/hospital");
                    setHospitalList(copied);
                }
            },
        });
    };

    return {
        hospitalList,
        setHospitalList,
        getHospitalById,
        updateHospital,
        createHospital,
        deleteHospital,
        getHospitalName,
        getHospitalTel,
        getHospitalAddress,
        getHospitalSplitAddress,
        getHospitalType,
        getHospitalReferrer
    };
};
