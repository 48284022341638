import {IOoDiagnosis} from "./diagnosis";

interface PharmacyState {
    pharmacyName?: string,
    pharmacyTel?: string,
    pharmacyFax?: string,
    pharmacyAddress?: string,
    medicinePrice?: number,
    faxResult?: number,
    deliveryType?: number,


}

export function diagnosisPharmacyState(diagnosis: IOoDiagnosis): PharmacyState {
    return {
        pharmacyName: diagnosis.pharmacyName,
        pharmacyTel: diagnosis.pharmacyTel,
        pharmacyFax: diagnosis.pharmacyFax,
        pharmacyAddress: diagnosis.pharmacyAddress,
        medicinePrice: diagnosis.medicinePrice,
        faxResult: diagnosis.faxResult,
        deliveryType: diagnosis.deliveryType,
    }
}

export type {PharmacyState};