import {AbstractOoParam} from "@api/params";

class OoParamId extends AbstractOoParam {
    constructor(public readonly id: string) {
        super();
    }

    getPathParams(): string {
        return `${this.id}`;
    }
}

export {
    OoParamId,
};


