import {useState} from "react";
import {PharmacyBankInfo} from "../../../../interfaces";

export const useBankInfoHook = () => {
    const [items, setItems] = useState<PharmacyBankInfo>({
        bank: '',
        bankAccount: '',
        bankHolderName: '',
    });

    const onChangeValue = (e: any) => {
        const {name, value} = e.target;
        setItems({...items, [name]: value});
    }

    return {
        items,
        onChangeValue,
    }
}