import React, {useEffect} from "react";
import doctorInitializedInfo from "../../../../assets/doctorInitialInfo.json";
import buttonStyles from '../../../../styles/common/Button.module.scss';
import classNames from "classnames/bind";
import styled from "styled-components";
import {DoctorServiceInfo} from "interfaces";
import {useDepartmentsSettingHook} from "@components/registerForm";
import { OoParamDoctorCreate } from "@api/params";

interface Props {
    onChanged: (data: DoctorServiceInfo) => void,
    param:OoParamDoctorCreate
}

export const DoctorRegisterDepartments = ({onChanged,param}: Props) => {

    const bx = classNames.bind(buttonStyles);
    const {
        data,
        onCheckedDepartments,
        isEqualDepartments
    } = useDepartmentsSettingHook();

    useEffect(() => {
        onChanged(data);
    }, [data]);

    return (
        <Wrapper>
            {
                doctorInitializedInfo.departments.map((item, index) => (
                    <div key={index}>
                        <input
                            id={`${item.subject}`}
                            type="checkbox"
                            value={item.subject}
                            onChange={onCheckedDepartments}
                            checked={param.departments.find(p => p === item.subject) ? true : false}
                            hidden={true}
                        />
                        <label htmlFor={`${item.subject}`} className={bx('checkbox-label')}>
                            <div>{item.subject}</div>
                        </label>
                    </div>
                ))
            }
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
`;