import {AbstractOoParam} from "../base";
import _ from "lodash";

class OoParamKakaoDevOpenOrderId extends AbstractOoParam {

    //오픈오더 아이디
    public orderId: string = '';

    public diagnosisId?: string = '';

    constructor(orderId: string, diagnosisId: string) {
        super();
        this.orderId = orderId;
        this.diagnosisId = diagnosisId;
    }

    getPathParams(): string {
        return _.isUndefined(this.diagnosisId) ? `${this.orderId}` : `${this.orderId}/${this.diagnosisId}`
    }
}

export {OoParamKakaoDevOpenOrderId}