import {baseUrl, HttpClient, IOoAuthorizationHeader} from "./base";
import {DoctorOnDoctorApi} from "./doctor";
import {DoctorOnAuthApi} from "./oauth";
import {DoctorOnHospitalApi} from "./hospital";
import {DoctorOnPharmacyApi} from "./pharmacy";
import {DoctorOnAccountApi} from "./account";
import {DoctorOnFamilyTelemedicineApi} from "./familyTelemedicine";
import {DoctorOnTelemedicineApi} from "./telemedicine";
import {DoctorOnReservationAPI} from "./reservation";
import {DoctorOnKakaoMobilityAPI} from "./kakaoMobility";
import {DoctorOnPaypleApi} from "./payple";
import {DoctorOnNotificationAPI} from "./pushNotification";
import {DoctorOnUnospayApi} from "./unospay";
import { DoctorOnCalculateApi } from "./calculate";
import { ProductSettingApi } from "./productSetting";

class DoctorOnAPI {
    private static instance: DoctorOnAPI;
    readonly auth: DoctorOnAuthApi;
    /**
     * API EndPoint
     *
     * dev: 개발서버
     * prod: 실서버
     */
    private readonly baseURL = baseUrl.prod;

    private constructor() {
        DoctorOnAPI.instance = this;

        const client = new HttpClient(this.baseURL, new DoctorOnAPIKeyAuthorization());
        this.auth = new DoctorOnAuthApi(client.instance);
    }

    static get shared() {
        if (!DoctorOnAPI.instance) {
            DoctorOnAPI.instance = new DoctorOnAPI();
        }
        return this.instance;
    }

    private _telemedicine?: DoctorOnTelemedicineApi;

    get telemedicine(): DoctorOnTelemedicineApi {
        return this._telemedicine!;
    }

    private _familyTelemedicine?: DoctorOnFamilyTelemedicineApi;

    get familyTelemedicine(): DoctorOnFamilyTelemedicineApi {
        return this._familyTelemedicine!;
    }

    private _account?: DoctorOnAccountApi;

    get account(): DoctorOnAccountApi {
        return this._account!;
    }

    private _doctor?: DoctorOnDoctorApi;

    get doctor(): DoctorOnDoctorApi {
        return this._doctor!;
    }

    private _hospital?: DoctorOnHospitalApi;

    get hospital(): DoctorOnHospitalApi {
        return this._hospital!;
    }
    private _calculate?: DoctorOnCalculateApi;

    get calculate(): DoctorOnCalculateApi {
        return this._calculate!;
    }
    private _productSetting?: ProductSettingApi;

    get productSetting(): ProductSettingApi {
        return this._productSetting!;
    }

    private _pharmacy?: DoctorOnPharmacyApi;

    get pharmacy(): DoctorOnPharmacyApi {
        return this._pharmacy!;
    }

    private _reservation?: DoctorOnReservationAPI;

    get reservation(): DoctorOnReservationAPI {
        return this._reservation!;
    }

    private _kakaoMobility?: DoctorOnKakaoMobilityAPI;

    get kakaoMobility(): DoctorOnKakaoMobilityAPI {
        return this._kakaoMobility!;
    }

    private _payple?: DoctorOnPaypleApi;

    get payple(): DoctorOnPaypleApi {
        return this._payple!;
    }

    private _pushNotification?: DoctorOnNotificationAPI;

    get pushNotification(): DoctorOnNotificationAPI {
        return this._pushNotification!;
    }

    private _unospay?: DoctorOnUnospayApi;

    get unospay(): DoctorOnUnospayApi {
        return this._unospay!;
    }

    setBearerToken(bearerToken: string) {
        const client = new HttpClient(this.baseURL, new DoctorOnBearerAuthorization(bearerToken));

        this._account = new DoctorOnAccountApi(client.instance);
        this._doctor = new DoctorOnDoctorApi(client.instance);
        this._hospital = new DoctorOnHospitalApi(client.instance);
        this._pharmacy = new DoctorOnPharmacyApi(client.instance);
        this._calculate = new DoctorOnCalculateApi(client.instance);
        this._productSetting = new ProductSettingApi(client.instance);
        this._familyTelemedicine = new DoctorOnFamilyTelemedicineApi(client.instance);
        this._telemedicine = new DoctorOnTelemedicineApi(client.instance);
        this._reservation = new DoctorOnReservationAPI(client.instance);
        this._kakaoMobility = new DoctorOnKakaoMobilityAPI(client.instance);
        this._payple = new DoctorOnPaypleApi(client.instance);
        this._pushNotification = new DoctorOnNotificationAPI(client.instance);
        this._unospay = new DoctorOnUnospayApi(client.instance);
    }
}

class DoctorOnAPIKeyAuthorization implements IOoAuthorizationHeader {
    authorization(): string {
        return 'key:ko4wQEwYmKuKbTFWLDdG3G';
    }
}

class DoctorOnBearerAuthorization implements IOoAuthorizationHeader {
    private readonly headerAuthorization: string;

    constructor(bearerToken: string) {
        this.headerAuthorization = `Bearer ${bearerToken}`;
    }

    authorization(): string {
        return this.headerAuthorization;
    }
}

export {
    DoctorOnAPI
};
