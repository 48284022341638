import React, { useEffect, useState } from 'react'
import {ResponsivePie} from '@nivo/pie'
import { theme } from '@styles/theme';
import { IOoCalculateMonthPaymentData } from '@api/models/calculate/calculateMonthPaymentData';

interface Props {
    list: IOoCalculateMonthPaymentData[]
}

const PieChart = ({list}:Props) => {
  const [pieData, setPieData] = useState<{id: string;value: number}[]>([]);
  const [legendsData, setLegendData] = useState<{
    id: string | number;
    label: string | number;
    hidden?: boolean;
    color?: string;
    fill?: string;}[]>([]);

    const dataFormatting = () => {
      const colors = ['#e8c1a0' , '#f47560', '#f1e15b' , '#e8a838']
      const formatData = list.map((item, i) => ({id:`${item.typeStr} (${item.totalCount}건)`, value:item.totalPrice}));
      const legendsFormatData = list.map((item, i) => ({id:item.typeStr, label:`${item.typeStr} (${item.totalCount}건) ${item.totalPrice.toLocaleString()}원` , color:colors[i]}));
      setPieData(formatData);
      setLegendData(legendsFormatData)
    }

  useEffect(() => {
    dataFormatting();
  },[list])

  return (
    <ResponsivePie data={pieData} activeOuterRadiusOffset={8} animate 
    theme={{legends:{text:{fontSize: 16}},labels:{text:{fontSize:16, fontWeight: 'bold'}}}}
    colors={{scheme:'nivo'}}
    legends={[
      {
        data:legendsData,
        anchor: "right",
        direction: 'column',
        translateX:-150,
        itemHeight: 50,
        itemTextColor: '#000000',
        itemWidth: 100,
        symbolShape: 'circle',
        symbolSize: 20,
      }
    ]}
    fit={true}
    arcLabel={''}
    enableArcLinkLabels={true}
    arcLinkLabel={(d) => `${d.id}`}
    margin={{
      bottom: 30,
      left: -250,
      right: 0,
      top: 30
        }}
    />
  )
}

export default PieChart