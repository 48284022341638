import React, {useEffect} from "react";
import styled from "styled-components";
import _ from "lodash";
import {OoParamDoctorUpdate} from "@params/doctor";
import {CustomInputText} from "@components/custom";
import {IOoDoctor} from "@models/doctor";
import {useDoctorInfoSettingHook} from "@components/infoForm";
import {ImageUpload} from "@components/imageUpload";

interface Props {
    data: IOoDoctor,
    componentName: string,
}

export const DoctorInfo = ({data, componentName}: Props) => {
    const {
        editStatus,
        color,
        setColor,
        items,
        setItems,
        onChangeMobile,
        onChangePicture,
        updateDoctor,
        onChangeCheckBox,
        isReset,
    } = useDoctorInfoSettingHook(data, componentName);

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        setItems({
            mobile: data.mobile,
            pictures: data.pictures,
            isCalculateAccount: data.isCalculateAccount
        })
    }, [data]);

    // 편집 모드 일때, border 색상 활성화
    useEffect(() => {
        if (editStatus.isEdit) {
            setColor("blue");
        } else {
            setColor("white");
            setItems({
                mobile: data.mobile,
                pictures: data.pictures,
                isCalculateAccount: data.isCalculateAccount
            });
        }
    }, [editStatus.isEdit]);

    // 전송 모드 일때, 쿼리 trigger
    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamDoctorUpdate();
            param.id = data.id || "";
            param.mobile = items.mobile;
            param.pictures = items.pictures;
            param.isCalculateAccount = items.isCalculateAccount;
            updateDoctor(param);
        }
    }, [editStatus.isSubmit]);
    
    
    return (
        <Wrapper>
            <div>
                <div>
                    <Table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>이름</td>
                            <td>
                                <CustomInputText value={data.name}
                                                 size="middle"
                                                 color={"white"}
                                                 placeholder="[필수]"
                                                 disabled={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td>이메일</td>
                            <td>
                                <CustomInputText value={data.email}
                                                 color={"white"}
                                                 size="middle"
                                                 placeholder="아이디@doctoron.kr [필수]"
                                                 disabled={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td>핸드폰</td>
                            <td>
                                {editStatus.isEdit ? 
                                    <CustomInputText value={items.mobile}
                                        onChanged={onChangeMobile}
                                        color={color}
                                        size="middle"
                                        placeholder="8자리 이상 [필수]"
                                        disabled={editStatus.isEdit}/> 
                                    : 
                                        <CustomInputText value={data.mobile}
                                        onChanged={onChangeMobile}
                                        color={color}
                                        size="middle"
                                        placeholder="8자리 이상 [필수]"
                                        disabled={editStatus.isEdit}/>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>정산용 확인여부</td>
                            <td>
                            {editStatus.isEdit ? 
                                    <CheckboxContainer>
                                        <label htmlFor="calculateAccount_Y"><input type="checkbox" id="calculateAccount_Y" value={1} onChange={onChangeCheckBox} checked={items.isCalculateAccount} /><span>Y</span></label>
                                        <label htmlFor="calculateAccount_N"><input type="checkbox" id="calculateAccount_N" value={0} onChange={onChangeCheckBox} checked={!items.isCalculateAccount}/><span>N</span></label>
                                    </CheckboxContainer>
                                    : 
                                    <CheckboxContainer>
                                        <label htmlFor="calculateAccount_Y"><input type="checkbox" id="calculateAccount_Y" value={1} onChange={onChangeCheckBox} checked={data.isCalculateAccount} disabled={!editStatus.isEdit}/><span>Y</span></label>
                                        <label htmlFor="calculateAccount_N"><input type="checkbox" id="calculateAccount_N" value={0} onChange={onChangeCheckBox} checked={!data.isCalculateAccount} disabled={!editStatus.isEdit}/><span>N</span></label>
                                    </CheckboxContainer>
                                }
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
                <ImageContainer>
                    <ImageUpload target="doctor"
                                 title="doctorProfile"
                                 picturesSrc={data.pictures[0]}
                                 editStatus={editStatus}
                                 hiddenRemoveButton={true}
                                 onChanged={onChangePicture}/>
                </ImageContainer>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }

`;

const ImageContainer = styled.div`
  padding-left: 50px;
`;

const Table = styled.table`
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #C8D6F8;
    height: 50px;
  }

  td:nth-child(2n-1) {
    background: #F4F6FB;
    width: 100px;
    text-align: center;
    font-weight: 700;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding: 2px;
    font-size: 13px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-between;

  & > label {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > span {
      margin-left: 5px;
    }
  }
`;