import React, {useEffect} from "react";
import "react-calendar/dist/Calendar.css";
import {CustomCalendar} from "@components/custom";
import {isEqual} from "lodash";
import {useCalendarSettingHook} from "@components/calendar/useCalendarSettingHook";
import moment from "moment";

interface Props {
    target: string,
    id?: string,
}

export const DiagnosisCalendar = ({target, id}: Props) => {
    const {
        modalVisible,
        setModalVisible,
        todayDate,
        setTodayDate,
        onShowCalendar,
        onClickDate,
        dailyDiagnosisSelectedDate,
        setDailyDiagnosisSelectedDate,
        onChangeDate,
        handleTodayButton,
        filteredTarget,
        diagnosisList,
        setDiagnosisList,
        resetCalendarParam,
    } = useCalendarSettingHook({target: target, id: id});

    useEffect(() => {
        setDiagnosisList([]);
    }, []);

    useEffect(() => {
        setModalVisible(false);
        if (isEqual(diagnosisList.length, 0)) {
            setTodayDate([moment().toDate(), moment().toDate()]);
            setDailyDiagnosisSelectedDate({
                id: target,
                date: [moment().format(`YYYY.MM.DD`), moment().format(`YYYY.MM.DD`)]
            });
            resetCalendarParam();
            return;
        }
    }, [diagnosisList]);

    return (
        <CustomCalendar visible={modalVisible}
                        setVisible={setModalVisible}
                        onChangeDate={onChangeDate}
                        value={[todayDate[0], todayDate[1]]}
                        onClickDate={onClickDate}
                        onShowCalendar={onShowCalendar}
                        selectedStartDay={dailyDiagnosisSelectedDate.date[0]}
                        selectedEndDay={dailyDiagnosisSelectedDate.date[1]}
                        handleTodayDate={handleTodayButton}
        />
    );
};