import {useDoctorConnectHook, useDoctorFunctionHook} from "@hooks/doctor";
import {useMemo} from "react";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {doctorCalendarParamState, doctorFindInfoState, doctorRtdbState} from "@recoils/doctor";
import {selectedUserState} from "@recoils/oauth";
import {useDoctorFindQuery} from "@queries/doctor";
import {dailyDiagnosisListState, dailyDiagnosisSelectedState} from "@recoils/telemedicine";
import _ from "lodash";
import {searchListState} from "@recoils/search";

export const useDashBoardSettingHook = () => {
    const target = 'doctor';

    const {connect, disconnect} = useDoctorConnectHook();
    const {doctorList, getDoctorById} = useDoctorFunctionHook();

    const [queryTrigger, setQueryTrigger] = useRecoilState(doctorFindInfoState);

    const dailyDiagnosisSelectedDate = useRecoilValue(dailyDiagnosisSelectedState(target));
    const resetDailyDiagnosisSelectedDate = useResetRecoilState(dailyDiagnosisSelectedState(target));
    const resetCalendarParam = useResetRecoilState(doctorCalendarParamState);

    const searchList = useRecoilValue(searchListState(target));

    const doctorRtdbList = useRecoilValue(doctorRtdbState);
    const list = useMemo(() => doctorRtdbList, [doctorRtdbList]);

    const selectedUser = useRecoilValue(selectedUserState);
    const selectedUserId = useMemo(() => {
        return selectedUser?.user.id ?? "";
    }, [selectedUser]);

    const doctorFindQueryResp = useDoctorFindQuery(selectedUserId);

    const selectedDoctorId = useMemo(() => {
        return doctorFindQueryResp.data?.id ?? "";
    }, [doctorFindQueryResp.data]);

    const userName = useMemo(() => {
        const target = getDoctorById(selectedDoctorId);
        if (_.isUndefined(target)) return "";
        return target.name;
    }, [selectedDoctorId, doctorList]);

    const [dailyDiagnosisList, setDailyDiagnosisList] = useRecoilState(dailyDiagnosisListState);

    const diagnosisList = useMemo(() => {
        if ((searchList.list || []).length > 0) {
            return searchList.list!;
        } else if (dailyDiagnosisList.length > 0) {
            return dailyDiagnosisList;
        } else {
            return list;
        }
    }, [searchList.list, dailyDiagnosisList, list]);

    return {
        selectedUserId,
        connect,
        disconnect,
        queryTrigger,
        setQueryTrigger,
        selectedDoctorId,
        searchList,
        list,
        doctorRtdbList,
        dailyDiagnosisSelectedDate,
        resetDailyDiagnosisSelectedDate,
        resetCalendarParam,
        diagnosisList,
        target,
        userName,
        setDailyDiagnosisList,
    };
};
