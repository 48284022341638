import {AbstractOoParam} from "../base";

class OoParamPharmacyUpdate extends AbstractOoParam {
    id: string = '';

    //약국명
    name?: string = undefined;

    //주소
    address?: string = undefined;

    //상세주소
    addressDetail?: string = undefined;

    //우편변호
    zipCode?: string = undefined;

    //전화번호
    tel?: string = undefined;

    //팩스번호
    fax?: string = undefined;

    //라이센스 번호
    license?: string = undefined;

    //홈페이지
    web?: string = undefined;

    //소개
    bio?: string = undefined;

    //정산 은행명
    bank?: string = undefined;

    //계좌번호
    bankAccount?: string = undefined;

    //예금주/
    bankHolderName?: string = undefined;

    //약국타입
    type?: number = undefined;

    //사진
    pictures?: Array<string> = undefined;

    //알림톡 수신 번호
    alimTalkReceivers?: Array<string> = undefined;

    //소속 병원 아이디 목록
    hospitalIdList?: Array<string> = undefined;

    //퀵 배송 지역 검색 키워드
    quickRegionKeywordList?: Array<string> = undefined;

    //퀵 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
    quickRegionKeywordHitCount?: number = undefined;

    //퀵 배송 문구
    quickDeliveryComment?: string = undefined;

    //당일 배송 지역 검색 키워드
    todayRegionKeywordList?: Array<string> = undefined;

    //당일배송 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
    todayRegionKeywordHitCount?: number = undefined;

    //당일 배송 문구
    todayDeliveryComment?: string = undefined;

    //택배 배송 지역 검색 키워드
    packageRegionKeywordList?: Array<string> = undefined;

    //택배 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
    packageRegionKeywordHitCount?: number = undefined;

    //택배 배송 문구
    packageDeliveryComment?: string = undefined;

    //약국 픽업 문구
    pickupDeliveryComment?: string = undefined;

    //약배송 타입 목록
    deliveryTypeList?: Array<number> = undefined;

    //퀵 가능 시간
    deliverQuickHours?: Array<string> = undefined;

    //퀵 비용
    deliverQuickPrice?: number = undefined;

    //당일배송 가능시간
    deliverTodayHours?: Array<string> = undefined;

    //당일배송 비용
    deliverTodayPrice?: number = undefined;

    //택배배송 가능시간
    deliverPackageHours?: Array<string> = undefined;

    //택배 배송 비용
    deliverPackagePrice?: number = undefined;

    //직접픽업 가능시간
    deliverPickupHours?: Array<string> = undefined;

    //직접픽업 배송 비용
    deliverPickupPrice?: number = undefined;
}

export {
    OoParamPharmacyUpdate
};