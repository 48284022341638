import {IOoWorkHours} from "@api/models/pharmacy";
import {AbstractOoParam} from "../base";


class OoParamDoctorHours extends AbstractOoParam {
    public readonly id: string;
    public readonly doctorHours: Array<IOoWorkHours>;

    constructor(id: string, doctorHours: Array<IOoWorkHours>) {
        super();
        this.id = id;
        this.doctorHours = doctorHours;
    }
}

export {
    OoParamDoctorHours
}