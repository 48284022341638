import {AxiosInstance} from "axios";
import {IOoResponse} from "../reponses";
import {endPoint} from "./base";
import {OoParamDoctorNotification} from "@models/notification/notification";

class DoctorOnNotificationAPI {
    constructor(private readonly axiosInstance: AxiosInstance) {
    }

    /**
     * 텍스트 메시지 노티피 케이션
     */
    async doctorMessage(param: OoParamDoctorNotification): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.pushNotification.doctorMessage, param);
    }
}


export {DoctorOnNotificationAPI}