import {Modal} from "react-bootstrap";
import React from "react";
import {progressState} from "../../../recoils";
import {useRecoilValue, useResetRecoilState} from "recoil";
import _ from "lodash";

export const SuspenseErrorModal = () => {
    const progress = useRecoilValue(progressState);
    const resetProgressStatus = useResetRecoilState(progressState);

    return (
        <Modal size='lg' show={!_.isEmpty(progress.error)} onHide={resetProgressStatus} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>에러</Modal.Title>
            </Modal.Header>
            <Modal.Body>{progress.error?.message || '이런!\n알 수 없는 에러가 발생했어요!'}</Modal.Body>
        </Modal>
    )
}