import React, { useState } from "react";
import {Button} from "react-bootstrap";
import classNames from "classnames/bind";
import buttonStyles from "../../../styles/common/Button.module.scss";
import styled from "styled-components";
import {useDoctorInfoRegisterSettingHook} from "./hooks/useDoctorInfoRegisterSettingHook";
import {HospitalRegisterBankInfo, HospitalRegisterInfo, HospitalRegisterLogo} from "@components/registerForm";
import {CustomCard} from "@components/custom";
import {useModalHook} from "@hooks/modal";
import _ from "lodash";

const errorType = 'errorModal';
const errorTitle = '등록이 불가합니다.';

export const HospitalInfoRegisterPage = () => {
    const bx = classNames.bind(buttonStyles);
    const {addModal} = useModalHook();
    const {
        param,
        createHospital,
        onChangedBankInfo,
        onChangedInfo,
        onChangedLogo,
    } = useDoctorInfoRegisterSettingHook();

    const onClickSubmitButton = (e: any) => {
        e.preventDefault();       
        let text = '';
        if (_.isEqual(param.name, "")) {
            text = "병원명을 입력해주세요.";
        } else if (_.includes(param.tel, "-")) {
            text = "- 제외하고 입력해주세요.";
        } else if (_.isEqual(param.tel, "")) {
            text = "전화번호를 입력해주세요.";
        } else if (_.isEqual(param.logo, "")){
            text = "로고를 넣어주세요.";
        } else if (!_.includes(param.email, "@doctoron.kr")) {
            text = "이메일 형식에 맞지 않습니다.";
        } else if (_.lt(param.zipCode.length, 5) || _.gt(param.zipCode.length, 6)) {
            text = "우편번호는 5자리 숫자로 입력해주세요.";
        } else if (_.isEqual(param.address, "")) {
            text = "주소를 입력해주세요.";
        } else if (
            _.isUndefined(param.regionKeywordList) ||
            _.isEqual(param.regionKeywordList.length, 0)
        ) {
            text = "지역 검색어를 입력해주세요.";
        } else if (_.lt(param.password.length, 8)) {
            text = "비밀번호 8자 이상 입력해주세요.";
        } else if (_.isEqual(param.bank, "")) {
            text = "은행명을 입력하세요.";
        } else if (_.isEqual(param.bankAccount, "")) {
            text = "은행계좌를 입력하세요.";
        } else if (_.isEqual(param.bankHolderName, "")) {
            text = "예금주를 입력하세요.";
        } else if (_.isEqual(param.pictures.length, 0)) {
            text = "사진을 등록하세요.";
        } else {
            createHospital(param);
        }
        
        !_.isEmpty(text) &&
        addModal({
            type: errorType,
            data: {
                title: errorTitle,
                contents: <div>{text}</div>,
            }
        })
    };

    return (
        <Wrapper>
            <CustomCard children={<HospitalRegisterInfo onChanged={onChangedInfo}/>}
                        buttonHidden={true}
                        title="병원 기본 정보 [필수]"
                        subTitle="병원 로고 [필수]"
                        component="hospitalRegisterInfo"/>
            <CustomCard children={<HospitalRegisterLogo onChanged={onChangedLogo}/>}
                        buttonHidden={true}
                        title="병원 사진 [필수]"
                        component="hospitalRegisterInfo"/>
            <CustomCard children={<HospitalRegisterBankInfo onChanged={onChangedBankInfo}/>}
                        buttonHidden={true}
                        title="정산 은행 정보 [필수]"
                        component="hospitalRegisterInfo"/>
            <ButtonContainer>
                <Button type="button"
                        onClick={() =>
                            addModal({
                                type: 'twoBtnModal',
                                data: {
                                    title: "병원 등록을 취소 하시겠습니까?",
                                    contents: <div>작성하던 내용이 사라집니다.계속 하시겠습니까?</div>,
                                    buttons: [{title: "계속 작성하기"}, {title: "등록 취소하기"}]
                                }
                            })}
                        className={bx("diagnosis-cancel-button")}>
                    취소
                </Button>
                <Button type="button"
                        onClick={onClickSubmitButton}
                        className={bx("diagnosis-submit-button")}>
                    등록
                </Button>
            </ButtonContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  top: 150px;
  position: relative;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 44px;
  padding-bottom: 44px;
`;
