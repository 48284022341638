import styled from "styled-components";
import {useRecoilState} from "recoil";
import {useDisplayDiagnosisRtdbHook} from "../../../../../hooks";
import {filterDisplayedDiagnosis, IOoDiagnosis} from "@models/diagnosis";
import React from "react";
import {selectedFilterState} from "@recoils/operator";

interface Props {
    list: Array<IOoDiagnosis>;
}

interface ColorProps {
    backgroundColor: string;
    isChecked: boolean;
}

enum StatusColor {
    default = "#EEEEEE",
    wait = "#FFFFFF",
    cancel = "#F6CFD3",
    success = "#6E8ED1",
}

enum TextColor {
    default = "#000000",
    wait = "#536DA3",
    success = "#FFFFFF",
    cancel = "#B43831",
}

export enum FilterStatus {
    total = 0,
    request,
    accept,
    noMedicineChoice,
    todayDeliveryFail,
    medicineDeliveryRequest,
    medicinePayWait,
    medicinePaySuccess,
    pharmacyPickup,
    todayPackageDelivery,
    doctorCancel,
    requestReservation,
    diagnosisPayFail,
    diagnosisComplete,
    waitPickup,
    medicineDeliveryStart,
    medicineDeliveryCancel,
}

export const DiagnosisFilterCard = ({list}: Props) => {
    const [selectFilterStatus, setSelectNumber] = useRecoilState(selectedFilterState);

    const {
        total,
        request,
        accept,
        doctorCancel,
        medicineDeliveryPaymentWait,
        medicineDeliveryPaymentOk,
        medicineDeliveryRequest,
        todayDeliveryFail,
        requestReservation,
        diagnosisPayFail,
        diagnosisComplete,
        waitPickup,
        medicineDeliveryStart,
        medicineDeliveryCancel,
    } = useDisplayDiagnosisRtdbHook({list});

    const FirstRow = [
        {
            label: "전체진료",
            filterStatus: FilterStatus.total,
            statusColor: StatusColor.default,
            textColor: TextColor.default,
            filteredCount: filterDisplayedDiagnosis(total()).length,
        },
        {
            label: "진료취소",
            filterStatus: FilterStatus.doctorCancel,
            statusColor: StatusColor.cancel,
            textColor: TextColor.cancel,
            filteredCount: filterDisplayedDiagnosis(doctorCancel()).length,
        },
        {
            label: "예약신청",
            filterStatus: FilterStatus.requestReservation,
            statusColor: StatusColor.default,
            textColor: TextColor.default,
            filteredCount: filterDisplayedDiagnosis(requestReservation()).length,
        },
        {
            label: "진료신청",
            filterStatus: FilterStatus.request,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(request()).length,
        },
        {
            label: "진료 접수 완료",
            filterStatus: FilterStatus.accept,
            statusColor: StatusColor.success,
            textColor: TextColor.success,
            filteredCount: filterDisplayedDiagnosis(accept()).length,
        },
        {
            label: "진료비 미결제",
            filterStatus: FilterStatus.diagnosisPayFail,
            statusColor: StatusColor.cancel,
            textColor: TextColor.cancel,
            filteredCount: filterDisplayedDiagnosis(diagnosisPayFail()).length,
        },
        {
            label: "진료 완료",
            filterStatus: FilterStatus.diagnosisComplete,
            statusColor: StatusColor.success,
            textColor: TextColor.success,
            filteredCount: filterDisplayedDiagnosis(diagnosisComplete()).length,
        },
    ];

    const SecondRow = [
        {
            label: "약 배송 신청",
            filterStatus: FilterStatus.medicineDeliveryRequest,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(medicineDeliveryRequest()).length,
        },
        {
            label: "약제비 결제 완료",
            filterStatus: FilterStatus.medicinePaySuccess,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(medicineDeliveryPaymentOk()).length,
        },
        {
            label: "약제비 미결제",
            filterStatus: FilterStatus.medicinePayWait,
            statusColor: StatusColor.cancel,
            textColor: TextColor.cancel,
            filteredCount: filterDisplayedDiagnosis(medicineDeliveryPaymentWait()).length,
        },
        {
            label: "픽업 대기",
            filterStatus: FilterStatus.waitPickup,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(waitPickup()).length,
        },
        {
            label: "약 배송 시작",
            filterStatus: FilterStatus.medicineDeliveryStart,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(medicineDeliveryStart()).length,
        },
        {
            label: "당일 배송 실패건",
            filterStatus: FilterStatus.todayDeliveryFail,
            statusColor: StatusColor.cancel,
            textColor: TextColor.cancel,
            filteredCount: filterDisplayedDiagnosis(todayDeliveryFail()).length,
        },
        {
            label: "배송 취소",
            filterStatus: FilterStatus.medicineDeliveryCancel,
            statusColor: StatusColor.cancel,
            textColor: TextColor.cancel,
            filteredCount: filterDisplayedDiagnosis(medicineDeliveryCancel()).length,
        },
    ];

    const onClickFilter = (e: any) => {
        e.preventDefault();
        const selectedStatus = e.target.value;
        setSelectNumber(selectedStatus);
    };

    return (
        <Wrapper>
            <div>
                {
                    FirstRow.map((item, index) => (
                        <LabelContainer key={index}
                                        htmlFor={`${item.filterStatus}`}
                                        backgroundColor={item.statusColor}
                                        isChecked={selectFilterStatus === item.filterStatus.toString()}>
                            <input id={`${item.filterStatus}`}
                                   type="radio"
                                   name="radio"
                                   onClick={onClickFilter}
                                   value={item.filterStatus}
                                   hidden={true}/>
                            <LabelDiv>
                                <StyledTitle color={item.textColor}>{item.label}</StyledTitle>
                                <StyledCount color={item.textColor}>
                                    {item.filteredCount}
                                </StyledCount>
                            </LabelDiv>
                        </LabelContainer>
                    ))
                }
            </div>
            <div>
                {
                    SecondRow.map((item, index) => (
                        <LabelContainer key={index}
                                        htmlFor={`${item.filterStatus}`}
                                        backgroundColor={item.statusColor}
                                        isChecked={selectFilterStatus === item.filterStatus.toString()}>
                            <input id={`${item.filterStatus}`}
                                   type="radio"
                                   name="radio"
                                   onClick={onClickFilter}
                                   value={item.filterStatus}
                                   hidden={true}/>
                            <LabelDiv>
                                <StyledTitle color={item.textColor}>{item.label}</StyledTitle>
                                <StyledCount color={item.textColor}>
                                    {item.filteredCount}
                                </StyledCount>
                            </LabelDiv>
                        </LabelContainer>
                    ))
                }
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  padding-top: 4px;
  display: block;
  height: auto;
  display: flex;
  flex-direction: column;

  & > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    margin-bottom: 1px;
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
`;

const LabelContainer = styled.label<ColorProps>`
  width: 100%;
  max-width: 100%;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.backgroundColor};
  border: ${(props) =>
          props.isChecked ? "2px solid red" : "1px solid #C2C1BD"};
  cursor: pointer;
  margin-bottom: 2px;

  &:nth-child(2n-1) {
    margin-right: 3px;
  }

  &:nth-child(2n) {
    margin-right: 3px;
  }

  &:nth-child(7n) {
    margin-right: 0;
  }
`;

const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledTitle = styled.b`
  font-size: 12px;
  color: ${(props) => props.color};

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const StyledCount = styled.b`
  font-size: 2rem;
  color: ${(props) => props.color};
`;
