import {IOoDiagnosis, OoDiagnosisType, OoWapleReservationStatus} from "@api/models";
import _ from "lodash";
import {formatHHMM} from "@hooks/format";

export const useFilteredReservationStatus = (diagnosis: IOoDiagnosis) => {
    // 예약 접수
    const isReservation = (): boolean => {
        return (_.isEqual(diagnosis.type || OoDiagnosisType.online, OoDiagnosisType.offlineReservation)) || (_.isEqual(diagnosis.type || OoDiagnosisType.online, OoDiagnosisType.onlineReservation));
    };

    // 바로 접수
    const isReceipt = (): boolean => {
        return (_.isEqual(diagnosis.type || OoDiagnosisType.online, OoDiagnosisType.online)) || (_.isEqual(diagnosis.type || OoDiagnosisType.online, OoDiagnosisType.offline));
    };

    // 예약 확정
    const isReservationConfirmed = (): boolean => {
        const status = diagnosis.wapleReservationStatus ?? OoWapleReservationStatus.unknown;
        return isReceipt() && (_.isEqual(status, OoWapleReservationStatus.reservationComplete));
    };

    // 예약 완료
    const isReservationComplete = (): boolean => {
        return _.isEqual(diagnosis.wapleReservationStatus, OoWapleReservationStatus.reservationComplete) && ((diagnosis.wapleReservationTimestamp ?? 0) > 0);
    };

    // 예약 진료 취소
    const isReservationDiagnosisCancel = (): boolean => {
        if (_.isUndefined(diagnosis.type)) {
            return false
        }
        const status = diagnosis.wapleReservationStatus ?? OoWapleReservationStatus.unknown;
        return isReservation() && (status >= OoWapleReservationStatus.receiptCancelBySelf && status <= OoWapleReservationStatus.receiptCancelByNoShow);
    };

    // 예약 취소
    const isReservationCancel = (): boolean => {
        if (_.isUndefined(diagnosis.type)) {
            return false
        }
        const status = diagnosis.wapleReservationStatus ?? OoWapleReservationStatus.unknown;
        return isReservation() && (status >= OoWapleReservationStatus.reservationCancelBySelf && status <= OoWapleReservationStatus.reservationCancelByNoShow);
    };

    // 내원 확인
    const isVisitComplete = (): boolean => {
        return (_.isEqual(diagnosis.wapleReservationStatus, OoWapleReservationStatus.visitComplete)) && ((diagnosis.wapleReservationTimestamp ?? 0) > 0);
    };

    //예약일 도래
    const isCloseReservation = (): boolean => {
        return _.isEqual(diagnosis.type ||  OoDiagnosisType.online, OoDiagnosisType.online) && ((diagnosis.wapleReservationTimestamp ?? 0) > 0);
    };

    // 예약 진료 완료 이전 상태
    const isDoneReservationDiagnosis = (): boolean => {
        return isReservationCancel() || isReservationDiagnosisCancel();
    };

    const filteredReservationStatus = () => {
        // 예약일 도래
        if (isCloseReservation()) {
            return `예약 ${formatHHMM(diagnosis.wapleReservationTimestamp ?? 0)}`;
        }

        if ((isReservation() && isReservationComplete()) || (isReservation() && isVisitComplete())) {
            return `예약 ${formatHHMM(diagnosis.wapleReservationTimestamp ?? 0)}`;
        } else if (isVisitComplete()) {
            return `내원 확인`;
        } else if (isReceipt()) {
            return `바로 접수`;
        } else if (isReservation()) {
            return `예약 접수`;
        } else {
            return `-`;
        }
    };

    return {
        isVisitComplete,
        isReceipt,
        isReservationCancel,
        isReservation,
        isReservationConfirmed,
        isReservationComplete,
        isCloseReservation,
        filteredReservationStatus,
        isReservationDiagnosisCancel,
        isDoneReservationDiagnosis
    }
};