import {OoDiagnosisPayType, OoHospitalMedicineType} from "@api/models";
import {AbstractOoParam} from "../base";

class OoParamPrescriptionCreate extends AbstractOoParam {
    // 진료 데이터 아이디
    id: string = '';

    prescription?: Array<string> = [];

    price: number = 0;

    // 병원 처방 약제비
    hospitalMedicinePrice?: number = 0;

    comment?: string = '';

    isHospitalDelivery?: boolean = false;

    // 결제 타입
    diagnosisPayType?: number = OoDiagnosisPayType.appPay;

    //약 타입 (약국약, 병원약, 둘다)
    hospitalMedicineType?: number = OoHospitalMedicineType.diagnosis;
}

export {
    OoParamPrescriptionCreate
}
