import { List, SearchInfo } from '@recoils/display'
import React from 'react'
import { RawExcelExportModel } from '../rawModel';
import { IOoCalculateList } from '@api/models/calculate/calculateList';
import _ from 'lodash'
import FileSaver from 'file-saver';
import moment from 'moment';
import { IOoHospital } from '@api/models';
const ExcelJS = require("exceljs");

interface CustomType extends IOoCalculateList, Omit<IOoHospital, 'id'|'type'>{
}

const calculateListMonthExcelDown = async (list: Array<CustomType>) => {
    let data_array: Array<RawExcelExportModel> = [];

    const filteredRefererList = (referer:string[]) => {
        return referer.map(item => item.split('|')[0]);
    }

    list.forEach(item => { 
        const model = new RawExcelExportModel();
        model.hospitalCode = item.hospitalCode;
        model.hospitalName = item.hospitalName;
        model.type = item.type;
        model.totalPrice = item.totalPrice;
        model.totalHospitalCalPrice = item.totalHospitalCalPrice;
        model.bank = item.bank;
        model.bankAccount = item.bankAccount;
        model.bankHolderName = item.bankHolderName;
        if(item.referer.length > 0){
            model.referer = filteredRefererList(item.referer);
            model.totalRefererCalPrice = item.totalRefererCalPrice;
        }else{
            model.referer = [];
            model.totalRefererCalPrice = [];
        }
        data_array.push(model);
    });

            const excelFileExtension = ".xlsx";
            const excelFileName = `tlc 정산 리스트 `;

            const arrayBufferCalculate = await (await fetch("/assets/calculateListMonth.xlsx")).arrayBuffer();
            const workbookCalculate = new ExcelJS.Workbook();
            await workbookCalculate.xlsx.load(arrayBufferCalculate);
            const ws_Calculate = workbookCalculate.getWorksheet("Worksheet");

            data_array.map((data: RawExcelExportModel, index: number) => {
            const row = ws_Calculate.getRow(index + 2); // 엑셀은 1부터 시작
            [
                data.hospitalCode,
                data.hospitalName,
                data.type === 0 ? '양방' : data.type === 1 ? '한방' : data.type === 2 ? 'B2B' : '피트니스',
                data.bank,
                data.bankAccount,
                data.bankHolderName,
                data.totalPrice,
                data.totalHospitalCalPrice,
                data.referer[0] ? data.referer[0] : '',
                data.referer[0] ? data.totalRefererCalPrice[0] : '',
                data.referer[1] ? data.referer[1] : '',
                data.referer[1] ? data.totalRefererCalPrice[1] : '',
                data.referer[2] ? data.referer[2] : '',
                data.referer[2] ? data.totalRefererCalPrice[2] : '',

            ].forEach((value , index) => {
                const cell = row.getCell(index + 1);
                cell.value = value;
                cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
                };
            });
        });
        const excelBuffer = await workbookCalculate.xlsx.writeBuffer();
        const excelFile = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(
        excelFile,
        excelFileName + "_" + moment().format("YYYYMMDD") + excelFileExtension
        );
}

export default calculateListMonthExcelDown