import tableStyles from '../../../../styles/common/Table.module.scss';
import classNames from "classnames/bind";
import React, {useEffect, useState} from "react";
import styles from "../../../../styles/doctor/register/DoctorBasicInfo.module.scss";
import fontStyles from "../../../../styles/common/Font.module.scss";
import layoutStyles from "../../../../styles/common/Layout.module.scss";
import {useRecoilValue} from "recoil";
import {editSelectorFamily} from '@recoils/status';
import {IOoDoctor} from '@models/doctor';
import {CustomInputText} from "@components/custom";
import {InitialImageUpload} from "@components/initialImageUpload";

interface Props {
    data: IOoDoctor,
    componentName: string,
}

export const DoctorLicense = ({data, componentName}: Props) => {
    const fx = classNames.bind(fontStyles);
    const tx = classNames.bind(tableStyles);
    const cx = classNames.bind(styles);
    const lx = classNames.bind(layoutStyles);
    const [color, setColor] = useState("white");
    const editStatus = useRecoilValue(editSelectorFamily(componentName));


    useEffect(() => {
        editStatus.isEdit ? setColor("blue") : setColor("white");
    }, [editStatus.isEdit]);


    const onChangeLicense = () => {

    };

    const onChangePicture = () => {

    };

    return (
        <div className={lx('doctor-license-container')}>
            <div>
                <div>
                    <table className={tx('table')}>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td><b>면허 번호</b></td>
                            <td>
                                <CustomInputText value={data.license}
                                                 onChanged={onChangeLicense}
                                                 disabled={editStatus.isEdit}
                                                 color={color}
                                                 size="middle"
                                                 placeholder="[필수]"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className={fx('info-font-style')}>*면허번호와 면허증 사본은 내부 확인용으로만 사용 되오니 안심하시기 바랍니다.</div>
                </div>
                <div className={cx('image-container')}>
                    <InitialImageUpload target="doctor"
                                        hospitalId={'1'}
                                        onChanged={onChangePicture}/>
                </div>
            </div>
        </div>
    )
}