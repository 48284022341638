import {AbstractOoParam} from "../base";
import {OoParamPrescriptionCreate} from "@api/params";

class OoParamFamilyPrescriptionCreate extends AbstractOoParam {
    public readonly prescriptionList: Array<OoParamPrescriptionCreate>;

    constructor(prescriptionList: Array<OoParamPrescriptionCreate>) {
        super();
        this.prescriptionList = prescriptionList;
    }
}

export {
    OoParamFamilyPrescriptionCreate
}