import {useRecoilState, useRecoilValue} from "recoil";
import _, {findIndex} from "lodash";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {
  useDiagnosisStatusChange,
  usePharmacyCreateQuery,
  usePharmacyDeleteQuery,
  usePharmacyListQuery,
  usePharmacyTimeUpdateQuery,
  usePharmacyUpdateQuery
} from "@queries/operator";
import {pharmacyListState} from "@recoils/pharmacy";
import {hospitalListState} from "@recoils/display";
import {findHospitalName, IOoPharmacy, IOoWorkHours} from "@models/pharmacy";
import {OoParamPharmacyCreate, OoParamPharmacyUpdate} from "@params/pharmacy";
import {OoParamDiagnosisStatus} from "@api/params";
import {IOoResponse} from "@api/reponses";
import {useSuspenseHook} from "@hooks/suspense";
import {useHospitalFunctionHook} from "@hooks/hospital";
import {OoDiagnosisStatus} from "@api/models";

export const usePharmacyFunctionHook = () => {
  const {setErrorStatus} = useSuspenseHook();
  const [list, setList] = useRecoilState(pharmacyListState);
  const hospitalList = useRecoilValue(hospitalListState);
  const navigate = useNavigate();

  usePharmacyListQuery();
  useHospitalFunctionHook();
  const createPharmacyResp = usePharmacyCreateQuery();
  const deletePharmacyResp = usePharmacyDeleteQuery();
  const updatePharmacyResp = usePharmacyUpdateQuery();
  const updatePharmacyTimeResp = usePharmacyTimeUpdateQuery();
  const diagnosisStatusResp = useDiagnosisStatusChange();


  // 지정 병원 이름 찾기
  const findTargetHospitalName = (item: IOoPharmacy): string | undefined => {
    let data = "";
    if (!_.isUndefined(item.hospitalIdList)) {
      item.hospitalIdList.map(
          (item) => (data = findHospitalName(item, hospitalList))
      );
    }
    return _.isUndefined(data) ? undefined : data.length > 0 ? data : undefined;
  };

  // 리코일에서 약국 정보 찾기
  const getPharmacyById = (id: string): IOoPharmacy => {
    return _.chain(list)
        .filter((e: IOoPharmacy) => _.isEqual(e.id || "", id))
        .head()
        .value();
  };

  // 약사웹에서 userId로 약국 정보 찾기
  const getPharmacyByUserId = (id: string): IOoPharmacy => {
    return _.chain(list)
        .filter((e: IOoPharmacy) => _.isEqual(e.userId, id))
        .head()
        .value();
  };

  // 약국 정보 등록
  const createPharmacy = (param: OoParamPharmacyCreate) => {
    createPharmacyResp.mutate(param, {
      onSuccess: (data: IOoPharmacy | undefined) => {
        toast.success("약국 정보 등록 완료");
        if (!_.isUndefined(data)) {
          const copied = _.cloneDeep(list);
          navigate("/pharmacy");
          setList(_.concat(copied, data));
        }
      },
    });
  };

  // 약국 정보 수정
  const updatePharmacy = (param: OoParamPharmacyUpdate) => {
    updatePharmacyResp.mutate(param, {
      onSuccess: (data: IOoPharmacy | undefined) => {
        toast.success("약국 정보 수정 완료");
        if (!_.isUndefined(data)) {
          const copied = _.cloneDeep(list);
          const index = findIndex(copied, (item) =>
              _.isEqual(item.id, data.id)
          );
          if (_.gte(index, 0)) {
            copied[index] = data;
            setList(copied);
          }
        } else {
          return;
        }
      },
    });
  };

  // 약국 시간 정보 수정
  const updateTimePharmacy = (id: string, param: IOoWorkHours[]) => {
    updatePharmacyTimeResp.mutate(
        {id, param},
        {
          onSuccess: (data: string | undefined) => {
            toast.success("약국 시간 수정 완료");
            if (!_.isUndefined(data)) {
              const copied = _.cloneDeep(list);
              const index = findIndex(copied, (item) => _.isEqual(item.id, id));
              copied[index].pharmacyHours = param;
              setList(copied);
            }
          },
        }
    );
  };

  // 약국 정보 삭제
  const deletePharmacy = (id: string) => {
    deletePharmacyResp.mutate(id, {
      onSuccess: (data: string | undefined) => {
        if (!_.isUndefined(data)) {
          toast.success("약국 정보 삭제 완료");
          if (!_.isUndefined(data)) {
            const copied = _.cloneDeep(list);
            const index = findIndex(copied, (item) => _.isEqual(item.id, id));
            copied.splice(index, 1);
            if (_.gte(index, 0)) {
              navigate("/pharmacy");
              setList(copied);
            }
          }
        }
      },
    });
  };

  // 약국 진료 상태 변경
  const changeStatusDiagnosis = (id: string, status: number, comment?: string) => {
    const param = new OoParamDiagnosisStatus(id, status, comment || "");
    diagnosisStatusResp.mutate(param, {
      onSuccess: (data: IOoResponse | undefined) => {
        switch (status) {
          case OoDiagnosisStatus.medicineDeliveryAccept:
            toast.success("약 준비 완료 상태 변경 성공");
            break;
          case OoDiagnosisStatus.medicineDeliveryStart:
            toast.success("약 배송 시작 상태 변경 성공");
            break;
          default :
            toast.success("처방전 반려 상태 변경 성공");
            break;
        }
        if (_.isUndefined(data)) return;
      },
      onError: (e: any) => {
        setErrorStatus(e);
        console.log('changeStatusDiagnosis error ====>', e);
      },
    })
  };

  return {
    findTargetHospitalName,
    getPharmacyById,
    getPharmacyByUserId,
    createPharmacy,
    updatePharmacy,
    deletePharmacy,
    updateTimePharmacy,
    changeStatusDiagnosis,
  };
};
