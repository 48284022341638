import {atom} from "recoil";
import {IOoError} from "../../api";

interface IOoProgressState {
    loading: boolean,
    error: IOoError | undefined,
    errorStatus: boolean,
}

const progressState = atom<IOoProgressState>({
    key: 'progressState',
    default: {
        loading: false,
        error: undefined,
        errorStatus: false,
    }
});

export {progressState};
export type {IOoProgressState};