import React, {useEffect} from "react";
import styled from "styled-components";
import {DoctorCareerInfo} from "../../../../interfaces";
import {CustomTextArea} from "@components/custom";
import {useRegisterCareerSettingHook} from "@components/registerForm";
import { OoParamDoctorCreate } from "@api/params";

interface Props {
    onChanged: (data: DoctorCareerInfo) => void;
    param:OoParamDoctorCreate
}

export const DoctorRegisterCareer = ({onChanged , param}: Props) => {
    const {data, onChangeEducation, onChangeCareer, onChangeBio} = useRegisterCareerSettingHook();

    useEffect(() => {
        onChanged(data);
    }, [data]);

    return (
        <Wrapper>
            <label>학력</label>
            <CustomTextArea color="blue"
                            value={data.education}
                            onChanged={onChangeEducation}
                            placeholder="[필수]"
                            size="middle"
                            disabled={true}
            />
            <label>경력</label>
            <CustomTextArea color="blue"
                            value={data.career}
                            onChanged={onChangeCareer}
                            placeholder="[필수]"
                            size="middle"
                            disabled={true}
            />
            <label>소개</label>
            <CustomTextArea color="blue"
                            value={data.bio}
                            onChanged={onChangeBio}
                            placeholder="[필수]"
                            size="middle"
                            disabled={true}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;

  & > label {
    font-weight: 700;
    font-size: 13px;
  }
`;
