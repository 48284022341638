import React from "react";
import _ from "lodash";
import {formatPrice, formatTel} from "@hooks/format";
import styled from "styled-components";
import {getMedicineDeliveryState, PharmacyState} from "@api/index";

interface Props {
    status: PharmacyState;
}

export const MedicineWayToReceiveInfo = ({status}: Props) => {
    return (
        <Wrapper>
            <div className="title">약 수령 방식</div>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>제휴약국</td>
                    <td>
                        {_.isUndefined(status.pharmacyName) ||
                        _.eq(status.pharmacyName, "")
                            ? "-"
                            : status.pharmacyName}
                    </td>
                    <td>약 수령방식</td>
                    <td>
                        {_.isUndefined(status.deliveryType)
                            ? "-"
                            : getMedicineDeliveryState(status.deliveryType).title}
                    </td>
                </tr>
                <tr>
                    <td>전화번호</td>
                    <td>
                        {_.isUndefined(status.pharmacyTel) ||
                        _.eq(status.pharmacyName, "")
                            ? "-"
                            : formatTel(status.pharmacyTel)}
                    </td>
                    <td>팩스번호</td>
                    <td>
                        {_.isUndefined(status.pharmacyFax) ||
                        _.eq(status.pharmacyName, "")
                            ? "-"
                            : formatTel(status.pharmacyFax)}
                    </td>
                </tr>
                <tr>
                    <td>약제비</td>
                    <td>
                        {_.isUndefined(status.medicinePrice)
                            ? "-"
                            : _.eq(status.medicinePrice, 0)
                                ? "약제비 없음"
                                : `${formatPrice(status.medicinePrice)}원`}
                    </td>
                    <td>주소</td>
                    <td>
                        {_.isUndefined(status.pharmacyAddress) ||
                        _.eq(status.pharmacyAddress, "")
                            ? "-"
                            : status.pharmacyAddress}
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-top: 48px;

  .title {
    font-weight: 700;
    font-size: 16px;
  }
`;

const Table = styled.table`
  width: 100%;
  height: 144px;
  font-size: 13px;
  margin-top: 8px;

  & tbody tr {
    height: 30%;
  }

  & tbody tr:nth-child(1) {
    border-top: 2px solid #c8d6f8;
    border-bottom: 1px solid #c8d6f8;
  }

  tr:nth-child(n + 1) {
    border-bottom: 1px solid #c8d6f8;
  }

  & tbody tr td:nth-child(2n-1) {
    background: #f4f6fb;
    text-align: center;
    font-weight: 700;
    width: 10%;
    font-size: 13px;
  }

  & tbody tr td:nth-child(2n) {
    padding: 10px;
    width: 40%;
  }
`;
