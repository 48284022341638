import React from "react";
import {Link} from "react-router-dom";
import {formatDateUsingDash, formatTel,} from "@hooks/format";
import styled from "styled-components";
import _ from "lodash";
import {IOoPharmacy} from "@models/pharmacy";

interface Props {
    data: Array<any>,
    findTargetHospitalName: (item: IOoPharmacy) => (string | undefined),
}

export const PharmacyListContainer = ({data, findTargetHospitalName}: Props) => {
    return (
        <Wrapper>
            {
                data.map((item, index) => (
                    <Link key={index}
                          to={`/pharmacyDetail`}
                          state={{data: item.id}}
                          style={{textDecoration: "none", color: "black"}}>
                        <ListContainer>
                            <div>{index + 1}</div>
                            <div>{formatDateUsingDash(item.timestamp)}</div>
                            <div>{item.name}</div>
                            <div>{formatTel(item.tel)}</div>
                            <div>
                                             <textarea value={`${item.address} ${item.addressDetail}`}
                                                       disabled={true}/>
                            </div>
                            {
                                !_.isUndefined(findTargetHospitalName) &&
                                <div>{findTargetHospitalName(item) ?? "-"}</div>
                            }
                        </ListContainer>
                    </Link>
                ))}

        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 1024px;
  color: #949390;
  font-weight: 500;
  font-size: 12px;
  z-index: 10;
  overflow-y: auto;

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-height: 768px) {
    height: 585px;
  }

  @media (min-height: 1024px) {
    height: 75%;
  }

  & > div {
    flex: 1;
    text-align: center;
  }
`;

const ListContainer = styled.div`
  display: flex;
  text-align: center;
  height: 60px;
  align-items: center;
  margin-bottom: 5px;
  background: #ffffff;
  box-shadow: 0px 1px 3px -2px;

  & > div {
    flex: 1;

    &:nth-child(5) > textarea {
      border: none;
      resize: none;
      width: 100%;
      text-align: center;
      background: #ffffff;
      color: black;
    }

    &:nth-child(6) > div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
