import {atom} from "recoil";

const selectedFilterState = atom<string>({
    key: 'selectedFilterState',
    default: '0',
});

const doctorSelectedFilterState = atom<string>({
    key: 'doctor/selectedFilterState',
    default: '0',
});
export {
    selectedFilterState,
    doctorSelectedFilterState
};
