import styled from "styled-components";
import React from "react";
import {getDiagnosisPriceStatus, getMedicinePayStatus, IOoDiagnosis} from "../../api";
import {getDiagnosisStatus} from "@models/diagnosis/diagnosisProcessingState";
import {formatDateYYYYMDHHmm} from "@hooks/format";

interface Props {
    data: IOoDiagnosis,
}

export const DiagnosisCancel = ({data}: Props) => {

    return (
        <Wrapper>
            <div className="title">{getDiagnosisStatus(data).title}</div>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>취소 사유</td>
                    <td>{data.cancelComment || '-'}</td>
                </tr>
                <tr>
                    <td>관리자 메모</td>
                    <td>{data.operatorMemo || '-'}</td>
                </tr>
                <tr>
                    <td>병원 메모</td>
                    <td>{data.comment || '-'}</td>
                </tr>
                <tr>
                    <td>약국 메모</td>
                    <td>{data.medicineMemo || '-'}</td>
                </tr>
                <tr>
                    <td>진료비 결제 내역</td>
                    <td>{getDiagnosisPriceStatus(data).title}</td>
                </tr>
                <tr>
                    <td>약제비 결제 내역</td>
                    <td>{getMedicinePayStatus(data).title}</td>
                </tr>
                <tr>
                    <td>시간</td>
                    <td>{formatDateYYYYMDHHmm(data.updateTimestamp)}</td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  .title {
    font-weight: 700;
    font-size: 16px;
    color: ${(props) => props.theme.colors.fontColor.red}
  }

  padding-bottom: 20px;
`;

const Table = styled.table`
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: 100%;
  height: 144px;
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 10px;
  white-space: pre-wrap;

  & tr {
    border-bottom: 1px solid #C8D6F8;
  }

  & tr td:nth-child(2n-1) {
    background: #F4F6FB;
    text-align: center;
    width: 10%;
    height: 48px;
    font-weight: 700;
  }

  & tr td:nth-child(2n) {
    padding-left: 20px;
    width: 25%;
  }

`;