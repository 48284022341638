import React, {useEffect, useState} from "react";
import {Button, Container} from "react-bootstrap";
import _ from "lodash";
import classNames from "classnames/bind";
import buttonStyles from "../../../styles/common/Button.module.scss";
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {selectedUserState} from "@recoils/oauth";
import {OoParamDoctorCreate} from "@params/doctor";
import {
    DoctorAdditionalInfo,
    DoctorAlimTalkInfo,
    DoctorBankInfo,
    DoctorBasicInfo,
    DoctorCareerInfo,
    DoctorLicenseInfo,
    DoctorServiceInfo,
    DoctorVideoInfo,
    DoctorWorkHourInfo
} from "../../../interfaces";
import {CustomCard} from "@components/custom";
import {
    DoctorRegisterAdditionalInfo,
    DoctorRegisterAlimInfo,
    DoctorRegisterBankInfo,
    DoctorRegisterBasicInfo,
    DoctorRegisterCareer,
    DoctorRegisterDiagnosisTime,
    DoctorRegisterLicense,
    DoctorRegisterService,
    DoctorRegisterVideo
} from "@components/registerForm";
import {useModalHook} from "@hooks/modal";
import {useDoctorFunctionHook} from "@hooks/doctor";

const errorType = 'errorModal';
const errorTitle = '등록이 불가합니다.';

export const DoctorInfoRegisterPage = () => {
        const bx = classNames.bind(buttonStyles);

        const {addModal} = useModalHook();

        const {createDoctor, getDoctorById} = useDoctorFunctionHook();
        const selectedUser = useRecoilValue(selectedUserState);
        const doctorInfo = getDoctorById(selectedUser?.user.id ?? "");

        const [param, setParam] = useState<OoParamDoctorCreate>(
            new OoParamDoctorCreate()
        );
            
        const onClickSubmitButton = (e: any) => {
            e.preventDefault();
            let text = '';
            param.gender = 'male';
            
            
            if (_.isEqual(param.name, "")) {
                text = "이름을 입력해주세요.";
            } else if (_.includes(param.tel, "-")) {
                text = "- 제외하고 입력해주세요.";
            } else if (_.isEqual(param.tel, "")) {
                text = "전화번호를 입력해주세요.";
            } else if (!_.includes(param.email, "@doctoron.kr")) {
                text = "이메일 형식에 맞지 않습니다.";
            } else if (_.lt(param.password.length, 8)) {
                text = "비밀번호 8자 이상 입력해주세요.";
            } else if (_.isEqual(param.hospitalId, "")) {
                text = "병원을 선택하세요.";
            } else if (_.isEqual(param.gender, "")) {
                text = "성별을 선택하세요.";
            } else if (_.isEmpty(param.alimTalkReceivers)) {
                text = "알림톡 수신번호를 입력하세요.";
            } else if (_.isEqual(param.education, "")) {
                text = "학력을 입력하세요.";
            } else if (_.isEqual(param.career, "")) {
                text = "경력을 입력하세요.";
            } else if (_.isEqual(param.bio, "")) {
                text = "소개를 입력하세요.";
            } else if (_.isEmpty(param.departments)) {
                text = "진료 과목을 선택하세요.";
            } else if (_.isEmpty(param.theme)) {
                text = "진료 테마를 선택하세요.";
            } else if (_.isEqual(param.bank, "")) {
                text = "은행명을 입력하세요.";
            } else if (_.isEqual(param.bankAccount, "")) {
                text = "은행계좌를 입력하세요.";
            } else if (_.isEqual(param.bankHolderName, "")) {
                text = "예금주를 입력하세요.";
            } else {
                createDoctor(param);
            }
            !_.isEmpty(text) &&
            addModal({
                type: errorType,
                data: {
                    title: errorTitle,
                    contents: <div>{text}</div>,
                }
            })
        };


        const onChangedBasicInfo = (data: DoctorBasicInfo) => {
            param.createBasicInfo(data);
            setParam(param);
        };

        const onChangedAdditionalInfo = (data: DoctorAdditionalInfo) => {
            param.createAdditionalInfo(data);
            setParam(param);
        };

        const onChangedAlimTalk = (data: DoctorAlimTalkInfo) => {
            param.createAlimTalk(data);
            setParam(param);
        };

        const onChangedCareerInfo = (data: DoctorCareerInfo) => {
            param.createCareerInfo(data);
            setParam(param);
        };

        const onChangedBankInfo = (data: DoctorBankInfo) => {
            param.createBankInfo(data);
            setParam(param);
        };

        const onChangeService = (data: DoctorServiceInfo) => {
            param.createServiceInfo(data);
            setParam(param);
        };

        const onChangeLicense = (data: DoctorLicenseInfo) => {
            param.createLicenseInfo(data);
            setParam(param);
        };

        const onChangeVideo = (data: DoctorVideoInfo) => {
            param.createVideoInfo(data);
            setParam(param);
        };

        const onChangeDiagnosisTime = (data: DoctorWorkHourInfo) => {
            param.createDoctorWorkHourInfo(data);
            setParam(param);
        };     

        return (
            <Wrapper>
                <Contents>
                    <Container>
                        <CustomCard
                            children={<DoctorRegisterBasicInfo doctorInfo={doctorInfo} param={param}  onChanged={onChangedBasicInfo}/>}
                            buttonHidden={true}
                            title="의사 기본 정보 [필수]"
                            subTitle="프로필 이미지"/>
                        <CustomCard children={<DoctorRegisterAdditionalInfo onChanged={onChangedAdditionalInfo}  param={param} setParam={setParam}/>}
                                    buttonHidden={true}
                                    title="의사 부가 정보 [필수]"/>
                        <CustomCard children={<DoctorRegisterAlimInfo onChanged={onChangedAlimTalk} param={param} />}
                                    buttonHidden={true}
                                    title="알림톡 수신 전화번호 [필수]"/>
                        <CustomCard children={<DoctorRegisterCareer onChanged={onChangedCareerInfo} param={param} />}
                                    buttonHidden={true}
                                    title="의사 경력 정보 [필수]"/>
                        <CustomCard children={<DoctorRegisterService onChanged={onChangeService} param={param} />}
                                    buttonHidden={true}
                                    title="진료 과목 (최대 4개까지 중복 선택 가능)"/>
                        <CustomCard children={<DoctorRegisterBankInfo onChanged={onChangedBankInfo} param={param} />}
                                    buttonHidden={true}
                                    title="정산 은행 정보 [필수]"/>
                        <CustomCard children={<DoctorRegisterLicense onChanged={onChangeLicense}/>}
                                    buttonHidden={true}
                                    title="의사 면허 [필수]"
                                    subTitle="면허증 사본"
                                    hidden={true}/>
                        <CustomCard children={<DoctorRegisterVideo onChanged={onChangeVideo}/>}
                                    buttonHidden={true}
                                    title="화상(zoom) 진료 제공 여부"/>
                        <CustomCard children={<DoctorRegisterDiagnosisTime onChanged={onChangeDiagnosisTime}/>}
                                    buttonHidden={true}
                                    title="비대면 진료시간"/>
                        <ButtonContainer>
                            <Button type="button"
                                    onClick={() =>
                                        addModal({
                                            type: 'twoBtnModal',
                                            data: {
                                                title: "의사 등록을 취소 하시겠습니까?",
                                                contents: <div>작성하던 내용이 사라집니다.계속 하시겠습니까?</div>,
                                                buttons: [{title: "계속 작성하기"}, {title: "등록 취소하기"}]
                                            }
                                        })}
                                    className={bx("diagnosis-cancel-button")}>
                                취소
                            </Button>
                            <Button type="button"
                                    onClick={onClickSubmitButton}
                                    className={bx("diagnosis-submit-button")}>
                                등록
                            </Button>
                        </ButtonContainer>
                    </Container>
                </Contents>
            </Wrapper>
        );
    }
;

const Wrapper = styled.div`
  top: 150px;
  position: relative;
  height: 100%;
`;

const Contents = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
`;
