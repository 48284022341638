interface IOoDiagnosis {
    // 유일값 아이디 (디비 생성시 자동 생성됨)
    id?: string;

    // 진료의 타입
    serviceType?: number;

    // 진료의 아이디
    doctorId: string;

    // 진료의 이름
    doctorName: string;

    // 진료의 사진 링크
    doctorPicture: string;

    // 진료과
    department: string;

    // 병원 아이디
    hospitalId?: string;

    // 병원 이름
    hospitalName: string;

    // 병원 전화번호
    hospitalTel: string;

    // 병원 주소
    hospitalAddress: string;

    // 초진 여부
    isFirst: boolean;

    // 진료 상태 (OoDiagnosisStatus)
    status: number;

    // 대리 접수 여부
    isProxy: boolean;

    // 약관 동의 여부
    isTermAgreed: boolean;

    // 환자 아이디
    patientId: string;

    // 환자 이름
    patientName: string;

    // 환자 사진 링크
    patientPicture: string;

    // 환자 성별 (male, female)
    patientGender: string;

    // 환자 전화번호
    patientTel: string;

    // 환자 주민번호
    patientIdentityNumber: string;

    // 환자와의 관계 (본인, 배우자)
    patientRelation: string;

    // 증상
    symptom: string;

    // 키
    height?: number

    // 몸무게
    weight?: number;

    // 증상 사진
    symptomPictures?: Array<string>;

    //진료비
    price?: number;

    //병원 처방 약제비 (이경우 배송지가 있을 수 있음)
    hospitalMedicinePrice?: number;

    //약제비
    medicinePrice?: number;

    /// 진료비 결제 테이블 아이디
    diagnosisPaymentId?: string;

    // 진료비 결제 카드명
    diagnosisPayCard?: string;

    // 진료비 카드 번호 - 페이플이 준 정보
    diagnosisPayCardNumber?: string;

    // 진료비 결제 방식 (일시불) - 페이플이 준 정보
    diagnosisPayPlan?: string;

    // 진료비 결제일 - 페이플이 준 정보
    diagnosisPayDate?: string;

    // 진료비 결제 금액 - 페이플이 준 정보
    diagnosisPayPrice?: string;

    // 진료비 결제 결과 코드(SPCD0000) - 페이플이 준 정보
    diagnosisPayCode?: string;

    // 진료비 결제 결과 (success)- 페이플이 준 정보
    diagnosisPayResult?: string;

    // 진료비 결제 결과 메시지 - 페이플이 준 정보
    diagnosisPayMessage?: string;

    /// 약제비 결제 테이블 아이디
    medicinePaymentId?: string;

    medicinePayCard?: string;

    medicinePayCardNumber?: string;

    medicinePayPlan?: string;

    medicinePayDate?: string;

    medicinePayPrice?: string;

    medicinePayCode?: string;

    medicinePayResult?: string;

    medicinePayMessage?: string;

    //약 배송비
    medicineDeliveryPaymentId?: string;

    medicineDeliveryPayCard?: string;

    medicineDeliveryPayCardNumber?: string;

    medicineDeliveryPayPlan?: string;

    medicineDeliveryPayDate?: string;

    medicineDeliveryPayPrice?: string;

    medicineDeliveryPayCode?: string;

    medicineDeliveryPayResult?: string;

    medicineDeliveryPayMessage?: string;

    // 처방전
    prescription?: Array<string>;

    // 수정된 처방전
    modifiedPrescription?: Array<string>

    //진료 소견
    comment?: string;

    //취소 사유
    cancelComment?: string;

    //약국 아이디
    pharmacyId?: string;

    // 약국 이름
    pharmacyName?: string;

    // 약국 주소
    pharmacyAddress?: string;

    // 약국 전화번호
    pharmacyTel?: string;

    // 약국 핸드폰 (알림톡)
    pharmacyMobile?: string;

    // 약국 팩스번호
    pharmacyFax?: string;

    //팩스 접수 영수중
    faxReceiptNumber?: string;

    //팩스 전송 결과
    faxTimestamp?: number;

    //팩스 전송 결과
    faxResult?: number;

    //건진 데이터 열람여부 동의
    isAgreeNhis?: boolean;

    //유전체 데이터 열람여부 동의
    isAgreeGenome?: boolean;

    // 진료 타입 (진료, 유전체 결과 진료), OoDiagnosisType
    type?: number;

    // 원격 진료 방식 OoTelemedicineType
    telemedicineType?: number;

    // 약 수령 타입 OoMedicineDeliveryType
    deliveryType?: number;

    // 배송지 이름
    deliveryName?: string;

    // 배송지 주소
    deliveryAddress?: string;

    // 배송지 주소 상세
    deliveryAddressDetail?: string;

    // 배송지 우편번호
    deliveryZipCode?: string;

    // 배송지 수령인
    deliveryRecipient?: string;

    // 배송지 수령인 전화번호
    deliveryTel?: string;

    // 배송 메모
    deliveryMemo?: string;

    // 배송 업체명
    deliveryCompanyName?: string;

    // 배송 업체 주소
    deliveryCompanyAddress?: string;

    // 배송 업체 연락처
    deliveryCompanyTel?: string;

    // 배송 업체 운영시간
    deliveryCompanyHour?: Array<string>;

    // 배송 등록 번호
    deliveryOrderCode?: string;

    // 배송 등록 응답 코드
    deliveryOrderResultCode?: string;

    // 고박스 배송 API 전송 시간
    deliveryOrderTimestamp?: number;

    // 배송 상태 업데이트 시간
    deliveryOrderUpdateTimestamp?: number;

    // 배송 취소자 (카카오 전용)
    deliveryOrderCancelBy?: string;

    // 배송 취소 사유 (카카오 전용)
    deliveryOrderCancelReason?: string;

    // 배송 옵션 (카카오: quick, quick_express, quick_economy)
    deliveryOrderOption?: string;

    // 배송 신청 횟수
    deliveryOrderCount?: number;

    // 배송 등록 응답 메세지
    deliveryOrderResultMessage?: string;

    // 약사 메모
    medicineMemo?: string;

    // 운영자 메모
    operatorMemo?: string;

    // DUR 시스템 동의 여부 (필수)
    isAgreeDUR?: boolean;

    // 대체약 조제 여부
    isSubstitute?: boolean;

    // 처방전 유효기간
    limitPrescription?: number;

    // 배송비
    deliveryPrice?: number;

    // 진료 데이터 변경 시간
    updateTimestamp: number;

    //줌 링크
    videoLink?: string;

    //줌 링크
    videoId?: string;

    //줌 링크
    videoPassword?: string;

    //예약 타입 OoTelemedicineType 사용
    reservationType?: number;

    //예약 상태
    reservationStatus?: number;

    //예약 이용 일시
    reservationTimestamp?: number;

    //예약 확정, 취소 일시일
    reservationUpdateTimestamp?: number;

    //약 배송 신청 시간
    medicineDeliveryTimestamp?: number;

    // 가족진료 키, 마더이 신청한 진료 데이터 timestamp (최종 삭제 예정)
    familyKey?: number;

    // 가족진료 검색 키, 마더 계정 신청자 아이디
    familyUserId?: string;

    // 가족진료 첫번째 진료 아이디
    familyDiagnosisId?: string;

    // 가족진료 키, 마더이 신청한 진료 데이터 timestamp (최종 삭제 예정)
    familyDiagnosisCount?: number;

    // 가족진료 상태
    familyStatus?: number;

    /// 접수 시간, 최초 접수 이후 바뀌지 않음. 리얼타임데이터베이스의 키로 사용
    timestamp: number;

    //예약 진료 타입
    wapleReservationType?: number;

    //예약 상태
    wapleReservationStatus?: number;

    //예약 이용 일시
    wapleReservationTimestamp?: number;

    //예약 확정, 취소 일시일
    wapleReservationUpdateTimestamp?: number;

    // 예약 취소 사유
    wapleCancelComment?: string;

    /// 카카오 취소 수수료
    kakaoCancelFeePrice?: number;

    /// 카카오 취소 후 환불 결제 테이블 아이디
    kakaoCancelPaymentId?: string;

    kakaoCancelPayCard?: string;

    kakaoCancelPayCardNumber?: string;

    kakaoCancelPayPlan?: string;

    kakaoCancelPayDate?: string;

    kakaoCancelPayPrice?: string;

    kakaoCancelPayCode?: string;

    kakaoCancelPayResult?: string;

    kakaoCancelPayMessage?: string;

    kakaoQuickStatus?: number;

    appVersion?: string;

    //병원 진료 완료 타입 (병원약, 약국약, 병원약 + 약국약)
    hospitalMedicineType?: number;

    //병원약 수령 타입
    hospitalMedicineDeliveryType?: number;

    hospitalMedicinePaymentId?: string;

    hospitalMedicinePayCard?: string;

    hospitalMedicinePayCardNumber?: string;

    hospitalMedicinePayPlan?: string;

    hospitalMedicinePayDate?: string;

    hospitalMedicinePayPrice?: string;

    hospitalMedicinePayCode?: string;

    hospitalMedicinePayResult?: string;

    hospitalMedicinePayMessage?: string;

    hospitalMedicinePayType?: number;
}

export type {
    IOoDiagnosis
}

export enum OoDiagnosisStatus {
    request = 0,                        // 신청
    accept = 1,                         // 접수완료
    diagnosisComplete = 2,              // 진료 완료 (결제 실패일 수 있음, 결제 실패는 결제 정보로 확인)
    decidedDeliveryType = 3,            // 사용자가 약 배송 방식 결정 ( 약 배송 신청 )
    medicineDeliveryPaymentWait = 4,    // 약제비 결제 대기
    medicineDeliveryPaymentOk = 5,      // 약제비 결제 시도 완료 (실패는 medicinePayCode, medicinePayResult 보고 판단)
    medicineDeliveryAccept = 6,         // 조제 시작
    medicineDeliveryStart = 7,          // 조제 완료 및 택배 발송
    medicineTakeOk = 8,                 // 약 수령 완료
    doctorCancel = 9,                   // 의사 취소
    userCancel = 10,                    // 사용자 취소
    operatorCancel = 11,                // 운영자 취소
    pharmacistCancel = 12,              // 약사 취소 (처방전 유효기간 지남 등)
}

export enum OoReservationStatus {
    request = 0,                        // 신청
    accept = 1,                         // 예약 확정
    change = 2,                         // 일정 변경
    cancel = 3,                         // 취소
}

export enum OoDiagnosisType {
    online = 0,                      //온라인 접수
    dtc = 1,                         //유전체 상담
    onlineReservation = 2,           //온라인 예약
    offline = 3,                      //오프라인 접수
    offlineReservation = 4,           //오프라인 예약
}

export enum OoPaymentType {
    diagnosis,
    medicine,
    dtc,
    medicineDelivery,
}

export enum OoTelemedicineType {
    phone = 0,                          // 전화
    video = 1,                          // 화상
    visit = 2,                          // 방문
}

export enum OoMedicineDeliveryType {
    unknown = 0,
    quickDelivery = 1,                  // 퀵 배송
    todayDelivery = 2,                  // * 당일 배송
    packageDelivery = 3,                // * 택배 - 웰니스 약국만 해당
    user = 4,                           // 직접 픽업 //사용자가 직접
    hospitalDelivery = 5,               // 병원 발송
    pharmacyPackage = 6,                // * 방문 수령
    kaKaoMobility = 7,                  // 카카오
}

export enum OoWapleReservationType {
    all = 0,
    visitReservation = 1,  // 대면진료(예약)
    visitRegister = 2,     // 대면진료(접수)
    phoneReservation = 3,  // 비대면진료(전화,예약)
    phoneRegister = 4,     // 비대면진료(전화,접수)
    videoReservation = 5,  // 비대면진료(화상,예약)
    videoRegister = 6,     // 비대면진료(화상,접수)
}

export enum OoWapleReservationStatus {
    unknown = -1,
    receiptWait = 101,                  // 접수대기
    receiptComplete = 111,              // 진료대기
    receiptCancelBySelf = 121,          // 진료취소(본인취소)
    receiptCancelByHospital = 122,      // 진료취소(병원취소)
    receiptCancelByNoShow = 123,        // 진료취소(미방문)
    reserved = 201,                     // 예약신청
    reservationComplete = 211,          // 예약완료
    reservationCancelBySelf = 221,      // 예약취소(본인취소)
    reservationCancelByHospital = 222,  // 예약취소(병원취소)
    reservationCancelByNoShow = 223,    // 예약취소(미방문)
    visitConfirm = 231,                  // 내원확인중
    visitComplete = 232,                // 내원확인완료
    onlineConfirm = 233,                 // 비대면 진료 당일 상태
    underTreatment = 301,               // 진료중
    treatmentCompletePayType_1 = 302,   // 진료완료(수납전)
    treatmentCompletePayType_2 = 303,   // 진료완료(자동결제)
    treatmentCompletePayType_3 = 304,   // 진료완료(일반결제)
    treatmentAllComplete = 400,         // 진료완료 + 수납완료
}

export enum OoKakaoQuickStatus {
    unknown = 0,                                //카카오 퀵 신청 아님
    reserved = 10,                              //예약 (미사용)
    request = 11,                               //카카오 퀵 배송 오더 정보는 만들었으나 실제로 신청은 안함
    matching = 100,                             //배정중
    reorder = 101,                              //배정실패 후 재배정 신청 (배정중 상태와 같음)
    matched = 102,                              //배정완료
    pickupStarted = 103,                        //픽업시작
    pickupCompleted = 104,                      //픽업완료
    dropOffStarted = 105,                       //배송시작
    dropOffCompleted = 106,                     //배송완료
    matchingFailed = 200,                       //배정실패
    cancelBeforePayment = 201,                  //결제전 배송취소
    cancelAfterPaymentBeforeMatching = 202,     //결제 후 배정전 취소
    cancelAfterPaymentAfterMatching = 203,      //결제 후 배정후 취소 (배송비 환불 일어남)
    cancelMatchingFailed = 204,                 //배정실패 후 배송 취소
    pharmacyPickup = 205,                       //배정실패 후 방문 수령
    operatorCancel = 206,                       //CS 배송 취소 (취소 수수료 결제 일어남)
    aborted = 207,
    feePayFailed = 208                          //수수료 결제 실패
}

export enum OoDiagnosisPayType {
    appPay = 0,         // 앱 자동결제 (default)
    kioskPay = 1,       // 키오스크 결제
    descPay = 2         // 병원 데스크 결제
}

export enum OoHospitalMedicineType {
    empty = 0,
    diagnosis = 1,              // 약국약
    hospitalMedicine = 2,       // 병원약
    both = 3                    // 약국약 +  병원약
}
