import styled from "styled-components";
import React from "react";
import { MainListContainerStyle } from "pages";

interface Props extends MainListContainerStyle {
    title: string,
    children: JSX.Element,
}

interface WrapperStyle extends MainListContainerStyle {
  title: string,
}

export const MainListTitle = ({title, children , maxWidth, minWidth}: Props) => {
    return (
        <Wrapper title={title} minWidth={minWidth} maxWidth={maxWidth}>
            <Title>{title}</Title>
            <>
              {children}
            </>
        </Wrapper>
    )
}

const Wrapper = styled.div<WrapperStyle>`
  width: 100%;
  max-width: ${props => props.maxWidth}px;
    min-width: ${props => props.minWidth}px;
  height: 80px;
  background: ${(props) => props.theme.colors.bgColor.white};
  border: 0.5px solid ${(props) => props.theme.colors.borderColor.grey};
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`;

const Title = styled.div`
  width: fit-content;
  height: 80px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 32px;
`;