import React, {useEffect} from "react";
import classNames from "classnames/bind";
import SelectStyles from "../../../../styles/common/Select.module.scss";
import styled from "styled-components";
import {useTargetHospital} from "../hooks/useTargetHospital";
import {HospitalSearchField} from "../../../hospitalSearchField";
import {PharmacyTargetHospital} from "../../../../interfaces";
import {PharmacyDeliveryType} from "@models/pharmacy";
import {CustomInputText} from "@components/custom";

interface Props {
    onChanged: (data: PharmacyTargetHospital) => void;
}

export const PharmacyRegisterTargetHospital = ({onChanged}: Props) => {
    const sx = classNames.bind(SelectStyles);
    const {
        deliveryTodayHidden,
        deliverPackageHidden,
        selectedHospital,
        setHospitalList,
        targetHospitalCompactList,
        items,
        setItems,
        onHospitalChange,
        onCheckedToday,
        onCheckedPackage,
        onChangeTodayPrice,
        onChangePackagePrice,
        onSelectedHitCount,
        onChangeTodayKeywordList,
    } = useTargetHospital();

    useEffect(() => {
        onChanged(items);
    }, [items, onChanged]);

    useEffect(() => {
        setItems({...items, hospitalIdList: [selectedHospital]});
    }, [selectedHospital]);

    useEffect(() => {
        setHospitalList(targetHospitalCompactList.data || []);
    }, [targetHospitalCompactList.data]);

    return (
        <Wrapper>
            <div className="select-container">
                <HospitalSearchField onChange={onHospitalChange}/>
            </div>
            <div className="common-div">
                <div className="title">배송 옵션 [필수]</div>
                <InputContainer>
                    <div>
                        <input type="checkbox"
                               id={`${PharmacyDeliveryType.today}`}
                               onChange={onCheckedToday}/>
                        <label htmlFor={`${PharmacyDeliveryType.today}`}>당일배송</label>
                        <CustomInputText value={`${items.deliveryTodayPrice}`}
                                         size="xSmall"
                                         color={deliveryTodayHidden ? "grey" : "blue"}
                                         placeholder="가격 입력"
                                         onChanged={onChangeTodayPrice}
                                         disabled={!deliveryTodayHidden}/>
                    </div>
                    <div>
                        <input type="checkbox"
                               id={`${PharmacyDeliveryType.package}`}
                               onChange={onCheckedPackage}/>
                        <label htmlFor={`${PharmacyDeliveryType.package}`}>택배</label>
                        <CustomInputText value={`${items.deliveryPackagePrice}`}
                                         size="xSmall"
                                         color={deliverPackageHidden ? "grey" : "blue"}
                                         placeholder="가격 입력"
                                         onChanged={onChangePackagePrice}
                                         disabled={!deliverPackageHidden}/>
                    </div>
                </InputContainer>
            </div>
            <div className="common-div">
                <div className="title">당일 배송 가능지역 설정[필수]</div>
                <CustomInputText value={items.todayRegionKeywordList[0] || ""}
                                 size="large"
                                 color={"blue"}
                                 placeholder=",로 구분하여 동읍면리를 입력해 주세요. [필수]"
                                 onChanged={onChangeTodayKeywordList}
                                 disabled={true}/>
            </div>
            <div className="common-div">
                <div className="title">히트카운트[필수]</div>
                <select className={sx("select", "middle", "blue")}
                        onChange={onSelectedHitCount}
                        defaultValue="">
                    <DisabledOption value="" disabled>
                        검색어 노출 개수를 선택해 주세요.
                    </DisabledOption>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                </select>
            </div>
        </Wrapper>
    );
};

const DisabledOption = styled.option`
  &:disabled {
    display: none;
  }
`;

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  height: 370px;

  .select-container {
    padding-top: 8px;
    position: relative;
  }

  .common-div {
    padding-top: 28px;
  }

  .title {
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > div {
    padding-right: 23px;
    display: flex;
    align-items: center;

    & > label {
      margin-right: 8px;
      font-weight: 700;
      font-size: 13px;
    }

    & > input {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
  }
`;
