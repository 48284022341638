import React from "react";
import {Page} from "react-pdf";
import './page.module.css';

interface Props {
    pageCount: number,
}

export const PrintHiddenPageList = ({pageCount}: Props) => {
    let arr = [];
    for (let i = 0; i < pageCount; i++) {
        arr[i] = i + 1;
    }
    return (
        <>
            {
                arr.map((item, index) => (
                    <React.Fragment key={index}>
                        <Page pageNumber={item} className="react-pdf__page"/>
                    </React.Fragment>
                ))
            }
        </>
    )
}

