import { atom } from "recoil";

export interface CalendarParamProp {
  id: string;
  beginTimestamp: string;
  endTimestamp: string;
  count?: string;
  diagnosisId?: string;
}

export const unopayCalendarParamState = atom<CalendarParamProp>({
  key: "unospay/doctorCalendarParam",
  default: {
    id: "",
    beginTimestamp: "",
    endTimestamp: "",
    count: "",
  },
});
