import {OoParamOAuth} from "@params/oauth";
import {IOoDataOAuth, IOoDataResponse} from "api/reponses";
import {AxiosInstance} from "axios";
import {endPoint} from "@request/base";

class DoctorOnAuthApi {
    constructor(private readonly axiosInstance: AxiosInstance) {
    }

    // OAuth 로그인
    async oauth(param: OoParamOAuth): Promise<IOoDataResponse<IOoDataOAuth>> {
        return await this.axiosInstance.post<IOoDataResponse<IOoDataOAuth>>(endPoint.auth.oauth, param);
    }
}

export {
    DoctorOnAuthApi
};
