import styled from "styled-components";
import {IOoDiagnosis, OoMedicineDeliveryType} from "@api/index";
import _ from "lodash";

interface Props {
    diagnosis: IOoDiagnosis,
}

export const KakaoFlexerInfo = ({diagnosis}: Props) => {
    return (
        <Wrapper>
            {
                _.isEqual(diagnosis.deliveryType, OoMedicineDeliveryType.kaKaoMobility)
                &&
                <>
                    <div className="top-container">
                        <div className="title">기사 정보</div>
                        <button className="button">기사 정보 조회</button>
                    </div>
                    <Table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>이름</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>전화번호</td>
                            <td>-</td>
                        </tr>
                        </tbody>
                    </Table>
                </>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;

  .top-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    width: 100px;
  }

  .button {
    width: 140px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D3D9E4;
    border-radius: 8px;
    margin-left: 8px;
    font-weight: 700;
    font-size: 13px;

    &:disabled {
      color: #D3D9E4;
    }
  }
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: 100%;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  & tbody tr {
    height: 47px;
  }

  & tbody tr:nth-child(1), tr:nth-child(2) {
    border-bottom: 1px solid #c8d6f8;
  }

  & tbody tr td:nth-child(2n-1) {
    background: #f4f6fb;
    text-align: center;
    font-weight: 700;
    width: 100px;
    font-size: 13px;
  }

  & tbody tr td:nth-child(2) {
    padding: 10px;
    overflow: auto;
  }

`;