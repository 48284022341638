import {useQuery} from "react-query";
import _ from "lodash";
import {
    DoctorOnAPI,
    IOoDiagnosis,
    isRequestSucceed,
    OoParamAddMedicinePrice,
    OoParamFamilyAddMedicinePrice
} from "../../api";
import {useSuspenseHook} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {pharmacyMedicinePriceSubmit} from "../../recoils/pharmacy/pharmacyMedicinePriceSubmit";
import {toast} from "react-toastify";

interface TelemedicineProps {
    ownerData: IOoDiagnosis,
    priceList: Array<OoParamAddMedicinePrice>,
    updateQuery: boolean,
}

export const usePharmacyTelemedicineUpdate = ({ownerData, priceList, updateQuery}: TelemedicineProps) => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const navigate = useNavigate();

    const setUpdateQueryTrigger = useSetRecoilState(pharmacyMedicinePriceSubmit);

    return useQuery(
        'pharmacy/telemedicineUpdate',
        async () => {
            try {
                setLoading();
                let response;
                let familyResponse;
                if (_.gte(priceList.length, 2)) {
                    familyResponse = await DoctorOnAPI.shared.familyTelemedicine.addMedicinePrice(
                        new OoParamFamilyAddMedicinePrice(ownerData.familyUserId || '', priceList));
                } else {
                    response = await DoctorOnAPI.shared.telemedicine.addMedicinePrice(priceList[0]);
                }
                setStopLoading();
                if (!_.isUndefined(response)) {
                    if (isRequestSucceed(response)) {
                        toast.success(`${ownerData.patientName}님의 약제비 전송되었습니다.`);
                        navigate('/home');
                    }
                }
                if (!_.isUndefined(familyResponse)) {
                    if (isRequestSucceed(familyResponse)) {
                        toast.success(`${ownerData.patientName} 외 ${ownerData.familyDiagnosisCount! - 1}명의 약제비가 전송되었습니다.`);
                        navigate('/home');
                    }
                }
                setUpdateQueryTrigger(false);
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        }, {
            enabled: updateQuery,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always",  // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )
};

