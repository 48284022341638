import {AbstractOoParam} from "../base";
import {OoParamDiagnosisStatus} from "@api/params";

class OoParamFamilyChangeStatus extends AbstractOoParam {
    public readonly statusList: Array<OoParamDiagnosisStatus>

    constructor(statusList: Array<OoParamDiagnosisStatus>) {
        super();
        this.statusList = statusList;
    }
}

export {
    OoParamFamilyChangeStatus
}