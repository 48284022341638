import styled from "styled-components";
import packageJson from "../../../../package.json";
import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ListTitle } from "./components/ListTitle";
import { List } from "./components/List";
import _, { set } from "lodash";
import { PaymentSearch } from "./components/PaymentSearch";
import { PaymentCalendar } from "./components/PaymentCalendar";
import { selectedUserState } from "@recoils/oauth";
import {
  unospayCalendarRangeListState,
  unospayListState,
  selectMenuNumberState,
  selectOrderPlatformNumberState,
  selectProviderCodeState,
} from "@recoils/unospay";
import { unospaySearchByNameState } from "@recoils/search/unospaySearchByNameState";
import { useUnospayStatusFilter } from "@hooks/unospay";
import { useUnospayRtdbHook } from "@hooks/realtimeDatabase";
import { OoDeliveryStatus, OoOrderPlatformType } from "@models/unospay";
import { MainListTitle } from "@components/mainListTitle";
import ReactExcelDownload from "./components/ExcelDownloadButton";
import ExcelDownloadButton from "./components/ExcelDownloadButton";
import { useUnospayOrderPlatformFilter } from "@hooks/unospay/useUnospayOrderPlatformFilter";
import { ExcelRawDataButton } from "./components/ExcelRawDataButton";
import { ExcelDownloadModal } from "./components/ExcelDownloadModal";
import { changeDownloadPlatformVisibleState } from "@recoils/unospay/changeDownloadPlatform";
import { useChangeOrderPlatformQuery } from "@queries/unospay/changeOrderPlatformQuery";
import { useUnospayProviderListQuery } from "@queries/unospay/unospayProviderListQuery";
import { useUnospayProviderFilter } from "@hooks/unospay/useUnospayProviderFilter";
import { IconButton } from "./components/IconButton";
import moment from "moment";
import ProductOrderAddModal from "./components/productOrderAddModal";
import LoadingSpinner from "@components/LoadingSpinner";
import { useHospitalListQuery } from "@queries/hospital";

import { IOoProducts } from "@api/models/productSetting/product";
import { DoctorOnAPI } from "@api/request/doctorOnAPI";
import { useTlcProductList } from "@queries/unospay/tlcProductQuery";

export const UnosPayDashBoardPage = () => {
  const selectedUser = useRecoilValue(selectedUserState);
  const [showProductOrderAddModal , setShowProductOrderAddModal] = useState(false);
  const selectedUserName = useMemo(() => {
    return selectedUser?.user.name ?? "";
  }, [selectedUser?.user.name]);

  const deliveryList = useRecoilValue(unospayListState);
  const searchList = useRecoilValue(unospaySearchByNameState);
  const calendarList = useRecoilValue(unospayCalendarRangeListState);

  const [selectMenuNumber, setSelectMenuNumber] = useRecoilState(selectMenuNumberState);
  const onSelectFilterMenu = (e: any) => {
    const { value } = e.target;
    setSelectMenuNumber(_.toNumber(value));
  };

  const [selectOrderPlatformNumber, setSelectOrderPlatformNumber] = useRecoilState(
    selectOrderPlatformNumberState
  );
  const onSelectOrderPlatformNumber = (e: any) => {
    const { value } = e.target;
    setSelectOrderPlatformNumber(_.toNumber(value));
  };

  const [selectProviderCode, setSelectProviderCode] = useRecoilState(selectProviderCodeState);
  const onSelectProviderFilter = (e: any) => {
    const { value } = e.target;
    setSelectProviderCode(_.toString(value));
  };

  const [selectProvider, setSelectProvider] = useState<number>(1);
  const onSelectProvider = (e: any) => {
    const { value } = e.target;
    setSelectProvider(_.toNumber(value));
  };

  // const [productList, setProductList] = useState<IOoProducts[]>([]);

  // const { data: productList, isLoading: isProductLoading } = useTlcProductList();
  const { data: providerList, isLoading: isProviderLoading } = useUnospayProviderListQuery();
  // const { data: hospitalList, isLoading: isHospitalLoading } = useHospitalListQuery();
  const { connect, disconnect } = useUnospayRtdbHook();

  // console.log('isProductLoading :: ', isProductLoading);
  // console.log('isProviderLoading :: ', isProviderLoading);
  // console.log('isHospitalLoading :: ', isHospitalLoading);

  // 모든 API 호출이 완료되었는지 확인
  // const isLoading = isProductLoading || isProviderLoading || isHospitalLoading || 
  //                   !productList || !providerList || !hospitalList;

  // console.log('isLoading :: ', isLoading);

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     const products = await useTlcProductList();
  //     console.log('products :: ', products);
  //     setProductList(products.data || []);
  //   };
  //   fetchProducts();
  // }, []);

  // useEffect(() => {
  //   console.log('h :: ', productList);
  // }, [productList]);

  const statusList = useMemo(() => {
    if (_.gt(searchList.list.length, 0)) {
      const temp = useUnospayStatusFilter({
        number: selectMenuNumber,
        list: searchList.list,
      });
      const orderPlatformTemp = useUnospayOrderPlatformFilter({
        number: selectOrderPlatformNumber,
        list: temp,
      });
      return useUnospayProviderFilter({
        exCode: selectProviderCode,
        list: orderPlatformTemp,
      });
    } else if (_.gt(calendarList.length, 0)) {
      const temp = useUnospayStatusFilter({
        number: selectMenuNumber,
        list: calendarList,
      });
      const orderPlatformTemp = useUnospayOrderPlatformFilter({
        number: selectOrderPlatformNumber,
        list: temp,
      });
      return useUnospayProviderFilter({
        exCode: selectProviderCode,
        list: orderPlatformTemp,
      });
    } else {
      const temp = useUnospayStatusFilter({
        number: selectMenuNumber,
        list: deliveryList,
      });
      const orderPlatformTemp = useUnospayOrderPlatformFilter({
        number: selectOrderPlatformNumber,
        list: temp,
      });
      return useUnospayProviderFilter({
        exCode: selectProviderCode,
        list: orderPlatformTemp,
      });
    }
  }, [
    selectMenuNumber,
    deliveryList,
    searchList,
    calendarList,
    selectOrderPlatformNumber,
    selectProviderCode,
  ]);

  //정렬 순서
  const customOrder = ["OF04", "OF10", "OF07", "OF05", "OF18", "OF26", "OF13"];

  function customSort(a: { externalCode: string }, b: { externalCode: string }) {
    const indexA = customOrder.indexOf(a.externalCode);
    const indexB = customOrder.indexOf(b.externalCode);

    if (indexA !== -1 || indexB !== -1) {
      // 둘 중 하나라도 우선 순위에 있는 경우
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    }
    // 나머지는 가나다순
    return a.externalCode.localeCompare(b.externalCode);
  }
  const sortedProviderList = providerList && [...providerList].sort(customSort);

  const [modalVisible, setModalVisible] = useRecoilState(changeDownloadPlatformVisibleState);

  useEffect(() => {
    connect("unosPay");

    return () => disconnect();
  }, []);

  // const url = `http://localhost:3000/unospayHdOrder/${moment().format('YYYY-MM-DD').toString()}`;
  const onHandleOpenPopUp = (path: string) => {
    const width = 1200;
    const height = 700;

    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    const url = `https://doctor-on-react.web.app/${path}`;
    // const url = `https://doctor-on-react-test.web.app/${path}`;
    // const url = `http://localhost:3000/${path}`;

    switch (path) {
      case "unospayAllMealsSchedule":
        return window.open(
          url,
          "상세페이지",
          `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`
        );
      case "unospayHdOrder" :
        return window.open(
          `${url}/${moment().format("YYYY-MM-DD").toString()}`,"상세페이지",
          `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`
        );
        case "companyPopup": 
        return window.open(
          url,
          "상세페이지",
          `width=${width},height=${600},left=${left},top=${top},scrollbars=yes`
        );
    }
  };

  return (
    <Wrapper>
        <>
          {showProductOrderAddModal && (
            <ProductOrderAddModal 
              showProductOrderAddModal={showProductOrderAddModal} 
              setShowProductOrderAddModal={setShowProductOrderAddModal}
            />
          )}
          <ExcelDownloadModal
            modalVisible={modalVisible}
            title="발주서 다운로드"
            contents="발주서를 다운로드 받으실 업체를 선택해주세요."
            selectValue={selectProvider}
            cancelButtonName="취소"
            submitButtonName="다운로드"
            onChangedVisible={setModalVisible}
            onChangedTrigger={onSelectProvider}
            list={statusList}
          />
          <TopRowContainer>
            <div>현재 버전 v{packageJson.version}</div>
            <SearchContainer>
              <ExportAlign>
                <IconButton
                  title="수기주문등록"
                  iconSrc="/img/ic-add-company.svg"
                  onClick={() => {
                    setShowProductOrderAddModal(true);
                  }}
                />
              </ExportAlign>
              <ExportAlign>
                <IconButton
                  title="업체등록"
                  iconSrc="/img/ic-add-company.svg"
                  onClick={() => {
                    onHandleOpenPopUp('companyPopup');
                  }}
                />
              </ExportAlign>
              <ExportAlign>
                <IconButton
                  title="그리팅스케쥴"
                  iconSrc="/img/ic-reset-archive.svg"
                  onClick={() => {
                    onHandleOpenPopUp("unospayAllMealsSchedule");
                  }}
                />
              </ExportAlign>
              <ExportAlign>
                <IconButton
                  title="그리팅 연동"
                  iconSrc="/img/ic-reset-archive.svg"
                  onClick={() => {
                    onHandleOpenPopUp("unospayHdOrder");
                  }}
                />
              </ExportAlign>
              <ExportAlign>
                <IconButton
                  title="필터 초기화"
                  iconSrc="/img/ic-reset-archive.svg"
                  onClick={() => {
                    setSelectMenuNumber(-1);
                    setSelectOrderPlatformNumber(-1);
                    setSelectProviderCode("-1");
                  }}
                />
              </ExportAlign>
              <ExportAlign>
                <ExcelRawDataButton 
                  list={statusList}
                  // productList={productList}
                  // hospitalList={hospitalList}
                  // providerList={providerList}
                />
              </ExportAlign>
              <ExportAlign>
                <ExcelDownloadButton
                  list={statusList}
                  select={selectProvider}
                  onClicked={() => setModalVisible(true)}
                ></ExcelDownloadButton>
                <PaymentSearch />
              </ExportAlign>
            </SearchContainer>
          </TopRowContainer>
          <MainListTitle
            title={`${selectedUserName} 관리자님 조회된 주문내역 입니다! (${statusList.length}건)`}
            maxWidth={1280} minWidth={1024}
          >
            <CalendarContainer>
              <ComboListLabel>업체 :</ComboListLabel>
              <SelectContainer>
                <select
                  className="select"
                  onChange={onSelectProviderFilter}
                  value={selectProviderCode}
                >
                  <option value="-1">전체보기</option>
                  {sortedProviderList &&
                    sortedProviderList.map((item) => (
                      <option key={item.id} value={item.externalCode}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </SelectContainer>
              <Hr />
              <ComboListLabel>상태 :</ComboListLabel>
              <SelectContainer>
                <select className="select" onChange={onSelectFilterMenu} value={selectMenuNumber}>
                  <option value="-1">전체보기</option>
                  <option value={OoDeliveryStatus.sendPackage}>발송 완료</option>
                  <option value={OoDeliveryStatus.existAddress}>배송정보 입력 완료</option>
                  <option value={OoDeliveryStatus.emptyAddress}>배송정보 미입력</option>
                  <option value={OoDeliveryStatus.cancelOrder}>주문 취소</option>
                </select>
              </SelectContainer>
              <Hr />
              <ComboListLabel>주문처 :</ComboListLabel>
              <SelectContainer>
                <select
                  className="select"
                  onChange={onSelectOrderPlatformNumber}
                  value={selectOrderPlatformNumber}
                >
                  <option value="-1">전체보기</option>
                  <option value={OoOrderPlatformType.kiosk}>키오스크 주문</option>
                  <option value={OoOrderPlatformType.mtmMall}>MTM몰 주문</option>
                  <option value={OoOrderPlatformType.telephone}>전화 주문</option>
                  <option value={OoOrderPlatformType.test}>테스트 주문</option>
                  <option value={OoOrderPlatformType.noTest}>테스트 주문 제외</option>
                </select>
              </SelectContainer>
              <PaymentCalendar />
            </CalendarContainer>
          </MainListTitle>
          <ListTitle />
          <MainContainer>
            <List list={statusList} />
          </MainContainer>
        </>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 90%;
`;

const TopRowContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  color: #bbbbbb;
  font-size: 12px;
  font-weight: 400;
`;

const SearchContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  display: flex;
  align-items: center;
  //margin: 0 auto;
  justify-content: right;
  //justify-content: end;
  padding-bottom: 8px;
  color: gray;
  font-size: 13px;
  font-weight: 700;
`;

const ExportAlign = styled.div`
  display: flex;
  align-items: center;
  //padding-right: 26px;
`;

const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 26px;
`;

const SelectContainer = styled.div`
  padding-right: 8px;
  font-size: 13px;

  .select {
    border-radius: 6px;
    height: 36px;
    border: 0.5px solid #c2c1bd;
    padding: 5px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  height: 100%;
  overflow: scroll;
  display: flex;
  justify-content: center;
`;

const StatusContainer = styled.div`
  overflow: scroll;
  width: 100%;
  max-width: 100%;
  min-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusRow = styled.div`
  background: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px -2px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 4px;
  width: 100%;

  & > a:first-child {
    width: 100%;
    height: 64px;
    color: #030303;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ComboListLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  margin: 10px 10px;
`;

const Hr = styled.div`
  height: 40%;
  margin: 10px 10px;
  width: 2px;
  border-width: 0;
  color: #c6c7c8;
  background-color: #c6c7c8;
`;
