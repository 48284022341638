import { IOoallMealsSchedule } from '@api/models/unospay/allMealsSchedule';
import { IOoMealDetail } from '@api/models/unospay/mealDetail';
import { OoUnospayPostMealDetail } from '@api/params/unospay/postMealDetail';
import { DoctorOnAPI } from '@api/request';
import styled, { CSSProperties } from "styled-components";
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router';
import ClipLoader from "react-spinners/ClipLoader";
import moment from 'moment';


const MealsScheduleItemPopup = () => {
  const {pathname} = useLocation();
  const [resMealDetail, setResMealDetail] = useState<IOoMealDetail>();
  let [loading, setLoading] = useState(true);
  const [color ,setColor] = useState('#19ce60')

  const override: CSSProperties = {
    display: "block",
    marginTop: window.screenY/2,
  };

  useEffect(()=>{
    if(pathname){
      const mealCode:string = pathname.split('/')[2];
      const itemCode:string = pathname.split('/')[3];
      const searchDate:string = pathname.split('/')[4];

      const getmealDetail = async () => {
        try {
          const param = new OoUnospayPostMealDetail();
          param.mealCode = mealCode;
          param.itemCode = itemCode;
          param.searchDate = moment(searchDate).format('YYYY-MM-DD');
          const response = await DoctorOnAPI.shared.unospay.postmealDetail(param)
          setResMealDetail(response.data);
          setLoading(false)
        } catch (error) {
          console.log(error);
          
        }
      }
      getmealDetail();
    }
  },[])
  
  return (
    
    <Container>
      {loading ? <ClipLoader color={color} loading={loading} cssOverride={override} size={50} aria-label="Loading Spinner" data-testid="loader" /> 
      : 
      resMealDetail ? 
        <MealContainer>
          <h3>{resMealDetail.itemName}</h3>
          <MealDesContainer>
          <span>코드번호 : {resMealDetail.itemCode}</span>
          <span>보관 : {resMealDetail.detl.keepType}</span>
          <span>칼로리 : {resMealDetail.detl.calorie}kal</span>
          </MealDesContainer>
          <MealImageListCotainer>
            {resMealDetail?.detl.descImgUrls.map(url => <img src={url} />  )}
          </MealImageListCotainer>
        </MealContainer>
        :<div>데이터가 없습니다.</div> 
      }

    </Container>
  )
}

export default MealsScheduleItemPopup

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const MealContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`


const MealDesContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`

const MealImageListCotainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  row-gap: 20px;
`
