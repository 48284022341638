import {useRecoilValue} from "recoil";
import {useMemo, useState} from "react";
import {useCardEdit} from "@hooks/common";
import {IOoDoctor} from "@models/doctor";
import {editSelectorFamily} from "@recoils/status";
import { useDoctorFunctionHook } from "@hooks/doctor";

export const useDoctorVideoSettingHook = (data: IOoDoctor, componentName: string) => {
    const {updateDoctor} = useDoctorFunctionHook();
    const {isReset} = useCardEdit(componentName);
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const [color, setColor] = useState("white");

    const initialData = useMemo(() => {
        return {
            isVideoDiagnosis: data.isVideoDiagnosis || false,
            videoId: data.videoId || "-",
            videoLink: data.videoLink || "-",
            videoPassword: data.videoPassword || "-",
        }
    }, [data]);

    const [items, setItems] = useState(initialData);

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setItems({...items, [name]: value});
    };


    return {
        editStatus,
        color,
        setColor,
        handleChange,
        items,
        setItems,
        isReset,
        updateDoctor,
        initialData,
    }
}