import {ModalOverlay} from "../modalOverlay";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import _ from "lodash";

interface Props{
    visible: boolean,
    targetTitle:string,
    onChangedVisible:(status:boolean) => void,
    onChangedSubmit: (status:boolean) => void,
}

export const DeleteModal = ({visible,targetTitle,onChangedVisible,onChangedSubmit}:Props) =>{

    const [handleVisible, setHandleVisible]= useState<boolean>(visible);
    const [handleDelete, setHandleDelete] =useState<boolean>(false);

    const onDeleteTarget = () => setHandleDelete(!handleDelete);

    useEffect(()=>{
        setHandleVisible(visible);
    },[visible]);

    useEffect(()=>{
        onChangedVisible(handleVisible);
    },[handleVisible]);

    useEffect(()=>{
        _.isEqual(handleDelete,true) && onChangedSubmit(handleDelete);
    },[handleDelete]);


    return (
        <ModalOverlay visible={handleVisible}>
            <Card>
                <TitleContainer>
                    <div>{targetTitle}을(를) 삭제 하시겠습니까?</div>
                    <div>선택하신 {targetTitle}이(가) 삭제됩니다. 이 동작은 취소 할 수 없습니다.</div>
                </TitleContainer>
                <ButtonContainer>
                    <CancelButton type="button" onClick={()=>setHandleVisible(!handleVisible)}>취소</CancelButton>
                    <SubmitButton type="button" onClick={onDeleteTarget}>{targetTitle} 삭제하기</SubmitButton>
                </ButtonContainer>
            </Card>
        </ModalOverlay>
    )
}

const Card = styled.div`
  width: 556px;
  height: 240px;
  background:#FFFFFF;
  border-radius: 8px;
  padding: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    
  & > div:nth-child(1){
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 20px;
  }
  
  & > div:nth-child(2){
    font-weight: 500;
    font-size: 13px;
    padding-bottom: 36px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const CancelButton = styled.button`
  width: 222px;
  height:60px;
  background: #CF5D5D;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  border:none;
`;


const SubmitButton = styled.button`
  width: 222px;
  height:60px;
  background: #6E8ED1;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  border:none;
`;
