import React, {useMemo} from "react";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import classNames from "classnames/bind";
import buttonStyles from "../../../styles/common/Button.module.scss";
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {Button} from "react-bootstrap";
import {CustomCard} from "@components/custom";
import {
    BankInfo,
    PharmacyAdditionalInfo,
    PharmacyAlimTalk,
    PharmacyInfo,
    PharmacyTargetHospital,
    PharmacyWorkHours
} from "@components/infoForm";
import {pharmacyListState} from "@recoils/pharmacy";
import {useModalHook} from "@hooks/modal";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";

export const PharmacyInfoDetail = () => {
    const bx = classNames.bind(buttonStyles);

    const {getPharmacyById, deletePharmacy} = usePharmacyFunctionHook();

    const location = useLocation();
    const id = _.get(location.state, "data") as string;
    const list = useRecoilValue(pharmacyListState);

    const pharmacyData = useMemo(() => {
        return getPharmacyById(id);
    }, [list]);

    const {addModal} = useModalHook();
    const isDelete = (e: any) => {
        e && deletePharmacy(id);
    };

    return (
        <>
            {
                !_.isEmpty(list) && (
                    <Container>
                        <div>
                            <CustomCard buttonHidden={false}
                                        title="약국 기본 정보 [필수]"
                                        subTitle="프로필 이미지"
                                        children={<PharmacyInfo data={pharmacyData}/>}
                                        component="pharmacyInfo"/>
                            <CustomCard buttonHidden={false}
                                        title="약국 추가 정보 [필수]"
                                        children={<PharmacyAdditionalInfo data={pharmacyData}
                                                                          componentName="pharmacyAdditionalInfo"/>}
                                        component="pharmacyAdditionalInfo"/>
                            <CustomCard buttonHidden={false}
                                        title="알림톡 수신 전화번호 [필수]"
                                        children={<PharmacyAlimTalk data={pharmacyData} componentName="pharmacyAlimTalk"/>}
                                        component="pharmacyAlimTalk"/>
                            <CustomCard buttonHidden={false}
                                        title="지정 병원 [필수]"
                                        children={<PharmacyTargetHospital data={pharmacyData}
                                                                          componentName="pharmacyTargetHospital"/>}
                                        component="pharmacyTargetHospital"/>
                            <CustomCard buttonHidden={false}
                                        title="정산 은행 정보 [필수]"
                                        children={<BankInfo data={pharmacyData}
                                                            target="pharmacy"
                                                            componentName="pharmacyBankInfo"/>}
                                        component="pharmacyBankInfo"/>
                            <CustomCard buttonHidden={false}
                                        title="약국 운영시간"
                                        children={<PharmacyWorkHours data={pharmacyData}
                                                                     componentName="OP_pharmacyWorkHours"/>}
                                        component="OP_pharmacyWorkHours"/>
                        </div>
                        <ButtonContainer>
                            <Button type="button"
                                    onClick={() =>
                                        addModal({
                                            type: 'twoBtnModal',
                                            data: {
                                                title: "약국을 삭제하시겠습니까?",
                                                contents: <div>선택하신 약국이 삭제됩니다. 이 동작은 취소 할 수 없습니다.</div>,
                                                buttons: [{title: "취소"}, {
                                                    title: "삭제하기",
                                                    callback: (e: any) => isDelete(e)
                                                }],
                                                goBack: false,
                                            }
                                        })}
                                    className={bx("diagnosis-cancel-button")}>
                                삭제
                            </Button>
                        </ButtonContainer>
                    </Container>
                )}
        </>
    );
};

const Container = styled.div`
    padding: 70px 0px;
    height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
`;
