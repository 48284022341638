import { IOoUnospayProvider } from "@api/models/unospay/provider";
import { atom } from "recoil";

export const selectMenuNumberState = atom<number>({
  key: "selectMenuNumberState",
  default: -1,
});

export const selectOrderPlatformNumberState = atom<number>({
  key: "selectOrderPlatformNumberState",
  default: -1,
});

export const selectProviderCodeState = atom<string>({
  key: "selectProviderNumberState",
  default: "",
});

export const providerListState = atom<Array<IOoUnospayProvider>>({
  key: "providerListState",
  default: [],
});
