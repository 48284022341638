import {useQuery} from "react-query";
import {useSuspenseHook} from "@hooks/suspense";
import {OoDailyTargetDiagnosisRecordRange} from "@params/telemedicine/dailyTargetDiagnosisRecordRange";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";
import {toast} from "react-toastify";
import _ from "lodash";
import {useRecoilState, useSetRecoilState} from "recoil";
import {dailyDiagnosisListState, dailyDiagnosisSelectedState} from "@recoils/telemedicine";
import moment from "moment";
import {triggerState} from "@recoils/calendar";

interface DailyListProps {
    doctorId: string;
    beginTimestamp: string;
    endTimestamp: string;
    count?: string;
    diagnosisId?: string;
    updateQueryTrigger: boolean;
}

export const useSelectedDoctorDiagnosisListQuery = ({
                                                        doctorId,
                                                        beginTimestamp,
                                                        endTimestamp,
                                                        count,
                                                        diagnosisId,
                                                        updateQueryTrigger,
                                                    }: DailyListProps) => {
    const target = 'doctor';
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const setDailyDiagnosisSelectData = useSetRecoilState(dailyDiagnosisSelectedState(target));
    const setDailyDiagnosisList = useSetRecoilState(dailyDiagnosisListState);

    const [calendarQueryTrigger, setCalendarQueryTrigger] = useRecoilState(triggerState);

    return useQuery(
        "doctor/dailyDiagnosisList",
        async () => {
            try {
                setLoading();
                const params = new OoDailyTargetDiagnosisRecordRange(
                    doctorId,
                    beginTimestamp,
                    endTimestamp,
                    diagnosisId,
                    count
                );
                const response = await DoctorOnAPI.shared.telemedicine.dailyDoctorDiagnosisRecordRange(params);
                setStopLoading();
                setCalendarQueryTrigger({...calendarQueryTrigger, doctorQuery: false});
                if (isRequestSucceed(response)) {
                    if (_.isEmpty(response.data)) {
                        toast.error("검색 결과가 없습니다.");
                        setDailyDiagnosisSelectData({
                            id: target,
                            date: [moment().format(`YYYY.MM.DD`), moment().format(`YYYY.MM.DD`)]
                        });
                        setDailyDiagnosisList([]);
                        return;
                    }
                    setDailyDiagnosisList(response.data!);
                    return response.data;
                }
                return null;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: updateQueryTrigger,
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false, // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    );
};
