import {useMutation} from "react-query";
import {DoctorOnAPI, isRequestSucceed} from "../../api";
import {OoParamPharmacyCreate} from "../../api/params/pharmacy";
import {useSuspenseHook} from "../../hooks";


export const usePharmacyCreateQuery = () => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();

    return useMutation(
        'operator/pharmacyCreate',
        async (param: OoParamPharmacyCreate) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.pharmacy.create(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
    )
};