import {ChangeEvent, useState} from "react";
import {DoctorBankInfo} from "../../../../interfaces";

export const useBankInfoSetting = () => {
    const [items, setItems] = useState<DoctorBankInfo>({
        bank: "",
        bankAccount: "",
        bankHolderName: "",
    });

    const onChangeBank = (e: ChangeEvent<HTMLInputElement>) => setItems({...items, bank: e.target.value});
    const onChangeBankAccount = (e: ChangeEvent<HTMLInputElement>) => setItems({...items, bankAccount: e.target.value});
    const onChangeBankHolder = (e: ChangeEvent<HTMLInputElement>) => setItems({...items, bankHolderName: e.target.value});

    return {
        items,
        onChangeBank,
        onChangeBankAccount,
        onChangeBankHolder
    }
}