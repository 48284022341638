import {atom, atomFamily, DefaultValue, selectorFamily} from "recoil";

export interface EditInfo {
    id: string,
    isCancel: boolean,
    isEdit: boolean,
    isSubmit: boolean,
}

// export interface EditId extends EditInfo {
//     //type 에러로 인해 인덱스 시그니처 속성 추가, ESLint any 오류 피하기 위해 ignore 주석 추가 - bsh
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     [key: string]: any,
//     id: string,
//
// }

const doctorUpdateAtom = atom<Array<string>>({
    key: "doctorUpdateAtom",
    default: [],
});

// atom 과 atomFamily의 차이점은 default 값에 특정한 파라미터를 받을 수 있는 함수가 될 수 있음
// 동일한 형태의 atom을 생성해주는 팩토리 함수 제공 ( => 팩토리 함수를 리턴하는 함수를 리턴?)

// atomFamily는 어떤 atom들을 생성했는지 알 수가 없음 => atomFamily를 통해 생성된 atom의 키를 별도로 관리해줘야함
const doctorUpdateAtomFamily = atomFamily<EditInfo, string>({
    key: 'doctorUpdateAtomFamily',
    default: (id) => {
        const info: EditInfo = {
            id,
            isCancel: true,
            isEdit: false,
            isSubmit: false
        }
        return info;
    }
});

// selector는 set값을 이용해 쓰기 가능한 상태, selectorFamily는 set, get 모두 가능한 상태이며
// selectorFamily는 한 파라미터를 받아 이 파라미터를 이용해 작업을 수행하는 selector를 리턴하는 팩토리 함수를 리턴?

const doctorUpdateSelectorFamily = selectorFamily<EditInfo, string>({
    key: "doctorUpdateSelectorFamily",
    get: (id: string) => ({get}) => get(doctorUpdateAtomFamily(id)),
    set: (id: string) => ({get, set, reset}, editInfo) => {
        if (editInfo instanceof DefaultValue) {
            reset(doctorUpdateAtomFamily(id))
            set(doctorUpdateAtom, (prevValue) => prevValue.filter((item) => item !== id))
            return
        }
        set(doctorUpdateAtomFamily(id), editInfo)
        set(doctorUpdateAtom, (prev) => Array.from(new Set([...prev, id])))
    }
});

export {
    doctorUpdateAtom,
    doctorUpdateAtomFamily,
    doctorUpdateSelectorFamily
};
