abstract class AbstractOoParam {
    getPathParams(): string {
        return '';
    }
}

export {
    AbstractOoParam
};

