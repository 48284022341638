import styled from "styled-components";
import BarChartDashBoard from "./BarChartDashBoard";
import RankBarChartDashBoard from "./RankBarChartDashBoard";
import PieChartHospitalTypeDashBoard from "./PieChartHospitalTypeDashBoard";

interface Props {
  target: string,
}

const ChartDashBoard = (target:Props) => {
  
  
  return (
    <Wrapper>
      <PieChartHospitalTypeDashBoard />
      {/* <BarChartDashBoard />
      <RankBarChartDashBoard /> */}
    </Wrapper>
  )
}

export default ChartDashBoard;

const Wrapper = styled.div`
  width: 100%;
  max-width: 55%;
  min-width: 1400px;
  height: 100%;
  margin-bottom: 180px;
  overflow-y: scroll;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 40px;
  `

