import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {RecoilRoot} from "recoil";
import {QueryClient, QueryClientProvider} from 'react-query';
import {LoadingModal, SuspenseErrorModal} from '@components/suspense'
import ReactDOM from 'react-dom/client';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {ScrollTop} from '@components/scrollTop';
import ModalProvider from "@components/modalProvider";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <RecoilRoot>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <ScrollTop/>
                <ToastContainer pauseOnFocusLoss={false}/>
                <App/>
                <LoadingModal/>
                <SuspenseErrorModal/>
                <ModalProvider/>
            </BrowserRouter>
        </QueryClientProvider>
    </RecoilRoot>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
