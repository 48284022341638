import _ from "lodash";
import React, {useEffect, useState} from "react";
import {IOoDiagnosis, OoDiagnosisStatus, OoMedicineDeliveryType, OoParamAddMedicinePrice} from "../../../../../../api";
import {CustomInputText} from "@components/custom";
import styled from "styled-components";
import {getPharmacyMedicinePayStatus} from "@api/models/pharmacy";

interface Props {
    diagnosisData: IOoDiagnosis,
    prescriptionData: OoParamAddMedicinePrice,
    onChanged: (price: string, substitute: boolean) => void,
    agreeDUR: boolean,
}

export const PharmacyMedicinePrice = ({diagnosisData, onChanged, agreeDUR, prescriptionData}: Props) => {
    const prescriptionPayStatus = {prescriptionPay: getPharmacyMedicinePayStatus(diagnosisData)};
    const [medicinePayPrice, setMedicinePayPrice] = useState<string>(diagnosisData.medicinePayPrice || '');
    const [substituteChecked, setSubstituteChecked] = useState(!!diagnosisData.isSubstitute);
    const filteredMedicinePaymentState = () => {
        const filteredDeliveryStatus =
            !_.isEqual(diagnosisData.deliveryType, OoMedicineDeliveryType.unknown) &&
            !_.isEqual(diagnosisData.deliveryType, OoMedicineDeliveryType.user);
        return filteredDeliveryStatus && _.gte(diagnosisData.status, OoDiagnosisStatus.medicineDeliveryPaymentWait);
    }

    const onChangePrice = (e: any) => setMedicinePayPrice(e.target.value);
    const onChangeSubstituteChecked = (e: any) => setSubstituteChecked(e.target.checked);

    useEffect(() => {
        setMedicinePayPrice(_.toString(prescriptionData.price));
    }, [diagnosisData.id, prescriptionData]);


    useEffect(() => {
        onChanged(_.toString(medicinePayPrice), substituteChecked);
    }, [medicinePayPrice, substituteChecked]);

    return (
        <Wrapper>
            <div className="title">약제비</div>
            {filteredMedicinePaymentState() ?
                <div>
                    <AfterMedicinePayPriceContainer>
                        <span>약제비</span>
                        <span>{prescriptionPayStatus.prescriptionPay.title}</span>
                    </AfterMedicinePayPriceContainer>
                    <CheckBoxContainer>
                        <label>
                            <input type="checkbox"
                                   defaultChecked={agreeDUR}
                                   disabled={true}/>
                            <b>DUR 시스템 확인(필수)</b>
                        </label>
                        <label>
                            <input type="checkbox"
                                   defaultChecked={substituteChecked}
                                   disabled={true}/>
                            <b>대체약 조제 여부(선택)</b>
                        </label>
                    </CheckBoxContainer>
                </div>
                :
                <div>
                    <MedicinePayPriceContainer>
                        <div>약제비</div>
                        <div>
                            <CustomInputText size="small"
                                             value={medicinePayPrice}
                                             disabled={true}
                                             placeholder="0"
                                             onChanged={onChangePrice}
                            />
                            <div>{` `}원</div>
                        </div>
                    </MedicinePayPriceContainer>
                    <CheckBoxContainer>
                        <label>
                            <input type="checkbox"
                                   checked={true}
                                   disabled={agreeDUR}/>
                            <div>DUR 시스템 확인(필수)</div>
                        </label>
                        <label>
                            <input type="checkbox"
                                   checked={substituteChecked}
                                   onChange={onChangeSubstituteChecked}/>
                            <div>대체약 조제 여부(선택)</div>
                        </label>
                    </CheckBoxContainer>
                </div>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  .title {
    font-weight: 700;
    font-size: 16px;
  }
`;

const MedicinePayPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;

  & > div:nth-child(1) {
    font-weight: 700;
    font-size: 13px;
    padding-top: 2px;
  }

  & > div:nth-child(2) {
    width: 100%;
    font-weight: 700;
    font-size: 13px;
    display: flex;
    align-items: center;

    & > div {
      padding-left: 12px;
    }
  }
`;

const AfterMedicinePayPriceContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 16px;

  & > span:nth-child(1) {
    font-weight: 700;
    font-size: 13px;
    padding-top: 2px;
  }

  & > span:nth-child(2) {
    font-weight: 700;
    font-size: 13px;
    display: flex;
    align-items: center;
    color: #C4C4C4;
    justify-content: flex-end;
    width: 144px;
    height: 28px;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 16px;
  font-weight: 700;
  font-size: 13px;

  & > label:nth-child(1) {
    display: flex;
    align-items: center;

    & > input[type="checkbox"] {
      margin-right: 15px;
    }
  }

  & > label:nth-child(2) {
    margin-top: 13px;
    display: flex;
    align-items: center;

    & > input[type="checkbox"] {
      margin-right: 15px;
    }
  }
`;