import React, {useEffect} from "react";
import styled from "styled-components";
import _ from "lodash";
import {IOoDoctor} from "@models/doctor";
import {CustomTextArea} from "@components/custom";
import {OoParamDoctorUpdate} from "@params/doctor";
import {useDoctorCareerSettingHook} from "@components/infoForm";

interface Props {
    data: IOoDoctor,
    componentName: string,
    editButtonClick?: boolean,
    submitButtonClick?: boolean,
}

export const DoctorCareer = ({data, componentName}: Props) => {
    const {
        editStatus,
        color,
        setColor,
        items,
        setItems,
        handleChange,
        updateDoctor,
        isReset,
        initialData,
    } = useDoctorCareerSettingHook(data, componentName);

    useEffect(() => {
        setItems(initialData);
    }, [data]);

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        if (editStatus.isEdit) {
            setColor("blue");
        } else {
            setColor("grey");
            setItems(initialData);
        }
    }, [editStatus.isEdit]);

    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamDoctorUpdate();
            param.id = data.id || "";
            param.education = items.education;
            param.career = items.career as string;
            param.bio = items.bio as string;
            updateDoctor(param);
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            {/*<label>학력</label>*/}
            {/*<CustomTextArea disabled={editStatus.isEdit}*/}
            {/*                color={color}*/}
            {/*                name="education"*/}
            {/*                value={items.education}*/}
            {/*                onChanged={handleChange}*/}
            {/*                placeholder="[필수]"*/}
            {/*                size="middle"/>*/}
            {/*<label>경력</label>*/}
            {/*<CustomTextArea color={color}*/}
            {/*                name="career"*/}
            {/*                disabled={editStatus.isEdit}*/}
            {/*                value={items.career}*/}
            {/*                onChanged={handleChange}*/}
            {/*                placeholder="[필수]"*/}
            {/*                size="middle"/>*/}
            <label>소개</label>
            <CustomTextArea color={color}
                            name="bio"
                            disabled={editStatus.isEdit}
                            value={items.bio || ''}
                            onChanged={handleChange}
                            placeholder="[필수]"
                            size="middle"/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;

  & > label {
    font-size: 13px;
    font-weight: 700;
  }
`;