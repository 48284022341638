import {useSuspenseHook} from "../../hooks";
import {useQuery} from "react-query";
import {DoctorOnAPI, isRequestSucceed} from "../../api";
import {OoParamKakaoDevOpenOrderId} from "@api/params";

interface Props {
    queryTrigger: boolean,
    orderId: string,
    diagnosisId: string,
}

export const useKakaoOrderQuery = ({...props}: Props) => {
    const {queryTrigger, orderId, diagnosisId} = props;
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    return useQuery(
        'kakao/order',
        async () => {
            try {
                setLoading();
                const param = new OoParamKakaoDevOpenOrderId(orderId, diagnosisId);
                const response = await DoctorOnAPI.shared.kakaoMobility.devGetStatus(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: queryTrigger,
            retry: true,
        }
    )
}
