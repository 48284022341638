import {useSuspenseHook} from "../../hooks";
import {useQuery} from "react-query";
import {DoctorOnAPI, isRequestSucceed, OoParamReservationConfirm} from "../../api";
import {OoParamReservationUpdate} from "@params/reservation/update";

interface Props {
    patientId: string,
    reservationTime: number,
    updateQueryTrigger: boolean,
}

export const useReservationTimeUpdateQuery = ({patientId, reservationTime, updateQueryTrigger}: Props) => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    return useQuery(
        'reservation/timeUpdate',
        async () => {
            try {
                setLoading();
                const param = new OoParamReservationUpdate();
                param.id = patientId;
                param.reservationTime = reservationTime;
                const response = await DoctorOnAPI.shared.reservation.update(param);
                if (isRequestSucceed(response)) {
                    const confirmParam = new OoParamReservationConfirm();
                    confirmParam.id = patientId;
                    const confirmResponse = await DoctorOnAPI.shared.reservation.confirm(confirmParam);
                    setStopLoading();
                    return isRequestSucceed(confirmResponse) ? confirmResponse : response;
                }
                setStopLoading();
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        }, {
            enabled: updateQueryTrigger,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )
}