import { useMutation } from "react-query";
import OoUnospayAddressInfoUpdate from "../../api/params/unospay/update";
import { useSuspenseHook } from "../../hooks";
import { DoctorOnAPI, isRequestSucceed } from "../../api";
import { toast } from "react-toastify";
import { DeliverDetailInfo } from "../../pages/Operator/UnospayOrderDetailPage/components/DeliveryDetailInfo";
import { OrderCsInfo } from "pages/Operator/UnospayOrderDetailPage/components/OrderCsInfo";
import OoUnospayHospitalInfoUpdate from "@api/params/unospay/updateHospitalInfo";

export const useUpdateAddressInfoQuery = () => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

  return useMutation(
    "unospay/updateAddressInfo",
    async (items: DeliverDetailInfo) => {
      try {
        setLoading();
        const param = new OoUnospayAddressInfoUpdate();
        param.id = items.id || "";
        param.recipientName = items.recipientName;
        param.mobile = items.mobile;
        param.address = items.address;
        param.addressDetail = items.addressDetail;
        param.memo = items.memo;
        param.subMemo = items.subMemo;
        param.zipCode = items.zipCode;
        const response = await DoctorOnAPI.shared.unospay.updateAddressInfo(
          param
        );
        setStopLoading();
        if (isRequestSucceed(response)) {
          toast.success("배송 정보 수정 완료");
          return response;
        }
        return undefined;
      } catch (e: any) {
        throw e;
      }
    }
  );
};

export const useUpdateOrderCsInfoQuery = () => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

  return useMutation(
    "unospay/updateCsInfoAddressInfo",
    async (items: OrderCsInfo) => {
      try {
        setLoading();
        const param = new OoUnospayAddressInfoUpdate();
        param.id = items.id || "";
        param.csMemo = items.csMemo;
        const response = await DoctorOnAPI.shared.unospay.updateAddressInfo(
          param
        );
        setStopLoading();
        if (isRequestSucceed(response)) {
          toast.success("CS 메모 수정 완료");
          return response;
        }
        return undefined;
      } catch (e: any) {
        throw e;
      }
    }
  );
};

export const useUpdateHospitalInfoQuery = () => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

  return useMutation(
    "unospay/updateHospitalInfo",
    async (items: { id: string; code: string }) => {
      try {
        setLoading();
        const param = new OoUnospayHospitalInfoUpdate();
        param.id = items.id || "";
        param.code = items.code;
        const response = await DoctorOnAPI.shared.unospay.updateHospitalInfo(
          param
        );
        setStopLoading();
        if (isRequestSucceed(response)) {
          toast.success("병원 정보 수정 완료");
          return response;
        } else {
          return undefined;
        }
      } catch (e: any) {
        throw e;
      }
    }
  );
};
