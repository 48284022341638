import React from "react";
import styled from "styled-components";

interface Props {
    title: string
    isShowTag: boolean,
    tagTitle: string,
    flex?: number,
}


export const PatientInfoField = ({title, isShowTag, tagTitle, flex}: Props) => {
    return (
        <Wrapper flex={flex ?? 0}>
            <NameContainer>
                {
                    isShowTag &&
                    <BadgeContainer>
                        <Badge>
                            {tagTitle}
                        </Badge>
                        <br/>
                    </BadgeContainer>
                }
                <TitleContainer>{title}</TitleContainer>
            </NameContainer>
        </Wrapper>
    )
}

const Wrapper = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BadgeContainer = styled.div`
  display: flex;
`;

const Badge = styled.div`
  width: 72px;
  height: 20px;
  background: #4CA555;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
`;

const TitleContainer = styled.div`
  font-weight: 700;
  font-size: 12px;
`;