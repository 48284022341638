import React from "react";
import _ from "lodash";
import {useRecoilValue} from "recoil";
import {PharmacyErrorState} from "../../recoils";
import styled from "styled-components";

interface Props {
    onEdit: () => void;
    onCancel: () => void;
    onHidden: boolean;
    isValid?: boolean;
}

export const EditButton = ({onEdit, onCancel, onHidden, isValid}: Props) => {
    const errorState = useRecoilValue(PharmacyErrorState);
    return (
        <>
            <Wrapper>
                <button type="button" onClick={onEdit} hidden={onHidden}>
                    편집
                </button>
            </Wrapper>
            <Wrapper hidden={!onHidden}>
                <button type="button" onClick={onCancel}>
                    취소
                </button>
                {!_.isUndefined(isValid) ? (
                    <button type="submit" disabled={!isValid}>
                        저장
                    </button>
                ) : (
                    <button type="submit" disabled={errorState}>
                        저장
                    </button>
                )}
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
