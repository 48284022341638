import {
    IOoDiagnosis,
    isMedicinePaySucceed,
    OoDiagnosisStatus,
    OoKakaoQuickStatus,
    OoMedicineDeliveryType
} from "@api/models";
import _ from "lodash";
import {DiagnosisBackgroundColor, DiagnosisStatusColor} from "../diagnosisProcessingState";

interface DiagnosisStatus {
    title: string,
    color: DiagnosisStatusColor,
    background: DiagnosisBackgroundColor,
}


// 약 배송 신청
export function getDeliveryStatusTitle(diagnosis: IOoDiagnosis): string {
    if (_.isEqual(diagnosis.deliveryType, OoMedicineDeliveryType.packageDelivery)) {
        return '택배 배송 신청'
    } else if (_.isEqual(diagnosis.deliveryType, OoMedicineDeliveryType.pharmacyPackage)) {
        return '방문 수령 신청';
    } else if (_.isEqual(diagnosis.deliveryType, OoMedicineDeliveryType.user)) {
        return '직접 픽업';
    } else {
        return '신청 안함';
    }
}

// 약제비 결제 대기
export function getMedicineDeliveryPaymentWaitTitle(diagnosis: IOoDiagnosis): string {
    return _.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.cancelBeforePayment) ? '배송 취소' : '약제비 결제 대기';
}

// 약제비 결제 완료 및 약 조제 시작
export function getMedicineDeliveryPaymentOkTitle(diagnosis: IOoDiagnosis): DiagnosisStatus {
    const medicineDeliveryPaymentOkStatus = _.isEqual(diagnosis.status, OoDiagnosisStatus.medicineDeliveryPaymentOk);
    const medicineDeliveryAcceptStatus = _.isEqual(diagnosis.status, OoDiagnosisStatus.medicineDeliveryAccept);

    if (_.isUndefined(diagnosis.kakaoQuickStatus)) {
        return isMedicinePaySucceed(diagnosis) ? {
            title: medicineDeliveryAcceptStatus ? '약 준비 완료' : '약제비 결제 완료',
            color: DiagnosisStatusColor.success,
            background: DiagnosisBackgroundColor.success,
        } : {
            title: '약제비 결제 실패',
            color: DiagnosisStatusColor.cancel,
            background: DiagnosisBackgroundColor.fail,
        };
    }

    const deliverySuccessStartScope = diagnosis.kakaoQuickStatus >= OoKakaoQuickStatus.request;
    const deliverySuccessEndScope = diagnosis.kakaoQuickStatus <= OoKakaoQuickStatus.pickupStarted;
    const operatorCancelStatus = _.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.operatorCancel);

    if (isMedicinePaySucceed(diagnosis)) {
        if (medicineDeliveryAcceptStatus && deliverySuccessStartScope && deliverySuccessEndScope) {
            return {
                title: '픽업 대기',
                color: DiagnosisStatusColor.default,
                background: DiagnosisBackgroundColor.success,
            }
        }
        if (medicineDeliveryAcceptStatus && operatorCancelStatus) {
            return {
                title: '약 준비 완료',
                color: DiagnosisStatusColor.default,
                background: DiagnosisBackgroundColor.success,
            }
        }
        if (medicineDeliveryPaymentOkStatus && operatorCancelStatus) {
            return {
                title: '약제비 결제 완료',
                color: DiagnosisStatusColor.success,
                background: DiagnosisBackgroundColor.success,
            }
        }
        return {
            title: '약제비 결제 완료',
            color: DiagnosisStatusColor.success,
            background: DiagnosisBackgroundColor.success,
        }
    } else {
        return {
            title: '약제비 결제 실패',
            color: DiagnosisStatusColor.cancel,
            background: DiagnosisBackgroundColor.fail,
        }
    }
}


// 카카오 배송 취소
export function getMedicineDeliveryStartTitle(diagnosis: IOoDiagnosis): DiagnosisStatus {
    if (_.isUndefined(diagnosis.kakaoQuickStatus)) {
        return {
            title: '약 배송 시작',
            color: DiagnosisStatusColor.default,
            background: DiagnosisBackgroundColor.success,
        }
    }


    const medicineDeliveryPaymentWaitCondition = _.isEqual(diagnosis.status, OoDiagnosisStatus.medicineDeliveryPaymentWait);
    const medicineDeliveryPaymentOkCondition = _.isEqual(diagnosis.status, OoDiagnosisStatus.medicineDeliveryPaymentOk);
    const medicineDeliveryAcceptCondition = _.isEqual(diagnosis.status, OoDiagnosisStatus.medicineDeliveryAccept);
    const deliveryStartCondition = _.isEqual(diagnosis.status, OoDiagnosisStatus.medicineDeliveryStart);

    const cancelBeforePaymentCondition = _.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.cancelBeforePayment);
    const matchingFailedCondition = _.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.matchingFailed);
    const pharmacyPickupCondition = _.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.pharmacyPickup);

    const cancelStartScope = diagnosis.kakaoQuickStatus >= OoKakaoQuickStatus.cancelAfterPaymentBeforeMatching;
    const cancelEndScope = diagnosis.kakaoQuickStatus < OoKakaoQuickStatus.pharmacyPickup;


    if (medicineDeliveryPaymentWaitCondition && cancelBeforePaymentCondition) {
        return {
            title: '배송 취소',
            color: DiagnosisStatusColor.cancel,
            background: DiagnosisBackgroundColor.fail,
        }
    }

    // 약제비 결제 완료
    if (medicineDeliveryPaymentOkCondition) {
        if ((cancelStartScope && cancelEndScope) || pharmacyPickupCondition) {
            return {
                title: '약제비 결제 완료',
                color: DiagnosisStatusColor.success,
                background: DiagnosisBackgroundColor.success,
            }
        } else if (matchingFailedCondition) {
            return {
                title: '배송 취소',
                color: DiagnosisStatusColor.cancel,
                background: DiagnosisBackgroundColor.fail,
            }
        }
    }

    // 약 조제 시작
    if (medicineDeliveryAcceptCondition) {
        if (matchingFailedCondition) {
            return {
                title: '배송 취소',
                color: DiagnosisStatusColor.cancel,
                background: DiagnosisBackgroundColor.fail,
            }
        } else if ((cancelStartScope && cancelEndScope) || pharmacyPickupCondition) {
            return {
                title: '약 준비 완료',
                color: DiagnosisStatusColor.success,
                background: DiagnosisBackgroundColor.success,
            }
        }
    }

    // 약 배송 시작
    if (deliveryStartCondition) {
        if (cancelBeforePaymentCondition) {
            return {
                title: '배송 취소',
                color: DiagnosisStatusColor.cancel,
                background: DiagnosisBackgroundColor.fail,
            }
        } else if (matchingFailedCondition) {
            return {
                title: '픽업 대기',
                color: DiagnosisStatusColor.default,
                background: DiagnosisBackgroundColor.success,
            }
        } else if (cancelStartScope && cancelEndScope) {
            return {
                title: '약제비 결제 완료',
                color: DiagnosisStatusColor.success,
                background: DiagnosisBackgroundColor.success,
            }
        }
    }

    return {
        title: '약 배송 시작',
        color: DiagnosisStatusColor.default,
        background: DiagnosisBackgroundColor.success,
    }
}
