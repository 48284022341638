import {
  diagnosisHospitalState,
  IOoDiagnosis,
  OoDiagnosisStatus,
  OoMedicineDeliveryType,
  OoParamAddMedicinePrice,
} from "../../../../../api";
import React, { useCallback, useEffect, useState } from "react";
import { PharmacyPrescription } from "./PharmacyPrescription";
import { PharmacyDeliveryInfo } from "./PharmacyDeliveryInfo";
import { PharmacyMedicinePrice } from "./PharmacyMedicinePrice";
import _ from "lodash";
import { PharmacyMemo } from "./PharmacyMemo";
import classNames from "classnames/bind";
import buttonStyles from "../../../../../styles/common/Button.module.scss";
import styled, { css } from "styled-components";
import { useSetRecoilState } from "recoil";
import { pharmacyMedicinePriceSubmit } from "@recoils/pharmacy/pharmacyMedicinePriceSubmit";
import {
  DiagnosisBackgroundColor,
  getDiagnosisStatus,
} from "@models/diagnosis/diagnosisProcessingState";
import { toast } from "react-toastify";
import { DiagnosisCancel } from "@components/diagnosisCancelTable";
import { usePharmacyFunctionHook } from "@hooks/pharmacy";
import { DiagnosisHospitalInfo } from "@components/diagnosisForm";
import { useMedicineHospitalPaymentFilter } from "@models/diagnosis/function/medicineHospitalPaymentFilter";
import {
  PatientInfo,
  PatientRequest,
} from "@components/diagnosisDetailComponents";

interface Props {
  diagnosisList: Array<IOoDiagnosis>;
  diagnosisData: IOoDiagnosis;
  prescriptionData: Array<OoParamAddMedicinePrice>;
  onChangedPrescription: (param: OoParamAddMedicinePrice) => void;
}

const textColor = {
  default: "#C2C1BD",
  none: "#000000",
  success: "#2176F6",
  fail: "#EC2C2C",
};

const borderColor = {
  default: "#C2C1BD",
  red: "#EC2C2C",
  blue: "#2176F6",
};

const backgroundColor = {
  default: "#EAEAEA",
  red: "#FDEFEF",
  blue: "#EFF7FF",
};

export const PharmacyDetailContent = ({
  diagnosisList,
  diagnosisData,
  prescriptionData,
  onChangedPrescription,
}: Props) => {
  const bx = classNames.bind(buttonStyles);

  const { isHospitalMedicinePaySucceed } =
    useMedicineHospitalPaymentFilter(diagnosisData);

  const [medicinePay, setMedicinePay] = useState<string>("");
  const [agreeDUR] = useState<boolean>(true);
  const [substitute, setSubstitute] = useState<boolean>(false);
  const [changedMemo, setChangedMemo] = useState<string>("");
  const [targetMedicine, setTargetMedicine] =
    useState<OoParamAddMedicinePrice>();
  const setQueryTrigger = useSetRecoilState(pharmacyMedicinePriceSubmit);

  const onChangedData = useCallback(
    (medicinePay: string, substitute: boolean) => {
      setMedicinePay(medicinePay);
      setSubstitute(substitute);
    },
    [medicinePay, substitute]
  );

  const onChangeMemo = useCallback(
    (memo: string) => {
      setChangedMemo(memo);
    },
    [changedMemo]
  );

  const filteredMedicinePaymentState = () => {
    const filteredDeliveryStatus =
      !_.isEqual(diagnosisData.deliveryType, OoMedicineDeliveryType.unknown) &&
      !_.isEqual(diagnosisData.deliveryType, OoMedicineDeliveryType.user);
    return (
      filteredDeliveryStatus &&
      _.gte(diagnosisData.status, OoDiagnosisStatus.medicineDeliveryPaymentWait)
    );
  };

  const onClickSubmitMedicinePrice = (e: any) => {
    e.preventDefault();
    setQueryTrigger(true);
  };

  useEffect(() => {
    const target = _.find(prescriptionData, (item) =>
      _.isEqual(item.diagnosisId, diagnosisData.id)
    );
    setTargetMedicine(target);
  }, [prescriptionData, diagnosisData.id]);

  useEffect(() => {
    onChangedPrescription(
      new OoParamAddMedicinePrice(
        diagnosisData.id as string,
        _.toNumber(medicinePay),
        agreeDUR,
        substitute,
        changedMemo
      )
    );
  }, [onChangedData, onChangeMemo]);

  const { changeStatusDiagnosis } = usePharmacyFunctionHook();

  const onChangeStatus = (e: any) => {
    e.preventDefault();
    let { value } = e.target;
    switch (_.toNumber(value)) {
      case OoDiagnosisStatus.medicineDeliveryAccept:
        changeStatusDiagnosis(
          diagnosisData.id || "",
          OoDiagnosisStatus.medicineDeliveryAccept,
          changedMemo
        );
        break;
      case OoDiagnosisStatus.medicineDeliveryStart:
        changeStatusDiagnosis(
          diagnosisData.id || "",
          OoDiagnosisStatus.medicineDeliveryStart,
          changedMemo
        );
        break;
      default:
        if (_.isEmpty(changedMemo))
          return toast.error("약사님 메모에 처방전 취소 사유를 입력해주세요.");
        changeStatusDiagnosis(
          diagnosisData.id || "",
          OoDiagnosisStatus.pharmacistCancel,
          changedMemo
        );
        break;
    }
  };

  const isCancelStatus = () => {
    const predicate1 = _.gte(
      diagnosisData.status,
      OoDiagnosisStatus.doctorCancel
    );
    const predicate2 = _.lte(
      diagnosisData.status,
      OoDiagnosisStatus.pharmacistCancel
    );
    return predicate1 && predicate2;
  };

  const isReadyToMedicine = () => {
    return (
      _.isEqual(
        diagnosisData.status,
        OoDiagnosisStatus.medicineDeliveryPaymentOk
      ) &&
      !isCancelStatus() &&
      isHospitalMedicinePaySucceed()
    );
  };

  const isReadyToDelivery = () => {
    return (
      _.isEqual(
        diagnosisData.status,
        OoDiagnosisStatus.medicineDeliveryAccept
      ) &&
      !isCancelStatus() &&
      isHospitalMedicinePaySucceed()
    );
  };

  return (
    <Wrapper>
      <TapContainer>
        <StatusButtonContainer>
          <Status color={`${getDiagnosisStatus(diagnosisData).background}`}>
            {getDiagnosisStatus(diagnosisData).title}
          </Status>
          <ChangedStatusButton
            background={
              isReadyToMedicine()
                ? backgroundColor.blue
                : backgroundColor.default
            }
            color={isReadyToMedicine() ? textColor.success : textColor.default}
            borderColor={
              isReadyToMedicine() ? borderColor.blue : borderColor.default
            }
            onClick={onChangeStatus}
            value={OoDiagnosisStatus.medicineDeliveryAccept}
            disabled={!isReadyToMedicine()}
          >
            약 준비 완료
          </ChangedStatusButton>
          <ChangedStatusButton
            background={
              isReadyToDelivery()
                ? backgroundColor.blue
                : backgroundColor.default
            }
            color={isReadyToDelivery() ? textColor.success : textColor.default}
            borderColor={
              isReadyToDelivery() ? borderColor.blue : borderColor.default
            }
            onClick={onChangeStatus}
            value={OoDiagnosisStatus.medicineDeliveryStart}
            disabled={!isReadyToDelivery()}
          >
            약 배송 시작
          </ChangedStatusButton>
          <ChangedStatusButton
            background={backgroundColor.red}
            color={textColor.fail}
            borderColor={borderColor.red}
            value={OoDiagnosisStatus.pharmacistCancel}
            onClick={onChangeStatus}
            disabled={filteredMedicinePaymentState()}
          >
            처방전 반려
          </ChangedStatusButton>
        </StatusButtonContainer>
        {isCancelStatus() && <DiagnosisCancel data={diagnosisData} />}
        <>
          <PatientInfo data={diagnosisData} />
          <PatientRequest
            data={diagnosisData}
            ownerData={_.head(diagnosisList)!}
          />
          <DiagnosisHospitalInfo
            status={diagnosisHospitalState(diagnosisData)}
          />
          <PharmacyPrescription diagnosisData={diagnosisData} />
          <PharmacyDeliveryInfo diagnosisData={diagnosisData} />

          {!_.isUndefined(targetMedicine) && (
            <>
              <PharmacyMedicinePrice
                diagnosisData={diagnosisData}
                agreeDUR={agreeDUR}
                onChanged={onChangedData}
                prescriptionData={targetMedicine}
              />
              <PharmacyMemo
                diagnosisData={diagnosisData}
                onChanged={onChangeMemo}
                prescriptionData={targetMedicine}
              />
            </>
          )}
          {!filteredMedicinePaymentState() && (
            <ButtonContainer>
              <button
                className={bx("diagnosis-submit-button")}
                onClick={onClickSubmitMedicinePrice}
              >
                발송
              </button>
            </ButtonContainer>
          )}
        </>
      </TapContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 1024px;
`;

const TapContainer = styled.div`
  height: auto;
  width: 100%;
  background: #fff;
  border: 1px solid #c4c4c4;
  padding: 2%;
`;

const StatusButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 44px;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 36px;
  font-weight: 700;
  font-size: 13px;
  background: ${(props) => props.color};
  border-radius: 8px;
  margin-left: 10px;

  ${(props) => {
    if (props.color === DiagnosisBackgroundColor.success) {
      return css`
        color: ${textColor.success};
        border: 1px solid ${textColor.success};
      `;
    } else if (props.color === DiagnosisBackgroundColor.fail) {
      return css`
        color: ${textColor.fail};
        border: 1px solid ${textColor.fail};
      `;
    } else {
      return css`
        color: ${textColor.none};
        border: 1px solid ${borderColor.default};
      `;
    }
  }}
`;

const ChangedStatusButton = styled.button<{
  color: string;
  background: string;
  borderColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 36px;
  font-weight: 700;
  font-size: 13px;
  background: ${(props) => props.background};
  border-radius: 8px;
  margin-left: 10px;
  border: 1px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
`;
