import React, {useEffect} from "react";
import doctorInitializedInfo from "../../../../assets/doctorInitialInfo.json";
import buttonStyles from '../../../../styles/common/Button.module.scss';
import classNames from "classnames/bind";
import layoutStyles from "../../../../styles/common/Layout.module.scss";
import styled from "styled-components";
import {DoctorServiceInfo} from "interfaces";
import {useThemeSettingHook} from "@components/registerForm";
import { OoParamDoctorCreate } from "@api/params";

interface Props {
    onChanged: (data: DoctorServiceInfo) => void,
    param:OoParamDoctorCreate
}

export const DoctorRegisterTheme = ({onChanged , param}: Props) => {
    const bx = classNames.bind(buttonStyles);
    const lx = classNames.bind(layoutStyles);
    
    const {
        data,
        onCheckedTheme,
        isEqualTheme,
    } = useThemeSettingHook();

    useEffect(() => {
        onChanged(data);
    }, [data]);

    return (
        <div className={lx('doctor-theme-container')}>
            <div>진료 테마 (최대 3개까지 중복 선택 가능)</div>
            <ThemeWrapper>
                {
                    doctorInitializedInfo.theme.map((item, index) => (
                        <div key={index}>
                            <input
                                id={`${item.val}`}
                                type="checkbox"
                                value={item.val}
                                onChange={onCheckedTheme}
                                checked={param.theme?.find(p => p === item.val) ? true : false}
                                hidden={true}
                            />
                            <label htmlFor={`${item.val}`} className={bx('checkbox-label')}>
                                <div>{item.val}</div>
                            </label>
                        </div>
                    ))
                }
            </ThemeWrapper>
        </div>
    );
}

const ThemeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;