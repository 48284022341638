import React, {useCallback, useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import _ from "lodash";
import {fileState} from "@recoils/file";
import {PatientInfoStatus} from "@api/index";
import classNames from "classnames/bind";
import styles from '@styles/diagnosis/Prescription.module.scss';
import buttonStyles from '@styles/common/Button.module.scss';
import moment from "moment";
import axios from "axios";
import FileSaver from "file-saver";
import {Button} from "react-bootstrap";
import {FilesReadTable} from "./FilesReadTable";
import {PdfViewer} from "../../pdfViewer";
import {toast} from "react-toastify";

interface Props {
    status: PatientInfoStatus,
}

export const PrescriptionForm = ({status}: Props) => {
    const cx = classNames.bind(styles);
    const bx = classNames.bind(buttonStyles);
    const setVisible = useSetRecoilState(fileState);
    const [uploadFile, setUploadFile] = useState<Array<string>>([]);
    const [pdfUrl, setPdfUrl] = useState<string>('');

    const getPrescription = useCallback(() => {
        if (_.isUndefined(status.prescription)) {
            return
        }
        const getData = status.prescription.map(item => item);
        setUploadFile(getData);
    }, [status.prescription]);

    const onClickUrl = (e: any) => {
        const getUrl = e.currentTarget.id;
        setVisible(true);
        setPdfUrl(getUrl);
    };

    const onClickFaxButton = () => {
        toast.error('미구현 기능입니다.');
    };

    const onClickDownloadButton = async (e: any) => {
        e.preventDefault();
        if (_.isUndefined(pdfUrl)) {
            return;
        }
        const date = moment().format('yyyyMMDDHHmm');
        try {
            const response = await axios.get(pdfUrl, {responseType: "arraybuffer"});
            FileSaver.saveAs(new Blob([response.data], {type: "application/pdf;charset=utf-8"}), `${status.patientName}_${date}`);
        } catch (e: any) {
            console.log('onClickDownloadButton error ============>', e);
            toast.error(`다운로드가 실패했습니다.\n(${e})`);
        }
    };

    useEffect(() => {
        getPrescription();
        if (_.isUndefined(status.prescription)) {
            return
        }
        const getData = status.prescription.map(item => item);
        setPdfUrl(getData[0]);
    }, [status, getPrescription]);

    return (
        <>
            <PdfViewer pdfUrl={pdfUrl}/>
            {
                !_.isUndefined(status.prescription) && _.gt(status.prescription.length, 0) ?
                    <div>
                        <div className={cx('container-div')}>
                            <div><b>처방전</b></div>
                            <FilesReadTable onClickUrl={onClickUrl}
                                            uploadFiles={uploadFile}
                                            patientName={status.patientName}
                                            checkBoxHidden={true}/>
                        </div>
                        <div className={cx('buttons-div')}>
                            <Button className={bx('button')} onClick={onClickDownloadButton}>
                                <div className={cx('button-inside-div')}>
                                    <img src={process.env.PUBLIC_URL + '/img/download-icon.png'}
                                         alt="download-button"
                                         className={cx('custom-download-icon')}/>
                                    <div>다운로드</div>
                                </div>
                            </Button>
                        </div>
                    </div>
                    :
                    <div className={cx('empty-file-container-div')}>
                        <div><b>처방전</b></div>
                        <table>
                            <thead>
                            <tr>
                                <td>파일첨부</td>
                                <td>없음</td>
                            </tr>
                            </thead>
                        </table>
                    </div>
            }
        </>
    )
}