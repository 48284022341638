import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { editSelectorFamily } from "@recoils/status";
import { IOoUnospayDelivery } from "@api/models";
import { CustomInputText, CustomTextArea } from "@components/custom";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useUnospayFunctionHook } from "@hooks/unospay";
import { formatDateYYYYMDHHmm, formatTel } from "@hooks/format";
import classNames from "classnames/bind";
import InputStyles from "../../../../../styles/common/Input.module.scss";
import buttonStyles from "../../../../../styles/common/Button.module.scss";
import { textAlign } from "@mui/system";
import { useUnosOrderDetailPageSettingHook } from "../../hooks/useUnosOrderDetailPageSettingHook";

interface Props {
  componentName: string;
  data: IOoUnospayDelivery;
}

export interface OrderCsInfo {
  id: string;
  csMemo: string;
}

const IS_DEV = false;
const baseURL = IS_DEV
  ? `http://localhost:3000/unospayOrderDetail`
  : "https://doctor-on-react.web.app/unospayOrderDetail";

export const OrderCsInfo = ({ componentName, data }: Props) => {
  const editStatus = useRecoilValue(editSelectorFamily(componentName));
  const [color, setColor] = useState("white");
  const { updateOrderCsInfo } = useUnospayFunctionHook(data.id || "");
  const [items, setItems] = useState<OrderCsInfo>({
    id: data.id || "",
    csMemo:
      _.isEmpty(data.csMemo) || _.isUndefined(data.csMemo) ? "-" : data.csMemo,
  });
  const { getCsInfoResponse } = useUnosOrderDetailPageSettingHook();

  const onChangeValue = (e: any) => {
    const { name, value } = e.target;
    setItems({ ...items, [name]: value });
  };

  const onHandleOpenPopUp = (id: string) => {
    const width = 1200;
    const height = 700;

    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    window.open(
      `${baseURL}/${id}`,
      "상세페이지",
      `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`
    );
  };

  useEffect(() => {
    setItems({
      id: data.id || "-",
      csMemo:
        _.isEmpty(data.csMemo) || _.isUndefined(data.csMemo)
          ? "-"
          : data.csMemo,
    });
  }, [data]);

  useEffect(() => {
    if (editStatus.isEdit) {
      setColor("blue");
      setItems({
        id: data.id || "",
        csMemo:
          _.isEmpty(data.csMemo) || _.isUndefined(data.csMemo)
            ? ""
            : data.csMemo,
      });
    } else {
      setColor("white");
      setItems({
        id: data.id || "",
        csMemo:
          _.isEmpty(data.csMemo) || _.isUndefined(data.csMemo)
            ? "-"
            : data.csMemo,
      });
    }
  }, [editStatus.isEdit]);

  useEffect(() => {
    if (!editStatus.isSubmit) return;
    console.log("submit!!!!!");
    updateOrderCsInfo(items);
  }, [editStatus.isSubmit]);

  const ref = useRef<HTMLTextAreaElement>(null);
  const ix = classNames.bind(InputStyles);
  const bx = classNames.bind(buttonStyles);

  console.log("csinfo", getCsInfoResponse);

  return (
    <div>
      <Table visible={editStatus.isEdit}>
        <thead></thead>
        <tbody>
          <tr>
            <td>CS 메모</td>
            <td>
              <CustomTextArea
                color={color}
                size="xLarge"
                value={items.csMemo}
                name="csMemo"
                onChanged={onChangeValue}
                placeholder="고객 CS 특이사항"
                disabled={editStatus.isEdit}
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <Table visible={true}>
        <thead></thead>
        <tbody>
          {getCsInfoResponse.data &&
            getCsInfoResponse.data!.map((memo, index) =>
              !_.isEmpty(memo.csMemo) && !_.isEqual(data.id, memo.id) ? (
                <>
                  <tr>
                    <td>
                      <a onClick={() => onHandleOpenPopUp(memo.id!)}>
                        {formatDateYYYYMDHHmm(memo.timestamp)}
                      </a>
                    </td>
                    <td>
                      {/* <CustomTextArea color={color}
                                    size="xMiddle"
                                    value={items.csMemo}
                                    name="csMemo"
                                    onChanged={onChangeValue}
                                    placeholder="고객 CS 특이사항"
                                    disabled={editStatus.isEdit} 
                                /> */}
                      {/* <CustomInputText size="xMiddle"
                                // color={color}
                                color={"white"}
                                name="csMemo"
                                value={memo.csMemo}
                                disabled={false} /> */}
                      {/* <CustomTextArea color={color}
                                size="auto"
                                value={memo.csMemo}
                                // value={items.csMemo}
                                name="historyMemo"
                                // onChanged={onChangeValue}
                                placeholder="고객 CS 특이사항"
                                disabled={true}  
                                />
                                */}
                      <HistoryMemo>{memo.csMemo}</HistoryMemo>
                    </td>
                  </tr>
                </>
              ) : (
                <></>
              )
            )}
        </tbody>
      </Table>
    </div>
  );
};

const Table = styled.table<{ visible: boolean }>`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: 100%;
  height: auto;
  font-size: 13px;
  margin-top: 8px;
  white-space: pre-wrap;

  & tr {
    border-bottom: 1px solid #c8d6f8;
  }

  & tr td:nth-child(2n-1) {
    background: #f4f6fb;
    text-align: center;
    height: 48px;
    font-weight: 700;
    width: 200px;
  }

  & tr td:nth-child(2n) {
    & > input {
      margin-left: 10px;
    }

    & > textarea {
      margin: 8px 0 8px 8px;
      display: block;
    }
  }

  & tr:nth-child(2) td:nth-child(2) {
    & > span {
      font-size: 12px;
      margin-left: 10px;
    }
  }
`;

const HistoryMemo = styled.div`
  height: auto;
  width: 100%;
  background: #fff;
  padding: 2%;
`;
