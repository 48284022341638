import {useModalHook} from "@hooks/modal";
import {
    ButtonContainer,
    Card,
    ContentsContainer,
    MultiButton,
    TitleContainer,
    Wrapper
} from "@components/customModal/style";
import React from "react";

export const MultiButtonModal = ({
                                     data: {
                                         title = '', contents = '', buttons = [
                                             {title: "버튼1", callback: (e: any) => false},
                                             {title: "버튼2", callback: (e: any) => false},
                                             {title: "버튼3", callback: (e: any) => false}]
                                     }
                                 }) => {
    const {removeCurrentModal} = useModalHook();
    return (
        <Wrapper>
            <Card>
                {
                    title.length > 0 &&
                    <TitleContainer>
                        {title}
                    </TitleContainer>
                }
                <ContentsContainer>
                    {contents}
                </ContentsContainer>
                <ButtonContainer flexDirection="column" align="center">
                    <MultiButton type="button" onClick={() => {
                        removeCurrentModal();
                        buttons[0].callback(true);
                    }}>{buttons[0].title}</MultiButton>
                    <MultiButton type="button" onClick={() => {
                        removeCurrentModal();
                        buttons[1].callback(true);
                    }}>{buttons[1].title}</MultiButton>
                    <MultiButton type="button" onClick={() => removeCurrentModal()}>{buttons[2].title}</MultiButton>
                </ButtonContainer>
            </Card>
        </Wrapper>
    )
}