import React, {useEffect} from "react";
import classNames from "classnames/bind";
import layoutStyles from "../../../../styles/common/Layout.module.scss";
import timeTableStyle from "../../../../styles/common/TimeTable.module.scss";
import {DoctorWorkHourInfo} from "interfaces";
import {WorkHourRow} from "@components/workHourRow";
import {useDiagnosisHoursSettingHook} from "@components/registerForm";

interface Props {
    onChanged: (data: DoctorWorkHourInfo) => void,
}

export const DoctorRegisterDiagnosisTime = ({onChanged}: Props) => {
    const tx = classNames.bind(timeTableStyle);
    const lx = classNames.bind(layoutStyles);

    const {
        data,
        defaultResult,
        onDefaultTimeChanged
    } = useDiagnosisHoursSettingHook();

    useEffect(() => {
        onChanged(data);
    }, [data]);

    return (
        <div className={lx('doctor-time-table')}>
            <div className={tx('time-table-head')}>
                <div>요일</div>
                <div>진료시작</div>
                <div>진료종료</div>
                <div>휴무일여부</div>
            </div>
            {
                defaultResult.map((item, index) => (
                    <WorkHourRow key={index}
                                 dayOfWeekNumber={item.dayOfWeek}
                                 hours={item.hours}
                                 breakHoursList={item.breakHoursList}
                                 isOff={item.isOff}
                                 onChange={onDefaultTimeChanged}
                                 isEdit={true}
                    />
                ))
            }
        </div>
    )
}