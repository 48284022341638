import React, {ForwardedRef, forwardRef, RefObject, useCallback, useMemo, useState} from "react";
import {Document, Page, pdfjs} from 'react-pdf';
import styled from "styled-components";
import {useRecoilState} from "recoil";
import {faAngleLeft, faAngleRight, faX} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {fileState} from "@recoils/file";
import {ModalOverlay} from "@components/modal";

// workerSrc 정의 하지 않으면 pdf 보여지지 않습니다.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
    pdfUrl: URL | string,
    printRef?: RefObject<any>,
}

export const PdfViewerWithPrint = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const [visible, setVisible] = useRecoilState(fileState);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const file = useMemo(() => {
        return props.pdfUrl
    }, [props.pdfUrl]);

    const handleInvisible = (e: any) => {
        e.preventDefault();
        setVisible(false);
    };

    const onDocumentLoadSuccess = useCallback(({numPages}: { numPages: number }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }, [file]);

    return (
        <ModalOverlay visible={visible}>
            <OverlayContainer>
                <ButtonContainer>
                    <CloseButton onClick={handleInvisible}>
                        <FontAwesomeIcon icon={faX} className="custom-close-icon"/>
                    </CloseButton>
                </ButtonContainer>
                <div>
                    {
                        !_.isUndefined(ref) &&
                        <div ref={ref}>
                            <Document file={file}
                                      onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber}/>
                            </Document>
                        </div>
                    }
                    <DivContainer>
                        <ButtonDiv>
                            {pageNumber > 1 && (
                                <PreviousButton
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setPageNumber(prev => prev + (-1));
                                    }}>
                                    <FontAwesomeIcon icon={faAngleLeft} className="custom-arrow-icon"/>
                                </PreviousButton>
                            )}
                        </ButtonDiv>
                        <PageDiv> {pageNumber} / {numPages}</PageDiv>
                        <ButtonDiv>
                            {pageNumber < numPages &&
                                <NextButton onClick={(e: any) => {
                                    e.preventDefault();
                                    setPageNumber(prev => prev + (+1))
                                }}>
                                    <FontAwesomeIcon icon={faAngleRight} className="custom-arrow-icon"/>
                                </NextButton>}
                        </ButtonDiv>
                    </DivContainer>
                </div>
            </OverlayContainer>
        </ModalOverlay>
    )
});

// export const PdfViewerWithPrint = ({pdfUrl, printRef}: Props) => {
//     const [visible, setVisible] = useRecoilState(fileState);
//     const [numPages, setNumPages] = useState(0);
//     const [pageNumber, setPageNumber] = useState(1);
//
//     const file = useMemo(() => ({pdfUrl}), [pdfUrl]);
//
//     const handleInvisible = (e: any) => {
//         e.preventDefault();
//         setVisible(false);
//     };
//
//     const onDocumentLoadSuccess = useCallback(({numPages}: { numPages: number }) => {
//         setNumPages(numPages);
//         setPageNumber(1);
//     }, [pdfUrl]);
//
//     console.log(printRef, file.pdfUrl);
//
//     return (
//         <ModalOverlay visible={visible}>
//             <OverlayContainer>
//                 <ButtonContainer>
//                     <CloseButton onClick={handleInvisible}>
//                         <FontAwesomeIcon icon={faX} className="custom-close-icon"/>
//                     </CloseButton>
//                 </ButtonContainer>
//                 <div>
//                     {
//                         !_.isUndefined(printRef) &&
//                         <Document file={file.pdfUrl}
//                                   onLoadSuccess={onDocumentLoadSuccess}>
//                             <Page pageNumber={pageNumber}
//                                   ref={printRef}/>
//                         </Document>
//                     }
//                     <DivContainer>
//                         <ButtonDiv>
//                             {pageNumber > 1 && (
//                                 <PreviousButton
//                                     onClick={(e: any) => {
//                                         e.preventDefault();
//                                         setPageNumber(prev => prev + (-1));
//                                     }}>
//                                     <FontAwesomeIcon icon={faAngleLeft} className="custom-arrow-icon"/>
//                                 </PreviousButton>
//                             )}
//                         </ButtonDiv>
//                         <PageDiv> {pageNumber} / {numPages}</PageDiv>
//                         <ButtonDiv>
//                             {pageNumber < numPages &&
//                                 <NextButton onClick={(e: any) => {
//                                     e.preventDefault();
//                                     setPageNumber(prev => prev + (+1))
//                                 }}>
//                                     <FontAwesomeIcon icon={faAngleRight} className="custom-arrow-icon"/>
//                                 </NextButton>}
//                         </ButtonDiv>
//                     </DivContainer>
//                 </div>
//             </OverlayContainer>
//         </ModalOverlay>
//     )
// }

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #FFF;
`;
const DivContainer = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: 25px;
  justify-content: space-evenly;
`;
const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
`;
const PageDiv = styled.div`
  font-size: 25px;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const PreviousButton = styled.button`
  background: rgba(4, 4, 4, 0.6);
  border: rgba(4, 4, 4, 0.6);
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;
const NextButton = styled.button`
  background: rgba(4, 4, 4, 0.6);
  border: rgba(4, 4, 4, 0.6);
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  color: #fff;
  display: flex;
  justify-content: flex-end;
`;