import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {doctorPrescriptionState} from "@recoils/doctor";
import {useState} from "react";
import _ from "lodash";
import {OoParamPrescriptionCreate} from "@api/params";
import {pdfUrlState} from "@recoils/common";
import {OoHospitalMedicineType} from "@api/models";

export const useDiagnosisPaySettingHook = (targetParam: OoParamPrescriptionCreate) => {
    const pdfUrlArr = useRecoilValue(pdfUrlState(targetParam.id || ""));
    const resetPdfUrlArr = useResetRecoilState(pdfUrlState(targetParam.id || ""));
    const [noPayChecked, setNoPayChecked] = useState<boolean>(false);
    const [prescription, setPrescription] = useRecoilState(doctorPrescriptionState);
    const [hospitalMedicinePriceShow, setHospitalMedicinePriceShow] = useState<boolean>(true);

    const [items, setItems] = useState({
        price: _.toString(targetParam.price),
        hospitalMedicinePrice: _.toString(targetParam.hospitalMedicinePrice),
        isHospitalDelivery: targetParam.isHospitalDelivery || false,
        isBothDelivery: false,
        hospitalMedicineType: targetParam.hospitalMedicineType || OoHospitalMedicineType.diagnosis,
    });

    const onChangePrice = (e: any) => {
        setItems({...items, price: e.target.value});
    };

    const onChangeHospitalMedicinePrice = (e: any) => {
        setItems({...items, hospitalMedicinePrice: e.target.value});
    };

    const onChangeNoPrescription = (e: any) => {
        !hospitalMedicinePriceShow && setHospitalMedicinePriceShow(!hospitalMedicinePriceShow);
        e.target.checked ? resetPdfUrlArr() : setItems({
            ...items,
            isHospitalDelivery: false,
            hospitalMedicineType: OoHospitalMedicineType.diagnosis
        });
        setPrescription(e.target.checked);
    };

    const onChangeNoPayChecked = (e: any) => {
        setNoPayChecked(e.target.checked);
        e.target.checked && setItems({...items, price: "0"});
    };

    const onChangeHospitalMedicine = (e: any) => {
        hospitalMedicinePriceShow ? setHospitalMedicinePriceShow(false) : setHospitalMedicinePriceShow(true);
        e.target.checked ?
            setItems({
                ...items,
                isHospitalDelivery: e.target.checked,
                hospitalMedicineType: OoHospitalMedicineType.hospitalMedicine
            }) :
            setItems({
                ...items,
                isHospitalDelivery: e.target.checked,
                hospitalMedicineType: OoHospitalMedicineType.diagnosis
            });
        setPrescription(true);
    };

    const onChangeBothMedicine = (e: any) => {
        setHospitalMedicinePriceShow(!hospitalMedicinePriceShow);
        e.target.checked ?
            setItems({
                ...items,
                isBothDelivery: e.target.checked,
                hospitalMedicineType: OoHospitalMedicineType.both
            }) :
            setItems({
                ...items,
                isBothDelivery: e.target.checked,
                hospitalMedicineType: OoHospitalMedicineType.diagnosis
            });
    };

    return {
        pdfUrlArr,
        items,
        setItems,
        setNoPayChecked,
        noPayChecked,
        prescription,
        setPrescription,
        onChangePrice,
        onChangeHospitalMedicinePrice,
        onChangeNoPrescription,
        onChangeNoPayChecked,
        onChangeHospitalMedicine,
        onChangeBothMedicine,
        hospitalMedicinePriceShow,
    }
}