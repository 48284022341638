import React, { useState } from 'react'
import styled from 'styled-components';
import _  from 'lodash';
import moment from 'moment';
import { FlexDiv } from '@components/flexDiv';
import { OoOrderPlatformType, UnospayStatusColor } from '@api/models';
import { IOoCalculateMonthList } from '@api/models/calculate/calculateMonthList';
interface Props {
    data: Array<IOoCalculateMonthList>,
}

interface ColorProps {
  color: string;
}

const CalculateRawListContainer = ({data}: Props) => {
  const [showCopyMessage, setShowCopyMessage] = useState({type: false, id:''});

  const getOrderPlatformIndexToString = (type:number) => {
    switch(type){
      case OoOrderPlatformType.kiosk:
        return '키오스크'
      case OoOrderPlatformType.mtmMall:
        return 'MTM몰'
      case OoOrderPlatformType.telephone:
        return '전화주문'
      case OoOrderPlatformType.test:
        return 'Test주문'
      default:
        return '-'
    }
  }

  const copyToClipboard = (id:string) => {
    navigator.clipboard
    .writeText(id)
    .then(() => {
      setShowCopyMessage({type:true , id});
      setTimeout(() => {
        setShowCopyMessage({type:false, id});
      }, 1000);
    })
    .catch((err) => {
      console.error("Error in copying text: ", err);
    });
  }

  return (
    <Wrapper>
        {_.sortBy(data,'dateTime').reverse().map((item, index) => (
          <StatusRow>
          {item.cancelDateTime === "" && item.operatorCancelTimestamp === 0 ? <CalculateState color={UnospayStatusColor.deliveryFormSuccess}></CalculateState> : <CalculateState color={UnospayStatusColor.cancel}></CalculateState>}
          <ListContainer>
                <FlexDiv flex={0.5}>{index + 1}</FlexDiv>
                <FlexDiv flex={1}> {item.payerName}</FlexDiv>
                <FlexDiv flex={1}> {item.payerMobile!.split('-').join('')}</FlexDiv>
                <FlexDiv flex={1}>{item.dateTime ? moment(item.dateTime).format('YYYY/MM/DD HH:mm') : ""}</FlexDiv>
                <FlexDiv flex={1.5}>{item.hospitalName}</FlexDiv>
                <FlexDiv flex={1}>{getOrderPlatformIndexToString(Number(item.orderPlatform))}</FlexDiv>
                <FlexDiv flex={1}>{item.hospitalCalPrice}</FlexDiv>
                <FlexDiv flex={3}>{item.goods}</FlexDiv>
                <FlexDiv flex={1}> {item.paymentPrice}</FlexDiv>
                <FlexDiv flex={1}> {item.providerName}</FlexDiv>
                <FlexDiv flex={1.5}>{item.refererList?.map(referer => <div>{referer.split('|')[0]} [{referer.split('|')[1]}%]</div>)}</FlexDiv>
                <FlexDiv flex={.5}> {item.refererCalPrice?.map(price => <div>{price}</div>)}</FlexDiv>
                <FlexDiv flex={.5} display='flex'>
                <DownloadThemeButton onClick={() =>copyToClipboard(item.id!)}>
                  <ButtonIcon src='/img/Supplier.png' alt='Supplier' />
                </DownloadThemeButton>
                  {showCopyMessage.id === item.id && showCopyMessage.type && (
                    <CopyMessageContainer>Copied to clipboard</CopyMessageContainer>
                  )}
                <DownloadThemeButton onClick={() =>copyToClipboard(item.orderId)}>
                  <ButtonIcon src='/img/VideoIdVerification.png' alt='Supplier' />
                </DownloadThemeButton>
                  {showCopyMessage.id === item.orderId && showCopyMessage.type && (
                    <CopyMessageContainer>Copied to clipboard</CopyMessageContainer>
                  )}
                  </FlexDiv>
            </ListContainer>
            </StatusRow>
        ))}
    </Wrapper>
  )
}

export default CalculateRawListContainer


const CopyMessageContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 0.5em;
  overflow-y: scroll;
  color: #2176f6;
  position: absolute;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75%;
  bottom: 100px;
  overflow: scroll;

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-height: 1024px) and (max-height: 1280px) {
    height: 70%;
  }

  @media (min-height: 768px) and (max-height: 1024px) {
    height: 65%;
  }
`;

const StatusRow = styled.div`
  background: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px -2px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 4px;
  width: 100%;

  & > a:first-child {
    width: 100%;
    height: 64px;
    color: #030303;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ListContainer = styled.div`
  width: 100%;
  display: grid;
  text-align: start;
  height: 60px;
  align-items: center;
  margin-bottom: 5px;
  background: #ffffff;  
  column-gap: 10px;
  grid-template-columns: 3% 5% 8% 5% 12% 5% 5% 15% 5% 7% 10% 5% 5%;
`;

const CalculateState = styled.div<ColorProps>`
  background-color: ${props => props.color};
  height: 65px;
  width: 10px;
`

const DownloadThemeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-weight: 700;
  font-size: 13px;
  color: black;
  border-radius: 8px;
  border: 0.5px solid #c2c1bd;
  background: white;
  padding: 4px 8px 4px 8px;
  margin-right: 5px;
`;

const ButtonIcon = styled.img`
  width: 16px;
  height: 16px;
`;
