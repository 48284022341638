import {IOoWorkHours} from "./function";
import {IOoDeliveryRegionTable} from "@models/pharmacy/deliveryRegionTable";

interface IOoPharmacy {
    id?: string;

    // 사용자 아이디 (account  테이블)
    userId: string;

    //이메일
    email: string;

    //약국명
    name: string;

    //배달가능 여부
    isDelivery: boolean;

    //주소
    address: string;

    //상세주소
    addressDetail?: string;

    //우편변호
    zipCode: string;

    //전화번호
    tel: string;

    //팩스번호
    fax: string;

    //라이센스 번호
    license: string;

    //홈페이지
    web?: string;

    //소개
    bio?: string;

    //정산 은행명
    bank: string;

    //계좌번호
    bankAccount: string;

    //예금주
    bankHolderName: string;

    //약국 타입 (문전, 거점) PharmacyType
    type: number;

    //사진
    pictures?: Array<string>;

    //운영시간
    pharmacyHours?: Array<IOoWorkHours>;

    //당일배송 가능 지역
    todayDeliveryRegion?: Array<IOoDeliveryRegionTable>;

    //알림톡 수신 번호
    alimTalkReceivers: Array<string>;

    //소속 병원 아이디 목록
    hospitalIdList?: Array<string>;

    //퀵 배송 지역 검색 키워드
    quickRegionKeywordList?: Array<string>;

    //퀵 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
    quickRegionKeywordHitCount?: number;

    //퀵 배송 문구
    quickDeliveryComment?: string;

    //당일 배송 지역 검색 키워드
    todayRegionKeywordList?: Array<string>;

    //당일배송 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
    todayRegionKeywordHitCount?: number;

    //당일 배송 문구
    todayDeliveryComment?: string;

    //택배 배송 지역 검색 키워드
    packageRegionKeywordList?: Array<string>;

    //택배 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
    packageRegionKeywordHitCount?: number;

    //택배 배송 문구
    packageDeliveryComment?: string;

    //약국 픽업 문구
    pickupDeliveryComment?: string;

    //약배송 타입 목록
    deliveryTypeList?: Array<number>;

    //퀵 가능 시간
    deliverQuickHours?: Array<string>;

    //퀵 비용
    deliverQuickPrice?: number;

    //당일배송 가능시간
    deliverTodayHours?: Array<string>;

    //당일배송 비용
    deliverTodayPrice?: number;

    //택배배송 가능시간
    deliverPackageHours?: Array<string>;

    //택배 배송 비용
    deliverPackagePrice?: number;

    //직접픽업 가능시간
    deliverPickupHours?: Array<string>;

    //직접픽업 배송 비용
    deliverPickupPrice?: number;

    // 등록일
    timestamp: number;

    //위도
    latitude?: string;

    //경도
    longitude?: string;
}

export type {
    IOoPharmacy,
};

export enum PharmacyDeliveryType {
    no = 0,
    quick = 1, // 미사용
    today = 2,
    package = 3,
    pickup = 4,
    kakao = 5,
};

export enum PharmacyType {
    frontOfGate = 0,
    base = 1,
};