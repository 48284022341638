import { useQuery } from "react-query";
import _ from "lodash";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import { useSuspenseHook } from "@hooks/suspense";
import { OoParamKeyword } from "@params/common/paramkeyword";
import { DoctorOnAPI } from "@request/doctorOnAPI";
import { isRequestSucceed } from "../../api";
import { unospaySearchByNameState } from "@recoils/search";

interface Props {
  searchQueryTrigger: boolean;
  searchName: string;
}

export const useFindByNameUnospayQuery = ({
  searchQueryTrigger,
  searchName,
}: Props) => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
  const setSearchList = useSetRecoilState(unospaySearchByNameState);
  return useQuery(
    "findByNameUnospay",
    async () => {
      try {
        setLoading();
        const param = new OoParamKeyword();
        param.keyword = searchName;
        const response = await DoctorOnAPI.shared.unospay.fulltextSearch(param);
        setStopLoading();
        if (isRequestSucceed(response)) {
          if (_.isEqual(response.data?.length, 0)) {
            toast.error(searchName + "으로 검색된 결과가 없습니다.");
            return null;
          }
          setSearchList({ userName: searchName, list: response.data || [] });
          return response.data;
        }
        return null;
      } catch (e: any) {
        setErrorStatus(e);
      }
    },
    {
      enabled: searchQueryTrigger,
      retry: true,
      refetchOnWindowFocus: false,
      refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
    }
  );
};
