import {useMutation} from "react-query";
import {useSuspenseHook} from "@hooks/suspense";
import {OoParamHospitalUpdate} from "@params/hospital";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";


export const useHospitalUpdateQuery = () => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();

    return useMutation(
        'hospital/update',
        async (param: OoParamHospitalUpdate) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.hospital.update(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
    )
}