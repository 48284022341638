import { DoctorOnAPI } from '@api/request'
import { ModalOverlay } from '@components/modal'
import React, { Dispatch, SetStateAction } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { ChoiceProps } from '..'

interface Props {
    showPopup: boolean;
    setShowPopup: Dispatch<SetStateAction<boolean>>;
    state:ChoiceProps|undefined;
    setState:Dispatch<SetStateAction<ChoiceProps|undefined>>;
    setChoiceProvider:Dispatch<SetStateAction<string>>;
    setChoiceType:Dispatch<SetStateAction<string>>;
    getProductSettingList:()=>void;
}

const ProductSettingModal = ({showPopup, setShowPopup,setState,setChoiceProvider,setChoiceType,getProductSettingList,state}:Props) => {

    const deleteProduct = async (id:string) => {
        try {
            const response = await DoctorOnAPI.shared.productSetting.delete(id);
            if(response.status === 'ok'){
                toast.success('삭제되었습니다.');
                setShowPopup(false)
                setState({type:'',id:''})
                setChoiceProvider('all')
                setChoiceType('all');
                getProductSettingList();
            }
        } catch (error : any) {
            console.log(error.message);
        }
    }

    const hadnleDelete = () => {
        if(state){
            deleteProduct(state.id);
        }
    }

  return (
    <ModalOverlay visible={showPopup}>
                <Contents>
                    <Title>제품을 삭제 하시겠습니까?</Title>
                    <ButtonContainer>
                        <CancelButton type="button" onClick={() => setShowPopup(false)}>
                            취소
                        </CancelButton>
                        <ContinueButton type="button" onClick={hadnleDelete}>
                            삭제
                        </ContinueButton>
                    </ButtonContainer>
                </Contents>
    </ModalOverlay>
  )
}

export default ProductSettingModal

const Contents = styled.div`
  width: 20%;
  height: 20%;
  background: #ffffff;
  border-radius: 8px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 45%;
  min-width: 500px;

  @media (min-width: 1024px) {
    width: 40%;
    height: 20%;
  }

  @media (min-width: 768px) {
    width: 20%;
    height: 20%;
  }
`;

const Title = styled.h4`
font-size: 25px;
font-weight: 600;

@media (min-width: 768px) {
    font-size: 25px;
  }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    flex: 3;
    justify-content: center;
    `



const CancelButton = styled.button`
width: 222px;
height: 60px;
background: #6e8ed1;
border-radius: 8px;
color: #ffffff;
font-size: 16px;
font-weight: 700;
border: none;

@media (min-width: 1024px) {
  width: 150px;
  height: 50px;
}

@media (min-width: 768px) {
  width: 150px;
  height: 50px;
}
`;


const ContinueButton = styled.button`
  width: 222px;
  height: 60px;
  background: #cf5d5d;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: none;

  @media (min-width: 1024px) {
    width: 150px;
    height: 50px;
  }

  @media (min-width: 768px) {
    width: 150px;
    height: 50px;
  }
`;