import {AbstractOoParam} from "@api/params";

class OoParamOAuth extends AbstractOoParam {

    public readonly token: string;
    public readonly type: number;
    public readonly nationCode: string;
    public readonly os: string;
    public readonly model: string;
    public readonly version: string;


    constructor(
        public readonly idToken: string) {
        super();

        this.nationCode = "82";
        this.type = 1;
        this.os = "windows 10";
        this.model = "custom";
        this.version = "10";
        this.token = "none";
    }
}

export {
    OoParamOAuth
};