import React from "react";
import {Link} from "react-router-dom";
import {useRecoilValue} from "recoil";
import _ from "lodash";
import styled, {css} from "styled-components";
import {filterFamilyDiagnosisReversed, getPatientInfo, IOoDiagnosis, isMyFamilyMember} from "@models/diagnosis";
import {displayListState} from "@recoils/display";
import {getDiagnosisStatus} from "@models/diagnosis/diagnosisProcessingState";
import {getPharmacyMedicineDeliveryPayStatus, getPharmacyMedicinePayStatus} from "@models/pharmacy";
import {getMedicineDeliveryState2} from "@models/diagnosis/medicineDeliveryState2";
import {getDiagnosisStatusByPharmacy} from "@models/pharmacy/diagnosisProcessingStateByPharmacy";
import {PatientInfoField} from "@components/patientInfoField";
import {formatTel} from "@hooks/format";
import {ListStatusField} from "@components/listStatusField";
import {DiagnosisStatusField} from "@components/diagnosisStatusField";

interface Props {
    list: Array<IOoDiagnosis>,
}

interface FlexProps {
    flex: number,
}

export const PharmacyListRow = ({list}: Props) => {
    const displayList = useRecoilValue(displayListState('pharmacy'));
    const predicate = (item: IOoDiagnosis, target: IOoDiagnosis) => isMyFamilyMember(target, item);
    const findFamilyList = (target: IOoDiagnosis) => filterFamilyDiagnosisReversed(displayList.list, (d) => predicate(d, target));

    return (
        <Wrapper>
            {
                list.map((item, index) => {
                    const status = {
                        patientInfo: getPatientInfo(item),
                        processStatus: getDiagnosisStatus(item),
                        medicinePay: getPharmacyMedicinePayStatus(item),
                        medicineDeliveryType: getMedicineDeliveryState2(item),
                        deliveryPay: getPharmacyMedicineDeliveryPayStatus(item),
                    };
                    return (
                        <StatusRow key={index}>
                            <Link to={`/pharamcyPrescription`}
                                  state={{data: item, familyData: findFamilyList(item)}}
                                  key={index}>
                                <DiagnosisStatusField status={getDiagnosisStatusByPharmacy(item)} flex={1.3}/>
                                <PatientInfoField title={status.patientInfo.patientName}
                                                  isShowTag={!_.isEqual(status.patientInfo.familyKey, 0)}
                                                  tagTitle={"가족"}
                                                  flex={0.7}/>
                                <FlexDiv flex={1}>{formatTel(item.patientTel)}</FlexDiv>
                                <FlexDiv flex={1}>
                                    {_.isUndefined(status.patientInfo.deliveryAddress) ? '-' : status.patientInfo.deliveryAddress}
                                </FlexDiv>
                            </Link>
                            <DeliveryTypeContainer flex={0.8}>
                                <DeliveryTypeStatus id={status.medicineDeliveryType.title}>
                                    {status.medicineDeliveryType.title}
                                </DeliveryTypeStatus>
                            </DeliveryTypeContainer>
                            <Link to={`/pharamcyPrescription`} state={{data: item, familyData: findFamilyList(item)}}>
                                <ListStatusField color={status.medicinePay.color}
                                                 title={status.medicinePay.title}
                                                 flex={1}
                                />
                                <ListStatusField color={status.deliveryPay.color}
                                                 title={status.deliveryPay.title}
                                                 flex={1}
                                />
                                <DeliveryStatusContainer flex={1}>
                                    <DeliveryStatus color={status.medicineDeliveryType.backgroundColor}>
                                        {status.medicineDeliveryType.text}
                                    </DeliveryStatus>
                                </DeliveryStatusContainer>
                            </Link>
                            <FlexDiv flex={0.8}><PrintButton>출력 하기</PrintButton></FlexDiv>
                        </StatusRow>
                    )
                })
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 80%;
  max-width: 1280px;
  min-width: 1024px;
  height: 100%;
  overflow: auto;
  margin-top: 418px;
  display: flex;
  justify-content: center;
`;

const FlexDiv = styled.div<FlexProps>`
  flex: ${(props) => props.flex};
`;

const StatusRow = styled.div`
  width: 1024px;
  height: 64px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px -2px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 4px;

  & > a:first-child {
    color: #030303;
    text-decoration: none;
    display: flex;
    flex: 3.4;
    justify-content: center;

    &:hover {
      color: #030303;
      text-decoration: none;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(4) > {
        flex: 1;
      }
    }
  }

  & > a:nth-child(3) {
    color: #030303;
    text-decoration: none;
    display: flex;
    flex: 2.3;
    justify-content: center;

    &:hover {
      color: #030303;
      text-decoration: none;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const DeliveryTypeContainer = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeliveryTypeStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 24px;
  border-radius: 4px;
  color: #FFFFFF;
  border: none;
  font-weight: 500;
  font-size: 12px;

  ${props =>
          _.includes(props.id, '방문수령') ?
                  css`
                    background: #4CA555;
                  `
                  :
                  css`
                    background: #00326A;
                  `
  }
`;

const PrintButton = styled.button`
  width: 71px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #C2C1BD;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #536DA3;
`;

const DeliveryStatusContainer = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeliveryStatus = styled.div`
  width: 60px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
`;