import {Card, Col, Row} from "react-bootstrap";

export const PharmacyLedgerColumn = () => {
    return (
        <Card className="mt-4 mb-1">
            <Col>
                <Row style={{
                    textAlign: "center",
                    height: "44px",
                    alignItems: "center",
                    color: "#949390",
                }}>
                    <Col style={{flex: "0"}}/>
                    <Col><b>결제일</b></Col>
                    <Col><b>환자명</b></Col>
                    <Col><b>약제비</b></Col>
                    <Col><b>배송비</b></Col>
                    <Col><b>환불 금액</b></Col>
                    <Col><b>결제 금액</b></Col>
                    <Col><b>정산 금액</b></Col>
                </Row>
            </Col>
        </Card>
    )
}