import React from "react";
import styled from "styled-components";
import _ from "lodash";
import {IOoDiagnosis, OoWapleReservationType} from "@api/models";
import {formatDateYYYYMDHHmm} from "@hooks/format";

interface Props {
    data: IOoDiagnosis,
}

const filteredReservationType = (data: IOoDiagnosis): string => {
    let text = '';
    switch (data.wapleReservationType) {
        case OoWapleReservationType.visitReservation:
            text = '예약(대면)';
            break;
        case OoWapleReservationType.visitRegister:
            text = '바로접수(대면)';
            break;
        case OoWapleReservationType.phoneReservation :
            text = '예약(비대면)';
            break;
        case OoWapleReservationType.phoneRegister :
            text = '바로접수(비대면)';
            break;
        case OoWapleReservationType.videoReservation :
            text = '예약(비대면)';
            break;
        case OoWapleReservationType.videoRegister :
            text = '바로접수(비대면)';
            break;
        default:
            text = '바로접수(비대면)';
    }
    return text;
};

export const ReservationInfo = ({data}: Props) => {
    return (
        <Wrapper>
            <Title>진료 정보</Title>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>접수 방식</td>
                    <td>{filteredReservationType(data)}</td>
                </tr>
                {
                    (!_.isUndefined(data.wapleReservationTimestamp) && !_.isEqual(data.wapleReservationTimestamp, 0)) &&
                    <tr>
                        <td>예약 시간</td>
                        <td>{formatDateYYYYMDHHmm(data.wapleReservationTimestamp)}</td>
                    </tr>
                }
                </tbody>
            </Table>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  padding-bottom: 50px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 7px;
`;

const Table = styled.table`
  width: 100%;
  height: auto;
  border: 2px solid #C8D6F8;

  & > tbody tr:first-child {
    border-bottom: 1px solid #C8D6F8;
  }

  & > tbody tr td:nth-child(2n-1) {
    width: 100px;
    height: 48px;
    background: #6E8ED1;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    color: #FFFFFF;
  }

  & > tbody tr td:nth-child(2n) {
    background: #FAFBFF;
    padding-left: 24px;
    font-weight: 400;
    font-size: 13px;
    color: #000000;
  }
`;