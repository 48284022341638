import {useNavigate} from "react-router-dom";
import {useHospitalFunctionHook} from "@hooks/hospital/useHospitalFunctionHook";
import {useRecoilState, useRecoilValue} from "recoil";
import {doctorListState, hospitalListState, listState, pharmacyListState} from "../../../../recoils";
import {useMemo, useState} from "react";
import {includes, isEmpty, isEqual} from "lodash";
import {useDoctorFunctionHook} from "@hooks/doctor";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";
import {IOoPharmacy} from "@models/pharmacy";
import {IOoDoctor, IOoHospital} from "@api/models";
import {toast} from "react-toastify";
import { IOoCalculateList } from "@api/models/calculate/calculateList";
import { useCalculateFunctionHook } from "@hooks/calculate/useCalculateFunctionHook";
import { calculateListState } from "@recoils/calculate/calculateListState";
import { IOoCalculateMonthList } from "@api/models/calculate/calculateMonthList";
import { calculateRawListState } from "@recoils/calculate/calculateRawListState";

export type List = IOoPharmacy | IOoDoctor | IOoHospital | IOoCalculateList | IOoCalculateMonthList ;

export const useListSearchSettingHook = (target: string) => {
    
    useDoctorFunctionHook();
    const {getHospitalName, getHospitalTel} = useHospitalFunctionHook();
    const {findTargetHospitalName} = usePharmacyFunctionHook();
    const { calculateListResp, calculateRawListResp} = useCalculateFunctionHook();

    const navigate = useNavigate();

    const doctorList = useRecoilValue(doctorListState);
    const hospitalList = useRecoilValue(hospitalListState);
    const pharmacyList = useRecoilValue(pharmacyListState);
    const calculateList = useRecoilValue(calculateListState);
    const calculateRawList = useRecoilValue(calculateRawListState);
    
    const [displayList, setDisplayList] = useRecoilState(listState(target));
    const [buttonStatus, setButtonStatus] = useState({
        cancel: false,
        search: false,
    });

    const list: Array<List> = useMemo(() => {
        if (isEqual(target, "doctor")) {
            return doctorList;
        } else if (isEqual(target, "hospital")) {
            return hospitalList;
        } else if (isEqual(target, "calculate")) {
            return calculateList;
        }else if (isEqual(target, "calculateRaw")) {
            return calculateRawList;
        } else {
            return pharmacyList;
        }
    }, [target, doctorList, hospitalList, pharmacyList, calculateList , calculateRawList]);

    const onRegisterDoctor = (e: any) => {
        e.preventDefault();
        if (isEqual(target, "doctor")) {
            navigate("/addDoctorInfo");
        } else if (isEqual(target, "hospital")) {
            navigate("/addHospitalInfo");
        } else {
            navigate("/addPharmacyInfo");
        }
    };

    const onChangeDeleteButton = (event: boolean) => {
        if (event) {
            setButtonStatus({search: false, cancel: event});
            setDisplayList({...displayList, list: list, userName: ""});
            return;
        }
        setButtonStatus({search: false, cancel: event});
    };

    const onClickSearchButton = () => {
        isEmpty(displayList.userName) && toast.error('검색어를 입력해주세요.');
        const result = list.filter((item) => includes(item.name , displayList.userName || ""));
        setDisplayList({...displayList, list: result});
    };

    const onChangeSearchName = (value: string) => {
        setDisplayList({...displayList, userName: value});
    };

    return {
        displayList,
        setDisplayList,
        calculateListResp,
        calculateRawListResp,
        buttonStatus,
        onRegisterDoctor,
        onChangeDeleteButton,
        onClickSearchButton,
        onChangeSearchName,
        setButtonStatus,
        list,
        findTargetHospitalName,
        getHospitalName,
        getHospitalTel
    }
}