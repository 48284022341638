import {AxiosInstance} from "axios";
import {OoParamDoctorScheduleCreate} from "@params/reservation/doctorSchduleCreate";
import {IOoDataResponse, IOoResponse, OoParamId} from "api";
import {endPoint} from "./base";
import {OoParamDoctorSchedule} from "@params/reservation/doctroSchdule";
import {IOoDataUnit, IOoReservation} from "@models/reservation";
import {OoParamReservationCancel} from "@params/reservation/hospitalCancel";
import {OoParamReservationConfirm} from "@params/reservation/confirm";
import {OoParamWapleEnableSlot} from "@params/reservation/enabledSlot";
import {OoParamReservationUpdate} from "@params/reservation/update";
import {OoParamWapleDoctorInfo} from "@params/reservation/doctorUpdateInfo";
import {OoParamReservationDiagnosisStatus} from "@params/reservation/changeStatus";

class DoctorOnReservationAPI {
    constructor(private readonly axiosInstance: AxiosInstance) {
    }

    //의사 진료 스케줄
    async getDoctorSchedule(param: OoParamDoctorSchedule): Promise<IOoDataResponse<IOoReservation[]>> {
        return await this.axiosInstance.post<IOoDataResponse<IOoReservation[]>>(endPoint.reservation.getDoctorSchedule, param);
    }

    //의사 진료 스케줄 설정
    async setDoctorSchedule(param: OoParamDoctorScheduleCreate): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.reservation.setDoctorSchedule, param);
    }

    //의사 정보 업데이트
    async updateDoctor(param: OoParamWapleDoctorInfo): Promise<IOoDataResponse<IOoResponse>> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.reservation.updateDoctor, param);
    }

    //예약 취소 (병원취소)
    async hospitalCancel(param: OoParamReservationCancel): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.reservation.hospitalCancel, param);
    }

    //예약 확정
    async confirm(param: OoParamReservationConfirm): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.reservation.confirm, param);
    }

    //예약 가능 시간 조회
    async getEnableSlot(param: OoParamWapleEnableSlot): Promise<IOoDataResponse<Array<IOoDataUnit>>> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.reservation.getEnableSlot, param);
    }

    //예약 시간 변경
    async update(param: OoParamReservationUpdate): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.reservation.update, param);
    }

    //내원 확인
    async visitComplete(param: OoParamId): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.reservation.visitComplete, param);
    }

    //예약일 도래 테스트
    async testTodayConfirm(param: OoParamReservationDiagnosisStatus): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.reservation.testTodayConfirm, param);
    }
}

export {
    DoctorOnReservationAPI
};