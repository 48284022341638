import {IOoDiagnosis, OoDiagnosisType, OoTelemedicineType} from "@models/diagnosis";
import _ from "lodash";
import React from "react";
import styled, {css} from "styled-components";

interface Props {
    list: IOoDiagnosis,
    flex?: number,
}

export const DiagnosisType = ({list, flex}: Props) => {
    return (
        <Wrapper flex={flex ?? 0}>
            {
                (_.isEqual(OoDiagnosisType.offline, list.type) || _.isEqual(OoDiagnosisType.offlineReservation, list.type)) ?
                    <Label color={"방문"}>방문</Label>
                    : _.isEqual(OoTelemedicineType.video, list.telemedicineType) ?
                        <Label color={"화상"}>화상</Label> :
                        <Label color={"전화"}>전화</Label>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
`;

const Label = styled.label`
  font-size: ${(props) => props.theme.fontSize.fs12};
  color: ${(props) => {
    switch (props.color) {
      case "방문":
        return css`
          ${(props) => props.theme.colors.statusRowFontColor.diagnosisTypeOffLine}`
      case "화상":
        return css`
          ${(props) => props.theme.colors.statusRowFontColor.diagnosisTypeVideo}`
      case "전화":
        return css`
          ${(props) => props.theme.colors.statusRowFontColor.diagnosisTypePhone}`
    }
  }};
`;

