import { ModalOverlay } from "@components/modal";
import { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import {OoOrderPlatformType} from "@api/models";

interface Props {
  modalVisible: boolean;
  title: any;
  contents: any;
  cancelButtonName: string;
  submitButtonName: string;
  selectValue?: number;
  onChangedVisible: (status: boolean) => void,
  onChangedTrigger: (status: boolean, value: number) => void,
}

export const OrderPlatformModal = ({
  modalVisible,
  title,
  contents,
  cancelButtonName,
  submitButtonName,
  selectValue,
  onChangedVisible,
  onChangedTrigger,
}: Props) => {

  const [handleVisible, setHandleVisible] = useState<boolean>(modalVisible);
  const [submit, setSubmit] = useState<boolean>(false);

  const handleSubmit = () => setSubmit(!submit);

  const [selectOrderNumber, setSelectOrderNumber] = useState<number>(0);
  const onSelectFilterOrder = (e: any) => {
    const { value } = e.target;
    setSelectOrderNumber(_.toNumber(value));
  };

  useEffect(() => {
    setHandleVisible(modalVisible);
    setSelectOrderNumber(selectValue!);
  }, [modalVisible]);

  useEffect(() => {
      onChangedVisible(handleVisible);
  }, [handleVisible]);

  useEffect(() => {
    onChangedTrigger(submit, selectOrderNumber);
  }, [submit]);

  return (
    <ModalOverlay visible={handleVisible}>
      <Wrapper>
        <Contents>
          <div className="contentsTitle">{title}</div>
          <div className="contents">{contents}</div>
          <SelectContainer>
            <select
              className="select"
              onChange={onSelectFilterOrder}
              value={selectOrderNumber}
            >
              <option value={OoOrderPlatformType.kiosk}>키오스크</option>
              <option value={OoOrderPlatformType.mtmMall}>MTM 몰</option>
              <option value={OoOrderPlatformType.telephone}>전화주문</option>
              <option value={OoOrderPlatformType.test}>TEST</option>
            </select>
          </SelectContainer>
        </Contents>
        <ButtonContainer>
          <CancelButton type="button" onClick={() => setHandleVisible(!handleVisible)}>
            {cancelButtonName}
          </CancelButton>
          <SubmitButton type="button" onClick={handleSubmit}>{submitButtonName}</SubmitButton>
        </ButtonContainer>
      </Wrapper>
    </ModalOverlay>
  );
};
const Wrapper = styled.div`
  width: 556px;
  height: 312px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Contents = styled.div`
  width: 100%;
  height: 32px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

  & .contentsTitle {
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 36px;
  }

  & .contents {
    width: 100%;
    color: #000;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    margin-top: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 184px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled.button`
  width: 222px;
  height: 60px;
  background: #6e8ed1;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: none;
`;

const CancelButton = styled.button`
  width: 222px;
  height: 60px;
  background: #cf5d5d;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: none;
  margin-right: 10px;
`;

const SelectContainer = styled.div`
  width: 100%;
  height: 52px;
  flex-shrink: 0;
  font-size: 13px;
  margin-top: 20px ;
  margin-bottom: 36px;
  .select {
    border-radius: 8px;
    border: 0.5px solid #c2c1bd;
    width: 240px;
    height: 52px;
    padding: 5px;
  }
`;
