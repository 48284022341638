import {useRecoilValue} from "recoil";
import {hospitalListState} from "@recoils/display";
import {editSelectorFamily} from "@recoils/status";
import {useState} from "react";
import _ from "lodash";
import {useCardEdit} from "@hooks/common";
import {IOoPharmacy, PharmacyDeliveryType} from "@models/pharmacy";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";

export const useTargetPharmacyHook = (data: IOoPharmacy, componentName: string) => {
    const {updatePharmacy, findTargetHospitalName} = usePharmacyFunctionHook();
    const {isReset} = useCardEdit(componentName);
    const list = useRecoilValue(hospitalListState);
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const [items, setItems] = useState({
        hospitalIdList: data.hospitalIdList || [],
        deliveryTypeList: data.deliveryTypeList || [],
        deliverTodayPrice: data.deliverTodayPrice || 0,
        deliverQuickPrice: data.deliverQuickPrice || 0,
        deliverPackagePrice: data.deliverPackagePrice || 0,
        deliverPickupPrice: data.deliverPickupPrice || 0,
        todayRegionKeywordList: data.todayRegionKeywordList || [],
        todayRegionKeywordHitCount: data.todayRegionKeywordHitCount || 2,
    });

    const [hospitalName, setHospitalName] = useState<string>("");

    // 지정병원 선택
    const onHospitalChange = (targetId: string) => {
        const copied = _.cloneDeep(items.hospitalIdList);
        const index = copied.findIndex((item) => _.isEqual(item, targetId));
        if (index > -1) {
            const removeArr = _.remove(copied, (item => _.isEqual(item, targetId)));
            setItems({...items, hospitalIdList: removeArr});
        } else {
            const newArr = _.concat(copied, targetId);
            setItems({...items, hospitalIdList: newArr});
        }
    };

    // 당일배송
    const onCheckedToday = (e: any) => {
        const copied = _.cloneDeep(items.deliveryTypeList);
        const index = copied.findIndex((item) => _.isEqual(item, PharmacyDeliveryType.today));
        if (e.target.checked) {
            const newArr = _.concat(copied, PharmacyDeliveryType.today);
            setItems({...items, deliveryTypeList: newArr});
        } else {
            if (_.gte(index, 0)) {
                copied.splice(index, 1);
                setItems({...items, deliveryTypeList: copied});
            }
        }
    };

    // 택배
    const onCheckedPackage = (e: any) => {
        const copied = _.cloneDeep(items.deliveryTypeList);
        const index = copied.findIndex((item) => _.isEqual(item, PharmacyDeliveryType.package));
        if (e.target.checked) {
            const newArr = _.concat(copied, PharmacyDeliveryType.package);
            setItems({...items, deliveryTypeList: newArr});
        } else {
            if (_.gte(index, 0)) {
                copied.splice(index, 1);
                setItems({...items, deliveryTypeList: copied});
            }
        }
    };

    const onChangeTodayPrice = (e: any) => {
        const target = items.deliveryTypeList.filter((item) => _.isEqual(item, _.toNumber(e.target.id)));
        !_.isEmpty(target) && setItems({...items, deliverTodayPrice: _.toNumber(e.target.value)});
    };

    const onChangePackagePrice = (e: any) => {
        const target = items.deliveryTypeList.filter((item) => _.isEqual(item, _.toNumber(e.target.id)));
        !_.isEmpty(target) && setItems({...items, deliverPackagePrice: _.toNumber(e.target.value)});
    };

    const onSelectedHitCount = (e: any) => {
        setItems({...items, todayRegionKeywordHitCount: _.toNumber(e.target.value)});
    };

    const onChangeTodayKeywordList = (e: any) => {
        setItems({...items, todayRegionKeywordList: [e.target.value]});
    };

    const findDeliveryChecked = (type: PharmacyDeliveryType): boolean => {
        if (_.isUndefined(items.deliveryTypeList)) return false;
        const matched = items.deliveryTypeList.filter((item) => _.isEqual(item, type));
        return matched.length > 0;
    };

    const makeKeywordList = (arr: Array<string>) => {
        const splitArr = _.split(_.head(arr), ',');
        return splitArr.map((item) => _.trim(item));
    };

    return {
        list,
        editStatus,
        items,
        setItems,
        onHospitalChange,
        onCheckedToday,
        onCheckedPackage,
        onChangeTodayPrice,
        onChangePackagePrice,
        onSelectedHitCount,
        onChangeTodayKeywordList,
        findDeliveryChecked,
        updatePharmacy,
        findTargetHospitalName,
        isReset,
        hospitalName,
        setHospitalName,
        makeKeywordList,
    }
}