import {useRecoilValue} from "recoil";
import {editSelectorFamily} from "@recoils/status";
import {useState} from "react";
import {useCardEdit} from "@hooks/common";
import {IOoPharmacy} from "@models/pharmacy";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";

export const usePharmacyAlimTalkHook = (data: IOoPharmacy, componentName: string) => {
    const {updatePharmacy} = usePharmacyFunctionHook();
    const {isReset} = useCardEdit(componentName);
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const [color, setColor] = useState<string>("grey");
    const [item, setItem] = useState({
        alimTalkReceivers: data.alimTalkReceivers,
    });

    const onChangeAlimTalk = (e: any) => setItem({...item, alimTalkReceivers: [e.target.value]});

    return {
        editStatus,
        color,
        setColor,
        item,
        setItem,
        onChangeAlimTalk,
        isReset,
        updatePharmacy,
    }
}