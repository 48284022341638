import { IOoPharmacy } from '@api/models/pharmacy';
import { SearchInfo } from '@recoils/display';
import React, { useMemo } from 'react';
import { HospitalListContainer } from '../ListPage/components/HospitalListContainer';
import { PharmacyListContainer } from '../ListPage/components/PharmacyListContainer';
import CalculateListContainer from '../ListPage/components/CalculateListContainer';
import CalculateRawListContainer from '../ListPage/components/CalculateRowListContainer';
import { DoctorListContainer } from '../ListPage/components';
import { IOoCalculateList } from '@api/models/calculate/calculateList';
import { IOoCalculateMonthList } from '@api/models/calculate/calculateMonthList';

interface Props{
    target:string;
    displayList: SearchInfo;
    findTargetHospitalName: (item: IOoPharmacy) => string | undefined;
    sortInfo: {
        name: string;
        type: boolean;
    }
    getHospitalName: (id: string) => string
    getHospitalTel: (id: string) => string | undefined
}

const FilteredList = ({ target, displayList, findTargetHospitalName, sortInfo, getHospitalName, getHospitalTel }:Props) => {
    const filteredList = useMemo(() => {
        switch (target) {
            case 'hospital':
                return <HospitalListContainer data={displayList.list || []}/>
            case 'pharmacy' :
                return <PharmacyListContainer data={displayList.list || []} findTargetHospitalName={findTargetHospitalName}/>
            case 'calculate' :
                return <CalculateListContainer data={displayList.list as Array<IOoCalculateList> || []} sortInfo={sortInfo}/>
            case 'calculateRaw' :
                return <CalculateRawListContainer data={displayList.list as Array<IOoCalculateMonthList> || []}/>
            default  :
                return <DoctorListContainer data={displayList.list || []} getHospitalName={getHospitalName} getHospitalTel={getHospitalTel}/>
        }
    }, [target, displayList, findTargetHospitalName, sortInfo, getHospitalName, getHospitalTel]);

    return filteredList;
};

export default FilteredList;
