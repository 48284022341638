import {atom} from "recoil";
import {IOoHospital} from "@models/hospital";

interface SearchProps {
    userName?: string,
    list: Array<IOoHospital>,
}

export const hospitalSearchByNameState = atom<SearchProps>({
    key: 'hospitalSearchListState',
    default: {
        userName: '',
        list: [],
    }
});