import { IOoProducts } from '@api/models/productSetting/product';
import { DoctorOnAPI } from '@api/request';
import { ModalOverlay } from '@components/modal';
import _, { update } from 'lodash';
import React, { ChangeEvent, useState } from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { MdLibraryAdd } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { MdDelete } from "react-icons/md";

interface Article {
  width?:string;
  height?: string;
  maxHeight?: string;
  overflowY?: string;
  display?: string;
  flexDirection?: string;
  gridTemplateColumns?: string;
  gridTemplateRow?: string;
  padding?: string;
  columnGap?: string;
  rowGap?: string;
}

interface AddProductInfoType {
  externalCode: string;
  providerName: string;
  productName: string;
  productFullName: string;
  productType: number;
  settleRate: number;
  supplyPrice: number;
  retailPrice: number;
  deliveryPrice: number;
  status: number;
  productCode: string;
  optionList: AddOptionInfoListType[];
  isConsignment: boolean;
  isShow: boolean;
}


interface InputLayout{
  width?: string;
}

interface AddOptionInfoListType {
  optionName:string;
  optionCode:string;
  optionPrice:number;
  optionSupplyPrice:number;
  optionOrderName:string;
  optionOrderCode:string;
}

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title?:string;
  updateProductInfo?: AddProductInfoType;
  setUpdateProductInfo?: React.Dispatch<React.SetStateAction<AddProductInfoType>>;
  setfilterProductList: React.Dispatch<React.SetStateAction<IOoProducts[] | undefined>>;
  filterProductList: IOoProducts[] | undefined;
}

const ProductAddInfoPage = ({visible , setVisible, title , updateProductInfo , setUpdateProductInfo , setfilterProductList , filterProductList}:Props) => {
  const [addProductInfo , setAddProductInfo] = useState<AddProductInfoType>({
    externalCode: "",
    providerName: "",
    productName: "",
    productFullName: "",
    productType: 0,
    settleRate: 0,
    supplyPrice: 0,
    retailPrice: 0,
    deliveryPrice: 0,
    status: 0,
    productCode: "",
    optionList:[],
    isConsignment: false,
    isShow: false,
  });
  const [addOptionInfoList, setAddOptionInfoList] = useState<AddOptionInfoListType[]>([{optionName: "", optionCode: "", optionPrice:0, optionSupplyPrice:0, optionOrderName:"", optionOrderCode:""}]);
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if(setUpdateProductInfo){
      if(name === 'retailPrice' || name === 'supplyPrice' || name === 'deliveryPrice'){
        setUpdateProductInfo(prev => ({...prev , [name]:Number(value)}));
      }else{
        setUpdateProductInfo(prev => ({...prev , [name]:value}));
      }
    }else{
      if(name === 'retailPrice' || name === 'supplyPrice' || name === 'deliveryPrice'){
        setAddProductInfo(prev => ({...prev , [name]:Number(value)}));
      }else{
        setAddProductInfo(prev => ({...prev , [name]:value}));
      }
    }
  };

  const handleChangeOption = (e: ChangeEvent<HTMLInputElement> , index:number) => {
    const { name, value } = e.target;
    if(setUpdateProductInfo){
      setUpdateProductInfo(prev => ({...prev, optionList: prev.optionList.map((item, idx) => 
        idx === index? { ...item, [name]: (name === 'optionPrice' || name === 'optionSupplyPrice') ? Number(value) : value } : item
        )
        }));
    }else{
      setAddOptionInfoList(prevList => 
        prevList.map((item, idx) => 
        idx === index ? { ...item, [name]: (name === 'optionPrice' || name === 'optionSupplyPrice') ? Number(value) : value } : item
        )
        );
        setAddProductInfo({...addProductInfo, optionList:addOptionInfoList});
    }
  }

  const handleChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if(setUpdateProductInfo){
      if(name === 'productType' || name === 'status'){
        setUpdateProductInfo(prev => ({...prev , [name]:Number(value)}));
      }else{
        setUpdateProductInfo(prev => ({...prev , [name]: value === "0" ? false : true}));
      }
    }else{
      if(name === 'productType' || name === 'status'){
        setAddProductInfo(prev => ({...prev , [name]:Number(value)}));
      }else{
        setAddProductInfo(prev => ({...prev , [name]: value === "0" ? false : true}));
      }
    }
  };

  const onKeyupCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if(setUpdateProductInfo){
      setUpdateProductInfo(prev => ({...prev , [name]: Number(value.replace(/[^-\.0-9]/g, ""))}));
    }else{
      setAddProductInfo(prev => ({...prev , [name]: Number(value.replace(/[^-\.0-9]/g, ""))}));
    }
  };

  const handleClickAddOption = () => {
    if(setUpdateProductInfo){
      setUpdateProductInfo(prev => ({...prev , optionList:[...prev.optionList , {optionName: "", optionCode: "", optionPrice:0, optionSupplyPrice:0, optionOrderName:"", optionOrderCode: ""}]}));
    }else{
      setAddOptionInfoList(prev => [...prev, {optionName: "", optionCode: "", optionPrice:0, optionSupplyPrice:0, optionOrderName:"", optionOrderCode: ""}]);
    }
  }

  const handleDeleteOption = (index: number) => {
    if(setUpdateProductInfo){
      setUpdateProductInfo(prev => ({
        ...prev, 
        optionList: prev.optionList.filter((_, idx) => idx !== index)
      }));
    } else {
      setAddOptionInfoList(prev => prev.filter((_, idx) => idx !== index));
      setAddProductInfo(prev => ({
        ...prev, 
        optionList: prev.optionList.filter((_, idx) => idx !== index)
      }));
    }
  }

  const productInfoValidation = () => {
    if(updateProductInfo){
      const keysArray = Object.keys(updateProductInfo).map(key => {
        if(key === 'optionList' || key === 'productCode' || key === 'productName'){
          return true;
        }
        return updateProductInfo[key as keyof AddProductInfoType] === '' ? false : true;
      });
      return keysArray.includes(false);
    }else{
      const keysArray = Object.keys(addProductInfo).map(key => {
        if(key === 'optionList' || key === 'productCode' || key === 'productName'){
          return true;
        }
        return addProductInfo[key as keyof AddProductInfoType] === '' ? false : true;
      });
      return keysArray.includes(false);
    }
  };

  const handleClickAddProductList = () => {
    const validationResult = productInfoValidation();
    if(!validationResult){
      if(title !== 'update'){
        createProductSettingList(addProductInfo);
      }else{
        updateProductSettingList(updateProductInfo!);
      }
    }else {
      toast.error("입력하신 내용을 확인해주세요");
    }
  }

  const closePopup = () => {
    setVisible(false);
  };

  const createProductSettingList = async (createData: AddProductInfoType) => {
    try {
      const response = await DoctorOnAPI.shared.productSetting.create(createData);
      if (response.status === "ok") {
        const createDataRes = response.data![0];
        setfilterProductList([...filterProductList!, createDataRes]);
        toast.success("등록되었습니다");
        closePopup();
        setAddOptionInfoList([{optionName: "", optionCode: "", optionPrice:0, optionSupplyPrice:0, optionOrderName:"", optionOrderCode: ""}]);
        setAddProductInfo({
          externalCode: "",
          providerName: "",
          productName: "",
          productFullName: "",
          productType: 0,
          settleRate:  0,
          supplyPrice: 0,
          retailPrice: 0,
          deliveryPrice: 0,
          productCode: "",
          optionList:[],
          status: 0,
          isConsignment: false,
          isShow: false,
        })
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const updateProductSettingList = async (updateData: AddProductInfoType) => {
    try {
      const response = await DoctorOnAPI.shared.productSetting.update(updateData);
      if (response.status === "ok") {
        const updateData = response.data;
        if(updateData){
          const updateProductList = filterProductList?.map(item => item.id === updateData.id ? {...updateData} : item);
          setfilterProductList(updateProductList);
        }
        toast.success("수정되었습니다");
        closePopup();
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <ModalOverlay visible={visible}>
        <Wrapper>
          <Article padding='30px'>
              <Title>제품 세부 정보 {title === 'update' ? '수정' : '등록'}</Title>
              <CloseModalBtn onClick={closePopup}>
                <MdCancel />
              </CloseModalBtn>
            <Article display='flex' flexDirection='column' padding='15px' rowGap='10px' height='' maxHeight='680px' overflowY='auto'>
              <Article display='grid' gridTemplateColumns='1fr 1fr' columnGap='10px'>
                <InputLayout>
                  <label>카테고리</label>
                  <select name="productType" value={title !== "update" ? addProductInfo.productType : updateProductInfo?.productType} onChange={handleChangeSelect}>
                    <option value="0">MTM</option>
                    <option value="1">MTE</option>
                    <option value="2">MTS</option>
                  </select>
                </InputLayout>
                <InputLayout>
                  <label>업체명</label>
                  <input placeholder='공급자명' value={title !== "update" ? addProductInfo.providerName : updateProductInfo?.providerName} name='providerName' onChange={handleChange}/>
                </InputLayout>
              </Article>
              <Article display='grid' gridTemplateColumns='1fr 1fr' columnGap='10px'>
                <InputLayout>
                  <label>제품코드</label>
                  <input placeholder='제품코드' value={title !== "update" ? addProductInfo.externalCode : updateProductInfo?.externalCode} name='externalCode' onChange={handleChange}/>
                </InputLayout>
                <InputLayout>
                <label>요율</label>
                <input placeholder='요율' value={title !== "update" ? addProductInfo.settleRate : updateProductInfo?.settleRate} name="settleRate" onChange={onKeyupCheck}/>
                </InputLayout>
              </Article>
              <Article display='grid' gridTemplateColumns='1fr 1fr' columnGap='10px'>
                <InputLayout>
                  <label>제품명</label>
                  <input placeholder='제품명' value={title !== "update" ? addProductInfo.productFullName : updateProductInfo?.productFullName} name='productFullName' onChange={handleChange}/>
                </InputLayout>
                <InputLayout>
                  <label>발주서 제품명</label>
                  <input placeholder='발주서 제품명' value={title !== "update" ? addProductInfo.productName : updateProductInfo?.productName} name='productName' onChange={handleChange}/>
                </InputLayout>
              </Article>
              <Article display='grid' gridTemplateColumns='1fr 1fr 1fr' columnGap='10px'>
                <
                  InputLayout>
                  <label>소비자가</label>
                  <input placeholder='소비자가' value={title !== "update" ? addProductInfo.retailPrice : updateProductInfo?.retailPrice} name='retailPrice' onChange={handleChange}/>
                </InputLayout>
                <InputLayout>
                  <label>공급가</label>
                  <input placeholder='공급가' value={title !== "update" ? addProductInfo.supplyPrice : updateProductInfo?.supplyPrice} name='supplyPrice' onChange={handleChange}/>
                </InputLayout>
                <InputLayout>
                  <label>발주서코드</label>
                  <input placeholder='발주서코드' value={title !== "update" ? addProductInfo.productCode : updateProductInfo?.productCode} name='productCode' onChange={handleChange}/>
                </InputLayout>
              </Article>
              <OptionHeader>
                <div>옵션코드</div>
                <div>옵션명</div>
                <div>옵션금액</div>
                <div>옵션공급가</div>
                <div>발주서 옵션명</div>
                <div>발주서 옵션코드</div>
                <div></div>
                <AddOptionCodeBtn onClick={handleClickAddOption}>
                  <MdLibraryAdd/>
                </AddOptionCodeBtn>
              </OptionHeader>
              <InputLayout>
                <OptionBody>
                {title !== "update" ? 
                addOptionInfoList.map((item, index) => (
                  <Article key={index} display='grid' gridTemplateColumns='123px 190px 125px 125px 200px 200px 50px'>
                      <input placeholder='옵션코드' value={item.optionCode} name='optionCode' onChange={(e) => handleChangeOption(e,index)}/>
                      <input placeholder='옵션명' value={item.optionName} name='optionName' onChange={(e) => handleChangeOption(e,index)}/>
                      <input placeholder='옵션금액' value={item.optionPrice} name='optionPrice' onChange={(e) => handleChangeOption(e,index)}/>
                      <input placeholder='옵션공급가' value={item.optionSupplyPrice} name='optionSupplyPrice' onChange={(e) => handleChangeOption(e,index)}/>
                      <input placeholder='발주서 옵션명' value={item.optionOrderName} name='optionOrderName' onChange={(e) => handleChangeOption(e,index)}/>
                      <input placeholder='발주서 옵션코드' value={item.optionOrderCode} name='optionOrderCode' onChange={(e) => handleChangeOption(e,index)}/>
                      <DeleteOptionBtn onClick={() => handleDeleteOption(index)}><MdDelete /></DeleteOptionBtn>
                  </Article>
                )) : 
                updateProductInfo?.optionList.map((item, index) => (
                  <Article key={index} display='grid' gridTemplateColumns='123px 190px 125px 125px 200px 200px 50px'>
                      <input placeholder='옵션코드' value={item.optionCode} name='optionCode' onChange={(e) => handleChangeOption(e,index)}/>
                      <input placeholder='옵션명' value={item.optionName} name='optionName' onChange={(e) => handleChangeOption(e,index)}/>
                      <input placeholder='옵션금액' value={item.optionPrice} name='optionPrice' onChange={(e) => handleChangeOption(e,index)}/>
                      <input placeholder='옵션공급가' value={item.optionSupplyPrice} name='optionSupplyPrice' onChange={(e) => handleChangeOption(e,index)}/>
                      <input placeholder='발주서 옵션명' value={item.optionOrderName} name='optionOrderName' onChange={(e) => handleChangeOption(e,index)}/>
                      <input placeholder='발주서 옵션코드' value={item.optionOrderCode} name='optionOrderCode' onChange={(e) => handleChangeOption(e,index)}/>
                      <DeleteOptionBtn onClick={() => handleDeleteOption(index)}><MdDelete /></DeleteOptionBtn>
                  </Article>
                ))
              }
                </OptionBody>
              </InputLayout>
              
              <Article display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' columnGap='10px'>
                <InputLayout>
                  <label>공급방식</label>
                  <select name="isConsignment" onChange={handleChangeSelect} value={title !== "update" ? !addProductInfo.isConsignment ? 0 : 1 : !updateProductInfo?.isConsignment ? 0 : 1}>
                    <option value={0}>N</option>
                    <option value={1}>Y</option>
                  </select>
                </InputLayout>
                <InputLayout>
                  <label>공개여부</label>
                  <select name="isShow" onChange={handleChangeSelect} value={title !== "update" ? !addProductInfo.isShow ? 0 : 1 : !updateProductInfo?.isShow ? 0 : 1}>
                    <option value={0}>N</option>
                    <option value={1}>Y</option>
                  </select>
                </InputLayout>
                <InputLayout>
                  <label>업체 배송비</label>
                  <input placeholder='업체 배송비' value={title !== "update" ? addProductInfo.deliveryPrice || 0 : updateProductInfo?.deliveryPrice || 0} name='deliveryPrice' onChange={handleChange}/>
                </InputLayout>
                <InputLayout>
                  <label>상태</label>
                  <select name="status" onChange={handleChangeSelect} value={title !== "update" ? addProductInfo.status : updateProductInfo?.status}>
                    <option value={0}>판매중</option>
                    <option value={-1}>판매중지</option>
                    <option value={1}>품절</option>
                  </select>
                </InputLayout>
              </Article>
            </Article>
            <ButtonLayout>
              <SubmitBtn onClick={handleClickAddProductList}>{title !== 'update' ? '제품등록' : '수정완료'}</SubmitBtn>
            </ButtonLayout>
          </Article>
        </Wrapper>
    </ModalOverlay>
  )
}

export default ProductAddInfoPage

const Wrapper = styled.div`
    position: relative;
    display: flex;
    max-width: 1200px;
    width: 1100px;
    background-color: #ffffff;
    border-radius: 25px;
`


const Article = styled.div<Article>`
  width: ${props => props.width};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  display: ${props => props.display};
  flex-direction: ${props => props.flexDirection};
  grid-template-columns: ${props => props.gridTemplateColumns};
  grid-template-row: ${props => props.gridTemplateRow};
  padding: ${props => props.padding};
  column-gap: ${props => props.columnGap};
  row-gap: ${props => props.rowGap};
  font-weight: bold;
  overflow-y: ${props => props.overflowY};

  & > input {
    border: 1px solid #d8edff;
    align-items: center;
    padding: 3px 7px;
  }
`
const CloseModalBtn = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  border:none;
  font-size: 30px;
  background-color: transparent;
  color: red;
  opacity: 0.5;

  & > svg {
    width: 100%;
    height: auto;
  }

  &:hover{
    opacity: 1;
  }
`

const InputLayout = styled.div<InputLayout>`
  max-height: 500px;
  overflow-y: auto;
  border-radius: 3px;
  
  & > label {
    width: ${props => props.width ? props.width : '140px'};
    border: 1px solid #d8edff;
    background-color: #d8edff;
    font-weight: bold;
    padding: 5px 7px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  
  & > select {
    width: ${props => props.width ? props.width : 'calc(100% - 140px)'};
    border: 1px solid #d8edff;
    font-weight: bold;
    padding: 6px 7px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  
  & > input {
    width: ${props => props.width ? props.width : 'calc(100% - 140px)'};
    padding: 5px 7px;
    border: 1px solid #d8edff;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`

const OptionHeader = styled.div`
  display: grid;
  grid-template-columns: 123px 190px 125px 125px 200px 200px 50px;
  -webkit-box-align: center;
  align-items: center;

  border-radius: 3px;
  background: rgb(216, 237, 255);

  & > div {
    border-left: 2px solid #fff;
    padding: 5px 7px;
  }
`

const OptionBody = styled.div`
  max-height: 500px;
  width: 1020px;
  border: 1px solid #d8edff;
  overflow-y: auto;
`

const ButtonLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const SubmitBtn = styled.button`
  background-color:#2176f6;
  opacity: 0.5;
  border: none;
  padding: 10px 25px;
  color: white;
  font-weight: bold;
  border-radius: 5px;

  &:hover {
    opacity: 1;
  }
`
const AddOptionCodeBtn = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  right: 40px;
  box-sizing: border-box;
  font-size: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > svg{
    color: #2176F6;
    opacity: 0.5;
  }
  & > svg:hover{
    color: #2176F6;
    opacity: 1;
  }
`

const Title = styled.h4`

`

const DeleteOptionBtn = styled.button`
  border: none;
  background: transparent;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff4d4f;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }`
