import { useUnospayPaymentSearchSettingHook } from "@hooks/unospay";
import React from "react";
import { UnospayCustomSearchBar } from "../customSearchBar";

export const PaymentSearch = () => {
  const {
    searchList,
    buttonStatus,
    onClickSearchButton,
    onChangeDeleteButton,
    onChangeSearchName,
  } = useUnospayPaymentSearchSettingHook();

  return (
    <div>
      <UnospayCustomSearchBar
        list={searchList}
        target="unospay"
        cancelStatus={buttonStatus.cancel}
        searchStatus={buttonStatus.search}
        onChangeDeleteButton={onChangeDeleteButton}
        onChangeName={onChangeSearchName}
        onChangeSubmit={onClickSearchButton}
      />
    </div>
  );
};
