import {DoctorOnAPI, isRequestSucceed} from "../../api";
import {useSuspenseHook} from "../../hooks";
import {useMutation} from "react-query";
import {OoParamDoctorHours} from "@api/params";
import {IOoWorkHours} from "@api/models/pharmacy";

export interface TimeProps {
    id: string,
    param: IOoWorkHours[],
}

export const useDoctorTimeUpdateQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'operator/doctorTimeUpdate',
        async ({id, param}: TimeProps) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.doctor.doctorHours(new OoParamDoctorHours(id, param));
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.status;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        },
    )
};