import {IOoDiagnosis, OoMedicineDeliveryType} from "./diagnosis";
import {fnGoboxFilter, fnKakaoFilter} from "@api/models";

interface DeliveryStatus {
    title: string,
    color: DeliveryStatusColor,
    text?: string,
    backgroundColor?: string,
}

export enum DeliveryStatusColor {
    default = '#949390',
    todayDelivery = '#5385EF',
    user = '#4CA555'
}

enum BackgroundColor {
    disabled = "#C2C1BD",
    active = "#6E8ED1",
}


export function getMedicineDeliveryState2(item: IOoDiagnosis): DeliveryStatus {
    const {filteredKaKaoStatus} = fnKakaoFilter();
    const {filteredGoboxStatus} = fnGoboxFilter();
    let status = {
        title: '선택 안 함',
        color: DeliveryStatusColor.default,
        text: '-',
        backgroundColor: BackgroundColor.disabled,
    };
    switch (item.deliveryType || -1) {
        case OoMedicineDeliveryType.quickDelivery:
            status = {
                title: '퀵 배송',
                color: DeliveryStatusColor.default,
                text: '-',
                backgroundColor: BackgroundColor.disabled,
            };
            break;
        case OoMedicineDeliveryType.todayDelivery:
            status = {
                title: '당일배송',
                color: DeliveryStatusColor.todayDelivery,
                text: filteredGoboxStatus(item.deliveryOrderResultCode || ''),
                backgroundColor: filteredGoboxStatus(item.deliveryOrderResultCode || '').match('-') ? BackgroundColor.disabled : BackgroundColor.active
            };
            break;
        case OoMedicineDeliveryType.packageDelivery:
            status = {
                title: '택배',
                color: DeliveryStatusColor.default,
                text: '-',
                backgroundColor: BackgroundColor.disabled,
            };
            break;
        case OoMedicineDeliveryType.user:
            status = {
                title: '직접 픽업',
                color: DeliveryStatusColor.user,
                text: '-',
                backgroundColor: BackgroundColor.disabled,
            };
            break;
        case OoMedicineDeliveryType.hospitalDelivery:
            status = {
                title: '병원 발송',
                color: DeliveryStatusColor.default,
                text: '-',
                backgroundColor: BackgroundColor.disabled,
            };
            break;
        case OoMedicineDeliveryType.pharmacyPackage:
            status = {
                title: '방문수령',
                color: DeliveryStatusColor.user,
                text: '-',
                backgroundColor: BackgroundColor.disabled,
            };
            break;
        case OoMedicineDeliveryType.kaKaoMobility:
            status = {
                title: '카카오 퀵',
                color: DeliveryStatusColor.user,
                text: filteredKaKaoStatus(item.kakaoQuickStatus || 0),
                backgroundColor: filteredKaKaoStatus(item.kakaoQuickStatus || 0).match('-') ? BackgroundColor.disabled : BackgroundColor.active
            };
            break;
    }
    return status;
}

export type {
    DeliveryStatus
}