import {FilterCard, PharmacyListContainer} from "./components";
import React, {useEffect} from "react";
import styled, {css} from "styled-components";
import {usePharmacyDashBoardSettingHook} from "./hooks";
import packageJson from "../../../../package.json";
import {ListTitleContainer} from "@components/layout";
import {MainListTitle} from "@components/mainListTitle";
import _ from "lodash";
import moment from "moment";
import {DiagnosisCalendar} from "@components/calendar";
import {DiagnosisSearch} from "../../Operator/DiagnosisDashBoardPage/components";

interface FlexProps {
    flex: number;
}

export const PharmacyDashBoardPage = () => {
    const {
        connect,
        disconnect,
        selectedUser,
        targetPharmacy,
        searchList,
        dailyDiagnosisList,
        pharmacyRtdbList,
        updateDisplay,
        userName,
        displayList,
        pharmacyId,
        dailyDiagnosisSelectedDate,
        target
    } = usePharmacyDashBoardSettingHook();

    useEffect(() => {
        connect(`pharmacyTelemedicine/${pharmacyId}`);
        return () => disconnect();
    }, [selectedUser, pharmacyId]);

    useEffect(() => {
        if (_.gt(searchList.list?.length, 0)) {
            updateDisplay(searchList.list!)
            return;
        } else if (dailyDiagnosisList.length > 0) {
            updateDisplay(dailyDiagnosisList)
        } else {
            updateDisplay(pharmacyRtdbList);
        }
    }, [dailyDiagnosisList, searchList, pharmacyRtdbList]);

    return (
        <Wrapper>
            <Container>
                <SearchContainer>
                    <div>v{packageJson.version}</div>
                    <DiagnosisSearch target={target} id={pharmacyId}/>
                </SearchContainer>
                <MainListTitle title={`${userName} 관리자님`} maxWidth={1280} minWidth={1024}>
                    <CalendarContainer>
                        <TodayDiagnosis id={_.head(dailyDiagnosisSelectedDate.date)}>
                            당일진료
                        </TodayDiagnosis>
                        <DiagnosisCalendar target={target} id={pharmacyId}/>
                    </CalendarContainer>
                </MainListTitle>
                <FilterCard list={displayList.list}/>
                <ListTitleContainer top="367px">
                    <>
                        <Title flex={1.3}>상태</Title>
                        <Title flex={0.9}>이름</Title>
                        <Title flex={1}>전화번호</Title>
                        <Title flex={1.1}>주소</Title>
                        <Title flex={1}>수령방법</Title>
                        <Title flex={1}>결제내역</Title>
                        <Title flex={1}>배송비</Title>
                        <Title flex={1}>기사배정</Title>
                        <Title flex={1}>처방전</Title>
                    </>
                </ListTitleContainer>
            </Container>
            <PharmacyListContainer list={displayList.list}/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  top: 150px;
  position: fixed;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 26px;
`;

const SearchContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  color: gray;
  font-size: 13px;
  font-weight: 700;
`;

const TodayDiagnosis = styled.div`
  width: 96px;
  height: 36px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  ${(props) =>
          props.id === `${moment().format(`YYYY.MM.DD`)}`
                  ? css`
                    color: ${(props) => props.theme.colors.statusFontColor.success};
                    border: 1px solid ${(props) => props.theme.colors.statusFontColor.success};
                    background: ${(props) =>
                            props.theme.colors.statusBgButtonColor.success};
                  `
                  : css`
                    color: ${(props) => props.theme.colors.fontColor.black};
                    border: 1px solid ${(props) => props.theme.colors.borderColor.grey};
                    background: ${(props) => props.theme.colors.bgColor.white};
                  `}
  \`;
`;

const Title = styled.div<FlexProps>`
  flex: ${(props) => props.flex};
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

