import { IOoUnospaySalesMenu } from "./salesMenu";
import { IOoUnospayTransportInfo } from "./transport";

interface IOoUnospayDelivery {
  id?: string;

  // 수령자 이름
  recipientName: string;

  // 수령자 전화번호
  mobile?: string;

  // 수령자 주소
  address: string;

  // 수령자 상세 주소
  addressDetail: string;

  // 수령자 우편번호
  zipCode: string;

  // 배송 메모
  memo?: string;

  subMemo?: string;

  // 배송정보
  transportInfo: Array<IOoUnospayTransportInfo>;

  // 병원 이름
  hospitalName?: string;

  // 매장 ID ex) theopusone_h0014 소문자적용
  affiliateId: string;

  // 영수증번호  ex) yyMMdd+주문번호
  dailyCode: string;

  //결제시간 yyyy-MM-dd HH:mm:ss
  dateTime: string;

  //취소시간 yyyy-MM-dd HH:mm:ss (취소 결제 아닌 경우 empty)
  cancelDateTime: string;

  // 닥터온 결제 취소
  operatorCancelDateTime: string;

  //결제타입
  paymentType: string;

  //승인번호 (승인 번호 없을 경우 empty - ex 현금 결제)
  approvalNumber: string;

  //결제금액
  paymentPrice: string;

  //메뉴목록
  salesMenu?: Array<IOoUnospaySalesMenu>;

  // 키오스크에서 인증된 핸드폰 번호
  phoneNumber?: string;

  // 검색 코드
  searchCode?: Array<string>;

  timestamp: number;

  updateTimestamp: number;

  // 닥터온 결제 취소 타임스탬프
  operatorCancelTimestamp?: number;

  // 배송 상태
  status?: number;

  // 주문 경로
  orderPlatform?: number;

  // mtm몰 회원등급
  group_name?: string;

  // 재구매 횟수
  reorderCount?: number;

  cancelComment?: string;

  csMemo?: string;
}

export type { IOoUnospayDelivery };

export enum OoUnosPaymentType {
  card = 1,
  cashReceipt,
  cash,
  payco = 5,
  kakaopay = 10,
  alipay = 12,
  wechatpay,
  zeropay,
  ssgpay,
  Lpay,
  bcQR,
  unionQR,
  appCard,
  naverPay = 26,
}

export enum OoDeliveryStatus {
  emptyAddress, // 배송지 미입력
  existAddress, // 배송지 입력 완료
  // existSelectMenu, // 메뉴 선택 완료
  cancelOrder, // 주문 취소
  sendPackage, // 발송 완료
  requestCancel,
  beforePayment
}

export enum OoOrderPlatformType {
  kiosk, // 기본값
  mtmMall,
  telephone,
  test, // 테스트 상태
  noTest,
  cafe24Offline = 90,
}


export enum OoProvider {
    hdProvider = 1,
    dsProvider,
    ssProvider,
    pmoProvider,
    mdslProvider,
    empProvider,
    nbProvider,
    tktProvider,
    jspProvider,
    mkProvider,
    smProvider,
    mhdProvider,
    buyerProvider,
    ddProvider,
    mocProvider,
    mmxProvider,
    nutritionProvider,
    foremeProvider,
    bicnuProvider,
    dongaotsukaProvider,
    overMaxProvider,
    yuhanProvider,
    dxvxProvider,
    femininedayProvider,
    momProvider,
    btyProvider,
    duldaProvider,
    maeilProvider,
    udLifeProvider,
}
