import {storage} from "../../firebaseConfig";
import {v4 as uuidv4} from 'uuid';
import {filteredType} from "./storageUtils";
import {formatYyyyMMDHH} from "@hooks/format";

class StorageUploader {
    static async upload(path: string, file: File) {
        try {
            const ref = storage.ref().child(path);
            const response = await ref.put(file);
            return await response.ref.getDownloadURL();
        } catch (e: any) {
            console.error(`----------------  StorageUploader::upload ===>> ERROR: ${JSON.stringify(e)}`);
            return null;
        }
    }
}

export class DoctorProfileUploader {
    static async upload(hospitalId: string, file: File) {
        const fileType = filteredType(file.type);
        const uniqFilename = uuidv4().replaceAll('-', '');
        const path = `doctor/profile/${hospitalId}/${uniqFilename}.${fileType}`;
        return await StorageUploader.upload(path, file);

    }
}

export class HospitalLogoUploader {
    static async upload(file: File) {
        const fileType = filteredType(file.type);
        const uniqFilename = uuidv4().replaceAll('-', '');
        const path = `hospitalLogo/${uniqFilename}.${fileType}`;
        return await StorageUploader.upload(path, file);
    }
}

export class PharmacyProfileUploader {
    static async upload(file: File) {
        const fileType = filteredType(file.type);
        const uniqFilename = uuidv4().replaceAll('-', '');
        const path = `pharmacy/${uniqFilename}.${fileType}`;
        return await StorageUploader.upload(path, file);
    }
}

export class DoctorPrescriptionUploader {
    static async upload(file: File, userId: string) {
        const date = new Date();
        const fileType = filteredType(file.type);
        const uniqFilename = uuidv4().replaceAll('-', '');
        const path = `prescriptions/${userId}/${formatYyyyMMDHH(date)}/${uniqFilename}.${fileType}`;
        return await StorageUploader.upload(path, file);
    }
}