import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {HospitalBankInfo} from "../../../../interfaces/Operator/OperatorHospitalCreateInterface";
import {CustomInputText} from "../../../custom";

interface Props {
    onChanged: (data: HospitalBankInfo) => void;
}

export const HospitalRegisterBankInfo = ({onChanged}: Props) => {
    const [items, setItems] = useState<HospitalBankInfo>({
        bank: "우리은행",
        bankAccount: "1234",
        bankHolderName: "홍길동",
    });

    const onChangeBank = (e: any) => setItems({...items, bank: e.target.value});
    const onChangeBankAccount = (e: any) => setItems({...items, bankAccount: e.target.value});
    const onChangeBankHolderName = (e: any) => setItems({...items, bankHolderName: e.target.value});

    useEffect(() => {
        onChanged(items);
    }, [items]);

    return (
        <Wrapper>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>은행명</td>
                    <td>
                        <CustomInputText value={items.bank}
                                         disabled={true}
                                         onChanged={onChangeBank}
                                         size="middle"
                                         color="blue"
                                         placeholder="[필수]"/>
                    </td>
                </tr>
                <tr>
                    <td>계좌번호</td>
                    <td>
                        <CustomInputText value={items.bankAccount}
                                         onChanged={onChangeBankAccount}
                                         disabled={true}
                                         size="middle"
                                         color="blue"
                                         placeholder="- 없이 입력 [필수]"/>
                    </td>
                </tr>
                <tr>
                    <td>예금주</td>
                    <td>
                        <CustomInputText placeholder="[필수]"
                                         value={items.bankHolderName}
                                         onChanged={onChangeBankHolderName}
                                         color="blue"
                                         disabled={true}
                                         size="middle"/>
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
    font-weight: 700;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;
