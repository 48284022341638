import React, {useEffect} from "react";
import {useAdditionalInfo} from "../hooks/useAdditionalInfo";
import styled from "styled-components";
import {PharmacyAdditionalInfo} from "../../../../interfaces";
import {CustomInputText} from "@components/custom";

interface Props {
    onChanged: (data: PharmacyAdditionalInfo) => void;
}

export const PharmacyRegisterAdditionalInfo = ({onChanged}: Props) => {
    const {
        items,
        onChangeValue
    } = useAdditionalInfo();

    useEffect(() => {
        onChanged(items);
    }, [items]);

    return (
        <Wrapper>
            <div>
                <div>
                    <Table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>우편번호</td>
                            <td>
                                <CustomInputText placeholder="5자리 숫자 [필수]"
                                                 value={items.zipCode}
                                                 onChanged={onChangeValue}
                                                 color="blue"
                                                 size="middle"
                                                 name="zipCode"
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>주소</td>
                            <td>
                                <CustomInputText placeholder="[필수]"
                                                 value={items.address}
                                                 onChanged={onChangeValue}
                                                 color="blue"
                                                 size="middle"
                                                 name="address"
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>상세주소</td>
                            <td>
                                <CustomInputText placeholder="[필수]"
                                                 value={items.addressDetail}
                                                 onChanged={onChangeValue}
                                                 color="blue"
                                                 size="middle"
                                                 name="addressDetail"
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>전화번호</td>
                            <td>
                                <CustomInputText placeholder="- 없이 입력 [필수]"
                                                 value={items.tel}
                                                 onChanged={onChangeValue}
                                                 color="blue"
                                                 size="middle"
                                                 name="tel"
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>팩스번호</td>
                            <td>
                                <CustomInputText placeholder="- 없이 입력 [필수]"
                                                 value={items.fax}
                                                 onChanged={onChangeValue}
                                                 color="blue"
                                                 size="middle"
                                                 name="fax"
                                                 disabled={true}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }

  .image-container {
    padding-left: 50px;
  }
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tbody tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  tbody tr td:first-child {
    font-weight: 700;
    font-size: 13px;
  }

  tbody tr td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
  }

  tbody tr td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;
