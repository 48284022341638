import {atom} from "recoil";
import {recoilPersist} from "recoil-persist";
import {IOoDataOAuth} from "../../api";

const KEY = 'selectedUserState';
const {persistAtom} = recoilPersist({
    key: KEY,
    storage: localStorage
});

const selectedUserState = atom<IOoDataOAuth | undefined>({
    key: KEY,
    default: undefined,
    effects_UNSTABLE: [persistAtom],
});

export {
    selectedUserState
};
