import { IOoUnospayDelivery, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
const ExcelJS = require("exceljs");

const createModel = (e:IOoUnospayDelivery) => {
    const model = new ExcelExportModel();
        model.name = e.recipientName;
        model.address1 = e.address;
        model.address2 = e.addressDetail;
        model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
        model.zipCode = e.zipCode;
        model.memo = e.memo;
        model.subMemo = e.subMemo;
        model.quantity = "1";
        model.deliveryCost = e.id;
    return model;
}

const idoctorOrder = async (list:IOoUnospayDelivery[], providerName:string, code:string) => {
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.femininedayProvider));
    let data_array: Array<ExcelExportModel> = [];

    excelList.forEach((e, index) => {
      if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
        e.salesMenu?.forEach(salesItem => {
            if(_.startsWith(salesItem.externalCode, code)){
                let model = createModel(e);   
                    model.deliveryDate = moment(e.timestamp).format('YYYY.MM. DD');
                    model.product = `[${providerName}] ${salesItem.menuName?.split('/')[0].replace(providerName,'')} * 1개`;
                data_array.push(model);
            }
        })
      }
    });

    const excelFileExtension = ".xlsx";
    const excelFileName = `(아이닥터샵_${providerName})`;

    const arrayBufferSheet1 = await (await fetch("/assets/idoctor.xlsx")).arrayBuffer();
    const workbookSheet1 = new ExcelJS.Workbook();
    await workbookSheet1.xlsx.load(arrayBufferSheet1);
    const ws_Sheet1 = workbookSheet1.getWorksheet("Sheet1");

    data_array.map((data: ExcelExportModel, index: number) => {
        const row = ws_Sheet1.getRow(index + 2);
        [
            data.name,
            data.zipCode,
            data.address1 + " " + data.address2,
            data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
            data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
            "",
            "",
            "",
            data.product,
            "",
            data.memo,
        ].forEach((value , index) => {
            const cell = row.getCell(index + 1);
            cell.value = value;
            cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            };
        })
    });
    const excelBuffer = await workbookSheet1.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      "tlc헬스케어 발주서"+"_"+moment().format("YYYYMMDD")+ `${excelFileName}` + excelFileExtension
    );
}

export default idoctorOrder