import {IOoDiagnosis, OoDiagnosisStatus, OoMedicineDeliveryType, OoWapleReservationStatus,} from "./diagnosis";
import {isDiagnosisPaySucceed, isMedicinePaySucceed} from "./function";
import _ from "lodash";
import {getDiagnosisKakaoDeliveryState, isKaKaoDelivery,} from "@api/models";

interface DiagnosisProcessingState {
  title: string;
  color: DiagnosisStatusColor;
  background: DiagnosisBackgroundColor;
  timestamp: number;
}

export enum DiagnosisStatusColor {
  default = "#28344D",
  cancel = "#CF5D5D",
  success = "#3E7BCD",
  wait = "#3E7BCD",
  green = "#4CA555",
}

export enum DiagnosisBackgroundColor {
  success = "#EFF7FF",
  fail = "#FDEFEF",
  green = "#4CA555",
}

export function getDiagnosisStatus(diagnosis: IOoDiagnosis): DiagnosisProcessingState {
  if (isKaKaoDelivery(diagnosis)) {
    return getDiagnosisKakaoDeliveryState(diagnosis);
  }

  let statusData = {
    title: "해당 없음",
    color: DiagnosisStatusColor.default,
    background: DiagnosisBackgroundColor.success,
    timestamp: diagnosis.timestamp,
  };

  switch (diagnosis.status) {
    case OoDiagnosisStatus.request:
      statusData = {
        title: "진료 신청",
        color: DiagnosisStatusColor.default,
        background: DiagnosisBackgroundColor.success,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoDiagnosisStatus.accept:
      statusData = {
        title: "진료 접수 완료",
        color: DiagnosisStatusColor.success,
        background: DiagnosisBackgroundColor.success,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoDiagnosisStatus.diagnosisComplete:
      if (isDiagnosisPaySucceed(diagnosis)) {
        statusData = {
          title: "진료 완료",
          color: DiagnosisStatusColor.green,
          background: DiagnosisBackgroundColor.success,
          timestamp: diagnosis.timestamp,
        };
      } else {
        statusData = {
          title: "진료비 결제 실패",
          color: DiagnosisStatusColor.cancel,
          background: DiagnosisBackgroundColor.fail,
          timestamp: diagnosis.timestamp,
        };
      }
      break;
    case OoDiagnosisStatus.decidedDeliveryType:
      statusData = {
        title: "약 배송 신청",
        color: DiagnosisStatusColor.success,
        background: DiagnosisBackgroundColor.success,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoDiagnosisStatus.medicineDeliveryPaymentWait:
      statusData = {
        title: "약제비 결제 대기",
        color: DiagnosisStatusColor.wait,
        background: DiagnosisBackgroundColor.success,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoDiagnosisStatus.medicineDeliveryPaymentOk:
      if (isMedicinePaySucceed(diagnosis)) {
        statusData = {
          title: "약제비 결제 완료",
          color: DiagnosisStatusColor.default,
          background: DiagnosisBackgroundColor.success,
          timestamp: diagnosis.timestamp,
        };
      } else {
        statusData = {
          title: "약제비 결제 실패",
          color: DiagnosisStatusColor.cancel,
          background: DiagnosisBackgroundColor.fail,
          timestamp: diagnosis.timestamp,
        };
      }
      break;
    case OoDiagnosisStatus.medicineDeliveryAccept:
      statusData = {
        title: "약 준비 완료",
        color: DiagnosisStatusColor.success,
        background: DiagnosisBackgroundColor.success,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoDiagnosisStatus.medicineDeliveryStart:
      if (
          _.isEqual(
              diagnosis.deliveryType,
              OoMedicineDeliveryType.pharmacyPackage
          )
      ) {
        statusData = {
          title: "약 준비 완료",
          color: DiagnosisStatusColor.default,
          background: DiagnosisBackgroundColor.success,
          timestamp: diagnosis.timestamp,
        };
      } else {
        statusData = {
          title: "약 배송 시작",
          color: DiagnosisStatusColor.default,
          background: DiagnosisBackgroundColor.success,
          timestamp: diagnosis.timestamp,
        };
      }
      break;
    case OoDiagnosisStatus.medicineTakeOk:
      statusData = {
        title: "약 수령 완료",
        color: DiagnosisStatusColor.success,
        background: DiagnosisBackgroundColor.success,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoDiagnosisStatus.doctorCancel:
      statusData = {
        title: "병원 진료 취소",
        color: DiagnosisStatusColor.cancel,
        background: DiagnosisBackgroundColor.fail,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoDiagnosisStatus.userCancel:
      statusData = {
        title: "사용자 진료 취소",
        color: DiagnosisStatusColor.cancel,
        background: DiagnosisBackgroundColor.fail,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoDiagnosisStatus.operatorCancel:
      statusData = {
        title: "관리자 진료 취소",
        color: DiagnosisStatusColor.cancel,
        background: DiagnosisBackgroundColor.fail,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoDiagnosisStatus.pharmacistCancel:
      statusData = {
        title: "약사 취소",
        color: DiagnosisStatusColor.cancel,
        background: DiagnosisBackgroundColor.fail,
        timestamp: diagnosis.timestamp,
      };
      break;
  }

  switch (diagnosis.wapleReservationStatus) {
    case OoWapleReservationStatus.reservationCancelBySelf:
      statusData = {
        title: "예약취소(본인취소)",
        color: DiagnosisStatusColor.cancel,
        background: DiagnosisBackgroundColor.fail,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoWapleReservationStatus.reservationCancelByHospital:
      statusData = {
        title: "예약취소(병원취소)",
        color: DiagnosisStatusColor.cancel,
        background: DiagnosisBackgroundColor.fail,
        timestamp: diagnosis.timestamp,
      };
      break;
    case OoWapleReservationStatus.reservationCancelByNoShow:
      statusData = {
        title: "예약취소(미방문)",
        color: DiagnosisStatusColor.cancel,
        background: DiagnosisBackgroundColor.fail,
        timestamp: diagnosis.timestamp,
      };
      break;
  }
  return statusData;
}

export type {DiagnosisProcessingState};
