import React from "react";
import {Button} from "react-bootstrap";
import classNames from "classnames/bind";
import buttonStyles from "../../../styles/common/Button.module.scss";
import styled from "styled-components";
import {usePharmacyRegisterSettingHook} from "@components/registerForm/pharmacy/hooks/usePharmacyRegisterSettingHook";
import {
    PharmacyRegisterAdditionalInfo,
    PharmacyRegisterAlimTalk,
    PharmacyRegisterBankInfo,
    PharmacyRegisterPrimaryInfo,
    PharmacyRegisterTargetHospital,
    PharmacyRegisterTime
} from "@components/registerForm";
import {CustomCard} from "@components/custom";
import _ from "lodash";

const errorType = 'errorModal';
const errorTitle = '등록이 불가합니다.';

export const PharmacyInfoRegisterPage = () => {
    const bx = classNames.bind(buttonStyles);
    const {
        param,
        createPharmacy,
        onChangedInfo,
        onChangedAdditionalInfo,
        onChangedBankInfo,
        onChangeTargetHospital,
        onChangeWorkHours,
        onChangedAlimTalkInfo,
        addModal,
    } = usePharmacyRegisterSettingHook();

    const onClickSubmitButton = (e: any) => {
        e.preventDefault();
        let text = '';
        if (_.isEqual(param.name, "")) {
            text = "이름을 입력해주세요.";
        } else if (!_.includes(param.email, "@doctoron.kr")) {
            text = "이메일 형식에 맞지 않습니다.";
        } else if (_.lt(param.password.length, 8)) {
            text = "비밀번호 8자 이상 입력해주세요.";
        } else if (_.lt(param.zipCode.length, 5) || _.gt(param.zipCode.length, 6)) {
            text = "우편번호는 5자리 숫자로 입력해주세요.";
        } else if (_.isEqual(param.address, "")) {
            text = "주소를 입력해주세요.";
        } else if (_.isEqual(param.addressDetail, "")) {
            text = "상세 주소를 입력해주세요.";
        } else if (_.includes(param.tel, "-")) {
            text = "- 제외하고 입력해주세요.";
        } else if (_.isEqual(param.tel, "")) {
            text = "전화번호를 입력해주세요.";
        } else if (_.includes(param.alimTalkReceivers, "")) {
            text = "알림톡 수신번호를 입력하세요.";
        } else if (_.includes(param.hospitalIdList, "")) {
            text = "지정병원을 선택하세요.";
        } else if (
            _.isUndefined(param.deliveryTypeList) ||
            _.isEqual(param.deliveryTypeList!.length, 0)
        ) {
            text = "배송 옵션을 선택하세요.";
        } else if (_.isEqual(param.bank, "")) {
            text = "은행명을 입력하세요.";
        } else if (_.isEqual(param.bankAccount, "")) {
            text = "은행계좌를 입력하세요.";
        } else if (_.isEqual(param.bankHolderName, "")) {
            text = "예금주를 입력하세요.";
        } else {
            createPharmacy(param);
        }
        !_.isEmpty(text) &&
        addModal({
            type: errorType,
            data: {
                title: errorTitle,
                contents: <div>{text}</div>,
            }
        })
    };


    return (
        <Wrapper>
            <CustomCard buttonHidden={true}
                        title="약국 기본 정보 [필수]"
                        subTitle="프로필 이미지"
                        children={<PharmacyRegisterPrimaryInfo onChanged={onChangedInfo}/>}/>
            <CustomCard buttonHidden={true}
                        title="약국 추가 정보 [필수]"
                        children={<PharmacyRegisterAdditionalInfo onChanged={onChangedAdditionalInfo}/>}/>
            <CustomCard buttonHidden={true}
                        title="알람톡 수신 전화번호 [필수]"
                        children={<PharmacyRegisterAlimTalk onChanged={onChangedAlimTalkInfo}/>}/>
            <CustomCard buttonHidden={true}
                        title="지정 병원 [필수]"
                        children={<PharmacyRegisterTargetHospital onChanged={onChangeTargetHospital}/>}/>
            <CustomCard buttonHidden={true}
                        title="정산 은행 정보 [필수]"
                        children={<PharmacyRegisterBankInfo onChanged={onChangedBankInfo}/>}/>
            <CustomCard buttonHidden={true}
                        title="약국 운영시간"
                        children={<PharmacyRegisterTime onChanged={onChangeWorkHours}/>}/>
            <ButtonContainer>
                <Button type="button"
                        onClick={() =>
                            addModal({
                                type: 'twoBtnModal',
                                data: {
                                    title: "약국 등록을 취소 하시겠습니까?",
                                    contents: <div>작성하던 내용이 사라집니다.계속 하시겠습니까?</div>,
                                    buttons: [{title: "계속 작성하기"}, {title: "등록 취소하기"}]
                                }
                            })}
                        className={bx("diagnosis-cancel-button")}>
                    취소
                </Button>
                <Button type="button"
                        onClick={onClickSubmitButton}
                        className={bx("diagnosis-submit-button")}>
                    등록
                </Button>
            </ButtonContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  top: 150px;
  position: relative;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 44px;
  padding-bottom: 44px;
`;
