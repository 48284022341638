import {useRecoilValue} from "recoil";
import {ChangeEvent, useMemo, useState} from "react";
import {IOoDoctor} from "@models/doctor";
import {useCardEdit} from "@hooks/common";
import {editSelectorFamily} from "@recoils/status";
import {useDoctorFunctionHook} from "@hooks/doctor";

export const useDoctorInfoSettingHook = (data: IOoDoctor, componentName: string) => {
    const {isReset} = useCardEdit(componentName);
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const {updateDoctor} = useDoctorFunctionHook();
    const [color, setColor] = useState("white");
    const initialData = useMemo(() => {
        return {
            mobile: data.mobile,
            pictures: data.pictures,
            isCalculateAccount: data.isCalculateAccount
        }
    }, [data]);

    const [items, setItems] = useState(initialData);

    const onChangeMobile = (e: any) => setItems({...items, mobile: e.target.value});
    const onChangePicture = (value: string) => setItems({...items, pictures: [value]});
    const onChangeCheckBox = (e:ChangeEvent<HTMLInputElement>) => {
        
        if(e.target.value === '0'){
            setItems({...items, isCalculateAccount:false});
        }else{
            setItems({...items,isCalculateAccount:true});
        }
            
    };

    return {
        initialData,
        editStatus,
        color,
        setColor,
        items,
        setItems,
        onChangeMobile,
        onChangePicture,
        onChangeCheckBox,
        updateDoctor,
        isReset,
    };
};
