import {atom} from "recoil";
import {IOoDiagnosis} from "@models/diagnosis";

interface SearchProps {
    userName?: string,
    list: Array<IOoDiagnosis>,
}

export const pharmacyDiagnosisSearchListState = atom<SearchProps>({
    key: 'pharmacyDiagnosisSearchListState',
    default: {
        userName: '',
        list: [],
    },
});