import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  IOoUnospayDelivery,
  IOoUnospaySalesMenu,
  IOoUnospaySalesMenuOption,
  OoDeliveryStatus,
  OoOrderPlatformType,
  OoProvider,
} from "@api/models";
import { ExcelExportModel } from "./model";
import _ from "lodash";
import styled from "styled-components";
import { formatPrice, formatTel } from "@hooks/format";
import moment from "moment";
import React from "react";
import { hdOrder } from "./components/hdOrder";
import { dsOrder } from "./components/dsOrder";
import { ssOrder } from "./components/ssOrder";
import { pmoOrder } from "./components/pmoOrder";
import { mdslOrder } from "./components/mdslOrder";
import { empOrder } from "./components/empOrder";
import { DownloadButton } from "../DownloadButton";
import { ExcelDownloadModal } from "../ExcelDownloadModal";
const ExcelJS = require("exceljs");

interface Props {
  list: Array<IOoUnospayDelivery>;
  select: OoProvider;
  onClicked: () => void;
}

export const ExcelDownloadButton = ({ list, select, onClicked }: Props) => {
  return (
    <div style={{ marginRight: "20px", display: "flex" }}>
      <DownloadButton
        title="발주서 다운로드"
        iconSrc="/img/ic_download.png"
        onClick={onClicked}
      ></DownloadButton>
    </div>
  );
};

export default ExcelDownloadButton;
