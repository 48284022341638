import {useSuspenseHook} from "@hooks/suspense";
import {useMutation} from "react-query";
import {OoParamId} from "@api/params";
import {DoctorOnAPI} from "@api/request";
import {isRequestSucceed} from "@api/reponses";

export const useReservationVisitCompeteQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'reservation/visitComplete',
        async (id: string) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.reservation.visitComplete(new OoParamId(id));
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response;
                }
                return undefined;
                return undefined;
            } catch (e: any) {
                throw e;
            }
        }
    )
}