import React from "react";
import styled from "styled-components";
import {useRecoilState} from "recoil";
import {filterDisplayedDiagnosis, IOoDiagnosis} from "@models/diagnosis";
import {doctorSelectedFilterState} from "@recoils/operator";
import {useDisplayDiagnosisRtdbHook} from "@hooks/realtimeDatabase";
import {FilterStatus} from "../../../../Operator/DiagnosisDashBoardPage/components";

interface Props {
    list: Array<IOoDiagnosis>;
}

interface ColorProps {
    backgroundColor: string;
    isChecked: boolean;
}

enum StatusColor {
    default = "#EEEEEE",
    wait = "#FFFFFF",
    cancel = "#F6CFD3",
    success = "#6E8ED1",
}

enum TextColor {
    default = "#000000",
    wait = "#536DA3",
    success = "#FFFFFF",
    cancel = "#B43831",
}

export enum DoctorFilterStatus {
    total = 0,
    request,
    accept,
    noMedicineChoice,
    medicineDeliveryRequest,
    doctorCancel,
    requestReservation,
    diagnosisPayFail,
    diagnosisComplete,
    medicineDeliveryStart,
}

export const StatusFilterCard = ({list}: Props) => {
    const [selectFilterStatus, setSelectNumber] = useRecoilState(doctorSelectedFilterState);

    const {
        total,
        doctorRequest,
        accept,
        doctorCancel,
        requestReservation,
        diagnosisPayFail,
        doctorDiagnosisComplete,
        medicineDeliveryRequest,
    } = useDisplayDiagnosisRtdbHook({list});

    const ButtonData = [
        {
            label: "전체 진료",
            filterStatus: FilterStatus.total,
            statusColor: StatusColor.default,
            textColor: TextColor.default,
            filteredCount: filterDisplayedDiagnosis(total()).length,
        },
        {
            label: "예약 신청",
            filterStatus: FilterStatus.requestReservation,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(requestReservation()).length,
        },
        {
            label: "진료 신청",
            filterStatus: FilterStatus.request,
            statusColor: StatusColor.success,
            textColor: TextColor.success,
            filteredCount: filterDisplayedDiagnosis(doctorRequest()).length,
        },
        {
            label: "진료 접수 완료",
            filterStatus: FilterStatus.accept,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(accept()).length,
        },
        {
            label: "진료비 미결제",
            filterStatus: FilterStatus.diagnosisPayFail,
            statusColor: StatusColor.cancel,
            textColor: TextColor.cancel,
            filteredCount: filterDisplayedDiagnosis(diagnosisPayFail()).length,
        },
        {
            label: "진료 완료",
            filterStatus: FilterStatus.diagnosisComplete,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(doctorDiagnosisComplete()).length,
        },
        {
            label: "약배송 요청",
            filterStatus: FilterStatus.medicineDeliveryRequest,
            statusColor: StatusColor.success,
            textColor: TextColor.success,
            filteredCount: filterDisplayedDiagnosis(medicineDeliveryRequest()).length,
        },
        {
            label: "진료 취소",
            filterStatus: FilterStatus.doctorCancel,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(doctorCancel()).length,
        },
    ];

    const onClickFilter = (e: any) => {
        e.preventDefault();
        const selectedStatus = e.target.value;
        setSelectNumber(selectedStatus);
    };

    return (
        <Wrapper>
            {
                ButtonData.map((item, index) => (
                    <React.Fragment key={index}>
                        <LabelContainer htmlFor={`${item.filterStatus}`}
                                        backgroundColor={item.statusColor}
                                        isChecked={selectFilterStatus === item.filterStatus.toString()}>
                            <input type="radio"
                                   name="radio"
                                   hidden={true}
                                   key={index}
                                   id={`${item.filterStatus}`}
                                   value={item.filterStatus}
                                   onClick={onClickFilter}
                            />
                            <LabelDiv>
                                <StyledLabel color={item.textColor}>{item.label}</StyledLabel>
                                <StyledCount color={item.textColor}>
                                    {item.filteredCount}
                                </StyledCount>
                            </LabelDiv>
                        </LabelContainer>
                    </React.Fragment>
                ))}
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  padding-top: 4px;
  display: flex;
  height: auto;
  gap: 2px;
  justify-content: space-between;
`;

const LabelContainer = styled.label<ColorProps>`
  width: 10rem;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  background: ${(props) => props.backgroundColor};
  border: ${(props) =>
          props.isChecked ? "2px solid red" : "1px solid #C2C1BD"};
  cursor: pointer;
`;

const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledLabel = styled.b`
  font-size: 12px;
  color: ${(props) => props.color};

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const StyledCount = styled.b`
  font-size: 2rem;
  color: ${(props) => props.color};
`;
