import {IOoDiagnosis, OoDiagnosisStatus, OoWapleReservationType} from "@api/models";
import _ from "lodash";
import {formatPrice} from "@hooks/format";


interface PayStatusData {
    title: string,
    color: PayStatusColor,
}

enum PayStatusColor {
    success = "#536DA3",
    fail = "#CF5D5D",
    pause = "#949390",
    default = "#000000",
}

//3월 이전 데이터 페이크 처리
const PAYPLE_PAY_SUCCEESS_FAKE_CODE = 'ZERO PAY';
const PAYPLE_PAY_SUCCEESS_CODE = 'SPCD0000';

const PAYPLE_PAY_REFUND_CODE = 'PAYC0000';

// 진료비 결제 성공 여부
export function isDiagnosisPaySucceed(diagnosis: IOoDiagnosis): boolean {
    return !isDiagnosisRefundSucceed(diagnosis) &&
        (_.isEqual(diagnosis.diagnosisPayCode, PAYPLE_PAY_SUCCEESS_CODE)
            || _.isEqual(diagnosis.diagnosisPayCode, PAYPLE_PAY_SUCCEESS_FAKE_CODE));
}

// 진료비 결제 환불 성공 여부
export function isDiagnosisRefundSucceed(diagnosis: IOoDiagnosis): boolean {
    return _.isEqual(diagnosis.diagnosisPayCode, PAYPLE_PAY_REFUND_CODE);
}

// 약제비 결제 성공 여부
export function isMedicinePaySucceed(diagnosis: IOoDiagnosis): boolean {
    return !isMedicineRefundSucceed(diagnosis) &&
        (_.isEqual(diagnosis.medicinePayCode, PAYPLE_PAY_SUCCEESS_CODE)
            || _.isEqual(diagnosis.medicinePayCode, PAYPLE_PAY_SUCCEESS_FAKE_CODE));
}

// 약제비 결제 환불 성공 여부
export function isMedicineRefundSucceed(diagnosis: IOoDiagnosis): boolean {
    return _.isEqual(diagnosis.medicinePayCode, PAYPLE_PAY_REFUND_CODE);
}

// 병원 약제비 결제 성공 여부
export function isHospitalMedicinePaySucceed(diagnosis: IOoDiagnosis): boolean {
    return !isHospitalMedicineRefundSucceed(diagnosis) &&
        (_.isEqual(diagnosis.hospitalMedicinePayCode, PAYPLE_PAY_SUCCEESS_CODE)
            || _.isEqual(diagnosis.hospitalMedicinePayCode, PAYPLE_PAY_SUCCEESS_FAKE_CODE));
}

// 병원 약제비 결제 환불 성공 여부
export function isHospitalMedicineRefundSucceed(diagnosis: IOoDiagnosis): boolean {
    return _.isEqual(diagnosis.hospitalMedicinePayCode, PAYPLE_PAY_REFUND_CODE);
}

// 배송비 결제 성공 여부
export function isMedicineDeliveryPaySucceed(diagnosis: IOoDiagnosis): boolean {
    return !isMedicineDeliveryRefundSucceed(diagnosis) &&
        (_.isEqual(diagnosis.medicineDeliveryPayCode, PAYPLE_PAY_SUCCEESS_CODE)
            || _.isEqual(diagnosis.medicineDeliveryPayCode, PAYPLE_PAY_SUCCEESS_FAKE_CODE));
}

// 배송비 결제 환불 성공 여부
export function isMedicineDeliveryRefundSucceed(diagnosis: IOoDiagnosis): boolean {
    return _.isEqual(diagnosis.medicineDeliveryPayCode, PAYPLE_PAY_REFUND_CODE);
}

// 진료비 결제 상태 정보
export function getDiagnosisPriceStatus(diagnosis: IOoDiagnosis): PayStatusData {
    const isVisitReservation = _.isEqual(diagnosis.wapleReservationType, OoWapleReservationType.visitReservation);
    const isNoPay = diagnosis.status < OoDiagnosisStatus.diagnosisComplete || diagnosis.status > OoDiagnosisStatus.medicineTakeOk;
    const isPayFail = !isDiagnosisPaySucceed(diagnosis);
    const isZeroPay = _.isEqual(diagnosis.price, 0);
    const isRefundSuccess = isDiagnosisRefundSucceed(diagnosis);
    return getDiagnosisPayStatus(diagnosis.price || 0, isNoPay, isPayFail, isZeroPay, isRefundSuccess, '진료비', isVisitReservation);
}

// 약제비 결제 상태 정보
export function getMedicinePayStatus(diagnosis: IOoDiagnosis): PayStatusData {
    const isNoPay = diagnosis.status < OoDiagnosisStatus.medicineDeliveryPaymentOk;
    const isPayFail = !isMedicinePaySucceed(diagnosis);
    const isZeroPay = _.isEqual(diagnosis.medicinePrice, 0);
    const isRefundSuccess = isMedicineRefundSucceed(diagnosis);
    const isHospitalPaySuccess = isHospitalMedicinePaySucceed(diagnosis);
    const isHospitalRefundSuccess = isHospitalMedicineRefundSucceed(diagnosis);
    return getMedicineStatus(diagnosis.medicinePrice || 0, isNoPay, isPayFail, isZeroPay, isRefundSuccess, '약제비', diagnosis.hospitalMedicinePrice || 0, isHospitalPaySuccess, isHospitalRefundSuccess);
}

// 배송비 결제 상태 정보
export function getMedicineDeliveryPayStatus(diagnosis: IOoDiagnosis): PayStatusData {
    const isNoPay = diagnosis.status < OoDiagnosisStatus.medicineDeliveryPaymentOk;
    const isPayFail = !isMedicineDeliveryPaySucceed(diagnosis);
    const isZeroPay = _.isEqual(diagnosis.deliveryPrice, 0);
    const isRefundSuccess = isMedicineDeliveryRefundSucceed(diagnosis);
    return getPayStatus(diagnosis.deliveryPrice || 0, isNoPay, isPayFail, isZeroPay, isRefundSuccess, '배송비');
}

export function getDiagnosisPayStatus(price: number,
                                      isNoPay: boolean,
                                      isPayFail: boolean,
                                      isZeroPay: boolean,
                                      isRefundSuccess: boolean,
                                      payString: string,
                                      isVisitReservation: boolean): PayStatusData {
    const isReservation = !_.isUndefined(isVisitReservation) && isVisitReservation;

    if (isReservation) {
        if (isNoPay) {
            return {
                title: `${formatPrice(price)}원`,
                color: PayStatusColor.pause
            };
        } else {
            if (isPayFail && !isNoPay) {
                return {
                    title: `${formatPrice(price)}원 결제실패`,
                    color: PayStatusColor.fail
                };
            }

            if (isRefundSuccess) {
                return {
                    title: `${formatPrice(price)}원 환불완료`,
                    color: PayStatusColor.success
                };
            }

            if (!isRefundSuccess && !isPayFail) {
                return {
                    title: `${formatPrice(price)}원 환불실패`,
                    color: PayStatusColor.fail
                };
            }

            return {
                title: isZeroPay ? `${formatPrice(price)}원` : `${formatPrice(price)}원 결제완료`,
                color: PayStatusColor.success
            };
        }
    } else {
        if (isNoPay) {
            return {
                title: `${formatPrice(price)}원`,
                color: PayStatusColor.pause
            };
        } else {
            if (isPayFail) {
                return {
                    title: `${formatPrice(price)}원 결제실패`,
                    color: PayStatusColor.fail
                };
            }

            if (isRefundSuccess) {
                return {
                    title: `${formatPrice(price)}원 환불완료`,
                    color: PayStatusColor.success
                };
            }

            if (!isRefundSuccess && isPayFail) {
                return {
                    title: `${formatPrice(price)}원 환불실패`,
                    color: PayStatusColor.fail
                };
            }

            return {
                title: isZeroPay ? `${formatPrice(price)}원` : `${formatPrice(price)}원 결제완료`,
                color: PayStatusColor.success
            };
        }
    }
}

export function getPayStatus(price: number,
                             isNoPrice: boolean,
                             isPayFail: boolean,
                             isZeroPay: boolean,
                             isRefundSuccess: boolean,
                             payString: string,
                             isVisitReservation?: boolean): PayStatusData {

    const isReservation = !_.isUndefined(isVisitReservation) && isVisitReservation;
    if (isReservation && isNoPrice) {
        return {
            title: `${formatPrice(price)}원`,
            color: PayStatusColor.pause
        };
    } else {
        if (isZeroPay) {
            return {
                title: `${formatPrice(price)}원`,
                color: PayStatusColor.success
            };
        } else {
            return {
                title: `${formatPrice(price)}원 결제완료`,
                color: PayStatusColor.success
            };
        }
    }

    if (isZeroPay && isPayFail) {
        if (isNoPrice) {
            return {
                title: `${formatPrice(price)}원`,
                color: PayStatusColor.pause
            };
        } else {
            return {
                title: `${formatPrice(price)}원`,
                color: PayStatusColor.pause
            };
        }
    } else if (!isZeroPay) {
        if (isPayFail) {
            if (isRefundSuccess) {
                return {
                    title: `${formatPrice(price)}원 환불완료`,
                    color: PayStatusColor.success
                };
            } else if (!isRefundSuccess) {
                return {
                    title: `${formatPrice(price)}원 환불실패`,
                    color: PayStatusColor.fail
                };
            } else {
                return {
                    title: `${formatPrice(price)}원 결제실패`,
                    color: PayStatusColor.fail
                };
            }
        } else if (!isPayFail) {
            return {
                title: `${formatPrice(price)}원 결제완료`,
                color: PayStatusColor.success
            };
        }
    } else {
        return {
            title: `${formatPrice(price)}원`,
            color: PayStatusColor.success
        };
    }
    return {
        title: `${payString} 없음`,
        color: PayStatusColor.pause
    };
}

export function getMedicineStatus(price: number,
                                  isNoPrice: boolean,
                                  isPayFail: boolean,
                                  isZeroPay: boolean,
                                  isRefundSuccess: boolean,
                                  payString: string,
                                  hospitalPrice: number,
                                  isHospitalSuccess: boolean,
                                  isHospitalRefundSuccess: boolean,): PayStatusData {

    if (isNoPrice) {
        return {
            title: `${payString} 없음`,
            color: PayStatusColor.pause
        };
    }


    if (isZeroPay) {
        return {
            title: `${payString} 없음`,
            color: PayStatusColor.pause
        };
    } else {
        if (isPayFail) {
            return {
                title: `${formatPrice(price)}원 결제실패`,
                color: PayStatusColor.fail
            };
        }

        if (isPayFail && isNoPrice) {
            return {
                title: `-`,
                color: PayStatusColor.pause
            };
        }

        if (isPayFail && !isNoPrice) {
            return {
                title: `${formatPrice(price)}원 결제실패`,
                color: PayStatusColor.fail
            };
        }

        return {
            title: `${formatPrice(price)}원 결제완료`,
            color: PayStatusColor.success
        };
    }
}


export type {PayStatusData};