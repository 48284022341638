import {OoUnosPaymentType} from "../unospay";

export function unospayPaymentTypeFilter(type: number): string {
    switch (type) {
        case OoUnosPaymentType.cashReceipt:
            return '현금영수증';
        case OoUnosPaymentType.cash:
            return '현금';
        case OoUnosPaymentType.payco:
            return '페이코';
        case OoUnosPaymentType.kakaopay:
            return '카카오페이';
        case OoUnosPaymentType.alipay:
            return '알리페이';
        case OoUnosPaymentType.wechatpay:
            return '위챗페이';
        case OoUnosPaymentType.zeropay:
            return '제로페이';
        case OoUnosPaymentType.ssgpay:
            return 'SSG페이';
        case OoUnosPaymentType.Lpay:
            return 'LPAY';
        case OoUnosPaymentType.bcQR:
            return 'BCQR';
        case OoUnosPaymentType.unionQR:
            return 'UNIONQR';
        case OoUnosPaymentType.appCard:
            return '앱카드';
        case OoUnosPaymentType.naverPay:
            return '네이버페이';
        default:
            return '카드';
    }
}