import {useRecoilValue} from "recoil";
import {
    dailyDiagnosisListState,
    dailyDiagnosisSelectedState,
    originDiagnosisRtdbState,
    searchListState,
    selectedUserState
} from "../../../../recoils";
import {UseDiagnosisRtdbHook} from "../../../../hooks";
import {useDisplayHook} from "@hooks/common";
import {useMemo} from "react";

export const useDashBoardSettingHook = () => {
    const target = 'operator';

    const {displayList, updateDisplay} = useDisplayHook(target);

    const dailyDiagnosisList = useRecoilValue(dailyDiagnosisListState);
    const originList = useRecoilValue(originDiagnosisRtdbState);
    const searchList = useRecoilValue(searchListState(target));

    const selectedUser = useRecoilValue(selectedUserState);

    const selectedUserName = useMemo(() => {
        return selectedUser?.user.name ?? '';
    }, [selectedUser?.user.name]);

    const dailyDiagnosisSelectedDate = useRecoilValue(dailyDiagnosisSelectedState(target));

    const {connect, disconnect} = UseDiagnosisRtdbHook();

    return {
        target,
        selectedUserName,
        dailyDiagnosisSelectedDate,
        dailyDiagnosisList,
        displayList,
        originList,
        searchList,
        updateDisplay,
        connect,
        disconnect,
    }

}