import { useSuspenseHook } from "../../hooks";
import { useMutation } from "react-query";
import { DoctorOnAPI, isRequestSucceed, OoParamId } from "../../api";
import { toast } from "react-toastify";

export const useResendKakaoAlimTalkQuery = () => {
  const { setLoading, setStopLoading } = useSuspenseHook();
  return useMutation("unospay/resendKakaoAlimTalk", async (id: string) => {
    try {
      setLoading();
      const response = await DoctorOnAPI.shared.unospay.resendKakaoAlimtalk(
        new OoParamId(id)
      );
      setStopLoading();
      if (isRequestSucceed(response)) {
        toast.success("알림톡을 전송했습니다.");
        return response;
      }
      return undefined;
    } catch (e: any) {
      throw e;
    }
  });
};
