import _ from "lodash";
import React from "react";
import styled from "styled-components";
import {useReservationSettingHook} from "@components/infoForm";
import {CustomCard} from "@components/custom";
import {ReservationSchedule} from "@components/infoForm/Doctor/ReservationSchdule";

export const DoctorReservation = () => {
    const {
        doctorInfo,
        doctorData,
    } = useReservationSettingHook();

    return (
        <Wrapper>
            {
                !_.isUndefined(doctorInfo) && !_.isUndefined(doctorData) &&
                <>
                    <Title>
                        <div>{doctorInfo.name} 원장님 예약 스케쥴 설정</div>
                    </Title>
                    <CustomCard buttonHidden={false}
                                title="예약 스케쥴 설정"
                                subTitle="제공하는 진료 타입"
                                children={<ReservationSchedule doctorInfo={doctorData}
                                                               componentName="doctorReservationSchedule"/>}
                                component="doctorReservationSchedule"
                    />
                </>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 150px;
`;

const Title = styled.div`
  padding-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 1024px;

  & > div:last-child {
    margin-left: 10px;
  }
`;