import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { editSelectorFamily } from "@recoils/status";
import { IOoUnospayDelivery } from "@api/models";
import { CustomInputText, CustomTextArea } from "@components/custom";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useUnospayFunctionHook } from "@hooks/unospay";
import { formatTel } from "@hooks/format";
import classNames from "classnames/bind";
import InputStyles from "../../../../../styles/common/Input.module.scss";
import buttonStyles from "../../../../../styles/common/Button.module.scss";
import { textAlign } from "@mui/system";

interface Props {
  componentName: string;
  data: IOoUnospayDelivery;
}

export interface DeliverDetailInfo {
  id: string;
  recipientName: string;
  mobile: string;
  address: string;
  addressDetail: string;
  memo: string;
  subMemo: string;
  zipCode: string;
}

export const DeliveryDetailInfo = ({ componentName, data }: Props) => {
  const editStatus = useRecoilValue(editSelectorFamily(componentName));
  const [color, setColor] = useState("white");
  const { updateDeliveryInfo } = useUnospayFunctionHook(data.id || "");
  const [items, setItems] = useState<DeliverDetailInfo>({
    id: data.id || "",
    recipientName: _.isEmpty(data.recipientName) ? "-" : data.recipientName,
    mobile:
      _.isEmpty(data.mobile) || _.isUndefined(data.mobile) ? "-" : data.mobile,
    address: _.isEmpty(data.address) ? "-" : `${data.address}`,
    addressDetail: _.isEmpty(data.addressDetail) ? "-" : data.addressDetail,
    memo: _.isEmpty(data.memo) || _.isUndefined(data.memo) ? "-" : data.memo,
    subMemo:
      _.isEmpty(data.subMemo) || _.isUndefined(data.subMemo)
        ? "-"
        : data.subMemo,
    zipCode:
      _.isEmpty(data.zipCode) || _.isUndefined(data.zipCode)
        ? "-"
        : data.zipCode,
  });

  const onChangeValue = (e: any) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    setItems({ ...items, [name]: value });
  };

  const [tooltip, setTooltip] = useState({
    text: "",
    left: 0,
    top: 0,
    bottom: 0,
    isOpen: false,
  });

  const handleMouseEnter = (e: any, cellData: any) => {
    const cellRect = e.target.getBoundingClientRect();
    const left = cellRect.left - 200;
    const top = cellRect.top + 55;
    const bottom = cellRect.bottom;

    if (cellData.length > 20) {
      setTooltip({ text: cellData, left, top, bottom, isOpen: true });
    }
  };

  const handleMouseLeave = () => {
    setTooltip({ text: "", left: 0, top: 0, bottom: 0, isOpen: false });
  };

  useEffect(() => {
    setItems({
      id: data.id || "-",
      recipientName: _.isEmpty(data.recipientName) ? "-" : data.recipientName,
      mobile:
        _.isEmpty(data.mobile) || _.isUndefined(data.mobile)
          ? "-"
          : data.mobile,
      address: _.isEmpty(data.address) ? "-" : data.address,
      addressDetail: _.isEmpty(data.addressDetail) ? "-" : data.addressDetail,
      memo: _.isEmpty(data.memo) || _.isUndefined(data.memo) ? "-" : data.memo,
      subMemo:
        _.isEmpty(data.subMemo) || _.isUndefined(data.subMemo)
          ? "-"
          : data.subMemo,
      zipCode:
        _.isEmpty(data.zipCode) || _.isUndefined(data.zipCode)
          ? "-"
          : data.zipCode,
    });
  }, [data]);

  useEffect(() => {
    if (editStatus.isEdit) {
      setColor("blue");
      setItems({
        id: data.id || "",
        recipientName: _.isEmpty(data.recipientName) ? "" : data.recipientName,
        mobile:
          _.isEmpty(data.mobile) || _.isUndefined(data.mobile)
            ? ""
            : data.mobile,
        address: _.isEmpty(data.address) ? "" : data.address,
        addressDetail: _.isEmpty(data.addressDetail) ? "" : data.addressDetail,
        memo: _.isEmpty(data.memo) || _.isUndefined(data.memo) ? "" : data.memo,
        subMemo:
          _.isEmpty(data.subMemo) || _.isUndefined(data.subMemo)
            ? ""
            : data.subMemo,
        zipCode:
          _.isEmpty(data.zipCode) || _.isUndefined(data.zipCode)
            ? ""
            : data.zipCode,
      });
    } else {
      setColor("white");
      setItems({
        id: data.id || "",
        recipientName: _.isEmpty(data.recipientName) ? "-" : data.recipientName,
        mobile:
          _.isEmpty(data.mobile) || _.isUndefined(data.mobile)
            ? "-"
            : data.mobile,
        address: _.isEmpty(data.address) ? "-" : data.address,
        addressDetail: _.isEmpty(data.addressDetail) ? "-" : data.addressDetail,
        memo:
          _.isEmpty(data.memo) || _.isUndefined(data.memo) ? "-" : data.memo,
        subMemo:
          _.isEmpty(data.subMemo) || _.isUndefined(data.subMemo)
            ? "-"
            : data.subMemo,
        zipCode:
          _.isEmpty(data.zipCode) || _.isUndefined(data.zipCode)
            ? "-"
            : data.zipCode,
      });
    }
  }, [editStatus.isEdit]);

  useEffect(() => {
    if (!editStatus.isSubmit) return;
    updateDeliveryInfo(items);
  }, [editStatus.isSubmit]);

  const ref = useRef<HTMLTextAreaElement>(null);
  const ix = classNames.bind(InputStyles);
  const bx = classNames.bind(buttonStyles);

  const subMemoToStr = (subMemo: string, memo: string) => {
    if (subMemo.length > 1) {
      return subMemo;
    }

    if (_.isEqual(subMemo, "2")) {
      return "자유출입가능";
    } else if (_.isEqual(subMemo, "6")) {
      return memo;
    } else if (_.isEqual(subMemo, "7")) {
      return "무인택배함";
    } else {
      return "-";
    }
  };

  // useEffect(() => {
  //     if (ref) {
  //         autosize(ref.current as HTMLTextAreaElement);
  //     }
  // }, []);

  return (
    <div>
      <Table visible={editStatus.isEdit}>
        <thead></thead>
        <tbody>
          <tr>
            <td>수령인</td>
            <td>
              <CustomInputText
                size="xMiddle"
                // color={color}
                color={"white"}
                name="recipientName"
                value={items.recipientName}
                onChanged={onChangeValue}
                disabled={editStatus.isEdit}
              />
            </td>
            <td>전화번호</td>
            <td>
              <CustomInputText
                size="xMiddle"
                // color={color}
                color={"white"}
                name="mobile"
                value={`${
                  editStatus.isEdit ? items.mobile : formatTel(items.mobile)
                }`}
                onChanged={onChangeValue}
                disabled={editStatus.isEdit}
              />
            </td>
          </tr>
          <tr>
            <td>우편번호</td>
            <td>
              <CustomInputText
                size="xMiddle"
                // color={color}
                color="white"
                name="zipCode"
                value={items.zipCode}
                onChanged={onChangeValue}
                disabled={editStatus.isEdit}
              />
            </td>
            <td>주소</td>
            <td colSpan={3}>
              {/*<span hidden={editStatus.isEdit}>{_.isEmpty(data.zipCode) ? "" : `(${data.zipCode})`}</span>*/}
              <CustomInputText
                size="large"
                // color={color}
                color="white"
                name="address"
                value={items.address}
                onChanged={onChangeValue}
                disabled={editStatus.isEdit}
              />
            </td>
          </tr>
          <tr>
            <td>상세주소</td>
            <td>
              <CustomInputText
                size="xMiddle"
                color="white"
                name="addressDetail"
                value={items.addressDetail}
                onChanged={onChangeValue}
                // disabled={editStatus.isEdit} />
                disabled={false}
              />
            </td>
            <td>배송 요청사항</td>
            <td
              onMouseEnter={(e) => handleMouseEnter(e, items.memo)}
              onMouseLeave={handleMouseLeave}
            >
              <CustomInputText
                size="transportMemo"
                color="white"
                // color={color}
                name="memo"
                value={items.memo}
                onChanged={onChangeValue}
                disabled={editStatus.isEdit}
              />
            </td>
            <td>
              공동현관
              <br />
              출입방법
            </td>
            <td>
              {!_.isUndefined(items.subMemo) ? (
                <CustomInputText
                  size="xMiddle"
                  color="white"
                  // color={color}
                  name="subMemo"
                  value={subMemoToStr(items.subMemo, items.memo)}
                  onChanged={onChangeValue}
                  disabled={editStatus.isEdit}
                />
              ) : (
                ""
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      {/* <div>
                <button type="button"
                    onClick={() => {}}
                    hidden={false}
                    className={bx("blue-button")}>
                    테스트
                </button>
            </div> */}
      {tooltip.isOpen && (
        <div
          className={ix("tooltip")}
          style={{
            left: tooltip.left,
            top: tooltip.top,
            bottom: tooltip.bottom,
          }}
          onMouseEnter={() => setTooltip({ ...tooltip, isOpen: true })}
          onMouseLeave={() => setTooltip({ ...tooltip, isOpen: false })}
        >
          {tooltip.text}
        </div>
      )}
    </div>
  );
};

const Table = styled.table<{ visible: boolean }>`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: 100%;
  height: auto;
  font-size: 13px;
  margin-top: 8px;
  white-space: pre-wrap;

  & tr {
    border-bottom: 1px solid #c8d6f8;
  }

  & tr td:nth-child(2n-1) {
    background: #f4f6fb;
    text-align: center;
    height: 48px;
    font-weight: 700;
    width: 100px;
  }

  & tr td:nth-child(2n) {
    & > input {
      margin-left: 10px;
    }

    & > textarea {
      margin: 8px 0 8px 8px;
      display: block;
    }
  }

  & tr:nth-child(2) td:nth-child(2) {
    & > span {
      font-size: 12px;
      margin-left: 10px;
    }
  }
`;
