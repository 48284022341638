import {Modal, modalState} from "@recoils/modal";
import {useRecoilState} from "recoil";
import {useCallback} from "react";

interface UseModal {
    addModal: ({type, data}: Modal) => void,
    removeCurrentModal: () => void,
}

export const useModalHook = (): UseModal => {
    const [modalList, setModalList] = useRecoilState(modalState);
    const addModal = useCallback(({type, data}: Modal) => {
        setModalList((prev) => {
            const newModalList = [...prev];
            newModalList.push({type, data});
            return newModalList;
        });
    }, [modalList]);

    const removeCurrentModal = useCallback(() => {
        setModalList((prev) => {
            const newModalList = [...prev];
            newModalList.pop();
            return newModalList;
        })
    }, [modalList]);

    return {
        addModal,
        removeCurrentModal,
    }
}