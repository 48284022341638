import {DoctorBankInfo} from "interfaces";
import React, {useEffect} from "react";
import styled from "styled-components";
import {CustomInputText} from "@components/custom";
import {useBankInfoSetting} from "@components/registerForm";
import { OoParamDoctorCreate } from "@api/params";

interface Props {
    onChanged: (data: DoctorBankInfo) => void;
    param:OoParamDoctorCreate
}

export const DoctorRegisterBankInfo = ({onChanged,param}: Props) => {
    const {items, onChangeBank, onChangeBankAccount, onChangeBankHolder} = useBankInfoSetting();

    useEffect(() => {
        onChanged(items);
    }, [items]);

    return (
        <Wrapper>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>은행명</td>
                    <td>
                        <CustomInputText placeholder="[필수]"
                                         value={param.bank ? param.bank : ''}
                                         onChanged={onChangeBank}
                                         color="blue"
                                         size="middle"
                                         disabled={true}/>
                    </td>
                </tr>
                <tr>
                    <td>계좌번호</td>
                    <td>
                        <CustomInputText placeholder="- 없이 입력 [필수]"
                                         value={param.bankAccount ? param.bankAccount : ''}
                                         onChanged={onChangeBankAccount}
                                         color="blue"
                                         size="middle"
                                         disabled={true}/>
                    </td>
                </tr>
                <tr>
                    <td>예금주</td>
                    <td>
                        <CustomInputText placeholder="[필수]"
                                         value={param.bankHolderName ? param.bankHolderName : ''}
                                         onChanged={onChangeBankHolder}
                                         color="blue"
                                         size="middle"
                                         disabled={true}/>
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    );
};


const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;


const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  & tbody tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  & tbody tr td:first-child {
    font-weight: 700;
    font-size: 13px;
  }

  & tbody td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
  }

  & tbody td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;
