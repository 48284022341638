import React, { useEffect, useState } from "react";
import {
  IOoDiagnosis,
  OoDiagnosisStatus,
  OoParamAddMedicinePrice,
} from "../../../../../../api";
import styled from "styled-components";
import { CustomTextArea } from "@components/custom";
import _ from "lodash";

interface Props {
  diagnosisData: IOoDiagnosis;
  prescriptionData: OoParamAddMedicinePrice;
  onChanged: (memo: string) => void;
}

export const PharmacyMemo = ({
  diagnosisData,
  onChanged,
  prescriptionData,
}: Props) => {
  const defaultMemo = _.isEmpty(diagnosisData.cancelComment || "")
    ? prescriptionData.memo || ""
    : _.isEqual(diagnosisData.status, OoDiagnosisStatus.pharmacistCancel)
    ? diagnosisData.cancelComment || ""
    : "";

  const [memo, setMemo] = useState<string>("");
  const onChangeMemo = (e: any) => setMemo(e.target.value);

  useEffect(() => {
    onChanged(memo);
  }, [memo]);

  useEffect(() => {
    setMemo(prescriptionData.memo || "");
  }, [diagnosisData.id, prescriptionData]);

  return (
    <Wrapper>
      <div>약사 메모</div>
      {_.gte(
        diagnosisData.status,
        OoDiagnosisStatus.medicineDeliveryPaymentWait
      ) ? (
        <CustomTextArea
          size="large"
          color="grey"
          value={defaultMemo}
          onChanged={onChangeMemo}
          disabled={false}
        />
      ) : (
        <CustomTextArea
          size="large"
          color="blue"
          value={memo ?? ""}
          onChanged={onChangeMemo}
          placeholder="대체약 조제시 대체 내역을 입력해주세요. (100자 이내)"
          disabled={true}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 50px;
  font-weight: 700;
  font-size: 16px;

  & > div:first-child {
    padding-bottom: 8px;
  }
`;
