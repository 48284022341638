import styled from "styled-components";

interface Props {
    title: string,
    checked: boolean,
    name?: string,
    onChange?: (e: any) => void,
    disabled?: boolean,
    value?: string | number,
}

export const CustomCheckBox = ({title, checked, name, value, onChange, disabled}: Props) => {
    return (
        <Wrapper id={title}>
            <input type="checkbox"
                   name={name}
                   checked={checked}
                   onChange={onChange}
                   disabled={disabled}
                   value={value}
            />
            <span>{title}</span>
        </Wrapper>
    )
}

const Wrapper = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;

  & > input {
    margin-right: 10px;
  }
`;