import styled from "styled-components";

interface SelectedMenuProps {
  menuCode: string;
  menuTitle: string;
}

enum CardColor {
  gray = "#EEE",
  navy = "#28344D",
}

export const SelectedMenuRow = ({ menuCode, menuTitle }: SelectedMenuProps) => {
  return (
    <Wrapper>
      <MenuCodeCard>{menuCode}</MenuCodeCard>
      <MenuTitle>{menuTitle}</MenuTitle>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

const MenuCodeCard = styled.div`
  width: 57px;
  height: 21px;
  background: ${CardColor.gray};
  color: ${CardColor.navy};
  border-radius: 2px;
  border: none;
  font-weight: 400;
  font-size: 12px;
  justify-content: center;
  display: flex;
  padding: 2px 8px;
`;

const MenuTitle = styled.div`
  color: #000;
  font-size: 12px;
  font-weight: 400;
  padding-left: 6px;
  white-space: nowrap;
`;
