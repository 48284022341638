import {useState} from "react";
import {OoParamHospitalCreate} from "@api/params";
import {HospitalBankInfo, HospitalBasicInfo, HospitalPicture,} from "../../../../interfaces";
import {useHospitalFunctionHook} from "@hooks/hospital";

export const useDoctorInfoRegisterSettingHook = () => {
  const {createHospital} = useHospitalFunctionHook();
  const [param, setParam] = useState<OoParamHospitalCreate>(
      new OoParamHospitalCreate()
  );

  const onChangedInfo = (data: HospitalBasicInfo) => {
    param.createInfo(data);
    setParam(param);
  };

  const onChangedBankInfo = (data: HospitalBankInfo) => {
    param.createBankInfo(data);
    setParam(param);
  };

  const onChangedLogo = (data: HospitalPicture) => {
    param.createPictures(data);
    setParam(param);
  };

  return {
    param,
    createHospital,
    onChangedBankInfo,
    onChangedInfo,
    onChangedLogo,
  };
};
