import React from "react";
import {useModalHook} from "@hooks/modal";
import {useNavigate} from "react-router-dom";
import {BlueButton, ButtonContainer, Card, ContentsContainer, RedButton, TitleContainer, Wrapper} from "../style";

export const TwoButtonModal = ({
                                   data: {
                                       title = '',
                                       contents = '',
                                       buttons = [
                                           {title: "등록", callback: (e: any) => false},
                                           {title: "취소", callback: (e: any) => false}],
                                       goBack = true,
                                   }
                               }) => {
    const {removeCurrentModal} = useModalHook();
    const navi = useNavigate();
    return (
        <Wrapper>
            <Card>
                {
                    title.length > 0 &&
                    <TitleContainer>
                        {title}
                    </TitleContainer>
                }
                <ContentsContainer>
                    {contents}
                </ContentsContainer>
                <ButtonContainer>
                    <RedButton type="button" onClick={() => removeCurrentModal()}>{buttons[0].title}</RedButton>
                    {
                        goBack ?
                            <BlueButton type="button" onClick={() => {
                                removeCurrentModal();
                                navi(-1);
                            }}>
                                {buttons[1].title}
                            </BlueButton>
                            :
                            <BlueButton type="button" onClick={() => {
                                removeCurrentModal();
                                buttons[1].callback(true);
                            }}>
                                {buttons[1].title}
                            </BlueButton>
                    }
                </ButtonContainer>
            </Card>
        </Wrapper>
    )
}

