import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  IOoUnospayDelivery,
  IOoUnospaySalesMenu,
  IOoUnospaySalesMenuOption,
  OoDeliveryStatus,
  OoOrderPlatformType,
  OoProvider,
} from "@api/models";
import { ExcelExportModel } from "../model";
import _ from "lodash";
import styled from "styled-components";
import { formatPrice, formatTel } from "@hooks/format";
import moment from "moment";
import React from "react";

const ExcelJS = require("exceljs");

interface Props {
  list: Array<IOoUnospayDelivery>;
  select: OoProvider;
}
const mdslNameCost = [
  {
    code: "OF13_01",
    optionCode: "101",
    subName: "당뇨케어 식단 (1주)",
    cost: "73000",
  },
  {
    code: "OF13_03",
    optionCode: "101",
    subName: "암케어 식단 (1주)",
    cost: "73000",
  },
  {
    code: "OF13_05",
    optionCode: "101",
    subName: "신장튼튼 식단 (1주)",
    cost: "73000",
  },
  {
    code: "OF13_02",
    optionCode: "101",
    subName: "신장케어 식단 (1주)",
    cost: "73000",
  },
  {
    code: "OF13_04",
    optionCode: "101",
    subName: "저당관리 식단 (1주)",
    cost: "73000",
  },
  {
    code: "OF13_01",
    optionCode: "102",
    subName: "당뇨케어 식단 (2주)",
    cost: "146000",
  },
  {
    code: "OF13_03",
    optionCode: "102",
    subName: "암케어 식단 (2주)",
    cost: "146000",
  },
  {
    code: "OF13_05",
    optionCode: "102",
    subName: "신장튼튼 식단 (2주)",
    cost: "146000",
  },
  {
    code: "OF13_02",
    optionCode: "102",
    subName: "신장케어 식단 (2주)",
    cost: "146000",
  },
  {
    code: "OF13_04",
    optionCode: "102",
    subName: "저당관리 식단 (2주)",
    cost: "146000",
  },
  {
    code: "OF13_06",
    optionCode: "101",
    subName: "DASH 식단 (1주)",
    cost: "73000",
  },
  {
    code: "OF13_07",
    optionCode: "101",
    subName: "예비부모식 (1주)",
    cost: "73000",
  },
  {
    code: "OF13_08",
    optionCode: "101",
    subName: "지중해식 (1주)",
    cost: "73000",
  },
  {
    code: "OF13_09",
    optionCode: "",
    subName: "한국형 지중해식",
    cost: "34000",
  },
  {
    code: "OF13_10",
    optionCode: "",
    subName: "프로틴20",
    cost: "34000",
  },
];

function addDays(date: Date, days: number) {
  const clone = new Date(date);
  clone.setDate(date.getDate() + days);
  return clone;
}

function getDeliveryCount(option: IOoUnospaySalesMenu) {
  let retCount = 1;
  if (
    _.isEqual(option.externalCode, "OF04_01") ||
    _.isEqual(option.externalCode, "OF04_11") ||
    _.isEqual(option.externalCode, "OF04_02") ||
    _.isEqual(option.externalCode, "OF04_03") ||
    _.isEqual(option.externalCode, "OF04_04") ||
    _.isEqual(option.externalCode, "OF04_05") ||
    _.isEqual(option.externalCode, "OF04_10") ||
    _.isEqual(option.externalCode, "OF04_09")
  ) {
    switch (_.first(option.salesOption)?.externalCode) {
      case "301":
      case "302":
      case "303":
      case "304":
        retCount = 1;
        break;
      case "101":
        retCount = 2;
        break;
      case "102":
      case "04":
      case "02":
        retCount = 6;
        break;
      case "103":
      case "05":
      case "03":
        retCount = 12;
        break;
    }
  } else if (
    _.isEqual(option.externalCode, "OF04_06") ||
    _.isEqual(option.externalCode, "OF04_07") ||
    _.isEqual(option.externalCode, "OF04_08") ||
    _.isEqual(option.externalCode, "OF05_01")
  ) {
    retCount = 6;
  } else if (_.isEqual(option.externalCode, "OF05_02")) {
    retCount = 12;
  } else if (
    _.isEqual(option.externalCode, "OF05_03") ||
    _.isEqual(option.externalCode, "OF05_04") ||
    _.isEqual(option.externalCode, "OF05_07") ||
    _.isEqual(option.externalCode, "OF05_08") ||
    _.isEqual(option.externalCode, "OF05_09")
  ) {
    retCount = 18;
  } else if (_.isEqual(option.externalCode, "OF05_10")) {
    retCount = 2;
  } else if (
    _.isEqual(option.externalCode, "OF05_11") ||
    _.isEqual(option.externalCode, "OF05_12") ||
    _.isEqual(option.externalCode, "OF05_13")
  ) {
    retCount = 4;
  } else if (_.isEqual(option.externalCode, "OF05_06")) {
    retCount = 24;
  } else if (
    _.isEqual(option.externalCode, "OF05_14") ||
    _.isEqual(option.externalCode, "OF05_15")
  ) {
    retCount = 21;
  }
  return retCount;
}

function getOptionDeliveryCount(optionExternalCode: string) {
  let retCount = 1;
  if (_.isEqual(optionExternalCode, "OF04_20")) {
    retCount = 1;
  }
  return retCount;
}

function convTimestampDateFormat(timestamp: number) {
  var dateFormat = new Date(timestamp);
  return (
    dateFormat.getFullYear() +
    "-" +
    (dateFormat.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    dateFormat.getDate().toString().padStart(2, "0")
  );
}

function getNewCareInfo(optionCode: string | undefined) {
  if (
    _.isEqual(optionCode, "01") ||
    _.isEqual(optionCode, "02") ||
    _.isEqual(optionCode, "03") ||
    _.isEqual(optionCode, "04") ||
    _.isEqual(optionCode, "05")
  ) {
    return "0";
  } else {
    return "";
  }
}

function getMenuPrice(exCode: string) {
  let ret = "";
  switch (exCode) {
    case "OF04_01": // 당뇨
    case "OF04_11": // 저당
    case "OF04_02": // 암환자
      ret = "7,700";
      break;
    case "OF04_03": // 퇴원후 회복식 (장수마을)
    case "OF04_10": // 암환자 회복식
      ret = "7,300";
      break;
    case "OF04_05": // 샐러드 식단
      ret = "6,300";
      break;
    case "OF04_09":
      ret = "7,700";
      break;
    case "OF04_07":
      ret = "4,300";
      break;
    case "OF04_06":
      ret = "3,950";
      break;
    case "OF04_08":
      ret = "5,250";
      break;
  }
  return ret;
}

function exCodeNumToStr(num: number) {
  let retStr = "";
  switch (num) {
    case 1:
      retStr = "OF04";
      break;
    case 2:
      retStr = "OF10";
      break;
    case 3:
      retStr = "OF05";
      break;
    case 4:
      retStr = "OF07";
      break;
    case 5:
      retStr = "OF13";
  }
  return retStr;
}

export const mdslOrder = async (
  list: Array<IOoUnospayDelivery>,
  select: OoProvider
) => {
  let data_array: Array<ExcelExportModel> = [];
  console.log(list);
  //공휴일 적용 안됨
  const today = new Date();
  let minDate: Date;
  const dayOfWeek = today.getDay(); // 0은 일요일, 1은 월요일, ... 6은 토요일
  switch (dayOfWeek) {
    case 1: // 월요일
      minDate = addDays(today, 2);
      break;
    case 2: // 화요일
      minDate = addDays(today, 2);
      break;
    case 3: // 수요일
      minDate = addDays(today, 2);
      break;
    case 4: // 목요일
      minDate = addDays(today, 2);
      break;
    case 5: // 금요일
      minDate = addDays(today, 3);
      break;
    case 6: // 토요일
      minDate = addDays(today, 4);
      break;
    case 0: // 일요일
      minDate = addDays(today, 3);
      break;
    default:
      minDate = addDays(today, 1);
  }

  const DeliveryRequest = ( type:string, memo?:string ) => {
    switch (type) {
      case '2':
        return '자유 출입 가능'
      case  '6':
        return memo
      case '7':
        return '무인택배함'
      default :
        return type
    }
  }

  let menuListIndex = 0;
  list.forEach((e, i) => {
    if (e.status == OoDeliveryStatus.existAddress  || e.status == OoDeliveryStatus.sendPackage) {
      const model = new ExcelExportModel();
      e.transportInfo.forEach((info, i) => {
        const option = e.salesMenu![i];
        //옵션으로 묶인 단품메뉴들
        option.salesOption?.map((optionData: IOoUnospaySalesMenuOption) => {
          if (_.startsWith(optionData.externalCode, exCodeNumToStr(select))) {
            console.log("optionData");
            model.product = info.menuName;
            model.exCode = info.externalCode;
            // model.quantity = e.salesMenu![i].menuCount;
            model.orderDate = convTimestampDateFormat(e.timestamp);

            model.name = e.recipientName;
            model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
            model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
            model.address1 = e.address;
            model.address2 = e.addressDetail;
            model.zipCode = e.zipCode;
            model.memo = e.memo;
            model.subMemo = e.subMemo ? DeliveryRequest(e.subMemo!, e.memo) : e.memo

            option.salesOption?.forEach((e) => {
              if (!_.isEmpty(e.externalCode)) {
                model.optionCodeList!.push(e.externalCode!);
              }
            });

            model.quantity = option.menuCount;
            model.withNewCare =
              getNewCareInfo(_.first(option.salesOption)?.externalCode) || "";

            if (e.salesMenu && e.salesMenu.length > i) {
              const salesMenuItem = e.salesMenu[i];

              const count = getOptionDeliveryCount(optionData.externalCode!);

              if (
                info.externalCode?.startsWith("OF05") ||
                info.externalCode?.startsWith("OF07") ||
                info.externalCode?.startsWith("OF13")
              ) {
                model.deliveryDate =
                  e.salesMenu![i].selectMenu![0].date != null
                    ? e.salesMenu![i].selectMenu![0].date
                    : "";
              }

              for (var j = 0; j < count; j++) {
                data_array.push({ ...model });
              }
            }
          }
        });

        if (_.startsWith(info.externalCode, exCodeNumToStr(select))) {
          model.product = info.menuName;
          model.exCode = info.externalCode;
          // model.quantity = e.salesMenu![i].menuCount;
          model.orderDate = convTimestampDateFormat(e.timestamp);

          model.name = e.recipientName;
          model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
          model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
          model.address1 = e.address;
          model.address2 = e.addressDetail;
          model.zipCode = e.zipCode;
          model.memo = e.memo;
          model.subMemo = e.subMemo ? DeliveryRequest(e.subMemo!, e.memo) : e.memo
          
          option.salesOption?.forEach((e) => {
            if (!_.isEmpty(e.externalCode)) {
              model.optionCodeList!.push(e.externalCode!);
            }
          });

          model.quantity = option.menuCount;

          const count = getDeliveryCount(option!);
          model.withNewCare =
            getNewCareInfo(_.first(option.salesOption)?.externalCode) || "";
          model.price =
            getMenuPrice(info.externalCode) ||
            formatPrice(Number(option.menuPrice)).toString();

          if (
            info.externalCode?.startsWith("OF05") ||
            info.externalCode?.startsWith("OF07") ||
            info.externalCode?.startsWith("OF13")
          ) {
            if (e.salesMenu![i].selectMenu != undefined) {
              model.deliveryDate =
                e.salesMenu![i].selectMenu![0].date != null
                  ? e.salesMenu![i].selectMenu![0].date
                  : "";
            }
          }

          if (e.salesMenu && e.salesMenu.length > i) {
            const salesMenuItem = e.salesMenu[i];

            const count = getDeliveryCount(salesMenuItem);

            for (var j = 0; j < count; j++) {
              data_array.push({ ...model });
            }
          }
        }
      });
    }
  });

  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = `tlc헬스케어 발주서`;

  const arrayBufferMdsl = await (
    await fetch("/assets/mdsl.xlsx")
  ).arrayBuffer();
  const workbookMdsl = new ExcelJS.Workbook();
  await workbookMdsl.xlsx.load(arrayBufferMdsl);
  const ws_mdsl = workbookMdsl.getWorksheet("주문 양식");
  const book = XLSX.utils.book_new();

  if (select == OoProvider.mdslProvider) {
    const rowNumber = 11;
    data_array.map((data: ExcelExportModel, index: number) => {
      const row = ws_mdsl.getRow(rowNumber + index);
      [
        "",
        index + 1,
        data.name,
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}`,
        moment(data.deliveryDate).format("YYYY-MM-DD"),
        mdslNameCost.find(
          (item) =>
            _.first(data.optionCodeList) == item.optionCode ?  _.first(data.optionCodeList) == item.optionCode && item.code === data.exCode : item.code === data.exCode 
        )?.subName,
        data.quantity,
        Number(
          mdslNameCost.find(
            (item) =>

              _.first(data.optionCodeList) == item.optionCode ?  _.first(data.optionCodeList) == item.optionCode && item.code === data.exCode : item.code === data.exCode
          )?.cost
        ),
        data.zipCode,
        data.address1 + " " + data.address2,
        data.subMemo,
      ].forEach((value, index) => {
        const cell = row.getCell(index + 1); // 엑셀은 1부터 시작
          cell.value = value;
      });
      // const cell = row.getCell(3);
      // cell.value = 'value';
    });
    // XLSX.utils.book_append_sheet(book, ws_mdsl, "메디쏠라");
  }
  const excelBuffer = await workbookMdsl.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFile,
    excelFileName +
      "_" +
      moment().format("YYYYMMDD") +
      "(메디쏠라)" +
      excelFileExtension
  );
};
