import {useSuspenseHook} from "@hooks/suspense";
import {useQuery} from "react-query";
import {DoctorOnAPI} from "@api/request";
import {OoParamId} from "@api/params";
import {isRequestSucceed} from "@api/reponses";

export const useReadByUserIdQuery = (id: string, queryTrigger: boolean) => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    return useQuery(
        "operator/readByUserId",
        async () => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.account.read(new OoParamId(id));
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: queryTrigger,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )
}