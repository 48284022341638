import _ from "lodash";
import React from "react";
import {IOoDiagnosis} from "@api/models";
import classNames from "classnames/bind";
import styles from "../../../../../../styles/diagnosis/DeliveryPatientInfo.module.scss";
import {formatPrice, formatTel} from "@hooks/format";
import styled from "styled-components";
import {getPharmacyMedicineDeliveryPayStatus} from "@models/pharmacy";

interface Props {
    diagnosisData: IOoDiagnosis,
}

export const PharmacyDeliveryInfo = ({diagnosisData}: Props) => {
    const deliveryStatus = {
        deliveryPay: getPharmacyMedicineDeliveryPayStatus(diagnosisData),
    }

    return (
        <Wrapper>
            <div className="title">배송 정보</div>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>수령인</td>
                    <td>{_.isUndefined(diagnosisData.deliveryRecipient) || _.eq(diagnosisData.deliveryRecipient, '') ? '-' : diagnosisData.deliveryRecipient}</td>
                    <td>우편번호</td>
                    <td>{_.isUndefined(diagnosisData.deliveryZipCode) || _.eq(diagnosisData.deliveryZipCode, '') ? '-' : diagnosisData.deliveryZipCode}</td>
                </tr>
                <tr>
                    <td>전화번호</td>
                    <td>{_.isUndefined(diagnosisData.deliveryTel) || _.eq(diagnosisData.deliveryZipCode, '') ? '-' : `${formatTel(diagnosisData.deliveryTel)}`}</td>
                    <td>주소</td>
                    <td>{_.isUndefined(diagnosisData.deliveryAddress) || _.isUndefined(diagnosisData.deliveryAddressDetail) ? '-' : `${diagnosisData.deliveryAddress} ${diagnosisData.deliveryAddressDetail}`}</td>
                </tr>
                <tr>
                    <td>배송비</td>
                    <td>{_.isUndefined(deliveryStatus.deliveryPay) ? '' : _.isEqual(deliveryStatus.deliveryPay, 0) ? '배송비 없음' : `${formatPrice(diagnosisData.deliveryPrice ?? 0)}원`}</td>
                    <td>배송메모</td>
                    <td>{_.isUndefined(diagnosisData.deliveryMemo) || _.eq(diagnosisData.deliveryMemo, '') ? '-' : diagnosisData.deliveryMemo}</td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;

  .title {
    font-weight: 700;
    font-size: 16px;
  }
`;

const Table = styled.table`
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: 100%;
  height: 144px;
  font-size: 13px;
  margin-top: 8px;

  tr {
    height: 30%;
  }

  tr:nth-child(1), tr:nth-child(2) {
    border-bottom: 1px solid #C8D6F8;
  }

  tr td:nth-child(2n-1) {
    background: #F4F6FB;
    text-align: center;
    font-weight: 700;
    width: 10%;
    font-size: 13px;
  }

  tr td:nth-child(2n) {
    padding: 10px;
    width: 40%;
    overflow: auto;
  }
`;