import "moment/locale/ko";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { DoctorOnAPI, userLevel } from "./api";
import { selectedUserState } from "./recoils";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import {
  DiagnosisDashBoardPage,
  DiagnosisDetailPage,
  DoctorDashBoardPage,
  DoctorDetailInfoDetailPage,
  DoctorDiagnosisDetailPage,
  DoctorInfoPage,
  DoctorInfoRegisterPage,
  DoctorReservation,
  HospitalInfoDetailPage,
  HospitalInfoRegisterPage,
  ListPage,
  PharmacyDashBoardPage,
  PharmacyDiagnosisDetail,
  PharmacyInfo,
  PharmacyInfoDetail,
  PharmacyInfoRegisterPage,
  PharmacyLedgerPage,
  UnosPayDashBoardPage,
  UnospayOrderDetailPage,
} from "./pages";
import { ThemeProvider } from "styled-components";
import { theme } from "@styles/theme";
import { Navigate, useRoutes } from "react-router-dom";
import { UnospayDeliveryListPopUp } from "@components/unospayDeliveryListPopUp";
import { HeaderLayout } from "@components/layout";
import { SignInPage } from "./pages/SignIn";
import { UnospayHdOrderPopUp } from "@components/unospayHdOrderData";
import AllMealsScheduleListPopUp from "pages/Operator/AllMealsScheduleListPopUp";
import MealsScheduleItemPopup from "pages/Operator/MealsScheduleItemPopup";
import DoctorCalculateBoardPage from "pages/Doctor/CalculateBoardPage";
import ProductSettingPage from "pages/Operator/ProductSettingPage";
import UnospayAddCompanyPopup from "@components/unospayAddCompanyPopup";
import CalculateDetailPage from "pages/Operator/CalculateDetailPage";
import CalculateMonthDetail from "pages/Operator/CalculateMonthDetail";
import DoctorCalculateMonthDetail from "pages/Doctor/CalculateMonthDetail";
import CalculatePage from "pages/Operator/CalculatePage";
import { CalculateHeaderLayout } from "@components/layout/headerContainer/CalculateHeader";
import ProductPriceList from "pages/Doctor/ProductPriceListPage";
import ProductInfoDetail from "pages/Doctor/ProductDetailPage";

function App() {
  const selectedUser = useRecoilValue(selectedUserState);
  const getToken = selectedUser?.bearerToken;
  if (!_.isUndefined(getToken)) DoctorOnAPI.shared.setBearerToken(getToken);

  const getOperatorRoutes = () => {
    return [
      {
        path: "/",
        element: <HeaderLayout children={<SignInPage />} />,
      },
      {
        path: "/home",
        element: <HeaderLayout children={<DiagnosisDashBoardPage />} />,
      },
      {
        path: "/doctor",
        element: <HeaderLayout children={<ListPage />} />,
      },
      {
        path: "/doctorDetail",
        element: <HeaderLayout children={<DoctorDetailInfoDetailPage />} />,
      },
      {
        path: "/hospital",
        element: <HeaderLayout children={<ListPage />} />,
      },
      {
        path: "/calculate",
        element: <HeaderLayout children={<CalculatePage />} />,
      },
      {
        path: "/calculateDetail",
        element: <HeaderLayout children={<CalculateDetailPage />} />,
      },
      {
        path: "/calculateMonthDetail",
        element: <HeaderLayout children={<CalculateMonthDetail />} />,
      },
      {
        path: "/productsSettings",
        element: <HeaderLayout children={<ProductSettingPage />} />,
      },
      {
        path: "/hospitalDetail",
        element: <HeaderLayout children={<HospitalInfoDetailPage />} />,
      },
      {
        path: "/addHospitalInfo",
        element: <HeaderLayout children={<HospitalInfoRegisterPage />} />,
      },
      {
        path: "/pharmacy",
        element: <HeaderLayout children={<ListPage />} />,
      },
      {
        path: "/pharmacyDetail",
        element: <HeaderLayout children={<PharmacyInfoDetail />} />,
      },
      {
        path: "/addPharmacyInfo",
        element: <HeaderLayout children={<PharmacyInfoRegisterPage />} />,
      },
      {
        path: "/addDoctorInfo",
        element: <HeaderLayout children={<DoctorInfoRegisterPage />} />,
      },
      {
        path: "/diagnosisDetail",
        element: <HeaderLayout children={<DiagnosisDetailPage />} />,
      },
      {
        path: "/unospay",
        element: <HeaderLayout children={<UnosPayDashBoardPage />} />,
      },
      {
        path: "/unospayOrderDetail",
        element: <HeaderLayout children={<UnospayOrderDetailPage />} />,
      },
      {
        path: "/unospayOrderDetail/:id",
        element: <UnospayDeliveryListPopUp />,
      },
      {
        path: "/unospayAllMealsSchedule",
        element: <AllMealsScheduleListPopUp />,
      },
      {
        path: "/unospayMealDetail/:mealCode/:itemCode/:searchDate",
        element: <MealsScheduleItemPopup />,
      },
      {
        path: "/unospayHdOrder/:date",
        element: <UnospayHdOrderPopUp />,
      },
      {
        path: "/companyPopup",
        element: <UnospayAddCompanyPopup />,
      },
      {
        path: "*",
        element: <Navigate to="/home" />,
      },
    ];
  };

  const getPharmacyRoutes = () => {
    return [
      {
        path: "/",
        element: <HeaderLayout children={<SignInPage />} />,
      },
      {
        path: "/home",
        element: <HeaderLayout children={<PharmacyDashBoardPage />} />,
      },
      {
        path: "/pharamcyPrescription",
        element: <HeaderLayout children={<PharmacyDiagnosisDetail />} />,
      },
      {
        path: "/pharmacyLedger",
        element: <HeaderLayout children={<PharmacyLedgerPage />} />,
      },
      {
        path: "/pharmacyInfo",
        element: <HeaderLayout children={<PharmacyInfo />} />,
      },
      {
        path: "*",
        element: <Navigate to="/home" />,
      },
    ];
  };

  const getDefaultRoute = () => {
    return [
      {
        path: "/",
        element: <HeaderLayout children={<SignInPage />} />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ];
  };

  const getCalculaterRoutes = () => {
    return [
      {
        path: "/",
        element: <CalculateHeaderLayout children={<SignInPage />} />,
      },
      {
        path: "/home",
        element: <CalculateHeaderLayout children={<DoctorCalculateBoardPage />} />,
      },
      {
        path: "/calculateMonthDetail",
        element: <CalculateHeaderLayout children={<DoctorCalculateMonthDetail />} />,
      },
      {
        path: "/productPriceList/*",
        element: <CalculateHeaderLayout children={<ProductPriceList />} />,
      },
      {
        path: "/productPriceList/mtm",
        element: <CalculateHeaderLayout children={<ProductPriceList />} />,
      },
      {
        path: "/productPriceList/mts",
        element: <CalculateHeaderLayout children={<ProductPriceList />} />,
      },
      {
        path: "/productPriceList/mte",
        element: <CalculateHeaderLayout children={<ProductPriceList />} />,
      },
      {
        path: "/productDetail/:externalCode",
        element: <CalculateHeaderLayout children={<ProductInfoDetail />} />,
      },
      {
        path: "*",
        element: <Navigate to="/home" />,
      },
    ];
  };

  const getDoctorRoutes = () => {
    return [
      {
        path: "/",
        element: <HeaderLayout children={<SignInPage />} />,
      },
      {
        path: "/home",
        element: <HeaderLayout children={<DoctorDashBoardPage />} />,
      },
      {
        path: "/prescription",
        element: <HeaderLayout children={<DoctorDiagnosisDetailPage />} />,
      },
      // {
      //   path: "/reservationInfo",
      //   element: <HeaderLayout children={<DoctorReservation />} />,
      // },
      {
        path: "/calculateDetail",
        element: <HeaderLayout children={<DoctorCalculateBoardPage />} />,
      },
      {
        path: "/calculateMonthDetail",
        element: <HeaderLayout children={<DoctorCalculateMonthDetail />} />,
      },
      {
        path: "*",
        element: <Navigate to="/home" />,
      },
    ];
  };

  const filteredLevel = (level: number) => {
    let route = getDefaultRoute();
    switch (level) {
      case userLevel.operator:
      case userLevel.manager:
        route = getOperatorRoutes();
        break;
      case userLevel.o2oPharmacy:
        route = getPharmacyRoutes();
        break;
      case userLevel.pharmacist:
        route = getPharmacyRoutes();
        break;
      case userLevel.o2oDoctor:
        route = getDoctorRoutes();
        break;
      case userLevel.o2oCalculater:
        route = getCalculaterRoutes();
        break;
    }
    return route;
  };

  return (
    <ThemeProvider theme={theme}>
      {useRoutes(filteredLevel(selectedUser?.user.level || 0))}
    </ThemeProvider>
  );
}

export default App;
