import { ModalOverlay } from "@components/modal";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CustomInputText } from "@components/custom";

interface Props {
  modalVisible: boolean;
  title: any;
  contents: any;
  cancelButtonName: string;
  submitButtonName: string;
  selectValue?: string;
  commentValue?: string;
  onChangedVisible: (status: boolean) => void;
  onChangedTrigger: (status: boolean, value: string) => void;
  // onChangedCommentInput: (value: string) => void
}

export const OrderCancelModal = ({
  modalVisible,
  title,
  contents,
  cancelButtonName,
  submitButtonName,
  selectValue,
  commentValue,
  onChangedVisible,
  onChangedTrigger,
}: // onChangedCommentInput
Props) => {
  const [handleVisible, setHandleVisible] = useState<boolean>(modalVisible);
  const [submit, setSubmit] = useState<boolean>(false);

  const handleSubmit = () => setSubmit(!submit);

  const [selectCancelComment, setSelectCancelComment] = useState<string>("");
  const onSelectFilterOrder = (e: any) => {
    const { value } = e.target;
    setSelectCancelComment(value);
  };

  const [etcCancelComment, setEtcCancelComment] = useState<string>("");
  const onEtcCancelComment = (e: any) => {
    const { value } = e.target;
    setEtcCancelComment(value);
  };

  useEffect(() => {
    setHandleVisible(modalVisible);
    setSelectCancelComment(selectValue!);
    setEtcCancelComment(commentValue!);
  }, [modalVisible]);

  useEffect(() => {
    onChangedVisible(handleVisible);
  }, [handleVisible]);

  useEffect(() => {
    console.log("11111", selectValue);
    onChangedTrigger(submit, `${selectCancelComment}=${etcCancelComment}`);
  }, [submit]);

  return (
    <ModalOverlay visible={handleVisible}>
      <Wrapper>
        <Contents>
          <div className="contentsTitle">{title}</div>
          <div className="contents">{contents}</div>
          <SelectContainer>
            <select
              className="select"
              onChange={onSelectFilterOrder}
              value={selectCancelComment}
            >
              <option value={"배송불가지역"}>배송불가지역</option>
              <option value={"오주문"}>오주문</option>
              <option value={"단순변심"}>단순변심</option>
              <option value={"기타"}>기타</option>
            </select>
          </SelectContainer>
          <CustomInputText
            size="middle"
            color={"blue"}
            value={etcCancelComment}
            disabled={true}
            name="comment"
            onChanged={onEtcCancelComment}
            placeholder={"기타 사유 입력"}
          />
        </Contents>
        <ButtonContainer>
          <CancelButton
            type="button"
            onClick={() => setHandleVisible(!handleVisible)}
          >
            {cancelButtonName}
          </CancelButton>
          <SubmitButton type="button" onClick={handleSubmit}>
            {submitButtonName}
          </SubmitButton>
        </ButtonContainer>
      </Wrapper>
    </ModalOverlay>
  );
};
const Wrapper = styled.div`
  width: 556px;
  height: 372px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Contents = styled.div`
  width: 100%;
  height: 32px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

  & .contentsTitle {
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 36px;
  }

  & .contents {
    width: 100%;
    color: #000;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    margin-top: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 234px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled.button`
  width: 222px;
  height: 60px;
  background: #6e8ed1;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: none;
`;

const CancelButton = styled.button`
  width: 222px;
  height: 60px;
  background: #cf5d5d;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: none;
  margin-right: 10px;
`;

const SelectContainer = styled.div`
  width: 100%;
  height: 52px;
  flex-shrink: 0;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 16px;
  .select {
    border-radius: 8px;
    border: 0.5px solid #c2c1bd;
    width: 240px;
    height: 52px;
    padding: 5px;
  }
`;
