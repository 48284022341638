import {useRecoilValue} from "recoil";
import {editSelectorFamily} from "@recoils/status";
import {useState} from "react";
import {PharmacyErrorState} from "@recoils/pharmacy";
import _ from "lodash";
import defaultHours from "../../../../assets/defaultHours.json";
import {useCardEdit} from "@hooks/common";
import {IOoPharmacy, IOoWorkHours} from "@models/pharmacy";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";

export const usePharmacyWorkHoursHook = (data: IOoPharmacy, componentName: string) => {
    const {isReset} = useCardEdit(componentName);
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const error = useRecoilValue(PharmacyErrorState);
    const {updateTimePharmacy} = usePharmacyFunctionHook();

    const [pharmacyParams, setPharmacyParams] = useState<Array<IOoWorkHours>>([]);
    const [lunchTime, setLunchTime] = useState<Array<string>>([]);
    const [changeLunchTime, setChangeLunchTime] = useState<IOoWorkHours>();

    // pharmacyHours의 배열이 empty 일 경우
    const defaultTime = defaultHours.pharmacyHours as IOoWorkHours[];
    const defaultDayList = _.concat(
        _.tail(defaultTime),
        _.head(defaultTime)
    ) as IOoWorkHours[];
    const defaultLunchTime = ["13:00,14:00"];

    // pharmacyHours의 배열의 길이가 8일때, 즉 점심시간 배열이 없을 경우
    const copiedOriginTime = _.chain(data.pharmacyHours)
        .cloneDeep()
        .sortBy("dayOfWeek")
        .value();
    const originDayList = _.concat(
        _.tail(copiedOriginTime),
        _.head(copiedOriginTime)
    ) as IOoWorkHours[];

    // pharmacyHours의 배열의 길이가 9일때, 즉 점심시간이 8번째 배열로 있을 경우
    const copiedPharmacyHours = _.chain(data.pharmacyHours)
        .cloneDeep()
        .sortBy("dayOfWeek")
        .value();
    const copiedHoliday = copiedPharmacyHours.splice(0, 1);
    const copiedLunchTime = copiedPharmacyHours.splice(
        copiedPharmacyHours.length - 1,
        copiedPharmacyHours.length
    );

    const result = _.concat(copiedPharmacyHours, copiedHoliday, copiedLunchTime);

    const onDefaultTimeChanged = (item: IOoWorkHours) => {
        const index = _.findIndex(pharmacyParams, (data) =>
            _.isEqual(data.dayOfWeek, item.dayOfWeek)
        );
        pharmacyParams[index] = item;
    };

    const onLunchTimeChanged = (item: string, checked: boolean) => {
        const target: IOoWorkHours = {
            dayOfWeek: 8,
            hours: item,
            isOff: checked,
            breakHoursList: defaultLunchTime,
        };
        setChangeLunchTime(target);
    };

    return {
        editStatus,
        isReset,
        error,
        pharmacyParams,
        setPharmacyParams,
        lunchTime,
        setLunchTime,
        changeLunchTime,
        setChangeLunchTime,
        onDefaultTimeChanged,
        onLunchTimeChanged,
        result,
        originDayList,
        defaultDayList,
        defaultLunchTime,
        updateTimePharmacy,
    };
};
