import { IOoCalculateMonthList } from "@api/models/calculate/calculateMonthList";
import { atom } from "recoil";

const calculateRawListState = atom<Array<IOoCalculateMonthList>>({
    key:'calculateRawListState',
    default: [],
});

export {
    calculateRawListState
};