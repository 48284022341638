import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import classNames from "classnames/bind";
import buttonStyles from "../../../../../styles/common/Button.module.scss";
import styled from "styled-components";
import {toast} from "react-toastify";
import {IOoDoctor} from "../../../../../api";
import doctorInitializedInfo from '../../../../../assets/doctorInitialInfo.json';

interface Props {
    data: IOoDoctor,
    editButtonClick: boolean,
    submitButtonClick: boolean,
    onChange: (data: Array<string>) => void,
}


export const DoctorTheme = ({data, editButtonClick, submitButtonClick, onChange}: Props) => {
    const bx = classNames.bind(buttonStyles);
    const [theme, setTheme] = useState<Array<string>>([]);

    const onCheckedTheme = (e: any) => {
        const index = _.findIndex(theme, (item) => _.isEqual(item, e.target.value));
        if (_.lt(theme.length, 3)) {
            if (!_.isEqual(index, -1)) {
                theme.splice(index, 1);
                setTheme(theme);
                onChange(theme);
            } else {
                theme.push(e.target.value);
                setTheme(theme);
                onChange(theme);
            }
        } else {
            if (!_.isEqual(index, -1)) {
                theme.splice(index, 1);
                setTheme(theme);
                onChange(theme);
            } else {
                toast.error('진료 과목은 최대 3개까지 선택가능합니다.');
                e.target.checked = false;
            }
        }
    };


    const isEqualDefaultTheme = useCallback((value: string) => {
        if (editButtonClick) {
            const themeData = theme.map(item => _.isEqual(item, value));
            return _.includes(themeData, true);
        } else {
            if (!_.isUndefined(data.theme)) {
                const temp = data.theme.map(item => _.isEqual(item, value));
                return _.includes(temp, true);
            } else {
                return false;
            }
        }
    }, [onCheckedTheme]);

    const isEqualTheme = useCallback((value: string) => {
        if (!_.isUndefined(data.theme)) {
            const temp = data.theme.map(item => _.isEqual(item, value));
            return _.includes(temp, true);
        } else {
            return false;
        }
    }, [onCheckedTheme]);

    useEffect(() => {
        setTheme(_.cloneDeep(_.isUndefined(data.theme) ? [] : data.theme));
    }, [editButtonClick]);

    const AfterChecked = () => {
        return (
            <Container>
                {
                    doctorInitializedInfo.theme.map((item, index) => (
                        <div key={index}>
                            <input
                                id={`${item.val}`}
                                type="checkbox"
                                value={item.val}
                                defaultChecked={isEqualDefaultTheme(`${item.val}`)}
                                disabled={!editButtonClick}
                                onChange={onCheckedTheme}
                                hidden={true}
                            />
                            <label htmlFor={`${item.val}`}
                                   className={bx('checkbox-label')}
                                   hidden={!editButtonClick}>
                                <div>{item.val}</div>
                            </label>
                        </div>
                    ))
                }
            </Container>
        )
    };

    const BeforeChecked = () => {
        return (
            <Container>
                {
                    doctorInitializedInfo.theme.map((item, index) => (
                        <div key={index}>
                            <input
                                id={`${item.val}`}
                                type="checkbox"
                                value={item.val}
                                disabled={!editButtonClick}
                                onChange={onCheckedTheme}
                                checked={isEqualDefaultTheme(`${item.val}`)}
                                hidden={true}
                            />
                            <label htmlFor={`${item.val}`}
                                   className={bx('checkbox-label')}
                                   hidden={editButtonClick}>
                                <div>{item.val}</div>
                            </label>
                        </div>
                    ))
                }
            </Container>
        )
    };

    return (
        <Wrapper>
            <div>진료 테마 (최대 3개까지 중복 선택 가능)</div>
            <AfterChecked/>
            <BeforeChecked/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  padding-top: 24px;

  & > div:first-child {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    margin-left: 10px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;