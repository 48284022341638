import React, { useEffect, useState } from 'react'
import ListDashBoard from './components/ListDashBoard';
import ChartDashBoard from './components/ChartDashBoard';
import { last } from 'lodash';
import { useLocation } from 'react-router';
import DataCheckListDashBoard from './components/DataCheckListDashBoard';
import styled from 'styled-components';
import { useHospitalListQuery } from '@queries/hospital';

type DashBoardType = 'chartDashBoard'|'calculate' | 'calculateRaw'

interface ButtonStyle{
    focus:boolean;
}

const CalculatePage = () => {
    useHospitalListQuery();
    const [tabType, setTabType] = useState<DashBoardType>('chartDashBoard');
    
    const handleClick = (type:string) => {
        switch(type) {
            case 'chartDashBoard':
                setTabType('chartDashBoard');
                break;
            case 'calculate':
                setTabType('calculate');
                break;
            case 'calculateRaw':
                setTabType('calculateRaw');
                break;
        }
    }

  return (
    <Wrapper>
        <TopContainer>
        <ButtonContainer>
            <Button focus={tabType==='chartDashBoard'} onClick={() => handleClick('chartDashBoard')}>대시보드</Button>
            <Button focus={tabType==='calculate'} onClick={() => handleClick('calculate')}>리스트</Button>
            <Button focus={tabType==='calculateRaw'} onClick={() => handleClick('calculateRaw')}>정산데이터</Button>
        </ButtonContainer>
        {tabType === 'chartDashBoard' && <ChartDashBoard target={tabType}/>}
        {tabType === 'calculate' && <>
        <ListDashBoard target={tabType}/></>}
        {tabType === 'calculateRaw' && <DataCheckListDashBoard target={tabType}/>}
        </TopContainer>
    </Wrapper>
  )
}

export default CalculatePage


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`
const TopContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  top:130px;
  align-items: center;
`;

const ButtonContainer = styled.div`
    border-bottom: 1px solid #cccccc;
    width: 100%;
    max-width: 55%;
    min-width: 1400px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;

    @media (min-height: 1024px) and (max-height: 1280px) {
        width: 90%;
      }
`

const Button = styled.button<ButtonStyle>`
    background: none;
    padding: 8px 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: ${props => props.focus ? '3px solid #1C6FC9' : 'none'};
    color: ${props => props.focus ? '#1C6FC9' : '#2B4E72'};
    font-weight: 700;
`