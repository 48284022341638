import _ from "lodash";
import {AbstractOoParam} from "@api/params";

class OoDailyDiagnosisRecordRange extends AbstractOoParam {
    // 시작 타임스탬프
    beginTimestamp: string = "0";

    // 종료 타임스탬프
    endTimestamp: string = "0";

    // 마지막 진료 아이디 - 페이지네이션
    diagnosisId?: string = undefined;

    count?: string = "100";

    constructor(beginTimestamp: string,
                endTimestamp: string,
                diagnosisId?: string,
                count?: string) {
        super();
        this.beginTimestamp = beginTimestamp;
        this.endTimestamp = endTimestamp;
        this.count = count;
        this.diagnosisId = diagnosisId;
    }

    getPathParams() {
        return _.isEmpty(this.diagnosisId)
            ? `${this.beginTimestamp}/${this.endTimestamp}/${this.count}`
            : `${this.beginTimestamp}/${this.endTimestamp}/${this.count}/${this.diagnosisId}`;
    }
}

export {OoDailyDiagnosisRecordRange};
