import {AxiosInstance} from "axios";
import {OoParamKakaoDevOpenOrderId} from "../params/kakao/kakaoOrderId";
import {IOoDataResponse, IOoResponse} from "../reponses";
import {endPoint} from "./base";
import {OoParamKakaoCancelPayment} from "../params/kakao/kakaoCancelPayment";

class DoctorOnKakaoMobilityAPI {
    constructor(private readonly axiosInstance: AxiosInstance) {
    }

    // 오더 단건 조회
    async devGetStatus(param: OoParamKakaoDevOpenOrderId): Promise<IOoDataResponse<string>> {
        const url = `${endPoint.kakaoMobilityOrder.order}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<string>>(url);
    }

    // 배정 전 취소
    async cancel(param: OoParamKakaoDevOpenOrderId): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.kakaoMobilityOrder.cancel, param);
    }

    // 배정 후 취소 수수료 결제
    async cancelPay(param: OoParamKakaoCancelPayment): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.kakaoMobilityOrder.cancelPay, param);

    }


}

export {DoctorOnKakaoMobilityAPI}