import React, {useEffect} from "react";
import styled from "styled-components";
import {IOoDoctor, OoWapleReservationType} from "@api/models";
import {useDiagnosisSettingHook} from "@components/infoForm";
import {CustomCheckBox} from "@components/custom";

interface Props {
    doctorInfo: IOoDoctor,
    componentName: string,
    isCancel:boolean,
    onChange: (items: Array<number>) => void,
}

export const ReservationDiagnosisType = ({doctorInfo, componentName, isCancel, onChange}: Props) => {
    const {
        editStatus,
        items,
        setItems,
        isExistType,
        onCheckedType,
    } = useDiagnosisSettingHook({doctorInfo, componentName});

    useEffect(() => {
        onChange(items);
    }, [items]);

    useEffect(()=>{
        if(isCancel){
            setItems(doctorInfo.wapleServiceType || []);
        }
    },[isCancel]);

    return (
        <DiagnosisTypeContainer>
            <div>
                <CustomCheckBox title="비대면 진료(예약)"
                                checked={isExistType(OoWapleReservationType.phoneReservation)}
                                onChange={onCheckedType}
                                disabled={!editStatus.isEdit}
                                value={OoWapleReservationType.phoneReservation}
                />
                <CustomCheckBox title="대면 진료(예약)"
                                checked={isExistType(OoWapleReservationType.visitReservation)}
                                onChange={onCheckedType}
                                disabled={!editStatus.isEdit}
                                value={OoWapleReservationType.visitReservation}
                />
                <CustomCheckBox title="대면 진료(접수)"
                                checked={isExistType(OoWapleReservationType.visitRegister)}
                                onChange={onCheckedType}
                                disabled={!editStatus.isEdit}
                                value={OoWapleReservationType.visitRegister}
                />
            </div>
        </DiagnosisTypeContainer>
    )
}


const DiagnosisTypeContainer = styled.div`
  margin-left: 18px;
  font-weight: 700;
  font-size: 13px;
  height: auto;

  & > div {
    display: flex;
    flex-direction: column;
    align-content: space-around;

    & > label {
      display: flex;
      align-items: center;
      height: 24px;
      margin-bottom: 12px;

      & > input[type=checkbox] {
        margin-right: 19px;
      }
    }
  }
`;
