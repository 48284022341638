import React, {useEffect, useMemo} from "react";
import {DoctorDetailContainer} from "../doctorDetailContainer";
import styled, {css} from "styled-components";
import classNames from "classnames/bind";
import buttonStyles from "../../../styles/common/Button.module.scss";
import {useRecoilState} from "recoil";
import _ from "lodash";
import {useFilteredReservationStatus} from "@models/reservation";
import {IOoDiagnosis, OoDiagnosisStatus, OoHospitalMedicineType, OoWapleReservationStatus} from "@models/diagnosis";
import {telemedicineUpdateSelector} from "@recoils/doctor";
import {getDoctorDiagnosisStatus} from "@models/doctor/doctorDiagnosisProcessingState";
import {OoParamPrescriptionCreate} from "@params/telemedicine";
import {DiagnosisBackgroundColor} from "@models/diagnosis/diagnosisProcessingState";
import {toast} from "react-toastify";
import {useDoctorFunctionHook} from "@hooks/doctor";

interface Props {
    data: IOoDiagnosis,
    list: Array<IOoDiagnosis>,
}

export const DoctorDetailLayout = ({data, list}: Props) => {
    const bx = classNames.bind(buttonStyles);
    const [telemedicineUpdateParamList, setTelemedicineUpdateParamList] = useRecoilState(telemedicineUpdateSelector);

    const {
        isDoneReservationDiagnosis,
        isReceipt,
        isReservation,
    } = useFilteredReservationStatus(data);

    const {
        changeStatusDiagnosis,
        updateTelemedicine,
        saveTelemedicine
    } = useDoctorFunctionHook();

    const onClickDiagnosisCancel = () => {
        const index = telemedicineUpdateParamList.findIndex((item) => _.isEqual(item.id, data.id || ""));
        if (_.isEmpty(telemedicineUpdateParamList[index].comment)) {
            toast.error('진료 내용에 취소 사유를 입력해주세요. ');
            return;
        }
        changeStatusDiagnosis(data.id || "", OoDiagnosisStatus.doctorCancel, telemedicineUpdateParamList[index].comment || "");
    };

    const onClickDiagnosisSuccess = () => {
        const index = telemedicineUpdateParamList.findIndex((item) => _.isEqual(item.id, data.id || ""));
        if (_.isEqual(telemedicineUpdateParamList[index].hospitalMedicinePrice, 0)
            && !_.isEqual(telemedicineUpdateParamList[index].hospitalMedicineType, OoHospitalMedicineType.diagnosis)) {
            toast.error('약제비를 입력해주세요.');
            return;
        }
        updateTelemedicine(telemedicineUpdateParamList);
    };

    const onClickSaveDiagnosis = () => saveTelemedicine(telemedicineUpdateParamList);

    const isTargetHospital = (): boolean => {
        return _.isEqual(data.hospitalId, 'jZXvLyOgstSeIzvMuYfg')
            || _.isEqual(data.hospitalId, 'BYs53cNaY9gfynC7KZtk')
            || _.isEqual(data.hospitalId, 'XIC6NRK8tfqZPiPfhfRR');
    };

    const isShowDiagnosisSuccessButton = () => {
        if (isReceipt()) {
            return data.status <= OoDiagnosisStatus.accept;
        } else {
            return data.status <= OoDiagnosisStatus.accept &&
                _.isEqual(data.wapleReservationStatus, OoWapleReservationStatus.reservationComplete)
                && !isDoneReservationDiagnosis();
        }
    };

    const isShowSaveButton = (): boolean => {
        if (isReceipt()) {
            return _.lt(data.status, OoDiagnosisStatus.diagnosisComplete)
        } else {
            return data.status <= OoDiagnosisStatus.accept && !isDoneReservationDiagnosis();
        }
    };

    useEffect(() => {
        if (_.isUndefined(list)) return;
        const newParamList = list.map((item) => {
            const param = new OoParamPrescriptionCreate();
            param.id = item.id || "";
            param.price = item.price || 0;
            param.comment = item.comment || "";
            param.hospitalMedicinePrice = item.hospitalMedicinePrice || 0;
            param.prescription = item.prescription || [];
            return param;
        });
        setTelemedicineUpdateParamList(newParamList);
    }, []);

    const index = useMemo(() => {
        return telemedicineUpdateParamList.findIndex((item) => _.isEqual(item.id, data.id || ""));
    }, [telemedicineUpdateParamList]);

    const handleReservation = () => {
        toast.error('미구현 기능입니다.');
    };

    return (
        <Wrapper>
            <TopWrapper>
                <ButtonContainer>
                    {
                        isReservation() &&
                        <ChangedStatusButton onClick={handleReservation}>
                            예약일 도래 테스트
                        </ChangedStatusButton>
                    }
                    <Status color={`${getDoctorDiagnosisStatus(data).background}`}>
                        {getDoctorDiagnosisStatus(data).title}
                    </Status>
                    {
                        isShowDiagnosisSuccessButton() &&
                        <ChangedStatusButton onClick={onClickDiagnosisCancel}>
                            진료 취소
                        </ChangedStatusButton>
                    }
                </ButtonContainer>
            </TopWrapper>
            {
                index > -1 &&
                <DoctorDetailContainer data={data}
                                       list={list}/>
            }
            <ButtonsContainer>
                {
                    isTargetHospital() &&
                    <button className={bx('diagnosis-save-button')}
                            onClick={onClickSaveDiagnosis}
                            disabled={!isShowSaveButton()}>
                        저장
                    </button>
                }
                <button className={bx('diagnosis-submit-button')}
                        onClick={onClickDiagnosisSuccess}
                        disabled={!isShowDiagnosisSuccessButton()}>
                    진료완료
                </button>
            </ButtonsContainer>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  height: auto;
  width: 1024px;
  background: #fff;
  border: 1px solid #C4C4C4;
`;

const textColor = {
    none: '#000000',
    success: '#2176F6',
    fail: '#EC2C2C',
};

const borderColor = {
    default: '#C2C1BD',
    fail: '#EC2C2C',
};

const TopWrapper = styled.div`
  width: 1024px;
  height: auto;
  border: none;
  display: flex;
  justify-content: flex-end;
  padding: 2%;
`;

const ButtonContainer = styled.div`
  height: auto;
  background: #fff;
  display: flex;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 72px;
  padding-bottom: 44px;
`;


const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 36px;
  font-weight: 700;
  font-size: 13px;
  background: ${props => props.color};
  border-radius: 8px;
  margin-left: 10px;

  ${(props) => {
    if (props.color === DiagnosisBackgroundColor.success) {
      return css`
        color: ${textColor.success};
        border: 1px solid ${textColor.success};
      `;
    } else if (props.color === DiagnosisBackgroundColor.fail) {
      return css`
        color: ${textColor.fail};
        border: 1px solid ${textColor.fail};
      `;
    } else if (props.color === DiagnosisBackgroundColor.green) {
      return css`
        background: ${DiagnosisBackgroundColor.success};
        border: 1px solid ${textColor.success};
        color: ${textColor.success};
      `;
    } else {
      return css`
        color: ${textColor.none};
        border: 1px solid ${borderColor.default};
      `;
    }
  }
  }
`;

const ChangedStatusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 36px;
  font-weight: 700;
  font-size: 13px;
  border-radius: 8px;
  margin-left: 10px;
  background: #FDEFEF;
  color: ${borderColor.fail};
  border: 1px solid ${borderColor.fail};
`;

