import React, {useEffect, useState} from "react";
import styled from "styled-components";
import _ from "lodash";
import {toast} from "react-toastify";
import {HospitalPicture} from "../../../../interfaces";
import {InitialImageUpload} from "@components/initialImageUpload";

interface Props {
    onChanged: (data: HospitalPicture) => void,
}

export const HospitalRegisterLogo = ({onChanged}: Props) => {
    const [arr, setArr] = useState<Array<string>>([]);
    const onChangedPicture = (target: string, title?: string) => {
        if (_.isEmpty(target)) return;

        if (!_.isUndefined(arr) && _.gt(arr.length, 5)) {
            toast.error('사진등록은 5개 까지 가능합니다.');
            return;
        }

        try {
            const targetNumber = _.toNumber(title!.charAt(3));
            if (!arr[targetNumber]) {
                const newArr = _.concat(arr, target);
                setArr(newArr);
                return;
            } else {
                const copied = _.cloneDeep(arr);
                copied[targetNumber] = target;
                setArr(copied);
                return;
            }
        } catch (e) {
            console.log(e, 'onChangedPicture error!');
        }
    };

    useEffect(() => {
        onChanged({pictures: arr});
    }, [arr, onChanged]);

    return (
        <Wrapper>
            <InitialImageUpload target="hospital"
                                title="img0"
                                onChanged={onChangedPicture}/>
            <InitialImageUpload target="hospital"
                                title="img1"
                                onChanged={onChangedPicture}/>
            <InitialImageUpload target="hospital"
                                title="img2"
                                onChanged={onChangedPicture}/>
            <InitialImageUpload target="hospital"
                                title="img3"
                                onChanged={onChangedPicture}/>
            <InitialImageUpload target="hospital"
                                title="img4"
                                onChanged={onChangedPicture}/>
        </Wrapper>)
}

const Wrapper = styled.div`
  display: flex;
`;