import { useRecoilState } from "recoil";
import { IOoUnospayDelivery } from "@api/models";
import { unospayDisplayListState } from "@recoils/unospay/unospayDisplayListState";

export const useUnospayDisplayHook = (id: string) => {
  const [displayList, setDisplayList] = useRecoilState(
    unospayDisplayListState(id)
  );

  const updateDisplay = (displayList: Array<IOoUnospayDelivery>) => {
    setDisplayList((cur) => ({ ...cur, list: displayList }));
  };

  return {
    displayList,
    updateDisplay,
  };
};
