import classNames from "classnames/bind";
import timeTableStyle from "../../../../styles/common/TimeTable.module.scss";
import React, {useEffect} from "react";
import _ from "lodash";
import styled from "styled-components";
import {IOoDoctor} from "api";
import {useDoctorDiagnosisTimeSettingHook} from "@components/infoForm";
import {WorkHourRow} from "@components/workHourRow";

interface Props {
    data: IOoDoctor,
    componentName: string,
}

export const DoctorDiagnosisTime = ({data, componentName}: Props) => {
    const tx = classNames.bind(timeTableStyle);
    const {
        initialData,
        editStatus,
        paramsData,
        setParamsData,
        handleTime,
        updateTimeDoctor,
    } = useDoctorDiagnosisTimeSettingHook(data, componentName);

    useEffect(() => {
        setParamsData(initialData);
    }, [initialData]);

    useEffect(() => {
        editStatus.isCancel && setParamsData(initialData);
    }, [editStatus.isCancel]);

    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            updateTimeDoctor({
                id: data.id || "",
                param: _.sortBy(paramsData, "dayOfWeek"),
            });
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <div className={tx("time-table-head")}>
                <div>요일</div>
                <div>진료시작</div>
                <div>진료종료</div>
                <div>휴무일여부</div>
            </div>
            {
                paramsData.map((item, index) => (
                    <WorkHourRow key={index}
                                 dayOfWeekNumber={item.dayOfWeek}
                                 hours={item.hours}
                                 breakHoursList={item.breakHoursList}
                                 isOff={item.isOff}
                                 onChange={handleTime}
                                 isEdit={editStatus.isEdit}
                                 isCancel={editStatus.isCancel}
                                 isSubmit={editStatus.isSubmit}
                    />
                ))
            }
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 16px;
`;
