import {IOoDiagnosis} from "@api/models";
import {
    filterMedicineDeliveryCancel,
    filterMedicinePackageDeliveryList,
    filterMedicinePaymentOkList,
    filterMedicinePaymentWaitList,
    filterMedicinePickupList,
    filterMedicinePickupWaitList,
    filterMedicineRequestList,
    filterMedicineTodayDeliveryList,
    filterReceiveList
} from "@api/models/pharmacy";


interface Props {
    list: Array<IOoDiagnosis>,
}

export const useDisplayPharmacyHook = ({list}: Props) => {
    // 전체 처방 접수
    const total = () => {
        return filterReceiveList(list);
    };

    // 약 조제 신청
    const decidedDeliveryType = () => {
        return filterMedicineRequestList(list);
    };

    // 약제비 결제 대기
    const medicineDeliveryPaymentWait = () => {
        return filterMedicinePaymentWaitList(list);
    };

    // 약제비 결제 완료
    const medicineDeliveryPaymentOk = () => {
        return filterMedicinePaymentOkList(list);
    };

    // 픽업 대기
    const pickupWait = () => {
        return filterMedicinePickupWaitList(list);
    };

    // 당일 배송 건
    const todayDelivery = () => {
        return filterMedicineTodayDeliveryList(list);
    };

    // 택배 건
    const pharmacyPackage = () => {
        return filterMedicinePackageDeliveryList(list);
    };

    // 방문 수령 건
    const pharmacyPickup = () => {
        return filterMedicinePickupList(list);
    };

    // 배송 취소
    const cancelDelivery = () => {
        return filterMedicineDeliveryCancel(list);
    };

    return {
        total,
        decidedDeliveryType,
        medicineDeliveryPaymentWait,
        medicineDeliveryPaymentOk,
        pickupWait,
        todayDelivery,
        pharmacyPackage,
        pharmacyPickup,
        cancelDelivery,
    }
}