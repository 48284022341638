import _ from "lodash";
import {IOoDiagnosis, isMedicineDeliveryPaySucceed, isMedicinePaySucceed, OoDiagnosisStatus,} from "@api/models";
import {PayStatusColor} from "@models/diagnosis/function/diagnosisPaymentFilter";

interface PayStatusData {
    title: string,
    color: PayStatusColor,
};


// 약국 약제비 결제 상태 정보
export function getPharmacyMedicinePayStatus(diagnosis: IOoDiagnosis): PayStatusData {
    // 약제비 없음
    const isZeroPay = _.isEqual(diagnosis.medicinePrice, 0);
    // 약제비를 입력하지 안았을 때,
    const isNoPay = !isZeroPay && (diagnosis.status < OoDiagnosisStatus.medicineDeliveryPaymentOk);
    // 약제비 결제 실패
    const isPayFail = !isMedicinePaySucceed(diagnosis);
    return getDeliveryPayStatus(diagnosis.medicinePrice || 0, isNoPay, isPayFail, isZeroPay, '약제비');
}

// 약국 배송비 결제 상태 정보
export function getPharmacyMedicineDeliveryPayStatus(diagnosis: IOoDiagnosis): PayStatusData {
    const isNoPay = diagnosis.status < OoDiagnosisStatus.medicineDeliveryPaymentOk;
    const isPayFail = !isMedicineDeliveryPaySucceed(diagnosis);
    const isZeroPay = _.isEqual(diagnosis.deliveryPrice, 0);
    return getDeliveryPayStatus(diagnosis.deliveryPrice || 0, isNoPay, isPayFail, isZeroPay, '배송비');
}

export function getDeliveryPayStatus(price: number,
                                     isNoPrice: boolean,
                                     isPayFail: boolean,
                                     isZeroPay: boolean,
                                     payString: string): PayStatusData {

    //약제비(배송비) 없음
    if (price > 0 && isNoPrice) {
        return {
            title: `${price}원 미결제`,
            color: PayStatusColor.default
        };
    }

    //결제전
    if (isNoPrice) {
        return {
            title: `${payString} 미입력`,
            color: PayStatusColor.pause
        };
    }

    //결제 실패
    if (isPayFail && !isZeroPay) {
        return {
            title: "결제 실패",
            color: PayStatusColor.fail
        };
    }

    return isZeroPay ? {
        title: `${payString} 없음`,
        color: PayStatusColor.fail
    } : {
        //결제 성공
        title: `${price}원 결제완료`,
        color: PayStatusColor.success
    };
}
