import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImageGallery from "react-image-gallery";
import {faX} from "@fortawesome/free-solid-svg-icons";
import {IOoDiagnosis, OoDiagnosisType, OoTelemedicineType} from "@models/diagnosis";
import {ModalOverlay} from "@components/modal";


interface ImageProps {
    original: string,
    thumbnail: string,
}

interface Props {
    data: IOoDiagnosis,
    ownerData: IOoDiagnosis,
}

export const PatientRequest = ({data, ownerData}: Props) => {
    const [imageSrc, setImageSrc] = useState<Array<ImageProps>>([]);
    const [galleryStartIndex, setGalleryStartIndex] = useState<number>(-1);

    const pictures = useMemo(() => {
        return data.symptomPictures;
    }, [data.symptomPictures]);

    useEffect(() => {
        const dataList = _.map(pictures, url => Object.assign({}, {
            original: url,
            thumbnail: url,
            originalHeight: 900,
            originalWidth: 1000
        }));

        if (_.isEmpty(dataList)) return;
        setImageSrc([...dataList]);
    }, [pictures]);

    const onImageClick = (e: any) => {
        const data = {original: e.target.src, thumbnail: e.target.src};
        const index = _.findIndex(imageSrc, img => _.isEqual(img.original, data.original));
        setGalleryStartIndex(index);
    };

    const handleInvisible = (e: any) => setGalleryStartIndex(-1);
    const isGalleryVisible = galleryStartIndex >= 0;

    return (
        <Wrapper>
            <ModalOverlay visible={isGalleryVisible}>
                <OverlayContainer>
                    <ButtonContainer>
                        <CloseButton onClick={handleInvisible}>
                            <FontAwesomeIcon icon={faX} size="2x"/>
                        </CloseButton>
                    </ButtonContainer>
                    <ImageContainer>
                        <ImageGallery items={imageSrc}
                                      showPlayButton={false}
                                      showFullscreenButton={false}
                                      startIndex={galleryStartIndex}/>
                    </ImageContainer>
                </OverlayContainer>
            </ModalOverlay>
            <div><b>진료 신청서</b></div>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>진료방식</td>
                    <td>
                        {
                            (_.isEqual(OoDiagnosisType.offline, ownerData.type) ||
                                _.isEqual(OoDiagnosisType.offlineReservation, ownerData.type)) ?
                                '방문' :
                                _.isEqual(OoTelemedicineType.video, ownerData.telemedicineType) ?
                                    '화상' : '전화'
                        }
                    </td>
                    <td>증상이미지</td>
                </tr>
                <tr>
                    <td>증상</td>
                    <td>
                        <SymptomTextArea readOnly={true}
                                         value={`${data.symptom}`}/>
                    </td>
                    <td>
                        <Container>
                            {
                                !_.isEmpty(imageSrc) ?
                                    imageSrc.map((item, index) =>
                                        <div className='img-div' key={index}>
                                            <ShowImg key={index}
                                                     src={`${item.original}`}
                                                     onClick={onImageClick}/>
                                        </div>
                                    )
                                    : <ImgNone>없음</ImgNone>
                            }
                        </Container>
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-top: 48px;
`;

const Table = styled.table`
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: 100%;
  height: 145px;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #C8D6F8;

    &:nth-child(1) {
      & > td {
        &:nth-child(3) {
          text-align: left;
          padding: 10px;
          width: 490px;
          height: 48px;
        }
      }
    }

    &:nth-child(2) {
      & > td {
        &:nth-child(1) > b {
          display: flex;
          justify-content: center;
          height: 100%;
          padding-top: 12px;
        }

        &:nth-child(2) {
          display: flex;
          justify-content: flex-start;
          height: 95px;
          width: 100%;
        }

        &:nth-child(3) {
          background: #FFFFFF;
          text-align: left;
        }
      }
    }

    & > td {
      &:nth-child(2n-1) {
        background: #F4F6FB;
        text-align: center;
        width: 100px;
        font-weight: 700;
        font-size: 13px;
      }

      &:nth-child(2n) {
        padding: 10px;
        width: 35%;
      }

      &:nth-child(2) {
        border-right: 1px solid #C8D6F8;
      }
    }
  }
`;

const SymptomTextArea = styled.textarea`
  border: none;
  width: 100%;
  resize: none;

  &:focus-visible {
    outline: none;
  }
`;

const Container = styled.div`
  overflow: auto;
  height: 95px;
  display: flex;
  flex-direction: row;

  .img-div {
    width: 20%;
    height: 100%;
  }
`;

const ImgNone = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  padding: 10px;
  font-weight: 500;
`;

const ShowImg = styled.img`
  width: 100%;
  height: 100%;
`;

const OverlayContainer = styled.div`
  display: flex;
`;

const ImageContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  color: #FFFFFF;
  display: flex;
  width: fit-content;
`;