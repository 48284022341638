import React from "react";
import styled from "styled-components";

interface FlexProps {
  flex: number;
}

export const ListTitle = () => {
  return (
    <Card>
      <Title flex={1}>주문일자</Title>
      <Title flex={0.5}>구매 경로</Title>
      <Title flex={0.5}>구매지</Title>
      <Title flex={0.9}>수령인</Title>
      <Title flex={1}>전화번호</Title>
      <Title flex={1.5}>배송지</Title>
      <Title flex={1}>업체명</Title>
      <Title flex={1.5}>주문제품</Title>
    </Card>
  );
};

const Card = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  background: #ffffff;
  border: 0.5px solid #c2c1bd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  color: #949390;
  font-weight: 500;
  font-size: 12px;
`;

const Title = styled.div<FlexProps>`
  flex: ${(props) => props.flex};
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
