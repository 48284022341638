import {IOoReservationHour, OoServiceType} from "@api/models";
import {AbstractOoParam} from "../base";
import {
    DoctorAdditionalInfo,
    DoctorAlimTalkInfo,
    DoctorBankInfo,
    DoctorBasicInfo,
    DoctorCareerInfo,
    DoctorLicenseInfo,
    DoctorServiceInfo,
    DoctorThemeInfo,
    DoctorVideoInfo,
    DoctorWorkHourInfo
} from "interfaces";
import {IOoWorkHours} from "@models/pharmacy";


class OoParamDoctorCreate extends AbstractOoParam {
    //병원 아이디
    public id:string = "";
    //이메일
    public email: string = '';

    //패스워드
    public password: string = '';

    //병원 아이디
    public hospitalId: string = '';

    //이름
    public name: string = '';

    //성별 (male, female)
    public gender: string = '';

    //전화번호
    public tel?: string = undefined;

    //정산여부
    public isCalculateAccount: boolean = false;

    //핸드폰
    public mobile: string = '';

    //소개
    public bio?: string = '';

    //학력
    public education?: string = '';

    //경력
    public career?: string = '';

    //라이센스 번호
    public license: string = '';

    //서비스 타입 (b2c 노출 여부)
    public serviceType: number = OoServiceType.o2o;

    //진료과
    public departments: Array<string> = [];

    //진료테마
    public theme?: Array<string> = [];

    //사진
    public pictures: Array<string> = [];

    //화상 진료 가능 여부
    public isVideoDiagnosis?: boolean = false;

    //알림톡 수신 번호/
    public alimTalkReceivers: Array<string> = [];

    //정산 은행명
    public bank?: string = '';

    //계좌번호
    public bankAccount?: string = '';

    //예금주
    public bankHolderName?: string = '';

    //진료시간
    public doctorHours?: Array<IOoWorkHours> = [];

    //비디오 링크
    public videoLink?: string = '';

    //비디오 아이디
    public videoId?: string = '';

    //비디오 패스워드
    public videoPassword?: string = '';

    //예약 기능 노출
    public isUseReservation?: boolean = false;

    //당일 예약 허용
    public isAllowTodayReservation?: boolean = false;

    //예약 가능 인원
    public reservationHeadCount?: number = 2;

    //예약 가능 시간대
    public reservationTime?: Array<string> = [];

    //예약 시간
    public reservationHours?: Array<IOoReservationHour> = [];

    //지역 검색 키워드
    public regionKeywordList?: Array<string> = [];

    createBasicInfo(data: DoctorBasicInfo) {
        this.email = data.email;
        this.password = data.password;
        this.name = data.name;
        this.mobile = data.mobile;
        this.pictures = data.pictures;
        this.isCalculateAccount = data.isCalculateAccount;
    };

    createAdditionalInfo(data: DoctorAdditionalInfo) {
        this.hospitalId = data.hospitalId;
        this.gender = data.gender;
        this.serviceType = data.serviceType;
    };

    createAlimTalk(data: DoctorAlimTalkInfo) {
        this.alimTalkReceivers = data.alimTalkReceivers;
    };

    createCareerInfo(data: DoctorCareerInfo) {
        this.education = data.education;
        this.career = data.career;
        this.bio = data.bio;
    };

    createServiceInfo(data: DoctorServiceInfo) {
        this.departments = data.departments;
        this.theme = data.theme;
    };

    // todo 라이센스 사진 등록 파라미터 없음
    createLicenseInfo(data: DoctorLicenseInfo) {
        this.license = data.license;
    };

    createTheme(data: DoctorThemeInfo) {
        this.theme = data.theme;
    };

    createBankInfo(data: DoctorBankInfo) {
        this.bank = data.bank;
        this.bankAccount = data.bankAccount;
        this.bankHolderName = data.bankHolderName;
    };

    createVideoInfo(data: DoctorVideoInfo) {
        this.isVideoDiagnosis = data.isVideoDiagnosis;
        this.videoId = data.videoId;
        this.videoLink = data.videoLink;
        this.videoPassword = data.videoPassword;
    };

    createDoctorWorkHourInfo(data: DoctorWorkHourInfo) {
        this.doctorHours = data.doctorHours;
    }

}

export {
    OoParamDoctorCreate
};