import {useRecoilState, useResetRecoilState} from "recoil";
import {telemedicineUpdateSelector} from "@recoils/doctor";
import {useMemo} from "react";
import _ from "lodash";
import {IOoDiagnosis} from "@api/models";

export const useDoctorDetailContainerSettingHook = (data: IOoDiagnosis) => {
    const [telemedicineUpdateParamList, setTelemedicineUpdateParamList] = useRecoilState(telemedicineUpdateSelector);
    const resetTelemedicineUpdateParamList = useResetRecoilState(telemedicineUpdateSelector);

    const index = useMemo(() => {
        return telemedicineUpdateParamList.findIndex((item) => _.isEqual(item.id, data.id || ""));
    }, [data]);

    const onChangePrescription = (value: Array<string>) => {
        const copied = _.cloneDeep(telemedicineUpdateParamList);
        copied[index].prescription = value;
        setTelemedicineUpdateParamList(copied);
    };

    const onChangeInfo = (value: string) => {
        const copied = _.cloneDeep(telemedicineUpdateParamList);
        copied[index].comment = value;
        setTelemedicineUpdateParamList(copied);
    };

    const onChangePay = (price: string, hospitalMedicinePrice: string, isHospitalDelivery: boolean, hospitalMedicineType: number) => {
        const copied = _.cloneDeep(telemedicineUpdateParamList);
        copied[index].price = _.toNumber(price);
        copied[index].hospitalMedicinePrice = _.toNumber(hospitalMedicinePrice);
        copied[index].isHospitalDelivery = isHospitalDelivery;
        copied[index].hospitalMedicineType = hospitalMedicineType;
        setTelemedicineUpdateParamList(copied);
    };

    return {
        telemedicineUpdateParamList,
        setTelemedicineUpdateParamList,
        resetTelemedicineUpdateParamList,
        index,
        onChangePrescription,
        onChangeInfo,
        onChangePay,
    }

}