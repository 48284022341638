import {AbstractOoParam} from "../base";

class OoUnospayChangeOrderPlatform implements AbstractOoParam {
    // 주문자 식별 id
    id: string = '';

    // 주문 플랫폼
    orderPlatform: number = 0;

    getPathParams(): string {
        return "";
    }
}


export {OoUnospayChangeOrderPlatform};
