import React, {useEffect} from "react";
import styled from "styled-components";
import _ from "lodash";
import {useDoctorAlimTalkSettingHook} from "@components/infoForm";
import {IOoDoctor} from "api";
import {OoParamDoctorUpdate} from "@params/doctor";
import {CustomInputText} from "@components/custom";

interface Props {
    data: IOoDoctor,
    componentName: string,

}

export const DoctorAlimTalk = ({data, componentName}: Props) => {
    const {
        editStatus,
        color,
        setColor,
        items,
        setItems,
        handleChange,
        updateDoctor,
        isReset,
        initialData,
    } = useDoctorAlimTalkSettingHook(data, componentName);

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        setItems(initialData);
    }, [data]);

    useEffect(() => {
        if (editStatus.isEdit) {
            setColor("blue");
        } else {
            setColor("grey");
            setItems(initialData);
        }
    }, [editStatus.isEdit]);

    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            let alimArr = [items.alimtalk1, items.alimtalk2, items.alimtalk3];
            const param = new OoParamDoctorUpdate();
            param.id = data.id || "";
            param.alimTalkReceivers = alimArr;
            updateDoctor(param);
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>
                        <CustomInputText value={items.alimtalk1}
                                         name="alimtalk1"
                                         onChanged={handleChange}
                                         disabled={editStatus.isEdit}
                                         color={color}
                                         size="large"
                                         placeholder="알림톡 수신번호를 입력하세요."/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <CustomInputText value={items.alimtalk2}
                                         name="alimtalk2"
                                         onChanged={handleChange}
                                         disabled={editStatus.isEdit}
                                         color={color}
                                         size="large"
                                         placeholder="알림톡 수신번호를 입력하세요."/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <CustomInputText value={items.alimtalk3}
                                         name="alimtalk3"
                                         onChanged={handleChange}
                                         disabled={editStatus.isEdit}
                                         color={color}
                                         size="large"
                                         placeholder="알림톡 수신번호를 입력하세요."/>
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    )
}


const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;

const Table = styled.table`
  & tbody tr td {
    padding-bottom: 5px;
  }
`;

