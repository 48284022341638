import {useSetRecoilState} from "recoil";
import {useQuery} from "react-query";
import _ from "lodash";
import {toast} from "react-toastify";
import {useSuspenseHook} from "@hooks/suspense";
import {doctorSearchParamState} from "@recoils/doctor";
import {OoParamDoctorPatientRecord} from "@params/familyTelemedicine/paramDoctorSearch";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";
import {searchListState} from "@recoils/search";

interface Props {
    searchName: string,
    doctorId: string,
    searchQueryTrigger: boolean,
    target: string,
}

export const useDoctorSearchPatientQuery = ({searchName, doctorId, searchQueryTrigger, target}: Props) => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const setSearchList = useSetRecoilState(searchListState(target));
    const setDoctorSearchParam = useSetRecoilState(doctorSearchParamState);

    return useQuery(
        'doctor/patientSearch',
        async () => {
            try {
                setLoading();
                const param = new OoParamDoctorPatientRecord(searchName, doctorId);
                const response = await DoctorOnAPI.shared.familyTelemedicine.patientNameRecordDoctor(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    if (_.isEqual(response.data?.length, 0)) {
                        toast.error(searchName + '으로 검색된 결과가 없습니다.');
                        return;
                    }
                    setSearchList({id: target, userName: searchName, list: response.data || []});
                    setDoctorSearchParam({searchName: searchName, doctorId: doctorId});
                    return response.data;
                }
                return null;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: searchQueryTrigger,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )
};