import {DiagnosisComment} from "../diagnosisComment";
import {PatientInfo} from "../patientInfo";
import {DiagnosisPay} from "../diagnosisPay";
import {IOoDiagnosis, OoDiagnosisStatus, OoWapleReservationStatus} from "@api/models";
import React from "react";
import styled from "styled-components";
import {ReservationInfo} from "../reservationInfo";
import {DisplayPrescription} from "../displayPrescription";
import {DisplayDiagnosisInfo} from "../displayDiagnosisInfo";
import {getDiagnosisDetailInfo} from "@models/diagnosis";
import {useFilteredReservationStatus} from "@models/reservation";
import _ from "lodash";
import {PatientRequest} from "../patientRequest";
import {DiagnosisCancel} from "@components/diagnosisCancelTable";
import {
    useDoctorDetailContainerSettingHook
} from "../../../pages/Doctor/DoctorDiagnosisDetailPage/hooks/useDoctorDetailContainerSettingHook";
import {Prescription} from "@components/diagnosisDetailComponents/prescription";

export interface Updatable {
    id: string,
    prescription: Array<string>,
    price: number,
    hospitalMedicinePrice: number,
    comment: string,
    isHospitalDelivery: boolean
}

export interface UploadFiles {
    id: string,
    uploadFiles: Array<File>,
}

interface Props {
    data: IOoDiagnosis,
    list: Array<IOoDiagnosis>,
}

export const DoctorDetailContainer = ({data, list}: Props) => {
    const {isReservation} = useFilteredReservationStatus(data);
    const {
        index,
        telemedicineUpdateParamList,
        onChangePrescription,
        onChangeInfo,
        onChangePay
    } = useDoctorDetailContainerSettingHook(data);

    const filteredCancelStatus = () => {
        const predicate1 = _.gte(data.status, OoDiagnosisStatus.doctorCancel);
        const predicate2 = _.lte(data.status, OoDiagnosisStatus.pharmacistCancel);
        return predicate1 && predicate2;
    };

    const filteredDisplayStatus = () => {
        const predicate1 = _.gte(data.wapleReservationStatus ?? OoWapleReservationStatus.unknown, OoWapleReservationStatus.receiptCancelBySelf);
        const predicate2 = _.lte(data.wapleReservationStatus ?? OoWapleReservationStatus.unknown, OoWapleReservationStatus.visitConfirm);
        const predicate3 = _.gte(data.wapleReservationStatus ?? OoWapleReservationStatus.unknown, OoWapleReservationStatus.underTreatment);
        return predicate1 && predicate2 || predicate3;
    };

    return (
        <Wrapper>
            {
                filteredCancelStatus() &&
                <DiagnosisCancel data={data}/>
            }
            <ReservationInfo data={data}/>
            <PatientInfo data={data}/>
            <PatientRequest data={data} ownerData={_.head(list)!}/>
            {
                isReservation() ?
                    <>
                        {
                            filteredDisplayStatus() ?
                                <>
                                    <DisplayPrescription data={data}/>
                                    <DisplayDiagnosisInfo status={getDiagnosisDetailInfo(data)}/>
                                </>
                                :
                                <>
                                    <Prescription data={data}
                                                  prescription={telemedicineUpdateParamList[index].prescription || []}
                                                  onChange={onChangePrescription}/>
                                    <DiagnosisComment comment={telemedicineUpdateParamList[index].comment || ""}
                                                      onChange={onChangeInfo}/>
                                    <DiagnosisPay data={data}
                                                  param={telemedicineUpdateParamList[index]}
                                                  onChange={onChangePay}/>
                                </>
                        }
                    </>
                    :
                    <>
                        {
                            data.status > OoDiagnosisStatus.accept ?
                                <>
                                    <DisplayPrescription data={data}/>
                                    <DisplayDiagnosisInfo status={getDiagnosisDetailInfo(data)}/>
                                </>
                                :
                                <>
                                    <Prescription data={data}
                                                  prescription={telemedicineUpdateParamList[index].prescription || []}
                                                  onChange={onChangePrescription}/>
                                    <DiagnosisComment comment={telemedicineUpdateParamList[index].comment || ""}
                                                      onChange={onChangeInfo}/>
                                    <DiagnosisPay data={data}
                                                  param={telemedicineUpdateParamList[index]}
                                                  onChange={onChangePay}/>
                                </>
                        }
                    </>

            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  padding: 2%;
`;
