import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useSuspenseHook } from "@hooks/suspense";
import { DoctorOnAPI } from "@request/doctorOnAPI";
import { OoParamId } from "@params/common";
import { isRequestSucceed } from "../../api";
import { CancelOrderComment } from "../../pages";
import { OoUnospayCancelOrderComment } from "@params/unospay/cancelOrderComment";

export const useCancelOrderUnospayQuery = () => {
  const { setLoading, setStopLoading } = useSuspenseHook();
  return useMutation(
    "unospay/cancelOrderUnospay",
    async (param: CancelOrderComment) => {
      try {
        setLoading();
        const response = await DoctorOnAPI.shared.unospay.cancelOrder(
          new OoUnospayCancelOrderComment(param.id, param.comment)
        );
        setStopLoading();
        if (isRequestSucceed(response)) {
          toast.success("주문이 취소되었습니다.");
          return response.data;
        }
        return undefined;
      } catch (e: any) {
        throw e;
      }
    }
  );
};
