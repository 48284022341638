import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import InputStyles from "../../../../../styles/common/Input.module.scss";
import SelectStyles from "../../../../../styles/common/Select.module.scss";
import _ from "lodash";
import { EditInfo } from "@recoils/status";
import { IOoUnospayTransportInfo, OoTransportOption } from "@models/unospay";
import {
  checkedListAtom,
  checkedListState,
} from "@recoils/unospay/checkedListState";
import { CustomInputText } from "@components/custom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { isDisabled } from "@testing-library/user-event/dist/utils";

interface Props {
  data: IOoUnospayTransportInfo;
  editStatus: EditInfo;
  isChecked?: boolean;
}

export const TransportRow = ({ data, editStatus, isChecked }: Props) => {
  const ix = classNames.bind(InputStyles);
  const sx = classNames.bind(SelectStyles);

  const TransportOptions = [
    { key: -1, value: "배송방법" },
    { key: 0, value: "일일배송" },
    { key: 1, value: "격일배송" },
    { key: 2, value: "택배" },
  ];

  const [selectTransportOption, setSelectTransportOption] = useState(
    getTransportOptionsValue(isValidOptionData(data.transportOption!))
  );

  function isValidOptionData(val: number) {
    return _.isUndefined(val) ? -1 : val;
  }

  function getTransportOptionsValue(key: number): string {
    switch (key) {
      case OoTransportOption.DAILY_DELIVERY:
        return "일일배송";
      case OoTransportOption.TWODAYS_DELIVERY:
        return "격일배송";
      case OoTransportOption.PACKAGE_DELIVERY:
        return "택배";
      case OoTransportOption.EARLY_DELIVERY:
        return "새벽배송";
      default:
        return "배송방법";
    }
  }

  function getTransportOptionsKey(value: string): number {
    switch (value) {
      case "일일배송":
        return OoTransportOption.DAILY_DELIVERY;
      case "격일배송":
        return OoTransportOption.TWODAYS_DELIVERY;
      case "택배":
        return OoTransportOption.PACKAGE_DELIVERY;
      case "새벽배송":
        return OoTransportOption.EARLY_DELIVERY;
      default:
        return -1;
    }
  }

  const onSelectTransportOptions = (e: any) => {
    const selectedOption = e.currentTarget.value;
    setSelectTransportOption(selectedOption);
  };

  const isPackage = selectTransportOption == "택배";
  const isDisableInput = !isPackage;

  // isDisalbed: 택배가 아닐 때
  const [checkedList, setCheckedList] = useRecoilState(
    checkedListState({ code: data.externalCode, orderNum: data.orderNum })
  );

  const [transportTextFieldShow, setTransportTextFieldShow] =
    useState<boolean>(true);
  const [color, setColor] = useState("white");

  const [selectTransportName, setSelectTransportName] = useState(
    data.selectedTransportName || "택배사선택"
  );

  const [selectTrackingNumber, setSelectTrackingNumber] = useState(
    data.trackingNumber || ""
  );

  useEffect(() => {
    setCheckedList({
      checked: false,
      isDisabled: isDisableInput,
      transport: {
        externalCode: data.externalCode,
        providerName: data.providerName,
        displayTransportNameList: data.displayTransportNameList,
        selectedTransportName: data.selectedTransportName,
        trackingNumber: data.trackingNumber,
        transportOption: getTransportOptionsKey(selectTransportOption),
        menuCode: data.menuCode,
        menuName: data.menuName,
        orderNum: data.orderNum,
      },
    });

    setSelectTransportName(data.selectedTransportName || "택배사선택");
    setSelectTrackingNumber(data.trackingNumber || "");
  }, [data]);

  const handleItem = (e: any) => {
    const { name, value } = e.target;
    const copied = _.cloneDeep(checkedList);

    switch (name) {
      case "externalCode":
        copied.transport.externalCode = data.externalCode;
        break;
      case "providerName":
        copied.transport.providerName = value;
        break;
      case "displayTransportNameList":
        copied.transport.providerName = value;
        break;
      case "selectedTransportName":
        copied.transport.selectedTransportName = selectTransportName;
        break;
      case "trackingNumber":
        copied.transport.trackingNumber = value;
        break;
      // case "menuCode":
      //     copied.transport.menuCode = value;
      //     break;
      case "transportOption":
        copied.transport.transportOption = getTransportOptionsKey(
          selectTransportOption
        );
        break;
    }

    setCheckedList(copied);
  };

  const onSelectTransportCompanyName = (e: any) => {
    const { value } = e.target;
    setSelectTransportName(value);
  };

  useEffect(() => {
    const transportInfo: IOoUnospayTransportInfo = {
      externalCode: data.externalCode,
      providerName: data.providerName,
      displayTransportNameList: data.displayTransportNameList,
      transportOption: getTransportOptionsKey(selectTransportOption),
      selectedTransportName: selectTransportName,
      trackingNumber: data.trackingNumber,
      menuCode: data.menuCode,
      menuName: data.menuName,
      orderNum: data.orderNum,
    };

    setCheckedList({ ...checkedList, transport: transportInfo });
  }, [selectTransportName]);

  useEffect(() => {
    const transportInfo: IOoUnospayTransportInfo = {
      externalCode: data.externalCode,
      providerName: data.providerName,
      displayTransportNameList: data.displayTransportNameList,
      transportOption: getTransportOptionsKey(selectTransportOption),
      selectedTransportName: selectTransportName,
      trackingNumber: data.trackingNumber,
      menuCode: data.menuCode,
      menuName: data.menuName,
      orderNum: data.orderNum,
    };
    setCheckedList({
      ...checkedList,
      checked: checkedList.checked,
      transport: transportInfo,
    });
  }, [checkedList.checked]);

  useEffect(() => {
    const isNotPackage =
      selectTransportOption !==
      getTransportOptionsValue(OoTransportOption.PACKAGE_DELIVERY);
    setCheckedList({
      checked: true,
      isDisabled: isDisableInput,
      transport: {
        externalCode: data.externalCode,
        providerName: data.providerName,
        displayTransportNameList: data.displayTransportNameList,
        selectedTransportName: isNotPackage ? "" : data.selectedTransportName,
        trackingNumber: isNotPackage ? "" : data.trackingNumber,
        transportOption: getTransportOptionsKey(selectTransportOption),
        menuCode: data.menuCode,
        menuName: data.menuName,
        orderNum: data.orderNum,
      },
    });
  }, [selectTransportOption]);

  useEffect(() => {
    checkedList.checked &&
    checkedList.transport.transportOption == OoTransportOption.PACKAGE_DELIVERY
      ? setColor("blue")
      : setColor("grey");
    if (!editStatus.isEdit) {
      setSelectTransportName(data.selectedTransportName || "");
      setCheckedList({
        ...checkedList,
        checked: false,
        isDisabled:
          checkedList.transport.transportOption !=
          OoTransportOption.PACKAGE_DELIVERY,
      });
    }
  }, [
    checkedList.checked,
    checkedList.transport.transportOption,
    editStatus.isEdit,
  ]);

  useEffect(() => {
    if (editStatus.isCancel) {
      setSelectTransportOption(getTransportOptionsValue(data.transportOption!));
      setSelectTransportName(data.selectedTransportName!);
      setSelectTrackingNumber(data.trackingNumber!);
      setCheckedList({ ...checkedList, checked: false });
    }
  }, [
    checkedList.checked,
    checkedList.transport.transportOption,
    editStatus.isEdit,
  ]);

  const handleCheckedChange = (e: any) => {
    const transportInfo: IOoUnospayTransportInfo = {
      externalCode: data.externalCode,
      providerName: data.providerName,
      displayTransportNameList: data.displayTransportNameList,
      transportOption: getTransportOptionsKey(selectTransportOption),
      selectedTransportName: selectTransportName,
      trackingNumber: data.trackingNumber,
      menuCode: data.menuCode,
      menuName: data.menuName,
      orderNum: data.orderNum,
    };

    setCheckedList({
      checked: !checkedList.checked,
      isDisabled: isDisableInput,
      transport: transportInfo,
    });
  };

  return (
    <tr key={data.externalCode}>
      <td>
        <input
          type="checkbox"
          checked={checkedList.checked}
          onChange={handleCheckedChange}
          value={data.externalCode}
          disabled={!editStatus.isEdit}
        />
      </td>
      <td>제조회사</td>
      <td>
        <CustomInputText
          size="unosInput"
          color="white"
          onChanged={handleItem}
          value={data.providerName}
          disabled={false}
          title={data.menuName}
        />
      </td>
      <td>배송방법</td>
      <td>
        {checkedList.checked ? (
          <>
            <select
              className={sx("select", "unospay", "blue")}
              value={selectTransportOption}
              onChange={onSelectTransportOptions}
            >
              {TransportOptions.map((item, index) => (
                <option value={item.value}>{item.value}</option>
              ))}
            </select>
            <input
              type="text"
              hidden={transportTextFieldShow}
              name="selectedTransportOption"
              value={selectTransportOption}
              onChange={handleItem}
              className={ix("Input", "unosInput", color)}
            />
          </>
        ) : (
          <CustomInputText
            size="unosInput"
            color={color}
            name="selectedTransportOption"
            onChanged={handleItem}
            value={selectTransportOption || "-"}
            disabled={checkedList.checked}
          />
        )}
      </td>
      <td>택배사</td>
      <td>
        {
          // "택배"인 경우
          // 1. 체크 시
          checkedList.checked &&
          checkedList.transport.transportOption ==
            OoTransportOption.PACKAGE_DELIVERY ? (
            <>
              <select
                className={sx("select", "unospay", "blue")}
                value={selectTransportName}
                onChange={onSelectTransportCompanyName}
              >
                <option value="택배사선택">택배사선택</option>
                {data.displayTransportNameList.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
                <option value="직접입력">직접입력</option>
              </select>
              <input
                type="text"
                hidden={transportTextFieldShow}
                name="selectedTransportName"
                value={checkedList.transport.selectedTransportName || ""}
                onChange={handleItem}
                className={ix("Input", "unosInput", color)}
              />
            </>
          ) : (
            // 2. 체크 X
            <CustomInputText
              size="unosInput"
              color={color}
              name="selectedTransportName"
              onChanged={handleItem}
              value={checkedList.transport.selectedTransportName || "-"}
              disabled={
                checkedList.checked &&
                checkedList.transport.transportOption ==
                  OoTransportOption.PACKAGE_DELIVERY
              }
            />
          )
        }
      </td>
      <td>운송장번호</td>
      <td>
        {
          // "택배"인 경우
          // 1. 체크 시
          checkedList.checked &&
          checkedList.transport.transportOption ==
            OoTransportOption.PACKAGE_DELIVERY ? (
            <CustomInputText
              size="trackingNumber"
              color={color}
              value={checkedList.transport.trackingNumber || ""}
              disabled={checkedList.checked}
              name="trackingNumber"
              onChanged={handleItem}
            />
          ) : (
            // 2.체크 X
            <CustomInputText
              size="trackingNumber"
              color={color}
              name="trackingNumber"
              value={checkedList.transport.trackingNumber || "-"}
              onChanged={handleItem}
              disabled={
                checkedList.checked &&
                checkedList.transport.transportOption ==
                  OoTransportOption.PACKAGE_DELIVERY
              }
            />
          )
        }
      </td>
    </tr>
  );
};
