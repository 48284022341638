import {editSelectorFamily} from "@recoils/status";
import {useResetRecoilState, useSetRecoilState} from "recoil";

export const useCardEdit = (editId: string) => {
    const setEdit = useSetRecoilState(editSelectorFamily(editId));
    const resetEdit = useResetRecoilState(editSelectorFamily(editId));

    const isEdit = () => {
        setEdit((cur) => ({...cur, isEdit: true, isCancel: false, isSubmit: false}));
    };

    const isSubmit = () => {
        setEdit((cur) => ({...cur, isSubmit: true, isEdit: false, isCancel: true}));
    };

    const isReset = () => {
        resetEdit();
    };

    return {isEdit, isSubmit, isReset}
};