import moment from "moment";

export function formatDateYYYYMDHHmm(date: number) {
  return moment(date).format("YYYY년 M월 D일 (dd) HH:mm");
}

export function formatDateMDhhmm(date: number) {
  return moment(date).format("M월 D일 (dd) HH:mm");
}

export function formatDateYyyyMMDHHmmss(date: number) {
  return moment(date).format("yyyyMMDDHHmmss");
}

export function formatHHMM(date: number) {
  return moment(date).format("HH:mm");
}

export function formatDateUsingDash(date: number) {
  moment().locale();
  return moment(date).format("YYYY-MM-DD");
}

export function formatYyyyMMDHH(date: Date) {
  return moment(date).format("yyyyMMDD");
}

export function formatMMDDEE(date: string) {
  return moment(date).format("M월 D일 (dd)");
}

export function formatYyyyMMDD(date: string) {
  return moment(date).format("YYYY.MM.DD");
}
