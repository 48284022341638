import React, {useEffect} from "react";
import {IOoDiagnosis} from "@api/models";
import {PdfViewer} from "@components/pdfViewer";
import _ from "lodash";
import styled from "styled-components";
import {BeforeAttachFileTable} from "./beforeAttachFileTable";
import {AfterAttachFileTable} from "./afterAttachFileTable";
import {PdfUrlType} from "@recoils/common";
import {
    usePrescriptionsSettingHook
} from "../../../pages/Doctor/DoctorDiagnosisDetailPage/hooks/usePrescriptionsSettingHook";

interface Props {
    data: IOoDiagnosis,
    prescription: Array<string>,
    onChange: (value: Array<string>) => void,
}

export const Prescription = ({data, onChange, prescription}: Props) => {
    const {
        uploadFile,
        pdfUrl,
        pdfUrlArr,
        setPdfUrlArr,
        onFileUpload,
        onHandleRemove,
        onClickHref,
        getUrl,
    } = usePrescriptionsSettingHook(data);

    useEffect(() => {
        onChange(pdfUrlArr.list);
    }, [pdfUrlArr]);

    useEffect(() => {
        getUrl();
    }, [uploadFile]);

    useEffect(() => {
        const pdfUrl: PdfUrlType = {
            id: data.id || "",
            list: prescription,
        };
        setPdfUrlArr(pdfUrl);
    }, []);

    return (
        <>
            <PdfViewer pdfUrl={pdfUrl}/>
            <Container>
                <div className="title">처방전</div>
                {
                    _.isEqual(pdfUrlArr.list.length, 0)
                        ? <BeforeAttachFileTable onFileUpload={onFileUpload}/>
                        : <AfterAttachFileTable onFileUpload={onFileUpload}
                                                onClickFileHref={onClickHref}
                                                onFileRemove={onHandleRemove}
                                                data={data}
                                                uploadFile={uploadFile}
                                                pdfUrlArr={pdfUrlArr.list}
                        />
                }
            </Container>
        </>
    )
}

const Container = styled.div`
  margin-top: 48px;

  .title {
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 7px;
  }

`;