import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useSuspenseHook } from "@hooks/suspense";
import { ItemType } from "../../pages/Operator/UnospayOrderDetailPage/components/DeliveryInfo";
import { OoUnospayUpdateTransportInfo } from "@params/unospay/updateTransportInfo";
import { DoctorOnAPI } from "@request/doctorOnAPI";
import { isRequestSucceed } from "../../api";

export const useUpdateTransportQuery = () => {
  const { setLoading, setStopLoading } = useSuspenseHook();
  return useMutation("unospay/updateTransport", async (data: ItemType) => {
    try {
      setLoading();
      const param = new OoUnospayUpdateTransportInfo();
      param.id = data.id;
      param.transportInfo = data.transportList;
      const response = await DoctorOnAPI.shared.unospay.updateTransportInfo(
        param
      );
      setStopLoading();
      if (isRequestSucceed(response)) {
        toast.success("배송 정보 수정이 완료되었습니다.");
        return response.data;
      }
      return undefined;
    } catch (e: any) {
      throw e;
    }
  });
};
