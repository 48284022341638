import React, {useEffect, useState} from "react";
import styles from '@styles/diagnosis/Prescription.module.scss';
import classNames from "classnames/bind";
import _, {findIndex} from "lodash";
import styled from "styled-components";

interface Props {
    onClickUrl?: (e: any) => void,
    uploadFiles: Array<string>,
    patientName?: string,
    onCheckedFile?: (arr: Array<string>) => void,
    checkBoxHidden: boolean,
}


export const FilesReadTable = ({onClickUrl, uploadFiles, patientName, onCheckedFile, checkBoxHidden}: Props) => {
    const cx = classNames.bind(styles);

    const [checkedClassName, setCheckedClassName] = useState('filesContents-div');
    const [checkedArr, setCheckedArr] = useState<Array<string>>([]);
    const [hidden, setHidden] = useState<boolean>(false);

    useEffect(() => {
        setHidden(checkBoxHidden);
    }, [checkBoxHidden]);

    useEffect(() => {
        !_.isUndefined(onCheckedFile) && onCheckedFile(checkedArr);
    }, [checkedArr]);

    const onChecked = (e: any) => {
        const copyArr = _.cloneDeep(checkedArr);
        if (e.target.checked) {
            setCheckedClassName('filesContainer-checked-div');
            copyArr.push(e.target.value);
            setCheckedArr(copyArr);
        } else {
            const index = findIndex(copyArr, (item) => _.isEqual(item, e.target.value));
            if (!_.lt(index, 0)) {
                copyArr.splice(index, 1);
                setCheckedArr(copyArr);
            }
            setCheckedClassName('filesContents-div');
        }
    };

    return (
        <>
            {
                !_.isUndefined(patientName) &&
                <Table>
                    <thead>
                    <tr>
                        <th>
                            <div>파일첨부</div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <div className={cx('filesContainer-div')}>
                                {
                                    uploadFiles.map((item: string, index: number) => (
                                        <div className={cx(`${checkedClassName}`)} key={index}>
                                            <div className={cx('filesName-div')}>
                                                {
                                                    hidden ?
                                                        <img src={process.env.PUBLIC_URL + '/img/pdf-icon.png'}
                                                             alt="pdf"
                                                             className={cx('custom-download-icon')}/>
                                                        :
                                                        <input type="checkbox"
                                                               className={cx('checkbox')}
                                                               value={item}
                                                               onClick={onChecked}
                                                               hidden={hidden}/>
                                                }
                                                <div onClick={onClickUrl}
                                                     id={item}
                                                     className={cx('prescriptionLink-a')}>
                                                    {patientName}_처방전.pdf
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            }
        </>
    )
}

const Table = styled.table`
  margin-top: 8px;
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: 100%;
  height: auto;
  font-size: 13px;
  font-weight: 700;

  & tr {
    th {
      width: 100%;
      height: 38px;
      border-bottom: 1px solid #C8D6F8;
      background: #F4F6FB;

      & > div {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 13px;
        margin-left: 14px;
        color: #030303;
      }
    }
  }
`;