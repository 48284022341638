import {IOoWorkHours} from "@api/models/pharmacy";
import {AbstractOoParam} from "../base";

class OoParamPharmacyHours extends AbstractOoParam {
    public readonly id: string;
    public readonly pharmacyHours: Array<IOoWorkHours>;

    constructor(id: string, pharmacyHours: Array<IOoWorkHours>) {
        super();
        this.id = id;
        this.pharmacyHours = pharmacyHours;
    }
}

export {
    OoParamPharmacyHours
}