import styled, {css} from "styled-components";
import _ from "lodash";

export const Wrapper = styled.button`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

export const Card = styled.div`
  width: 556px;
  min-height: 240px;
  height: auto;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 20px;
  width: 100%;
`;

export const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > div:nth-child(1) {
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    padding-bottom: 36px;
  }
`;

export const ButtonContainer = styled.div<{ align?: string, flexDirection?: string }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => _.isEmpty(props.flexDirection) ? "initial" : props.flexDirection};
  justify-content: ${(props) => _.isEmpty(props.align) ? "space-between" : props.align};
  align-items: center;
`;


// button
const Button = styled.button`
  width: 222px;
  height: 60px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  border: none;
`;

export const RedButton = styled(Button)`
  background: #CF5D5D;
  color: #FFFFFF;
`;

export const BlueButton = styled(Button)`
  background: #6E8ED1;
  color: #FFFFFF;
`;

export const MultiButton = styled(Button)`
  margin-bottom: 5px;
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 100%;
  border: none;
  background: #ffffff;
  color: #949390;

  ${(props) =>
          props.hidden &&
          css`
            display: none;
          `}
`;