import {atom, atomFamily, DefaultValue, selectorFamily} from "recoil";

export type PdfUrlType = {
    id: string,
    list: Array<string>
};

const pdfUrlAtom = atom<Array<string>>({
    key: 'pdfUrlAtom',
    default: [],
});

const pdfUrlAtomFamily = atomFamily<PdfUrlType, string>({
    key: 'pdfUrlAtomFamily',
    default: (id) => {
        const item: PdfUrlType = {
            id,
            list: [],
        }
        return item;
    }
});

export const pdfUrlState = selectorFamily<PdfUrlType, string>({
    key: "pdfUrlState",
    get: (id: string) => ({get}) => get(pdfUrlAtomFamily(id)),
    set: (id: string) => ({get, set, reset}, pdfUrl) => {
        if (pdfUrl instanceof DefaultValue) {
            reset(pdfUrlAtomFamily(id))
            set(pdfUrlAtom, (prevValue) => prevValue.filter((item) => item !== id))
            return
        }
        set(pdfUrlAtomFamily(id), pdfUrl)
        set(pdfUrlAtom, (prevValue) => Array.from((new Set([...prevValue, id]))))
    }
})