import { IOoUnospayDelivery, IOoUnospaySalesMenu, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _ from 'lodash'
import FileSaver from 'file-saver';
import moment from 'moment';
const ExcelJS = require("exceljs");

const findProductCode = (excode:string) => {
  switch (excode) {
    case 'OF31_01':
      return '현미밥도시락/6종'
    case 'OF31_02':
      return '볶음밥도시락/6종'
      case 'OF31_03':
      return '두부면파스타/6종'
    
  }
}

const brownRiceProducts = ['닭가슴살 스테이크','부채살 스테이크','올인원스테이크','연어 스테이크'];
const friedRice = ['닭가슴살 계란볶음밥','부채살 계란볶음밥','연어 계란볶음밥','닭가슴살 김치볶음밥','부채살 김치볶음밥','연어 김치볶음밥'];
const noodles = ['토마토 두부면 파스타','크림 두부면 파스타'];

const findProduct = (excode:string) => {
  switch(excode){
    case 'OF31_01':
      return brownRiceProducts;
    case 'OF31_02':
      return friedRice;
      case 'OF31_03':
      return noodles;
  }
}

const findQuantity = (excode:string) => {
  switch(excode){
    case 'OF31_01':
    case 'OF31_02':
      return '1';
    case 'OF31_03':
      return '3';
  }
}
const getDeliveryMessage = (data: ExcelExportModel) => {
  switch (data.subMemo) {
      case "6":
        return data.memo;
      case "2":
        return "자율 출입가능";
      case "7":
        return "무인택배함";
      case "":
        return data.memo;
      default:
        return data.subMemo;
    }
}

const createModel = (e:IOoUnospayDelivery, salesItem:IOoUnospaySalesMenu) => {
  const model = new ExcelExportModel();
                model.name = e.recipientName;
                model.address1 = e.address;
                model.address2 = e.addressDetail;
                model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                model.zipCode = e.zipCode;
                model.memo = e.memo;
                model.subMemo = e.subMemo;
                model.quantity = findQuantity(salesItem.externalCode!);
                model.exCode = salesItem.externalCode;
  return model;
}

const smOrder = async (list:IOoUnospayDelivery[]) => {
  
  const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.smProvider));
  let data_array: Array<ExcelExportModel> = [];
  console.log(excelList);
    
    excelList.forEach(e => {
      if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
        e.salesMenu?.forEach((salesItem, i) => {
          if(_.startsWith(salesItem.externalCode, 'OF31')){
            if(salesItem.salesOption){
              const getProductName = salesItem.salesOption.map(item => item.optionName);
              const defaultProductName = findProduct(salesItem.externalCode!);
              const productsName = [...defaultProductName! , ...getProductName];
              productsName.map(item => {
                let model = createModel(e, salesItem)
                model.product = item;
                data_array.push(model);
              })
            }else{
              findProduct(salesItem.externalCode!)?.map(item => {
                let model = createModel(e, salesItem)
                    model.product = item;
                    data_array.push(model)
                })
              }
            }
        });
      }
    })

    console.log(data_array);
  
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferSM = await (await fetch("/assets/sm.xlsx")).arrayBuffer();
    const workbookSM = new ExcelJS.Workbook();
    await workbookSM.xlsx.load(arrayBufferSM);
    const ws_SM = workbookSM.getWorksheet("Worksheet");

    data_array.map((data: ExcelExportModel, index: number) => {
      console.log(data);
      
      const row = ws_SM.getRow(index + 2); // 엑셀은 1부터 시작
      [
        findProductCode(data.exCode!),
        "",
        "",
        "TLC헬스케어",
        data.product,
        data.product,
        data.quantity,
        data.name,
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
        data.zipCode,
        `${data.address1} ${data.address2}`,
        data.memo,
      ].forEach((value , index) => {
        const cell = row.getCell(index + 1);
        cell.value = value;
        cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        };
      });
    });
    const excelBuffer = await workbookSM.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName + "_" + moment().format("YYYYMMDD") + "(식스밀)" + excelFileExtension
    );
}

export default smOrder