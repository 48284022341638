import {useModalHook} from "@hooks/modal";
import {
    ButtonContainer,
    Card,
    ContentsContainer,
    RedButton,
    TitleContainer,
    Wrapper
} from "@components/customModal/style";
import React from "react";

export const OneButtonModal = ({
                                   data: {
                                       title = '',
                                       contents = '',
                                       buttons = [{title: "닫기", callback: (e: any) => false}]
                                   }
                               }) => {
    const {removeCurrentModal} = useModalHook();
    return (
        <Wrapper>
            <Card>
                {
                    title.length > 0 &&
                    <TitleContainer>
                        {title}
                    </TitleContainer>
                }
                <ContentsContainer>
                    {contents}
                </ContentsContainer>
                <ButtonContainer>
                    <RedButton type="button" onClick={() => removeCurrentModal()}>{buttons[0].title}</RedButton>
                </ButtonContainer>
            </Card>
        </Wrapper>
    )
}