import {atom, selector} from "recoil";
import {OoParamPrescriptionCreate} from "@params/telemedicine";


const telemedicineUpdateAtom = atom<Array<OoParamPrescriptionCreate>>({
    key: "telemedicineUpdateAtom",
    default: [],
});

export const telemedicineUpdateSelector = selector<Array<OoParamPrescriptionCreate>>({
    key: "telemedicineUpdateSelector",
    get: ({get}) => get(telemedicineUpdateAtom),
    set: ({set}, newValue) => {
        set(telemedicineUpdateAtom, newValue);
    }
});

export const doctorUpdateTelemedicineState = atom<Array<OoParamPrescriptionCreate>>({
    key: "doctor/doctorUpdateTelemedicineState",
    default: [],
});