import styled from "styled-components";
import {ReactElement} from "react";
import _ from "lodash";

interface Props {
    children: ReactElement,
    top?: string,
}

export const ListTitleContainer = ({children, top}: Props) => {
    return (
        <Card top={top}>
            {children}
        </Card>
    )
}

const Card = styled.div<{ top?: string }>`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  background: #ffffff;
  border: 0.5px solid #c2c1bd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 4px;
  color: #949390;
  font-weight: 500;
  font-size: 12px;
`;