import {useRecoilState} from "recoil";
import _ from "lodash";
import {useQuery} from "react-query";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";
import {hospitalListState} from "@recoils/display";
import {useSuspenseHook} from "@hooks/suspense";

export const useHospitalListQuery = () => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const [list, setList] = useRecoilState(hospitalListState);
    return useQuery(
        'hospital/list',
        async () => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.hospital.list();
                setStopLoading();
                if (isRequestSucceed(response)) {
                    setList(response.data || []);
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        }, {
            enabled: _.isEmpty(list), // 리스트가 비어있을 때만 요청 실행
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        }
    )
}