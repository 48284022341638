import { useSuspenseHook } from "../../hooks";
import { useQuery } from "react-query";
import { DoctorOnAPI, isRequestSucceed, OoParamId } from "../../api";
import _ from "lodash";

interface Props {
  id: string;
}

export const useFindDeliveryIdGfOrderQuery = ({ id }: Props) => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
  return useQuery(
    "getGfOrderByDeliveryId",
    async () => {
      try {
        setLoading();
        const response =
          await DoctorOnAPI.shared.unospay.getGfOrderByDeliveryId(
            new OoParamId(id)
          );
        setStopLoading();
        if (isRequestSucceed(response)) {
          return response.data || [];
        }
        return null;
      } catch (e: any) {
        setErrorStatus(e);
      }
    },
    {
      retry: true,
      refetchOnWindowFocus: false,
      refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
    }
  );
};
