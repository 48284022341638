import React, {useEffect} from "react";
import _ from "lodash";
import styled from "styled-components";
import {IOoDoctor} from "@models/doctor";
import {OoParamDoctorUpdate} from "@params/doctor";
import {CustomInputText} from "@components/custom";
import {useDoctorVideoSettingHook} from "@components/infoForm";

interface Props {
    data: IOoDoctor;
    componentName: string,
}

export const DoctorVideo = ({data, componentName}: Props) => {
    const {
        editStatus,
        color,
        setColor,
        handleChange,
        items,
        setItems,
        isReset,
        updateDoctor,
        initialData,
    } = useDoctorVideoSettingHook(data, componentName);

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        setItems(initialData);
    }, [data]);

    useEffect(() => {
        if (editStatus.isEdit) {
            items.isVideoDiagnosis ? setColor("blue") : setColor("grey");
        } else {
            setColor("white");
            setItems(initialData);
        }
    }, [editStatus.isEdit, items.isVideoDiagnosis]);

    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamDoctorUpdate();
            param.id = data.id || "";
            param.isVideoDiagnosis = items.isVideoDiagnosis;
            param.videoId = items.videoId;
            param.videoLink = items.videoLink;
            param.videoPassword = items.videoPassword;
            updateDoctor(param);
        }
    }, [editStatus.isSubmit]);
    return (
        <Wrapper>
            <CheckboxContainer>
                <label htmlFor="noVideo" className="container">미제공
                    <input type="radio"
                           id="noVideo"
                           name="zoom"
                           checked={!items.isVideoDiagnosis}
                           onChange={() => setItems({...items, isVideoDiagnosis: false})}
                           disabled={!editStatus.isEdit}/>
                    <span className="checkmark"></span>
                </label>
                <label htmlFor="video" className="container"> 제공
                    <input type="radio"
                           name="zoom"
                           id="video"
                           checked={items.isVideoDiagnosis}
                           onChange={() => setItems({...items, isVideoDiagnosis: true})}
                           disabled={!editStatus.isEdit}/>
                    <span className="checkmark"></span>
                </label>
            </CheckboxContainer>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>
                        <b>줌링크</b>
                    </td>
                    <td>
                        {
                            editStatus.isEdit ?
                                <CustomInputText color={color}
                                                 size="xLarge"
                                                 disabled={items.isVideoDiagnosis}
                                                 value={items.videoLink}
                                                 onChanged={handleChange}
                                />
                                :
                                <CustomInputText color={color}
                                                 size="xLarge"
                                                 placeholder="-"
                                                 disabled={false}
                                                 value={items.videoLink}
                                                 onChanged={handleChange}
                                />
                        }
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>회의ID</b>
                    </td>
                    <td>
                        {
                            editStatus.isEdit ?
                                <CustomInputText color={color}
                                                 size="xLarge"
                                                 disabled={items.isVideoDiagnosis}
                                                 value={items.videoId}
                                                 onChanged={handleChange}
                                />
                                :
                                <CustomInputText color={color}
                                                 size="xLarge"
                                                 disabled={false}
                                                 placeholder="-"
                                                 value={items.videoId}
                                                 onChanged={handleChange}
                                />
                        }
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>암호</b>
                    </td>
                    <td>
                        {
                            editStatus.isEdit ?
                                <CustomInputText color={color}
                                                 size="xLarge"
                                                 disabled={items.isVideoDiagnosis}
                                                 value={items.videoPassword}
                                                 onChanged={handleChange}
                                />
                                :
                                <CustomInputText color={color}
                                                 size="xLarge"
                                                 disabled={false}
                                                 placeholder="-"
                                                 value={items.videoPassword}
                                                 onChanged={handleChange}
                                />
                        }
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    );
};


const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-between;
  font-weight: 700;
  font-size: 13px;
  align-items: center;

  .container {
    display: flex;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-weight: 700;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
  }

  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 8px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
