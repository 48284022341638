import {useLocation} from "react-router-dom";
import _ from "lodash";
import {IOoDiagnosis, OoParamAddMedicinePrice} from "../../../../api";
import {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {pharmacyMedicinePriceSubmit} from "../../../../recoils/pharmacy/pharmacyMedicinePriceSubmit";
import {usePharmacyTelemedicineHook} from "../../../../hooks";
import {usePharmacyTelemedicineUpdate} from "../../../../queries/pharmacy";

export const usePharmacyDetailLayoutSettingHook = () => {
    const location = useLocation();
    const ownerData = _.get(location.state, 'data') as IOoDiagnosis;
    const familyData = _.get(location.state, 'familyData') as Array<IOoDiagnosis>;
    const [targetData, setTargetData] = useState<IOoDiagnosis>();
    const [medicinePrice, setMedicinePrice] = useState<Array<OoParamAddMedicinePrice>>([]);
    const [medicineTargetPrice, setMedicineTargetPrice] = useState<OoParamAddMedicinePrice | undefined>();
    const [updateQueryTrigger, setUpdateQueryTrigger] = useRecoilState(pharmacyMedicinePriceSubmit);
    const diagnosisList = [ownerData, ...familyData];

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const {makeMedicinePriceParam, makeMedicinePriceList} = usePharmacyTelemedicineHook();

    const [selectedValue, setSelectedValue] = useState<string>(ownerData.id || '');

    const onChanged = (e: any) => {
        const targetData = _.find(diagnosisList, (d) => _.isEqual(d.timestamp.toString(), e.currentTarget.id));
        !_.isUndefined(targetData) && setTargetData(targetData);
        setSelectedValue(e.currentTarget.value);
    };

    usePharmacyTelemedicineUpdate({
        ownerData: ownerData,
        priceList: medicinePrice,
        updateQuery: updateQueryTrigger,
    });

    useEffect(() => {
        const priceList = _.map(diagnosisList, (d) => makeMedicinePriceParam({data: d}));
        setMedicinePrice(priceList);
    }, []);

    useEffect(() => {
        makeMedicinePriceList({targetData: medicineTargetPrice, priceDataList: medicinePrice});
        setModalVisible(false);
    }, [medicineTargetPrice]);

    const onChangedMedicinePayPrice = (param: OoParamAddMedicinePrice) => {
        setMedicineTargetPrice(param);
    };

    const onChangedPayStatus = (status: boolean) => {
        setUpdateQueryTrigger(status);
    };

    return {
        modalVisible,
        diagnosisList,
        medicinePrice,
        onChangedPayStatus,
        selectedValue,
        onChanged,
        ownerData,
        targetData,
        onChangedMedicinePayPrice,
    }
}