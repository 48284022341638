import {useMutation} from "react-query";
import _ from "lodash";
import {OoParamDiagnosisStatus, OoParamFamilyChangeStatus} from "@params/familyTelemedicine";
import {useSuspenseHook} from "@hooks/suspense";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";

export const useChangeStatusQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'doctor/updateStatus',
        async (list: Array<OoParamDiagnosisStatus>) => {
            try {
                setLoading();
                let response;
                if (_.gte(list.length, 2)) {
                    response = await DoctorOnAPI.shared.familyTelemedicine.diagnosisStatus(new OoParamFamilyChangeStatus(list));
                } else {
                    response = await DoctorOnAPI.shared.telemedicine.diagnosisStatus(list[0]);
                }
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        }
    )
}