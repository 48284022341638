import { MainListTitle } from "@components/mainListTitle";
import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { DoctorOnAPI } from "@api/request";
import { IOoProducts } from "@api/models/productSetting/product";
import _ from "lodash";
import { Spinner, Modal, Button } from "react-bootstrap";
import ProductSettingModal from "./components/ProductSettingModal";
import ProductSettingList from "./components/ProductSettingList";
import ProductAddInfoPage from "../ProductAddInfoPage";
import { saveAs } from 'file-saver'; // 추가
import * as XLSX from 'xlsx'; // 추가

export interface ProductInfo {
  externalCode: string;
  providerName: string;
  productFullName: string;
  productName: string;
  productType: number;
  settleRate: string;
  isConsignment: boolean;
  isShow: boolean;
  supplyPrice: number;
  retailPrice: number;
  productCode: string;
}

export interface ChoiceProps {
  type?: string;
  id: string;
}

interface JustifyContentStyle {
  type?: string;
}

const MTMTYPES = [
  { key: "all", value: "전체" },
  { key: "0", value: "MTM" },
  { key: "1", value: "MTE" },
  { key: "2", value: "MTS" },
];

const ProductSettingPage = () => {
  const [visible, setVisible] = useState(false);
  const [productList, setProductList] = useState<IOoProducts[] | undefined>([]);
  const [filterProductList, setfilterProductList] = useState<IOoProducts[] | undefined>([]);
  const [loading, setLoading] = useState(true);
  const [providerList, setProviderList] = useState<string[]>([]);
  const [addProductList, setAddProductList] = useState<ProductInfo[]>([
    {
      externalCode: "",
      providerName: "",
      productName: "",
      productFullName: "",
      productType: 0,
      settleRate: "",
      supplyPrice: 0,
      retailPrice: 0,
      productCode: "",
      isConsignment: false,
      isShow: false,
    },
  ]);
  const [state, setState] = useState<ChoiceProps>();
  const [showPopup, setShowPopup] = useState(false);
  const [choiceProvider, setChoiceProvider] = useState("all");
  const [choiceType, setChoiceType] = useState("all");
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCategory, setSearchCategory] = useState('productName'); // 기본 검색 카테고리
  const [showExportModal, setShowExportModal] = useState(false);

  const handleClickProduct = () => {
    setAddProductList([
      {
        externalCode: "",
        providerName: "",
        productName: "",
        productFullName: "",
        productType: 0,
        settleRate: "",
        supplyPrice: 0,
        retailPrice: 0,
        productCode: "",
        isConsignment: false,
        isShow: false,
      },
    ]);
    setVisible(true);
  };

  const providerDuplicates = (list: string[]) => {
    const setList = new Set(list);
    return [...setList];
  };

  const getProviderData = async (type: string) => {
    try {
      const response = await DoctorOnAPI.shared.productSetting.providerFind(Number(type));
      if (response.status === "ok") {
        if (response.data) {
          const result = providerDuplicates(response.data.providerList);
          setProviderList(result);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleChangeType = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setChoiceType(value);
    if (value !== "all") {
      getProviderData(value);
    }
  };

  const handleChangeOption = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setChoiceProvider(value);
  };

  const getProductSettingList = async () => {
    try {
      const response = await DoctorOnAPI.shared.productSetting.list();
      if (response.status === "ok") {
        setProductList(response.data);
        setfilterProductList(response.data);
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const checkFilter = () => {
    if (choiceProvider === "all" && choiceType === "all") {
      setfilterProductList(productList);
    } else if (choiceProvider === "all" && choiceType !== "all") {
      const typeFilterList = productList?.filter((item) => item.productType === Number(choiceType));
      setfilterProductList(typeFilterList);
    } else if (choiceProvider !== "all" && choiceType !== "all") {
      const typeFilterList = productList?.filter((item) => item.productType === Number(choiceType));
      const providerFilterList = typeFilterList?.filter(
        (item) => item.providerName === choiceProvider
      );
      setfilterProductList(providerFilterList);
    } else {
      const providerFilterList = productList?.filter(
        (item) => item.providerName === choiceProvider
      );
      setfilterProductList(providerFilterList);
    }
    setSearchTerm('')
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    // 검색어 입력 시 품목 및 업체 초기화
    setChoiceType("all"); // 품목 초기화
    setChoiceProvider("all"); // 업체 초기화
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchCategory(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchClick(); // 엔터 키 클릭 시 검색 동작
    }
  };

  useEffect(() => {
    checkFilter();
  }, [choiceProvider, choiceType]);

  useEffect(() => {
    getProductSettingList();
  }, [setState, addProductList]);

  const handleSearchClick = () => {
    const filteredProducts = productList?.filter(product => {
      const valueToSearch = (product as any)[searchCategory]?.toString().toLowerCase() || ''; // 'any'로 캐스팅
      return valueToSearch.includes(searchTerm.toLowerCase());
    }) || [];
    setfilterProductList(filteredProducts);
  };

  const downloadExcel = () => {
    const modifiedData = filterProductList
        ?.sort((a, b) => a.externalCode.localeCompare(b.externalCode)) // externalCode 오름차순 정렬
        .map(product => ({
            '코드': product.externalCode,
            '업체명': product.providerName,
            '제품 이름': product.productFullName,
            '제품 유형': getProductTypeToStr(product.productType),
            '정산 비율': product.settleRate,
            '공급가': product.supplyPrice,
            '소매가': product.retailPrice,
        })) || [];

    const worksheet = XLSX.utils.json_to_sheet(modifiedData, { header: ['코드', '업체명', '제품 이름', '제품 유형', '정산 비율', '공급가', '소매가'] }); // 필터된 제품 목록을 시트로 변환

    // 컬럼 너비 설정
    const columnWidths = [
        { wch: 10 }, // 코드
        { wch: 15 }, // 업체명
        { wch: 50 }, // 제품 이름
        { wch: 15 }, // 제품 유형
        { wch: 15 }, // 정산 비율
        { wch: 10 }, // 공급가
        { wch: 10 }, // 소매가
    ];
    worksheet['!cols'] = columnWidths; // 컬럼 너비 적용

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, 'products.xlsx'); // 파일 저장
  };

  const downloadOrderExcel = () => {
    const modifiedData = filterProductList
        ?.sort((a, b) => a.externalCode.localeCompare(b.externalCode)) // externalCode 오름차순 정렬
        .flatMap(product => {
          if (product.optionList && product.optionList.length > 0) {
            // 옵션이 있는 경우
            return product.optionList.map((option, index) => ({
              '발주서 제품명': product.productFullName,
              '정산페이지 제품명': product.productName,
              '제품코드': product.externalCode,
              '옵션코드': option.optionCode || '',
              '옵션명': option.optionName || '',
              '옵션금액': option.optionPrice || 0,
              '옵션 공급가': option.optionSupplyPrice || 0,
              '옵션 발주서 코드': option.optionOrderCode || '',
              '공급자명': product.providerName,
              '공급가': product.supplyPrice,
              '소비자가': product.retailPrice,
              '발주서코드': product.productCode,
              '요율': product.settleRate,
              '카테고리': getProductTypeToStr(product.productType),
              '공급방식': product.isConsignment ? '위탁' : '매입',
              '공개여부': product.isShow ? '공개' : '비공개'
            }));
          } else {
            // 옵션이 없는 경우
            return [{
              '발주서 제품명': product.productFullName,
              '정산페이지 제품명': product.productName,
              '제품코드': product.externalCode,
              '옵션코드': '',
              '옵션명': '',
              '옵션금액': 0,
              '옵션 공급가': 0,
              '옵션 발주서 코드': '',
              '공급자명': product.providerName,
              '공급가': product.supplyPrice,
              '소비자가': product.retailPrice,
              '발주서코드': product.productCode,
              '요율': product.settleRate,
              '카테고리': getProductTypeToStr(product.productType),
              '공급방식': product.isConsignment ? '위탁' : '매입',
              '공개여부': product.isShow ? '공개' : '비공개'
            }];
          }
        }) || [];

    // console.log(modifiedData)
    const worksheet = XLSX.utils.json_to_sheet(modifiedData, { header: ['발주서 제품명', '정산페이지 제품명', '제품코드', '옵션코드', '옵션명', '옵션금액', '옵션 공급가', '옵션 발주서 코드', '공급자명', '공급가', '소비자가', '발주서코드', '요율', '카테고리', '공급방식', '공개여부']});

    // 컬럼 너비 설정
    const columnWidths = [
        {wch: 20}, // 발주서 제품명
        {wch: 20}, // 정산페이지 제품명
        {wch: 15}, // 제품코드
        {wch: 15}, // 옵션코드
        {wch: 15}, // 옵션명
        {wch: 10}, // 옵션금액
        {wch: 10}, // 옵션 공급가
        {wch: 15}, // 옵션 발주서 코드
        {wch: 15}, // 공급자명
        {wch: 10}, // 공급가
        {wch: 10}, // 소비자가
        {wch: 15}, // 발주서코드
        {wch: 10}, // 요율
        {wch: 10}, // 카테고리
        {wch: 10}, // 공급방식
        {wch: 10}  // 공개여부
    ];
    worksheet['!cols'] = columnWidths; // 컬럼 너비 적용

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, 'products.xlsx'); // 파일 저장
  };

  const ExportModal = () => (
    <Modal
      show={showExportModal}
      onHide={() => setShowExportModal(false)}
      centered // 이 속성을 추가하여 모달을 수직 중앙에 배치합니다.
      // style={{ top: '20%' }} // 이 스타일을 추가하여 모달을 위로 조금 올립니다.
    >
      <Modal.Header closeButton>
        <Modal.Title>내보내기 타입</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <Button 
          variant="primary" 
          onClick={() => { downloadExcel(); setShowExportModal(false); }}
          style={{ width: '200px', height: '50px' }}
        >
          간략 상품 정보
        </Button>
        <Button 
          variant="primary" 
          onClick={() => { downloadOrderExcel(); setShowExportModal(false); }}
          style={{ width: '200px', height: '50px' }}
        >
          발주서용 상세 상품 정보
        </Button>
      </Modal.Body>
    </Modal>
  );

  return (
    <Wrapper>
      {loading ? (
        <TopContainer type="center">
          <Spinner animation="border" variant="primary" />
        </TopContainer>
      ) : (
        <TopContainer>
          <ProductSettingModal
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            setState={setState}
            setChoiceProvider={setChoiceProvider}
            setChoiceType={setChoiceType}
            getProductSettingList={getProductSettingList}
            state={state}
          />
          <MainListTitle title="제품 관리" maxWidth={1500}>
            <ArticleContainer>
              <ProductCount>Total : {filterProductList?.length || 0}개</ProductCount>
              <Select onChange={handleCategoryChange} value={searchCategory} style={{ width: '100px' }}>
                <option value="productName">제품명</option>
                <option value="providerName">업체명</option>
                <option value="externalCode">코드</option>
              </Select>
              <SearchInput
                type="text"
                placeholder="검색어 입력 후 엔터"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleKeyPress} // onKeyPress 대신 onKeyDown 사용
              />
              품목 :
              <Select onChange={handleChangeType} value={choiceType}>
                {MTMTYPES.map((item) => (
                  <option key={item.key} value={item.key}>{item.value}</option>
                ))}
              </Select>
              업체 :
              <Select onChange={handleChangeOption}>
                <option value={"all"}>전체</option>
                {providerList.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </Select>
              <ProductAddButton onClick={handleClickProduct}>제품등록</ProductAddButton>
              <ProductAddButton onClick={() => setShowExportModal(true)}>내보내기</ProductAddButton>
            </ArticleContainer>
          </MainListTitle>
          {visible && (
            <ProductAddInfoPage visible={visible} setVisible={setVisible} title='add' setfilterProductList={setfilterProductList} filterProductList={filterProductList}/>
          )}
          {filterProductList!.length > 0 ? (
            <ProductSettingList
              list={filterProductList}
              state={state}
              setState={setState}
              setShowPopup={setShowPopup}
              productList={productList}
              filterProductList={filterProductList}
              setfilterProductList={setfilterProductList}
              setChoiceProvider={setChoiceProvider}
              setChoiceType={setChoiceType}
              getProductSettingList={getProductSettingList}
            />
          ) : (
            <NoContentMessage>표시 할 내용이 없습니다.</NoContentMessage>
          )}
          <ExportModal />
        </TopContainer>
      )}
    </Wrapper>
  );
};

export default ProductSettingPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 90%;
  background-color: #f8f8f8;
  padding: 20px;
`;

const TopContainer = styled.div<JustifyContentStyle>`
  width: 1280px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  justify-content: ${(props) => props.type};
`;

const ProductAddButton = styled.button`
  width: 80px;
  height: 35px;
  background-color: #ffffff;
  border: 2px solid #131adb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: #104989;
  transition: all 0.3s ease;
  margin-right: 10px;

  &:hover {
    background-color: #276fc1;
    color: #ffffff;
    transform: scale(1.05);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* column-gap: 14px; // 간격을 줄임 */
  align-items: center;
  font-size: 18px;
`;

const Select = styled.select`
  width: 130px;
  height: 30px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0 5px;
  margin: 6px;
`;

const NoContentMessage = styled.div`
  font-size: 18px;
  color: #888;
  margin-top: 20px;
`;

const ProductCount = styled.div`
  margin-left: auto;
  margin-right: 4px; /* 우측 마진 추가 */
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border-radius: 5px; /* 모서리 둥글게 */
  padding: 5px 10px; /* 패딩 추가 */
  background-color: #f0f8ff; /* 배경색 추가 */
  width: 140px; /* 가로 사이즈 고정 */
  text-align: center; /* 텍스트 중앙 정렬 */
`;

const SearchInput = styled.input`
  margin-right: 10px;
  padding: 5px;
  width: 230px; // 카테고리와 같은 너비 설정
  height: 30px; // 카테고리와 같은 높이 설정
  border-radius: 5px; // 카테고리와 같은 모서리 둥글게
  border: 1px solid #ccc; // 카테고리와 같은 테두리 설정
  font-size: 16px; // 플레이스홀더 텍스트 사이즈 변경
`;

const getProductTypeToStr = (type: number): string => {
  switch (type) {
    case 0:
      return "MTM";
    case 1:
      return "MTE";
    case 2:
      return "MTS";
    default:
      return "알 수 없음"; // 기본값
  }
};