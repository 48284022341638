import {AxiosInstance} from "axios";
import {IOoDataResponse, IOoDoctor, IOoResponse, OoParamEmail, OoParamId} from "api";
import {endPoint} from "./base";
import {OoParamDoctorCreate, OoParamDoctorHours, OoParamDoctorUpdate, OoParamUpdateStatus} from "@params/doctor";

class DoctorOnDoctorApi {
    constructor(private readonly axiosInstance: AxiosInstance) {
    }

    //사용자 아이디로 의사정보 조회
    async readByUserId(param: OoParamId): Promise<IOoDataResponse<IOoDoctor>> {
        const url = `${endPoint.doctor.readByUserId}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoDoctor>>(url);
    }

    async create(param: OoParamDoctorCreate): Promise<IOoDataResponse<IOoDoctor>> {
        return await this.axiosInstance.post<IOoDataResponse<IOoDoctor>>(endPoint.doctor.create, param);
    }

    async update(param: OoParamDoctorUpdate): Promise<IOoDataResponse<IOoDoctor>> {
        return await this.axiosInstance.put<IOoDataResponse<IOoDoctor>>(endPoint.doctor.updateDoctor, param);
    }

    async doctorHours(param: OoParamDoctorHours): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.doctor.doctorHours, param);
    }

    async findByEmail(param: OoParamEmail): Promise<IOoDataResponse<IOoDoctor>> {
        const url = `${endPoint.doctor.findByEmail}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoDoctor>>(url);
    }

    async read(param: OoParamId): Promise<IOoDataResponse<IOoDoctor>> {
        const url = `${endPoint.doctor.read}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoDoctor>>(url);
    }

    async list(): Promise<IOoDataResponse<IOoDoctor[]>> {
        return await this.axiosInstance.get<IOoDataResponse<IOoDoctor[]>>(endPoint.doctor.list);
    }

    async delete(param: OoParamId): Promise<IOoResponse> {
        const url = `${endPoint.doctor.delete}/${param.getPathParams()}`;
        return await this.axiosInstance.delete<IOoResponse>(url);
    }

    async updateStatus(param: OoParamUpdateStatus): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.doctor.updateStatus, param);
    }

}

export {
    DoctorOnDoctorApi
};