import _ from "lodash";

export const fnGoboxFilter = () => {

    function filteredGoboxStatus(type: string) {
        return _.isEqual(type, '100') ? '등록완료' : '-';
    }

    return {
        filteredGoboxStatus
    }
}