import {DoctorOnAPI} from "@api/request";
import {useSuspenseHook} from "@hooks/suspense";
import {OoParamWapleDoctorInfo} from "@params/reservation/doctorUpdateInfo";
import {useMutation} from "react-query";
import {isRequestSucceed} from "@api/reponses";

export const useUpdateDoctorReservationInfoQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'doctor/updateReservationInfo',
        async (param: OoParamWapleDoctorInfo) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.reservation.updateDoctor(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        }
    )
};