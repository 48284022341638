import React from "react";
import styled from "styled-components";

interface Props {
    comment: string,
    onChange: (value: string) => void,
}

export const DiagnosisComment = ({comment, onChange}: Props) => {
    return (
        <Wrapper>
            <Title>진료 내용</Title>
            <TextAreaContainer>
                <textarea
                    onChange={(e: any) => onChange(e.target.value)}
                    value={comment}
                    placeholder="진료 내용을 입력해 주세요."/>
            </TextAreaContainer>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-top: 48px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const TextAreaContainer = styled.div`
  padding-top: 8px;

  & > textarea {
    font-weight: 400;
    font-size: 13px;
    width: 100%;
    height: 80px;
    border: 2px solid #C8D6F8;
    border-radius: 8px;
    padding: 10px;
    resize: none;
    overflow: scroll;
  }
`;