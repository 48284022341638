import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {doctorPrescriptionState} from "@recoils/doctor";

interface Props {
    onFileUpload: (e: any) => void,
}

export const BeforeAttachFileTable = ({onFileUpload}: Props) => {
    const prescriptionButtonChecked = useRecoilValue(doctorPrescriptionState);
    return (
        <Table>
            <thead>
            <tr>
                <th>
                    <div>파일첨부</div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                {
                    prescriptionButtonChecked ?
                        <td>
                            <NoDescription>처방전 없음</NoDescription>
                        </td>
                        :
                        <td>
                            <Wrapper>
                                <Container>
                                    <LabelContainer htmlFor="uploadFiles">
                                        <FontAwesomeIcon icon={faCloudArrowUp}/>
                                        <div>파일 첨부하기</div>
                                    </LabelContainer>
                                    <input type="file"
                                           id="uploadFiles"
                                           accept="application/pdf,image/jpg,image/jpeg"
                                           multiple={false}
                                           onChange={onFileUpload}
                                           hidden={true}
                                    />
                                </Container>
                                <ImgNone>파일을 첨부하려면 첨부하기 버튼을 눌러주세요</ImgNone>
                            </Wrapper>
                        </td>
                }
            </tr>
            </tbody>
        </Table>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const ImgNone = styled.p`
  color: #C4C4C4;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 13px;
  margin-top: 6px;
`;

const LabelContainer = styled.label`
  display: flex;
  border: 1px solid #D3D9E4;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > div {
    font-weight: 700;
    font-size: 13px;
  }

  & > svg {
    color: #1C6FC9;
    width: 24px;
    height: 16px;
    padding-right: 8px;
  }
`;

const Table = styled.table`
  margin-top: 8px;
  border-top: 2px solid #C8D6F8;
  border-bottom: 2px solid #C8D6F8;
  width: 100%;
  height: auto;

  tr:nth-child(1) th {
    width: 100%;
    height: 38px;
    border-bottom: 1px solid #C8D6F8;
    background: #F4F6FB;
  }

  tr:nth-child(1) th div {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    margin-left: 14px;
    color: #030303;
  }
`;

const NoDescription = styled.div`
  font-weight: 400;
  font-size: 13px;
  margin-left: 14px;
`;