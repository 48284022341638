import {DiagnosisComment} from "../DiagnosisComment";
import {Prescription} from "../Prescription";
import {DiagnosisPay} from "../DiagnosisPay";
import {IOoDiagnosis, OoDiagnosisStatus, OoWapleReservationStatus, OoWapleReservationType} from "@api/models";
import React from "react";
import styled from "styled-components";
import {DisplayPrescription} from "../DisplayPrescription";
import {useFilteredReservationStatus} from "@models/reservation";
import _ from "lodash";
import {useDoctorDetailContainerSettingHook} from "../../hooks/useDoctorDetailContainerSettingHook";
import {DiagnosisCancel} from "@components/diagnosisCancelTable";
import {DiagnosisInfo, ReservationInfoForm} from "@components/diagnosisForm";
import {toast} from "react-toastify";
import {PatientInfo, PatientRequest} from "@components/diagnosisDetailComponents";

export interface Updatable {
    id: string,
    prescription: Array<string>,
    price: number,
    hospitalMedicinePrice: number,
    comment: string,
    isHospitalDelivery: boolean
}

export interface UploadFiles {
    id: string,
    uploadFiles: Array<File>,
}

interface Props {
    data: IOoDiagnosis,
    list: Array<IOoDiagnosis>,
}

export const DoctorDetailContainer = ({data, list}: Props) => {
    const {isReservation} = useFilteredReservationStatus(data);
    const {
        index,
        telemedicineUpdateParamList,
        onChangePrescription,
        onChangeInfo,
        onChangePay
    } = useDoctorDetailContainerSettingHook(data);

    const filteredCancelStatus = () => {
        const predicate1 = _.gte(data.status, OoDiagnosisStatus.doctorCancel);
        const predicate2 = _.lte(data.status, OoDiagnosisStatus.pharmacistCancel);
        return predicate1 && predicate2;
    };

    const filteredDisplayStatus = () => {
        const predicate1 = _.gte(data.wapleReservationStatus ?? OoWapleReservationStatus.unknown, OoWapleReservationStatus.receiptCancelBySelf);
        const predicate2 = _.lte(data.wapleReservationStatus ?? OoWapleReservationStatus.unknown, OoWapleReservationStatus.visitConfirm);
        const predicate3 = _.gte(data.wapleReservationStatus ?? OoWapleReservationStatus.unknown, OoWapleReservationStatus.treatmentCompletePayType_1);
        return (predicate1 && predicate2) || predicate3;
    };

    const handleChecked = (e: any) => {
        e.target.checked && toast.error('미구현 기능입니다.');
    };

    return (
        <Wrapper>
            {
                filteredCancelStatus() &&
                <DiagnosisCancel data={data}/>
            }
            <ReservationInfoForm diagnosis={data}/>
            <PatientInfo data={data}/>
            <PatientRequest data={data} ownerData={_.head(list)!}/>
            {
                isReservation() ?
                    <>
                        {
                            filteredDisplayStatus() ?
                                <>
                                    <DisplayPrescription data={data}/>
                                    <DiagnosisInfo diagnosis={data}/>
                                </>
                                :
                                <>
                                    <Prescription data={data}
                                                  prescription={telemedicineUpdateParamList[index].prescription || []}
                                                  onChange={onChangePrescription}/>
                                    <DiagnosisComment comment={telemedicineUpdateParamList[index].comment || ""}
                                                      onChange={onChangeInfo}/>
                                    <DiagnosisPay data={data}
                                                  param={telemedicineUpdateParamList[index]}
                                                  onChange={onChangePay}/>
                                </>
                        }
                    </>
                    :
                    <>
                        {
                            data.status > OoDiagnosisStatus.accept ?
                                <>
                                    <DisplayPrescription data={data}/>
                                    <DiagnosisInfo diagnosis={data}/>
                                </>
                                :
                                <>
                                    <Prescription data={data}
                                                  prescription={telemedicineUpdateParamList[index].prescription || []}
                                                  onChange={onChangePrescription}/>
                                    <DiagnosisComment comment={telemedicineUpdateParamList[index].comment || ""}
                                                      onChange={onChangeInfo}/>
                                    <DiagnosisPay data={data}
                                                  param={telemedicineUpdateParamList[index]}
                                                  onChange={onChangePay}/>
                                </>
                        }
                    </>

            }
            {
                _.isEqual(OoWapleReservationType.visitReservation, data.wapleReservationType) && isReservation()
                &&
                <InputContainer>
                    <input type="checkbox" onClick={handleChecked}/> <span>데스크에서 수납</span>
                </InputContainer>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  padding: 2%;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 13px;

  input[type="checkbox"] {
    margin-right: 10px;
  }
`;
