import {useState} from "react";
import {DoctorVideoInfo} from "../../../../interfaces/Operator/OperatorDoctorCreateInterface";

export const useVideoSettingHook = () => {
    const [color, setColor] = useState("blue");
    const [items, setItems] = useState<DoctorVideoInfo>({
        isVideoDiagnosis: false,
        videoLink: '',
        videoId: '',
        videoPassword: '',
    });

    const onChangeVideoLink = (e: any) => setItems({...items, videoLink: e.target.value});
    const onChangeVideoId = (e: any) => setItems({...items, videoId: e.target.value});
    const onChangeVideoPassword = (e: any) => setItems({...items, videoPassword: e.target.value});

    return {
        color,
        setColor,
        items,
        setItems,
        onChangeVideoLink,
        onChangeVideoId,
        onChangeVideoPassword,
    }
}