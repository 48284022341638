import {Button} from "react-bootstrap";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {IOoDiagnosis} from "@api/models";
import _ from "lodash";
import {useSetRecoilState} from "recoil";
import {fileState} from "@recoils/file";
import ReactToPrint from "react-to-print";
import axios from "axios";
import FileSaver from "file-saver";
import moment from "moment";
import {useSuspenseHook} from "@hooks/suspense";
import {Document, pdfjs} from "react-pdf";
import {PrintHiddenPageList} from "@components/printHiddenPageList";
import {FilesReadTable} from "@components/diagnosisForm/prescriptionForm/FilesReadTable";
import classNames from "classnames/bind";
import styles from "../../../../../../styles/diagnosis/Prescription.module.scss";
import buttonStyles from "../../../../../../styles/common/Button.module.scss";
import {PdfViewerWithPrint} from "@components/pdfViewerWithPrint";
import {toast} from "react-toastify";


// workerSrc 정의 하지 않으면 pdf 보여지지 않습니다.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


interface Props {
    diagnosisData: IOoDiagnosis,
}

export const PharmacyPrescription = ({diagnosisData}: Props) => {
    const cx = classNames.bind(styles);
    const bx = classNames.bind(buttonStyles);

    const componentRef = useRef(null);
    const setVisible = useSetRecoilState(fileState);
    const [uploadFile, setUploadFile] = useState<Array<string>>([]);
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [pdfPageCount, setPdfPageCount] = useState<number>(0);
    const {setStopLoading} = useSuspenseHook();

    const getPrescription = useCallback(() => {
        if (!_.isUndefined(diagnosisData.prescription)) {
            const getData = diagnosisData.prescription?.map(item => item);
            return setUploadFile(getData);
        }
    }, [diagnosisData.prescription]);

    useEffect(() => {
        getPrescription();
        if (!_.isUndefined(diagnosisData.prescription)) {
            const getData = diagnosisData.prescription?.map(item => item);
            return setPdfUrl(getData[0]);
        }
    }, [diagnosisData.prescription, diagnosisData, getPrescription]);

    const onClickUrl = (e: any) => {
        setVisible(true);
    };

    const onClickDownloadButton = async (e: any) => {
        e.preventDefault();
        const date = moment().format('yyyyMMDDHHmm');
        try {
            const response = await axios.get(pdfUrl, {responseType: "arraybuffer"});
            FileSaver.saveAs(new Blob([response.data], {type: "application/pdf;charset=utf-8"}), `${diagnosisData.patientName}_${date}`);
        } catch (e: any) {
            toast.error(`다운로드가 실패했습니다.\n(${e})`);
        }
    };

    return (
        <>
            <PdfViewerWithPrint pdfUrl={pdfUrl} printRef={componentRef}/>
            {
                !_.isUndefined(diagnosisData.prescription) && _.gt(diagnosisData.prescription.length, 0) ?
                    <div>
                        <div className={cx('container-div')}>
                            <div><b>처방전</b></div>
                            <FilesReadTable onClickUrl={onClickUrl}
                                            uploadFiles={uploadFile}
                                            patientName={diagnosisData.patientName}
                                            checkBoxHidden={true}/>
                        </div>
                        <div className={cx('buttons-div')}>
                            <Button className={bx('button')} onClick={onClickDownloadButton}>
                                <div className={cx('button-inside-div')}>
                                    <img src={process.env.PUBLIC_URL + '/img/download-icon.png'} alt=""
                                         className={cx('custom-download-icon')}/>
                                    <div>다운로드</div>
                                </div>
                            </Button>
                            <ReactToPrint trigger={() => <Button className={bx('button')}>인쇄</Button>}
                                          content={() => componentRef.current}/>{' '}
                            <div style={{display: "none"}}>
                                <Document file={pdfUrl}
                                          onLoadSuccess={(pdf) => {
                                              setPdfPageCount(pdf.numPages);
                                              setStopLoading();
                                          }}
                                          ref={componentRef}>
                                    <PrintHiddenPageList pageCount={pdfPageCount}/>
                                </Document>
                            </div>
                            {/* 미구현 hidden - bsh */}
                            {/*<Button className={bx('button')} onClick={onClickFaxButton} hidden={true}>처방전 팩스 전송</Button>*/}
                        </div>
                    </div>
                    :
                    <div className={cx('empty-file-container-div')}>
                        <div><b>처방전</b></div>
                        <table>
                            <thead>
                            <tr>
                                <td>파일첨부</td>
                                <td>없음</td>
                            </tr>
                            </thead>
                        </table>
                    </div>
            }
        </>
    )
}