import {useRecoilValue} from "recoil";
import {editSelectorFamily} from "@recoils/status";
import {useState} from "react";
import {IOoPharmacy} from "@models/pharmacy";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";

export const usePharmacyInfoHook = (data: IOoPharmacy) => {
    const {updatePharmacy} = usePharmacyFunctionHook();
    const componentName: string = "pharmacyInfo";
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const [items, setItems] = useState({
        email: data.email,
        name: data.name,
        license: data.license,
        pictures: data.pictures,
    });

    const onChangedPicture = (target: string) => setItems({...items, pictures: [target]});

    return {
        editStatus,
        items,
        onChangedPicture,
        updatePharmacy,
    }
}