import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  IOoUnospayDelivery,
  IOoUnospaySalesMenu,
  IOoUnospaySalesMenuOption,
  OoDeliveryStatus,
  OoOrderPlatformType,
  OoProvider,
} from "@api/models";
import { ExcelExportModel } from "../model";
import _ from "lodash";
import styled from "styled-components";
import { formatPrice, formatTel } from "@hooks/format";
import moment from "moment";
import React from "react";
const ExcelJS = require("exceljs");

interface Props {
  list: Array<IOoUnospayDelivery>;
  select: OoProvider;
}

function addDays(date: Date, days: number) {
  const clone = new Date(date);
  clone.setDate(date.getDate() + days);
  return clone;
}

function getDeliveryCount(option: IOoUnospaySalesMenu) {
  let retCount = 1;
  if (
    _.isEqual(option.externalCode, "OF04_01") ||
    _.isEqual(option.externalCode, "OF04_11") ||
    _.isEqual(option.externalCode, "OF04_02") ||
    _.isEqual(option.externalCode, "OF04_03") ||
    _.isEqual(option.externalCode, "OF04_04") ||
    _.isEqual(option.externalCode, "OF04_05") ||
    _.isEqual(option.externalCode, "OF04_10") ||
    _.isEqual(option.externalCode, "OF04_09")
  ) {
    switch (_.first(option.salesOption)?.externalCode) {
      case "301":
      case "302":
      case "303":
      case "304":
        retCount = 1;
        break;
      case "101":
        retCount = 2;
        break;
      case "102":
      case "04":
      case "02":
        retCount = 6;
        break;
      case "103":
      case "05":
      case "03":
        retCount = 12;
        break;
    }
  } else if (
    _.isEqual(option.externalCode, "OF04_06") ||
    _.isEqual(option.externalCode, "OF04_07") ||
    _.isEqual(option.externalCode, "OF04_08") ||
    _.isEqual(option.externalCode, "OF05_01")
  ) {
    retCount = 6;
  } else if (_.isEqual(option.externalCode, "OF05_02")) {
    retCount = 12;
  } else if (
    _.isEqual(option.externalCode, "OF05_03") ||
    _.isEqual(option.externalCode, "OF05_04") ||
    _.isEqual(option.externalCode, "OF05_07") ||
    _.isEqual(option.externalCode, "OF05_08") ||
    _.isEqual(option.externalCode, "OF05_09")
  ) {
    retCount = 18;
  } else if (_.isEqual(option.externalCode, "OF05_10")) {
    retCount = 2;
  } else if (
    _.isEqual(option.externalCode, "OF05_11") ||
    _.isEqual(option.externalCode, "OF05_12") ||
    _.isEqual(option.externalCode, "OF05_13")
  ) {
    retCount = 4;
  } else if (_.isEqual(option.externalCode, "OF05_06")) {
    retCount = 24;
  } else if (
    _.isEqual(option.externalCode, "OF05_14") ||
    _.isEqual(option.externalCode, "OF05_15")
  ) {
    retCount = 21;
  }
  return retCount;
}

function getOptionDeliveryCount(optionExternalCode: string) {
  let retCount = 1;
  if (_.isEqual(optionExternalCode, "OF04_20")) {
    retCount = 1;
  }
  return retCount;
}

function convTimestampDateFormat(timestamp: number) {
  var dateFormat = new Date(timestamp);
  return (
    dateFormat.getFullYear() +
    "-" +
    (dateFormat.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    dateFormat.getDate().toString().padStart(2, "0")
  );
}

function getNewCareInfo(optionCode: string | undefined) {
  if (
    _.isEqual(optionCode, "01") ||
    _.isEqual(optionCode, "02") ||
    _.isEqual(optionCode, "03") ||
    _.isEqual(optionCode, "04") ||
    _.isEqual(optionCode, "05")
  ) {
    return "0";
  } else {
    return "";
  }
}

function getMenuPrice(exCode: string) {
  let ret = "";
  switch (exCode) {
    case "OF04_01": // 당뇨
    case "OF04_11": // 저당
    case "OF04_02": // 암환자
      ret = "7,700";
      break;
    case "OF04_03": // 퇴원후 회복식 (장수마을)
    case "OF04_10": // 암환자 회복식
      ret = "7,300";
      break;
    case "OF04_05": // 샐러드 식단
      ret = "6,300";
      break;
    case "OF04_09":
      ret = "7,700";
      break;
    case "OF04_07":
      ret = "4,300";
      break;
    case "OF04_06":
      ret = "3,950";
      break;
    case "OF04_08":
      ret = "5,250";
      break;
  }
  return ret;
}

function exCodeNumToStr(num: number) {
  let retStr = "";
  switch (num) {
    case 1:
      retStr = "OF04";
      break;
    case 2:
      retStr = "OF10";
      break;
    case 3:
      retStr = "OF05";
      break;
    case 4:
      retStr = "OF07";
      break;
    case 5:
      retStr = "OF13";
  }
  return retStr;
}

function getProductInfo(code: string): [string, string] {
  switch (code) {
    case "OF10_01_201":
      return ["CS캡)뉴케어 구수한맛 200ml", "1025123"];
    case "OF10_01_202":
      return ["(CB)뉴케어 딸기맛 200ml", "1025145"];
    case "OF10_01_203":
      return ["CS캡)뉴케어 고소한 검은깨 200ml", "1025122"];
    case "OF10_03_201":
      return ["(CS)당플랜호두맛 200ml(30입)", "1024778"];
    case "OF10_03_202":
      return ["뉴케어 당플랜 인절미맛", "1105923"];
    case "OF10_02":
      return ["(CS)뉴케어 인핸서 200ml(30입)", "1025291"];
    case "OF10_04_201":
      return ["(NEW)마이밀 마이키즈 초코 150ml", "1025295"];
    case "OF10_04_202":
      return ["(NEW)마이밀 마이키즈 딸기 150ml", "1025296"];
    case "OF10_05":
      return ["(CS)뉴케어 액티브 골든밸런스 200ml", "1023905"];
    case "OF10_05_201":
      return ["(리뉴얼,CS)뉴케어 액티브 오리지널 200ml(24입)", "1105815"];
    case "OF10_05_202":
      return ["(CS)뉴케어 액티브 밀크맛 200ml(24입)", "1105664"];
    case "OF10_07":
      return ["뉴케어 케이디플러스 200ml", "1103719"];
    case "OF10_06":
      return ["뉴케어 케이디 200ml", "1103720"];
    case "OF10_08_201":
      return ["(RE) 당플랜 소고기 볶음밥 300g (28입)", "2037775"];
    case "OF10_08_202":
      return ["(RE) 당플랜 닭가슴살 볶음밥 300g (28입)", "2037772"];
    case "OF10_08_203":
      return ["당플랜 병아리콩 해물볶음밥 300g(28입)", "2038995"];
    case "OF07_01_201":
      return ["당뇨식단/10끼", "0302780"];
    case "OF07_01_202":
      return ["당뇨식단/10끼", "0302780"];
    case "OF07_02_301":
      return ["암환자식단/10끼", "0302948"];
    case "OF07_02_302":
      return ["암환자식단/10끼", "0302929"];
    case "OF07_05_201":
      return ["500차림/5끼", "0302972"];
    case "OF07_05_202":
      return ["500차림/5끼", "0302972"];
    case "OF07_06_201":
      return ["정성한상/5끼", "0302798"];
    case "OF07_06_202":
      return ["정성한상/5끼", "0302798"];
    case "OF07_03_201":
      return ["300샐러드밀/5끼 (300kcal)", "0302825"];
    case "OF07_03_202":
      return ["300샐러드밀/5끼 (300kcal)", "0302825"];
    case "OF07_04_201":
      return ["300라이스밀/5끼 (300kcal)", "0302998"];
    case "OF07_04_202":
      return ["300라이스밀/5끼 (300kcal)", "0302998"];
    case "OF07_11":
      return ["[실온]중기이유식 (8팩)", "0302575"];
    case "OF07_12":
      return ["[실온]후기이유식 (8팩)", "0302576"];
    case "OF07_13":
      return ["밸런스쉐이크 (7팩)", "0302246"];
    default:
      return ["", ""];
  }
}

export const dsOrder = async (
  list: Array<IOoUnospayDelivery>,
  select: OoProvider
) => {
  let data_array: Array<ExcelExportModel> = [];
  console.log(list);
  //공휴일 적용 안됨
  const today = new Date();
  let minDate: Date;
  const dayOfWeek = today.getDay(); // 0은 일요일, 1은 월요일, ... 6은 토요일
  switch (dayOfWeek) {
    case 1: // 월요일
      minDate = addDays(today, 2);
      break;
    case 2: // 화요일
      minDate = addDays(today, 2);
      break;
    case 3: // 수요일
      minDate = addDays(today, 2);
      break;
    case 4: // 목요일
      minDate = addDays(today, 2);
      break;
    case 5: // 금요일
      minDate = addDays(today, 3);
      break;
    case 6: // 토요일
      minDate = addDays(today, 4);
      break;
    case 0: // 일요일
      minDate = addDays(today, 3);
      break;
    default:
      minDate = addDays(today, 1);
  }

  let menuListIndex = 0;
  list.forEach((e, i) => {
    
    // if(e.orderPlatform == OoOrderPlatformType.mtmMall) {
    //   return;
    // }
    if (e.status == OoDeliveryStatus.existAddress || e.status == OoDeliveryStatus.sendPackage) {
      e.transportInfo.forEach((info, i) => {
        const model = new ExcelExportModel();
        const option = e.salesMenu![i];
        
        //옵션으로 묶인 단품메뉴들
        option.salesOption?.map((optionData: IOoUnospaySalesMenuOption) => {
          
          if (_.startsWith(optionData.externalCode, exCodeNumToStr(select))) {
            model.excelStatus = e.status == OoDeliveryStatus.sendPackage ? 'sendPackage' : 'existAddress'
            model.product = info.menuName;
            model.exCode = info.externalCode;
            // model.quantity = e.salesMenu![i].menuCount;
            model.orderDate = convTimestampDateFormat(e.timestamp);
            model.name = e.recipientName;
            model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
            model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
            model.address1 = e.address;
            model.address2 = e.addressDetail;
            model.zipCode = e.zipCode;
            model.memo = e.memo;
            model.subMemo = e.subMemo;
            model.countType = optionData.externalCode === 'OF10_08' ? 'EA':'BOX'

            option.salesOption?.forEach((e) => {
              if (!_.isEmpty(e.externalCode)) {
                model.optionCodeList!.push(e.externalCode!);
              }
            });

            model.quantity = option.menuCount;

            model.withNewCare =
              getNewCareInfo(_.first(option.salesOption)?.externalCode) || "";

            if (e.salesMenu && e.salesMenu.length > i) {
              const salesMenuItem = e.salesMenu[i];

              const count = getOptionDeliveryCount(optionData.externalCode!);

              for (var j = 0; j < count; j++) {
                data_array.push({ ...model });
              }
              
            }
          }
        });

        if (_.startsWith(info.externalCode, exCodeNumToStr(select))) {
          model.excelStatus = e.status == OoDeliveryStatus.sendPackage ? 'sendPackage' : 'existAddress'
          model.product = info.menuName;
          model.exCode = info.externalCode;
          // model.quantity = e.salesMenu![i].menuCount;
          model.orderDate = convTimestampDateFormat(e.timestamp);

          model.name = e.recipientName;
          model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
          model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
          model.address1 = e.address;
          model.address2 = e.addressDetail;
          model.zipCode = e.zipCode;
          model.memo = e.memo;
          model.subMemo = e.subMemo;
          model.countType = info.externalCode === 'OF10_08' ? 'EA':'BOX'

          option.salesOption?.forEach((e) => {
            if (!_.isEmpty(e.externalCode)) {
              model.optionCodeList!.push(e.externalCode!);
            }
          });

          model.quantity = option.menuCount;

          const count = getDeliveryCount(option!);
          model.withNewCare =
            getNewCareInfo(_.first(option.salesOption)?.externalCode) || "";
          model.price =
            getMenuPrice(info.externalCode) ||
            formatPrice(Number(option.menuPrice)).toString();

          if (e.salesMenu && e.salesMenu.length > i) {
            const salesMenuItem = e.salesMenu[i];

            const count = getDeliveryCount(salesMenuItem);

            for (var j = 0; j < count; j++) {
              data_array.push({ ...model });
            }
          }
        }
      });
    }
  });

  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = `tlc헬스케어 발주서`;

  const arrayBufferDs = await (
    await fetch("/assets/ds.xlsx")
  ).arrayBuffer();
  const workbookDs = new ExcelJS.Workbook();
  await workbookDs.xlsx.load(arrayBufferDs);
  const ws_ds = workbookDs.getWorksheet('Sheet1');

  if (select == OoProvider.dsProvider) {
    let newRows: (string | number | undefined)[][] = [];
    const rowNumber = 5;

    data_array.map((data: ExcelExportModel, index: number) => {
      // console.log(`exCode :: => ${data.exCode} :::: ${data.optionCodeList}`);

      // console.log(JSON.stringify(data));

      const exCode = _.isEmpty(data.optionCodeList!)
        ? data.exCode
        : `${data.exCode}_${_.first(data.optionCodeList)}`;
      const [productName, productCode]: [string, string] = getProductInfo(
        exCode!
      );
      const row = ws_ds.getRow(rowNumber + index);
      [
        index + 1,
        data.name,
        data.zipCode,
        data.address1 + " " + data.address2,
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}`,
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}`,
        data.name,
        "매출",
        "TLC헬스케어",
        productName,
        Number(data.quantity),
        data.countType,
        data.memo,
        "",
        productCode,
      ].forEach((value, index) => {
        const cell = row.getCell(index + 1); // 엑셀은 1부터 시작
        cell.value = value;
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
      return false;
    });

    const cellDate = ws_ds.getCell("C2");
    cellDate.value = { formula: 'TEXT(TODAY(), "YYYY.MM.DD")' };
  }
  const excelBuffer = await workbookDs.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFile,
    excelFileName +
      "_" +
      moment().format("YYYYMMDD") +
      "(대상)" +
      excelFileExtension
  );
};
