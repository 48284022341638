import React, {useEffect} from "react";
import _ from "lodash";
import classNames from "classnames/bind";
import SelectStyles from "../../../../styles/common/Select.module.scss";
import styled from "styled-components";
import {filteredServiceType, IOoDoctor, OoServiceType} from "@models/doctor";
import {useDoctorAdditionalSettingHook} from "@components/infoForm";
import {OoParamDoctorUpdate} from "@params/doctor";
import {CustomInputText} from "@components/custom";
import {genderFormat} from "@hooks/format";

interface Props {
    data: IOoDoctor,
    componentName: string,
}

export const DoctorAdditionalInfo = ({data, componentName}: Props) => {
    const sx = classNames.bind(SelectStyles);
    const {
        updateDoctor,
        editStatus,
        items,
        setItems,
        handleChange,
        isReset,
        hospitalName,
        initialData
    } = useDoctorAdditionalSettingHook(data, componentName);

    useEffect(() => {
        isReset();
    }, []);

    // 편집 모드 일때, border 색상 활성화
    useEffect(() => {
        if (!editStatus.isEdit) {
            setItems(initialData);
        }
    }, [editStatus.isEdit]);

    // 전송 모드 일때, 쿼리 trigger
    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamDoctorUpdate();
            param.id = data.id || "";
            param.gender = items.gender;
            param.serviceType = items.serviceType;
            updateDoctor(param);
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <Table>
                <tbody>
                <tr>
                    <td>
                        <CustomInputText size="large"
                                         value={hospitalName}
                                         disabled={false}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        {
                            editStatus.isEdit ?
                                <select className={sx("select", "large", "blue")}
                                        onChange={handleChange}
                                        value={items.gender}
                                        name="gender"
                                >
                                    <DisabledOption value="" disabled>
                                        성별을 선택하세요
                                    </DisabledOption>
                                    <option value="male">남</option>
                                    <option value="female">여</option>
                                </select>
                                :
                                <CustomInputText size="large"
                                                 value={genderFormat(data.gender)}
                                                 disabled={editStatus.isEdit}/>
                        }
                    </td>
                </tr>
                <tr>
                    <td>
                        {
                            editStatus.isEdit ?
                                <select className={sx("select", "large", "blue")}
                                        onChange={handleChange}
                                        name="serviceType"
                                        value={`${data.serviceType}`}>
                                    <DisabledOption value="-1" disabled>
                                        진료타입을 선택하세요
                                    </DisabledOption>
                                    <option value={`${OoServiceType.b2c}`}>
                                        {filteredServiceType(OoServiceType.b2c)}
                                    </option>
                                    <option value={`${OoServiceType.o2o}`}>
                                        {filteredServiceType(OoServiceType.o2o)}
                                    </option>
                                    <option value={`${OoServiceType.o2o2c}`}>
                                        {filteredServiceType(OoServiceType.o2o2c)}
                                    </option>
                                    <option value={`${OoServiceType.testB2c}`}>
                                        {filteredServiceType(OoServiceType.testB2c)}
                                    </option>
                                    <option value={`${OoServiceType.testO2o}`}>
                                        {filteredServiceType(OoServiceType.testO2o)}
                                    </option>
                                    <option value={`${OoServiceType.testO2o2c}`}>
                                        {filteredServiceType(OoServiceType.testO2o2c)}
                                    </option>
                                    <option value={`${OoServiceType.covid}`}>
                                        {filteredServiceType(OoServiceType.covid)}
                                    </option>
                                </select>
                                :
                                <CustomInputText size="large"
                                                 value={filteredServiceType(items.serviceType)}
                                                 disabled={editStatus.isEdit}/>
                        }
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;

const Table = styled.table`
  width: 100%;
  height: 144px;
  font-size: 13px;
  margin-top: 8px;
  white-space: pre-wrap;
`;

const DisabledOption = styled.option`
  &:disabled {
    display: none;
  }
`;
