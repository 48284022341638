import React from "react";
import {getMedicineDeliveryPayStatus, IOoDiagnosis,} from "@api/index";
import buttonStyles from "@styles/common/Button.module.scss";
import classNames from "classnames/bind";
import _ from "lodash";
import styled from "styled-components";
import {toast} from "react-toastify";
import {formatTel} from "@hooks/format";

interface Props {
  diagnosis: IOoDiagnosis;
}

export const DeliveryPatientInfo = ({diagnosis}: Props) => {
  const bx = classNames.bind(buttonStyles);

  const onClickDeliveryEdit = () => {
    toast.error("미구현 기능입니다.");
  };

  return (
      <Wrapper>
        <div className="title">배송지 정보</div>
        <Table>
          <thead></thead>
          <tbody>
          <tr>
            <td>수령인</td>
            <td>
              {_.isUndefined(diagnosis.deliveryRecipient) ||
              _.eq(diagnosis.deliveryRecipient, "")
                  ? "-"
                  : diagnosis.deliveryRecipient}
            </td>
            <td>우편번호</td>
            <td>
              {_.isUndefined(diagnosis.deliveryZipCode) ||
              _.eq(diagnosis.deliveryZipCode, "")
                  ? "-"
                  : diagnosis.deliveryZipCode}
            </td>
          </tr>
          <tr>
            <td>전화번호</td>
            <td>
              {_.isUndefined(diagnosis.deliveryTel) ||
              _.eq(diagnosis.deliveryZipCode, "")
                  ? "-"
                  : `${formatTel(diagnosis.deliveryTel)}`}
            </td>
            <td>주소</td>
            <td>
              {_.isUndefined(diagnosis.deliveryAddress) ||
              _.isUndefined(diagnosis.deliveryAddressDetail) ||
              _.eq(diagnosis.deliveryAddress, "") ||
              _.eq(diagnosis.deliveryAddressDetail, "")
                  ? "-"
                  : `${diagnosis.deliveryAddress} ${diagnosis.deliveryAddressDetail}`}
            </td>
          </tr>
          <tr>
            <td>배송비</td>
            <td>
              {_.isUndefined(diagnosis.deliveryPrice)
                  ? "-"
                  : _.isEqual(diagnosis.deliveryPrice, 0)
                      ? "배송비 없음"
                      : getMedicineDeliveryPayStatus(diagnosis).title}
            </td>
            <td>배송메모</td>
            <td>
              {_.isUndefined(diagnosis.deliveryMemo) ||
              _.eq(diagnosis.deliveryMemo, "")
                  ? "-"
                  : diagnosis.deliveryMemo}
            </td>
          </tr>
          </tbody>
        </Table>
        <ButtonContainer>
          <button onClick={onClickDeliveryEdit} className={bx("button")}>
            배송지 수정
          </button>
        </ButtonContainer>
      </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;

  .title {
    font-weight: 700;
    font-size: 16px;
  }
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: 100%;
  height: 144px;
  font-size: 13px;
  margin-top: 8px;

  & tbody tr {
    height: 30%;
  }

  & tbody tr:nth-child(1),
  tr:nth-child(2) {
    border-bottom: 1px solid #c8d6f8;
  }

  & tbody tr td:nth-child(2n-1) {
    background: #f4f6fb;
    text-align: center;
    font-weight: 700;
    width: 10%;
    font-size: 13px;
  }

  & tbody tr td:nth-child(2n) {
    padding: 10px;
    width: 40%;
    overflow: auto;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;
