import {useDiagnosisDelete, useDiagnosisRefund} from "@queries/operator";
import {OoParamPaypleRefund} from "@api/params";
import {IOoResponse} from "@api/reponses";
import _ from "lodash";
import {OoPaymentType} from "@api/models";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

export const useDiagnosisFunctionHook = () => {
    const navi = useNavigate();

    const diagnosisRefundResp = useDiagnosisRefund();
    const deleteDiagnosisResp = useDiagnosisDelete();

    const refundDiagnosis = (paymentId: string, paymentType: number) => {
        const param = new OoParamPaypleRefund(paymentId, paymentType);
        diagnosisRefundResp.mutate(param, {
            onSuccess: (data: IOoResponse | undefined) => {
                if (_.isUndefined(data)) {
                    toast.error('환불 실패 : 데이터 읽기가 실패했습니다. 관리자에게 문의하세요.');
                    return;
                } else {
                    if (_.isEqual(param.paymentType, OoPaymentType.diagnosis)) {
                        toast.success('진료비 환불 성공');
                        return;
                    } else if (_.isEqual(param.paymentType, OoPaymentType.medicine)) {
                        toast.success('약제비 환불 성공');
                        return;
                    } else {
                        toast.success('배송비 환불 성공');
                        return;
                    }
                }
            },
            onError: (e: any) => {
                console.log('refundDiagnosis error ============> ', e);
            }
        })
    };

    const deleteDiagnosis = (id: string) => {
        deleteDiagnosisResp.mutate(id, {
            onSuccess: (data: IOoResponse | null) => {
                if (_.isUndefined(data)) {
                    toast.error('진료 삭제에 실패했습니다.');
                    return
                }
                toast.success('진료 삭제 완료!');
                navi('/home');
            },
            onError: (e: any) => {
                console.log('deleteDiagnosis error ============> ', e);
            }
        })
    };


    return {
        refundDiagnosis,
        deleteDiagnosis,
    }
}