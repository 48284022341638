import {IOoDiagnosis, OoDiagnosisStatus} from "@api/models";
import _ from "lodash";
import {
    PAYPLE_PAY_REFUND_CODE,
    PAYPLE_PAY_SUCCEESS_CODE,
    PAYPLE_PAY_SUCCEESS_FAKE_CODE,
    PayStatusColor, PayStatusData
} from "@models/diagnosis/function/diagnosisPaymentFilter";
import {formatPrice} from "@hooks/format";

export const useMedicinePaymentFilter = (diagnosis: IOoDiagnosis) => {

    // 약제비 결제 성공 여부
    function isMedicinePaySucceed(): boolean {
        return !isMedicineRefundSucceed() &&
            (_.isEqual(diagnosis.medicinePayCode, PAYPLE_PAY_SUCCEESS_CODE)
                || _.isEqual(diagnosis.medicinePayCode, PAYPLE_PAY_SUCCEESS_FAKE_CODE));
    }

    // 약제비 결제 환불 성공 여부
    function isMedicineRefundSucceed(): boolean {
        return _.isEqual(diagnosis.medicinePayCode, PAYPLE_PAY_REFUND_CODE);
    }

    // 약제비 결제 판단 필요 여부
    function isNoPay(): boolean {
        return diagnosis.status < OoDiagnosisStatus.medicineDeliveryPaymentOk || diagnosis.status > OoDiagnosisStatus.medicineTakeOk;
    }

    // 약제비 0원일 경우
    function isZeroPay(): boolean {
        return _.isEqual(diagnosis.medicinePayPrice, 0);
    }

    function getMedicinePayStatus(): PayStatusData {
        const payString = '약제비';
        const price = _.toNumber(diagnosis.medicinePayPrice || "0");

        if (isNoPay()) {
            return {
                title: `${payString} 없음`,
                color: PayStatusColor.pause
            };
        }

        if (isZeroPay()) {
            return {
                title: `${payString} 없음`,
                color: PayStatusColor.pause
            };
        } else {
            if (!isMedicinePaySucceed()) {
                return {
                    title: `${formatPrice(price)}원 결제실패`,
                    color: PayStatusColor.fail
                };
            } else if (isMedicineRefundSucceed()) {
                return {
                    title: `${formatPrice(price)}원 환불완료`,
                    color: PayStatusColor.success
                };
            }else if(!isMedicineRefundSucceed() && !isMedicinePaySucceed() ){
                return {
                    title: `${formatPrice(price)}원 환불실패`,
                    color: PayStatusColor.fail
                };
            }else{
                return {
                    title: isZeroPay() ? `${formatPrice(price)}원` : `${formatPrice(price)}원 결제완료`,
                    color: PayStatusColor.success
                };
            }
        }
    }

    return {
        getMedicinePayStatus,
    }

}