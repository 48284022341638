import {
  IOoDiagnosis,
  isDiagnosisPaySucceed,
  isDiagnosisRefundSucceed,
  isMedicinePaySucceed,
  OoDiagnosisStatus,
  OoKakaoQuickStatus,
  OoMedicineDeliveryType,
  OoWapleReservationStatus,
} from "@api/models";
import _ from "lodash";

// 전체 진료
export function filterDiagnosisTotalList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  return list;
}

// 진료 취소
export function filterDiagnosisCancelList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.doctorCancel);
  const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.userCancel);
  const predicate3 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.operatorCancel);
  return _.filter(list, (d) => predicate1(d) || predicate2(d) || predicate3(d));
}

// 예약 신쳥
export function filterReservationList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  return _.filter(list, (d) => _.isEqual(d.wapleReservationStatus, OoWapleReservationStatus.reserved));
}

// 진료 신청
export function filterDiagnosisRequestList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  return _.filter(list, (d) => _.isEqual(d.status, OoDiagnosisStatus.request));
}

// 의사 진료 신청
export function filterDoctorDiagnosisRequestList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  return _.filter(list, (d) => _.isEqual(d.status, OoDiagnosisStatus.request) && !_.isEqual(d.wapleReservationStatus, OoWapleReservationStatus.reserved));
}

// 진료 접수 완료
export function filterDiagnosisAcceptList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  return _.filter(list, (d) => _.isEqual(d.status, OoDiagnosisStatus.accept));
}

// 진료비 미결제
export function filterDiagnosisPayFail(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  const predicate1 = (d: IOoDiagnosis) => _.isEqual(isDiagnosisRefundSucceed(d), false);
  const predicate2 = (d: IOoDiagnosis) => _.isEqual(isDiagnosisPaySucceed(d), false) && !_.isEqual(d.price, 0);
  return _.filter(list, (d) => predicate1(d) && predicate2(d));
}

// 진료 완료
export function filterDiagnosisCompleteList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.diagnosisComplete);
  const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.user);
  const predicate3 = (d: IOoDiagnosis) => _.isEqual(isDiagnosisPaySucceed(d), true);
  return _.filter(list, (d) => (predicate1(d) && predicate3(d)) || (predicate2(d) && predicate3(d)));
}

// 의사 진료 완료
export function filterDoctorDiagnosisCompleteList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  const predicate1 = (d: IOoDiagnosis) => _.gte(d.status, OoDiagnosisStatus.diagnosisComplete);
  const predicate2 = (d: IOoDiagnosis) => _.lt(d.status, OoDiagnosisStatus.doctorCancel);
  const predicate3 = (d: IOoDiagnosis) => _.isEqual(d.wapleReservationStatus, OoWapleReservationStatus.reserved)
  return _.filter(list, (d) => (predicate1(d)) && predicate2(d) && !predicate3(d));
}

// 약 배송 신청
export function filterRequestMedicineDeliveryList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  const predicate1 = (d: IOoDiagnosis) =>
      _.isEqual(OoMedicineDeliveryType.pharmacyPackage, d.deliveryType) ||
      _.isEqual(OoMedicineDeliveryType.quickDelivery, d.deliveryType) ||
      _.isEqual(OoMedicineDeliveryType.todayDelivery, d.deliveryType) ||
      _.isEqual(OoMedicineDeliveryType.packageDelivery, d.deliveryType) ||
      _.isEqual(OoMedicineDeliveryType.kaKaoMobility, d.deliveryType);
  const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.decidedDeliveryType);
  return _.filter(list, (d) => predicate1(d) && predicate2(d));
}

// 약제비 결제 완료
export function filterDiagnosisMedicinePaySuccessList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  return _.filter(list, (d) => _.isEqual(d.status, OoDiagnosisStatus.medicineDeliveryPaymentOk));
}

// 약제비 미결제
export function filterDiagnosisMedicinePayWaitList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.medicineDeliveryPaymentOk);
  const predicate2 = (d: IOoDiagnosis) => _.isEqual(isMedicinePaySucceed(d), false);
  // const predicate3 = (d: IOoDiagnosis) => _.isEqual(isMedicineDeliveryPaySucceed(d), false);
  return _.filter(list, (d) => predicate1(d) && (predicate2(d)));
}

// 픽업 대기
export function filterPickupWaitList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  const predicate1 = (d: IOoDiagnosis) =>
      _.isEqual(OoMedicineDeliveryType.pharmacyPackage, d.deliveryType) ||
      _.isEqual(OoMedicineDeliveryType.packageDelivery, d.deliveryType) ||
      _.isEqual(OoMedicineDeliveryType.kaKaoMobility, d.deliveryType);
  const predicate2 = (d: IOoDiagnosis) => _.isEqual(OoDiagnosisStatus.medicineDeliveryAccept, d.status);
  return _.filter(list, (d) => predicate1(d) && predicate2(d));
}

// 약 배송 시작
export function filterMedicineDeliveryStart(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.medicineDeliveryStart);
  const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.pharmacyPackage);
  const predicate3 = (d: IOoDiagnosis) => _.isEqual(d.kakaoQuickStatus, OoKakaoQuickStatus.cancelBeforePayment) ||
      _.isEqual(d.kakaoQuickStatus, OoKakaoQuickStatus.matchingFailed) ||
      _.isEqual(d.kakaoQuickStatus, OoKakaoQuickStatus.feePayFailed) ||
      (
          _.gte(d.kakaoQuickStatus, OoKakaoQuickStatus.cancelAfterPaymentBeforeMatching) &&
          _.lt(d.kakaoQuickStatus, OoKakaoQuickStatus.pharmacyPickup)
      );
  return _.filter(list, (d) => predicate1(d) && (predicate2(d) || !predicate3(d)));
}

// 당일 배송 실패건
export function filterTodayDeliveryFailList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.medicineDeliveryAccept);
  const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.todayDelivery);
  const predicate3 = (d: IOoDiagnosis) => !_.isEqual(d.deliveryOrderResultCode, "100");
  return _.filter(list, (d) => predicate1(d) && predicate2(d) && predicate3(d));
}

// 배송 취소
export function filterMedicineDeliveryFail(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.kaKaoMobility);
  const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.kakaoQuickStatus, OoKakaoQuickStatus.cancelMatchingFailed);
  const predicate3 = (d: IOoDiagnosis) => _.isEqual(d.kakaoQuickStatus, OoKakaoQuickStatus.cancelBeforePayment);
  return _.filter(list, (d) => predicate1(d) && (predicate2(d) || predicate3(d)));
}

// 방문 수령
export function filterPharmacyPackageList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  return _.filter(list, (d) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.pharmacyPackage));
}

// 당일배송 / 택배
export function filterTodayPackageDeliveryList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
  return _.filter(list, (d) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.todayDelivery)
      || _.isEqual(d.deliveryType, OoMedicineDeliveryType.packageDelivery)
  );
}
