class OoPharmacySearchParam {
    public name: string;
    public pharmacyId: string;

    getPathParams(): string {
        return `${this.name}/${this.pharmacyId}`;
    }

    constructor(name: string, pharmacyId: string) {
        this.name = name;
        this.pharmacyId = pharmacyId;
    }

}

export {
    OoPharmacySearchParam
}