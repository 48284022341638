import {useMutation} from "react-query";
import {useSuspenseHook} from "@hooks/suspense";
import {OoParamDoctorUpdate} from "@params/doctor";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";

export const useDoctorInfoUpdateQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'doctor/infoUpdate',
        async (param: OoParamDoctorUpdate) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.doctor.update(param);
                setStopLoading();
                return isRequestSucceed(response) ? response.data : undefined;
            } catch (e: any) {
                throw e;
            }
        },
    )
}