import {atom, selector} from "recoil";

export const doctorDiagnosisPriceState = atom<Array<Object>>({
    key: "doctorDiagnosisPriceState",
    default: [],
});


export const doctorDiagnosisSelector = selector<Array<object>>({
    key: "doctor/doctorDiagnosisPriceState",
    get: ({get}) => get(doctorDiagnosisPriceState),
    set: ({set}, newValue) => {
        set(doctorDiagnosisPriceState, ((prevValue) => [...prevValue, newValue]));
    }
})
