import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import { productListState } from "@recoils/unospay/tlcProductState";
import { useRecoilState } from "recoil";

const ProductDetailPage = () => {
  const { externalCode } = useParams<{ externalCode: string }>();
  const [productList] = useRecoilState(productListState);
  const [productName, setProductName] = useState<string>("");
  const [providerName, setProviderName] = useState<string>("");

  useEffect(() => {
    console.log('externalCode :: ', externalCode);
    console.log('productList :: ', productList);
    const product = _.find(productList, (product) => product.externalCode === externalCode);
    console.log('product :: ', product);
    if (product) {
      setProductName(product.productName);
      setProviderName(product.providerName);
    }
  }, [externalCode, productList]);

  return (
    <Wrapper>
      <h1>{productName} - {providerName}</h1>
      <StyledIframe src={`http://api.doctoron.kr/menu_page/${externalCode}.html`} />
    </Wrapper>
  );
};

export default ProductDetailPage;

const Wrapper = styled.div`
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
    
  @media (min-height: 800px) {
    height: 85%;
  }
    
  @media (min-height: 850px) {
    height: 87%;
  }
  
  @media (min-height: 900px) {
    height: 90%;
  }
  
  @media (min-height: 950px) {
    height: 93%;
  }

  @media (min-height: 1000px) {
    height: 100%;
  }
`;

const OpenModalButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 80%;
  height: 80%;
  min-width: 800px;
  min-height: 600px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: calc(100% - 40px);
  border: none;
`;