import {useMutation} from "react-query";
import {useSuspenseHook} from "@hooks/suspense";
import {OoParamPharmacyUpdate} from "@params/pharmacy";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";

export const usePharmacyUpdateQuery = () => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();

    return useMutation(
        'operator/pharmacyUpdate',
        async (paramsData: OoParamPharmacyUpdate) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.pharmacy.update(paramsData);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        }
    )
};