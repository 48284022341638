export const mkOrderData: {
  code: string;
  info: {
      code?: string;
      price: number;
      deliveryPrice: number;
  }[];
}[] = [
    {code:"OF30_01", info:[{price: 135000 , deliveryPrice: 10000}]},
    {code:"OF30_02", info:[{price: 160000 , deliveryPrice: 10000}]},
    {code:"OF30_03", info:[{price: 210000 , deliveryPrice: 40000}]},
    {code:"OF30_04", info:[{price: 25700 , deliveryPrice: 3000}]},
    {code:"OF30_28", info:[{price: 34500 , deliveryPrice: 3000}]},
    {code:"OF30_29", info:[{price: 30500 , deliveryPrice: 3000}]},
    {code:"OF30_05", info:[{price: 120000 , deliveryPrice: 0}]},
    {code:"OF30_06", info:[{price: 5500 , deliveryPrice: 3000}]},
    {code:"OF30_31", info:[{price: 8000 , deliveryPrice: 3000}]},
    {code:"OF30_32", info:[{price: 12000 , deliveryPrice: 3000}]},
    {code:"OF30_33", info:[{price: 16000 , deliveryPrice: 3000}]},
    {code:"OF30_07", info:[{price: 4600 , deliveryPrice: 3000}]},
    {code:"OF30_08", info:[{price: 3300 , deliveryPrice: 3000}]},
    {code:"OF30_09", info:[{price: 98000 , deliveryPrice: 5000}]},
    {code:"OF30_30", info:[{price: 150000 , deliveryPrice: 10000}]},
    {code:"OF30_10", info:[{price: 210000 , deliveryPrice: 20000}]},
    {code:"OF30_11", info:[{code:'201', price: 23000 , deliveryPrice: 3000}
                          ,{code:'202', price: 27000 , deliveryPrice: 3000}
                          ]},
    {code:"OF30_12", info:[{price: 35000 , deliveryPrice: 3000}]},
    {code:"OF30_34", info:[{price: 40000 , deliveryPrice: 3000}]},
    {code:"OF30_35", info:[{price: 47000 , deliveryPrice: 3000}]},
    {code:"OF30_13", info:[{code:'201', price: 17000 , deliveryPrice: 3000}
                          ,{code:'202', price: 19000 , deliveryPrice: 3000}
                          ,{code:'203', price: 24000, deliveryPrice: 3000}
                          ,{code:'204', price: 28000, deliveryPrice: 3000}
                          ]},
    {code:"OF30_14", info:[{code:'201', price: 2300, deliveryPrice: 3000}
                          ,{code:'202', price: 2300, deliveryPrice: 3000}
                          ,{code:'203', price: 2300, deliveryPrice: 3000}
                          ,{code:'204', price: 2300, deliveryPrice: 3000}
                          ,{code:'205', price: 2300, deliveryPrice: 3000}
                          ,{code:'206', price: 2300, deliveryPrice: 3000}
                          ,{code:'207', price: 4000, deliveryPrice: 3000}
                          ,{code:'208', price: 4000, deliveryPrice: 3000}
                          ,{code:'209', price: 4000, deliveryPrice: 3000}
                          ,{code:'210', price: 4000, deliveryPrice: 3000}
                          ,{code:'211', price: 4000, deliveryPrice: 3000}
                          ,{code:'212', price: 4000, deliveryPrice: 3000}
                          ]},
    {code:"OF30_15", info:[{price: 15000, deliveryPrice:0 }]},
    {code:"OF30_37", info:[{price: 15000, deliveryPrice:0 }]},
    {code:"OF30_38", info:[{price: 15000, deliveryPrice:0 }]},
    {code:"OF30_39", info:[{price: 15000, deliveryPrice:0 }]},
    {code:"OF30_16", info:[{price: 6000, deliveryPrice: 3000}]},
    {code:"OF30_17", info:[{price: 15000, deliveryPrice: 3000}]},
    {code:"OF30_18", info:[{code:'201', price: 1300, deliveryPrice: 3000}
                          ,{code:'202', price: 2000, deliveryPrice: 3000}
                          ,{code:'203', price: 2500, deliveryPrice: 3000}
                          ,{code:'204', price: 2700, deliveryPrice: 3000}
                          ,{code:'205', price: 3000, deliveryPrice: 3000}
                          ,{code:'206', price: 10000, deliveryPrice: 3000}
                          ,{code:'207', price: 5000, deliveryPrice: 3000}
                          ,{code:'208', price: 5000, deliveryPrice: 3000}
                          ,{code:'209', price: 5000, deliveryPrice: 3000}
                          ,{code:'210', price: 5000, deliveryPrice: 3000}
                          ,{code:'211', price: 5000, deliveryPrice: 3000}
                          ,{code:'212', price: 5000, deliveryPrice: 3000}
                          ,{code:'213', price: 5000, deliveryPrice: 3000}
                          ,{code:'214', price: 5000, deliveryPrice: 3000}
                          ,{code:'215', price: 5000, deliveryPrice: 3000}
                          ]},
    {code:"OF30_20", info:[{price: 8500, deliveryPrice: 3000}]},
    {code:"OF30_40", info:[{price: 9000, deliveryPrice: 3000}]},
    {code:"OF30_41", info:[{price: 11000, deliveryPrice: 3000}]},
    {code:"OF30_42", info:[{price: 12000, deliveryPrice: 3000}]},
    {code:"OF30_21", info:[{code:'201', price: 3500, deliveryPrice: 3000}
                          ,{code:'202', price: 3500, deliveryPrice: 3000}
                          ,{code:'203', price: 9000, deliveryPrice: 3000}
                          ,{code:'204', price: 12000, deliveryPrice: 3000}
                        ]},
    {code:"OF30_22", info:[{code:'201', price: 2500, deliveryPrice: 3000}
                          ,{code:'202', price: 4500, deliveryPrice: 3000}
                          ,{code:'203', price: 5800, deliveryPrice: 3000}
                          ,{code:'204', price: 8000, deliveryPrice: 3000}
                          ,{code:'205', price: 11000, deliveryPrice: 3000}
                          ,{code:'206', price: 16000, deliveryPrice: 3000}
                          ,{code:'207', price: 19000, deliveryPrice: 3000}
                          ,{code:'208', price: 5000, deliveryPrice: 3000}
                          ,{code:'209', price: 5000, deliveryPrice: 3000}
                          ,{code:'210', price: 5000, deliveryPrice: 3000}
                          ,{code:'211', price: 5000, deliveryPrice: 3000}
                          ,{code:'212', price: 5000, deliveryPrice: 3000}
                          ,{code:'213', price: 5000, deliveryPrice: 3000}
                          ,{code:'214', price: 5000, deliveryPrice: 3000}
                          ,{code:'215', price: 5000, deliveryPrice: 3000}
                          ,{code:'216', price: 5000, deliveryPrice: 3000}
                        ]},
    {code:"OF30_23", info:[{code:'201', price: 12000, deliveryPrice: 3000}
                          ,{code:'202', price: 14000, deliveryPrice: 3000}
                        ]},
    {code:"OF30_24", info:[{code:'201', price: 4000, deliveryPrice: 3000}
                          ,{code:'202', price: 6000, deliveryPrice: 3000}
                          ,{code:'203', price: 8000, deliveryPrice: 3000}
                          ,{code:'204', price: 10000, deliveryPrice: 3000}
                          ,{code:'205', price: 12000, deliveryPrice: 3000}
                          ,{code:'206', price: 17000, deliveryPrice: 3000}
                        ]},
    {code:"OF30_25", info:[{code:'201', price: 70000, deliveryPrice: 7000}
                          ,{code:'202', price: 118000, deliveryPrice: 7000}
                        ]},
    {code:"OF30_26", info:[{code:'201', price: 96000, deliveryPrice: 10000}
                          ,{code:'202', price: 125000, deliveryPrice: 10000}
                          ,{code:'203', price: 125000, deliveryPrice: 10000}
                        ]},
    {code:"OF30_27", info:[{price: 17000, deliveryPrice: 3000}]},
  ];