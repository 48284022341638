import {Spinner} from "react-bootstrap";
import React from "react";
import {useRecoilValue} from "recoil";
import {ModalOverlay} from "@components/modal";
import {progressState} from "@recoils/progressStatus";


export const LoadingModal = () => {
    const progress = useRecoilValue(progressState);

    return (
        <ModalOverlay visible={progress.loading}>
            <Spinner animation="border" variant="primary"/>
        </ModalOverlay>
    )
}