import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {DoctorBasicInfo} from "../../../../interfaces";
import {IOoDoctor} from "@models/doctor";
import {CustomInputText} from "@components/custom";
import {InitialImageUpload} from "@components/initialImageUpload";
import {useBasicInfoSettingHook} from "@components/registerForm";
import { OoParamDoctorCreate } from "@api/params";
import _ from "lodash";

interface Props {
    onChanged: (items: DoctorBasicInfo) => void;
    doctorInfo: IOoDoctor;
    param:OoParamDoctorCreate
}

export const DoctorRegisterBasicInfo = ({onChanged, doctorInfo , param}: Props) => {
  const [isChecked, setIsChecked] = useState(false);
    const {
        items,
        onChangeEmail,
        onChangePassword,
        onChangeName,
        onChangeMobile,
        onChangePicture,
        onChangeCheckBox
    } = useBasicInfoSettingHook();
    
    useEffect(() => {
        onChanged(items);
    }, [items]);

    return (
        <Wrapper>
            <div>
                <div>
                    <Table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>이름</td>
                            <td>
                                <CustomInputText value={param.name}
                                                 onChanged={onChangeName}
                                                 color="blue"
                                                 size="middle"
                                                 placeholder="[필수]"
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>전화번호</td>
                            <td>
                                <CustomInputText value={param.mobile}
                                                 onChanged={onChangeMobile}
                                                 color="blue"
                                                 size="middle"
                                                 placeholder="- 없이 입력 [필수]"
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>이메일</td>
                            <td>
                                <CustomInputText value={param.email}
                                                 onChanged={onChangeEmail}
                                                 color="blue"
                                                 size="middle"
                                                 placeholder="아이디@doctoron.kr [필수]"
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>비밀번호</td>
                            <td>
                                <CustomInputText value={param.password}
                                                 onChanged={onChangePassword}
                                                 color="blue"
                                                 size="middle"
                                                 placeholder="8자리 이상 [필수]"
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>정산용 확인여부</td>
                            <td>
                              <CheckboxContainer>
                                <label htmlFor="calculateAccount_Y"><input type="checkbox" id="calculateAccount_Y" value={1}onChange={onChangeCheckBox} checked={items.isCalculateAccount}/><span>Y</span></label>
                                <label htmlFor="calculateAccount_N"><input type="checkbox" id="calculateAccount_N" value={0} onChange={onChangeCheckBox} checked={!items.isCalculateAccount} /><span>N</span></label>
                              </CheckboxContainer>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
                <div className={"image-container"}>
                    <InitialImageUpload target="doctor"
                                        onChanged={onChangePicture}/>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }

  .image-container {
    padding-left: 50px;
  }
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  & tbody tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  & tbody tr td:first-child {
    font-weight: 700;
    font-size: 13px;
  }

  & tbody td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
  }

  & tbody td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-between;

  & > label {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > span {
      margin-left: 5px;
    }
  }
`;