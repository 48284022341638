import React from 'react'
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import { IOoUnospayDelivery, IOoUnospaySalesMenu, IOoUnospaySalesMenuOption, OoDeliveryStatus, OoProvider } from '@api/models';
import { IOoProducts } from '@api/models/productSetting/product';
const ExcelJS = require("exceljs");

const createModel = (e:IOoUnospayDelivery, salesItem:IOoUnospaySalesMenu) => {
  const model = new ExcelExportModel();
                model.name = e.recipientName;
                model.address1 = e.address;
                model.address2 = e.addressDetail;
                model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                model.zipCode = e.zipCode;
                model.memo = e.memo;
                model.subMemo = e.subMemo;
                model.quantity = "1";
                model.exCode = salesItem.externalCode;
  return model;
}

const mmxOrder = async (list:IOoUnospayDelivery[], mtsProductList:IOoProducts[]) => {
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.mmxProvider));
    let data_array: Array<ExcelExportModel> = [];

    const underBarStartsWithCheck = (code:string, optionCode:string) => {
      if(!_.isUndefined(optionCode) && _.startsWith(optionCode,'_')){
          return `${code}_${optionCode.replaceAll('_', '')}`;
      }else if(!_.isUndefined(optionCode) && _.endsWith(optionCode,'_')){
          return `${code}_${optionCode.replaceAll('_', '')}`;
      }else if(optionCode.length !== 0){
          return `${code}_${optionCode}`;
      }else{
          return `${code}`
      }
  }

      
  const findProductName = (externalCode: string , salesOption?:IOoUnospaySalesMenuOption[]) => {
    const productName =  mtsProductList.find(product => product.externalCode == externalCode)?.productName || '';
    return productName.replaceAll('하프앤하프','').trim();
  }
  console.log(excelList);
  
  const filterList = excelList.filter(item => item.salesMenu?.some( sale => {
      if(!_.isUndefined(sale.salesOption) && sale.salesOption.length > 0){
          const makeCode = underBarStartsWithCheck(sale.externalCode!, `${sale.salesOption.map(sale => !_.isUndefined(sale.externalCode) && sale.externalCode).join('_')}`);
          console.log(mtsProductList);
          console.log(makeCode);
          if(mtsProductList.find(product => product.externalCode === makeCode)){
              return item;
          }
      }else{
          if(mtsProductList.find(product => product.externalCode === sale.externalCode)){
              return item;
          }
      }
  }));

  filterList.forEach(e => {
        if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
            e.salesMenu?.forEach(salesItem => {
                if(_.startsWith(salesItem.externalCode, 'OF41')){
                  if(salesItem.salesOption){
                    let model = createModel(e, salesItem);
                    const code = salesItem.externalCode!;
                    const optionCode = underBarStartsWithCheck(code, `${salesItem.salesOption.map(sale => !_.isUndefined(sale.externalCode) && sale.externalCode).join('_')}`);
                    
                    model.product = findProductName(optionCode);
                    data_array.push(model);
                    }
                }
              })
        }
    })
    console.log(data_array);
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferMMX = await (await fetch("/assets/mmx.xlsx")).arrayBuffer();
    const workbookMMX = new ExcelJS.Workbook();
    await workbookMMX.xlsx.load(arrayBufferMMX);
    const ws_mmx = workbookMMX.getWorksheet("1");
    data_array.map((data: ExcelExportModel, index: number) => {
      const row = ws_mmx.getRow(index + 2);
      [
        index+1,
        '티엘씨헬스케어',
        data.name,
        data.tel1? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
        `${data.address1} ${data.address2}`,
        data.product,
        data.quantity,
        data.memo,
        '하프앤하프',
        "",
        "070-8670-8399"
    ].forEach((value, index) => {
        const cell = row.getCell(index + 1);
        cell.value = value;
      })
    });
    const excelBuffer = await workbookMMX.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName + "_" + moment().format("YYYYMMDD") + "(모먼트엑스)" + excelFileExtension
    );

}

export default mmxOrder