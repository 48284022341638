import {useSuspenseHook} from "@hooks/suspense";
import {useQuery} from "react-query";
import {toast} from "react-toastify";
import {useSetRecoilState} from "recoil";
import {doctorAlimTalkPushMessageModalState} from "@recoils/doctor";
import {OoParamDoctorNotification} from "@models/notification/notification";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";

interface Props {
    queryTrigger: boolean,
    userId: string,
    diagnosisId: string,
    message: string,
    title?: string,
}

export const useDoctorSendPushMessageQuery = ({queryTrigger, userId, diagnosisId, message, title}: Props) => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const setPushMessageModalVisible = useSetRecoilState(doctorAlimTalkPushMessageModalState);
    return useQuery(
        'doctor/doctorMessage',
        async () => {
            try {
                setLoading();
                const type = "com.doctorOn.doctorMessage";
                const param = new OoParamDoctorNotification(type, userId, diagnosisId, message, title || "");
                const response = await DoctorOnAPI.shared.pushNotification.doctorMessage(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    setPushMessageModalVisible(false);
                    toast.success('메세지 전송 완료');
                    return response;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: queryTrigger,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )
}