import {IOoDiagnosis} from "./diagnosis";

interface HospitalState {
    hospitalName: string,
    doctorName: string,
    hospitalTel: string,
    hospitalAddress: string,
}

export function diagnosisHospitalState(diagnosis: IOoDiagnosis): HospitalState {
    return {
        hospitalName: diagnosis.hospitalName,
        doctorName: diagnosis.doctorName,
        hospitalTel: diagnosis.hospitalTel,
        hospitalAddress: diagnosis.hospitalAddress,
    }
}

export type {HospitalState};