import {IOoDiagnosis, isDiagnosisPaySucceed, OoDiagnosisStatus} from "@api/models";
import {DiagnosisBackgroundColor, DiagnosisStatusColor} from "../diagnosis/diagnosisProcessingState";
import {getDiagnosisReservationState, isReservation} from "./doctorDiagnosisReservationState";

interface DiagnosisProcessingState {
    title: string;
    color: DiagnosisStatusColor;
    background: DiagnosisBackgroundColor;
    timestamp: number;
}

export function getDoctorDiagnosisStatus(diagnosis: IOoDiagnosis): DiagnosisProcessingState {
    if (isReservation(diagnosis)) {
        return getDiagnosisReservationState(diagnosis);
    }

    let statusData = {
        title: "진료 완료",
        color: DiagnosisStatusColor.green,
        background: DiagnosisBackgroundColor.green,
        timestamp: diagnosis.timestamp,
    };
    switch (diagnosis.status) {
        case OoDiagnosisStatus.request:
            statusData = {
                title: "진료 신청",
                color: DiagnosisStatusColor.default,
                background: DiagnosisBackgroundColor.success,
                timestamp: diagnosis.timestamp,
            };
            break;
        case OoDiagnosisStatus.accept:
            statusData = {
                title: "진료 접수 완료",
                color: DiagnosisStatusColor.success,
                background: DiagnosisBackgroundColor.success,
                timestamp: diagnosis.timestamp,
            };
            break;
        case OoDiagnosisStatus.diagnosisComplete:
            if (isDiagnosisPaySucceed(diagnosis)) {
                statusData = {
                    title: "진료 완료",
                    color: DiagnosisStatusColor.green,
                    background: DiagnosisBackgroundColor.green,
                    timestamp: diagnosis.timestamp,
                };
            } else {
                statusData = {
                    title: "진료비 결제 실패",
                    color: DiagnosisStatusColor.cancel,
                    background: DiagnosisBackgroundColor.fail,
                    timestamp: diagnosis.timestamp,
                };
            }
            break;
        case OoDiagnosisStatus.doctorCancel:
            statusData = {
                title: "병원 진료 취소",
                color: DiagnosisStatusColor.cancel,
                background: DiagnosisBackgroundColor.fail,
                timestamp: diagnosis.timestamp,
            };
            break;
        case OoDiagnosisStatus.userCancel:
            statusData = {
                title: "사용자 진료 취소",
                color: DiagnosisStatusColor.cancel,
                background: DiagnosisBackgroundColor.fail,
                timestamp: diagnosis.timestamp,
            };
            break;
        case OoDiagnosisStatus.operatorCancel:
            statusData = {
                title: "관리자 진료 취소",
                color: DiagnosisStatusColor.cancel,
                background: DiagnosisBackgroundColor.fail,
                timestamp: diagnosis.timestamp,
            };
            break;
        case OoDiagnosisStatus.pharmacistCancel:
            statusData = {
                title: "약사 취소",
                color: DiagnosisStatusColor.cancel,
                background: DiagnosisBackgroundColor.fail,
                timestamp: diagnosis.timestamp,
            };
            break;
    }
    return statusData;
}

export type {DiagnosisProcessingState};
