import {Link} from "react-router-dom";
import _ from "lodash";
import React, {useEffect} from "react";
import classNames from "classnames/bind";
import buttonStyles from "../../../../../styles/common/Button.module.scss";
import {
    filterDisplayedDiagnosis,
    getDiagnosisPriceStatus,
    getMedicinePayStatus,
    getPatientInfo,
    getPatientRequestState,
    IOoDiagnosis
} from "@api/models";
import styled from "styled-components";
import {ReservationType} from "./ReservationType";
import {DiagnosisType} from "./DiagnosisType";
import {useDiagnosisListContainerSettingHook} from "../../hooks/useDiagnosisListContainerSettingHook";
import {getDeliveryStatusTitle} from "@models/diagnosis/function/fnDiagnosisTitleFilter";
import {DiagnosisStatusField} from "@components/diagnosisStatusField";
import {PatientInfoField} from "@components/patientInfoField";
import {FlexDiv} from "@components/flexDiv";
import {formatDateMDhhmm, formatDateYyyyMMDHHmmss, formatHHMM, formatTel} from "@hooks/format";
import {ListStatusField} from "@components/listStatusField";
import {getPharmacyMedicineDeliveryPayStatus} from "@models/pharmacy";
import {useModalHook} from "@hooks/modal";
import {useDoctorSendPushMessageQuery} from "@queries/doctor";
import {useDoctorFunctionHook} from "@hooks/doctor";
import {getDoctorDiagnosisStatus} from "@models/doctor/doctorDiagnosisProcessingState";

interface Props {
    list: Array<IOoDiagnosis>,
}

export const DiagnosisListContainer = ({list}: Props) => {
    const bx = classNames.bind(buttonStyles);

    const {
        filteredList,
        setFilteredList,
        calendarParam,
        searchParam,
        memorizedList,
        findFamilyList,
        isShowAlimTalkButton,
        statusList,
        value,
        setValue,
        queryTrigger,
        setQueryTrigger,
        isVisitConfirm,
    } = useDiagnosisListContainerSettingHook(list);

    useEffect(() => {
        setFilteredList(statusList);
    }, [memorizedList]);

    const {addModal} = useModalHook();
    const {confirmVisitComplete, sendWaitNotification} = useDoctorFunctionHook();

    const onClickAlimTalkButton = (target: IOoDiagnosis) => {
        addModal({
            type: 'twoBtnModal',
            data: {
                title: "진료 대기 알림",
                contents: <div>
                    <div>5분 이내에 진료가 가능한 경우에만 알림을 보내주세요.</div>
                    <div>{target.patientName}님에게 진료 대기 알림을 보내시겠습니까?</div>
                    <div>(진료 대기 알림은 여러 번 전송 가능합니다.)</div>
                </div>,
                goBack: false,
                buttons: [
                    {title: "취소",},
                    {title: "보내기", callback: () => sendWaitNotification(target.id || "")}]
            }
        })
    };

    const resp = useDoctorSendPushMessageQuery({
        queryTrigger: queryTrigger,
        userId: value.userId,
        diagnosisId: value.diagnosisId || "",
        message: value.message,
        title: value.title
    });

    useEffect(() => {
        setQueryTrigger(false);
    }, [resp]);

    const onClickPushMessageButton = (target: IOoDiagnosis) => {
        addModal({
            type: 'twoBtnModal',
            data: {
                title: "원격진료 접수를 확인해 주세요!",
                contents: <div>
                    <div>접수번호 : {formatDateYyyyMMDHHmmss(target.timestamp || 0)}</div>
                    <div>접수 시각 : {formatHHMM(target.timestamp || 0)}</div>
                    <div>이름 : {target.patientName}</div>
                    <TextAreaContainer>
                        <div className="title">환자에게 전달할 내용을 입력해 주세요.</div>
                        <textarea className="textarea"
                                  placeholder="예) 2시경 진료 시작 예정입니다."
                                  onChange={(e) => setValue({
                                      ...value,
                                      userId: target.patientId,
                                      diagnosisId: target.id || "",
                                      message: e.target.value
                                  })}/>
                    </TextAreaContainer>
                </div>,
                goBack: false,
                buttons: [
                    {title: "취소",},
                    {
                        title: "확인", callback: (e) => setQueryTrigger(e)
                    }]
            }
        })
    };

    return (
        <Wrapper>
            {
                _.gt(filterDisplayedDiagnosis(filteredList).length, 0) ?
                    <Container>
                        {
                            filterDisplayedDiagnosis(filteredList).map((item, index) => {
                                const status = {
                                    patientInfo: getPatientInfo(item),
                                    processStatus: getDoctorDiagnosisStatus(item),
                                    diagnosisPrice: getDiagnosisPriceStatus(item),
                                    prescriptionPrice: getMedicinePayStatus(item),
                                    deliveryPay: getPharmacyMedicineDeliveryPayStatus(item),
                                    deliveryType: getDeliveryStatusTitle(item),
                                    requestType: getPatientRequestState(item),
                                };
                                return (
                                    <div key={index}>
                                        {
                                            isVisitConfirm(item) ?
                                                <StatusRowWithButton key={item.timestamp}>
                                                    <Button key={`${item.id}${index}`}
                                                            type="button"
                                                            onClick={() =>
                                                                addModal({
                                                                    type: 'twoBtnModal',
                                                                    data: {
                                                                        title: "내원 확인",
                                                                        contents:
                                                                            <div style={{textAlign: "left"}}>
                                                                                <div>접수번호
                                                                                    : {formatDateYyyyMMDHHmmss(item.timestamp)}</div>
                                                                                <div>예약일시
                                                                                    : {formatDateMDhhmm(item.timestamp)}</div>
                                                                                <div>이름 : {item.patientName}</div>
                                                                                <br/>
                                                                                <div>환자가 내원하셨으면 아래 "내원확인" 버튼을 눌러주세요.
                                                                                </div>
                                                                                <div>(진료 대기열에 추가합니다.)</div>
                                                                            </div>
                                                                        ,
                                                                        buttons: [{title: "취소"}, {
                                                                            title: "내원 확인",
                                                                            callback: () => confirmVisitComplete(item.id || "")
                                                                        }],
                                                                        goBack: false,
                                                                    }
                                                                })}>
                                                        <DiagnosisStatusField status={status.processStatus}
                                                                              flex={1.1}/>
                                                        <PatientInfoField title={status.patientInfo.patientName}
                                                                          isShowTag={!_.isEqual(status.patientInfo.familyKey, 0)}
                                                                          tagTitle={"가족"}
                                                                          flex={1}/>
                                                        <FlexDiv flex={1.1}>{formatTel(item.patientTel)}</FlexDiv>
                                                        <DiagnosisType list={item} flex={1}/>
                                                        <ReservationType target={item}
                                                                         list={list}
                                                                         familyList={findFamilyList(item)}
                                                                         flex={1}/>
                                                        <ListStatusField color={status.diagnosisPrice.color}
                                                                         title={status.diagnosisPrice.title}
                                                                         flex={1}/>
                                                        <ListStatusField color={status.prescriptionPrice.color}
                                                                         title={status.prescriptionPrice.title}
                                                                         flex={1.1}/>
                                                        <ListStatusField color={status.deliveryType}
                                                                         title={status.deliveryType}
                                                                         flex={1.1}/>
                                                    </Button>
                                                    <FlexDiv flex={0.5}>
                                                        <button id={item.id}
                                                                type="button"
                                                                className={bx('waiting-button', isShowAlimTalkButton(item) ? 'blue' : 'grey')}
                                                                onClick={() =>
                                                                    addModal({
                                                                        type: 'multiBtnModal',
                                                                        data: {
                                                                            title: "접수 알림",
                                                                            contents:
                                                                                <div>
                                                                                    환자에게 알림톡 또는 푸시 메세지를 보낼 수 있습니다.
                                                                                </div>,
                                                                            buttons: [{title: "접수 알림톡 보내기"}, {title: "푸시 메세지 보내기"}, {title: "닫기"}]
                                                                        }
                                                                    })}
                                                                disabled={!isShowAlimTalkButton(item)}>
                                                            보내기
                                                        </button>
                                                    </FlexDiv>
                                                </StatusRowWithButton>
                                                :
                                                <StatusRow key={item.id || ""}>
                                                    <Link to={'/prescription'}
                                                          state={{
                                                              diagnosisList: _.concat(item, findFamilyList(item)),
                                                              calendarParam: calendarParam,
                                                              searchParam: searchParam
                                                          }}>
                                                        <DiagnosisStatusField status={status.processStatus}
                                                                              flex={1.1}/>
                                                        <PatientInfoField title={status.patientInfo.patientName}
                                                                          isShowTag={!_.isEqual(status.patientInfo.familyKey, 0)}
                                                                          tagTitle={"가족"}
                                                                          flex={0.9}/>
                                                        <FlexDiv flex={1.1}>{formatTel(item.patientTel)}</FlexDiv>
                                                        <DiagnosisType list={item} flex={0.9}/>
                                                    </Link>
                                                    <ReservationType target={item}
                                                                     list={list}
                                                                     familyList={findFamilyList(item)}
                                                                     flex={1}/>
                                                    <Link to={`/prescription`}
                                                          state={{
                                                              diagnosisList: _.concat(item, findFamilyList(item)),
                                                              calendarParam: calendarParam,
                                                              searchParam: searchParam
                                                          }}>
                                                        <ListStatusField color={status.diagnosisPrice.color}
                                                                         title={status.diagnosisPrice.title}
                                                                         flex={1}/>
                                                        <ListStatusField color={status.prescriptionPrice.color}
                                                                         title={status.prescriptionPrice.title}
                                                                         flex={1.1}/>
                                                        <ListStatusField color={status.deliveryType}
                                                                         title={status.deliveryType}
                                                                         flex={1.1}/>
                                                    </Link>
                                                    <FlexDiv flex={0.8}>
                                                        <button id={item.id || ""}
                                                                type="button"
                                                                className={bx('waiting-button', isShowAlimTalkButton(item) ? 'blue' : 'grey')}
                                                                onClick={() =>
                                                                    addModal({
                                                                        type: 'multiBtnModal',
                                                                        data: {
                                                                            title: "접수 알림",
                                                                            contents:
                                                                                <div>
                                                                                    환자에게 알림톡 또는 푸시 메세지를 보낼 수 있습니다.
                                                                                </div>,
                                                                            buttons: [{
                                                                                title: "접수 알림톡 보내기",
                                                                                callback: () => onClickAlimTalkButton(item)
                                                                            }, {
                                                                                title: "푸시 메세지 보내기",
                                                                                callback: () => onClickPushMessageButton(item)
                                                                            }, {title: "닫기"}]
                                                                        }
                                                                    })}
                                                                disabled={!isShowAlimTalkButton(item)}>
                                                            보내기
                                                        </button>
                                                    </FlexDiv>
                                                </StatusRow>
                                        }
                                    </div>
                                )
                            })
                        }
                    </Container>
                    :
                    <EmptyDiagnosisList>
                        <div>오늘의 진료 요청이 없습니다.</div>
                        <div>오늘 진료 요청이 들어올 경우 자동 업데이트됩니다.</div>
                    </EmptyDiagnosisList>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 80%;
  max-width: 1280px;
  min-width: 1024px;
  height: 100%;
  overflow-y: auto;
  margin-top: 418px;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  overflow-y: auto;
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    width: 100%;
    max-width: 1280px;
    min-width: 1024px;
  }
`;

const StatusRow = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 3px -2px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 4px;

  & > a:nth-child(1) {
    height: 64px;
    color: #030303;
    text-decoration: none;
    display: flex;
    flex: 4;
    justify-content: center;
    align-items: center;

    &:hover {
      color: #030303;
      text-decoration: none;
    }
  }

  & > a:nth-child(3) {
    height: 64px;
    color: #030303;
    text-decoration: none;
    display: flex;
    flex: 3;
    justify-content: center;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: #030303;
      text-decoration: none;
    }
  }
`;

const StatusRowWithButton = styled(StatusRow)`
  justify-content: space-between;
`;

const Button = styled.button`
  border: none;
  padding: 0;
  background: #FFFFFF;
  display: flex;
  flex: 5;
  align-items: center;
`;

const EmptyDiagnosisList = styled.div`
  width: 1024px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TextAreaContainer = styled.div`
  width: 100%;
  height: 160px;
  padding-top: 45px;

  .title {
    text-align: left;
    font-size: 13px;
    font-weight: 800;
    padding-bottom: 5px;
  }

  .textarea {
    width: 100%;
    height: 100px;
    resize: none;
    border-radius: 8px;
    padding: 5px;
  }
`;

