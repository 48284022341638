import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {PharmacyLedgerColumn} from "./PharmacyLedgerColumn";

export const PharmacyLedgerPage = () => {
    return (
        <Container className="pt-4">
            <Col className="pb-4">
                <Button type="button">달력 2022년 07월 </Button>
            </Col>
            <Card>
                <Container className="d-flex flex-row pt-2 pb-2">
                    <Row className="col-md-6">
                        <Col className=" d-flex justify-content-evenly ">
                            <Button type="button">총 환불 금액</Button>
                            <Button type="button">총 결제 금액</Button>
                            <Button type="button">정산 금액</Button>
                            <Button type="button">카드 수수료: 2.1%</Button>
                        </Col>
                    </Row>
                    <Row className="col-md-6">
                        <Col className="d-flex justify-content-end">
                            <Button type="button">엑셀 저장</Button>
                        </Col>
                    </Row>
                </Container>
            </Card>
            <PharmacyLedgerColumn/>
        </Container>
    )
}