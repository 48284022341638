import {atom} from "recoil";
import {IOoPharmacy} from "@models/pharmacy";

interface SearchProps {
    userName?: string,
    list: Array<IOoPharmacy>,
}

export const pharmacySearchByNameState = atom<SearchProps>({
    key: 'pharmacySearchListState',
    default: {
        userName: '',
        list: [],
    }
});