import _ from "lodash";
import React, {useEffect} from "react";
import {OoParamPharmacyUpdate} from "@api/params";
import classNames from "classnames/bind";
import SelectStyles from "../../../../styles/common/Select.module.scss";
import InputStyles from "../../../../styles/common/Input.module.scss";
import {useTargetPharmacyHook} from "../hooks/useTargetPharmacyHook";
import {HospitalSearchField} from "../../../hospitalSearchField";
import {CustomInputText} from "@components/custom";
import styled from "styled-components";
import {IOoPharmacy, PharmacyDeliveryType} from "@models/pharmacy";

interface Props {
    data: IOoPharmacy,
    componentName: string,
}

export const PharmacyTargetHospital = ({data, componentName}: Props) => {
    const ix = classNames.bind(InputStyles);
    const sx = classNames.bind(SelectStyles);
    const {
        editStatus,
        items,
        setItems,
        onHospitalChange,
        onCheckedToday,
        onCheckedPackage,
        onChangeTodayPrice,
        onChangePackagePrice,
        onSelectedHitCount,
        onChangeTodayKeywordList,
        findDeliveryChecked,
        updatePharmacy,
        findTargetHospitalName,
        isReset,
        hospitalName,
        setHospitalName,
    } = useTargetPharmacyHook(data,componentName);

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        const name = findTargetHospitalName(data);
        setHospitalName(name || "-");
    }, [findTargetHospitalName]);

    // 편집 모드 일때, border 색상 활성화
    useEffect(() => {
        if (!editStatus.isEdit) {
            setItems({
                hospitalIdList: data.hospitalIdList || [],
                deliveryTypeList: data.deliveryTypeList || [],
                deliverTodayPrice: data.deliverTodayPrice || 0,
                deliverQuickPrice: data.deliverQuickPrice || 0,
                deliverPackagePrice: data.deliverPackagePrice || 0,
                deliverPickupPrice: data.deliverPickupPrice || 0,
                todayRegionKeywordList: data.todayRegionKeywordList || [],
                todayRegionKeywordHitCount: data.todayRegionKeywordHitCount || 2,
            })
        }
    }, [editStatus.isEdit]);

    useEffect(() => {
        setItems({
            hospitalIdList: data.hospitalIdList || [],
            deliveryTypeList: data.deliveryTypeList || [],
            deliverTodayPrice: data.deliverTodayPrice || 0,
            deliverQuickPrice: data.deliverQuickPrice || 0,
            deliverPackagePrice: data.deliverPackagePrice || 0,
            deliverPickupPrice: data.deliverPickupPrice || 0,
            todayRegionKeywordList: data.todayRegionKeywordList || [],
            todayRegionKeywordHitCount: data.todayRegionKeywordHitCount || 2,
        })
    }, [data]);

    // 전송 모드 일때, 쿼리 trigger
    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamPharmacyUpdate();
            param.id = data.id || "";
            param.hospitalIdList = items.hospitalIdList;
            param.deliveryTypeList = items.deliveryTypeList;
            param.todayRegionKeywordHitCount = items.todayRegionKeywordHitCount;
            param.todayRegionKeywordList = items.todayRegionKeywordList;
            param.deliverTodayPrice = _.toNumber(items.deliverTodayPrice);
            param.deliverQuickPrice = items.deliverQuickPrice;
            param.deliverPackagePrice = items.deliverPackagePrice;
            param.deliverPickupPrice = items.deliverPickupPrice;
            updatePharmacy(param);
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <div className="select-container">
                {
                    editStatus.isEdit ?
                        <HospitalSearchField onChange={onHospitalChange}
                                             targetHospital={hospitalName}/>
                        :
                        <div style={{display: "flex", width: "100%"}}>
                            <CustomInputText size="large"
                                             value={hospitalName}
                                             disabled={editStatus.isEdit}/>
                        </div>
                }
            </div>
            <div className="common-div">
                <div className="title">배송 옵션 [필수]</div>
                <InputContainer>
                    <div>
                        <input type="checkbox"
                               id={`${PharmacyDeliveryType.today}`}
                               checked={findDeliveryChecked(PharmacyDeliveryType.today)}
                               onChange={onCheckedToday}
                               disabled={!editStatus.isEdit}/>
                        <label htmlFor={`${PharmacyDeliveryType.today}`}>당일배송</label>
                        <input type="text"
                               defaultValue={`${items.deliverTodayPrice}`}
                               className={ix("Input", "xSmall", !editStatus.isEdit ? "grey" : "blue")}
                               placeholder="가격 입력"
                               onChange={onChangeTodayPrice}
                               disabled={!editStatus.isEdit}
                               hidden={!findDeliveryChecked(PharmacyDeliveryType.today)}/>
                    </div>
                    <div>
                        <input type="checkbox"
                               id={`${PharmacyDeliveryType.package}`}
                               checked={findDeliveryChecked(PharmacyDeliveryType.package)}
                               onChange={onCheckedPackage}
                               disabled={!editStatus.isEdit}/>
                        <label htmlFor={`${PharmacyDeliveryType.package}`}>택배</label>
                        <input type="text"
                               defaultValue={`${items.deliverPackagePrice}`}
                               className={ix("Input", "xSmall", !editStatus.isEdit ? "grey" : "blue")}
                               placeholder="가격 입력"
                               onChange={onChangePackagePrice}
                               disabled={!editStatus.isEdit}
                               hidden={!findDeliveryChecked(PharmacyDeliveryType.package)}/>
                    </div>
                </InputContainer>
            </div>
            <div className="common-div">
                <div className="title">당일 배송 가능지역 설정[필수]</div>
                <CustomInputText value={_.join(items.todayRegionKeywordList)}
                                 size="large"
                                 color={!editStatus.isEdit ? "grey" : "blue"}
                                 placeholder=",로 구분하여 동읍면리를 입력해 주세요. [필수]"
                                 onChanged={onChangeTodayKeywordList}
                                 disabled={editStatus.isEdit}/>
            </div>
            <div className="common-div">
                <div className="title">히트카운트[필수]</div>
                {
                    editStatus.isEdit ?
                        <select className={sx("select", "middle", "blue")}
                                onChange={onSelectedHitCount}
                                defaultValue={data.todayRegionKeywordHitCount}>
                            <DisabledOption value="" disabled>
                                검색어 노출 개수를 선택해 주세요.
                            </DisabledOption>
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                        </select>
                        :
                        <CustomInputText size="middle"
                                         onChanged={onSelectedHitCount}
                                         value={`${items.todayRegionKeywordHitCount}`}
                                         disabled={editStatus.isEdit}/>
                }
            </div>
        </Wrapper>
    );
};

const DisabledOption = styled.option`
  &:disabled {
    display: none;
  }
`;

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  .select-container {
    padding-top: 8px;
    position: relative;
  }

  .common-div {
    padding-top: 28px;
  }

  .title {
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > div {
    padding-right: 23px;
    display: flex;
    align-items: center;

    & > label {
      margin-right: 8px;
      font-weight: 700;
      font-size: 13px;
    }

    & > input {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
  }
`;
