import styled from "styled-components";
import {useCardEdit} from "@hooks/common";
import _ from "lodash";
import {useRecoilValue} from "recoil";
import {editSelectorFamily} from "@recoils/status";
import React, {useEffect} from "react";
import classNames from "classnames/bind";
import buttonStyles from "../../../styles/common/Button.module.scss";

interface Props {
    children: JSX.Element;
    buttonHidden: boolean;
    editButton?: boolean;
    submitButton?: boolean;
    reset?: boolean;
    isEdit?: (trigger: boolean) => void;
    title?: string;
    subTitle?: string;
    component?: string;
    hidden?: boolean;
    submitButtonTitle?: string;
    editButtonTitle?: string;
    submitButtonDisabled?: boolean;
}

export const CustomCardNoBorder = ({
                                       children,
                                       buttonHidden,
                                       title,
                                       subTitle,
                                       component,
                                       hidden,
                                       submitButtonTitle,
                                       editButtonTitle,
                                       submitButtonDisabled
                                   }: Props) => {
    const bx = classNames.bind(buttonStyles);

    const {isEdit, isSubmit, isReset} = useCardEdit(_.isUndefined(component) ? "" : component);

    const edit = useRecoilValue(
        editSelectorFamily(_.isUndefined(component) ? "" : component)
    );

    const onEdit = (e: any) => {
        e.preventDefault();
        isEdit();
    };

    const onCancel = (e: any) => {
        e.preventDefault();
        isReset();
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        isSubmit();
    };

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        return () => {
            _.isEqual(edit.isSubmit, true) && isReset();
        };
    }, [onSubmit]);

    return (
        <Card hidden={hidden}>
            <Wrapper>
                <Title>{title}</Title>
                <SubTitle>{subTitle}</SubTitle>
                <ButtonContainer>
                    {!buttonHidden && (
                        <>
                            <button type="button"
                                    hidden={edit.isCancel}
                                    onClick={onCancel}
                                    className={bx("cancel-button")}>
                                취소
                            </button>
                            <button type="button"
                                    hidden={edit.isCancel}
                                    className={bx("blue-button")}
                                    disabled={_.isUndefined(submitButtonDisabled) ? false : submitButtonDisabled}
                                    onClick={onSubmit}>
                                {submitButtonTitle}
                            </button>
                            <button type="button"
                                    onClick={onEdit}
                                    hidden={edit.isEdit}
                                    className={bx("blue-button")}>
                                {editButtonTitle}
                            </button>
                        </>
                    )}
                </ButtonContainer>
            </Wrapper>
            {children}
        </Card>
    )

}

const Card = styled.div`
  height: auto;
  background: #ffffff;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  width: 300px;
  font-size: 0.5em;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  width: 500px;
  display: flex;
  align-items: end;
`;

const SubTitle = styled.div`
  width: 165px;
  font-size: 16px;
  font-weight: 700;
`;
