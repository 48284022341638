import {useState} from "react";
import {IOoCompactHospital} from "@api/models";
import {PharmacyTargetHospital} from "../../../../interfaces";
import _ from "lodash";
import {usePharmacyTargetHospital} from "@queries/operator";
import {PharmacyDeliveryType} from "@api/models/pharmacy";

export const useTargetHospital = () => {
    const [deliveryTodayHidden, setDeliveryTodayHidden] = useState(true);
    const [deliverPackageHidden, setDeliverPackageHidden] = useState(true);
    const [selectedHospital, setSelectedHospital] = useState<string>("");
    const [hospitalList, setHospitalList] = useState<Array<IOoCompactHospital>>([]);
    const [items, setItems] = useState<PharmacyTargetHospital>({
        hospitalIdList: [],
        deliveryTypeList: [],
        deliveryTodayPrice: 0,
        deliveryQuickPrice: 0,
        deliveryPackagePrice: 0,
        deliveryPickupPrice: 0,
        todayRegionKeywordList: [],
        todayRegionKeywordHitCount: 2,
    });

    const targetHospitalCompactList = usePharmacyTargetHospital();
    const onHospitalChange = (targetId: string) => setSelectedHospital(targetId);

    const onCheckedToday = (e: any) => {
        const copied = items.deliveryTypeList;
        const index = copied.findIndex((item) => _.isEqual(item, PharmacyDeliveryType.today));
        if (e.target.checked && index < 0) {
            copied.push(PharmacyDeliveryType.today);
        } else {
            copied.splice(index, 1);
        }
        setItems({...items, deliveryTypeList: copied});
        setDeliveryTodayHidden(!deliveryTodayHidden);
    };

    const onCheckedPackage = (e: any) => {
        const copied = items.deliveryTypeList;
        const index = copied.findIndex((item) => _.isEqual(item, PharmacyDeliveryType.package));
        if (e.target.checked && index < 0) {
            copied.push(PharmacyDeliveryType.package);
        } else {
            copied.splice(index, 1);
        }
        setItems({...items, deliveryTypeList: copied});
        setDeliverPackageHidden(!deliverPackageHidden);
    };

    const onChangeTodayPrice = (e: any) => {
        setItems({...items, deliveryTodayPrice: _.toNumber(e.target.value)});
    };

    const onChangePackagePrice = (e: any) => {
        setItems({...items, deliveryPackagePrice: _.toNumber(e.target.value)});
    };

    const onSelectedHitCount = (e: any) => {
        const value = _.toNumber(e.target.value);
        setItems({...items, todayRegionKeywordHitCount: value});
    };

    const onChangeTodayKeywordList = (e: any) => {
        setItems({...items, todayRegionKeywordList: makeKeywordList(e.target.value)});
    };

    const makeKeywordList = (keyword: string) => {
        const splitArr = _.split(keyword, ",");
        return splitArr.map((item) => _.trim(item));
    };


    return {
        deliveryTodayHidden,
        setDeliveryTodayHidden,
        deliverPackageHidden,
        setDeliverPackageHidden,
        selectedHospital,
        hospitalList,
        setHospitalList,
        targetHospitalCompactList,
        items,
        setItems,
        onHospitalChange,
        onCheckedToday,
        onCheckedPackage,
        onChangeTodayPrice,
        onChangePackagePrice,
        onSelectedHitCount,
        onChangeTodayKeywordList,
    };
};
