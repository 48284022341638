import _ from "lodash";

export function splitOpenTime(item: string): string[] {
    const openHour = _.head(item.split(',')) as string;
    return openHour.split(':');
}

export function splitCloseTime(item: string): string[]{
    const closeHour = _.last(item.split(',')) as string;
    return closeHour.split(':');
}

export function reservationSplitTime(item:string):string{
    const hour = item.substring(0,2);
    const minute = item.substring(2,4);
    return `${hour}:${minute}`;
}

export function filteredIsOffArr(item:string):boolean{
    return _.isEqual(item,'Y');
}
