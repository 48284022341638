import { IOoUnospayDelivery, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
const ExcelJS = require("exceljs");

const createModel = (e:IOoUnospayDelivery) => {
    const model = new ExcelExportModel();
        model.name = e.recipientName;
        model.address1 = e.address;
        model.address2 = e.addressDetail;
        model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
        model.zipCode = e.zipCode;
        model.memo = e.memo;
        model.subMemo = e.subMemo;
        model.quantity = "1";
        model.deliveryCost = e.id;
    return model;
}


const pharmanetOrder = async (list:IOoUnospayDelivery[], providerName:string, code:string) => {
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.yuhanProvider));
    let data_array: Array<ExcelExportModel> = [];
    
    const customProductName = (menuName:string) => {
        return `[${providerName}] ${menuName.split('/')[0]}`
    }
    
    excelList.forEach((e, index) => {
      if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
        e.salesMenu?.forEach(salesItem => {
            console.log(salesItem);
            if(_.startsWith(salesItem.externalCode, code)){
                let model = createModel(e);   
                    model.deliveryDate = moment(e.timestamp).format('YYYY.MM. DD');
                    model.product = customProductName(salesItem.menuName!);
                data_array.push(model);
            }
        })
      }
    });

    console.log(data_array);
    
    const excelFileExtension = ".xlsx";
    const excelFileName = `(파마인넷_${providerName})`;

    const arrayBufferSheet1 = await (await fetch("/assets/yu.xlsx")).arrayBuffer();
    const workbookSheet1 = new ExcelJS.Workbook();
    await workbookSheet1.xlsx.load(arrayBufferSheet1);
    const ws_Sheet1 = workbookSheet1.getWorksheet("Sheet1");

    data_array.map((data: ExcelExportModel, index: number) => {
        const row = ws_Sheet1.getRow(index + 2);
        [
            data.deliveryDate,
            `${moment(data.deliveryDate).format('YYYYMMDD')}${String(index+1).padStart(4,'0')}`,
            data.product,
            data.quantity,
            data.name,
            data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
            data.name,
            data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
            data.zipCode,
            data.address1 + " " + data.address2,
            data.memo,
            "",
            "",
        ].forEach((value , index) => {
            const cell = row.getCell(index + 1);
            cell.value = value;
            cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            };
        })
    });

    const excelBuffer = await workbookSheet1.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      "tlc헬스케어 발주서"+"_"+ moment().format("YYYYMMDD")  + `${excelFileName}` + excelFileExtension
    );
}

export default pharmanetOrder