import React, {useEffect, useState} from "react";
import styled from "styled-components";
import _ from "lodash";
import {useRecoilValue} from "recoil";
import {IOoPharmacy} from "@models/pharmacy";
import {useCardEdit} from "@hooks/common";
import {editSelectorFamily} from "@recoils/status";
import {OoParamPharmacyUpdate} from "@params/pharmacy";
import {CustomInputText} from "@components/custom";
import {PharmacyPicture} from "@components/infoForm";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";

interface Props {
    data: IOoPharmacy,
    componentName: string,
}

export const PharmacyPrimaryInfo = ({data, componentName}: Props) => {
    const {updatePharmacy} = usePharmacyFunctionHook();
    const {isReset} = useCardEdit(componentName);
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const [items, setItems] = useState({
        email: data.email,
        name: data.name,
        license: data.license,
        pictures: data.pictures,
    });

    const onChangedPicture = (target: string) => setItems({...items, pictures: [target]});

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        setItems({
            email: data.email,
            name: data.name,
            license: data.license,
            pictures: data.pictures,
        })
    }, [data]);


    // 전송 모드 일때, 쿼리 trigger
    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) {
            return;
        }
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamPharmacyUpdate();
            param.id = data.id || "";
            param.pictures = items.pictures;
            updatePharmacy(param);
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <div>
                <div>
                    <Table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>약국명</td>
                            <td>
                                <CustomInputText value={items.name}
                                                 color={"white"}
                                                 size="middle"
                                                 placeholder="[필수]"
                                                 disabled={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td>이메일</td>
                            <td>
                                <CustomInputText value={items.email}
                                                 color={"white"}
                                                 size="middle"
                                                 placeholder="아이디@doctoron.kr [필수]"
                                                 disabled={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td>라이센스 번호</td>
                            <td>
                                <CustomInputText value={items.license}
                                                 color={"white"}
                                                 size="middle"
                                                 placeholder="[필수]"
                                                 disabled={false}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
                <PictureContainer>
                    <PharmacyPicture data={data}
                                     isEdit={editStatus.isEdit}
                                     isCancel={editStatus.isCancel}
                                     onChange={onChangedPicture}/>
                </PictureContainer>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }
`;

const PictureContainer = styled.div`
  padding-left: 50px;
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  tr td:first-child {
    font-weight: 700;
    font-size: 13px;
  }

  td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding: 2px;
    font-size: 13px;
  }
`;
