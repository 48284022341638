import {DiagnosisRtdbSubscriber, IOoDiagnosis} from "../../api";
import {useSetRecoilState} from "recoil";
import moment from "moment";
import _ from "lodash";
import {pharmacyReceiveRtdbState} from "@recoils/pharmacy";

export const usePharmacyHook = () => {
    const setPharmacyRtdbList = useSetRecoilState(pharmacyReceiveRtdbState);

    const isTodayDiagnosis = (diagnosis: IOoDiagnosis) => {
        const startOfDay = moment().startOf('day').toDate().getTime();
        const endOfDay = moment().endOf('day').toDate().getTime();

        // william: 진료일과 약배송 신청이 다른 날인경우 처리, 검증 안됨
        // if (diagnosis.status >= OoDiagnosisStatus.decidedDeliveryType && !_.isEqual(diagnosis.deliveryType, OoMedicineDeliveryType.user)) {
        //     if (!_.isEmpty(diagnosis.medicineDeliveryTimestamp)) {
        //         return diagnosis.medicineDeliveryTimestamp! >= startOfDay && diagnosis.medicineDeliveryTimestamp! <= endOfDay;
        //     }
        // }
        return diagnosis.timestamp >= startOfDay && diagnosis.timestamp <= endOfDay;
    }

    const onValue = (diagnosisList: Array<IOoDiagnosis>) => {
        const todayList = _.chain(diagnosisList)
            .filter(d => isTodayDiagnosis(d))
            .sortBy(d => d.medicineDeliveryTimestamp)
            .reverse()
            .value();
        setPharmacyRtdbList(todayList);
    }

    const connect = (path: string) => {
        DiagnosisRtdbSubscriber.shared().subscribe(path, onValue);
    };

    const disconnect = () => {
        DiagnosisRtdbSubscriber.shared().unsubscribe();
    };

    return {
        connect,
        disconnect
    };
}
