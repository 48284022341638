import _ from "lodash";
import {AbstractOoParam} from "@api/params";

class OoDailyTargetDiagnosisRecordRange extends AbstractOoParam {
    // target id
    id: string = "";

    // 시작 타임스탬프
    beginTimestamp: string = "0";

    // 종료 타임스탬프
    endTimestamp: string = "0";

    // 마지막 진료 아이디 - 페이지네이션
    diagnosisId?: string = undefined;

    count?: string = "100";

    constructor(
        id: string,
        beginTimestamp: string,
        endTimestamp: string,
        diagnosisId?: string,
        count?: string
    ) {
        super();
        this.id = id;
        this.beginTimestamp = beginTimestamp;
        this.endTimestamp = endTimestamp;
        this.count = count;
        this.diagnosisId = diagnosisId;
    }

    getPathParams() {
        return _.isEmpty(this.diagnosisId)
            ? `${this.id}/${this.beginTimestamp}/${this.endTimestamp}/${this.count}`
            : `${this.id}/${this.beginTimestamp}/${this.endTimestamp}/${this.count}/${this.diagnosisId}`;
    }
}

export {OoDailyTargetDiagnosisRecordRange};
