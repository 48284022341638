import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  IOoUnospayDelivery,
  IOoUnospaySalesMenu,
  IOoUnospaySalesMenuOption,
  IOoUnospayTransportInfo,
  OoDeliveryStatus,
  OoOrderPlatformType,
  OoProvider,
} from "@api/models";
import { ExcelExportModel } from "../model";
import _ from "lodash";
import styled from "styled-components";
import { formatPrice, formatTel } from "@hooks/format";
import moment from "moment";
import React from "react";
import { exCodeNumToStr, findTlcMenuList, listProviderFilter } from "../../ExcelDownloadModal";
const ExcelJS = require("exceljs");

interface Props {
  list: Array<IOoUnospayDelivery>;
  select: OoProvider;
}

const eventExternalCodes = [
  "OF04_12",
  "OF04_13",
  "OF04_14",
  "OF04_15",
  "OF04_16",
  "OF04_17",
  "OF04_18",
  "OF04_19",
  "OF04_20",
  "OF04_21",
];

const getProductName = (code:string):{
  code: string;
  subName: string;
  cost: string;
}[] | undefined => {
  switch (code) {
    case 'OF04_06':
      return [{ code: "145172", subName: "뷰티핏 6일 패키지", cost: "23700" }]
    case 'OF04_07':
      return [{ code: "145171", subName: "베지라이프 6일 패키지", cost: "25800" }]
    case 'OF04_08':
      return [{ code: "145169", subName: "프로틴업 6일 패키지", cost: "31500" }]
    case 'OF04_12':
      return [{ code: "144113", subName: "연화식 패키지1(제육, 소불고기, 고등어조림)", cost: "15182" }]
    case 'OF04_13':
      return [{ code: "144114", subName: "연화식 패키지2(찜닭, 소고기장조림, 함박스테이크)", cost: "16900" }]
    case 'OF04_14':
      return [{ code: "144115", subName: "연화식 패키지3(고등어 허브구이, 데리야끼구이)", cost: "16900" }]
    case 'OF04_15':
      return [
              {code: "144113", subName: "연화식 패키지1(제육, 소불고기, 고등어조림)", cost: "15182"},  
              {code: "144114", subName: "연화식 패키지2(찜닭, 소고기장조림, 함박스테이크)", cost: "16900"}
            ]
    case 'OF04_33':
      return [{  code: "150086", subName: "5일 다이어트 패키지", cost: "26250" }]
    case 'OF04_34':
      return [{  code: "150086", subName: "10일 다이어트 패키지", cost: "26250" }]
  }
}


const tlcproteinUp5 = [
  {
    code: "150086",
    subName: "5일 다이어트 패키지",
    cost: "26250",
  },
];
const tlcproteinUp10 = [
  {
    code: "150086",
    subName: "10일 다이어트 패키지",
    cost: "26250",
  },
];
const softProtain1 = [
  {
    code: "144113",
    subName: "연화식 패키지1(제육, 소불고기, 고등어조림)",
    cost: "15182",
  },
];
const softProtain2 = [
  {
    code: "144114",
    subName: "연화식 패키지2(찜닭, 소고기장조림, 함박스테이크)",
    cost: "16900",
  },
];
const softProtain3 = [
  {
    code: "144115",
    subName: "연화식 패키지3(고등어 허브구이, 데리야끼구이)",
    cost: "9818",
  },
];

const beautyFit = [
  { code: "145172", subName: "뷰티핏 6일 패키지", cost: "23700" },
];
const veggieLife = [
  { code: "145171", subName: "베지라이프 6일 패키지", cost: "25800" },
];
const protainUp = [
  { code: "145169", subName: "프로틴업 6일 패키지", cost: "31500" },
];

function addDays(date: Date, days: number) {
  const clone = new Date(date);
  clone.setDate(date.getDate() + days);
  return clone;
}

function getDeliveryCount(option: IOoUnospaySalesMenu) {
  let retCount = 1;
  if (
    _.isEqual(option.externalCode, "OF04_01") ||
    _.isEqual(option.externalCode, "OF04_11") ||
    _.isEqual(option.externalCode, "OF04_02") ||
    _.isEqual(option.externalCode, "OF04_03") ||
    _.isEqual(option.externalCode, "OF04_04") ||
    _.isEqual(option.externalCode, "OF04_05") ||
    _.isEqual(option.externalCode, "OF04_10") ||
    _.isEqual(option.externalCode, "OF04_09") ||
    _.isEqual(option.externalCode, "OF04_27") ||
    _.isEqual(option.externalCode, "OF04_20") ||
    _.isEqual(option.externalCode, "OF04_28") ||
    _.isEqual(option.externalCode, "OF04_32")
  ) {
    switch (_.first(option.salesOption)?.externalCode) {
      case "301":
      case "302":
      case "303":
      case "304":
        retCount = 1;
        break;
      case "101":
        retCount = 2;
        break;
      case "102":
      case "04":
      case "02":
        retCount = 6;
        break;
      case "103":
      case "05":
      case "03":
        retCount = 12;
        break;
      default:
        return retCount;
    }
  } else if (
    _.isEqual(option.externalCode, "OF04_06") ||
    _.isEqual(option.externalCode, "OF04_07") ||
    _.isEqual(option.externalCode, "OF04_08")
  ) {
    retCount = 1;
  } else if (_.isEqual(option.externalCode, "OF05_01")) {
    retCount = 6;
  } else if (_.isEqual(option.externalCode, "OF05_02")) {
    retCount = 12;
  } else if (
    _.isEqual(option.externalCode, "OF05_03") ||
    _.isEqual(option.externalCode, "OF05_04") ||
    _.isEqual(option.externalCode, "OF05_07") ||
    _.isEqual(option.externalCode, "OF05_08") ||
    _.isEqual(option.externalCode, "OF05_09")
  ) {
    retCount = 18;
  } else if (_.isEqual(option.externalCode, "OF05_10")) {
    retCount = 2;
  } else if (
    _.isEqual(option.externalCode, "OF05_11") ||
    _.isEqual(option.externalCode, "OF05_12") ||
    _.isEqual(option.externalCode, "OF05_13")
  ) {
    retCount = 4;
  } else if (_.isEqual(option.externalCode, "OF05_06")) {
    retCount = 24;
  } else if (
    _.isEqual(option.externalCode, "OF05_14") ||
    _.isEqual(option.externalCode, "OF05_15")
  ) {
    retCount = 21;
  }
  return retCount;
}

function convTimestampDateFormat(timestamp: number) {
  var dateFormat = new Date(timestamp);
  return (
    dateFormat.getFullYear() +
    "-" +
    (dateFormat.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    dateFormat.getDate().toString().padStart(2, "0")
  );
}

function getNewCareInfo(optionCode: string | undefined) {
  if (
    _.isEqual(optionCode, "01") ||
    _.isEqual(optionCode, "02") ||
    _.isEqual(optionCode, "03") ||
    _.isEqual(optionCode, "04") ||
    _.isEqual(optionCode, "05")
  ) {
    return "0";
  } else {
    return "";
  }
}

function getMenuPrice(exCode: string) {
  let ret = "";
  switch (exCode) {
    case "OF04_01": // 당뇨
    case "OF04_02": // 암환자
    case "OF04_28":
    case "OF04_20":
      ret = "7,700";
      break;
    case "OF04_03": // 퇴원후 회복식 (장수마을)
    case "OF04_10": // 암환자 회복식
    case "OF04_04":
      ret = "7,300";
      break;
    case "OF04_05": // 샐러드 식단
      ret = "6,300";
      break;
    case "OF04_09":
      ret = "7,700";
      break;
    case "OF04_06":
      ret = "23,700";
      break;
    case "OF04_07":
      ret = "25,800";
      break;
    case "OF04_08":
      ret = "31,500";
      break;
    case "OF04_11":
    case "OF04_27":
      ret = "7,300";
      break;
    case "OF04_27":
      ret = "7,300";
      break;
    case "OF04_32":
      ret = "26,250";
      break;
  }
  return ret;
}

const dawnVisitMethod = (submemo:string) => {
  switch (submemo) {
    case "2":
      return "2";
    case "3":
    case "4":
    case "5":
    case "7":
      return "";
    case "6":
        return "6"
    default:
      return "1"
  }
}

function formatDateToCustomFormat(dateString: string): string {
  if (!/^\d{8}$/.test(dateString)) {
    // 올바르지 않은 날짜 형식이므로 기본 값을 반환합니다.
    return "";
  }

  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6).padStart(2, "0");
  const day = dateString.substring(6, 8).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

const findTransportInfo = (excode:string , transportInfo: IOoUnospayTransportInfo[]) => {
  return transportInfo.find(item => item.externalCode === excode);
}

const dawndeliveryType = (excode:string , transportInfo: IOoUnospayTransportInfo[]) => {
  const findHDProduct = findTransportInfo(excode, transportInfo);
  console.log(findHDProduct);
  
  switch(findHDProduct?.transportOption){
    case 3:
      return '새벽배송';
    case 2:
      return '일반배송';
    default:
      return ''; 
  }
}

export const hdOrder = async (
  list: Array<IOoUnospayDelivery>,
  select: OoProvider
) => {
  let data_array: Array<ExcelExportModel> = [];
  //공휴일 적용 안됨
  const tlcMenuHd = findTlcMenuList(list , exCodeNumToStr(OoProvider.hdProvider));
  const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.hdProvider));
  const today = new Date();
  let minDate: Date;
  
  const dayOfWeek = today.getDay(); // 0은 일요일, 1은 월요일, ... 6은 토요일
  switch (dayOfWeek) {
    case 1: // 월요일
      minDate = addDays(today, 2);
      break;
    case 2: // 화요일
      minDate = addDays(today, 2);
      break;
    case 3: // 수요일
      minDate = addDays(today, 2);
      break;
    case 4: // 목요일
      minDate = addDays(today, 2);
      break;
    case 5: // 금요일
      minDate = addDays(today, 3);
      break;
    case 6: // 토요일
      minDate = addDays(today, 4);
      break;
    case 0: // 일요일
      minDate = addDays(today, 3);
      break;
    default:
      minDate = addDays(today, 1);
  }

  let menuListIndex = 0;
  let arrayHdOrderList = [];
  if(tlcMenuHd && tlcMenuHd.length > 0){
    arrayHdOrderList = [...excelList, ...tlcMenuHd]
  }else{
    arrayHdOrderList = [...excelList]
  }
  arrayHdOrderList.reverse().forEach((e, i) => {
    if (e.status == OoDeliveryStatus.existAddress || e.status == OoDeliveryStatus.sendPackage) {
      const model = new ExcelExportModel();
      e.salesMenu?.forEach((salesItem, i) => {
        
        const option = e.salesMenu![i];
        
        if (_.startsWith(salesItem.externalCode, exCodeNumToStr(select))) {
          console.log(e);
          model.product = salesItem.menuName;
          model.exCode = salesItem.externalCode;
          model.orderDate = convTimestampDateFormat(e.timestamp);
          model.name = e.recipientName;
          model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
          model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
          model.address1 = e.address;
          model.address2 = e.addressDetail;
          model.zipCode = e.zipCode;
          model.memo = e.memo;
          model.subMemo = e.subMemo;
          dawndeliveryType(salesItem.externalCode!, e.transportInfo);
          model.dawndeliveryType = dawndeliveryType(salesItem.externalCode!,e.transportInfo);
          if(dawndeliveryType(salesItem.externalCode!, e.transportInfo)==="새벽배송" && e.subMemo){
            model.dawnVisitMethod = dawnVisitMethod(e.subMemo)
          }

          option.salesOption?.forEach((e) => {
            if (!_.isEmpty(e.externalCode)) {
              model.optionCodeList!.push(e.externalCode!);
            }
          });

          model.quantity = option.menuCount;
          
          if (
            e.salesMenu![i].selectMenu &&
            (salesItem.externalCode?.startsWith("OF05") ||
              salesItem.externalCode?.startsWith("OF07") ||
              salesItem.externalCode?.startsWith("OF04_06") ||
              salesItem.externalCode?.startsWith("OF04_07") ||
              salesItem.externalCode?.startsWith("OF04_08") ||
              salesItem.externalCode?.startsWith("OF04_15"))
          ) {
            model.deliveryDate =
              e.salesMenu![i].selectMenu![0].date != null
                ? e.salesMenu![i].selectMenu![0].date
                : "";
          }

          model.withNewCare =
            getNewCareInfo(_.first(option.salesOption)?.externalCode) || "";
          
          model.price =
            getMenuPrice(salesItem.externalCode!) ||
            formatPrice(Number(option.menuPrice)).toString();

          if (e.salesMenu && e.salesMenu.length > i) {
            const salesMenuItem = e.salesMenu[i];

            const count = getDeliveryCount(salesMenuItem);
            
            for (var j = 0; j < count; j++) {
              const selectMenuIndex = Math.floor(j / 2);
              
              const selectMenuItem =
                salesMenuItem.selectMenu &&
                salesMenuItem.selectMenu.length > selectMenuIndex
                  ? salesMenuItem.selectMenu[selectMenuIndex]
                  : undefined;
              const menuList = selectMenuItem?.menuList;
              
              const currentSelectMenu = salesMenuItem.selectMenu
                ? salesMenuItem.selectMenu[Math.floor(j / 2)]
                : undefined;
              
              if (salesItem.externalCode == "OF04_06") {
                model.menu = beautyFit[j].subName;
                model.menuCode = beautyFit[j].code;
                model.menuCost = beautyFit[j].cost;
              } else if (salesItem.externalCode == "OF04_07") {
                model.menu = veggieLife[j].subName;
                model.menuCode = veggieLife[j].code;
                model.menuCost = veggieLife[j].cost;
              } else if (salesItem.externalCode == "OF04_08") {
                model.menu = protainUp[j].subName;
                model.menuCode = protainUp[j].code;
                model.menuCost = protainUp[j].cost;
              }
              else if (salesItem.externalCode == "OF04_15") {
                //연화식 세트 1, 2 합쳐진 메뉴
                model.menu = softProtain1[j].subName;
                model.menuCode = softProtain1[j].code;
                model.menuCost = softProtain1[j].cost;
                data_array.push({ ...model });
                model.menu = softProtain2[j].subName;
                model.menuCode = softProtain2[j].code;
                model.menuCost = softProtain2[j].cost;
              } 
              else if (salesItem.externalCode == "OF04_33") {
                //tlc 큐레이션 메뉴
                model.menu = tlcproteinUp5[j].subName;
                model.menuCode = tlcproteinUp5[j].code;
                model.menuCost = tlcproteinUp5[j].cost;
              } 
              else if (salesItem.externalCode == "OF04_34") {
                //tlc 큐레이션 메뉴
                model.menu = tlcproteinUp10[j].subName;
                model.menuCode = tlcproteinUp10[j].code;
                model.menuCost = tlcproteinUp10[j].cost;
              } 
              else {
                if (menuList && menuList.length > menuListIndex) {
                  model.menu =
                    menuList[menuListIndex]?.subName != ""
                      ? menuList[menuListIndex]?.subName
                      : menuList[menuListIndex]?.name;
                  model.menuCode = menuList[menuListIndex]?.code || "";
                  model.menuCost = menuList[menuListIndex]?.cost || "";
                }

                model.deliveryDate = currentSelectMenu?.date
                  ? formatDateToCustomFormat(currentSelectMenu.date)
                  : "";
                menuListIndex++;

                if (
                  (menuList && menuListIndex >= menuList.length) ||
                  menuList == undefined
                ) {
                  menuListIndex = 0;
                }
              }
              data_array.push({ ...model });
            }
          }
        }else{
            salesItem.salesOption?.map(sale => {
            if(_.startsWith(sale.externalCode, exCodeNumToStr(select))){
              console.log(e);
              
              model.product = salesItem.menuName;
              model.exCode = salesItem.externalCode;
              model.orderDate = convTimestampDateFormat(e.timestamp);
              model.name = e.recipientName;
              model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
              model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
              model.address1 = e.address;
              model.address2 = e.addressDetail;
              model.zipCode = e.zipCode;
              model.memo = e.memo;
              model.subMemo = e.subMemo;
              model.dawndeliveryType = dawndeliveryType(salesItem.externalCode!, e.transportInfo);
              if(dawndeliveryType(salesItem.externalCode!, e.transportInfo) && e.subMemo){
                model.dawnVisitMethod = dawnVisitMethod(e.subMemo)
              }
              if(getProductName(sale.externalCode!)){
                  getProductName(sale.externalCode!)?.map(item => {
                    model.menu = item.subName,
                    model.menuCode = item.code,
                    model.price = item.cost,
                    model.quantity = salesItem.menuCount;
                    model.deliveryDate =
                    e.salesMenu![i].selectMenu![0].date != null
                      ? e.salesMenu![i].selectMenu![0].date
                      : "";
                    data_array.push({ ...model });
                  }
                )
                
              }
              
            }
          });
        }
      });
    }
  });
  
  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = `tlc헬스케어 발주서`;

  const arrayBufferHd = await (
    await fetch("/assets/hd.xlsx")
  ).arrayBuffer();
  const workbookHd = new ExcelJS.Workbook();

  await workbookHd.xlsx.load(arrayBufferHd);
  const ws_hd = workbookHd.getWorksheet('주문업로드목록');

  if (select == OoProvider.hdProvider) {
    let custNum = 1;
    let iceNum = 1;
    let previousOrderDate = "";
    let custNumAddition = 0;
    const rowNumber = 2;
    const yellowColumns = [
      11, 12, 13, 14, 21, 22, 23, 24, 28, 29, 30, 31, 32, 33, 34,
    ];
    data_array.reverse();
    data_array.forEach((data: ExcelExportModel, index: number) => {
      if (previousOrderDate !== moment(data.orderDate).format("YYYYMMDD")) {
        custNum = 1; // orderDate 값이 바뀌면 custNum을 1로 초기화
        previousOrderDate = moment(data.orderDate).format("YYYYMMDD");
      }

      if (
        _.isEqual(data.exCode, "OF04_06") ||
        _.isEqual(data.exCode, "OF04_07") ||
        _.isEqual(data.exCode, "OF04_08")
      ) {
        const dateTimeTemp = `${moment(data.orderDate).format(
          "YYYYMMDD"
        )}${_.padStart(custNum.toString(), 4, "0")}`;
        data.custOrderNum = dateTimeTemp;
        // data.price = '31,500';

        if (iceNum == 6) {
          custNum++;
          iceNum = 1;
        } else {
          iceNum++;
        }
      } else if (eventExternalCodes.includes(data.exCode!)) {
        const dateTimeTemp = `${moment(data.orderDate).format(
          "YYYYMMDD"
        )}${_.padStart(custNum.toString(), 4, "0")}`;
        data.custOrderNum = dateTimeTemp;
        custNum++;
        if (custNumAddition == 0) {
          custNumAddition = 1;
        } else {
          custNumAddition = 0;
        }
      } else {
        if (index % 2 == custNumAddition) {
          const dateTimeTemp = `${moment(data.orderDate).format(
            "YYYYMMDD"
          )}${_.padStart(custNum.toString(), 4, "0")}`;
          data.custOrderNum = dateTimeTemp;
        } else {
          const dateTimeTemp = `${moment(data.orderDate).format(
            "YYYYMMDD"
          )}${_.padStart(custNum.toString(), 4, "0")}`;
          data.custOrderNum = dateTimeTemp;
          custNum++;
        }
      }

      // formatPrice((Number(item.menuPrice || "0") * Number(item.menuCount || "1")) + getOptionsPrice(item) * Number(item.menuCount || "0"))
      const today = moment().format("dddd");

      const row = ws_hd.getRow(rowNumber + index);
      [
        data.withNewCare,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        data.custOrderNum,
        index + 1,
        data.menuCode,
        data.menu,
        "",
        "",
        "",
        "",
        "",
        "",
        data.quantity + ".",
        data.price,
        data.orderDate,
        data.deliveryDate ? moment(data.deliveryDate).format("YYYY-MM-DD") : "",
        "TLC헬스케어",
        "",
        "1800-0700",
        data.name,
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}`,
        data.tel2 ? `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}` : `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}`,
        `${data.address1} ${data.address2}`,
        "",
        data.zipCode,
        data.memo,
        data.dawndeliveryType === '새벽배송' ? "1" : "",
        data.dawnVisitMethod === '1' ? data.dawnVisitMethod : data.dawnVisitMethod === '2' ? '2' : "",
        data.dawnVisitMethod === "1" ? data.subMemo : "",
        data.dawnVisitMethod === '6' ? data.memo : "",
        "2",
        data.dawndeliveryType,
      ].forEach((value, index) => {
        const cell = row.getCell(index + 1); // 엑셀은 1부터 시작
        cell.value = value;
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        if (yellowColumns.includes(index + 1)) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFF2CC" }, // Light gray color
          };
        }
        if (index == 21) {
          cell.alignment = { horizontal: "left", vertical: "middle" };
          // cell.alignment = { vertical: 'middle' };
        }
      });
      // return false;
    });
    // XLSX.utils.book_append_sheet(book, ws_hd, "현대그리팅");
  }

  const excelBuffer = await workbookHd.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFile,
    excelFileName +
      "_" +
      moment().format("YYYYMMDD") +
      "(현대그린푸드)" +
      excelFileExtension
  );
};
