import {useQuery} from "react-query";
import {useRecoilState} from "recoil";
import _ from "lodash";
import {useSuspenseHook} from "@hooks/suspense";
import {doctorListState} from "@recoils/doctor";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";

export const useDoctorListQuery = () => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const [list, setList] = useRecoilState(doctorListState);
    return useQuery(
        'doctor/doctorList',
        async () => {
            try {
                if (!_.isEmpty(list)) {
                    return list;
                }
                setLoading();
                const response = await DoctorOnAPI.shared.doctor.list();
                setStopLoading();
                if (isRequestSucceed(response)) {
                    setList(response.data || []);
                }
                return null;
            } catch (e: any) {
                setErrorStatus(e);
            }
        }, {
            enabled: true,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always",  // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )
}