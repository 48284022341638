import {IOoDiagnosis} from "@api/models";
import {ToggleButton} from "react-bootstrap";
import React from "react";
import classNames from "classnames/bind";
import styles from "../../../styles/diagnosis/DiagnosisDetail.module.scss";
import styled from "styled-components";

interface Props {
    list: Array<IOoDiagnosis>,
    radioValue: string | undefined,
    onChanged: (e: any) => void,
}

export const TabButton = ({list, radioValue, onChanged}: Props) => {
    const cx = classNames.bind(styles);

    return (
        <Wrapper>
            {
                list.map((diagnosis, index) =>
                    <ToggleButton key={diagnosis.id}
                                  id={`${diagnosis.timestamp}`}
                                  type="radio"
                                  name="radio"
                                  value={diagnosis.id!}
                                  checked={radioValue === (diagnosis.id ?? "")}
                                  onChange={onChanged}
                                  bsPrefix={cx('btn-check')}
                                  className={cx('active-tab-button')}>
                        <div className={cx('active-tab-button-label')}>
                            <span>{index + 1}</span> {diagnosis.patientName}
                        </div>
                        <div className={cx('active-tab-border-div')}/>
                    </ToggleButton>
                )
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
`