import {IOoHospital} from "@api/models";
import {WorkHourRow} from "@components/workHourRow";
import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import classNames from "classnames/bind";
import timeTableStyle from "@styles/common/TimeTable.module.scss";
import {useRecoilValue} from "recoil";
import {editSelectorFamily} from "@recoils/status";
import {useMakeTimeHook} from "@hooks/common";
import _ from "lodash";
import {IOoWorkHours} from "@models/pharmacy";
import {OoParamHospitalUpdate} from "@api/params";
import {useHospitalFunctionHook} from "@hooks/hospital";

interface Props {
    componentName: string,
    data: IOoHospital,
}

export const HospitalOperatingHours = ({data, componentName}: Props) => {
    const tx = classNames.bind(timeTableStyle);

    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const {updateHospital} = useHospitalFunctionHook();
    const {
        normalizedTimeTable,
        normalizedDefaultTimeTable
    } = useMakeTimeHook();

    const initialData = useMemo(() => {
        return _.isEmpty(data.hospitalHours) || _.isUndefined(data.hospitalHours) ?
            normalizedDefaultTimeTable()
            : normalizedTimeTable(data.hospitalHours);
    }, [data.hospitalHours]);

    const [paramsData, setParamsData] = useState<Array<IOoWorkHours>>(initialData);

    const handleTime = (target: IOoWorkHours) => {
        const copied = _.cloneDeep(paramsData);
        const index = _.findIndex(copied, data => _.isEqual(data.dayOfWeek, target.dayOfWeek));
        copied[index] = target;
        setParamsData(copied);
    };

    useEffect(() => {
        setParamsData(initialData);
    }, [initialData]);

    useEffect(() => {
        editStatus.isCancel && setParamsData(initialData);
    }, [editStatus.isCancel]);

    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            const updateParam = new OoParamHospitalUpdate();
            updateParam.id = data.id || "";
            updateParam.hospitalHours = paramsData;
            updateHospital(updateParam);
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <div className={tx("time-table-head")}>
                <div>요일</div>
                <div>진료시작</div>
                <div>진료종료</div>
                <div>휴무일여부</div>
            </div>
            {
                paramsData.map((item, index) => (
                    <WorkHourRow key={index}
                                 dayOfWeekNumber={item.dayOfWeek}
                                 hours={item.hours}
                                 breakHoursList={item.breakHoursList}
                                 isOff={item.isOff}
                                 onChange={handleTime}
                                 isEdit={editStatus.isEdit}
                                 isCancel={editStatus.isCancel}
                                 isSubmit={editStatus.isSubmit}
                    />
                ))
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 16px;
`;
