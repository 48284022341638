import { useEffect, useState } from "react";
import moment from "moment";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  unospayCalendarRangeListState,
  unospayDailySelectedDateState,
} from "@recoils/unospay";
import { useReadRangeUnospayQuery } from "@queries/unospay";

export const usePaymentCalendarHook = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [todayDate, setTodayDate] = useState<Array<Date>>([
    moment().toDate(),
    moment().toDate(),
  ]);

  const [calendarQueryTrigger, setCalendarQueryTrigger] = useState(false);
  const [dailyDiagnosisSelectedDate, setDailyDiagnosisSelectedDate] =
    useRecoilState(unospayDailySelectedDateState);
  const resetDiagnoisSelectedDate = useResetRecoilState(
    unospayDailySelectedDateState
  );
  const list = useRecoilValue(unospayCalendarRangeListState);

  useReadRangeUnospayQuery({
    beginTimestamp: moment(_.head(dailyDiagnosisSelectedDate)!, "YYYYMMDD")
      .startOf("day")
      .valueOf()
      .toString(),
    endTimestamp: moment(_.last(dailyDiagnosisSelectedDate)!, "YYYYMMDD")
      .endOf("day")
      .valueOf()
      .toString(),
    count: "50000",
    id: "",
    updateQueryTrigger: calendarQueryTrigger,
  });

  useEffect(() => {
    setModalVisible(false);
    setCalendarQueryTrigger(false);
  }, [list]);

  const onShowCalendar = (e: any) => {
    e.preventDefault();
    setModalVisible(true);
  };

  const onClickDate = (value: Date, e: any) => {
    e.preventDefault();
    setDailyDiagnosisSelectedDate([
      ...dailyDiagnosisSelectedDate,
      moment(value).format("YYYY.MM.DD"),
    ]);
  };

  const onChangeDate = (value: Array<Date>) => {
    if (_.isEqual(value.length, 2)) {
      const isOverMonth = isMaxDateLimit(value);
      if (isOverMonth) {
        toast.error("최대 조회기간은 180일까지입니다.");
        setTodayDate([moment().toDate(), moment().toDate()]);
        setDailyDiagnosisSelectedDate([
          moment().format("YYYY.MM.DD"),
          moment().format("YYYY.MM.DD"),
        ]);
        return;
      }
      setCalendarQueryTrigger(true);
    }

    const getDate = value.map((item) => item);
    setTodayDate(getDate);
    const stringToTime = value.map((item) => moment(item).format("YYYY.MM.DD"));
    setDailyDiagnosisSelectedDate(stringToTime);
  };

  const isMaxDateLimit = (ranges: Array<Date>) => {
    const startDate = moment(ranges[0]).toDate();
    const endDate = moment(ranges[1]).toDate();

    let count = 0;
    while (startDate <= endDate) {
      startDate.setDate(startDate.getDate() + 1);
      count++;
    }

    return count > 180;
  };

  const handleTodayButton = (e: any) => {
    e.preventDefault();
    resetDiagnoisSelectedDate();
  };

  return {
    modalVisible,
    setModalVisible,
    todayDate,
    setTodayDate,
    onShowCalendar,
    onClickDate,
    dailyDiagnosisSelectedDate,
    setDailyDiagnosisSelectedDate,
    onChangeDate,
    handleTodayButton,
  };
};
