import {useMutation} from "react-query";
import {useSuspenseHook} from "@hooks/suspense";
import {OoParamPaypleRefund} from "@api/params";
import {DoctorOnAPI, isRequestSucceed} from "../../api";

export const useDiagnosisRefund = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        "operator/diagnosisRefund",
        async (param: OoParamPaypleRefund) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.payple.refund(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }

        }
    )
}