import {useQuery} from "react-query";
import {DoctorOnAPI, isRequestSucceed} from "../../api";
import {useSuspenseHook} from "../../hooks";

export const usePharmacyTargetHospital = () => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();

    return useQuery(
        'operator/pharmacyTargetHospital',
        async () => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.hospital.compactList();
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        }, {
            enabled: true,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always",  // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )
};
