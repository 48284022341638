import { useEffect, useState } from "react";
import _ from "lodash";
import { useRecoilState, useResetRecoilState } from "recoil";
import { unospayDailySelectedDateState } from "@recoils/unospay";
import { unospaySearchByNameState } from "@recoils/search/unospaySearchByNameState";
import { useFindByNameUnospayQuery } from "@queries/unospay";

export const useUnospayPaymentSearchSettingHook = () => {
  const resetDailyDiagnosisSelectedDate = useResetRecoilState(
    unospayDailySelectedDateState
  );
  const [buttonStatus, setButtonStatus] = useState({
    cancel: false,
    search: false,
  });

  const [searchList, setSearchList] = useRecoilState(unospaySearchByNameState);
  const [searchQueryTrigger, setSearchQueryTrigger] = useState(false);

  useEffect(() => {
    if (_.isEqual(buttonStatus.cancel, false)) {
      console.log('buttonStatus.cancel:', buttonStatus.cancel);
      // setSearchList({ userName: "", list: [] });
      setButtonStatus({ ...buttonStatus, search: false });
    }
  }, [buttonStatus.cancel]);

  useEffect(() => {
    if (_.isEqual(buttonStatus.search, false)) return;
    if (_.eq(searchList.userName, "")) return;

    setSearchQueryTrigger(true);
    setButtonStatus({ ...buttonStatus, cancel: true, search: false });
  }, [buttonStatus.search]);

  useFindByNameUnospayQuery({
    searchName: _.isUndefined(searchList.userName) ? "" : searchList.userName,
    searchQueryTrigger: searchQueryTrigger,
  });

  const onChangeSearchName = (value: string) => {
    setSearchList({ ...searchList, userName: value });
    setSearchQueryTrigger(false);
  };

  const onChangeDeleteButton = (event: boolean) => {
    setButtonStatus({ ...buttonStatus, cancel: event });
    setSearchQueryTrigger(false);
  };

  const onClickSearchButton = (event: boolean) => {
    setButtonStatus({ ...buttonStatus, search: event, cancel: event });
    resetDailyDiagnosisSelectedDate();
  };

  return {
    searchList,
    buttonStatus,
    onClickSearchButton,
    onChangeDeleteButton,
    onChangeSearchName,
  };
};
