import React, {useEffect} from "react";
import _ from "lodash";
import styled from "styled-components";
import {IOoDoctor, IOoHospital} from "@api/models";
import {OoParamDoctorUpdate, OoParamHospitalUpdate, OoParamPharmacyUpdate} from "@api/params";
import {CustomInputText} from "@components/custom";
import {useBankInfoSettingHook} from "@components/infoForm";
import {IOoPharmacy} from "@models/pharmacy";

interface Props {
    target: string,
    data: IOoDoctor | IOoHospital | IOoPharmacy;
    componentName: string,
}

export const BankInfo = ({target, data, componentName}: Props) => {
    const {
        editStatus,
        color,
        setColor,
        items,
        setItems,
        handleChange,
        isReset,
        initialData,
        updateDoctor,
        updatePharmacy,
        updateHospital,
    } = useBankInfoSettingHook(data, componentName);

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        setItems(initialData);
    }, [data]);

    useEffect(() => {
        if (editStatus.isEdit) {
            setColor("blue");
        } else {
            setColor("white");
            setItems(initialData);
        }
    }, [editStatus.isEdit]);

    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            doUpdate();
        }
    }, [editStatus.isSubmit]);

    const doUpdate = () => {
        switch (target) {
            case "doctor":
                const doctorParam = new OoParamDoctorUpdate();
                doctorParam.id = data.id || "";
                doctorParam.bank = _.trim(items.bank);
                doctorParam.bankAccount = _.trim(items.bankAccount);
                doctorParam.bankHolderName = _.trim(items.bankHolderName);
                return updateDoctor(doctorParam);
            case "pharmacy":
                const pharmacyParam = new OoParamPharmacyUpdate();
                pharmacyParam.id = data.id || "";
                pharmacyParam.bank = _.trim(items.bank);
                pharmacyParam.bankAccount = _.trim(items.bankAccount);
                pharmacyParam.bankHolderName = _.trim(items.bankHolderName);
                return updatePharmacy(pharmacyParam);
            case "hospital":
                const hospitalParam = new OoParamHospitalUpdate();
                hospitalParam.id = data.id || "";
                hospitalParam.bank = _.trim(items.bank);
                hospitalParam.bankAccount = _.trim(items.bankAccount);
                hospitalParam.bankHolderName = _.trim(items.bankHolderName);
                return updateHospital(hospitalParam);
        }
    };

    return (
        <Wrapper>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>
                        <b>은행명</b>
                    </td>
                    <td>
                        <CustomInputText name="bank"
                                         value={items.bank}
                                         disabled={editStatus.isEdit}
                                         onChanged={handleChange}
                                         color={color}
                                         size="middle"
                                         placeholder="[필수]"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>계좌번호</b>
                    </td>
                    <td>
                        <CustomInputText name="bankAccount"
                                         value={items.bankAccount}
                                         onChanged={handleChange}
                                         disabled={editStatus.isEdit}
                                         color={color}
                                         size="middle"
                                         placeholder="- 없이 입력 [필수]"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>예금주</b>
                    </td>
                    <td>
                        <CustomInputText name="bankHolderName"
                                         value={items.bankHolderName}
                                         onChanged={handleChange}
                                         disabled={editStatus.isEdit}
                                         color={color}
                                         size="middle"
                                         placeholder="[필수]"/>
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;
