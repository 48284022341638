import _ from "lodash";
import {
    PAYPLE_PAY_REFUND_CODE,
    PAYPLE_PAY_SUCCEESS_CODE,
    PAYPLE_PAY_SUCCEESS_FAKE_CODE,
    PayStatusColor
} from "@models/diagnosis/function/diagnosisPaymentFilter";
import {IOoDiagnosis, OoDiagnosisStatus, PayStatusData} from "@api/models";
import {formatPrice} from "@hooks/format";

export const useMedicineHospitalPaymentFilter = (diagnosis: IOoDiagnosis) => {
    // 병원 약제비 결제 성공 여부
    function isHospitalMedicinePaySucceed(): boolean {
        return !isHospitalMedicineRefundSucceed() &&
            (_.isEqual(diagnosis.hospitalMedicinePayCode, PAYPLE_PAY_SUCCEESS_CODE)
                || _.isEqual(diagnosis.hospitalMedicinePayCode, PAYPLE_PAY_SUCCEESS_FAKE_CODE));
    }

    // 병원 약제비 환불 성공 여부
    function isHospitalMedicineRefundSucceed(): boolean {
        return _.isEqual(diagnosis.hospitalMedicinePayCode, PAYPLE_PAY_REFUND_CODE);
    }

    // 약제비 결제 판단 필요 여부
    function isNoPay(): boolean {
        return diagnosis.status < OoDiagnosisStatus.medicineDeliveryPaymentOk || diagnosis.status > OoDiagnosisStatus.medicineTakeOk;
    }

    // 약제비 0원일 경우
    function isZeroPay(): boolean {
        return _.isEqual(diagnosis.hospitalMedicinePrice || 0, 0);
    }

    function getHospitalMedicinePayStatus(): PayStatusData {
        const price = _.toNumber(diagnosis.hospitalMedicinePrice || "0");
        const payString = '병원약';

        if (isZeroPay()) {
            return {
                title: ``,
                color: PayStatusColor.default
            };
        }

        if (isNoPay()) {
            if (!isHospitalMedicinePaySucceed()) {
                return {
                    title: `${payString} ${formatPrice(price)}원 결제실패`,
                    color: PayStatusColor.fail
                };
            } else if (isHospitalMedicineRefundSucceed()) {
                return {
                    title: `${payString} ${formatPrice(price)}원 환불완료`,
                    color: PayStatusColor.success
                };
            } else if (!isHospitalMedicineRefundSucceed() && !isHospitalMedicinePaySucceed()) {
                return {
                    title: `${payString} ${formatPrice(price)}원 환불실패`,
                    color: PayStatusColor.fail
                };
            } else {
                return {
                    title: isZeroPay() ? `` : ` ${payString}${formatPrice(price)}원 결제완료`,
                    color: PayStatusColor.success
                };
            }
        } else {
            return {
                title: isZeroPay() ? `` : ` ${payString}${formatPrice(price)}원 결제완료`,
                color: PayStatusColor.success
            };
        }
    }

    return {
        getHospitalMedicinePayStatus,
        isHospitalMedicinePaySucceed,
    }
}