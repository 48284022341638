import {CustomInputText} from "@components/custom";
import React, {useEffect} from "react";
import {OoParamPharmacyUpdate} from "@api/params";
import _ from "lodash";
import {usePharmacyAdditionalInfoHook} from "../hooks/usePharmacyAdditionalInfoHook";
import styled from "styled-components";
import {IOoPharmacy} from "@models/pharmacy";

interface Props {
    data: IOoPharmacy,
    componentName: string
}

export const PharmacyAdditionalInfo = ({data, componentName}: Props) => {
    const {
        editStatus,
        color,
        setColor,
        items,
        setItems,
        onChangeZipcode,
        onChangeAddress,
        onChangeAddressDetail,
        onChangeTel,
        onChangeFax,
        updatePharmacy,
        isReset,
    } = usePharmacyAdditionalInfoHook(data, componentName);

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        setItems({
            zipCode: data.zipCode,
            address: data.address,
            addressDetail: _.isUndefined(data.addressDetail) ? '' : data.addressDetail,
            tel: data.tel,
            fax: data.fax,
        })
    }, [data]);


    // 편집 모드 일때, border 색상 활성화
    useEffect(() => {
        if (editStatus.isEdit) {
            setColor("blue");
        } else {
            setColor("white");
            setItems({
                zipCode: data.zipCode,
                address: data.address,
                addressDetail: _.isUndefined(data.addressDetail) ? '' : data.addressDetail,
                tel: data.tel,
                fax: data.fax,
            });
        }
    }, [editStatus.isEdit]);

    // 전송 모드 일때, 쿼리 trigger
    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamPharmacyUpdate();
            param.id = data.id || "";
            param.tel = items.tel;
            param.zipCode = items.zipCode;
            param.fax = items.fax;
            param.address = items.address;
            param.addressDetail = items.addressDetail;
            updatePharmacy(param);
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <div>
                <div>
                    <Table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>우편번호</td>
                            <td>
                                <CustomInputText placeholder="5자리 숫자 [필수]"
                                                 value={items.zipCode}
                                                 onChanged={onChangeZipcode}
                                                 color={color}
                                                 size="middle"
                                                 disabled={editStatus.isEdit}/>
                            </td>
                        </tr>
                        <tr>
                            <td>주소</td>
                            <td>
                                <CustomInputText placeholder="[필수]"
                                                 value={items.address}
                                                 onChanged={onChangeAddress}
                                                 color={color}
                                                 size="middle"
                                                 disabled={editStatus.isEdit}/>
                            </td>
                        </tr>
                        <tr>
                            <td>상세주소</td>
                            <td>
                                <CustomInputText placeholder="[필수]"
                                                 value={items.addressDetail}
                                                 onChanged={onChangeAddressDetail}
                                                 color={color}
                                                 size="middle"
                                                 disabled={editStatus.isEdit}/>
                            </td>
                        </tr>
                        <tr>
                            <td>전화번호</td>
                            <td>
                                <CustomInputText placeholder="- 없이 입력 [필수]"
                                                 value={items.tel}
                                                 onChanged={onChangeTel}
                                                 color={color}
                                                 size="middle"
                                                 disabled={editStatus.isEdit}/>
                            </td>
                        </tr>
                        <tr>
                            <td>팩스번호</td>
                            <td>
                                <CustomInputText placeholder="- 없이 입력 [필수]"
                                                 value={items.fax}
                                                 onChanged={onChangeFax}
                                                 color={color}
                                                 size="middle"
                                                 disabled={editStatus.isEdit}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }
`;

const Table = styled.table`
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #C8D6F8;
    height: 50px;
  }

  td:nth-child(2n-1) {
    background: #F4F6FB;
    width: 100px;
    text-align: center;
    font-weight: 700;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;