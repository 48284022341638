import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {ModalOverlay} from "../modalOverlay";

export interface CancelModalProps{
    visible: boolean,
    targetTitle:string,
    onChangedVisible:(status:boolean) => void,
    onChangedCancel: (status:boolean) => void,
}

export const CancelModal = ({visible,targetTitle,onChangedVisible,onChangedCancel}:CancelModalProps) =>{
    const [handleVisible, setHandleVisible]= useState<boolean>(visible);
    const [handleCancel, setHandleCancel] =useState<boolean>(false);

    const onCancelTarget = () => setHandleCancel(!handleCancel);

    useEffect(()=>{
        setHandleVisible(visible);
    },[visible]);

    useEffect(()=>{
        onChangedVisible(handleVisible);
    },[handleVisible]);

    useEffect(()=>{
        onChangedCancel(handleCancel);
    },[handleCancel]);

    return (
        <ModalOverlay visible={handleVisible}>
            <Card>
                <TitleContainer>
                    <div>{targetTitle}을(를) 취소 하시겠습니까?</div>
                    <div>작성하던 내용이 사라집니다. 계속하겠습니까?</div>
                </TitleContainer>
                <ButtonContainer>
                    <ContinueButton type="button" onClick={()=>setHandleVisible(!handleVisible)}>계속 작성하기</ContinueButton>
                    <CancelButton type="button" onClick={onCancelTarget}>등록 취소하기</CancelButton>
                </ButtonContainer>
            </Card>
        </ModalOverlay>
    )
}

const Card = styled.div`
  width: 556px;
  height: 240px;
  background:#FFFFFF;
  border-radius: 8px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    
  & > div:nth-child(1){
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 20px;
  }
  
  & > div:nth-child(2){
    font-weight: 500;
    font-size: 13px;
    padding-bottom: 36px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ContinueButton = styled.button`
  width: 222px;
  height:60px;
  background: #CF5D5D;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  border:none;
`;

const CancelButton = styled.button`
  width: 222px;
  height:60px;
  background: #6E8ED1;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  border:none;
`;
