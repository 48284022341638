import React, {useEffect} from "react";
import {OoParamPharmacyUpdate} from "@api/params";
import {CustomInputText} from "@components/custom";
import {PharmacyPicture} from "../PharmacyPicture";
import styled from "styled-components";
import _ from "lodash";
import {usePharmacyInfoHook} from "../hooks/usePharmacyInfoHook";
import {IOoPharmacy} from "@models/pharmacy";

interface Props {
    data: IOoPharmacy;
}

export const PharmacyInfo = ({data}: Props) => {
    const {editStatus, items, onChangedPicture, updatePharmacy} = usePharmacyInfoHook(data);

    // 전송 모드 일때, 쿼리 trigger
    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamPharmacyUpdate();
            param.id = data.id || "";
            param.pictures = items.pictures;
            updatePharmacy(param);
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <div>
                <div>
                    <Table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>
                                <b>약국명</b>
                            </td>
                            <td>
                                <CustomInputText value={items.name}
                                                 color={"white"}
                                                 size="middle"
                                                 placeholder="[필수]"
                                                 disabled={false}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>이메일</b>
                            </td>
                            <td>
                                <CustomInputText value={items.email}
                                                 color={"white"}
                                                 size="middle"
                                                 placeholder="아이디@doctoron.kr [필수]"
                                                 disabled={false}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>라이센스 번호</b>
                            </td>
                            <td>
                                <CustomInputText value={items.license}
                                                 color={"white"}
                                                 size="middle"
                                                 placeholder="[필수]"
                                                 disabled={false}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
                <PictureContainer>
                    <PharmacyPicture data={data}
                                     isEdit={editStatus.isEdit}
                                     isCancel={editStatus.isCancel}
                                     onChange={onChangedPicture}/>
                </PictureContainer>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }
`;

const PictureContainer = styled.div`
  padding-left: 50px;
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding: 2px;
    font-size: 13px;
  }
`;
