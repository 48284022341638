import { IOoUnospayDelivery, OoDeliveryStatus, OoProvider } from "@api/models"
import { exCodeNumToStr, findTlcMenuList, listProviderFilter } from "../../ExcelDownloadModal";
import { ExcelExportModel } from "../model";
import _ from "lodash";
import moment from "moment";
import FileSaver from "file-saver";
import { IOoProducts } from "@api/models/productSetting/product";
const ExcelJS = require("exceljs");

/**
 * @description 닥터 다이어리 발주서 양식 다운로드
 */

const getProductInfo = (exCode: string = '', optionCode: string = '', productList: IOoProducts[] = []) => {
  const product = productList.find(p => p.externalCode === exCode);
  if (product) {
    const option = product.optionList.find(o => o.optionCode === optionCode);
    const optionName = option?.optionOrderName || option?.optionName || '';
    return [product.productName, optionName];
  }
  return ['', ''];
}

const isConsignment = (exCode: string, productList: IOoProducts[]) => {
  return productList.find(p => p.externalCode === exCode)?.isConsignment;
}

const createExcelModel = (delivery: IOoUnospayDelivery): ExcelExportModel => {
  const model = new ExcelExportModel();
  model.name = delivery.recipientName;
  model.address1 = delivery.address;
  model.address2 = delivery.addressDetail;
  model.tel1 = delivery.mobile?.replace("\t", "").split('-').join('');
  model.memo = delivery.memo;
  model.subMemo = delivery.subMemo;
  model.orderDate = moment(delivery.dateTime).format('YYYY-MM-DD');
  return model;
}

const processDeliveryItem = (delivery: IOoUnospayDelivery, productList: IOoProducts[]): ExcelExportModel[] => {
  const result: ExcelExportModel[] = [];

  delivery.salesMenu?.forEach(salesItem => {
    if (!isConsignment(salesItem.externalCode!, productList) && _.startsWith(salesItem.externalCode, 'OF32')) {
      const model = createExcelModel(delivery);
      const [productName] = getProductInfo(salesItem.externalCode, '', productList);
      const optionNames = salesItem.salesOption?.map(option => {
        if (!option.externalCode) return '';
        const matchingProduct = productList.find(p => p.externalCode === salesItem.externalCode);
        const matchingOption = matchingProduct?.optionList.find(o => o.optionCode === option.externalCode);
        return matchingOption?.optionOrderName;
      }).filter(Boolean).join(' ') || '';
      
      model.option = optionNames ? `${productName} ${optionNames}` : productName;
      result.push(model);
    }
  });

  return result;
}

const mhdOrder = async (deliveryList: IOoUnospayDelivery[], productList: IOoProducts[]) => {
  const excelList = listProviderFilter(deliveryList, exCodeNumToStr(OoProvider.mhdProvider));
  const tlcMenuMHD = findTlcMenuList(deliveryList, exCodeNumToStr(OoProvider.mhdProvider));
  const combinedList = [...excelList, ...(tlcMenuMHD || [])];

  const data_array: ExcelExportModel[] = combinedList.flatMap(delivery => 
    (delivery.status === OoDeliveryStatus.existAddress || delivery.status === OoDeliveryStatus.sendPackage) 
      ? processDeliveryItem(delivery, productList) 
      : []
  );

  // Excel 파일 생성 및 저장
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(await (await fetch("/assets/mhd.xlsx")).arrayBuffer());
  const worksheet = workbook.getWorksheet("당일발송");

  data_array.forEach((data, index) => {
    const row = worksheet.getRow(index + 2);
    [
      data.name,
      data.name,
      `${data.address1} ${data.address2}`,
      "",
      data.tel1 ? `${data.tel1.substring(0, 3)}-${data.tel1.substring(3, 7)}-${data.tel1.substring(7)}` : "",
      data.option,
      data.memo,
      "1",
      "TLC헬스케어",
      "",
      "",
      data.orderDate,
    ].forEach((value, cellIndex) => {
      const cell = row.getCell(cellIndex + 1);
      cell.value = value;
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
  });

  const excelBuffer = await workbook.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  
  const today = moment().format("YYYYMMDD");
  FileSaver.saveAs(excelFile, `${today}_닥터다이어리 발주서 양식(TLC헬스케어).xlsx`);
}

export default mhdOrder