import { IOoUnospayDelivery, IOoUnospaySalesMenuOption, OoDeliveryStatus, OoProvider } from '@api/models'
import React, { useEffect, useState } from 'react'
import { ExcelExportModel } from '../model';
import { usePaymentCalendarHook } from '@hooks/unospay';
import moment from 'moment';
import _ from "lodash";
import FileSaver, { saveAs } from 'file-saver';

const ExcelJS = require("exceljs");
 
const exCodeNumToStr = (num: number) => {
    let retStr = "";
    switch (num) {
      case 1:
        retStr = "OF04";
        break;
      case 2:
        retStr = "OF10";
        break;
      case 3:
        retStr = "OF05";
        break;
      case 4:
        retStr = "OF07";
        break;
      case 5:
        retStr = "OF13";
    }
    return retStr;
  }

  const getProductName = (excode:string) => {
    switch(excode) {
      case 'OF27_01_101':
        return '오리지널 도시락/4팩'
      case 'OF27_01_102':
        return '오리지널 도시락/8팩'
      case 'OF27_02_101':
        return '시그니처 도시락/6팩'
      case 'OF27_02_102':
        return '시그니처 도시락/12팩'
      case 'OF27_03_101':
        return '클린 도시락/4팩'
      case 'OF27_03_102':
        return '클린 도시락/8팩'
    }
  }

export const tktOrder = async (
    list: Array<IOoUnospayDelivery>,
    select: OoProvider,
) => {

    let data_array: Array<ExcelExportModel> = [];
    const filterDatas = list.filter(item => item.transportInfo.some(i => i.providerName === '팀키토'))
    console.log(filterDatas);
    
    filterDatas.map(data => 
      {
        if(data.status === OoDeliveryStatus.existAddress || data.status == OoDeliveryStatus.sendPackage){
                data.transportInfo.forEach((info, i) => {
                  if(info.providerName === '팀키토'){
                    const {externalCode, } = info;
                    const model = new ExcelExportModel();
                    const {salesOption,menuCount} = data.salesMenu![i];
                    
                    salesOption?.map((optionData: IOoUnospaySalesMenuOption) => {
                      if (optionData.externalCode && _.startsWith(externalCode, exCodeNumToStr(select))) {
                        model.product = getProductName(`${externalCode}_${optionData.externalCode}`); //상품명1
                        model.tel1 = _.startsWith(data.mobile,'\t') ? data.mobile?.replace('\t',''):_.trim(data.mobile);
                        model.address1 = data.address;    
                        model.address2 = data.addressDetail;    
                        model.name = data.recipientName;
                        model.quantity = menuCount;
                        model.memo = data.memo;
                      }
                    })
                    data_array.push({ ...model });
                  }
                })
            }
        })
        
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferTKT = await (
        await fetch("/assets/tkt.xlsx")
      ).arrayBuffer();
      const workbookTKT = new ExcelJS.Workbook();

        await workbookTKT.xlsx.load(arrayBufferTKT);
        const ws_tkt = workbookTKT.getWorksheet('Sheet1');
        const rowNumber = 2;
      
        data_array.forEach((data: ExcelExportModel, index: number) => {
          const row = ws_tkt.getRow(rowNumber + index);
            [
                data.name,
                `${data.tel1?.substring(0,3)}-${data.tel1?.substring(3,7)}-${data.tel1?.substring(7)}`,
                `${data.address1} ${data.address2}`,
                data.product,
                data.quantity,
                data.memo,
                "",
                "",
              ].forEach((value, index) => {
                const cell = row.getCell(index + 1);
                    cell.value = value;
              })

        });
        
        const excelBuffer = await workbookTKT.xlsx.writeBuffer();
        const excelFile = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(
            excelFile,
            excelFileName +
            "_" +
            moment().format("YYYYMMDD") +
            "(팀키토)" +
            excelFileExtension
        );

    }
  


export default tktOrder