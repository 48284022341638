import {AbstractOoParam} from "../base";

class OoParamKeyword extends AbstractOoParam {
    keyword: string = '';

    count?: string = '100';

    getPathParams(): string {
        return `${this.keyword}/${this.count}`;
    }
}

export {
    OoParamKeyword,
};


