import {useMutation} from "react-query";
import {useSuspenseHook} from "@hooks/suspense";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {OoParamId} from "@params/common";
import {isRequestSucceed} from "../../api";

export const useSendWaitNotificationQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'doctor/sendWaitNotification',
        async (id: string) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.telemedicine.sendDiagnosisStart(new OoParamId(id));
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        }
    )
}