import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  IOoUnospayDelivery,
  IOoUnospaySalesMenu,
  IOoUnospaySalesMenuOption,
  OoDeliveryStatus,
  OoOrderPlatformType,
  OoProvider,
} from "@api/models";
import { ExcelExportModel } from "../model";
import _ from "lodash";
import styled from "styled-components";
import { formatPrice, formatTel } from "@hooks/format";
import moment from "moment";
import React from "react";

const ExcelJS = require("exceljs");

interface Props {
  list: Array<IOoUnospayDelivery>;
  select: OoProvider;
}

// 냉장 제품 (냉동/냉장 따로 내보내기위함)
const empScheduleExCode = ["OF18_01", "OF18_02", "OF18_03", "OF18_04", "OF18_05", "OF18_11"];
const empNameCost = [
  {
    code: "OF18_01",
    optionCode: undefined,
    subName: "신장환자용식단(투석 4주 프로그램)",
    cost: 147200,
  },
  {
    code: "OF18_02",
    optionCode: undefined,
    subName: "신장환자용식단(비투석 4주 프로그램)",
    cost: 134400,
  },
  {
    code: "OF18_03",
    optionCode: undefined,
    subName: "암환자용식단(4주 프로그램)",
    cost: 153600,
  },
  {
    code: "OF18_04",
    optionCode: undefined,
    subName: "당뇨환자용식단(4주 프로그램)",
    cost: 147200,
  },
  {
    code: "OF18_05",
    optionCode: undefined,
    subName: "염분조절식단(4주 프로그램)",
    cost: 147200,
  },
  {
    code: "OF18_06",
    optionCode: undefined,
    subName: "저염도시락(6팩)",
    cost: 33900,
  },
  {
    code: "OF18_07",
    optionCode: undefined,
    subName: "저당도시락(5팩)",
    cost: 28250,
  },
  {
    code: "OF18_08",
    optionCode: undefined,
    subName: "비투석신장질환자용 부리또 2종(8개)",
    cost: 32960,
  },
  {
    code: "OF18_09",
    optionCode: undefined,
    subName: "요오드조절 보양죽5종(5팩)",
    cost: 22660,
  },
  {
    code: "OF18_10",
    optionCode: undefined,
    subName: "비투석 식물성 덮밥 4종",
    cost: 18600,
  },
  {
    code: "OF18_11",
    optionCode: "101",
    subName: "요오드조절식단(1주 프로그램)",
    cost: 104836,
  },
  {
    code: "OF18_11",
    optionCode: "102",
    subName: "요오드조절식단(2주 프로그램)",
    cost: 209671,
  },
  {
    code: "OF18_12",
    optionCode: undefined,
    subName: "당뇨덮밥도시락(6팩)",
    cost: 27900,
  },
];

function addDays(date: Date, days: number) {
  const clone = new Date(date);
  clone.setDate(date.getDate() + days);
  return clone;
}

function getDeliveryCount(option: IOoUnospaySalesMenu) {
  let retCount = 1;
  if (
    _.isEqual(option.externalCode, "OF04_01") ||
    _.isEqual(option.externalCode, "OF04_11") ||
    _.isEqual(option.externalCode, "OF04_02") ||
    _.isEqual(option.externalCode, "OF04_03") ||
    _.isEqual(option.externalCode, "OF04_04") ||
    _.isEqual(option.externalCode, "OF04_05") ||
    _.isEqual(option.externalCode, "OF04_10") ||
    _.isEqual(option.externalCode, "OF04_09")
  ) {
    switch (_.first(option.salesOption)?.externalCode) {
      case "301":
      case "302":
      case "303":
      case "304":
        retCount = 1;
        break;
      case "101":
        retCount = 2;
        break;
      case "102":
      case "04":
      case "02":
        retCount = 6;
        break;
      case "103":
      case "05":
      case "03":
        retCount = 12;
        break;
    }
  } else if (
    _.isEqual(option.externalCode, "OF04_06") ||
    _.isEqual(option.externalCode, "OF04_07") ||
    _.isEqual(option.externalCode, "OF04_08") ||
    _.isEqual(option.externalCode, "OF05_01")
  ) {
    retCount = 6;
  } else if (_.isEqual(option.externalCode, "OF05_02")) {
    retCount = 12;
  } else if (
    _.isEqual(option.externalCode, "OF05_03") ||
    _.isEqual(option.externalCode, "OF05_04") ||
    _.isEqual(option.externalCode, "OF05_07") ||
    _.isEqual(option.externalCode, "OF05_08") ||
    _.isEqual(option.externalCode, "OF05_09")
  ) {
    retCount = 18;
  } else if (_.isEqual(option.externalCode, "OF05_10")) {
    retCount = 2;
  } else if (
    _.isEqual(option.externalCode, "OF05_11") ||
    _.isEqual(option.externalCode, "OF05_12") ||
    _.isEqual(option.externalCode, "OF05_13")
  ) {
    retCount = 4;
  } else if (_.isEqual(option.externalCode, "OF05_06")) {
    retCount = 24;
  } else if (
    _.isEqual(option.externalCode, "OF05_14") ||
    _.isEqual(option.externalCode, "OF05_15")
  ) {
    retCount = 21;
  }
  return retCount;
}

function getOptionDeliveryCount(optionExternalCode: string) {
  let retCount = 1;
  if (_.isEqual(optionExternalCode, "OF04_20")) {
    retCount = 1;
  }
  return retCount;
}

function convTimestampDateFormat(timestamp: number) {
  var dateFormat = new Date(timestamp);
  return (
    dateFormat.getFullYear() +
    "-" +
    (dateFormat.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    dateFormat.getDate().toString().padStart(2, "0")
  );
}

function getNewCareInfo(optionCode: string | undefined) {
  if (
    _.isEqual(optionCode, "01") ||
    _.isEqual(optionCode, "02") ||
    _.isEqual(optionCode, "03") ||
    _.isEqual(optionCode, "04") ||
    _.isEqual(optionCode, "05")
  ) {
    return "0";
  } else {
    return "";
  }
}

function getMenuPrice(exCode: string) {
  let ret = "";
  switch (exCode) {
    case "OF04_01": // 당뇨
    case "OF04_11": // 저당
    case "OF04_02": // 암환자
      ret = "7,700";
      break;
    case "OF04_03": // 퇴원후 회복식 (장수마을)
    case "OF04_10": // 암환자 회복식
      ret = "7,300";
      break;
    case "OF04_05": // 샐러드 식단
      ret = "6,300";
      break;
    case "OF04_09":
      ret = "7,700";
      break;
    case "OF04_07":
      ret = "4,300";
      break;
    case "OF04_06":
      ret = "3,950";
      break;
    case "OF04_08":
      ret = "5,250";
      break;
  }
  return ret;
}

function exCodeNumToStr(num: number) {
  let retStr = "";
  switch (num) {
    case 1:
      retStr = "OF04";
      break;
    case 2:
      retStr = "OF10";
      break;
    case 3:
      retStr = "OF05";
      break;
    case 4:
      retStr = "OF07";
      break;
    case 5:
      retStr = "OF13";
      break;
    case 6:
      retStr = "OF18";
  }
  return retStr;
}

export const empOrder = async (list: Array<IOoUnospayDelivery>, select: OoProvider) => {
  let data_array: Array<ExcelExportModel> = [];
  console.log(list);
  //공휴일 적용 안됨
  const today = new Date();
  let minDate: Date;
  const dayOfWeek = today.getDay(); // 0은 일요일, 1은 월요일, ... 6은 토요일
  switch (dayOfWeek) {
    case 1: // 월요일
      minDate = addDays(today, 2);
      break;
    case 2: // 화요일
      minDate = addDays(today, 2);
      break;
    case 3: // 수요일
      minDate = addDays(today, 2);
      break;
    case 4: // 목요일
      minDate = addDays(today, 2);
      break;
    case 5: // 금요일
      minDate = addDays(today, 3);
      break;
    case 6: // 토요일
      minDate = addDays(today, 4);
      break;
    case 0: // 일요일
      minDate = addDays(today, 3);
      break;
    default:
      minDate = addDays(today, 1);
  }

  list.forEach((e, i) => {
    if (e.status == OoDeliveryStatus.existAddress || e.status == OoDeliveryStatus.sendPackage) {
      e.transportInfo.forEach((info, i) => {
        const model = new ExcelExportModel();
        const option = e.salesMenu![i];
        //옵션으로 묶인 단품메뉴들
        option.salesOption?.map((optionData: IOoUnospaySalesMenuOption) => {
          if (_.startsWith(optionData.externalCode, exCodeNumToStr(select))) {
            console.log("optionData");
            model.excelStatus =
              e.status == OoDeliveryStatus.sendPackage ? "sendPackage" : "existAddress";
            model.product = info.menuName;
            model.exCode = info.externalCode;
            // model.quantity = e.salesMenu![i].menuCount;
            model.orderDate = convTimestampDateFormat(e.timestamp);

            model.name = e.recipientName;
            model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
            model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
            model.address1 = e.address;
            model.address2 = e.addressDetail;
            model.zipCode = e.zipCode;
            model.memo = e.memo;
            model.subMemo = e.subMemo;

            option.salesOption?.forEach((e) => {
              if (!_.isEmpty(e.externalCode)) {
                model.optionCodeList!.push(e.externalCode!);
              }
            });

            model.quantity = option.menuCount;
            model.withNewCare = getNewCareInfo(_.first(option.salesOption)?.externalCode) || "";

            if (e.salesMenu && e.salesMenu.length > i) {
              const salesMenuItem = e.salesMenu[i];

              const count = getOptionDeliveryCount(optionData.externalCode!);

              if (
                info.externalCode?.startsWith("OF05") ||
                info.externalCode?.startsWith("OF07") ||
                info.externalCode?.startsWith("OF13") ||
                info.externalCode?.startsWith("OF18")
              ) {
                model.deliveryDate =
                  e.salesMenu![i].selectMenu![0].date != null
                    ? e.salesMenu![i].selectMenu![0].date
                    : "";
              }

              for (var j = 0; j < count; j++) {
                data_array.push({ ...model });
              }
            }
          }
        });

        if (_.startsWith(info.externalCode, exCodeNumToStr(select))) {
          model.excelStatus =
            e.status == OoDeliveryStatus.sendPackage ? "sendPackage" : "existAddress";
          model.product = info.menuName;
          model.exCode = info.externalCode;
          // model.quantity = e.salesMenu![i].menuCount;
          model.orderDate = convTimestampDateFormat(e.timestamp);

          model.name = e.recipientName;
          model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
          model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
          model.address1 = e.address;
          model.address2 = e.addressDetail;
          model.zipCode = e.zipCode;
          model.memo = e.memo;
          model.subMemo = e.subMemo;

          option.salesOption?.forEach((e) => {
            if (!_.isEmpty(e.externalCode)) {
              model.optionCodeList!.push(e.externalCode!);
            }
          });

          model.quantity = option.menuCount;

          const count = getDeliveryCount(option!);
          model.withNewCare = getNewCareInfo(_.first(option.salesOption)?.externalCode) || "";
          model.price =
            getMenuPrice(info.externalCode) || formatPrice(Number(option.menuPrice)).toString();

          if (
            info.externalCode?.startsWith("OF05") ||
            info.externalCode?.startsWith("OF07") ||
            info.externalCode?.startsWith("OF13") ||
            info.externalCode?.startsWith("OF18")
          ) {
            if (e.salesMenu![i].selectMenu != undefined) {
              model.deliveryDate =
                e.salesMenu![i].selectMenu![0].date != null
                  ? e.salesMenu![i].selectMenu![0].date
                  : "";
            }
          }

          if (e.salesMenu && e.salesMenu.length > i) {
            const salesMenuItem = e.salesMenu[i];

            const count = getDeliveryCount(salesMenuItem);

            for (var j = 0; j < count; j++) {
              data_array.push({ ...model });
            }
          }
        }
      });
    }
  });

  const excelFileExtension = ".xlsx";
  const excelFileName = `tlc헬스케어 발주서`;

  const arrayBufferMdsl = await (await fetch("/assets/emp.xlsx")).arrayBuffer();
  const workbookEmp = new ExcelJS.Workbook();
  await workbookEmp.xlsx.load(arrayBufferMdsl);
  const ws_emp = workbookEmp.getWorksheet("잇마플 발주서");
  const workbookEmpIce = new ExcelJS.Workbook();
  await workbookEmpIce.xlsx.load(arrayBufferMdsl);
  const ws_emp_ice = workbookEmpIce.getWorksheet("잇마플 발주서");

  if (select == OoProvider.empProvider) {
    // moment.locale('ko')
    let data_array_refrigerated: Array<ExcelExportModel> = [];
    let data_array_frozen: Array<ExcelExportModel> = [];
    const rowNumber = 2;
    data_array.map((data: ExcelExportModel, index: number) => {
      if (empScheduleExCode.includes(data.exCode!)) {
        data_array_refrigerated.push(data);
      } else {
        console.log(JSON.stringify(data));
        data_array_frozen.push(data);
      }
    });

    data_array_refrigerated.map((data: ExcelExportModel, index: number) => {
      const row = ws_emp.getRow(rowNumber + index);
      [
        data.orderDate,
        moment(data.deliveryDate).format("dddd"),
        moment(data.deliveryDate).format("YYYY-MM-DD"),
        empNameCost.find(
          (item) => item.code === data.exCode && _.first(data.optionCodeList) == item.optionCode
        )?.subName,
        Number(data.quantity),
        Number(
          empNameCost.find(
            (item) => item.code === data.exCode && _.first(data.optionCodeList) == item.optionCode
          )?.cost
        ),
        data.name,
        data.name,
        data.tel1,
        data.address1 + " " + data.address2,
        data.memo,
        "",
        "",
      ].forEach((value, index) => {
        const cell = row.getCell(index + 1); // 엑셀은 1부터 시작
        if (index == 5) {
          cell.value = value;
          cell.numFmt = "#,###";
        } else {
          cell.value = value;
        }
        cell.alignment={
          horizontal: 'left',
        }
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });
    data_array_frozen.map((data: ExcelExportModel, index: number) => {
      const row = ws_emp_ice.getRow(rowNumber + index);
      [
        // data.orderDate, '', '',
        // empNameCost.find(item => item.code === data.exCode && _.first(data.optionCodeList) == item.optionCode)?.subName, data.quantity, Number(empNameCost.find(item => item.code === data.exCode && _.first(data.optionCodeList) == item.optionCode)?.cost),
        // data.name, data.name, data.tel1, data.address1 + ' ' + data.address2, data.memo, "", ""
        data.orderDate,
        "",
        "",
        empNameCost.find(
          (item) => item.code === data.exCode && _.first(data.optionCodeList) == item.optionCode
        )?.subName,
        Number(data.quantity),
        Number(
          empNameCost.find(
            (item) => item.code === data.exCode && _.first(data.optionCodeList) == item.optionCode
          )?.cost
        ),
        data.name,
        data.name,
        data.tel1,
        data.address1 + " " + data.address2,
        data.memo,
        "",
        "",
      ].forEach((value, index) => {
        const cell = row.getCell(index + 1); // 엑셀은 1부터 시작
        if (index == 5) {
          cell.value = value;
          cell.numFmt = "#,###";
        } else {
          cell.value = value;
        }
        cell.alignment={
          horizontal: 'left',
        }
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });
  }
  const excelBuffer = await workbookEmp.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFile,
    excelFileName + "_" + moment().format("YYYYMMDD") + "(잇마플냉장)" + excelFileExtension
  );
  const excelBufferIce = await workbookEmpIce.xlsx.writeBuffer();
  const excelFileIce = new Blob([excelBufferIce], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFileIce,
    excelFileName + "_" + moment().format("YYYYMMDD") + "(잇마플냉동)" + excelFileExtension
  );
};
