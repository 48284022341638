import React from "react";
import _ from "lodash";
import styled from "styled-components";
import {IOoDiagnosis} from "@api/models";
import {formatDateYYYYMDHHmm, formatDateYyyyMMDHHmmss, formatIdentityNumber, formatTel} from "@hooks/format";

interface Props {
    data: IOoDiagnosis,
}

export const PatientInfo = ({data}: Props) => {
    return (
        <>
            <div><b>환자 기본 정보</b></div>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>Chart No.</td>
                    <td>{formatDateYyyyMMDHHmmss(data.timestamp)}</td>
                    <td>접수 시간</td>
                    <td>{formatDateYYYYMDHHmm(data.timestamp)}</td>
                    <td>성별</td>
                    <td>{_.isEqual(data.patientGender, 'male') ? '남자' : '여자'}</td>
                </tr>
                <tr>
                    <td>진료대상</td>
                    <td>{data.patientRelation}</td>
                    <td>이름</td>
                    <td>{data.patientName}</td>
                    <td>몸무게</td>
                    <td>{_.isEqual(data.weight, 0) ? '없음' : data.weight}</td>
                </tr>
                <tr>
                    <td>전화번호</td>
                    <td>{formatTel(data.patientTel)}</td>
                    <td>주민등록번호</td>
                    <td>{formatIdentityNumber(data.patientIdentityNumber)}</td>
                    <td>{`서비스 이용 \n 약관 동의`}</td>
                    <td>{data.isTermAgreed ? '동의' : '미동의'}</td>
                </tr>
                </tbody>
            </Table>
        </>
    );
}

const Table = styled.table`
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: 100%;
  height: 144px;
  font-size: 13px;
  margin-top: 8px;
  white-space: pre-wrap;

  tr {
    border-bottom: 1px solid #C8D6F8;
  }

  tr td:nth-child(2n-1) {
    font-weight: 700;
    font-size: 13px;
    background: #F4F6FB;
    text-align: center;
    width: 10%;
    height: 48px;
  }

  tr td:nth-child(2n) {
    padding-left: 20px;
    width: 25%;
  }
`;