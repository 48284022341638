import {atom} from "recoil";

export interface DoctorCalendarParamProp {
    doctorId: string;
    beginTimestamp: string;
    endTimestamp: string;
    count?: string;
    diagnosisId?: string;
}

export const doctorCalendarParamState = atom<DoctorCalendarParamProp>({
    key: 'doctor/doctorCalendarParam',
    default: {
        doctorId: "",
        beginTimestamp: "",
        endTimestamp: "",
        count: "",
        diagnosisId: "",
    },
})