import {useQuery} from "react-query";
import {DoctorOnAPI, isRequestSucceed, OoParamId} from "../../api";
import {useSuspenseHook} from "../../hooks";

export const usePharmacyFindQuery = (id: string) => {
    const {setErrorStatus} = useSuspenseHook();

    return useQuery(
        'operator/pharmacyFind',
        async () => {
            try {
                const param = new OoParamId(id);
                const response = await DoctorOnAPI.shared.pharmacy.readByUserId(param);
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        }, {
            enabled: true,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always",  // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )

};