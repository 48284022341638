import {IOoHospital} from "@api/models";
import {CustomInputText} from "@components/custom";
import {useRecoilValue} from "recoil";
import {editSelectorFamily} from "@recoils/status";
import styled from "styled-components";
import classNames from "classnames/bind";
import selectStyles from '@styles/common/Select.module.scss';
import {useEffect, useState} from "react";
import _ from "lodash";
import {OoParamHospitalUpdate} from "@api/params";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";
import {useHospitalFunctionHook} from "@hooks/hospital";

interface Props {
    componentName: string,
    data: IOoHospital,
}

export const HospitalMedicine = ({componentName, data}: Props) => {
    const sx = classNames.bind(selectStyles);

    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const [color, setColor] = useState<string>('grey');
    const [hospital, setHospital] = useState<string>(_.isUndefined(data.hospitalMedicinePickupId) || _.isEmpty(data.hospitalMedicinePickupId) ? "-" : data.hospitalMedicinePickupId);
    const {getPharmacyById} = usePharmacyFunctionHook();
    const {updateHospital, getHospitalById} = useHospitalFunctionHook();

    const onChangePickupAddress = (e: any) => {
        setHospital(e.target.value);
    };

    const getTitle = (id: string) => {
        if (getHospitalById(id)) {
            return '병원 발송';
        } else if (getPharmacyById(id)) {
            return getPharmacyById(id)?.name;
        } else {
            return '-';
        }
    };

    useEffect(() => {
        editStatus.isEdit && setColor('blue');
    }, [editStatus.isEdit]);

    useEffect(() => {
        if (!editStatus.isSubmit) return;
        if (window.confirm('수정하시겠습니까?')) {
            let param = new OoParamHospitalUpdate();
            switch (hospital) {
                case '-':
                    param.id = data.id || '';
                    param.updateClearAll();
                    updateHospital(param);
                    break;
                case data.id || '':
                    param.id = data.id || '';
                    param.updateHospital(data);
                    updateHospital(param);
                    break;
                default:
                    param.id = data.id || '';
                    param.updatePharmacy(getPharmacyById(hospital));
                    updateHospital(param);
            }
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            {
                editStatus.isEdit ?
                    <select className={sx('select', 'middle', color)} value={hospital} onChange={onChangePickupAddress}>
                        <option value="-">-</option>
                        <option value={data.id || ''}>병원 발송</option>
                        <option value={_.head(data.pharmacies)?.id || ''}
                                hidden={_.isUndefined(_.head(data.pharmacies))}>{_.head(data.pharmacies)?.name || ''}</option>
                    </select>
                    :
                    <CustomInputText size="middle"
                                     value={getTitle(hospital)}
                                     disabled={editStatus.isEdit}/>
            }

        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;