import React, {useEffect, useState} from "react";
import _ from "lodash";
import styled from "styled-components";
import {IOoDiagnosis} from "@models/diagnosis";
import {ModalOverlay} from "@components/modal";
import {filteredReservationType} from "@models/reservation";
import {formatDateYYYYMDHHmm} from "@hooks/format";

interface Props {
    visible: boolean,
    unVisible: (e: boolean) => void,
    target: IOoDiagnosis,
}

export const ReservationCancelModal = ({visible, unVisible, target}: Props) => {
    const [show, setShow] = useState<boolean>(false);
    const onClickCloseButton = () => setShow(false);

    useEffect(() => {
        _.isEqual(show, false) && unVisible(show);
    }, [show]);

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    return (
        <ModalOverlay visible={show}>
            <Wrapper>
                <TopContainer>
                    <div> 예약 취소</div>
                </TopContainer>
                <MiddleContainer>
                    <div>
                        접수 방식 : {filteredReservationType(target)}
                    </div>
                    <div>
                        접수 번호 : {target.wapleReservationTimestamp}
                    </div>
                    <div>
                        예약 일시 : {formatDateYYYYMDHHmm(target.wapleReservationTimestamp ?? 0)}
                    </div>
                    <div>
                        이름 : {target.patientName}
                    </div>
                </MiddleContainer>
                <BottomContainer>
                    <div> 상기 예약이 취소되었습니다.</div>
                    <ButtonContainer>
                        <button onClick={onClickCloseButton}>닫기</button>
                    </ButtonContainer>
                </BottomContainer>
            </Wrapper>
        </ModalOverlay>
    )
}

const Wrapper = styled.div`
  width: 550px;
  height: 384px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 36px;
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 47px;

  & > div:nth-child(1) {
    font-weight: 700;
    font-size: 20px;
  }
`;

const MiddleContainer = styled.div`
  font-weight: 500;
  font-size: 13px;
  padding-top: 20px;
  text-align: left;
  height: 100px;

  & > div:nth-child(3) {
    color: #2452B1;
  }
`;

const BottomContainer = styled.div`
  font-weight: 700;
  font-size: 12px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
  text-align: left;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;

  & > button {
    width: 100%;
    height: 60px;
    background: #6E8ED1;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
  }
`;