import {AbstractOoParam} from "@api/params";

class OoParamReservationCancel extends AbstractOoParam {
    // 진료 ID
    id: string = '';

    // 취소사유
    comment?: string = undefined;
}

export {
    OoParamReservationCancel
};