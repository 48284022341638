import {atom} from "recoil";
import {IOoPharmacy} from "@models/pharmacy";

const pharmacyListState = atom<Array<IOoPharmacy>>({
    key: 'pharmacyListState',
    default: [],
});

export {
    pharmacyListState
};