import { IOoCalculateMonthPaymentData } from '@api/models/calculate/calculateMonthPaymentData';
import { DoctorOnAPI } from '@api/request';
import PieChart from '@components/chart/pieChart';
import { ModalOverlay } from '@components/modal';
import classNames from 'classnames';
import moment from 'moment';
import React, { ChangeEvent, ChangeEventHandler, MouseEvent, MouseEventHandler, Suspense, useEffect, useState } from 'react'
import Calendar from 'react-calendar';
import styled from 'styled-components'
import buttonStyles from "@styles/common/Button.module.scss";
import { Spinner } from 'react-bootstrap';
import _ from 'lodash';
import { useSuspenseHook } from '@hooks/suspense';

interface ChartContainerStyle {
  display?:string;
  align?:string;
  justify?:string;
}

const PieChartHospitalTypeDashBoard = () => {
  const [monthDataList, setMonthDataList] = useState<IOoCalculateMonthPaymentData[]>([]);
  const [loading, setLoading] = useState(true)
  const totalValue = monthDataList.reduce((acc, item) => acc + item.totalPrice, 0);
  const [visible , setVisible] = useState(false);
  const [month, setMonth] = useState<Date | null>(new Date());

  const bx = classNames.bind(buttonStyles);
  const getMonthTotalPrice = async () => {
    try {
      const date = moment(month).format('YYYYMM')
      const result = await DoctorOnAPI.shared.calculate.orderStatisticsTypeData(date);
      if(result.status === 'ok'){
        setMonthDataList(result.data!);
        setLoading(false);
      }
    } catch (error) {
      
    }
  }

  const clickHandleMonth = (value: Date) => {
    setMonth(value);
    setVisible(false);
  }

  const onShowCalendar = () => {
    setVisible(true)
  }

  useEffect(() => {
    getMonthTotalPrice();
  },[month])

  return (
    <Wrapper>
      {loading ? <SpinnerContainer><Spinner animation="border" variant="primary"/></SpinnerContainer> : 
      <>
        <h3>월 총 매출 현황</h3>
      <LayoutContainer>
        {monthDataList.length > 0 ? 
          <ChartContainer>
            <PieChart list={monthDataList}/>
            <TotalText>
              총 합계 : {totalValue.toLocaleString()} 원
            </TotalText>
          </ChartContainer>
          : 
          <ChartContainer display='flex' align='center' justify='center'><span>데이터가 없습니다.</span></ChartContainer>
        }
          <CalendarContainer>
              <ModalOverlay visible={visible}>
                <CalendarWrapper>
                  <Calendar view='year' onClickMonth={clickHandleMonth} value={month}/>
                  <button type="button" className={bx("button")} onClick={() => setVisible(false)}>
                    닫기
                  </button>
                </CalendarWrapper>
              </ModalOverlay>
              <CalendarButton type="button" onClick={onShowCalendar}>
                <div>
                  <img src={process.env.PUBLIC_URL + `/img/calendar-icon.png`} alt="calendar" />
                  {moment(month).format('YYYY.MM')}
                </div>
              </CalendarButton>
          </CalendarContainer>
        </LayoutContainer>
        </>
        }
    </Wrapper>
  )
}

export default PieChartHospitalTypeDashBoard

const Wrapper = styled.div`
    width: 100%;
    height: 550px;
    background-color: #fff;
    box-shadow: 0 1px 3px -2px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    ms-overflow-style: none;

    & > h3 {
      align-self: center;
      padding: 25px 0px 0px 0px;
    }
`

const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const LayoutContainer = styled.div`
    width: 100%;
    display: flex;
`

const ChartContainer = styled.div<ChartContainerStyle>`
    width: 70%;
    height: 450px;
    display: ${props => props.display};
    align-items: ${props => props.align};
    justify-content: ${props => props.justify};
`

const TotalText = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    transform: translate(33%, -80px);
`

const CalendarContainer = styled.div`
  width: 30%;
  height: 450px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const CalendarWrapper = styled.div`
  background: #fff;
  width: 400px;
  height: auto;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  flex-direction: column;
  z-index: 999;
  box-sizing: border-box;

    .react-calendar {
      border: none;
    }

    .react-calendar__year-view__months {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 300px;
      height: 300px;
    }
`;

const CalendarButton = styled.button`
  width: 180px;
  height: 36px;
  border-radius: 8px;
  border: 0.5px solid #c2c1bd;
  background: #ffffff;
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    & > img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

`;
