import React from 'react'
import styled from 'styled-components';
import { Card, Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AiOutlineMinusSquare } from 'react-icons/ai';

interface SelectedProductInfo{
    providerName:string;
    providerCode:string;
    productName:string;
    externalCode:string;
    optionCode:string;
    optionName:string;
    productPrice:number;
    menuCount:number;
}

interface InputButtonLayoutStyle{
    gridTemplateColumns?:string;
}

interface LayoutStyle{
    width?:string;
}

interface Props{
    item: SelectedProductInfo;
    index:number;
    handleClickMinusProduct: (index: number) => void;
}

const ProductOrderList = ({item , index , handleClickMinusProduct}:Props) => {
  return (
    <CardLayout>
        <Card.Body>
        <Row>
            <Col>
                <span>{index+1}</span>
            </Col>
            <Col>
            <Layout>
                <ReadOnlyInputLayout>
                    <label>
                    업체명
                    </label>
                    <input  name='providerName' value={item.providerName}
                    disabled
                    />
                </ReadOnlyInputLayout>
            </Layout>
            </Col>
            <Col>
            <Layout>
                <ReadOnlyInputLayout>
                    <label>
                    제품명
                    </label>
                    <input placeholder='' name='productName' value={item.productName}
                    disabled
                    />
                </ReadOnlyInputLayout>
            </Layout>
            </Col>
            <Col>
            <Layout>
                <ReadOnlyInputLayout>
                    <label>
                    옵션명
                    </label>
                    <input placeholder='' name='optionCode' value={item.optionName}
                    disabled
                    />
                </ReadOnlyInputLayout>
            </Layout>
            </Col>
            <Col>
            <Layout>
                <InputButtonLayout  gridTemplateColumns='50px auto;'>
                    <label>
                    수량
                    </label>
                    <input placeholder='' name='optionCode' value={item.menuCount}
                    disabled
                    />
                </InputButtonLayout>
            </Layout>
            </Col>
        </Row>
        <Button onClick={() => handleClickMinusProduct(index)}><AiOutlineMinusSquare /></Button>
        </Card.Body>
        <PriceLayout>
            <span>상품 금액 : {item.productPrice.toLocaleString()}원</span>
        </PriceLayout>
    </CardLayout>
  )
}

export default ProductOrderList

const CardLayout = styled.div`
    padding: 5px 0px 10px;
    border-bottom: 2px solid #C8D6F8;
    &:last-child {
        border: none;
    }
`
const PriceLayout = styled.div`
    text-align: right;
    padding: 2px 55px;
`

const Layout = styled.div<LayoutStyle>`
    position: relative;
`

const Button = styled.button`
    border: none;
    background: transparent;
    & > svg {
        font-size: 35px;
        color: #0a53be;
        opacity: 0.5;
    }
    & > svg:hover {
        color: #0a53be;
        opacity: 1;
    }
`


const InputButtonLayout = styled.div<InputButtonLayoutStyle>`
    display: grid;
    grid-template-columns:${props => props.gridTemplateColumns}
    align-items: center;

    border-radius: 5px;
    & > input {
        width: 100%;
        border:none;
        border: 1px solid #cfdbf7;
        padding: 5px 7px;
        box-sizing: border-box;
    }
    & > label {
        padding: 6px 7px;
        background-color: #cfdbf7;
    }
    & > button {
       height:100%;
        border: none;
    }
`

const ReadOnlyInputLayout = styled.div`
    display: grid;
    grid-template-columns: 75px auto;
    align-items: center;

    border-radius: 5px;
    & > input {
        width: 100%;
        border:none;
        border: 1px solid #cfdbf7;
        padding: 5px 7px;
        box-sizing: border-box;
    }
    & > label {
        padding: 6px 7px;
        background-color: #cfdbf7;
    }
`