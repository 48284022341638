import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  IOoUnospayDelivery,
  IOoUnospaySalesMenu,
  IOoUnospaySalesMenuOption,
  OoDeliveryStatus,
  OoOrderPlatformType,
  OoProvider,
} from "@api/models";
import { ExcelExportModel } from "../model";
import _ from "lodash";
import styled from "styled-components";
import { formatPrice, formatTel } from "@hooks/format";
import moment from "moment";
import React from "react";
import { exCodeNumToStr, findTlcMenuList, listProviderFilter } from "../../ExcelDownloadModal";

const ExcelJS = require("exceljs");

interface Props {
  list: Array<IOoUnospayDelivery>;
  select: OoProvider;
}

const getDeliveryMessage = (data: ExcelExportModel) => {
  
  switch (data.subMemo) {
    case "6":
      return data.memo;
    case "2":
      return "자율 출입가능";
    case "7":
      return "무인택배함";
    case "":
      return data.memo;
    default:
      return data.subMemo;
  }
};

function addDays(date: Date, days: number) {
  const clone = new Date(date);
  clone.setDate(date.getDate() + days);
  return clone;
}

function getDeliveryCount(option: IOoUnospaySalesMenu) {
  let retCount = 1;
  if (
    _.isEqual(option.externalCode, "OF04_01") ||
    _.isEqual(option.externalCode, "OF04_11") ||
    _.isEqual(option.externalCode, "OF04_02") ||
    _.isEqual(option.externalCode, "OF04_03") ||
    _.isEqual(option.externalCode, "OF04_04") ||
    _.isEqual(option.externalCode, "OF04_05") ||
    _.isEqual(option.externalCode, "OF04_10") ||
    _.isEqual(option.externalCode, "OF04_09")
  ) {
    switch (_.first(option.salesOption)?.externalCode) {
      case "301":
      case "302":
      case "303":
      case "304":
        retCount = 1;
        break;
      case "101":
        retCount = 2;
        break;
      case "102":
      case "04":
      case "02":
        retCount = 6;
        break;
      case "103":
      case "05":
      case "03":
        retCount = 12;
        break;
    }
  } else if (
    _.isEqual(option.externalCode, "OF04_06") ||
    _.isEqual(option.externalCode, "OF04_07") ||
    _.isEqual(option.externalCode, "OF04_08") ||
    _.isEqual(option.externalCode, "OF05_01")
  ) {
    retCount = 6;
  } else if (_.isEqual(option.externalCode, "OF05_02")) {
    retCount = 12;
  } else if (
    _.isEqual(option.externalCode, "OF05_03") ||
    _.isEqual(option.externalCode, "OF05_04") ||
    _.isEqual(option.externalCode, "OF05_07") ||
    _.isEqual(option.externalCode, "OF05_08") ||
    _.isEqual(option.externalCode, "OF05_09")
  ) {
    retCount = 18;
  } else if (_.isEqual(option.externalCode, "OF05_10")) {
    retCount = 2;
  } else if (
    _.isEqual(option.externalCode, "OF05_11") ||
    _.isEqual(option.externalCode, "OF05_12") ||
    _.isEqual(option.externalCode, "OF05_13")
  ) {
    retCount = 4;
  } else if (_.isEqual(option.externalCode, "OF05_06")) {
    retCount = 24;
  } else if (
    _.isEqual(option.externalCode, "OF05_14") ||
    _.isEqual(option.externalCode, "OF05_15")
  ) {
    retCount = 21;
  }
  return retCount;
}

function getOptionDeliveryCount(optionExternalCode: string) {
  let retCount = 1;
  if (_.isEqual(optionExternalCode, "OF04_20")) {
    retCount = 1;
  }
  return retCount;
}

const delivery = (excode:string) => {
  
  switch(excode) {
    case "OF07_09":
    case "OF07_14":
    case "OF07_15":
    case "OF07_01_201":
    case "OF07_01_202":
    case "OF07_02_201":
    case "OF07_02_202":
    case "OF07_14_101":
    case "OF07_14_102":
    case "OF07_15_101":
    case "OF07_15_102":
      return "0"
    case "OF07_03_201":
    case "OF07_04_201":
    case "OF07_08_201":
    case "OF07_06_201":
    case "OF07_16_101":
      return "1"
    case "OF07_03_202":
    case "OF07_04_202":
    case "OF07_08_202":
    case "OF07_06_202":
    case "OF07_16_102":
      return "2"
    default:
      return "0"
  }
}

function convTimestampDateFormat(timestamp: number) {
  var dateFormat = new Date(timestamp);
  return (
    dateFormat.getFullYear() +
    "-" +
    (dateFormat.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    dateFormat.getDate().toString().padStart(2, "0")
  );
}

function getNewCareInfo(optionCode: string | undefined) {
  if (
    _.isEqual(optionCode, "01") ||
    _.isEqual(optionCode, "02") ||
    _.isEqual(optionCode, "03") ||
    _.isEqual(optionCode, "04") ||
    _.isEqual(optionCode, "05")
  ) {
    return "0";
  } else {
    return "";
  }
}

function getMenuPrice(exCode: string) {
  let ret = "";
  switch (exCode) {
    case "OF04_01": // 당뇨
    case "OF04_11": // 저당
    case "OF04_02": // 암환자
      ret = "7,700";
      break;
    case "OF04_03": // 퇴원후 회복식 (장수마을)
    case "OF04_10": // 암환자 회복식
      ret = "7,300";
      break;
    case "OF04_05": // 샐러드 식단
      ret = "6,300";
      break;
    case "OF04_09":
      ret = "7,700";
      break;
    case "OF04_07":
      ret = "4,300";
      break;
    case "OF04_06":
      ret = "3,950";
      break;
    case "OF04_08":
      ret = "5,250";
      break;
  }
  return ret;
}

function getProductInfoPmw(code: string): [string, string, string, string] {
  switch (code) {
    case "OF07_01_201":
      return ["디자인밀 저당식단_A세트 (6끼)", "0303915", "61020","0002"];
    case "OF07_01_202":
      return ["디자인밀 저당식단_B세트 (6끼)", "0304677", "61020","0002"];
    case "OF07_02_201":
      return ["디자인밀 고단백식단_A세트 (6끼)", "0303917", "61020","0002"];
    case "OF07_02_202":
      return ["디자인밀 고단백식단_B세트 (6끼)", "0304678", "61020","0002"];
    case "OF07_03_201":
      return ["300샐러드밀/5끼 (300kcal)", "0302825", "33000","0001"];
    case "OF07_03_202":
      return ["300샐러드밀/10끼 (300kcal)", "0302825", "66000","0001"];
    case "OF07_04_201":
      return ["300라이스밀/5끼 (300kcal)", "0302998", "30360","0001"];
    case "OF07_04_202":
      return ["300라이스밀/10끼 (300kcal)", "0302998", "60720","0001"];
    case "OF07_06_201":
      return ["정성한상PLUS/5끼", "0303745", "46200","0001"];
    case "OF07_06_202":
      return ["정성한상PLUS/10끼", "0303745", "92400","0001"];
    case "OF07_08":
      return ["NEW정성한상/5끼", "0303744", "37400","0001"];
    case "OF07_08_201":
      return ["NEW정성한상/5끼", "0303744", "37400","0001"];
    case "OF07_08_202":
      return ["정성한상PLUS/10끼", "0303744", "74800","0001"];
      case "OF07_14_101":
        return ["케어유 고단백 당뇨영양식 (8개입)", "0304485", "20400","0002"];
      case "OF07_14_102":
        return ["케어유 고단백 당뇨영양식 (24개입)", "0304095", "61200","0002"];
      case "OF07_15_101":
        return ["케어유 고단백 균형영양식 (8개입)", "0304484", "18360","0002"];
      case "OF07_15_102":
        return ["케어유 고단백 균형영양식 (24개입)", "0304094", "55080","0002"];
      case "OF07_16_101":
        return ["그린Meal+ (1주5끼)", "0303676", "46200","0001"];
      case "OF07_16_102":
        return ["그린Meal+ (2주10끼)", "0303676", "92400","0001"];
      default:
        return ["", "", "",""];
  }
}

function formatDateToCustomFormat(dateString: string): string {
  if (!/^\d{8}$/.test(dateString)) {
    // 올바르지 않은 날짜 형식이므로 기본 값을 반환합니다.
    return "";
  }

  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6).padStart(2, "0");
  const day = dateString.substring(6, 8).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const pmoOrder = async (list: Array<IOoUnospayDelivery>, select: OoProvider) => {
  let data_array: Array<ExcelExportModel> = [];
  console.log(list);
  
  const tlcMenuHd = findTlcMenuList(list , exCodeNumToStr(OoProvider.pmoProvider));
  
  const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.pmoProvider));
  //공휴일 적용 안됨
  const today = new Date();
  let minDate: Date;
  const dayOfWeek = today.getDay(); // 0은 일요일, 1은 월요일, ... 6은 토요일
  switch (dayOfWeek) {
    case 1: // 월요일
      minDate = addDays(today, 2);
      break;
    case 2: // 화요일
      minDate = addDays(today, 2);
      break;
    case 3: // 수요일
      minDate = addDays(today, 2);
      break;
    case 4: // 목요일
      minDate = addDays(today, 2);
      break;
    case 5: // 금요일
      minDate = addDays(today, 3);
      break;
    case 6: // 토요일
      minDate = addDays(today, 4);
      break;
    case 0: // 일요일
      minDate = addDays(today, 3);
      break;
    default:
      minDate = addDays(today, 1);
  }

  let menuListIndex = 0;
  let arrayHdOrderList = [];
  if(tlcMenuHd && tlcMenuHd.length > 0){
    arrayHdOrderList = [...excelList, ...tlcMenuHd]
  }else{
    arrayHdOrderList = [...excelList]
  }

  console.log(arrayHdOrderList);
  arrayHdOrderList.forEach((e, i) => {
    
    if (e.status == OoDeliveryStatus.existAddress || e.status == OoDeliveryStatus.sendPackage) {
      const model = new ExcelExportModel();
      model.excelStatus =
      e.status === OoDeliveryStatus.sendPackage ? "sendPackage" : "existAddress";
      e.salesMenu?.forEach((salesItem, i) => {
        if(_.startsWith(salesItem.externalCode, 'OF07')){
          if (salesItem.salesOption) {
            console.log(e.salesMenu![i]);
            
            model.product = salesItem.menuName;
            model.orderDate = convTimestampDateFormat(e.timestamp);
            model.name = e.recipientName;
            model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
            model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
            model.address1 = e.address;
            model.address2 = e.addressDetail;
            model.zipCode = e.zipCode;
            model.memo = e.memo;
            model.subMemo = e.subMemo;
            model.quantity = salesItem.menuCount;
            model.deliveryDate = e.salesMenu![i].selectMenu && e.salesMenu![i].selectMenu!.length > 0  ? formatDateToCustomFormat(String(e.salesMenu![i].selectMenu![0].date)) : ""
            if (salesItem.salesOption.length > 0) {
              salesItem.salesOption.map((optionItem) => {
                if (optionItem.externalCode) {
                  if(_.includes(optionItem.externalCode,'_')){
                    model.exCode = `${optionItem.externalCode}`
                    data_array.push({ ...model });
                  }else{
                    model.exCode = `${salesItem.externalCode}_${optionItem.externalCode}`
                    data_array.push({ ...model });
                  }
                }
              });
            } else {
              if (salesItem.externalCode?.startsWith("OF07")) {
                const [productName, productCode, productPrice] = getProductInfoPmw(
                  salesItem.externalCode as string
                  );
                  model.exCode = salesItem.externalCode;
                  model.productCode = productCode;
                  model.price = productPrice;
                  data_array.push({ ...model });
                }
              }
            } else {
              model.product = salesItem.menuName;
              model.exCode = salesItem.externalCode;
              model.orderDate = convTimestampDateFormat(e.timestamp);
              model.name = e.recipientName;
              model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
              model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
              model.address1 = e.address;
              model.address2 = e.addressDetail;
              model.zipCode = e.zipCode;
              model.memo = e.memo;
              model.subMemo = e.subMemo;
              model.productCode = getProductInfoPmw(salesItem.externalCode as string)[1];
              model.price = salesItem.menuPrice;
              model.quantity = salesItem.menuCount;
              model.deliveryDate = _.isUndefined(e.salesMenu![i].selectMenu![0]) ? "" : formatDateToCustomFormat(String(e.salesMenu![i].selectMenu![0].date));
              data_array.push({ ...model });
            }
          }
        });
    }
  });

  console.log(data_array);
  
  
  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = `tlc헬스케어 발주서`;

  const arrayBufferPmo = await (await fetch("/assets/pmo.xlsx")).arrayBuffer();
  const workbookPmo = new ExcelJS.Workbook();
  await workbookPmo.xlsx.load(arrayBufferPmo);
  const ws_pmo = workbookPmo.getWorksheet("업로드 양식");
  // const ws_pmo = XLSX.utils.aoa_to_sheet([
  //     [
  //     "주문자명", "수령자명", "수령자 우편번호", "수령자 기본주소", "수령자 상세주소", "수령자 핸드폰번호", "수령자 전화번호", "배송요청사항",
  //     "상품총액", "배송비", "할인총액", "결제총액", "결제구분", "주문일자", "상품코드", "증정상품코드", "증정식단연동", "배송타입", "주문수량",
  //     "증정주문수량", "첫배송일", "배송기간(일)", "배송기간(주)", "주말포함", "증정배송기간(일)", "보냉가방 구매여부", "외부몰 주문번호",
  //     "외부몰코드", "체험단여부", "배달유형", "알러지여부"
  //     ],
  // ]);

  // const book = XLSX.utils.book_new();

  if (select == OoProvider.pmoProvider) {
    let custNum = 1;
    let previousOrderDate = "";
    const rowNumber = 2;
    const grayColumns = [11, 13, 16, 17, 20, 24, 25, 26, 28, 29, 31];
    data_array.map((data: ExcelExportModel, index: number) => {
      const row = ws_pmo.getRow(rowNumber + index);

      if (previousOrderDate !== moment(data.orderDate).format("YYYYMMDD")) {
        custNum = 1; // orderDate 값이 바뀌면 custNum을 1로 초기화
        previousOrderDate = moment(data.orderDate).format("YYYYMMDD");
      }
      const dateTimeTemp = `TLC${moment(data.orderDate).format("YYYYMMDD")}${_.padStart(
        custNum.toString(),
        4,
        "0"
      )}`;
      data.custOrderNum = dateTimeTemp;
      custNum++;

      const exCode = _.isEmpty(data.optionCodeList!)
        ? data.exCode
        : `${data.exCode}_${_.first(data.optionCodeList)}`;
      // const exCode = _.isEmpty(data.optionCodeList!) ?
      // data.exCode : `${data.exCode}_${_.first(data.optionCodeList)}`
        
      const deliveryToday = ["OF07_01", "OF07_02", "OF07_14", "OF07_15","OF07_01_201","OF07_01_202","OF07_02_201","OF07_02_202","OF07_14_101","OF07_14_102","OF07_15_101","OF07_15_102"].includes(data.exCode!)
        ? "0"
        : "5";
      const deliveryWeek = ["OF07_01", "OF07_02", "OF07_14", "OF07_15"].includes(data.exCode!)
        ? "0"
        : "1";
      const deliveryPeriod = data.optionCodeList?.some((item) => item === "202") ? "2" : "1";
      [
        data.name,
        data.name,
        data.zipCode,
        data.address1,
        data.address2,
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}`,
        data.tel2 ? `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}` : `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}`,
        getDeliveryMessage(data),
        Number(getProductInfoPmw(data.exCode!)[2]),
        "0",
        "0",
        Number(getProductInfoPmw(data.exCode!)[2]),
        "40",
        data.orderDate!.replaceAll("-", ""),
        getProductInfoPmw(data.exCode!)[1],
        "0",
        "N",
        getProductInfoPmw(data.exCode!)[3],
        data.quantity,
        "0",
        data.deliveryDate?.replace(/-/g, ""),
        deliveryToday,
        delivery(data.exCode as string),
        "N",
        "0",
        "",
        "N",
        data.custOrderNum,
        "70",
        "N",
        "H",
        "N",
      ].forEach((value, index) => {
        const cell = row.getCell(index + 1); // 엑셀은 1부터 시작
        cell.value = value;
        
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
      return false;
    });
  }
  const excelBuffer = await workbookPmo.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFile,
    excelFileName + "_" + moment().format("YYYYMMDD") + "(풀무원)" + excelFileExtension
  );
};
