import {IOoHospital} from "../../api";
import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {ImageUpload} from "../imageUpload";
import {useRecoilValue} from "recoil";
import {editSelectorFamily} from "@recoils/status";
import _, {isEmpty} from "lodash";
import {OoParamHospitalUpdate} from "@api/params";
import {useCardEdit} from "@hooks/common";
import {toast} from "react-toastify";
import {useHospitalFunctionHook} from "@hooks/hospital";

interface Props {
    data: IOoHospital,
    componentName: string,
}

export const HospitalPictures = ({data, componentName}: Props) => {
    const target = 'hospital';
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const {isReset} = useCardEdit(componentName);
    const {updateHospital} = useHospitalFunctionHook();

    const initialData = useMemo(() => {
        return data.pictures;
    }, [data]);

    const [item, setItem] = useState<Array<string>>(initialData);

    console.log(item);
    const onChangedPicture = (target: string, title: string) => {
        console.log(target,'=========>', title);
        try {
            if (!_.isUndefined(item) && _.gt(item.length, 5)) {
                toast.error('사진등록은 5개 까지 가능합니다.');
                return;
            }

            if (isEmpty(target)) {
                const index = _.toNumber(title.charAt(3));
                const copied = _.cloneDeep(item);
                copied.splice(index, 1);
                setItem(copied);
                return;
            }

            const targetNumber = _.toNumber(title.charAt(3));
            if (!item[targetNumber]) {
                const copied = _.cloneDeep(item);
                copied[targetNumber] = target;
                setItem(copied);
                return;
            } else {
                const copied = _.cloneDeep(item);
                copied[targetNumber] = target;
                setItem(copied);
                return;
            }
        } catch (e) {
            console.log(e, 'onChangedPicture error!');
        }
    };

    const onRemovedPicture = (e: any) => {
        e.preventDefault();
        const {id} = e.target;
        console.log(id);
        const index = _.toNumber(id.charAt(3));
        const copied = _.cloneDeep(item);
        copied.splice(index, 1);
        setItem(copied);
    };

    useEffect(() => {
        isReset();
    }, []);

    // 전송 모드 일때, 쿼리 trigger
    useEffect(() => {
        if (!editStatus.isSubmit) return;
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamHospitalUpdate();
            param.id = data.id || "";
            param.pictures = _.compact(item);
            updateHospital(param);
        }
    }, [editStatus.isSubmit]);

    useEffect(() => {
        editStatus.isCancel && setItem(initialData);
    }, [editStatus.isCancel]);

    useEffect(() => {
        setItem(initialData);
    }, [data]);

    return (
        <Wrapper>
            <ImageUpload target={target}
                         title="img0"
                         onChanged={onChangedPicture}
                         picturesSrc={item[0]}
                         editStatus={editStatus}
                         onRemoved={onRemovedPicture}
            />
            <ImageUpload target={target}
                         title="img1"
                         onChanged={onChangedPicture}
                         picturesSrc={item[1]}
                         editStatus={editStatus}
                         onRemoved={onRemovedPicture}
            />
            <ImageUpload target={target}
                         title="img2"
                         onChanged={onChangedPicture}
                         picturesSrc={item[2]}
                         editStatus={editStatus}
                         onRemoved={onRemovedPicture}
            />
            <ImageUpload target={target}
                         title="img3"
                         onChanged={onChangedPicture}
                         picturesSrc={item[3]}
                         editStatus={editStatus}
                         onRemoved={onRemovedPicture}
            />
            <ImageUpload target={target}
                         title="img4"
                         onChanged={onChangedPicture}
                         picturesSrc={item[4]}
                         editStatus={editStatus}
                         onRemoved={onRemovedPicture}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
  display: flex;
`;
