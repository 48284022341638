import _ from "lodash";
import React, {useEffect} from "react";
import classNames from "classnames/bind";
import SelectStyles from "../../../../styles/common/Select.module.scss";
import timeTableStyle from "../../../../styles/common/TimeTable.module.scss";
import {ReservationDiagnosisType, useReservationScheduleSettingHook} from "@components/infoForm";
import {IOoDoctor} from "@models/doctor";
import {OoParamDoctorScheduleCreate} from "@params/reservation/doctorSchduleCreate";
import {CustomCheckBox, CustomInputText} from "@components/custom";
import styled from "styled-components";
import {TimeTable} from "@components/timeTable";

interface Props {
    doctorInfo: IOoDoctor,
    componentName: string,
}

export interface ParamProps {
    doctorId: string;
    hospitalId: string;
    wapleReservationType: Array<number>;
    startTimeArr: Array<string>;
    endTimeArr: Array<string>;
    isOffArr: Array<string>;
    lunchStartTime: string;
    lunchEndTime: string;
    slotUnit: number;
    enableCnt: number;
}

export const ReservationSchedule = ({doctorInfo, componentName}: Props) => {
    const tx = classNames.bind(timeTableStyle);
    const sx = classNames.bind(SelectStyles);

    const {
        editStatus,
        reservationEnabled,
        createParam,
        data,
        hourData,
        setHourData,
        hospitalHourEnabled,
        onTimeChanged,
        onLunchTimeChanged,
        handleEqualHospitalTime,
        onChangeSlotUnit,
        onChangeEnableCnt,
        onReservationEnabled,
        setReservationDoctor,
        handleReservationDiagnosisType,
        sortedDoctorHoursList,
        sortedReservationHoursList,
        setQueryTrigger
    } = useReservationScheduleSettingHook(doctorInfo, componentName);

    useEffect(() => {
        if (!_.isUndefined(sortedDoctorHoursList) && hospitalHourEnabled) {
            setHourData({
                sortedHoursList: sortedDoctorHoursList.combinedDoctorHours,
                sortedLunchHoursList: sortedDoctorHoursList.lunchTime
            });
            return;
        }
        if (!_.isUndefined(sortedReservationHoursList)) {
            setHourData({
                sortedHoursList: sortedReservationHoursList.sortedReservationHourList,
                sortedLunchHoursList: sortedReservationHoursList.combinedLunchTime
            });
        }
    }, [sortedReservationHoursList, sortedDoctorHoursList, hospitalHourEnabled]);

    useEffect(() => {
        if (editStatus.isSubmit) {
            const params = new OoParamDoctorScheduleCreate();
            params.doctorId = createParam.doctorId;
            params.hospitalId = createParam.hospitalId;
            params.wapleReservationType = createParam.wapleReservationType;
            params.enableCnt = createParam.enableCnt;
            params.isOffArr = createParam.isOffArr;
            params.lunchStartTime = createParam.lunchStartTime;
            params.lunchEndTime = createParam.lunchEndTime;
            params.slotUnit = createParam.slotUnit;
            params.startTimeArr = createParam.startTimeArr;
            params.endTimeArr = createParam.endTimeArr;
            params.isUseReservation = reservationEnabled;
            setReservationDoctor(params);
            setQueryTrigger(true);
        }
    }, [editStatus.isSubmit]);

    return (
        <div>
            {
                !_.isEmpty(data)
                &&
                <>
                    <TopContainer>
                        <div>
                            <CheckBoxContainer>
                                <label>
                                    <CustomCheckBox title="예약 기능 노출"
                                                    disabled={!editStatus.isEdit}
                                                    checked={reservationEnabled}
                                                    onChange={onReservationEnabled}/>
                                </label>
                                <label>
                                    <CustomCheckBox title="병원 운영시간과 동일"
                                                    disabled={!editStatus.isEdit}
                                                    checked={hospitalHourEnabled}
                                                    onChange={handleEqualHospitalTime}/>
                                </label>
                            </CheckBoxContainer>
                            <Table>
                                <thead></thead>
                                <tbody>
                                <tr>
                                    <td>예약 슬롯 단위</td>
                                    <td>
                                        {
                                            editStatus.isEdit ?
                                                <select className={sx('select', 'x-middle', 'blue')}
                                                        onChange={onChangeSlotUnit}
                                                        value={`${createParam.slotUnit}`}>
                                                    <option value="10">10분</option>
                                                    <option value="30">30분</option>
                                                    <option value="60">60분</option>
                                                </select>
                                                :
                                                <CustomInputText size="xMiddle"
                                                                 value={`${data.slotUnit}분`}
                                                                 disabled={!editStatus.isEdit}/>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>예약 가능 인원</td>
                                    <td>
                                        {
                                            editStatus.isEdit
                                                ?
                                                <select className={sx('select', 'x-middle', 'blue')}
                                                        onChange={onChangeEnableCnt}
                                                        defaultValue={`${createParam.enableCnt}`}>
                                                    <option value="1">1명</option>
                                                    <option value="2">2명</option>
                                                    <option value="3">3명</option>
                                                    <option value="4">4명</option>
                                                    <option value="5">5명</option>
                                                    <option value="6">6명</option>
                                                    <option value="7">7명</option>
                                                    <option value="8">8명</option>
                                                    <option value="9">9명</option>
                                                    <option value="10">10명</option>
                                                </select>
                                                :
                                                <CustomInputText size="xMiddle"
                                                                 value={`${data?.enableCnt}명`}
                                                                 disabled={!editStatus.isEdit}/>
                                        }
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                        <ReservationDiagnosisType doctorInfo={doctorInfo}
                                                  componentName={componentName}
                                                  isCancel={editStatus.isCancel}
                                                  onChange={handleReservationDiagnosisType}/>
                    </TopContainer>
                    <TableContainer>
                        <div>
                            <div className={tx('time-table-head')}>
                                <div>요일</div>
                                <div>진료시작</div>
                                <div>진료종료</div>
                                <div>휴무일 여부</div>
                            </div>
                            <div>
                                <TimeTable hourList={hourData.sortedHoursList}
                                           lunchHourList={hourData.sortedLunchHoursList}
                                           editStatus={editStatus}
                                           reservationEnabled={reservationEnabled}
                                           onLunchTimeChanged={onLunchTimeChanged}
                                           onTimeChanged={onTimeChanged}
                                />
                            </div>
                        </div>
                    </TableContainer>
                </>}
        </div>

    )
}

const TopContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;

  & > div:nth-child(1) {
    margin-left: 24px;
    width: 500px;
  }
`;


const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 700;
  color: #000000;

  & > label {
    width: 50%;
    display: flex;
    align-items: center;

    & > input[type=checkbox] {
      margin-right: 19px;
    }
  }
`;

const Table = styled.table`
  width: fit-content;
  height: 96px;

  & > tbody {
    & > tr:nth-child(1) {
      border-top: 2px solid #C8D6F8;
      border-bottom: 1px solid #C8D6F8;
    }

    & > tr:nth-child(2) {
      border-bottom: 1px solid #C8D6F8;
    }

    & > tr td:nth-child(2n-1) {
      width: 100px;
      height: 100%;
      background: #F4F6FB;
      font-weight: 700;
      font-size: 13px;
      color: #000000;
      text-align: center;
    }

    & > tr td:nth-child(2n) {
      & > input[type=text] {
        margin-left: 8px;
        margin-top: 4px;
        margin-bottom: 4px;
      }

      & > select {
        margin-left: 8px;
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 24px;

  & > div {
    width: 976px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
`;