import React from "react";
import styled from "styled-components";
import { Navbar } from "../Header/Navbar";
import { useNavbarHooks } from "../Header/Navbar/hooks/useNavbarHooks";

interface Props {
  children: JSX.Element;
}

interface FontSize {
  size: number;
}

export const CalculateHeaderLayout = ({ children }: Props) => {
  const { cx, oauthList, selectedUser, navigate, onClickLogout, GetUserList } = useNavbarHooks();

  return (
    <Wrapper>
      <Navbar />
      <Layout>
        <Header>
          <Title size={30}>{selectedUser?.user.name}</Title>
          <LogoutButton
            type="button"
            value="로그아웃"
            onClick={onClickLogout}
            className="nav-logout-button"
          />
        </Header>
        <BodyLayout>
          {children}
        </BodyLayout>
      </Layout>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #edf2f7;
  display: flex;
  flex-direction: row;
`;

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
  row-gap: 20px;
  overflow: hidden;
`
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BodyLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.span<FontSize>`
  font-size: ${(props) => props.size}px;
  font-weight: bold;
`;

const LogoutButton = styled.input`
  background-color: #ffffff;
  border: 1px solid #d1d7dd;
  width: 100px;
  height: 35px;
  border-radius: 10px;

  &:hover,
  &:focus {
    color: #28344d;
    font-weight: bold;
  }
`;