import {useState} from "react";
import {PharmacyAlimTalkInfo} from "../../../../interfaces";

export const useAlimTalkHook = () => {
    const [item, setItem] = useState<PharmacyAlimTalkInfo>({
        alimTalkReceivers: [],
    });

    const onChangeValue = (e: any) => {
        const {name, value} = e.target;
        setItem({...item, [name]: [value]});
    }

    return {
        item,
        onChangeValue
    }
}