import { ModalOverlay } from "../../modal";
import Calendar from "react-calendar";
import moment from "moment";
import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import classNames from "classnames/bind";
import buttonStyles from "@styles/common/Button.module.scss";
import { OrderPaymentCalendarProps } from "pages/Operator/UnospayDashBoardPage/components/PaymentCalendar";

interface Props extends OrderPaymentCalendarProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onChangeDate: (value: Array<Date>) => void;
  value: [Date | null, Date | null];
  onClickDate: (value: Date, e: any) => void;
  onShowCalendar: (e: any) => void;
  selectedStartDay: string;
  selectedEndDay: string;
  handleTodayDate: (e: any) => void;
}

export const CustomCalendar = ({
  type,
  setSearchDate,
  visible,
  setVisible,
  onChangeDate,
  value,
  onClickDate,
  onShowCalendar,
  selectedStartDay,
  selectedEndDay,
  handleTodayDate,
  dobuleDate,
  setDobuleDate
}: Props) => {
  const bx = classNames.bind(buttonStyles);
  const [orderDate, setOrderDate] = useState<Date>();

  const handleClickOrderDay = (date: Date) => {
    setOrderDate(date);
    if (setSearchDate) {
      setSearchDate(moment(date).format("YYYY-MM-DD"));
      setVisible(false);
    }
  };

  const onChageDoubleDate = (e:Date[]) => {
    if(setDobuleDate){
      setDobuleDate([moment(e[0]).format("YYYY.MM.DD"),moment(e[1]).format("YYYY.MM.DD")])
      setVisible(false)
    }
  }
  

  return (
    <>
      {type === "order" ? (
        <div>
          <ModalOverlay visible={visible}>
            <CalendarWrapper>
              <div>
                <Calendar
                  value={orderDate}
                  minDetail="year"
                  formatDay={(locale, date) => moment(date).format("D")}
                  prev2Label={null}
                  next2Label={null}
                  onClickDay={handleClickOrderDay}
                  selectRange={false}
                  allowPartialRange={false}
                  goToRangeStartOnSelect={true}
                  returnValue="range"
                  tileContent={({ date, view }) =>
                    view === "month" && date.toDateString() === new Date().toDateString() ? (
                      <label>today</label>
                    ) : null
                  }
                />
              </div>
              <div>
                <button type="button" className={bx("button")} onClick={() => setVisible(false)}>
                  닫기
                </button>
              </div>
            </CalendarWrapper>
          </ModalOverlay>
          <CalendarButton type="button" onClick={onShowCalendar}>
            <div>
              <img src={process.env.PUBLIC_URL + `/img/calendar-icon.png`} alt="calendar" />
              {moment(orderDate).format("YYYY.MM.DD")}
            </div>
          </CalendarButton>
        </div>
      ) : type ==='doubleCandler' ? (<div>
        <ModalOverlay visible={visible}>
          <CalendarWrapper>
            <div>
              <Calendar selectRange={true} onChange={onChageDoubleDate}/>
            </div>
            <div>
                <button type="button" className={bx("button")} onClick={handleTodayDate}>
                  오늘로 이동
                </button>
                <button type="button" className={bx("button")} onClick={() => setVisible(false)}>
                  닫기
                </button>
              </div>
          </CalendarWrapper>
        </ModalOverlay>
          <CalendarButton type="button" onClick={onShowCalendar}>
            <div>
              <img src={process.env.PUBLIC_URL + `/img/calendar-icon.png`} alt="calendar" />
              {dobuleDate && dobuleDate[0]}
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + `/img/forward-icon.png`} alt="calendar" />
            </div>
            <div> {dobuleDate && dobuleDate[1]}</div>
          </CalendarButton>
      </div>) : (
        <div>
          <ModalOverlay visible={visible}>
            <CalendarWrapper>
              <div>
                <Calendar
                  onChange={onChangeDate}
                  value={value}
                  minDetail="year"
                  formatDay={(locale, date) => moment(date).format("D")}
                  prev2Label={null}
                  next2Label={null}
                  onClickDay={onClickDate}
                  selectRange={true}
                  allowPartialRange={true}
                  goToRangeStartOnSelect={true}
                  returnValue="range"
                  tileContent={({ date, view }) =>
                    view === "month" && date.toDateString() === new Date().toDateString() ? (
                      <label>today</label>
                    ) : null
                  }
                />
              </div>
              <div>
                <button type="button" className={bx("button")} onClick={handleTodayDate}>
                  오늘로 이동
                </button>
                <button type="button" className={bx("button")} onClick={() => setVisible(false)}>
                  닫기
                </button>
              </div>
            </CalendarWrapper>
          </ModalOverlay>
          <CalendarButton type="button" onClick={onShowCalendar}>
            <div>
              <img src={process.env.PUBLIC_URL + `/img/calendar-icon.png`} alt="calendar" />
              {selectedStartDay}
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + `/img/forward-icon.png`} alt="calendar" />
            </div>
            <div>{selectedEndDay}</div>
          </CalendarButton>
        </div>
      )}
    </>
  );
};

const CalendarWrapper = styled.div`
  display: flex;
  background: #fff;
  width: 630px;
  height: auto;
  padding: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  flex-direction: column;
  z-index: 999;
  box-sizing: border-box;

  & > div:nth-child(1) {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;

    /*react-calendar custom*/

    .react-calendar {
      width: 100%;
      border: none;
      height: auto;
    }

    .react-calendar__viewContainer div {
      height: 100%;

      & > abbr[title] {
        text-decoration: none;
      }
    }

    .react-calendar__month-view__days {
    }

    .react-calendar__navigation {
      height: 40px;
      font-size: 15px;
      font-weight: 800;
    }

    .react-calendar__navigation button {
      min-width: 100px;
      font-size: 15px;
      font-weight: 800;
    }

    .react-calendar__tile {
      background: none;
      text-align: center;
      line-height: 16px;
      font-size: 13px;
      font-weight: 800;
      padding: 1px 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 70px;
    }

    .react-calendar__tile:enabled:focus,
    .react-calendar__tile:enabled:hover {
      border-radius: 50%;
      background: #86b7fe;
      color: white;
    }

    .react-calendar__tile:disabled {
      color: #f0f0f0;
      background: none;
    }

    .react-calendar__tile--now {
      border-radius: 50%;
      color: black;
      background: none;
    }

    .react-calendar__tile--hasActive {
      background: none;
      color: cornflowerblue;
    }

    .react-calendar__tile--active {
      background: #2176f6;
      border-radius: 6px;
      font-weight: bold;
      color: white;
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      border-radius: 50%;
      color: white;
      background: #86b7fe;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background: none;
    }

    .react-calendar__navigation button:disabled {
      color: #f0f0f0;
      background: none;
    }

    .react-calendar__tile--range {
      background: #f8f8fa;
      color: #2176f6;
      border-radius: 50%;
    }

    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: #2176f6;
      color: white;
      border-radius: 50%;
    }

    .react-calendar__tile--rangeStart {
      background: #2176f6;
      color: white;
      border-radius: 50%;
    }

    .react-calendar__tile--rangeEnd {
      background: #2176f6;
      color: white;
      border-radius: 50%;
    }
  }

  & > div:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }
`;

const CalendarButton = styled.button`
  width: 268px;
  height: 36px;
  border-radius: 8px;
  border: 0.5px solid #c2c1bd;
  background: #ffffff;
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    & > img {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 20px;
      height: 20px;
    }
  }
`;
