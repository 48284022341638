import {atom} from "recoil";
import {IOoUnospayDelivery} from "@models/unospay";

export interface SearchProps {
    userName?: string,
    list: Array<IOoUnospayDelivery>,
}

export const unospaySearchByNameState = atom<SearchProps>({
    key: 'unospaySearchByNameState',
    default: {
        userName: '',
        list: [],
    }
});
