import styled, { CSSProperties } from "styled-components";

interface Props {
  title: string;
  iconSrc: string;
  onClick: () => void;
  style?: CSSProperties; // style 타입을 CSSProperties로 변경
}

// export const FilterResetButton = ({ title, iconSrc, onClick, style }: Props) => {
export const IconButton = ({ title, iconSrc, onClick, style }: Props) => {
  return (
    <FilterResetThemeButton onClick={onClick} style={style}>
      <ButtonIcon src={iconSrc} alt={title} />
      {title}
    </FilterResetThemeButton>
  );
};
const FilterResetThemeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  font-weight: 700;
  font-size: 13px;
  color: black;
  border-radius: 8px;
  border: 0.5px solid #c2c1bd;
  background: white;
  padding: 4px 12px 4px 13px;
  margin-right: 20px;
`;

const ButtonIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 5px;
`;
