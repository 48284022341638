import {useRecoilValue} from "recoil";
import {useMemo, useState} from "react";
import {IOoDoctor} from "@models/doctor";
import {editSelectorFamily} from "@recoils/status";
import {useCardEdit} from "@hooks/common";
import {IOoHospital} from "@api/models";
import {IOoPharmacy} from "@models/pharmacy";
import {useDoctorFunctionHook} from "@hooks/doctor";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";
import {useHospitalFunctionHook} from "@hooks/hospital";

export const useBankInfoSettingHook = (data: IOoDoctor | IOoHospital | IOoPharmacy, componentName: string) => {
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const {isReset} = useCardEdit(componentName);

    const {updateDoctor} = useDoctorFunctionHook();
    const {updatePharmacy} = usePharmacyFunctionHook();
    const {updateHospital} = useHospitalFunctionHook();

    const [color, setColor] = useState("white");

    const initialData = useMemo(() => {
        return {
            bank: data.bank || "-",
            bankAccount: data.bankAccount || "-",
            bankHolderName: data.bankHolderName || "-",
        }
    }, [data]);

    const [items, setItems] = useState(initialData);

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setItems({...items, [name]: value});
    }
    return {
        editStatus,
        color,
        setColor,
        items,
        setItems,
        handleChange,
        isReset,
        initialData,
        updateDoctor,
        updatePharmacy,
        updateHospital,

    }
}