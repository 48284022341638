import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  IOoUnospayDelivery,
  IOoUnospaySalesMenu,
  IOoUnospaySalesMenuOption,
  OoDeliveryStatus,
  OoOrderPlatformType,
  OoProvider,
} from "@api/models";
import { ExcelExportModel } from "../model";
import _ from "lodash";
import styled from "styled-components";
import { formatPrice, formatTel } from "@hooks/format";
import moment from "moment";
import React from "react";

const ExcelJS = require("exceljs");

interface Props {
  list: Array<IOoUnospayDelivery>;
  select: OoProvider;
}

const initialWeaning = [
  { code: "B-001-01", name: "한우단호박당근죽", cost: "2680" },
  { code: "B-005-01", name: "한우무죽", cost: "2680" },
  { code: "B-014-01", name: "콜리플라워닭죽", cost: "2300" },
  { code: "B-015-01", name: "고구마닭죽", cost: "2300" },
  { code: "B-018-01", name: "찹쌀비타민닭죽", cost: "2300" },
  { code: "B-024-01", name: "사과타락죽", cost: "2130" },
];
const middleWeaning = [
  [
    { code: "C-001-01", name: "한우가지로메인죽", cost: "3000" },
    { code: "C-026-01", name: "브로콜리양송이감자죽", cost: "2350" },
    { code: "C-002-01", name: "한우근대감자죽", cost: "3000" },
    { code: "C-024-01", name: "단호박고구마죽", cost: "2350" },
    { code: "C-028-01", name: "차조단호박시금치죽", cost: "2350" },
    { code: "C-014-01", name: "애호박미역닭죽", cost: "2570" },
  ],
  [
    { code: "C-029-01", name: "한우오트밀배죽", cost: "3000" },
    { code: "C-016-01", name: "배추닭살죽", cost: "2570" },
    { code: "C-036-01", name: "한우오트밀고구마죽", cost: "3000" },
    { code: "C-021-01", name: "수수버섯죽", cost: "2350" },
    { code: "C-031-01", name: "한우양배추죽", cost: "3000" },
    { code: "C-032-01", name: "닭고기시금치죽", cost: "2570" },
  ],
];
const lateWeaning = [
  [
    { code: "D-010-01", name: "한우가지연두부죽", cost: "3110" },
    { code: "D-066-01", name: "치즈감자닭죽", cost: "2680" },
    { code: "D-070-01", name: "과일단호박죽", cost: "2460" },
    { code: "D-006-01", name: "한우오이양배추죽", cost: "3110" },
    { code: "D-059-01", name: "구기자야채닭죽", cost: "2680" },
    { code: "D-057-01", name: "감자오트밀죽", cost: "2460" },
    { code: "D-001-01", name: "한우병아리콩죽", cost: "3110" },
    { code: "D-022-01", name: "단호박시금치닭죽", cost: "2680" },
    { code: "D-055-01", name: "치즈사과단호박죽", cost: "2460" },
  ],
  [
    { code: "D-050-01", name: "한우무미역죽", cost: "3110" },
    { code: "D-060-01", name: "치킨두부죽", cost: "2680" },
    { code: "D-028-01", name: "병아리콩양송이버섯죽", cost: "2460" },
    { code: "D-014-01", name: "한우표고당근죽", cost: "3110" },
    { code: "D-043-01", name: "누룽지닭죽", cost: "2680" },
    { code: "D-037-01", name: "시금치콩나물죽", cost: "2460" },
    { code: "D-013-01", name: "한우백만송이비타민죽", cost: "3110" },
    { code: "D-036-01", name: "닭녹두버섯죽", cost: "2680" },
    { code: "D-041-01", name: "대구쑥갓죽", cost: "2680" },
  ],
];
const completeWeaning = [
  [
    { code: "E-050-01", name: "한우사골야채진밥", cost: "3220" },
    { code: "E-037-01", name: "닭가슴살두부진밥", cost: "2840" },
    { code: "E-051-01", name: "돈안심콩나물진밥", cost: "2840" },
    { code: "E-065-01", name: "한우오곡영양진밥", cost: "3220" },
    { code: "E-025-01", name: "시금치고구마닭진밥", cost: "2840" },
    { code: "E-068-01", name: "달콤과일진밥", cost: "2680" },
    { code: "E-042-01", name: "한우무진밥", cost: "3220" },
    { code: "E-023-01", name: "고구마삼색진밥", cost: "2680" },
    { code: "E-013-01", name: "치킨카레진밥", cost: "2840" },
  ],
  [
    { code: "E-046-01", name: "한우두부미역진밥", cost: "3220" },
    { code: "E-044-01", name: "에그치킨리조또", cost: "2840" },
    { code: "E-053-01", name: "단호박사과진밥", cost: "2680" },
    { code: "E-002-01", name: "비프스튜진밥", cost: "3220" },
    { code: "E-036-01", name: "닭가슴살녹두진밥", cost: "2840" },
    { code: "E-062-01", name: "미트토마토치즈진밥", cost: "2840" },
    { code: "E-012-01", name: "한우파프리카진밥", cost: "3220" },
    { code: "E-041-01", name: "크림버섯리조또", cost: "2680" },
    { code: "E-015-01", name: "게살진밥", cost: "2840" },
  ],
];

const prepMeal_13M = [
  [
    { code: "E-050-01", name: "한우사골야채진밥", cost: "3220" },
    { code: "E-062-01", name: "미트토마토치즈진밥", cost: "2840" },
    { code: "F-019-51", name: "닭볶음탕", cost: "3000" },
    { code: "F-041-01", name: "한우미역국", cost: "3000" },
    { code: "E-012-01", name: "한우파프리카진밥", cost: "3220" },
    { code: "E-036-01", name: "닭가슴살녹두진밥", cost: "2840" },
    { code: "F-039-51", name: "순두부프리타타", cost: "2680" },
    { code: "F-002-01", name: "설렁탕", cost: "3000" },
    { code: "E-065-01", name: "한우오곡영양진밥", cost: "3220" },
    { code: "E-015-01", name: "게살진밥", cost: "2840" },
    { code: "F-109-51", name: "라구스파게티", cost: "3000" },
    { code: "F-043-01", name: "닭고기무국", cost: "2680" },
  ],
  [
    { code: "E-046-01", name: "한우두부미역진밥", cost: "3220" },
    { code: "E-044-01", name: "에그치킨리조또", cost: "2840" },
    { code: "F-012-51", name: "배추들깨볶음", cost: "2680" },
    { code: "F-001-01", name: "닭곰탕", cost: "2680" },
    { code: "E-042-01", name: "한우무진밥", cost: "3220" },
    { code: "E-051-01", name: "돈안심콩나물진밥", cost: "2840" },
    { code: "F-063-51", name: "치즈닭갈비", cost: "3000" },
    { code: "F-003-01", name: "감자애호박된장국", cost: "2460" },
    { code: "E-002-01", name: "비프스튜진밥", cost: "3220" },
    { code: "E-037-01", name: "닭가슴살두부진밥", cost: "2840" },
    { code: "F-064-51", name: "돈안심숙주볶음", cost: "3000" },
    { code: "F-053-01", name: "한우곰탕", cost: "3000" },
  ],
];
const twoDishesOneSoup_14M = [
  [
    { code: "F-006-51", name: "한우버섯불고기", cost: "3440" },
    { code: "F-026-51", name: "순한마파두부", cost: "2680" },
    { code: "F-043-01", name: "닭고기무국", cost: "2680" },
    { code: "F-109-51", name: "라구스파게티", cost: "3000" },
    { code: "F-019-51", name: "닭볶음탕", cost: "3000" },
    { code: "F-041-01", name: "한우미역국", cost: "3000" },
    { code: "F-025-51", name: "한우단호박영양찜", cost: "3220" },
    { code: "F-012-51", name: "배추들깨볶음", cost: "2680" },
    { code: "F-003-01", name: "감자애호박된장국", cost: "2460" },
  ],
  [
    { code: "F-064-51", name: "돈안심숙주볶음", cost: "3000" },
    { code: "F-063-51", name: "치즈닭갈비", cost: "3000" },
    { code: "F-053-01", name: "한우곰탕", cost: "3000" },
    { code: "F-018-51", name: "한우알밤조림", cost: "3440" },
    { code: "F-114-51", name: "투움바파스타", cost: "3000" },
    { code: "F-001-01", name: "닭곰탕", cost: "2680" },
    { code: "F-030-51", name: "간장돈불고기", cost: "3000" },
    { code: "F-039-51", name: "순두부프리타타", cost: "2680" },
    { code: "F-002-01", name: "설렁탕", cost: "3000" },
  ],
];
const twoDishesOneSoupOrSauce_14M = [
  [
    { code: "F-006-51", name: "한우버섯불고기", cost: "3440" },
    { code: "F-039-51", name: "순두부프리타타", cost: "2680" },
    { code: "G-112-51", name: "단호박미트카레덮밥소스", cost: "3000" },
    { code: "F-109-51", name: "라구스파게티", cost: "3000" },
    { code: "F-019-51", name: "닭볶음탕", cost: "3000" },
    { code: "F-041-01", name: "한우미역국", cost: "3000" },
    { code: "F-064-51", name: "돈안심숙주볶음", cost: "3000" },
    { code: "F-012-51", name: "배추들깨볶음", cost: "2680" },
    { code: "G-106-51", name: "한우샤브덮밥소스", cost: "3220" },
  ],
  [
    { code: "F-025-51", name: "한우단호박영양찜", cost: "3220" },
    { code: "F-063-51", name: "치즈닭갈비", cost: "3000" },
    { code: "F-003-01", name: "감자애호박된장국", cost: "2460" },
    { code: "F-018-51", name: "한우알밤조림", cost: "3440" },
    { code: "F-026-51", name: "순한마파두부", cost: "2680" },
    { code: "G-108-51", name: "치킨데리야끼덮밥소스", cost: "3000" },
    { code: "F-030-51", name: "간장돈불고기", cost: "3000" },
    { code: "F-114-51", name: "투움바파스타", cost: "3000" },
    { code: "F-053-01", name: "한우곰탕", cost: "3000" },
  ],
];
const simpleSet_14M = [
  [
    { code: "G-003-01", name: "한우불고기비빔밥", cost: "3220" },
    { code: "G-114-51", name: "돼지고기가지덮밥소스", cost: "3000" },
    { code: "F-043-01", name: "닭고기무국", cost: "2680" },
    { code: "G-007-01", name: "닭고기찹쌀영양밥", cost: "2900" },
    { code: "G-118-51", name: "해물짜장덮밥소스", cost: "3000" },
    { code: "F-053-01", name: "한우곰탕", cost: "3000" },
    { code: "G-005-01", name: "치즈새우계란밥", cost: "2900" },
    { code: "G-106-51", name: "한우샤브덮밥소스", cost: "3220" },
    { code: "F-001-01", name: "닭곰탕", cost: "2680" },
  ],
  [
    { code: "G-008-01", name: "닭모듬버섯잡채밥", cost: "2900" },
    { code: "G-112-51", name: "단호박미트카레덮밥소스", cost: "3000" },
    { code: "F-002-01", name: "설렁탕", cost: "3000" },
    { code: "G-006-01", name: "한우치즈야채밥", cost: "3220" },
    { code: "G-124-51", name: "오므라이스덮밥소스", cost: "2680" },
    { code: "F-003-01", name: "감자애호박된장국", cost: "2460" },
    { code: "G-004-01", name: "돈안심짜장밥", cost: "2900" },
    { code: "G-108-51", name: "치킨데리야끼덮밥소스", cost: "3000" },
    { code: "F-041-01", name: "한우미역국", cost: "3000" },
  ],
];
const ambientMiddleWeaning = [
  { code: "SSB55130018", name: "한우과일죽", cost: "3070" },
  { code: "SSB55130019", name: "닭고기애호박미역죽", cost: "2740" },
];
const ambientLateWeaning = [
  { code: "SSB55130014", name: "한우참깨애호박죽", cost: "3210" },
  { code: "SSB55130015", name: "닭고기버섯죽", cost: "2940" },
  { code: "SSB55130022", name: "한우뿌리채소죽", cost: "3210" },
  { code: "SSB55130023", name: "찹쌀누룽지닭죽", cost: "2940" },
];
const ambientCompleteWeaning = [
  { code: "SSB55130016", name: "한우야채진밥", cost: "3320" },
  { code: "SSB55130017", name: "닭고기양송이진밥", cost: "3050" },
  { code: "SSB55130020", name: "한우불고기진밥", cost: "3320" },
  { code: "SSB55130021", name: "닭고기알밤진밥", cost: "3050" },
];
const ambientKids = [
  { code: "SSB55120054", name: "본죽키즈 한우야채죽", cost: "3220" },
  { code: "SSB55120052", name: "본죽키즈 영양닭죽", cost: "3000" },
  { code: "SSB55120051", name: "본죽키즈 모둠야채죽", cost: "3000" },
  { code: "SSB55120053", name: "본죽키즈 튼튼전복죽", cost: "3220" },
];

const gastrectomyLatter = [
  [
    { code: "D-014-01", name: "한우표고당근죽", cost: "3110" },
    { code: "D-060-01", name: "치킨두부죽", cost: "2680" },
    { code: "D-063-01", name: "흰살생선시금치죽", cost: "2680" },
    { code: "D-006-01", name: "한우오이양배추죽", cost: "3110" },
    { code: "D-066-01", name: "치즈감자닭죽", cost: "2680" },
    { code: "D-010-01", name: "한우가지연두부죽", cost: "3110" },
    { code: "D-001-01", name: "한우병아리콩죽", cost: "3110" },
    { code: "D-036-01", name: "닭녹두버섯죽", cost: "2680" },
    { code: "D-059-01", name: "구기자야채닭죽", cost: "2680" },
  ],
  [
    { code: "D-050-01", name: "한우무미역죽", cost: "3110" },
    { code: "D-041-01", name: "대구쑥갓죽", cost: "2680" },
    { code: "D-014-01", name: "한우표고당근죽", cost: "3110" },
    { code: "D-010-01", name: "한우가지연두부죽", cost: "3110" },
    { code: "D-043-01", name: "누룽지닭죽", cost: "2680" },
    { code: "D-028-01", name: "병아리콩양송이버섯죽", cost: "2460" },
    { code: "D-013-01", name: "한우백만송이비타민죽", cost: "3110" },
    { code: "D-059-01", name: "구기자야채닭죽", cost: "2680" },
    { code: "D-063-01", name: "흰살생선시금치죽", cost: "2680" },
    { code: "D-011-01", name: "한우미역깨죽", cost: "3110" },
    { code: "D-022-01", name: "단호박시금치닭죽", cost: "2680" },
    { code: "D-037-01", name: "시금치콩나물죽", cost: "2460" },
  ],
];
const gastrectomyFinal = [
  [
    { code: "E-046-01", name: "한우두부미역진밥", cost: "3220" },
    { code: "E-051-01", name: "돈안심콩나물진밥", cost: "2840" },
    { code: "E-050-01", name: "한우사골야채진밥", cost: "3220" },
    { code: "E-042-01", name: "한우무진밥", cost: "3220" },
    { code: "E-025-01", name: "시금치고구마닭진밥", cost: "2840" },
    { code: "E-021-02", name: "대구살병아리콩진밥", cost: "2840" },
    { code: "E-012-01", name: "한우파프리카진밥", cost: "3220" },
    { code: "E-044-01", name: "에그치킨리조또", cost: "2840" },
    { code: "E-053-01", name: "단호박사과진밥", cost: "2680" },
  ],
  [
    { code: "E-028-01", name: "한우잡채진밥", cost: "3440" },
    { code: "E-062-01", name: "미트토마토치즈진밥", cost: "2840" },
    { code: "E-023-01", name: "고구마삼색진밥", cost: "2680" },
    { code: "E-046-01", name: "한우두부미역진밥", cost: "3220" },
    { code: "E-036-01", name: "닭가슴살녹두진밥", cost: "2840" },
    { code: "E-015-01", name: "게살진밥", cost: "2840" },
    { code: "E-050-01", name: "한우사골야채진밥", cost: "3220" },
    { code: "E-037-01", name: "닭가슴살두부진밥", cost: "2840" },
    { code: "E-041-01", name: "크림버섯리조또", cost: "2680" },
    { code: "E-002-01", name: "비프스튜진밥", cost: "3220" },
    { code: "E-013-01", name: "치킨카레진밥", cost: "2840" },
    { code: "E-042-01", name: "한우무진밥", cost: "3220" },
  ],
];

function addDays(date: Date, days: number) {
  const clone = new Date(date);
  clone.setDate(date.getDate() + days);
  return clone;
}

function getDeliveryCount(option: IOoUnospaySalesMenu) {
  let retCount = 1;
  if (
    _.isEqual(option.externalCode, "OF04_01") ||
    _.isEqual(option.externalCode, "OF04_11") ||
    _.isEqual(option.externalCode, "OF04_02") ||
    _.isEqual(option.externalCode, "OF04_03") ||
    _.isEqual(option.externalCode, "OF04_04") ||
    _.isEqual(option.externalCode, "OF04_05") ||
    _.isEqual(option.externalCode, "OF04_10") ||
    _.isEqual(option.externalCode, "OF04_09")
  ) {
    switch (_.first(option.salesOption)?.externalCode) {
      case "301":
      case "302":
      case "303":
      case "304":
        retCount = 1;
        break;
      case "101":
        retCount = 2;
        break;
      case "102":
      case "04":
      case "02":
        retCount = 6;
        break;
      case "103":
      case "05":
      case "03":
        retCount = 12;
        break;
    }
  } else if (
    _.isEqual(option.externalCode, "OF04_06") ||
    _.isEqual(option.externalCode, "OF04_07") ||
    _.isEqual(option.externalCode, "OF04_08") ||
    _.isEqual(option.externalCode, "OF05_01")
  ) {
    retCount = 6;
  } else if (_.isEqual(option.externalCode, "OF05_02")) {
    retCount = 12;
  } else if (
    _.isEqual(option.externalCode, "OF05_03") ||
    _.isEqual(option.externalCode, "OF05_04") ||
    _.isEqual(option.externalCode, "OF05_07") ||
    _.isEqual(option.externalCode, "OF05_08") ||
    _.isEqual(option.externalCode, "OF05_09")
  ) {
    retCount = 18;
  } else if (_.isEqual(option.externalCode, "OF05_10")) {
    retCount = 2;
  } else if (
    _.isEqual(option.externalCode, "OF05_11") ||
    _.isEqual(option.externalCode, "OF05_12") ||
    _.isEqual(option.externalCode, "OF05_13")
  ) {
    retCount = 4;
  } else if (_.isEqual(option.externalCode, "OF05_06")) {
    retCount = 24;
  } else if (
    _.isEqual(option.externalCode, "OF05_14") ||
    _.isEqual(option.externalCode, "OF05_15")
  ) {
    retCount = 21;
  }
  return retCount;
}

function getOptionDeliveryCount(optionExternalCode: string) {
  let retCount = 1;
  if (_.isEqual(optionExternalCode, "OF04_20")) {
    retCount = 1;
  }
  return retCount;
}

function convTimestampDateFormat(timestamp: number) {
  var dateFormat = new Date(timestamp);
  return (
    dateFormat.getFullYear() +
    "-" +
    (dateFormat.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    dateFormat.getDate().toString().padStart(2, "0")
  );
}

function getNewCareInfo(optionCode: string | undefined) {
  if (
    _.isEqual(optionCode, "01") ||
    _.isEqual(optionCode, "02") ||
    _.isEqual(optionCode, "03") ||
    _.isEqual(optionCode, "04") ||
    _.isEqual(optionCode, "05")
  ) {
    return "0";
  } else {
    return "";
  }
}

function getMenuPrice(exCode: string) {
  let ret = "";
  switch (exCode) {
    case "OF04_01": // 당뇨
    case "OF04_11": // 저당
    case "OF04_02": // 암환자
      ret = "7,700";
      break;
    case "OF04_03": // 퇴원후 회복식 (장수마을)
    case "OF04_10": // 암환자 회복식
      ret = "7,300";
      break;
    case "OF04_05": // 샐러드 식단
      ret = "6,300";
      break;
    case "OF04_09":
      ret = "7,700";
      break;
    case "OF04_07":
      ret = "4,300";
      break;
    case "OF04_06":
      ret = "3,950";
      break;
    case "OF04_08":
      ret = "5,250";
      break;
  }
  return ret;
}

function exCodeNumToStr(num: number) {
  let retStr = "";
  switch (num) {
    case 1:
      retStr = "OF04";
      break;
    case 2:
      retStr = "OF10";
      break;
    case 3:
      retStr = "OF05";
      break;
    case 4:
      retStr = "OF07";
      break;
    case 5:
      retStr = "OF13";
  }
  return retStr;
}
const filterCode = (code:string) => {
  switch (code) {
    case 'OF05_16':
      return false;
    case 'OF05_17':
      return false;
    case 'OF05_18':
      return false;
    default:
      return true;
  }
}

export const ssOrder = async (
  list: Array<IOoUnospayDelivery>,
  select: OoProvider
) => {
  let data_array: Array<ExcelExportModel> = [];
  console.log(list);
  //공휴일 적용 안됨
  const today = new Date();
  let minDate: Date;
  const dayOfWeek = today.getDay(); // 0은 일요일, 1은 월요일, ... 6은 토요일
  switch (dayOfWeek) {
    case 1: // 월요일
      minDate = addDays(today, 2);
      break;
    case 2: // 화요일
      minDate = addDays(today, 2);
      break;
    case 3: // 수요일
      minDate = addDays(today, 2);
      break;
    case 4: // 목요일
      minDate = addDays(today, 2);
      break;
    case 5: // 금요일
      minDate = addDays(today, 3);
      break;
    case 6: // 토요일
      minDate = addDays(today, 4);
      break;
    case 0: // 일요일
      minDate = addDays(today, 3);
      break;
    default:
      minDate = addDays(today, 1);
  }

  let menuListIndex = 0;
  list.forEach((e, i) => {
    // if(e.orderPlatform == OoOrderPlatformType.mtmMall) {
    //   return;
    // }
    if (e.status == OoDeliveryStatus.existAddress  || e.status == OoDeliveryStatus.sendPackage) {
      const model = new ExcelExportModel();
      model.excelStatus = e.status == OoDeliveryStatus.sendPackage ? 'sendPackage' : 'existAddress'
      e.transportInfo.forEach((info, i) => {
        const option = e.salesMenu![i];
        if(filterCode(info.externalCode)){
        //옵션으로 묶인 단품메뉴들
          option.salesOption?.map((optionData: IOoUnospaySalesMenuOption) => {
            if (_.startsWith(optionData.externalCode, exCodeNumToStr(select))) {
              console.log("optionData");
              model.product = info.menuName;
              model.exCode = info.externalCode;
              // model.quantity = e.salesMenu![i].menuCount;
              model.orderDate = convTimestampDateFormat(e.timestamp);

              model.name = e.recipientName;
              model.tel1 =  e.mobile?.replace("\t", "").split('-').join('');
              model.tel2 =  e.phoneNumber?.replace("\t", "").split('-').join('');
              model.address1 = e.address;
              model.address2 = e.addressDetail;
              model.zipCode = e.zipCode;
              model.memo = e.memo;
              model.subMemo = e.subMemo;

              option.salesOption?.forEach((e) => {
                if (!_.isEmpty(e.externalCode)) {
                  model.optionCodeList!.push(e.externalCode!);
                }
              });

              model.quantity = option.menuCount;

              model.withNewCare =
                getNewCareInfo(_.first(option.salesOption)?.externalCode) || "";

              if (e.salesMenu && e.salesMenu.length > i) {
                const salesMenuItem = e.salesMenu[i];

                const count = getOptionDeliveryCount(optionData.externalCode!);

                for (var j = 0; j < count; j++) {
                  data_array.push({ ...model });
                }
              }
            }
          });

          if (_.startsWith(info.externalCode, exCodeNumToStr(select))) {
            model.product = info.menuName;
            model.exCode = info.externalCode;
            // model.quantity = e.salesMenu![i].menuCount;
            model.orderDate = convTimestampDateFormat(e.timestamp);

            model.name = e.recipientName;
            model.tel1 =  e.mobile?.replace("\t", "").split('-').join('');
            model.tel2 =  e.phoneNumber?.replace("\t", "").split('-').join('');
            model.address1 = e.address;
            model.address2 = e.addressDetail;
            model.zipCode = e.zipCode;
            model.memo = e.memo;
            model.subMemo = e.subMemo;

            option.salesOption?.forEach((e) => {
              if (!_.isEmpty(e.externalCode)) {
                model.optionCodeList!.push(e.externalCode!);
              }
            });

            model.quantity = option.menuCount;

            const count = getDeliveryCount(option!);
            model.withNewCare =
              getNewCareInfo(_.first(option.salesOption)?.externalCode) || "";
            model.price =
              getMenuPrice(info.externalCode) ||
              formatPrice(Number(option.menuPrice)).toString();

            if (
              info.externalCode?.startsWith("OF05") ||
              info.externalCode?.startsWith("OF07")
            ) {
              model.deliveryDate =
                e.salesMenu![i].selectMenu![0].date != null
                  ? e.salesMenu![i].selectMenu![0].date
                  : "";
            }

            if (e.salesMenu && e.salesMenu.length > i) {
              const salesMenuItem = e.salesMenu[i];

              const count = getDeliveryCount(salesMenuItem);

              for (var j = 0; j < count; j++) {
                const selectMenuIndex = Math.floor(j / 2);
                const selectMenuItem =
                  salesMenuItem.selectMenu &&
                  salesMenuItem.selectMenu.length > selectMenuIndex
                    ? salesMenuItem.selectMenu[selectMenuIndex]
                    : undefined;
                const menuList = selectMenuItem?.menuList;
                const currentSelectMenu = salesMenuItem.selectMenu
                  ? salesMenuItem.selectMenu[Math.floor(j / 2)]
                  : undefined;

                if (info.externalCode == "OF05_01") {
                  model.menu = initialWeaning[j].name;
                  model.menuCode = initialWeaning[j].code;
                  model.menuCost = initialWeaning[j].cost;
                  if (j == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_02") {
                  if (j < count / 2) {
                    model.menu = middleWeaning[0][j].name;
                    model.menuCode = middleWeaning[0][j].code;
                    model.menuCost = middleWeaning[0][j].cost;
                  } else {
                    console.log(j - count / 2);
                    model.menu = middleWeaning[1][j - count / 2].name;
                    model.menuCode = middleWeaning[1][j - count / 2].code;
                    model.menuCost = middleWeaning[1][j - count / 2].cost;
                  }
                  if (j == 0 || j - count / 2 == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_03") {
                  if (j < count / 2) {
                    model.menu = lateWeaning[0][j].name;
                    model.menuCode = lateWeaning[0][j].code;
                    model.menuCost = lateWeaning[0][j].cost;
                  } else {
                    model.menu = lateWeaning[1][j - count / 2].name;
                    model.menuCode = lateWeaning[1][j - count / 2].code;
                    model.menuCost = lateWeaning[1][j - count / 2].cost;
                  }
                  if (j == 0 || j - count / 2 == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_04") {
                  if (j < count / 2) {
                    model.menu = completeWeaning[0][j].name;
                    model.menuCode = completeWeaning[0][j].code;
                    model.menuCost = completeWeaning[0][j].cost;
                  } else {
                    model.menu = completeWeaning[1][j - count / 2].name;
                    model.menuCode = completeWeaning[1][j - count / 2].code;
                    model.menuCost = completeWeaning[1][j - count / 2].cost;
                  }
                  if (j == 0 || j - count / 2 == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_06") {
                  if (j < count / 2) {
                    model.menu = prepMeal_13M[0][j].name;
                    model.menuCode = prepMeal_13M[0][j].code;
                    model.menuCost = prepMeal_13M[0][j].cost;
                  } else {
                    model.menu = prepMeal_13M[1][j - count / 2].name;
                    model.menuCode = prepMeal_13M[1][j - count / 2].code;
                    model.menuCost = prepMeal_13M[1][j - count / 2].cost;
                  }
                  if (j == 0 || j - count / 2 == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_07") {
                  if (j < count / 2) {
                    model.menu = twoDishesOneSoup_14M[0][j].name;
                    model.menuCode = twoDishesOneSoup_14M[0][j].code;
                    model.menuCost = twoDishesOneSoup_14M[0][j].cost;
                  } else {
                    model.menu = twoDishesOneSoup_14M[1][j - count / 2].name;
                    model.menuCode = twoDishesOneSoup_14M[1][j - count / 2].code;
                    model.menuCost = twoDishesOneSoup_14M[1][j - count / 2].cost;
                  }
                  if (j == 0 || j - count / 2 == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_08") {
                  if (j < count / 2) {
                    model.menu = twoDishesOneSoupOrSauce_14M[0][j].name;
                    model.menuCode = twoDishesOneSoupOrSauce_14M[0][j].code;
                    model.menuCost = twoDishesOneSoupOrSauce_14M[0][j].cost;
                  } else {
                    model.menu =
                      twoDishesOneSoupOrSauce_14M[1][j - count / 2].name;
                    model.menuCode =
                      twoDishesOneSoupOrSauce_14M[1][j - count / 2].code;
                    model.menuCost =
                      twoDishesOneSoupOrSauce_14M[1][j - count / 2].cost;
                  }
                  if (j == 0 || j - count / 2 == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_09") {
                  if (j < count / 2) {
                    model.menu = simpleSet_14M[0][j].name;
                    model.menuCode = simpleSet_14M[0][j].code;
                    model.menuCost = simpleSet_14M[0][j].cost;
                  } else {
                    model.menu = simpleSet_14M[1][j - count / 2].name;
                    model.menuCode = simpleSet_14M[1][j - count / 2].code;
                    model.menuCost = simpleSet_14M[1][j - count / 2].cost;
                  }
                  if (j == 0 || j - count / 2 == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_10") {
                  model.menu = ambientMiddleWeaning[j].name;
                  model.menuCode = ambientMiddleWeaning[j].code;
                  model.menuCost = ambientMiddleWeaning[j].cost;
                  if (j == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_11") {
                  model.menu = ambientLateWeaning[j].name;
                  model.menuCode = ambientLateWeaning[j].code;
                  model.menuCost = ambientLateWeaning[j].cost;
                  if (j == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_12") {
                  model.menu = ambientCompleteWeaning[j].name;
                  model.menuCode = ambientCompleteWeaning[j].code;
                  model.menuCost = ambientCompleteWeaning[j].cost;
                  if (j == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_13") {
                  model.menu = ambientKids[j].name;
                  model.menuCode = ambientKids[j].code;
                  model.menuCost = ambientKids[j].cost;
                  if (j == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_14") {
                  if (j < 9) {
                    model.menu = gastrectomyLatter[0][j].name;
                    model.menuCode = gastrectomyLatter[0][j].code;
                    model.menuCost = gastrectomyLatter[0][j].cost;
                  } else {
                    model.menu = gastrectomyLatter[1][j - 9].name;
                    model.menuCode = gastrectomyLatter[1][j - 9].code;
                    model.menuCost = gastrectomyLatter[1][j - 9].cost;
                  }
                  if (j == 0 || j - 9 == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                } else if (info.externalCode == "OF05_15") {
                  if (j < 9) {
                    model.menu = gastrectomyFinal[0][j].name;
                    model.menuCode = gastrectomyFinal[0][j].code;
                    model.menuCost = gastrectomyFinal[0][j].cost;
                  } else {
                    model.menu = gastrectomyFinal[1][j - 9].name;
                    model.menuCode = gastrectomyFinal[1][j - 9].code;
                    model.menuCost = gastrectomyFinal[1][j - 9].cost;
                  }
                  if (j == 0 || j - 9 == 0) {
                    model.deliveryCost = "3500";
                  } else {
                    model.deliveryCost = "";
                  }
                }
                data_array.push({ ...model });

                if (
                  info.externalCode.startsWith("OF05") &&
                  !_.isEmpty(model.optionCodeList)
                ) {
                  if (
                    info.externalCode == "OF05_01" ||
                    info.externalCode == "OF05_10" ||
                    info.externalCode == "OF05_11" ||
                    info.externalCode == "OF05_12" ||
                    info.externalCode == "OF05_13"
                  ) {
                    if (j == count - 1) {
                      if (model.optionCodeList != undefined) {
                        if (
                          model.optionCodeList.some(
                            (item: string) => item === "101"
                          )
                        ) {
                          model.menu = "토핑볶은한우";
                          model.menuCode = "H-001-51";
                          model.menuCost = "8080";
                        } else if (
                          model.optionCodeList.some(
                            (item: string) => item === "102"
                          )
                        ) {
                          model.menu = "토핑 볶은한우플러스";
                          model.menuCode = "H-002-51";
                          model.menuCost = "7530";
                        }
                      }
                      data_array.push({ ...model });
                    }
                  }
                  // 위에 해당하지 않는 나머지 모든 메뉴 중 토핑과 관계 없는 제품군 제외
                  else if (
                    info.externalCode !== "OF05_14" &&
                    info.externalCode !== "OF05_15"
                  ) {
                    if (j == count - 1 || j == count / 2 - 1) {
                      if (model.optionCodeList != undefined) {
                        if (
                          model.optionCodeList.some(
                            (item: string) => item === "101"
                          )
                        ) {
                          model.menu = "토핑볶은한우";
                          model.menuCode = "H-001-51";
                          model.menuCost = "8080";
                        } else if (
                          model.optionCodeList.some(
                            (item: string) => item === "102"
                          )
                        ) {
                          model.menu = "토핑 볶은한우플러스";
                          model.menuCode = "H-002-51";
                          model.menuCost = "7530";
                        }
                      }
                      data_array.push({ ...model });
                    }
                  } else if (
                    info.externalCode == "OF05_14" ||
                    info.externalCode == "OF05_15"
                  ) {
                    if (j == 8) {
                      model.menu = "환자식 설명서";
                      model.menuCode = "9780201231227";
                      model.menuCost = "";
                      model.quantity = "1";
                      data_array.push({ ...model });
                      model.menu = "용기 뚜껑";
                      model.menuCode = "9780201231234";
                      model.menuCost = "";
                      model.quantity = "9";
                      data_array.push({ ...model });
                    } else if (j == count - 1) {
                      model.menu = "환자식 설명서";
                      model.menuCode = "9780201231227";
                      model.menuCost = "";
                      model.quantity = "1";
                      data_array.push({ ...model });
                      model.menu = "용기 뚜껑";
                      model.menuCode = "9780201231234";
                      model.menuCost = "";
                      model.quantity = "12";
                      data_array.push({ ...model });
                    }
                    model.quantity = "1";
                  }
                }
              }
            }
          }
        }
      });
    }
  });

  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = `tlc헬스케어 발주서`;

  // const ws_ss = XLSX.utils.aoa_to_sheet([
  //     [
  //         "주문번호", "우편번호", "배송지주소", "수취인명", "수취인연락처", "상품명", "납품가", "매출금액", "품목코드", "주문 수량", "택배비", "배송메세지",
  //         "메모1", "메모2", "비고", "생산일", "정산기준일",
  //     ],
  // ]);

  const arrayBufferSs = await (
    await fetch("/assets/ss.xlsx")
  ).arrayBuffer();
  const workbookSs = new ExcelJS.Workbook();
  await workbookSs.xlsx.load(arrayBufferSs);
  const ws_ss = workbookSs.getWorksheet('발주서');

  if (select == OoProvider.ssProvider) {
    let manufacturingDate = "";
    const rowNumber = 2;
    [...data_array].map((data: ExcelExportModel, index: number) => {
      if (
        data.exCode == "OF05_10" ||
        data.exCode == "OF05_11" ||
        data.exCode == "OF05_12" ||
        data.exCode == "OF05_13"
      ) {
        manufacturingDate = moment().format("YYYY-MM-DD");
      } else {
        manufacturingDate =
          moment().format("dddd") === "Friday"
            ? moment().add(3, "days").format("YYYY-MM-DD")
            : moment().add(1, "days").format("YYYY-MM-DD");
      }
      //순수본 OF05_01 한우토핑 로직
      if (
        (data.exCode == "OF05_01" ||
          data.exCode == "OF05_10" ||
          data.exCode == "OF05_11" ||
          data.exCode == "OF05_12" ||
          data.exCode == "OF05_13") &&
        !_.isEmpty(data.optionCodeList) &&
        (data.menuCode == "H-001-51" || data.menuCode == "H-002-51")
      ) {
        data.quantity = "2";
      }
      const row = ws_ss.getRow(rowNumber + index);
      [
        "TLC_" +
          moment(data.deliveryDate, "YYYYMMDD")
            .subtract(1, "days")
            .format("YYYYMMDD") +
          "_" +
          String(index + 1).padStart(3, "0"),
        data.zipCode,
        data.address1 + " " + data.address2,
        data.name,
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}`,
        data.menu,
        Number(data.menuCost),
        Number(data.menuCost),
        data.menuCode,
        Number(data.quantity),
        Number(data.deliveryCost),
        data.memo,
        data.product,
        "",
        "TLC_" +
          moment(data.deliveryDate, "YYYYMMDD")
            .subtract(1, "days")
            .format("MMDD"),
        moment(data.deliveryDate, "YYYYMMDD")
          .subtract(1, "days")
          .format("YYYYMMDD"),
        moment(data.deliveryDate, "YYYYMMDD")
          .subtract(1, "days")
          .format("YYYYMMDD"),
      ].forEach((value, index) => {
        const cell = row.getCell(index + 1); // 엑셀은 1부터 시작
          cell.value = value;
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
      });
      return false;
    });
  }
  const excelBuffer = await workbookSs.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFile,
    excelFileName +
      "_" +
      moment().format("YYYYMMDD") +
      "(순수본)" +
      excelFileExtension
  );
};
