import _ from "lodash";
import {
    IOoDiagnosis,
    isDiagnosisPaySucceed,
    isMedicinePaySucceed,
    OoDiagnosisStatus,
    OoKakaoQuickStatus,
    OoMedicineDeliveryType
} from "@api/models";

interface DiagnosisProcessingState {
    title: string,
    color: DiagnosisStatusColor,
    background: DiagnosisBackgroundColor,
    timestamp: number,
}

enum DiagnosisStatusColor {
    default = '#28344D',
    cancel = '#CF5D5D',
    success = '#3E7BCD',
    wait = '#3E7BCD',
}

enum DiagnosisBackgroundColor {
    success = '#EFF7FF',
    fail = '#FDEFEF',
}

export function isKaKaoDelivery(diagnosis: IOoDiagnosis) {
    return _.isEqual(diagnosis.deliveryType, OoMedicineDeliveryType.kaKaoMobility) && _.gte(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.request);
}

export function getDiagnosisKakaoDeliveryStateByPharmacy(diagnosis: IOoDiagnosis): DiagnosisProcessingState {
    let statusData = {
        title: 'error!',
        color: DiagnosisStatusColor.default,
        background: DiagnosisBackgroundColor.success,
        timestamp: diagnosis.timestamp,
    };
    switch (diagnosis.status) {
        case OoDiagnosisStatus.diagnosisComplete:
            if (isDiagnosisPaySucceed(diagnosis)) {
                if (_.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.cancelMatchingFailed)) {
                    statusData = {
                        title: '배송 취소',
                        color: DiagnosisStatusColor.default,
                        background: DiagnosisBackgroundColor.success,
                        timestamp: diagnosis.timestamp
                    };
                } else {
                    statusData = {
                        title: '진료 완료',
                        color: DiagnosisStatusColor.default,
                        background: DiagnosisBackgroundColor.success,
                        timestamp: diagnosis.timestamp
                    };
                }
            } else {
                statusData = {
                    title: '진료비 결제 실패',
                    color: DiagnosisStatusColor.cancel,
                    background: DiagnosisBackgroundColor.fail,
                    timestamp: diagnosis.timestamp
                };
            }
            break;
        case OoDiagnosisStatus.decidedDeliveryType:
            statusData = {
                title: '약 배송 신청',
                color: DiagnosisStatusColor.default,
                background: DiagnosisBackgroundColor.success,
                timestamp: diagnosis.timestamp
            };
            break;
        case OoDiagnosisStatus.medicineDeliveryPaymentWait:
            if (_.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.cancelBeforePayment)) {
                statusData = {
                    title: '배송 취소',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            } else {
                statusData = {
                    title: '약제비 결제 대기',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            }
            break;
        case OoDiagnosisStatus.medicineDeliveryPaymentOk:
            if (isMedicinePaySucceed(diagnosis)) {
                if (_.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.matchingFailed)
                    || _.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.cancelAfterPaymentAfterMatching)
                    || _.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.feePayFailed)) {
                    statusData = {
                        title: '약제비 결제 완료',
                        color: DiagnosisStatusColor.wait,
                        background: DiagnosisBackgroundColor.success,
                        timestamp: diagnosis.timestamp
                    };
                } else {
                    statusData = {
                        title: '약제비 결제 완료',
                        color: DiagnosisStatusColor.wait,
                        background: DiagnosisBackgroundColor.success,
                        timestamp: diagnosis.timestamp
                    };
                }
            } else {
                statusData = {
                    title: '약제비 결제 실패',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            }
            break;
        case OoDiagnosisStatus.medicineDeliveryAccept:
            if ((_.gte(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.request) && _.lte(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.pickupStarted))
                || (_.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.matchingFailed))
                || _.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.feePayFailed)) {
                statusData = {
                    title: '픽업 대기',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            } else if ((_.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.cancelAfterPaymentBeforeMatching))
                || (_.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.cancelAfterPaymentAfterMatching))
                || (_.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.pharmacyPickup))) {
                statusData = {
                    title: '약 준비 완료',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            } else {
                statusData = {
                    title: '픽업 대기',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            }
            break;
        case OoDiagnosisStatus.medicineDeliveryStart:
            if (_.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.cancelBeforePayment)) {
                statusData = {
                    title: '배송 취소',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            } else if (_.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.matchingFailed)) {
                statusData = {
                    title: '픽업 대기',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            } else if (_.gte(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.cancelAfterPaymentBeforeMatching) && _.lt(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.pharmacyPickup)) {
                statusData = {
                    title: '약제비 결제 완료',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            } else if (_.isEqual(diagnosis.kakaoQuickStatus, OoKakaoQuickStatus.feePayFailed)) {
                statusData = {
                    title: '약 배송 시작',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            } else {
                statusData = {
                    title: '약 배송 시작',
                    color: DiagnosisStatusColor.wait,
                    background: DiagnosisBackgroundColor.success,
                    timestamp: diagnosis.timestamp
                };
            }
    }
    return statusData;
}