import {IOoDiagnosis, OoTelemedicineType} from "./diagnosis";

// 진료신청
interface RequestProps {
    serviceType?: number,
    symptom: string,
    symptomPictures?: Array<string>,
    serviceText: string,
}

export function getPatientRequestState(status: IOoDiagnosis): RequestProps {

    switch (status.serviceType) {
        case OoTelemedicineType.phone:
            return {
                serviceType: status.serviceType,
                symptom: status.symptom,
                symptomPictures: status.symptomPictures,
                serviceText: "전화",

            }
        case OoTelemedicineType.visit:
            return {
                serviceType: status.serviceType,
                symptom: status.symptom,
                symptomPictures: status.symptomPictures,
                serviceText: "방문",

            }
        default :
            return {
                serviceType: status.serviceType,
                symptom: status.symptom,
                symptomPictures: status.symptomPictures,
                serviceText: "화상"
            }
    }

}

export type {RequestProps};