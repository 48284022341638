import styled from "styled-components";

interface Props {
    children: JSX.Element,
}

export const DetailContainer = ({children}: Props) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
