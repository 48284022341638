import {AxiosInstance} from "axios";
import {IOoDataResponse, IOoResponse} from "../reponses";
import {endPoint} from "./base";
import {OoParamPharmacyCreate, OoParamPharmacyHours, OoParamPharmacyUpdate} from "@api/params";
import {OoParamId} from "../params";
import {IOoPharmacy} from "@models/pharmacy";

class DoctorOnPharmacyApi {
    constructor(private readonly axiosInstance: AxiosInstance) {
    }

    async create(param: OoParamPharmacyCreate): Promise<IOoDataResponse<IOoPharmacy>> {
        return await this.axiosInstance.post<IOoDataResponse<IOoPharmacy>>(endPoint.pharmacy.create, param);
    }

    async update(param: OoParamPharmacyUpdate): Promise<IOoDataResponse<IOoPharmacy>> {
        return await this.axiosInstance.put<IOoDataResponse<IOoPharmacy>>(endPoint.pharmacy.update, param);
    }

    async list(): Promise<IOoDataResponse<IOoPharmacy[]>> {
        return await this.axiosInstance.get<IOoDataResponse<IOoPharmacy[]>>(endPoint.pharmacy.list);
    }

    async readByUserId(param: OoParamId): Promise<IOoDataResponse<IOoPharmacy>> {
        const url = `${endPoint.pharmacy.readByUserId}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoPharmacy>>(url);
    }

    async delete(param: OoParamId): Promise<IOoResponse> {
        const url = `${endPoint.pharmacy.delete}/${param.getPathParams()}`;
        return await this.axiosInstance.delete<IOoResponse>(url);
    }

    async pharmacyHours(param: OoParamPharmacyHours): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.pharmacy.pharmacyHours, param);
    }
}

export {
    DoctorOnPharmacyApi
};