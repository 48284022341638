import React from "react";
import _ from "lodash";
import styled from "styled-components";
import {getDiagnosisPriceStatus, IOoDiagnosis} from "@api/index";

interface Props {
    diagnosis: IOoDiagnosis,
}

export const DiagnosisInfo = ({diagnosis}: Props) => {
    return (
        <Wrapper>
            <div className="title">진료 정보</div>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>진료비</td>
                    <td>{getDiagnosisPriceStatus(diagnosis).title}</td>
                </tr>
                <tr>
                    <td>진료 내용</td>
                    <td>
                        <Comment>
                            {_.isEmpty(diagnosis.comment) ? '-' : diagnosis.comment}
                        </Comment>
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-top: 48px;

  .title {
    font-size: 16px;
    font-weight: 700;
  }
`;

const Table = styled.table`
  margin-top: 8px;
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: 100%;
  height: auto;
  font-size: 13px;
  font-weight: 700;

  & tbody tr {
    border-bottom: 1px solid #C8D6F8;
    height: 50px;
  }

  & tbody tr td:nth-child(2n-1) {
    background: #F4F6FB;
    width: 10%;
    text-align: center;
  }

  & tbody tr td:nth-child(2n) {
    font-weight: normal;
    padding: 10px;
    font-size: 13px;
  }
`;

const Comment = styled.div`
  height: auto;
  white-space: pre-wrap;
`;