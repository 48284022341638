import React, {useEffect, useState} from "react";
import _ from "lodash";
import {IOoDiagnosis} from "@api/models";
import {OoParamAddMedicinePrice} from "@api/params";
import styled from "styled-components";
import {Button, Modal} from "react-bootstrap";

interface ConfirmModalProps {
    modalVisible: boolean,
    diagnosisList: Array<IOoDiagnosis>,
    medicinePrice: Array<OoParamAddMedicinePrice>,
    onChangedStatus: (status: boolean) => void,
}

export const ConfirmModal = ({modalVisible, diagnosisList, medicinePrice, onChangedStatus}: ConfirmModalProps) => {
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        setVisible(modalVisible);
    }, [modalVisible]);

    const handleClose = () => setVisible(!visible);

    const findPatientName = (id: string) => {
        const findId = diagnosisList.find((item) => _.isEqual(item.id, id));
        return findId?.patientName;
    };

    const onSubmitButton = () => {
        onChangedStatus(true);
        setVisible(!visible);
    };

    const onCloseButton = () => {
        onChangedStatus(false);
        setVisible(!visible);
    };

    return (
        <Modal size='lg' show={visible} onHide={handleClose} centered={true}>
            <Modal.Header>
                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <h4>약제비 전송</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {
                        medicinePrice.map((price) => (
                            <div key={price.diagnosisId}>
                                {findPatientName(price.diagnosisId)}님의 약제비 {price.price}원
                            </div>
                        ))
                    }
                    <p>전송 하시겠습니까?</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSubmitButton}>전송하기</Button>
                <Button onClick={onCloseButton}>닫기</Button>
            </Modal.Footer>
        </Modal>
    )
}

const Wrapper = styled.div`
  width: 550px;
  height: auto;
  padding: 10px;
`;

const ModalHeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const ModalClosetButton = styled.button`

`;

const ModalBodyWrapper = styled.div`

`;

export type {ConfirmModalProps};