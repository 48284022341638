import {IOoReservationHour} from "@api/models";
import {IOoWorkHours} from "../pharmacy";

//의사
interface IOoDoctor {
    id?: string;

    // 사용자 아이디 (account  테이블)
    userId: string;

    //의사코드 (자동 생성)
    code: string;

    //의사코드 QR(자동 생성)
    codeQrUrl?: string;

    //이름
    name: string;

    //성별 (male, female)
    gender: string;

    //전화번호
    tel?: string;

    //핸드폰
    mobile: string;

    //정산여부
    isCalculateAccount: boolean;

    //이메일
    email: string;

    //소개
    bio?: string;

    //학력
    education?: string;

    //경력
    career?: string;

    //라이센스 번호
    license: string;

    //서비스 타입 (b2c 노출 여부)
    serviceType: number;

    //진료과
    departments: Array<string>;

    //진료테마
    theme?: Array<string>;

    //사진
    pictures: Array<string>;

    //화상 진료 가능 여부
    isVideoDiagnosis?: boolean;

    //알림톡 수신 번호
    alimTalkReceivers: Array<string>;

    //소속 병원 아이디 목록
    hospitalId: string;

    //정산 은행명
    bank?: string;

    //계좌번호
    bankAccount?: string;

    //예금주
    bankHolderName?: string;

    //진료시간
    doctorHours?: Array<IOoWorkHours>;

    //비디오 링크
    videoLink?: string;

    //비디오 아이디
    videoId?: string;

    //비디오 패스워드
    videoPassword?: string;

    //예약 기능 노출
    isUseReservation?: boolean;

    //당일 예약 허용
    isAllowTodayReservation?: boolean;

    //예약 가능 인원
    reservationHeadCount?: number;

    //예약 가능 시간대
    reservationTime?: Array<string>;

    //예약 시간
    reservationHours?: Array<IOoReservationHour>

    //지역 검색 키워드
    regionKeywordList?: Array<string>;

    //등록일
    timestamp: number;

    //와플서버 의사 진료가능 타입
    // 기본값은  [4, 6] -> 온라인 전화 접수, 오프라인 접수
    wapleServiceType?: Array<number>;
}

export type {
    IOoDoctor
};

export enum OoServiceType {
    b2c = 0,  //원격진료 전용 (기본)
    o2o = 1,  //지정병원 적용
    o2o2c = 2, //지정병원, 원격진료 모두
    testB2c = 3,
    testO2o = 4,
    testO2o2c = 5,
    covid = 6,
}

export const filteredServiceType = (type: number) => {
    let title = "코로나";
    switch (type) {
        case OoServiceType.b2c:
            title = "원격진료";
            break;
        case OoServiceType.o2o:
            title = "지정병원";
            break;
        case OoServiceType.o2o2c:
            title = "원격진료, 지정병원";
            break;
        case OoServiceType.testB2c:
            title = "테스트 원격진료";
            break;
        case OoServiceType.testO2o:
            title = "테스트 지정병원";
            break;
        case OoServiceType.testO2o2c:
            title = "테스트 원격진료, 지정병원";
            break;
        default :
            break;
    }
    return title;
}

export const reversedServiceType = (title: string) => {
    switch (title) {
        case "원격진료":
            return OoServiceType.b2c;
        case "지정병원":
            return OoServiceType.o2o;
        case  "원격진료, 지정병원":
            return OoServiceType.o2o2c;
        case  "테스트 원격진료":
            return OoServiceType.testB2c;
        case  "테스트 지정병원":
            return OoServiceType.testO2o;
        case  "테스트 원격진료, 지정병원":
            return OoServiceType.testO2o2c;
        default :
            return OoServiceType.covid;
    }
}