import {Card, Container} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import _ from "lodash";
import {HospitalType} from "../../../../api";
import {HospitalTypeInfo} from "../../../../interfaces/Operator/OperatorHospitalCreateInterface";


interface Props {
    onChange: (data: HospitalTypeInfo) => void,
}

const DisabledOption = styled.option`
  &:disabled {
    display: none;
  }
`;
export const HospitalRegisterType = ({onChange}: Props) => {

    const [data, setData] = useState<HospitalTypeInfo>({
        type: HospitalType.normal,
    });

    useEffect(() => {
        onChange(data);
    }, [data]);

    const onSelectType = (e: any) => {
        let value = _.toNumber(e.target.value);
        setData({...data, type: value});
    };

    return (
        <Container>
            <Card>
                <div><b>병원 진료 타입</b></div>
                <select onChange={onSelectType} required={true}>
                    <DisabledOption selected={true} disabled>병원 타입을 선택하세요.</DisabledOption>
                    <option value={HospitalType.normal}>양방</option>
                    <option value={HospitalType.orientalMedicine}>한방</option>
                    <option value={HospitalType.b2b}>B2B</option>
                </select>
            </Card>
        </Container>
    )
}
