import {atom} from "recoil";

export interface postCodeProps {
    zipCode: string,
    address: string,
    detailAddress?: string
}

export const postCodeState = atom<postCodeProps>({
    key: 'postCodeState',
    default: {
        zipCode: '',
        address: '',
        detailAddress: ''
    }
})