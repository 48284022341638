import React, {useMemo, useState} from "react";
import {Document, Page, pdfjs} from 'react-pdf';
import styled from "styled-components";
import {useRecoilState} from "recoil";
import {faAngleLeft, faAngleRight, faX} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import _ from "lodash";
import {fileState} from "@recoils/file";
import {ModalOverlay} from "@components/modal";
import style from '../../styles/common/PrescriptionFileAttach.module.scss';
import classNames from "classnames/bind";

// workerSrc 정의 하지 않으면 pdf 보여지지 않습니다.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
    pdfUrl: URL | string,
}

export const PdfViewer = ({pdfUrl}: Props) => {
    const cx = classNames.bind(style);
    const [visible, setVisible] = useRecoilState(fileState);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const file = useMemo(() => ({pdfUrl}), [pdfUrl]);

    const handleInvisible = (e: any) => {
        e.preventDefault();
        setVisible(false);
    };

    const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    return (
        <ModalOverlay visible={visible}>
            <OverlayContainer>
                <Document file={_.toString(file.pdfUrl)}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onSourceError={(error) => toast.error('Error while retrieving document source! ' + error.message)}>
                    <Page pageNumber={pageNumber}/>
                </Document>
                <DivContainer>
                    <ButtonDiv>
                        {pageNumber > 1 && (
                            <PreviousButton onClick={(e: any) => {
                                e.preventDefault();
                                setPageNumber(prev => prev + (-1))
                            }}>
                                <FontAwesomeIcon icon={faAngleLeft} className={cx('custom-arrow-icon')}/>
                            </PreviousButton>
                        )}
                    </ButtonDiv>
                    <PageDiv> {pageNumber} / {numPages}</PageDiv>
                    <ButtonDiv>
                        {pageNumber < numPages &&
                            <NextButton onClick={(e: any) => {
                                e.preventDefault();
                                setPageNumber(prev => prev + (+1))
                            }}>
                                <FontAwesomeIcon icon={faAngleRight} className={cx('custom-arrow-icon')}/>
                            </NextButton>}
                    </ButtonDiv>
                </DivContainer>
            </OverlayContainer>
            <ButtonContainer>
                <CloseButton onClick={handleInvisible}>
                    <FontAwesomeIcon icon={faX} className="custom-close-icon"/>
                </CloseButton>
            </ButtonContainer>
        </ModalOverlay>
    )
}

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
`;

const DivContainer = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: 25px;
  justify-content: space-evenly;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  font-size: 30px;
  padding-left: 15px;
`;

const PageDiv = styled.div`
  font-size: 25px;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const PreviousButton = styled.button`
  background: rgba(4, 4, 4, 0.6);
  border: rgba(4, 4, 4, 0.6);
  border-radius: 50%;
  width: 40px;
  height: 40px;

  & > svg {
    border-color: #FFFFFF;
  }
`;

const NextButton = styled.button`
  background: rgba(4, 4, 4, 0.6);
  border: rgba(4, 4, 4, 0.6);
  border-radius: 50%;
  width: 40px;
  height: 40px;

  & > svg {
    border-color: #FFFFFF;
  }
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  color: #fff;
  display: flex;
  justify-content: flex-end;
`;