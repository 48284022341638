import { OoUnospayCalculateListParam } from "@api/params/calculate/calculateListParam";
import { useSuspenseHook } from "..";
import { useRecoilState, useRecoilValue } from "recoil";
import { useCalculateListMutation, useCalculateRawListMutation } from "@queries/calculate/useCalculateListMutation";

export const useCalculateFunctionHook = () => {
  //정산데이터 조회
  const calculateListResp = useCalculateListMutation();
  const calculateRawListResp = useCalculateRawListMutation();

  return {
    calculateListResp,
    calculateRawListResp
  };
};
