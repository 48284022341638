import {useQuery} from "react-query";
import {OoParamName} from "@api/params";
import {DoctorOnAPI, isRequestSucceed} from "../../api";
import _, {isEqual} from "lodash";
import {FilterStatus} from "../../pages/Operator/DiagnosisDashBoardPage/components";
import {useSuspenseHook} from "../../hooks";
import {useSetRecoilState} from "recoil";
import {searchListState, selectedFilterState} from "../../recoils";
import {toast} from "react-toastify";

interface Props {
    searchName: string,
    searchQueryTrigger: boolean,
    target: string,
}

export const useOperatorSearchQuery = ({searchName, searchQueryTrigger, target}: Props) => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const setSearchByNameList = useSetRecoilState(searchListState(target));
    const setSelectFilterStatus = useSetRecoilState(selectedFilterState);

    return useQuery(
        'search/diagnosisPatientSearch',
        async () => {
            try {
                setLoading();
                const parseName = new OoParamName(searchName);
                const response = await DoctorOnAPI.shared.familyTelemedicine.patientNameRecord(parseName);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    if (isEqual(response.data?.length, 0)) {
                        toast.error(searchName + '으로 검색된 결과가 없습니다.');
                        return;
                    }
                    setSearchByNameList({id: target, userName: searchName, list: response.data!});
                    setSelectFilterStatus(_.toString(FilterStatus.total));
                }
                return null;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: searchQueryTrigger,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )
};