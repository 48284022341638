import {useState} from "react";
import {DoctorServiceInfo} from "../../../../interfaces/Operator/OperatorDoctorCreateInterface";
import _ from "lodash";
import {toast} from "react-toastify";

export const useDepartmentsSettingHook = () => {

    const [data, setData] = useState<DoctorServiceInfo>({
        departments: [],
        theme: [],
    });

    const [checkedDepartments, setCheckedDepartments] = useState<Array<string>>([]);

    const onCheckedDepartments = (e: any) => {
        const index = _.findIndex(checkedDepartments, (item) => _.isEqual(item, e.target.value));
        if (_.lt(checkedDepartments.length, 4)) {
            if (!_.isEqual(index, -1)) {
                checkedDepartments.splice(index, 1);
                setCheckedDepartments(checkedDepartments);
                setData({...data, departments: checkedDepartments});
            } else {
                checkedDepartments.push(e.target.value);
                setCheckedDepartments(checkedDepartments);
                setData({...data, departments: checkedDepartments});
            }
        } else {
            if (!_.isEqual(index, -1)) {
                checkedDepartments.splice(index, 1);
                setCheckedDepartments(checkedDepartments);
                setData({...data, departments: checkedDepartments});
            } else {
                toast.error('진료 과목은 최대 4개까지 선택가능합니다.');
                return;
            }
        }
    };

    const isEqualDepartments = (value: string) => {
        const temp = checkedDepartments.map(item => _.isEqual(item, value));
        return _.includes(temp, true);
    };

    return {
        data,
        checkedDepartments,
        onCheckedDepartments,
        isEqualDepartments
    }
}