import {AbstractOoParam} from "../base";

class OoUnospayAddressInfoUpdate extends AbstractOoParam {
    public id: string = '';

    public recipientName?: string = undefined;

    public mobile?: string = undefined;

    public address?: string = undefined;

    public addressDetail?: string = undefined;

    public zipCode?: string = undefined;

    public memo?: string = undefined;

    public subMemo?: string = undefined;

    public csMemo?: string = undefined;
}

export default OoUnospayAddressInfoUpdate;
