import {useMemo, useState} from "react";
import _ from "lodash";
import {splitCloseTime, splitOpenTime} from "@api/models/pharmacy";

export interface WorkHourRowProps {
    weekNumber: number,
    openHour: string,
    openMinute: string,
    closeHour: string,
    closeMinute: string,
    checked: boolean,
}

export const useWorkHourRowSettingHook = ({...props}) => {
    const {hours, dayOfWeekNumber, isOff} = props;

    const [openTime, setOpenTime] = useState<Array<string>>(splitOpenTime(hours));
    const [closeTime, setCloseTime] = useState<Array<string>>(splitCloseTime(hours));

    const originTime: WorkHourRowProps = useMemo(() => {
        return {
            weekNumber: dayOfWeekNumber,
            openHour: _.head(splitOpenTime(hours)) as string,
            openMinute: _.last(splitOpenTime(hours)) as string,
            closeHour: _.head(splitCloseTime(hours)) as string,
            closeMinute: _.last(splitCloseTime(hours)) as string,
            checked: isOff,
        }
    }, [hours, isOff]);

    const [changedTime, setChangedTime] = useState<WorkHourRowProps>(originTime);

    const onOpenTimeChanged = (time: string, id: string) => {
        const openHours = splitOpenTime(time);
        setChangedTime({
            ...changedTime,
            weekNumber: _.toNumber(id),
            openHour: _.head(openHours) as string,
            openMinute: _.last(openHours) as string,
            closeHour: changedTime.closeHour,
            closeMinute: changedTime.closeMinute,
        });
    };

    const onCloseTimeChanged = (time: string, id: string) => {
        const closeHours = splitOpenTime(time);
        setChangedTime({
            ...changedTime,
            weekNumber: _.toNumber(id),
            openHour: changedTime.openHour,
            openMinute: changedTime.openMinute,
            closeHour: _.head(closeHours) as string,
            closeMinute: _.last(closeHours) as string,
        });
    };

    const onCheckChanged = (e: any) => {
        setChangedTime({
            weekNumber: _.toNumber(e.target.id),
            openHour: changedTime.openHour,
            openMinute: changedTime.openMinute,
            closeHour: changedTime.closeHour,
            closeMinute: changedTime.closeMinute,
            checked: e.target.checked as boolean,
        });
    };

    return {
        openTime,
        closeTime,
        changedTime,
        setOpenTime,
        setCloseTime,
        setChangedTime,
        originTime,
        onCheckChanged,
        onCloseTimeChanged,
        onOpenTimeChanged,
    }

}