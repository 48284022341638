import styled from "styled-components";

interface FlexProps {
  flex: number;
  display?:string;
}

interface Props {
  children: any;
  flex: number;
  display?: string;
}

export const FlexDiv = ({ children, flex ,display }: Props) => {
  return <Div display={display} flex={flex}>{children}</Div>;
};

const Div = styled.div<FlexProps>`
  flex: ${(props) => props.flex};
  display: ${(props) => props.display};
  text-align: center;
`;
