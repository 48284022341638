import {Link} from "react-router-dom";
import React from "react";
import styled from "styled-components";
import _ from "lodash";
import {ReservationType} from "./ReservationType";
import {DiagnosisType} from "./DiagnosisType";
import {fnKakaoFilter} from "@models/diagnosis/function/fnKakaoFilter";
import {
  filterFamilyDiagnosisReversed,
  getMedicineDeliveryPayStatus,
  getPatientInfo,
  IOoDiagnosis,
  isMyFamilyMember
} from "@models/diagnosis";
import {getMedicineDeliveryState2} from "@models/diagnosis/medicineDeliveryState2";
import {DiagnosisStatusField} from "@components/diagnosisStatusField";
import {getDiagnosisStatus} from "@models/diagnosis/diagnosisProcessingState";
import {PatientInfoField} from "@components/patientInfoField";
import {FlexDiv} from "@components/flexDiv";
import {formatTel} from "@hooks/format";
import {ListStatusField} from "@components/listStatusField";
import {useRecoilValue} from "recoil";
import {useDisplayHook} from "@hooks/common";
import {useDiagnosisPayFilter} from "@models/diagnosis/function/diagnosisPaymentFilter";
import {useMedicinePaymentFilter} from "@models/diagnosis/function/medicinePaymentFilter";
import {useMedicineHospitalPaymentFilter} from "@models/diagnosis/function/medicineHospitalPaymentFilter";
import {calendarParamState} from "@recoils/operator/calendarParamState";
import {searchListState} from "@recoils/search";

interface Props {
  list: Array<IOoDiagnosis>;
}

export const DiagnosisListRecord = ({list}: Props) => {
  const {filteredKaKaoStatus} = fnKakaoFilter();
  const calendarParam = useRecoilValue(calendarParamState);
  const searchParam = useRecoilValue(searchListState('operator'));

  const {displayList} = useDisplayHook('operator');
  const predicate = (item: IOoDiagnosis, target: IOoDiagnosis) => isMyFamilyMember(target, item);
  const findFamilyList = (target: IOoDiagnosis) => filterFamilyDiagnosisReversed(displayList.list, (d) => predicate(d, target));

  return (
      <Wrapper>
        {
          list.map((item, i) => {
            const {getDiagnosisPayStatus} = useDiagnosisPayFilter(item);
            const {getMedicinePayStatus} = useMedicinePaymentFilter(item);
            const {getHospitalMedicinePayStatus} = useMedicineHospitalPaymentFilter(item);
            const status = {
              patientInfo: getPatientInfo(item),
              diagnosisPrice: getDiagnosisPayStatus(),
              medicinePay: getMedicinePayStatus(),
              hospitalMedicinePay: getHospitalMedicinePayStatus(),
              medicineDeliveryPay: getMedicineDeliveryPayStatus(item),
              deliveryStatus: getMedicineDeliveryState2(item),
              kaKaoStatus: filteredKaKaoStatus(item.kakaoQuickStatus || 0),
            };
            return (
                <StatusRow key={i}>
                  <Link to={`/diagnosisDetail`}
                        state={{
                          diagnosisList: _.concat(item, findFamilyList(item)),
                          calendarParam: calendarParam,
                          searchParam: searchParam
                        }}>
                    <DiagnosisStatusField status={getDiagnosisStatus(item)} flex={1.1}/>
                    <PatientInfoField title={status.patientInfo.patientName}
                                      isShowTag={!_.isEqual(status.patientInfo.familyKey, 0)}
                                      tagTitle={"가족"}
                                      flex={1}/>
                    <FlexDiv flex={1}>{formatTel(item.patientTel)}</FlexDiv>
                    <ReservationType list={item} flex={1}/>
                    <DiagnosisType item={item} flex={0.5}/>
                    <ListStatusField color={status.diagnosisPrice.color}
                                     title={status.diagnosisPrice.title}
                                     flex={1}/>
                    <FlexDiv flex={1}>
                      <div>{status.hospitalMedicinePay.title.length > 0 && status.hospitalMedicinePay.title}</div>
                      {status.medicinePay.title}
                    </FlexDiv>
                    <ListStatusField color={status.deliveryStatus.color}
                                     title={status.deliveryStatus.title}
                                     flex={1}/>
                    <Container>
                      <DeliveryStatus color={status.deliveryStatus.backgroundColor}>
                        {status.deliveryStatus.text}
                      </DeliveryStatus>
                    </Container>
                  </Link>
                </StatusRow>
            );
          })}
      </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: auto;
  width: 100%;
  max-width: 100%;
  min-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusRow = styled.div`
  background: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px -2px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 4px;
  width: 100%;

  & > a:first-child {
    width: 100%;
    height: 64px;
    color: #030303;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeliveryStatus = styled.div`
  width: 60px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
`;
