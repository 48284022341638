import {useMemo, useState} from "react";
import {
    filterFamilyDiagnosisReversed,
    IOoDiagnosis,
    isMyFamilyMember,
    OoDiagnosisStatus,
    OoWapleReservationStatus,
    OoWapleReservationType
} from "@api/models";
import {useRecoilValue} from "recoil";
import {doctorCalendarParamState, doctorSearchParamState} from "@recoils/doctor";
import {doctorSelectedFilterState} from "@recoils/operator";
import {useDisplayHook, useFilteredStatusHook} from "@hooks/common";
import _ from "lodash";

export const useDiagnosisListContainerSettingHook = (list: Array<IOoDiagnosis>) => {
    const [filteredList, setFilteredList] = useState<Array<IOoDiagnosis>>(list);

    const calendarParam = useRecoilValue(doctorCalendarParamState);
    const searchParam = useRecoilValue(doctorSearchParamState);
    const selectedFilterStatus = useRecoilValue(doctorSelectedFilterState);

    const parseNumber = parseInt(selectedFilterStatus);
    const statusList = useFilteredStatusHook({number: parseNumber, list: list});
    const memorizedList = useMemo(() => {
        return statusList;
    }, [list, parseNumber]);

    const {displayList} = useDisplayHook('doctor');
    const predicate = (item: IOoDiagnosis, target: IOoDiagnosis) => isMyFamilyMember(target, item);
    const findFamilyList = (target: IOoDiagnosis) => filterFamilyDiagnosisReversed(displayList.list, (d) => predicate(d, target));

    const isShowAlimTalkButton = (data: IOoDiagnosis): boolean => {
        return _.isEqual(data.status, OoDiagnosisStatus.accept);
    };

    const [value, setValue] = useState({
        userId: "",
        diagnosisId: "",
        message: "",
        title: "접수 알림",
    });

    const [queryTrigger, setQueryTrigger] = useState<boolean>(false);

    const isVisitConfirm = (target: IOoDiagnosis) => {
        return _.isEqual(OoWapleReservationType.visitReservation, target.wapleReservationType)
            && _.isEqual(OoWapleReservationStatus.reservationComplete, target.wapleReservationStatus);
    };

    return {
        filteredList,
        setFilteredList,
        calendarParam,
        searchParam,
        memorizedList,
        findFamilyList,
        isShowAlimTalkButton,
        statusList,
        value,
        setValue,
        queryTrigger,
        setQueryTrigger,
        isVisitConfirm,
    }
}