import React, {useEffect} from "react";
import {usePrimaryInfoHook} from "../hooks/usePrimaryInfoHook";
import styled from "styled-components";
import {CustomInputText} from "@components/custom";
import {PharmacyInfo} from "../../../../interfaces";
import {InitialImageUpload} from "@components/initialImageUpload";

interface Props {
    onChanged: (data: PharmacyInfo) => void;
}

export const PharmacyRegisterPrimaryInfo = ({onChanged}: Props) => {
    const {
        items,
        onChangeEmail,
        onChangePassword,
        onChangeName,
        onChangePicture,
    } = usePrimaryInfoHook();

    useEffect(() => {
        onChanged(items);
    }, [items]);

    return (
        <Wrapper>
            <div>
                <div>
                    <Table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>약국명</td>
                            <td>
                                <CustomInputText value={items.name}
                                                 color={"blue"}
                                                 size="middle"
                                                 placeholder="[필수]"
                                                 disabled={true}
                                                 onChanged={onChangeName}/>
                            </td>
                        </tr>
                        <tr>
                            <td>이메일</td>
                            <td>
                                <CustomInputText value={items.email}
                                                 color={"blue"}
                                                 size="middle"
                                                 placeholder="아이디@doctoron.kr [필수]"
                                                 disabled={true}
                                                 onChanged={onChangeEmail}/>
                            </td>
                        </tr>
                        <tr>
                            <td>비밀번호</td>
                            <td>
                                <CustomInputText value={items.password}
                                                 color={"blue"}
                                                 size="middle"
                                                 placeholder="8자리 이상 [필수]"
                                                 disabled={true}
                                                 onChanged={onChangePassword}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
                <ImageContainer>
                    <InitialImageUpload target="pharmacy"
                                        onChanged={onChangePicture}/>
                </ImageContainer>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }

  .image-container {
    padding-left: 50px;
  }
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tbody tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  tbody tr td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
    font-weight: 700;
  }

  tbody tr td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;

const ImageContainer = styled.div`
  padding-left: 50px;
`;
