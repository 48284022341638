import {atom, atomFamily, DefaultValue, selectorFamily} from "recoil";
import {IOoDiagnosis} from "@models/diagnosis";
import {recoilPersist} from "recoil-persist";
import {IOoUnospayDelivery} from "@api/models";

interface SearchInfo {
    id: string,
    userName?: string,
    list?: Array<IOoDiagnosis>,
}

const KEY = 'searchListState';

const {persistAtom} = recoilPersist({
    key: KEY,
    storage: localStorage
});

const searchAtom = atom<Array<string>>({
    key: 'searchAtom',
    default: [],
    effects_UNSTABLE: [persistAtom],
});

const searchAtomFamily = atomFamily<SearchInfo, string>({
    key: 'searchAtomFamily',
    default: (id) => {
        const item: SearchInfo = {
            id,
            userName: "",
            list: [],
        }
        return item;
    }
});

export const searchListState = selectorFamily<SearchInfo, string>({
    key: KEY,
    get: (id: string) => ({get}) => get(searchAtomFamily(id)),
    set: (id: string) => ({get, set, reset}, displayInfo) => {
        if (displayInfo instanceof DefaultValue) {
            reset(searchAtomFamily(id))
            set(searchAtom, (prevValue) => prevValue.filter((item) => item !== id))
            return
        }
        set(searchAtomFamily(id), displayInfo)
        set(searchAtom, (prev) => Array.from(new Set([...prev, id])))
    },
})
