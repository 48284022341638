import React from "react";
import styled, {css} from "styled-components";
import {Button} from "react-bootstrap";
import {DiagnosisDetailContainer} from "../DiagnosisDetailContainer";
import _ from "lodash";
import {IOoDiagnosis, OoDiagnosisStatus, OoPaymentType} from "@models/diagnosis";
import {DiagnosisBackgroundColor, getDiagnosisStatus} from "@models/diagnosis/diagnosisProcessingState";
import {useModalHook} from "@hooks/modal";
import {useDiagnosisFunctionHook} from "@hooks/common";
import {useDoctorFunctionHook} from "@hooks/doctor";

interface Props {
    diagnosis: IOoDiagnosis,
    list: Array<IOoDiagnosis>,
}

const SuccessStatusData = [
    {
        title: "진료 신청",
        status: OoDiagnosisStatus.request,
    },
    {
        title: "진료 접수 완료",
        status: OoDiagnosisStatus.accept,
    },
    {
        title: "진료 완료",
        status: OoDiagnosisStatus.diagnosisComplete,
    },
    {
        title: "약 배송 신청",
        status: OoDiagnosisStatus.decidedDeliveryType,
    },
    {
        title: "약제비 결제 대기",
        status: OoDiagnosisStatus.medicineDeliveryPaymentWait,
    },
    {
        title: "약제비 결제 완료",
        status: OoDiagnosisStatus.medicineDeliveryPaymentOk,
    },
    {
        title: "약 준비 완료",
        status: OoDiagnosisStatus.medicineDeliveryAccept,
    },
    {
        title: "약 배송 시작",
        status: OoDiagnosisStatus.medicineDeliveryStart,
    },
    {
        title: "약 수령 완료",
        status: OoDiagnosisStatus.medicineTakeOk,
    },
];

const FailStatusData = [
    {
        title: "진료 취소",
        status: OoDiagnosisStatus.operatorCancel,
    },
    {
        title: "진료비 환불",
        status: OoPaymentType.diagnosis
    },
    {
        title: "약제비 환불",
        status: OoPaymentType.medicine
    },
    {
        title: "배송비 환불",
        status: OoPaymentType.medicineDelivery
    },
    {
        title: "진료 삭제",
    },
];

export const DiagnosisDetailLayout = ({diagnosis, list}: Props) => {
    const {addModal} = useModalHook();
    const {refundDiagnosis, deleteDiagnosis} = useDiagnosisFunctionHook();
    const {changeStatusDiagnosis} = useDoctorFunctionHook();

    const onClickStatusButton = (e: any) => {
        e.preventDefault();
        addModal({
            type: 'noBtnModal',
            data: {
                title: "진료 상태 변경",
                contents:
                    <div>
                        <div>{diagnosis.patientName}의 진료 상태를 변경합니다.</div>
                        <StatusButtonsContainer>
                            {
                                SuccessStatusData.map((item, index) => (
                                    <Button key={index}
                                            type="button"
                                            onClick={onChangeStatus}
                                            id={`${item.status}`}
                                            value={`${item.title}`}>
                                        {item.title}
                                    </Button>
                                ))
                            }
                        </StatusButtonsContainer>
                        <CancelStatusButtonsContainer>
                            {
                                FailStatusData.map((item, index) => (
                                    <Button key={index}
                                            type="button"
                                            onClick={onChangeStatus}
                                            id={`${item.status}`}
                                            value={`${item.title}`}
                                            disabled={isDisabledStatus(item.title)}>
                                        {item.title}
                                    </Button>
                                ))
                            }
                        </CancelStatusButtonsContainer>
                    </div>,
            }
        });
    };

    const onChangeStatus = (e: any) => {
        e.preventDefault();
        if (_.isEqual(e.target.value, "진료 삭제")) {
            addModal({
                type: 'twoBtnModal',
                data: {
                    title: "진료 삭제",
                    contents:
                        <div>
                            <div>{diagnosis.patientName}님의 진료를 삭제 하시겠습니까?</div>
                            <div>사용자 앱의 내 진료, 알림에서도 완전히 삭제됩니다.</div>
                        </div>,
                    buttons: [
                        {
                            title: "취소"
                        },
                        {
                            title: "확인",
                            callback: (e) => deleteDiagnosis(diagnosis.id || "")
                        }
                    ],
                    goBack: false,
                }
            });
            return;
        } else if (_.isEqual(e.target.value, "진료비 환불")) {
            addModal({
                type: 'twoBtnModal',
                data: {
                    title: "진료비 자동 결제 환불 요청",
                    contents:
                        <div>
                            <div>진료비 자동 결제 환불 요청을 하시겠습니까?</div>
                            <div>이 과정은 되돌릴 수 없습니다.</div>
                            <div>취소 성공 시 상태가 "관리자 취소"로 변경됩니다.</div>
                        </div>,
                    buttons: [
                        {
                            title: "취소"
                        },
                        {
                            title: "진료비 환불",
                            callback: (e) => refundDiagnosis(diagnosis.diagnosisPaymentId || "", OoPaymentType.diagnosis)
                        }
                    ],
                    goBack: false,
                }
            });
            return;
        } else if (_.isEqual(e.target.value, "약제비 환불")) {
            addModal({
                type: 'twoBtnModal',
                data: {
                    title: "약제비 결제 환불 요청",
                    contents:
                        <div>
                            <div>약제비 결제 취소 요청을 하시겠습니까?</div>
                            <div>이 과정은 되돌릴 수 없습니다.</div>
                            <div>취소 성공 시 상태가 "관리자 취소"로 변경됩니다.</div>
                        </div>,
                    buttons: [
                        {
                            title: "취소"
                        },
                        {
                            title: "약제비 및 배송비 환불",
                            callback: (e) => refundDiagnosis(diagnosis.medicinePaymentId || "", OoPaymentType.medicine)
                        }
                    ],
                    goBack: false,
                }
            });
            return;
        } else if (_.isEqual(e.target.value, "배송비 환불")) {
            addModal({
                type: 'twoBtnModal',
                data: {
                    title: "배송비 결제 취소 요청",
                    contents:
                        <div>
                            <div>배송비 결제 취소 요청을 하시겠습니까?</div>
                            <div>이 과정은 되돌릴 수 없습니다.</div>
                        </div>,
                    buttons: [
                        {
                            title: "취소"
                        },
                        {
                            title: "배송비 환불",
                            callback: (e) => refundDiagnosis(diagnosis.medicineDeliveryPaymentId || "", OoPaymentType.medicineDelivery)
                        }
                    ],
                    goBack: false,
                }
            });
            return;
        }
        addModal({
            type: 'twoBtnModal',
            data: {
                title: "진료 상태 변경",
                contents:
                    <div>
                        <div>{diagnosis.patientName}님의 진료 상태를 {e.target.value} 으로 변경하시겠습니까?</div>
                    </div>,
                buttons: [
                    {
                        title: "취소"
                    },
                    {
                        title: "확인",
                        callback: (e) => changeStatusDiagnosis(diagnosis.id || "", _.toNumber(e.target.id), "")
                    }
                ],
                goBack: false,
            }
        });
    };

    const isDisabledStatus = (title: string) => {
        const predicate1 = (d: IOoDiagnosis) => _.gt(OoDiagnosisStatus.request, d.status);
        const predicate2 = (title: string) => _.isEqual(title, "진료비 환불") || _.isEqual(title, "약제비 환불") || _.isEqual(title, "배송비 환불");
        return predicate1(diagnosis) && predicate2(title);
    };

    return (
            <Wrapper>
                <TabContainer>
                    <ButtonContainer>
                        <Status color={`${getDiagnosisStatus(diagnosis).background}`}>
                            {getDiagnosisStatus(diagnosis).title}
                        </Status>
                        <ChangedStatusButton onClick={onClickStatusButton}>
                            상태 변경
                        </ChangedStatusButton>
                    </ButtonContainer>
                    <DiagnosisDetailContainer diagnosisData={diagnosis} list={list}/>
                </TabContainer>
            </Wrapper>
    );
};

const textColor = {
    none: "#000000",
    success: "#2176F6",
    fail: "#EC2C2C",
};

const borderColor = {
    default: "#C2C1BD",
};

const Wrapper = styled.div`
  width: 1024px;
`;

const TabContainer = styled.div`
  height: auto;
  width: 100%;
  background: #fff;
  border: 1px solid #c4c4c4;
  padding: 2%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 36px;
  font-weight: 700;
  font-size: 13px;
  background: ${(props) => props.color};
  border-radius: 8px;
  margin-left: 10px;

  ${(props) => {
    if (props.color === DiagnosisBackgroundColor.success) {
      return css`
        color: ${textColor.success};
        border: 1px solid ${textColor.success};
      `;
    } else if (props.color === DiagnosisBackgroundColor.fail) {
      return css`
        color: ${textColor.fail};
        border: 1px solid ${textColor.fail};
      `;
    } else {
      return css`
        color: ${textColor.none};
        border: 1px solid ${borderColor.default};
      `;
    }
  }}
}

`;

const ChangedStatusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 36px;
  font-weight: 700;
  font-size: 13px;
  background: #ffffff;
  border-radius: 8px;
  margin-left: 10px;
  border: 1px solid ${borderColor.default};
`;

const StatusButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  width: 100%;
  height: auto;

  button {
    width: 145px;
    height: 36px;
    font-weight: 700;
    font-size: 13px;
    border: 1px solid #2176f6;
    border-radius: 8px;
    color: #2176f6;
    background: #eff7ff;

    &:nth-child(2) {
      margin-right: 8px;
      margin-left: 8px;
      margin-bottom: 8px;
    }

    &:nth-child(5) {
      margin-right: 8px;
      margin-left: 8px;
      margin-bottom: 8px;
    }

    &:nth-child(8) {
      margin-right: 8px;
      margin-left: 8px;
    }

    &:hover {
      background: #eff7ff;
      border: 1px solid #2176f6;
      color: #2176f6;
    }
  }
`;

const CancelStatusButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;

  button {
    width: 145px;
    height: 36px;
    font-weight: 700;
    font-size: 13px;
    border: 1px solid #ec2c2c;
    border-radius: 8px;
    color: #ec2c2c;
    background: #fdefef;
    margin-top: 8px;

    &:nth-child(2) {
      margin-left: 8px;
      margin-right: 8px;
    }

    &:nth-child(5) {
      margin-left: 8px;
      margin-right: 8px;
    }

    &:hover {
      background: #fdefef;
      border: 1px solid #ec2c2c;
      color: #ec2c2c;
    }

    &:disabled {
      background: #FFFFFF;
      border: 1px solid #C2C1BD;
      color: #000000;
      opacity: unset;
    }
  }
`;
