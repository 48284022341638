import {usePharmacyFunctionHook} from "@hooks/pharmacy/usePharmacyFunctionHook";
import React, {useMemo} from "react";
import {useRecoilValue} from "recoil";
import {selectedUserState} from "@recoils/oauth";
import {CustomCard} from "@components/custom";
import _ from "lodash";
import {DetailContainer} from "@components/detailContainer";
import {
    BankInfo,
    PharmacyAdditionalInfo,
    PharmacyAlimTalk,
    PharmacyPrimaryInfo,
    PharmacyTargetHospital,
    PharmacyWorkHours
} from "@components/infoForm";

export const PharmacyInfo = () => {
    const {getPharmacyByUserId} = usePharmacyFunctionHook();
    const selectedUser = useRecoilValue(selectedUserState);

    const user = useMemo(() => {
        return selectedUser?.user.id ?? "";
    }, [selectedUser]);

    const pharmacyData = getPharmacyByUserId(user);
    return (
        <DetailContainer>
            <>
                {!_.isUndefined(pharmacyData) && (
                    <div>
                        <div>
                            <CustomCard buttonHidden={false}
                                        title="약국 기본 정보 [필수]"
                                        subTitle="프로필 이미지"
                                        children={<PharmacyPrimaryInfo data={pharmacyData}
                                                                       componentName="pharmacyPrimaryInfo"/>}
                                        component="pharmacyPrimaryInfo"
                            />
                            <CustomCard buttonHidden={false}
                                        title="약국 추가 정보 [필수]"
                                        children={<PharmacyAdditionalInfo data={pharmacyData}
                                                                          componentName="pharmacyAdditionalInfo"/>}
                                        component="pharmacyAdditionalInfo"
                            />
                            <CustomCard buttonHidden={false}
                                        title="알림톡 수신 전화번호 [필수]"
                                        children={<PharmacyAlimTalk data={pharmacyData}
                                                                    componentName="pharmacyAlimTalk"/>}
                                        component="pharmacyAlimTalk"
                            />
                            <CustomCard buttonHidden={false}
                                        title="지정 병원 [필수]"
                                        children={<PharmacyTargetHospital data={pharmacyData}
                                                                          componentName="pharmacyTargetHospital"/>}
                                        component="pharmacyTargetHospital"
                            />

                            <CustomCard buttonHidden={false}
                                        title="정산 은행 정보 [필수]"
                                        children={<BankInfo data={pharmacyData}
                                                            target="pharmacy"
                                                            componentName="pharmacyBankInfo"/>}
                                        component="pharmacyBankInfo"
                            />
                            <CustomCard buttonHidden={false}
                                        title="약국 운영시간"
                                        children={<PharmacyWorkHours data={pharmacyData}
                                                                     componentName="pharmacyWorkHours"/>}
                                        component="pharmacyWorkHours"
                            />
                        </div>
                    </div>
                )}
            </>
        </DetailContainer>
    );
};

