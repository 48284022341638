import React, {ChangeEvent, useEffect, useState} from "react";
import {useHospitalInfoHook} from "@components/infoForm";
import styled from "styled-components";
import {CustomInputText} from "@components/custom";
import {ImageUpload} from "@components/imageUpload";
import _ from "lodash";
import {HospitalType, IOoHospital} from "@api/models";
import {OoParamHospitalUpdate} from "@api/params";
import classNames from "classnames";
import  SelectStyles  from '@styles/common/Select.module.scss';

interface Props {
    data: IOoHospital,
    componentName: string,
}

export const HospitalInfo = ({data, componentName}: Props) => {
    const sx = classNames.bind(SelectStyles);
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const {
        editStatus,
        makeKeywordList,
        color,
        setColor,
        items,
        setItems,
        isReset,
        handleChange,
        updateHospital,
        onChangePicture,
        initialData,
    } = useHospitalInfoHook({data, componentName});

    const onChangeReferrerList = (e: ChangeEvent<HTMLInputElement>) => setItems({...items, referrerList: makeKeywordList(e.target.value)});

    const onSelectType = (e: ChangeEvent<HTMLSelectElement>) => {   
        const {name, value} = e.target;
        
        if(name === 'isMtmHospital'){
            if(e.target.value === '0'){
                setItems({...items, isMtmHospital:true});
            }else{
                setItems({...items, isMtmHospital:false});
            }
        }else if(name === 'useOrderAlimtalk'){
            
            if(Number(e.target.value) === 0){
                setItems({...items, useOrderAlimtalk:true});
            }else{
                setItems({...items, useOrderAlimtalk:false});
            }
        }
        else{
            setItems({...items, [name]:Number(value)});
        }
    };

    const handleClickCopyClipBoard = (id:string) => {
        navigator.clipboard
        .writeText(id)
        .then(() => {
            setShowCopyMessage(true);
            setTimeout(()=>{
                setShowCopyMessage(false);
            },1000);
        })
        .catch((err) => {
            console.error("Error in copying text: ", err);
          });
        
    }

    useEffect(() => {
        isReset();
    }, []);

    // 편집 모드 일때, border 색상 활성화
    useEffect(() => {
        if (editStatus.isEdit) {
            setColor("blue");
        } else {
            setColor("white");
            setItems({...initialData});
        }
    }, [editStatus.isEdit]);

    // 전송 모드 일때, 쿼리 trigger
    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamHospitalUpdate();
            param.id = data.id || "";
            param.zipCode = _.trim(items.zipCode);
            param.address = items.address;
            param.regionKeywordList = makeKeywordList(items.regionKeywordList);
            param.logo = items.logo;
            param.isMtmHospital = items.isMtmHospital;
            param.type = items.type;
            param.referrerList = items.referrerList;
            param.useOrderAlimtalk = items.useOrderAlimtalk;
            updateHospital(param);
        }
    }, [editStatus.isSubmit]);

    useEffect(() => {
        setItems({...initialData});
    }, [data]);

    console.log(items);
    

    return (
        <Wrapper>
            <div>
                <div>
                    <Table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>병원 이름</td>
                            <td>
                                <CustomInputText value={data.name}
                                                 size="middle"
                                                 color={"white"}
                                                 placeholder="[필수]"
                                                 disabled={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td>전화번호</td>
                            <td>
                                <CustomInputText value={data.tel}
                                                 color={"white"}
                                                 size="middle"
                                                 placeholder="- 없이 입력 [필수]"
                                                 disabled={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td>이메일</td>
                            <td>
                                <CustomInputText value={data.email}
                                                 color={"white"}
                                                 size="middle"
                                                 placeholder="아이디@doctoron.kr [필수]"
                                                 disabled={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td>우편번호</td>
                            <td>
                                <CustomInputText value={items.zipCode}
                                                 color={"white"}
                                                 name="zipCode"
                                                 size="middle"
                                                 placeholder="5자리 숫자 [필수]"
                                                 disabled={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td>주소</td>
                            <td>
                                <CustomInputText value={items.address}
                                                 color={"white"}
                                                 name="address"
                                                 size="middle"
                                                 placeholder="[필수]"
                                                 onChanged={handleChange}
                                                 disabled={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td>지역 검색어</td>
                            <td>
                                <CustomInputText value={items.regionKeywordList}
                                                 color={editStatus.isEdit ? 'blue' : 'white'}
                                                 name="regionKeywordList"
                                                 size="middle"
                                                 placeholder=",로 구분하여 복수 입력 가능 [필수]"
                                                 onChanged={handleChange}
                                                 disabled={editStatus.isEdit}/>
                            </td>
                        </tr>
                        <tr>
                            <td>병원 코드</td>
                            <td>
                                <CustomInputText value={data.code}
                                                 color={"white"}
                                                 size="middle"
                                                 placeholder="아이디@doctoron.kr [필수]"
                                                 disabled={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td>MTM 병원</td>
                           {editStatus.isEdit ? <td>
                                <select className={sx("select", "middle", "blue")}
                                        onChange={onSelectType}
                                        name="isMtmHospital"
                                        value={items.isMtmHospital ? 0 : 1}>
                                    <option value={0}>Y</option>
                                    <option value={1}>N</option>
                                </select>
                            </td> :  <td>
                                <CustomInputText value={items.isMtmHospital ? "Y" : "N"}
                                                 color={"white"}
                                                 size="middle"
                                                 disabled={false}/>
                            </td>}
                        </tr>
                        <tr>
                            <td>병원 타입</td>
                            {editStatus.isEdit ? <td>
                                <select className={sx("select", "middle", "blue")}
                                        onChange={onSelectType}
                                        name="type"
                                        value={items.type}>
                                    <option value={HospitalType.normal}>양방</option>
                                    <option value={HospitalType.orientalMedicine}>한방</option>
                                    <option value={HospitalType.b2b}>B2B</option>
                                    <option value={HospitalType.fitness}>피트니스</option>
                                </select>
                            </td> :
                            <td>
                                <CustomInputText value={getHospitalTypeToStr(data.type!)}
                                                 color={"white"}
                                                 size="middle"
                                                 disabled={false}/>
                            </td>
                            }
                        </tr>
                        <tr>
                            <td>debug Id</td>
                            <td onClick={()=>handleClickCopyClipBoard(data.id!)}>
                                <CopyBoardContainer>
                                    <span>{data.id}</span>
                                    {showCopyMessage && (
                                        <CopyMessageContainer>Copied to clipboard</CopyMessageContainer>
                                        )}
                                </CopyBoardContainer>
                            </td>
                        </tr>
                        <tr>
                            <td>추천인</td>
                            <td>
                                <CustomInputText value={items.referrerList!.join(", ")}
                                                 color={editStatus.isEdit ? 'blue' : 'white'}
                                                 size="middle"
                                                 disabled={editStatus.isEdit}
                                                 onChanged={onChangeReferrerList}
                                                 />
                            </td>
                        </tr>
                        <tr>
                            <td>알림톡 사용여부</td>
                           {editStatus.isEdit ? <td>
                                <select className={sx("select", "middle", "blue")}
                                        onChange={onSelectType}
                                        name="useOrderAlimtalk"
                                        value={items.useOrderAlimtalk ? 0 : 1}>
                                            <option value={0}>Y</option>
                                            <option value={1}>N</option>
                                </select>
                            </td> :  <td>
                                <CustomInputText value={items.useOrderAlimtalk ? "Y" : "N"}
                                                 color={"white"}
                                                 size="middle"
                                                 disabled={false}/>
                            </td>}
                        </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="image-container">
                    <ImageUpload target="hospital"
                                 picturesSrc={data.logo || ""}
                                 editStatus={editStatus}
                                 onChanged={onChangePicture}
                                 hiddenRemoveButton={true}/>
                </div>
            </div>
        </Wrapper>
    );
};

function getHospitalTypeToStr(type: number): string {
    switch (type) {
        case 0:
            return "양방";
        case 1:
            return "한방"
        case 2:
            return "B2B"
        case 3:
            return "Fitness"
        default:
            return "-";
    }
}

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }

  .image-container {
    padding-left: 50px;
  }
`;

const Table = styled.table`
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tbody tr {
    border-bottom: 1px solid #C8D6F8;
    height: 50px;
  }

  tbody tr td:nth-child(2n-1) {
    background: #F4F6FB;
    width: 100px;
    text-align: center;
    font-weight: 700;
  }

  tbody tr td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;
const CopyMessageContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 0.5em;
  overflow-y: scroll;
  color: #2176f6;
`;
const CopyBoardContainer = styled.div`
    display: flex;
    column-gap: 20px;
`
