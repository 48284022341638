import {IOoDiagnosis} from "./diagnosis";


interface PatientInfoStatus {
    id?: string,
    patientId: string,
    patientName: string,
    patientGender: string,
    patientTel: string,
    patientIdentityNumber: string,
    patientRelation: string,
    symptom: string,
    height?: number,
    weight?: number,
    timestamp: number,
    prescription?: string[],
    familyUserId?: string,
    familyKey?: number,
    fullAddress?: string,
    deliveryAddress?:string,
    isTermAgreed: boolean,

}

export function getPatientInfo(diagnosis: IOoDiagnosis): PatientInfoStatus {

    return {
        id: diagnosis.id || '',
        patientId: diagnosis.patientId,
        patientName: diagnosis.patientName,
        patientGender: diagnosis.patientGender,
        patientTel: diagnosis.patientTel,
        patientIdentityNumber: diagnosis.patientIdentityNumber,
        patientRelation: diagnosis.patientRelation,
        symptom: diagnosis.symptom,
        height: diagnosis.height || undefined,
        weight: diagnosis.weight || undefined,
        timestamp: diagnosis.timestamp,
        prescription: diagnosis.prescription,
        familyUserId: diagnosis.familyUserId,
        familyKey: diagnosis.familyKey,
        fullAddress: `${diagnosis.deliveryAddress}${diagnosis.deliveryAddressDetail}`,
        deliveryAddress : diagnosis.deliveryAddress,
        isTermAgreed: diagnosis.isTermAgreed,
    }
}

export type {PatientInfoStatus};