import React, {useEffect} from "react";
import {DoctorVideoInfo} from "../../../../interfaces";
import {CustomInputText} from "@components/custom";
import styled from "styled-components";
import {useVideoSettingHook} from "@components/registerForm";

interface Props {
    onChanged: (data: DoctorVideoInfo) => void,
}

export const DoctorRegisterVideo = ({onChanged}: Props) => {
    const {
        color,
        setColor,
        items,
        setItems,
        onChangeVideoLink,
        onChangeVideoId,
        onChangeVideoPassword,
    } = useVideoSettingHook();

    useEffect(() => {
        onChanged(items);
    }, [items]);

    useEffect(() => {
        items.isVideoDiagnosis ? setColor("blue") : setColor("grey");
    }, [items.isVideoDiagnosis]);

    return (
        <Wrapper>
            {
                items.isVideoDiagnosis ?
                    <CheckboxContainer>
                        <div>
                            <input type="radio"
                                   id="noVideo"
                                   name="zoom"
                                   checked={false}
                                   onChange={() => setItems({...items, isVideoDiagnosis: false})}/>
                            <label htmlFor="noVideo"/>
                            미제공
                        </div>
                        <div>
                            <input type="radio"
                                   name="zoom"
                                   id="video"
                                   checked={true}
                                   onChange={() => setItems({...items, isVideoDiagnosis: true})}/>
                            <label htmlFor="video"/>
                            제공
                        </div>
                    </CheckboxContainer>
                    :
                    <CheckboxContainer>
                        <div>
                            <input type="radio"
                                   name="zoom"
                                   id="noVideo"
                                   checked={true}
                                   onChange={() => setItems({...items, isVideoDiagnosis: true})}/>
                            <label htmlFor="noVideo"/>
                            미제공
                        </div>
                        <div>
                            <input type="radio"
                                   name="zoom"
                                   id="video"
                                   checked={false}
                                   onChange={() => setItems({...items, isVideoDiagnosis: true})}/>
                            <label htmlFor="video"/>
                            제공
                        </div>
                    </CheckboxContainer>
            }
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>
                        <b>줌링크</b>
                    </td>
                    <td>
                        <CustomInputText color={color}
                                         size="xLarge"
                                         disabled={items.isVideoDiagnosis}
                                         value={items.videoLink}
                                         onChanged={onChangeVideoLink}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>회의ID</b>
                    </td>
                    <td>
                        <CustomInputText color={color}
                                         size="xLarge"
                                         disabled={items.isVideoDiagnosis}
                                         value={items.videoId}
                                         onChanged={onChangeVideoId}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>암호</b>
                    </td>
                    <td>
                        <CustomInputText color={color}
                                         size="xLarge"
                                         disabled={items.isVideoDiagnosis}
                                         value={items.videoPassword}
                                         onChanged={onChangeVideoPassword}/>
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    )
};


const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;


const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  width: 15%;
  justify-content: space-between;

  & > div:nth-child(1) {
    font-weight: 700;
    font-size: 13px;
    display: flex;
    align-items: center;

    input[id="noVideo"] {
      display: none;
    }

    label::before {
      background: #EEEEEE;
      content: '✓';
      color: #FFFFFF;
      margin-right: 7px;
      cursor: pointer;
    }

    input[id="noVideo"]:checked + label::before {
      background: #6E8ED1;
      color: #FFFFFF;
      content: '✓';
      margin-right: 7px;
    }
  }

  & > div:nth-child(2) {
    font-weight: 700;
    font-size: 13px;
    display: flex;
    align-items: center;

    input[id="video"] {
      display: none;
    }


    label::before {
      background: #EEEEEE;
      content: '✓';
      color: #FFFFFF;
      margin-right: 7px;
      cursor: pointer;
    }

    input[id="video"]:checked + label::before {
      background: #6E8ED1;
      color: #FFFFFF;
      content: '✓';
      margin-right: 7px;
    }
  }
`;
