import {AxiosInstance} from "axios";
import {OoParamId, OoParamOAuth} from "../params";
import {IOoDataResponse, IOoResponse} from "../reponses";
import {endPoint} from "./base";
import {IOoAccount} from "../models/account/account";
import _ from "lodash";
import {OoParamAccountList, OoParamAccountUpdate, OoParamName} from "@api/params";

class DoctorOnAccountApi {
    constructor(private readonly axiosInstance: AxiosInstance) {
    }

    async read(param: OoParamId): Promise<IOoDataResponse<IOoAccount>> {
        let url = endPoint.account.read;
        if (!_.isEmpty(param.getPathParams())) {
            url = `$url/${param.getPathParams()}`;
        }
        return await this.axiosInstance.get<IOoDataResponse<IOoAccount>>(url);
    }

    async list(param: OoParamAccountList): Promise<IOoDataResponse<IOoAccount[]>> {
        const url = `${endPoint.account.list}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoAccount[]>>(url);
    }

    async search(param: OoParamName): Promise<IOoDataResponse<IOoAccount[]>> {
        const url = `${endPoint.account.search}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoAccount[]>>(url);
    }

    async update(param: OoParamAccountUpdate): Promise<IOoDataResponse<IOoAccount>> {
        return await this.axiosInstance.post<IOoDataResponse<IOoAccount>>(endPoint.account.update, param);
    }

    async signOut(param: OoParamOAuth): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.account.signOut);
    }
}

export {
    DoctorOnAccountApi
};
