import {useRecoilState, useResetRecoilState, useSetRecoilState} from "recoil";
import {dailyDiagnosisSelectedState, searchListState, selectedFilterState} from "../../../../recoils";
import {useState} from "react";
import _ from "lodash";
import {useOperatorSearchQuery} from "@queries/search";
import {useDoctorSearchPatientQuery} from "@queries/doctor";
import {usePharmacyPatientSearchQuery} from "@queries/pharmacy";

interface Props {
    target: string,
    id?: string,
}

export const useSearchSettingHook = ({target, id}: Props) => {
    const resetDailyDiagnosisSelectedDate = useResetRecoilState(dailyDiagnosisSelectedState(target));
    const [buttonStatus, setButtonStatus] = useState({
        cancel: false,
        search: false,
    });

    const [searchQueryTrigger, setSearchQueryTrigger] = useState({
        operatorQuery: false,
        doctorQuery: false,
        pharmacyQuery: false,
    });

    const {operatorQuery, doctorQuery, pharmacyQuery} = searchQueryTrigger;

    const [searchList, setSearchList] = useRecoilState(searchListState(target));
    const resetSearchList = useResetRecoilState(searchListState(target));

    const setSelectNumber = useSetRecoilState(selectedFilterState);

    useOperatorSearchQuery({
        searchName: searchList.userName ?? "",
        searchQueryTrigger: operatorQuery,
        target: target,
    });

    useDoctorSearchPatientQuery({
        searchName: searchList.userName ?? "",
        doctorId: id || "",
        searchQueryTrigger: doctorQuery,
        target: target,
    });

    usePharmacyPatientSearchQuery({
        searchName: searchList.userName ?? "",
        pharmacyId: id || "",
        searchQueryTrigger: pharmacyQuery,
        target: target,
    });

    const filteredTarget = (isTrue: boolean, isReset?: boolean) => {
        if (isReset) {
            setSearchQueryTrigger({
                operatorQuery: false,
                doctorQuery: false,
                pharmacyQuery: false,
            });
            return;
        }

        if (_.isEqual(target, 'operator')) {
            setSearchQueryTrigger({...searchQueryTrigger, operatorQuery: isTrue});
        } else if (_.isEqual(target, 'pharmacy')) {
            setSearchQueryTrigger({...searchQueryTrigger, pharmacyQuery: isTrue});
        } else {
            setSearchQueryTrigger({...searchQueryTrigger, doctorQuery: isTrue});
        }
    };

    const onChangeSearchName = (value: string) => {
        if (_.isEmpty(value)) {
            resetSearchList();
            return;
        }
        setSearchList({...searchList, userName: value});
        filteredTarget(false);
    };

    const onChangeDeleteButton = (event: boolean) => {
        setButtonStatus({...buttonStatus, cancel: event});
        filteredTarget(false);
    };

    const onClickSearchButton = (event: boolean) => {
        setButtonStatus({...buttonStatus, search: event, cancel: event});
        resetDailyDiagnosisSelectedDate();
        filteredTarget(true);
    };


    return {
        searchList,
        buttonStatus,
        setButtonStatus,
        setSearchList,
        setSelectNumber,
        setSearchQueryTrigger,
        onClickSearchButton,
        onChangeDeleteButton,
        onChangeSearchName,
        filteredTarget,
        resetSearchList,
    }
}