import {useRecoilValue} from "recoil";
import {useState} from "react";
import {IOoDoctor} from "@models/doctor";
import {editSelectorFamily} from "@recoils/status";
import {useDoctorFunctionHook} from "@hooks/doctor";

export const useDoctorServiceSettingHook = (data: IOoDoctor, componentName: string) => {
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const {updateDoctor} = useDoctorFunctionHook();
    const [items, setItems] = useState({
        departments: data.departments,
        theme: data.theme,
    });

    const onChangeDepartments = (data: Array<string>) => setItems({...items, departments: data});
    const onChangeTheme = (data: Array<string>) => setItems({...items, theme: data});

    return {
        editStatus,
        items,
        setItems,
        onChangeDepartments,
        onChangeTheme,
        updateDoctor,
    };
};
