import { useSuspenseHook } from "../../hooks";
import { useQuery } from "react-query";
import { DoctorOnAPI, isRequestSucceed } from "../../api";
import _ from "lodash";

interface Props {
  mobile: string;
  queryTrigger?: boolean;
}

export const useGetCsMemoByMobileQuery = ({ mobile, queryTrigger }: Props) => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
  return useQuery(
    "getCsMemoByMobile",
    async () => {
      try {
        setLoading();
        const response = await DoctorOnAPI.shared.unospay.getCsMemoByMobile(
          mobile
        );
        setStopLoading();
        if (isRequestSucceed(response)) {
          return response.data;
        }
        return undefined;
      } catch (e: any) {
        setErrorStatus(e);
      }
    },
    {
      enabled: _.isUndefined(queryTrigger) ? !!mobile : queryTrigger!,
      retry: true,
      refetchOnWindowFocus: false,
      refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
    }
  );
};
