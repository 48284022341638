import {AbstractOoParam} from "../base";

class OoParamDiagnosisStatus extends AbstractOoParam {
    public readonly id: string;
    public readonly status: number;
    public readonly comment?: string;

    constructor(id: string, status: number, comment: string) {
        super();
        this.id = id;
        this.status = status;
        this.comment = comment;
    }
}

export {
    OoParamDiagnosisStatus
}