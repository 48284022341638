import _ from "lodash";
import React, {useEffect} from "react";
import classNames from "classnames/bind";
import timeTableStyle from "../../../../styles/common/TimeTable.module.scss";
import {PharmacyWorkLunchHours} from "./WorkLunchHours";
import {usePharmacyWorkHoursHook} from "../hooks/usePharmacyWorkHoursHook";
import styled from "styled-components";
import {WorkHourRow} from "../../../workHourRow";
import {IOoPharmacy} from "@models/pharmacy";

interface Props {
    data: IOoPharmacy,
    componentName: string,
}

export const PharmacyWorkHours = ({data, componentName}: Props) => {
    const tx = classNames.bind(timeTableStyle);
    const {
        editStatus,
        isReset,
        error,
        pharmacyParams,
        setPharmacyParams,
        lunchTime,
        setLunchTime,
        changeLunchTime,
        onDefaultTimeChanged,
        onLunchTimeChanged,
        result,
        originDayList,
        defaultDayList,
        defaultLunchTime,
        updateTimePharmacy,
    } = usePharmacyWorkHoursHook(data, componentName);

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        try {
            // 1. data.pharmacyHours가 없을 때,
            if (_.isEmpty(data.pharmacyHours) || _.isUndefined(data.pharmacyHours)) {
                setPharmacyParams(defaultDayList);
                setLunchTime(defaultLunchTime);
                // 2. data.pharmacyHours length가 8일 때, 즉 점심시간이 없을 때
            } else if (_.isEqual(data.pharmacyHours?.length, 8)) {
                setPharmacyParams(originDayList);
                setLunchTime(defaultLunchTime);
            } else {
                // 3. data.pharmacyHours length가 9일 때, 즉 점심시간이 있을 때
                setPharmacyParams(result);
            }
        } catch (e) {
            console.log(e, "error");
        }
    }, [data, editStatus.isEdit]);

    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) return;
        if (window.confirm("수정하시겠습니까?")) {
            if (_.gte(pharmacyParams.length, 9)) {
                updateTimePharmacy(
                    data.id ?? "",
                    _.sortBy(pharmacyParams, "dayOfWeek")
                );
            }

            if (_.lte(pharmacyParams.length, 8)) {
                const copied = _.cloneDeep(pharmacyParams);
                if (changeLunchTime) {
                    copied.push(changeLunchTime);
                    updateTimePharmacy(data.id ?? "", _.sortBy(copied, "dayOfWeek"));
                }
            }
        }
        return;
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            {error ? <div style={{color: "red", textAlign: "center"}}> 올바르지 않은 선택입니다.</div> : ""}
            <div className={tx('time-table-head')}>
                <div>요일</div>
                <div>진료시작</div>
                <div>진료종료</div>
                <div>휴무일여부</div>
            </div>
            {
                pharmacyParams.map((item, index) => (
                    <WorkHourRow key={index}
                                 dayOfWeekNumber={item.dayOfWeek}
                                 hours={item.hours}
                                 breakHoursList={item.breakHoursList}
                                 isOff={item.isOff}
                                 onChange={onDefaultTimeChanged}
                                 isEdit={editStatus.isEdit}
                                 isCancel={editStatus.isCancel}
                                 isSubmit={editStatus.isSubmit}

                    />
                ))
            }
            {
                (_.lte(pharmacyParams.length, 8) || _.isEmpty(pharmacyParams)) &&
                <PharmacyWorkLunchHours lunchHourArr={lunchTime}
                                        onChange={onLunchTimeChanged}
                                        isEdit={editStatus.isEdit}/>
            }
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 16px;
`;