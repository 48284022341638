import _ from "lodash";
import {IOoUnospayDelivery, OoOrderPlatformType} from "../unospay";

export const useOrderPlatformStatus = (platform: IOoUnospayDelivery) => {
  // 키오스크
  const isKiosk = (): boolean => {
    const status = platform.orderPlatform;
    return _.isEqual(status, OoOrderPlatformType.kiosk);
  };

  // 자사몰
  const isMtmMall = (): boolean => {
    const status = platform.orderPlatform;
    return _.isEqual(status, OoOrderPlatformType.mtmMall);
  };

  // 전화주문
  const isTelephone = (): boolean => {
    const status = platform.orderPlatform;
    return _.isEqual(status, OoOrderPlatformType.telephone);
  };

  // 테스트
  const isTest = (): boolean => {
    const status = platform.orderPlatform;
    return _.isEqual(status, OoOrderPlatformType.test);
  };

  const filteredOrderPlatformStatus = () => {
    if (isKiosk()) {
      return `키오스크`;
    } else if (isMtmMall()) {
      return `MTM 몰`;
    } else if (isTelephone()) {
      return `전화주문`;
    } else if (isTest()) {
      return `TEST`;
    } else {
      return `키오스크`;
    }
  };

  return {
    isKiosk,
    isMtmMall,
    isTelephone,
    filteredOrderPlatformStatus
  };
};

// 전체 주문 내역
export function filterOrderPlatformTotalList(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
  return filterOfflineTotal(list);
}

// 키오스크 주문
export function filterKiosk(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
  return _.filter(list, (item) => _.isEqual(item.orderPlatform, OoOrderPlatformType.kiosk));
}

// MTM Mall 주문
export function filterMtmMall(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
  return _.filter(list, (item) => _.isEqual(item.orderPlatform, OoOrderPlatformType.mtmMall));
}

// 전화 주문
export function filterPhone(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
  return _.filter(list, (item) => _.isEqual(item.orderPlatform, OoOrderPlatformType.telephone));
}

// 테스트 주문
export function filterTest(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
  return _.filter(list, (item) => _.isEqual(item.orderPlatform, OoOrderPlatformType.test));
}

// 테스트 제외
export function filterNoTest(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
  return _.filter(list, (item) => !_.isEqual(item.orderPlatform, OoOrderPlatformType.test));
}


export function filterOfflineTotal(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
  return _.filter(list, (item) => !_.isEqual(item.orderPlatform, OoOrderPlatformType.cafe24Offline));
}