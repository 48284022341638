import {ModalOverlay} from "@components/modal";
import {CloseButton} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {IOoDiagnosis} from "@api/models";
import _ from "lodash";
import {formatDateYYYYMDHHmm, formatDateYyyyMMDHHmmss} from "@hooks/format";
import styled from "styled-components";


interface Props {
    visible: boolean,
    unVisible: (e: boolean) => void,
    id: string,
    list: Array<IOoDiagnosis>,
}

export const DiagnosisConfirmModal = ({visible, unVisible, id, list}: Props) => {
    const target = list.filter((item) => _.isEqual(item.id, id));

    const [show, setShow] = useState<boolean>(visible);
    const onClickCloseButton = () => {
        setShow(false);
    };

    useEffect(() => {
        unVisible(show);
    }, [show]);

    useEffect(() => {
        setShow(visible);
    }, [visible]);


    return (
        <ModalOverlay visible={show}>
            <Wrapper>
                <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                    <div style={{fontWeight: "700", fontSize: "20px"}}>진료 접수 확인</div>
                    <div>
                        <CloseButton style={{width: "14px", height: "14px", color: "#949390"}}
                                     onClick={onClickCloseButton}>
                        </CloseButton>
                    </div>
                </div>
                {
                    target.map((item) => (
                        <div style={{fontWeight: "500", fontSize: "13px", paddingTop: "20px"}} key={item.id}>
                            <div>
                                접수 번호 : {formatDateYyyyMMDHHmmss(item.timestamp)}
                            </div>
                            <div>
                                접수 시각 : {formatDateYYYYMDHHmm(item.timestamp)}
                            </div>
                            <div>
                                이름 : {item.patientName}
                            </div>
                        </div>
                    ))
                }
                <Container>
                    <div> 예샹되는 진료 대기 시간을 선택해주세요.</div>
                    <ButtonContainer>
                        <CustomButton>5분 이내</CustomButton>
                        <CustomButton>10분 이내</CustomButton>
                        <CustomButton>20분 이내</CustomButton>
                        <CustomButton>30분 이내</CustomButton>
                        <CustomButton>1시간 이내</CustomButton>
                        <CustomButton>2시간 이내</CustomButton>
                    </ButtonContainer>
                </Container>
                <Container>
                    <div> 환자에게 보낼 전달 사항을 입력해 주세요. (입력하지 않는 경우 기본 메시지만 전달됩니다.)</div>
                    <div>
                        <CustomInput type="text" placeholder="예)병원사정으로 해당 일시 예약이 불가합니다."/>
                    </div>
                </Container>
                <div style={{width: "100%", paddingTop: "10px", display: "flex", justifyContent: "space-between"}}>
                    <button style={{
                        width: "48%",
                        height: "60px",
                        background: "#CF5D5D",
                        borderRadius: "8px",
                        border: "none",
                        color: "#FFFFFF",
                        fontWeight: "700",
                        fontSize: "16px",
                    }}>취소
                    </button>
                    <button style={{
                        width: "48%",
                        height: "60px",
                        background: "#6E8ED1",
                        borderRadius: "8px",
                        border: "none",
                        color: "#FFFFFF",
                        fontWeight: "700",
                        fontSize: "16px",
                    }}>확인
                    </button>
                </div>
            </Wrapper>
        </ModalOverlay>
    )
}

const Wrapper = styled.div`
  width: 550px;
  height: auto;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 3%;
`;

const Container = styled.div`
  font-weight: 700;
  font-size: 12px;
  padding-top: 20px;
`;

const ButtonContainer = styled.div`
  font-weight: 500;
  font-size: 12px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
`;

const CustomButton = styled.button`
  width: 70px;
  height: 24px;
  background: #FFFFFF;
  border: 1px solid #C2C1BD;
  border-radius: 4px;
`;

const CustomInput = styled.input`
  padding: 1%;
  border: 1px solid #CBCBCB;
  width: 450px;
  height: 47px;
  border-radius: 8px;
`;