import _ from "lodash";
import React from "react";
import {pdfjs} from "react-pdf";
import {ConfirmModal} from "@components/modal";
import styled from "styled-components";
import {TabButton} from "@components/custom";
import {usePharmacyDetailLayoutSettingHook} from "./hooks/usePharmacyDetailLayoutSettingHook";
import {PharmacyDetailContent} from "./components/PharmacyDetailContent";

// workerSrc 정의 하지 않으면 pdf 보여지지 않습니다.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PharmacyDiagnosisDetail = () => {
    const {
        modalVisible,
        diagnosisList,
        medicinePrice,
        onChangedPayStatus,
        selectedValue,
        onChanged,
        ownerData,
        targetData,
        onChangedMedicinePayPrice,
    } = usePharmacyDetailLayoutSettingHook();

    return (
        <>
            <ConfirmModal modalVisible={modalVisible}
                          diagnosisList={diagnosisList}
                          medicinePrice={medicinePrice}
                          onChangedStatus={onChangedPayStatus}/>
            <Wrapper>
                <PatientContainer>
                    <TabButton list={diagnosisList} radioValue={selectedValue} onChanged={onChanged}/>
                </PatientContainer>
                {
                    !_.isUndefined(targetData) ?
                        <PharmacyDetailContent diagnosisList={diagnosisList}
                                               diagnosisData={targetData}
                                               prescriptionData={medicinePrice}
                                               onChangedPrescription={onChangedMedicinePayPrice}/>
                        :
                        <PharmacyDetailContent diagnosisList={diagnosisList}
                                               diagnosisData={ownerData}
                                               prescriptionData={medicinePrice}
                                               onChangedPrescription={onChangedMedicinePayPrice}/>
                }
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
  top: 150px;
  position: relative;
  height: max-content;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PatientContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;