import {atom} from "recoil";
import {IOoDiagnosis} from "@models/diagnosis";


const pharmacyReceiveRtdbState = atom<Array<IOoDiagnosis>>({
    key: 'pharmacyReceiveRtdbState',
    default: [],
});

export {
    pharmacyReceiveRtdbState
};
