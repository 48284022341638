import { deliveryProcessingStatus } from "@api/models";
import { CancelButtons } from "@components/diagnosisForm/deliveryInfo/cancelButtons";
import { ModalOverlay } from "@components/modal";
import { useUnospayFunctionHook } from "@hooks/unospay";
import { useUnosOrderDetailPageSettingHook } from "pages/Operator/UnospayOrderDetailPage/hooks/useUnosOrderDetailPageSettingHook";
import React, { ChangeEvent, useState } from 'react'
import { SetterOrUpdater } from "recoil";
import styled from "styled-components";
interface Props {
    modalVisible: boolean;
    title: string;
    cancelButtonName:string;
    submitButtonName:string;
    contents:string;
    onChangedVisible:SetterOrUpdater<boolean>
  }
const OrderChangeStatusModal = ({
  modalVisible,
  title,
  cancelButtonName,
  submitButtonName,
  contents,
  onChangedVisible,
}:Props) => {
  const {data} = useUnosOrderDetailPageSettingHook();
  const [status , setStatus] = useState<number>(0)
  const { changeDeliveryStatus } = useUnospayFunctionHook(data.id || "");
  const handleClickCancel = () => {
    onChangedVisible(prev => !prev)
  }

  const deliveryStatusData = deliveryProcessingStatus(data);

  const handleSelectFilter = (e:ChangeEvent<HTMLSelectElement>) => {
    const {value} = e.target;
    setStatus(Number(value));
  }

  const handleSubmit = () => {
    if(data.id){
      changeDeliveryStatus({id:data.id,status:status})
      onChangedVisible(prev => !prev)
    }
  }

  return (
    <ModalOverlay visible={modalVisible}>
      <Wrapper>
        <Contents>
          <div className="contentsTitle">{title}</div>
          <SelectContainer>
            <select
              className="select"
              onChange={handleSelectFilter}
            >
              <option value={0} selected={deliveryStatusData.title === '배송정보 미입력' ? true : false}>배송정보 미입력</option>
              <option value={1} selected={deliveryStatusData.title === '배송정보 입력 완료' ? true : false}>배송정보 입력 완료</option>
            </select>
          </SelectContainer>
        <ButtonContainer>
          <CancelButton
            type="button"
            onClick={handleClickCancel}
            >
            {cancelButtonName}
          </CancelButton>
          <SubmitButton type="button" onClick={handleSubmit}>
            {submitButtonName}
          </SubmitButton>
        </ButtonContainer>
        </Contents>
      </Wrapper>
    </ModalOverlay>
  )
}

export default OrderChangeStatusModal;

const Wrapper = styled.div`
  width: 556px;
  height: 372px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Contents = styled.div`
  width: 100%;
  height: 32px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

  & .contentsTitle {
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 36px;
  }

  & .contents {
    width: 100%;
    color: #000;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    margin-top: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SelectContainer = styled.div`
  width: 100%;
  height: 52px;
  flex-shrink: 0;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 16px;
  .select {
    border-radius: 8px;
    border: 0.5px solid #c2c1bd;
    width: 240px;
    height: 52px;
    padding: 5px;
  }
`;

const CancelButton = styled.button`
  width: 222px;
  height: 60px;
  background: #cf5d5d;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: none;
  margin-right: 10px;
`;

const SubmitButton = styled.button`
  width: 222px;
  height: 60px;
  background: #6e8ed1;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: none;
`;