import {AbstractOoParam} from "../base";
import {
  PharmacyAdditionalInfo,
  PharmacyAlimTalkInfo,
  PharmacyBankInfo,
  PharmacyDelivery,
  PharmacyDeliveryRegion,
  PharmacyHours,
  PharmacyInfo,
  PharmacyTargetHospital,
} from "interfaces";
import {IOoWorkHours, PharmacyType} from "@models/pharmacy";

class OoParamPharmacyCreate extends AbstractOoParam {
  //이메일
  public email: string = "";

  //패스워드
  public password: string = "";

  //약국명
  public name: string = "";

  //배송 가능 여부
  public isDelivery: boolean = false;

  //주소
  public address: string = "";

  //상세주소
  public addressDetail?: string = "";

  //우편변호
  public zipCode: string = "";

  //전화번호
  public tel: string = "";

  //팩스번호
  public fax: string = "";

  //홈페이지
  public web?: string = "";

  //소개
  public bio?: string = "";

  //정산 은행명
  public bank: string = "";

  //계좌번호
  public bankAccount: string = "";

  //예금주/
  public bankHolderName: string = "";

  public type: number = PharmacyType.frontOfGate;

  //라이센스 번호
  public license: string = "";

  //사진
  public pictures?: Array<string> = [];

  //알림톡 수신 번호
  public alimTalkReceivers: Array<string> = [];

  //소속 병원 아이디 목록
  public hospitalIdList?: Array<string> = [];

  //운영시간
  public pharmacyHours?: Array<IOoWorkHours>;

  //퀵 배송 지역 검색 키워드
  public quickRegionKeywordList?: Array<string> = [];

  //퀵 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
  public quickRegionKeywordHitCount?: number = 2;

  //퀵 배송 문구
  public quickDeliveryComment?: string = "";

  //당일 배송 지역 검색 키워드
  public todayRegionKeywordList?: Array<string> = [];

  //당일배송 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
  public todayRegionKeywordHitCount?: number = 2;

  //당일 배송 문구
  public todayDeliveryComment?: string = "";

  //택배 배송 지역 검색 키워드
  public packageRegionKeywordList?: Array<string> = [];

  //택배 배송 문구
  public packageDeliveryComment?: string = "";

  //약국 픽업 문구
  public pickupDeliveryComment?: string = "";

  //택배 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
  public packageRegionKeywordHitCount?: number = 0;

  //약배송 타입 목록
  public deliveryTypeList?: Array<number> = [];

  //퀵 가능 시간 - 사용안함
  public deliverQuickHours?: Array<string> = [];

  //퀵 비용 - 사용안함
  public deliverQuickPrice?: number = 0;

  //당일배송 가능시간
  public deliverTodayHours?: Array<string> = [];

  //당일배송 비용
  public deliverTodayPrice?: number = 0;

  //택배배송 가능시간
  public deliverPackageHours?: Array<string> = [];

  //택배 배송 비용
  public deliverPackagePrice?: number = 0;

  //직접픽업 가능시간
  public deliverPickupHours?: Array<string> = [];

  //직접픽업 배송 비용
  public deliverPickupPrice?: number = 0;

  createInfo(data: PharmacyInfo) {
    this.email = data.email;
    this.name = data.name;
    this.password = data.password;
    this.pictures = data.pictures;
  }

  createAdditionalInfo(data: PharmacyAdditionalInfo) {
    this.zipCode = data.zipCode;
    this.address = data.address;
    this.addressDetail = data.addressDetail;
    this.tel = data.tel;
    this.fax = data.fax;
  }

  createAlimTalkInfo(data: PharmacyAlimTalkInfo) {
    this.alimTalkReceivers = data.alimTalkReceivers;
  }

  createBankInfo(data: PharmacyBankInfo) {
    this.bank = data.bank;
    this.bankAccount = data.bankAccount;
    this.bankHolderName = data.bankHolderName;
  }

  createTargetHospital(data: PharmacyTargetHospital) {
    this.hospitalIdList = data.hospitalIdList;
    this.deliveryTypeList = data.deliveryTypeList;
    this.deliverTodayPrice = data.deliveryTodayPrice;
    this.deliverQuickPrice = data.deliveryQuickPrice;
    this.deliverPackagePrice = data.deliveryPackagePrice;
    this.deliverPickupPrice = data.deliveryPickupPrice;
    this.todayRegionKeywordHitCount = data.todayRegionKeywordHitCount;
    this.todayRegionKeywordList = data.todayRegionKeywordList;
  }

  createDeliveryRegion(data: PharmacyDeliveryRegion) {
    this.todayRegionKeywordHitCount = data.todayRegionKeywordHitCount;
    this.todayRegionKeywordList = data.todayRegionKeywordList;
  }

  createDelivery(data: PharmacyDelivery) {
    this.deliverTodayPrice = data.deliverTodayPrice;
    this.deliveryTypeList = data.deliveryTypeList;
  }

  createWorkHour(data: PharmacyHours) {
    this.pharmacyHours = data.pharmacyHours;
  }
}

export {OoParamPharmacyCreate};
