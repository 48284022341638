class RawExcelExportModel {
    id?:string = "";
    payerName?:string = "";
    payerMobile?:string = "";
    productType?:string = "";
    dateTime:string = "";
    hospitalCode:string = "";
    hospitalName:string = "";
    orderPlatform:string = "";
    hospitalCalPrice:number = 0;
    externalCode:string = "";
    goods:string = "";
    paymentPrice:number = 0;
    providerName:string = "";
    refererList:string = "";
    refererCalPrice:string = "";
    orderId:string = "";
    cancelDateTime:string = "";
    operatorCancelTimestamp?:number = 0;
    totalPrice?:number = 0;
    totalHospitalCalPrice?:number = 0;
    referer: string[] = [];
    totalRefererCalPrice: number[] = [];
    type?:number = 0;
    bank:string = "";
    bankAccount:string = "";
    bankHolderName: string = "";
}

export { RawExcelExportModel };