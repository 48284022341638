import { IOoUnospayDelivery, IOoUnospaySalesMenu, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import { exCodeNumToStr, findTlcMenuList, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import { IOoProducts } from '@api/models/productSetting/product';
const ExcelJS = require("exceljs");

/**
 *  @description 마이오케어 발주서 생성
 */

const createModel = (e:IOoUnospayDelivery, salesItem:IOoUnospaySalesMenu) => {
    const model = new ExcelExportModel();
    model.name = e.recipientName;
    model.address1 = e.address;
    model.address2 = e.addressDetail;
    model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
    model.zipCode = e.zipCode;
    model.memo = e.memo;
    model.subMemo = e.subMemo;
    model.quantity = "1";
    model.exCode = salesItem.externalCode;
    return model;
}

const getProduct = (salesItem:IOoUnospaySalesMenu, productList:IOoProducts[]) => {
    const product = productList.find(product => product.externalCode === salesItem.externalCode);
    return product;
}

const getOptionName = (product:IOoProducts, optionCode:string) => {
    const option = product.optionList.find(option => option.optionCode === optionCode);
    return option?.optionOrderName;
}

const addBottle = (salesItem:IOoUnospaySalesMenu) => {
    const bottleCode = [ 'OF48_03', 'OF48_04']
    if(bottleCode.includes(salesItem.externalCode!)){
        return true;
    }
    const ret = salesItem.salesOption?.some(option => option.externalCode === '102');

    return ret;
}

const findProductName = (optionName:string) => {
    if(optionName === '10포'){
        return `마이오케어 미니`
    }else{
        return `마이오케어 라이프`
    }
}

const mocOrder = async (list:IOoUnospayDelivery[], productList:IOoProducts[]) => {
    let arrayHdOrderList = [];
    // 마이오케어 프로바이더 코드 
    const tlcMenuMOC = findTlcMenuList(list , 'OF48');
    const excelList = listProviderFilter(list , 'OF48');

    if(_.isEmpty(tlcMenuMOC)){
        arrayHdOrderList = [...excelList];
    }else{
        arrayHdOrderList = [...tlcMenuMOC! , ...excelList]
    }
    // if(tlcMenuMOC!.length > 0){
    //   arrayHdOrderList = [...tlcMenuMOC! , ...excelList]
    // }else{
    //   arrayHdOrderList = [...excelList];
    // }
    let data_array: Array<ExcelExportModel> = [];
    arrayHdOrderList.forEach(e => {
        if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
            e.salesMenu?.forEach(salesItem => {
                const product = getProduct(salesItem, productList);
                console.log("salesItem :: ", salesItem);
                if(_.startsWith(salesItem.externalCode, 'OF48')){
                    if(salesItem.salesOption){
                        let model = createModel(e, salesItem);
                        model.product = product?.productName + (!_.isEmpty(salesItem.salesOption) ? ' ' + getOptionName(product!, salesItem.salesOption![0].externalCode!) : '');
                        model.option = addBottle(salesItem) ? '보틀' : '';
                        model.quantity = salesItem.externalCode === 'OF48_04' ? '4' : '1';
                        data_array.push(model);
                    }
                }else if(_.startsWith(salesItem.externalCode, 'OF12')){
                    salesItem.salesOption?.forEach(sale => {
                        if(sale.externalCode === '203' || sale.externalCode === '204'){
                            let model = createModel(e, salesItem);
                            model.product = findProductName(sale.optionName!);
                            data_array.push(model);
                        }
                    })
                }
            })
        }
    })
    console.log(data_array);
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferMOC = await (await fetch("/assets/moc.xlsx")).arrayBuffer();
    const workbookMOC = new ExcelJS.Workbook();
    await workbookMOC.xlsx.load(arrayBufferMOC);
    const ws_moc = workbookMOC.getWorksheet("마이오케어");
    data_array.map((data: ExcelExportModel, index: number) => {
        const row = ws_moc.getRow(index + 2);
        [
            '',
            '',
            data.product,
            data.option,
            data.quantity,
            '',
            '',
            data.name,
            '',
            data.tel1? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
            data.zipCode,
            `${data.address1} ${data.address2}`,
            data.memo
        ].forEach((value, index) => {
            const cell = row.getCell(index + 1);
            cell.value = value;
        })
    });
    const excelBuffer = await workbookMOC.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
        excelFile,
        excelFileName + "_" + moment().format("YYYYMMDD") + "(마이오케어)" + excelFileExtension
    );
}

export default mocOrder