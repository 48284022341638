import { IOoUnospayDelivery, OoDeliveryStatus } from "@models/unospay";
import { useUnospayStatusRtdbHook } from "@hooks/unospay/useUnospayStatusRtdbHook";

interface Props {
  number: number;
  list: Array<IOoUnospayDelivery>;
}

export const useUnospayStatusFilter = ({ number, list }: Props) => {
  const { total, emptyAddress, existAddress, cancelOrder, sendPackage } =
    useUnospayStatusRtdbHook({ list: list });

  switch (number) {
    case OoDeliveryStatus.sendPackage:
      return sendPackage();
    case OoDeliveryStatus.existAddress:
      return existAddress();
    case OoDeliveryStatus.emptyAddress:
      return emptyAddress();
    case OoDeliveryStatus.cancelOrder:
      return cancelOrder();
    default:
      return total();
  }
};
