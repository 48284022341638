import { DoctorOnAPI } from '@api/request';
import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components';
import _ from 'lodash'
import { toast } from 'react-toastify';
import { providerList } from 'interfaces/Operator/OperatorUnospayProviderList';
import moment from 'moment';

interface WidthProps {
    width: number;
}

interface FlexProps {
    flex: number;
}

interface CompanyInfo {
    email:string;
    name:string;
    externalCode:string;
}

const UnospayAddCompanyPopup = () => {
    const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({
        email: "",
        name: "",
        externalCode: "",
    })
    const [providerList , setProviderList] = useState<providerList[]>([]);

    const handleChange = (e:ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setCompanyInfo({...companyInfo, [name]: value});
    }

    const addCompanyInfo = async (companyInfo:CompanyInfo) => {
        const param = {
            email: `${companyInfo.email}@doctoron.kr`,
            name: companyInfo.name,
            externalCode: companyInfo.externalCode,
            uid: "",
            displayTransportNameList: []
        }
        try {
            const response = await DoctorOnAPI.shared.unospay.createFoodProvider(param)
            if(response.status === 'ok'){
                toast.success('업체 등록이 완료 되었습니다.')
                window.close();
            }
            
        } catch (error : any) {
            console.log(error.message);
            
        }
        
    }

    const validation = () => {
        if(Object.values(companyInfo).every((value) => value !== "")){
            return false
        }else{
            return true
        }
    }

    const handleSubmitClick = () => {
        if(!validation()){
            addCompanyInfo(companyInfo)
        }else{
            toast.error('내용을 입력해주세요')
        }
    }

    const getProviderList = async () => {
        try {
            const response = await DoctorOnAPI.shared.unospay.getFoodProvider();
            if(response.data){
                setProviderList(response.data)
            }
        } catch (error) {
            toast.error('업체목록을 불러오는데 에러가 발생했습니다. 새로고침을 해주세요')
        }
    }

    useEffect(() => {
        getProviderList();
    },[])

  return (
    <Container>
        <CompanyInfoContainer>
            <Title>업체 등록</Title>
            <InputContainer>
                <EmailContainer>
                    <Input width={150}  placeholder='아이디' name='email' onChange={handleChange} autoFocus/>
                    <span>@</span>
                    <span>doctoron.kr</span>
                </EmailContainer>
                <Input width={270} placeholder='업체명' name='name' onChange={handleChange}/>
                <Input width={270} placeholder='코드' name='externalCode' onChange={handleChange}/>
            </InputContainer>
            <Button onClick={handleSubmitClick}>등록</Button>
        </CompanyInfoContainer>
        <CompanyListContainer>
             <ListHeader>
                <ItemField flex={.5}>코드</ItemField>
                <ItemField flex={1}>이름</ItemField>
                <ItemField flex={1}>이메일</ItemField>
                <ItemField flex={1}>생성날짜</ItemField>
            </ListHeader>
            <ListContainerLayout>
                {_.sortBy(providerList, 'externalCode').map(item => (
                    <ListContainer>
                        <ItemField flex={.5}>{item.externalCode}</ItemField>
                        <ItemField flex={1}>{item.name}</ItemField>
                        <ItemField flex={2}>{item.email}</ItemField>
                        <ItemField flex={1}>{moment(item.timestamp).format('YYYY-MM-DD HH:MM')}</ItemField>
                    </ListContainer>
                ))}
            </ListContainerLayout>
        </CompanyListContainer>
    </Container>
  )
}

export default UnospayAddCompanyPopup

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    column-gap: 30px;
`
const Title = styled.h3`
    font-size: 30px;
    font-weight: 700;
`
const Button= styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-size: 18px;
    color: black;
    border-radius: 8px;
    border: 0.5px solid #c2c1bd;
    background: white;
    padding: 12px 32px;
    margin-right: 20px;

        &:hover{
            color:#3e7bcd;
            border: 1px solid #3e7bcd;
        }
`
const EmailContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    column-gap: 5px;

    span {
        font-size: 18px;
    }
`
const Input = styled.input<WidthProps>`
    width: ${(props) => props.width}px;
    padding: 10px 12px;
    font-size: 18px;
`
const CompanyInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
`

const CompanyListContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    border: 1px solid #cdcdcd;
`

const ListHeader = styled.div`
    display: flex;

    padding: 10px;
    border-bottom: 1px solid #cdcdcd;
`
const ListContainerLayout = styled.div`
    width: 700px;
    height: 300px;
    overflow: scroll;
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
`

const ListContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    column-gap: 20px;
    overflow: scroll;
    padding: 0px 15px 15px;
`
const ItemField = styled.span<FlexProps>`
    flex: ${props => props.flex};
    font-size: 15px;
`
