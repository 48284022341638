import {atom} from "recoil";
import {recoilPersist} from "recoil-persist";
import {IOoDataOAuth} from "../../api";

const KEY = 'oauthList';
const {persistAtom} = recoilPersist({
    key: KEY,
    storage: localStorage
});

const oauthListState = atom<Array<IOoDataOAuth>>({
    key: KEY,
    default: [],
    effects_UNSTABLE: [persistAtom]
});

export {
    oauthListState
};
