import styled from "styled-components";

interface ModalProps {
    visible: boolean,
}

export const ModalOverlay = styled.div<ModalProps>`
      box-sizing: border-box;
      display: ${(props) => (props.visible ? 'flex' : 'none')};
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 999;
`