import {AxiosInstance} from "axios";
import {
  IOoDataResponse,
  IOoDiagnosis,
  IOoResponse,
  OoParamFamilyAddMedicinePrice,
  OoParamFamilyChangeStatus,
  OoParamFamilyPrescriptionCreate,
  OoPharmacySearchParam
} from "api";
import {endPoint} from "./base";
import {OoParamName} from "@params/common/paramName";
import {OoParamDoctorPatientRecord} from "@params/familyTelemedicine/paramDoctorSearch";

class DoctorOnFamilyTelemedicineApi {
  constructor(private readonly axiosInstance: AxiosInstance) {
  }

  /**
   * 가족 처방전 저장
   */
  async save(param: OoParamFamilyPrescriptionCreate): Promise<IOoResponse> {
    return await this.axiosInstance.post<IOoResponse>(endPoint.familyTelemedicine.save, param);
  }

  /**
   * 가족 약제비 전송
   */
  async addMedicinePrice(param: OoParamFamilyAddMedicinePrice): Promise<IOoResponse> {
    return await this.axiosInstance.post<IOoResponse>(endPoint.familyTelemedicine.addMedicinePrice, param);
  }

  /**
   * 가족 처방전 전송
   */
  async prescription(param: OoParamFamilyPrescriptionCreate): Promise<IOoResponse> {
    return await this.axiosInstance.post<IOoResponse>(endPoint.familyTelemedicine.prescription, param);
  }

  /**
   * 가족 진료 상태 변경
   */
  async diagnosisStatus(param: OoParamFamilyChangeStatus): Promise<IOoResponse> {
    return await this.axiosInstance.put<IOoResponse>(endPoint.familyTelemedicine.diagnosisStatus, param);
  }

  /**
   * 관리자, 마스터 약국 이름 검색 진료 데이터
   */
  async patientNameRecord(param: OoParamName): Promise<IOoDataResponse<IOoDiagnosis[]>> {
    const url = `${endPoint.familyTelemedicine.patientNameRecord}/${param.getPathParams()}`;
    return await this.axiosInstance.get<IOoDataResponse<IOoDiagnosis[]>>(url);
  }

  /**
   * 약국 이름 검색 진료 데이터
   */
  async patientNameRecordPharmacy(param: OoPharmacySearchParam): Promise<IOoDataResponse<IOoDiagnosis[]>> {
    const url = `${endPoint.familyTelemedicine.patientNameRecordPharmacy}/${param.getPathParams()}`;
    return await this.axiosInstance.get<IOoDataResponse<IOoDiagnosis[]>>(url);
  }

  /**
   * 의사 이름 검색 진료 데이터
   */
  async patientNameRecordDoctor(param: OoParamDoctorPatientRecord): Promise<IOoDataResponse<IOoDiagnosis[]>> {
    const url = `${endPoint.familyTelemedicine.patientNameRecordDoctor}/${param.getPathParams()}`;
    return await this.axiosInstance.get<IOoDataResponse<IOoDiagnosis[]>>(url);
  }
}

export {DoctorOnFamilyTelemedicineApi};
