import {useSuspenseHook} from "../../hooks";
import {useMutation} from "react-query";
import {OoParamDiagnosisStatus} from "@api/params";
import {DoctorOnAPI} from "@api/request";
import {useSetRecoilState} from "recoil";
import {statusChangeState} from "@recoils/operator/statusChangeState";
import {statusChangeAlertState} from "@recoils/operator/statusChangeAlertState";
import {isRequestSucceed} from "@api/reponses";

export const useDiagnosisStatusChange = () => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const setModalVisible = useSetRecoilState(statusChangeState);
    const setConfirmModalVisible = useSetRecoilState(statusChangeAlertState);
    return useMutation(
        'doctor/changeStatus',
        async (param: OoParamDiagnosisStatus) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.telemedicine.diagnosisStatus(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    setConfirmModalVisible(false);
                    setModalVisible(false);
                    return response;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        }
    )
};

