import styled from "styled-components";

interface Props {
    children: JSX.Element
}

export const ListContainer = ({children}: Props) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 1024px;
  height: 100%;
  margin-top: 263px;
  color: #949390;
  font-weight: 500;
  font-size: 12px;
  position: fixed;
  z-index: 10;
  overflow: auto;

  & > div {
    flex: 1;
    text-align: center;
  }
`;
