import {useState} from "react";
import moment from "moment";
import {useRecoilState, useResetRecoilState, useSetRecoilState} from "recoil";
import {
    dailyDiagnosisListState,
    dailyDiagnosisSelectedState,
    searchListState,
    selectedFilterState,
} from "@recoils/index";
import _ from "lodash";
import {FilterStatus} from "../../pages/Operator/DiagnosisDashBoardPage/components";
import {toast} from "react-toastify";
import {useSelectedDoctorDiagnosisListQuery} from "@queries/doctor";
import {usePharmacyDailyDiagnosisList} from "@queries/pharmacy";
import {useDailyDiagnosisList} from "@queries/operator";
import {triggerState} from "@recoils/calendar";
import {calendarParamState} from "@recoils/operator/calendarParamState";

interface Props {
    target: string,
    id?: string,
}

export const useCalendarSettingHook = ({target, id}: Props) => {
        const [modalVisible, setModalVisible] = useState(false);
        const [todayDate, setTodayDate] = useState<Array<Date>>([
            moment().toDate(),
            moment().toDate(),
        ]);

        const [calendarQueryTrigger, setCalendarQueryTrigger] = useRecoilState(triggerState);

        const {operatorQuery, doctorQuery, pharmacyQuery} = calendarQueryTrigger;

        const [dailyDiagnosisSelectedDate, setDailyDiagnosisSelectedDate] = useRecoilState(dailyDiagnosisSelectedState(target));
        const [diagnosisList, setDiagnosisList] = useRecoilState(dailyDiagnosisListState);

        const setSearchList = useSetRecoilState(searchListState(target));
        const setSelectNumber = useSetRecoilState(selectedFilterState);
        const resetCalendarParam = useResetRecoilState(calendarParamState);

        useSelectedDoctorDiagnosisListQuery({
            doctorId: id || "",
            beginTimestamp: moment(_.head(dailyDiagnosisSelectedDate.date)!, "YYYYMMDD")
                .startOf("day")
                .valueOf()
                .toString(),
            endTimestamp: moment(_.last(dailyDiagnosisSelectedDate.date)!, "YYYYMMDD")
                .endOf("day")
                .valueOf()
                .toString(),
            count: "100",
            diagnosisId: "",
            updateQueryTrigger: doctorQuery,
        });

        usePharmacyDailyDiagnosisList(
            {
                pharmacyId: id || "",
                beginTimestamp: moment(_.head(dailyDiagnosisSelectedDate.date)!, "YYYYMMDD")
                    .startOf("day")
                    .valueOf()
                    .toString(),
                endTimestamp: moment(_.last(dailyDiagnosisSelectedDate.date)!, "YYYYMMDD")
                    .endOf("day")
                    .valueOf()
                    .toString(),
                count: "100",
                diagnosisId: "",
                updateQueryTrigger: pharmacyQuery,
            }
        );

        useDailyDiagnosisList({
            beginTimestamp: moment(_.head(dailyDiagnosisSelectedDate.date)!, "YYYYMMDD")
                .startOf("day")
                .valueOf()
                .toString(),
            endTimestamp: moment(_.last(dailyDiagnosisSelectedDate.date)!, "YYYYMMDD")
                .endOf("day")
                .valueOf()
                .toString(),
            count: "100",
            diagnosisId: "",
            updateQueryTrigger: operatorQuery,
        });

        const filteredTarget = (isTrue: boolean, isReset?: boolean) => {
            (isReset || false) && setCalendarQueryTrigger({operatorQuery: false, doctorQuery: false, pharmacyQuery: false});
            if (_.isEqual(target, 'operator')) {
                setCalendarQueryTrigger({...calendarQueryTrigger, operatorQuery: isTrue});
            } else if (_.isEqual(target, 'pharmacy')) {
                setCalendarQueryTrigger({...calendarQueryTrigger, pharmacyQuery: isTrue});
            } else {
                setCalendarQueryTrigger({...calendarQueryTrigger, doctorQuery: isTrue});
            }
        };

        const onShowCalendar = (e: any) => {
            e.preventDefault();
            setModalVisible(true);
            setSearchList({id: target, userName: "", list: []});
            setSelectNumber(_.toString(FilterStatus.total));
        };

        const onClickDate = (value: Date, e: any) => {
            e.preventDefault();
            setDailyDiagnosisSelectedDate({
                ...dailyDiagnosisSelectedDate, date: [
                    ...dailyDiagnosisSelectedDate.date,
                    moment(value).format("YYYY.MM.DD"),
                ]
            });
        };

        const onChangeDate = (value: Array<Date>) => {
            if (_.isEqual(value.length, 2)) {
                const isOverMonth = isMaxDateLimit(value);
                if (isOverMonth) {
                    toast.error("최대 조회기간은 30일까지입니다.");
                    setTodayDate([moment().toDate(), moment().toDate()]);
                    setDailyDiagnosisSelectedDate({
                        id: target,
                        date: [moment().format(`YYYY.MM.DD`), moment().format(`YYYY.MM.DD`)]
                    });
                    return;
                }
                filteredTarget(true);
            }

            const getDate = value.map((item) => item);
            setTodayDate(getDate);
            const stringToTime = value.map((item) => moment(item).format("YYYY.MM.DD"));
            setDailyDiagnosisSelectedDate({...dailyDiagnosisSelectedDate, date: stringToTime});
        };

        const isMaxDateLimit = (ranges: Array<Date>) => {
            const startDate = moment(ranges[0]).toDate();
            const endDate = moment(ranges[1]).toDate();

            let count = 0;
            while (startDate <= endDate) {
                startDate.setDate(startDate.getDate() + 1);
                count++;
            }

            return count > 30;
        };

        const handleTodayButton = (e: any) => {
            e.preventDefault();
            setDailyDiagnosisSelectedDate({
                id: target,
                date: [moment().format(`YYYY.MM.DD`), moment().format(`YYYY.MM.DD`)]
            });
            setTodayDate([moment().toDate(), moment().toDate()]);
            filteredTarget(true);
        };

        return {
            modalVisible,
            setModalVisible,
            todayDate,
            setTodayDate,
            onShowCalendar,
            onClickDate,
            dailyDiagnosisSelectedDate,
            setDailyDiagnosisSelectedDate,
            onChangeDate,
            handleTodayButton,
            filteredTarget,
            diagnosisList,
            setDiagnosisList,
            setCalendarQueryTrigger,
            resetCalendarParam,
        };
    }
;
