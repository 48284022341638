import React from "react";
import styled from "styled-components";
import {DiagnosisProcessingState} from "@models/diagnosis/diagnosisProcessingState";
import {formatDateMDhhmm} from "@hooks/format";


interface Props {
    status: DiagnosisProcessingState,
    flex?: number,
}

export const DiagnosisStatusField = ({status, flex}: Props) => {
    return (
        <Wrapper flex={flex ?? 0}>
            <div>
                <StatusBar color={status.color}/>
            </div>
            <div>
                <StatusDate>{formatDateMDhhmm(status.timestamp)}</StatusDate>
                <StatusTitle color={status.color}>{status.title}</StatusTitle>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div<{ flex: number }>`
  display: flex;
  flex: ${(props) => props.flex};
  justify-content: space-between;

  & > div:first-child {
    width: 10%;
  }

  div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    width: 90%;
  }
`;

const StatusBar = styled.div`
  height: 64px;
  width: 12px;
  margin-right: 3px;
  background: ${(props) => props.color};
`;

const StatusDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #949390;
  font-size: 12px;
`;

const StatusTitle = styled.div`
  color: ${(props) => props.color};
  font-weight: 700;
`;
