import styled from "styled-components";
import React from "react";

interface Props {
    color: string,
    title: string,
    flex?: number,
}

export const ListStatusField = ({color, title, flex}: Props) => {
    return <CustomDiv flex={flex ?? 0} color={color}>{title}</CustomDiv>
}

const CustomDiv = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
  color: ${props => props.color};
  white-space: pre-wrap;
  text-align: center;
`;
