import React from "react";
import {filteredTime} from "@api/models";
import _ from "lodash";
import {Link} from "react-router-dom";
import styled from "styled-components";

interface Props {
    data: Array<any>,
    getHospitalName: (id:string) => string,
    getHospitalTel: (id:string) => string | undefined,
}

export const DoctorListContainer = ({data, getHospitalName, getHospitalTel}: Props) => {
    const splitDepartments = (department: string[]) => {
        return _.join(department, ",");
    };

    return (
        <Wrapper>
            {
                _.sortBy(data, "timestamp").map((item, index) => (
                    <Link key={index}
                          to={`/doctorDetail`}
                          state={{data: item.id}}
                          style={{textDecoration: "none", color: "black"}}>
                        <ListContainer>
                            <div>{index + 1}</div>
                            <div>{filteredTime(item.timestamp)}</div>
                            <div>{getHospitalName(`${item.hospitalId}`)} </div>
                            <div>{item.name}</div>
                            <div>{item.email}</div>
                            <div>{splitDepartments(item.departments)}</div>
                            <div>{getHospitalTel(`${item.hospitalId}`)}</div>
                        </ListContainer>
                    </Link>
                ))}
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 1024px;
  color: #949390;
  font-weight: 500;
  font-size: 12px;
  overflow-y: auto;

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-height: 768px) {
    height: 585px;
  }

  @media (min-height: 1024px) {
    height: 75%;
  }

  & > div {
    flex: 1;
    text-align: center;
  }
`
const ListContainer = styled.div`
  display: flex;
  text-align: center;
  height: 60px;
  align-items: center;
  margin-bottom: 5px;
  background: #ffffff;
  box-shadow: 0px 1px 3px -2px;

  & > div {
    flex: 1;
  }
`;
