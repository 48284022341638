import _ from "lodash";

interface IOoWorkHours {
    //요일 0: 공휴일, 1: 월요일...
    dayOfWeek: number;

    //hh:mm 포맷, 시작, 종료 예: 10:00,18:00
    hours: string;

    //hh:mm 포맷, 시작, 종료 예: [10:00,18:00] 복수개 존제
    breakHoursList: Array<string>;

    //휴무 여부
    isOff: boolean
}

export enum DayOfWeek {
    restDay = 0,
    mon = 1,
    tue = 2,
    wed = 3,
    thu = 4,
    fri = 5,
    sat = 6,
    sun = 7,
    lunch = 8,
    break = 9
}

export function filteredDayOfWeek(item: number): string {
  if (_.isEqual(item, DayOfWeek.mon)) {
      return "월"
  } else if (_.isEqual(item, DayOfWeek.tue)) {
      return "화"
  } else if (_.isEqual(item, DayOfWeek.wed)) {
      return "수"
  } else if (_.isEqual(item, DayOfWeek.thu)) {
      return "목"
  } else if (_.isEqual(item, DayOfWeek.fri)) {
      return "금"
  } else if (_.isEqual(item, DayOfWeek.sat)) {
        return "토"
    } else if (_.isEqual(item, DayOfWeek.sun)) {
        return "일"
    } else if (_.isEqual(item, DayOfWeek.lunch)) {
        return "점심시간"
    } else if (_.isEqual(item, DayOfWeek.restDay)) {
        return "공휴일"
    } else {
        return "-"
    }
}

export type {
    IOoWorkHours
};
