import React, {useEffect} from "react";
import {DiagnosisCalendar, DiagnosisFilterCard, DiagnosisListContainer, DiagnosisSearch} from "./components";
import styled, {css} from "styled-components";
import moment from "moment";
import _ from "lodash";
import packageJson from "../../../../package.json";
import {useDashBoardSettingHook} from "./hooks/useDashBoardSettingHook";
import {MainListTitle} from "@components/mainListTitle";
import {ListTitleContainer} from "@components/layout";

interface FlexProps {
    flex: number;
}

export const DiagnosisDashBoardPage = () => {
    const {
        target,
        selectedUserName,
        dailyDiagnosisSelectedDate,
        dailyDiagnosisList,
        displayList,
        originList,
        searchList,
        updateDisplay,
        connect,
        disconnect,
    } = useDashBoardSettingHook();

    useEffect(() => {
        connect("allTeleMedicine");
        return () => disconnect();
    }, []);

    useEffect(() => {
        if (_.gt(searchList.list?.length, 0)) {
            updateDisplay(searchList.list!);
        } else if (_.gt(dailyDiagnosisList.length, 0)) {
            updateDisplay(dailyDiagnosisList);
        } else {
            updateDisplay(originList);
        }
    }, [originList, searchList, dailyDiagnosisList]);


    return (
        <Wrapper>
            <TopContainer>
                <SearchContainer>
                    <div>v{packageJson.version}</div>
                    <DiagnosisSearch target={target}/>
                </SearchContainer>
                <MainListTitle title={`${selectedUserName} 관리자님`} maxWidth={1280} minWidth={1024}>
                    <CalendarContainer>
                        <TodayDiagnosis id={_.head(dailyDiagnosisSelectedDate.date)}>
                            당일진료
                        </TodayDiagnosis>
                        <DiagnosisCalendar target={target}/>
                    </CalendarContainer>
                </MainListTitle>
                <DiagnosisFilterCard list={displayList.list}/>
                <ListTitleContainer>
                    <>
                        <Title flex={1.1}>상태</Title>
                        <Title flex={1}>이름</Title>
                        <Title flex={1}>전화번호</Title>
                        <Title flex={1}>접수 방식</Title>
                        <Title flex={0.5}>진료 방식</Title>
                        <Title flex={1}>진료비 결제 내역</Title>
                        <Title flex={1}>약제비 결제 내역</Title>
                        <Title flex={1}>약 수령 방식</Title>
                        <Title flex={1}>기사 배정</Title>
                    </>
                </ListTitleContainer>
            </TopContainer>
            <DiagnosisListContainer list={displayList.list}/>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 75px 0px;
`;

const TopContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 26px;
`;

const SearchContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  color: gray;
  font-size: 13px;
  font-weight: 700;
`;

const TodayDiagnosis = styled.div`
  width: 96px;
  height: 36px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  ${(props) =>
          props.id === `${moment().format(`YYYY.MM.DD`)}`
                  ? css`
                    color: ${(props) => props.theme.colors.statusFontColor.success};
                    border: 1px solid ${(props) => props.theme.colors.statusFontColor.success};
                    background: ${(props) =>
                            props.theme.colors.statusBgButtonColor.success};
                  `
                  : css`
                    color: ${(props) => props.theme.colors.fontColor.black};
                    border: 1px solid ${(props) => props.theme.colors.borderColor.grey};
                    background: ${(props) => props.theme.colors.bgColor.white};
                  `}
  \`;
`;

const Title = styled.div<FlexProps>`
  flex: ${(props) => props.flex};
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
