import React, {ChangeEvent, useEffect, useState} from "react";
import _ from "lodash";
import styled from "styled-components";
import {HospitalBasicInfo} from "../../../../interfaces";
import {CustomInputText} from "@components/custom";
import {InitialImageUpload} from "@components/initialImageUpload";
import classNames from "classnames/bind";
import SelectStyles from "@styles/common/Select.module.scss";
import {HospitalRegisterType} from "@components/registerForm";
import {HospitalType} from "@api/models";

interface Props {
    onChanged: (data: HospitalBasicInfo) => void,
}

export const HospitalRegisterInfo = ({onChanged}: Props) => {
    const sx = classNames.bind(SelectStyles);
    const [items, setItems] = useState<HospitalBasicInfo>({
        name: "",
        email: "",
        password: "opusone1004",
        tel: "",
        zipCode: "",
        address: "",
        regionKeywordList: [],
        logo: "",
        isMtmHospital: true,
        type: 0,
        referrerList:[],
        useOrderAlimtalk: true
    });

    useEffect(() => {
        onChanged(items);
    }, [items]);

    const onChangeName = (e: any) => setItems({...items, name: e.target.value});
    const onChangeEmail = (e: any) => setItems({...items, email: e.target.value});
    const onChangePassword = (e: any) => setItems({...items, password: e.target.value});
    const onChangeTel = (e: any) => setItems({...items, tel: e.target.value});
    const onChangeZipCode = (e: any) => setItems({...items, zipCode: e.target.value});
    const onChangeAddress = (e: any) => setItems({...items, address: e.target.value});
    const onChangeRegionKeywordList = (e: any) => setItems({
        ...items,
        regionKeywordList: makeKeywordList(e.target.value)
    });
    const onChangeLogo = (target: string) => setItems({...items, logo: target});
    const makeKeywordList = (keyword: string) => {
        const splitArr = _.split(keyword, ",");
        return splitArr.map((item) => _.trim(item));
    };

    const onSelectIsMtm = (e: any) => {
        const value = _.toNumber(e.target.value);
        if(value === 1) {
            // onChangeReferrer('');
            const isMtmHospital = value === 1;
            setItems({ ...items, isMtmHospital });
        }
        const isMtmHospital = value === 0;
        setItems({ ...items, isMtmHospital });
    };

    const onSelectIsOrderAlimtalk = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(e.target.value);
        console.log(value);
        
        if(value === 1) {
            setItems({ ...items, useOrderAlimtalk: false });
        }else{
            setItems({ ...items, useOrderAlimtalk:true });
        }
    };
    const onSelectType = (e: any) => {
        let value = _.toNumber(e.target.value);
        setItems({...items, type: value});
    };

    const onChangeReferrerList = (e: ChangeEvent<HTMLInputElement>) => setItems({...items, referrerList: makeKeywordList(e.target.value)});

    return (
        <Wrapper>
            <div>
                <div>
                    <Table>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>병원명</td>
                            <td>
                                <CustomInputText value={items.name}
                                                 size="middle"
                                                 color={"blue"}
                                                 placeholder="[필수]"
                                                 onChanged={onChangeName}
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>전화번호</td>
                            <td>
                                <CustomInputText value={items.tel}
                                                 color={"blue"}
                                                 size="middle"
                                                 placeholder="- 없이 입력 [필수]"
                                                 disabled={true}
                                                 onChanged={onChangeTel}/>
                            </td>
                        </tr>
                        <tr>
                            <td>이메일</td>
                            <td>
                                <CustomInputText value={items.email}
                                                 color={"blue"}
                                                 size="middle"
                                                 placeholder="아이디@doctoron.kr [필수]"
                                                 disabled={true}
                                                 onChanged={onChangeEmail}/>
                            </td>
                        </tr>
                        <tr>
                            <td>우편번호</td>
                            <td>
                                <CustomInputText value={items.zipCode}
                                                 color={"blue"}
                                                 size="middle"
                                                 placeholder="5자리 숫자 [필수]"
                                                 disabled={true}
                                                 onChanged={onChangeZipCode}/>
                            </td>
                        </tr>
                        <tr>
                            <td>주소</td>
                            <td>
                                <CustomInputText value={items.address}
                                                 color={"blue"}
                                                 size="middle"
                                                 placeholder="[필수]"
                                                 disabled={true}
                                                 onChanged={onChangeAddress}/>
                            </td>
                        </tr>
                        <tr>
                            <td>지역 검색어</td>
                            <td>
                                <CustomInputText value={
                                    _.isEmpty(items.regionKeywordList)
                                        ? ""
                                        : _.join(items.regionKeywordList)
                                }
                                                 color={"blue"}
                                                 size="middle"
                                                 placeholder=",로 구분하여 복수 입력 가능 [필수]"
                                                 disabled={true}
                                                 onChanged={onChangeRegionKeywordList}/>
                            </td>
                        </tr>
                        <tr>
                            <td>비밀번호</td>
                            <td>
                                <CustomInputText value={items.password}
                                                 color={"blue"}
                                                 size="middle"
                                                 placeholder="8자리 이상 [필수]"
                                                 onChanged={onChangePassword}
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>MTM 병원여부</td>
                            <td>
                                <select className={sx("select", "middle", "blue")}
                                        onChange={onSelectIsMtm}
                                        defaultValue="">
                                    <option value={0}>Y</option>
                                    <option value={1}>N</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>병원 타입</td>
                            <td>
                                <select onChange={onSelectType} required={true} className={sx("select", "middle", "blue")}>
                                    <DisabledOption selected={true} disabled>병원 타입을 선택하세요.</DisabledOption>
                                    <option value={HospitalType.normal}>양방</option>
                                    <option value={HospitalType.orientalMedicine}>한방</option>
                                    <option value={HospitalType.b2b}>B2B</option>
                                    <option value={HospitalType.fitness}>피트니스</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>추천인/추천병원</td>
                            <td>
                                <CustomInputText value={
                                    _.isEmpty(items.referrerList)
                                        ? ""
                                        : _.join(items.referrerList)
                                }
                                                 color={"blue"}
                                                 size="middle"
                                                 placeholder="추천인 / 추천병원"
                                                 onChanged={onChangeReferrerList}
                                                 disabled={true}/>
                            </td>
                        </tr>
                        <tr>
                            <td>알림톡 사용여부</td>
                            <td>
                                <select className={sx("select", "middle", "blue")}
                                        onChange={onSelectIsOrderAlimtalk}
                                        defaultValue="">
                                    <option value={0}>Y</option>
                                    <option value={1}>N</option>
                                </select>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="image-container">
                    <InitialImageUpload target="hospital"
                                        onChanged={onChangeLogo}/>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }

  .image-container {
    padding-left: 50px;
  }
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: auto;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  tr {
    border-bottom: 1px solid #c8d6f8;
    height: 50px;
  }

  td:nth-child(2n-1) {
    background: #f4f6fb;
    width: 100px;
    text-align: center;
    font-weight: 700;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding-left: 8px;
    font-size: 13px;
  }
`;

const DisabledOption = styled.option`
  &:disabled {
    display: none;
  }
`;

