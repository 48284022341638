import { IOoUnospayDelivery, IOoUnospaySalesMenu, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _ from 'lodash';
import moment from 'moment';
const ExcelJS = require("exceljs");
import FileSaver from 'file-saver';

const DATA = [
     {code:'OF40_01_101', name:'더포르미 8종8팩', price:24152}
    ,{code:'OF40_01_102', name:'더포르미 10종10팩', price:30192}
    ,{code:'OF40_02_101', name:'인생도시락 8종8팩', price:27522}
    ,{code:'OF40_02_102', name:'인생도시락 10종10팩', price:34242}
    ,{code:'OF40_03', name:'반칙도시락 8종8팩', price:26252}
    ,{code:'OF40_04', name:'미니덮밥 8종8팩', price:19752}
]

const createModel = (e:IOoUnospayDelivery, salesItem:IOoUnospaySalesMenu) => {
    const model = new ExcelExportModel();
                  model.name = e.recipientName;
                  model.address1 = e.address;
                  model.address2 = e.addressDetail;
                  model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                  model.zipCode = e.zipCode;
                  model.memo = e.memo;
                  model.subMemo = e.subMemo;
                  model.quantity = "1";
                  model.orderDate = moment(e.timestamp).format('YYYY-MM-DD');
                  model.exCode = salesItem.externalCode;
    return model;
  }

const getProductInfo = (excode:string) => {
    const productInfo = DATA.find(item => item.code == excode);
    return productInfo
}

const foremeOrder = async (list:IOoUnospayDelivery[]) => {
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.foremeProvider));
    let data_array: Array<ExcelExportModel> = [];
    excelList.forEach(e => {
        if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
            e.salesMenu?.forEach(salesItem => {
                if(_.startsWith(salesItem.externalCode, 'OF40')){
                    let model = createModel(e, salesItem);
                    if(!_.isUndefined(salesItem.salesOption) && salesItem.salesOption.length === 0){
                        const productInfo = getProductInfo(salesItem.externalCode!);
                        model.product = productInfo?.name;
                        model.price = productInfo?.price.toLocaleString();
                        model.exCode = productInfo?.code;
                        data_array.push(model);
                    }else{
                        const optionCode = salesItem.salesOption!.map(sale => sale.externalCode);
                        const productInfo = getProductInfo(`${salesItem.externalCode}_${optionCode}`);
                        model.product = productInfo?.name;
                        model.price = productInfo?.price.toLocaleString();
                        model.exCode = productInfo?.code;
                        data_array.push(model);
                    }
                }
              })
        }
    });

    console.log(data_array);
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferFRM = await (await fetch("/assets/foreme.xlsx")).arrayBuffer();
    const workbookFRM = new ExcelJS.Workbook();
    await workbookFRM.xlsx.load(arrayBufferFRM);
    const ws_mmx = workbookFRM.getWorksheet("Sheet1");
    data_array.map((data: ExcelExportModel, index: number) => {
      const row = ws_mmx.getRow(index + 2);
      [
        data.orderDate,
        index+1,
        data.product,
        data.product,
        data.quantity,
        data.price,
        data.name,
        data.name,
        data.tel1? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
        `${data.address1} ${data.address2}`,
        data.memo,
    ].forEach((value, index) => {
        const cell = row.getCell(index + 1);
        cell.value = value;
      })
    });
    const excelBuffer = await workbookFRM.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName + "_" + moment().format("YYYYMMDD") + "(포르미)" + excelFileExtension
    );
}
export default foremeOrder