import _ from "lodash";
import {useRecoilValue} from "recoil";
import {useEffect, useMemo, useState} from "react";
import {filterFamilyDiagnosisReversed, IOoDiagnosis, isMyFamilyMember} from "@models/diagnosis";
import {doctorRtdbState, SearchParamProps} from "@recoils/doctor";
import {useDisplayHook} from "@hooks/common/useDisplayHook";
import {selectedUserState} from "@recoils/oauth";
import {useDoctorFindQuery, useDoctorSearchPatientQuery, useSelectedDoctorDiagnosisListQuery} from "@queries/doctor";
import {useDoctorConnectHook} from "@hooks/doctor";
import {CalendarParamProp} from "@recoils/unospay";

interface Props {
    list: Array<IOoDiagnosis>,
    calendarParam: CalendarParamProp,
    searchParam: SearchParamProps,
}

export const useDiagnosisDetailSettingHook = ({list, calendarParam, searchParam}: Props) => {
    const {connect, disconnect} = useDoctorConnectHook();

    const {displayList, updateDisplay} = useDisplayHook("doctor");
    const doctorRtdbList = useRecoilValue(doctorRtdbState);
    const memoDoctorRtdbList = useMemo(() => doctorRtdbList, [doctorRtdbList]);

    const [queryTrigger, setQueryTrigger] = useState({
        findQueryTrigger: true,
        searchQueryTrigger: false,
        calendarQueryTrigger: false,
    });

    const selectedUser = useRecoilValue(selectedUserState);
    const selectedUserId = useMemo(() => {
        setQueryTrigger({...queryTrigger, findQueryTrigger: true});
        return selectedUser?.user.id ?? "";
    }, [selectedUser]);

    const doctorFindQuery = useDoctorFindQuery(selectedUserId, queryTrigger.findQueryTrigger);
    const targetDoctorId = useMemo(() => {
        return doctorFindQuery.data?.id ?? "";
    }, [doctorFindQuery.data]);

    useEffect(() => {
        if (!_.isUndefined(targetDoctorId))
            connect(`teleMedicine/${targetDoctorId}`);
        return () => {
            disconnect();
            setQueryTrigger({...queryTrigger, findQueryTrigger: false});
        };
    }, [targetDoctorId]);


    const searchQueryResp = useDoctorSearchPatientQuery({
        searchName: searchParam.searchName,
        doctorId: searchParam.doctorId,
        searchQueryTrigger: queryTrigger.searchQueryTrigger,
        target: 'doctor'
    });

    const selectedDiagnosisQueryResp = useSelectedDoctorDiagnosisListQuery({
        doctorId: targetDoctorId,
        beginTimestamp: calendarParam.beginTimestamp,
        endTimestamp: calendarParam.endTimestamp,
        count: calendarParam.count,
        diagnosisId: calendarParam.diagnosisId || "",
        updateQueryTrigger: queryTrigger.calendarQueryTrigger,
    });

    useEffect(() => {
        setQueryTrigger({
            findQueryTrigger: false,
            searchQueryTrigger: false,
            calendarQueryTrigger: false,
        });
    }, []);


    useEffect(() => {
        const copied = _.cloneDeep(calendarParam);
        const filteredObj = _.omit(copied, ['diagnosisId']);
        if (!_.every(filteredObj, (param) => _.isEmpty(param))) {
            setQueryTrigger({...queryTrigger, calendarQueryTrigger: true});
            return;
        } else if (!_.every(searchParam, (param) => _.isEmpty(param))) {
            setQueryTrigger({...queryTrigger, searchQueryTrigger: true});
            return;
        } else {
            const diagnosisList = makeDiagnosisList(_.head(list)!.id || "", memoDoctorRtdbList);
            updateDisplay(diagnosisList);
            return;
        }
    }, [list, calendarParam, searchParam, memoDoctorRtdbList]);

    useEffect(() => {
        if (!_.isUndefined(searchQueryResp.data)) {
            const searchDiagnosisList = makeDiagnosisList(_.head(list)!.id || "", searchQueryResp.data || []);
            updateDisplay(searchDiagnosisList);
            setQueryTrigger({...queryTrigger, searchQueryTrigger: false});
            return;
        }
        if (!_.isUndefined(selectedDiagnosisQueryResp.data)) {
            const selectedDiagnosisList = makeDiagnosisList(_.head(list)?.id || "", selectedDiagnosisQueryResp.data || []);
            updateDisplay(selectedDiagnosisList);
            setQueryTrigger({...queryTrigger, calendarQueryTrigger: false});
            return;
        }
        const diagnosisList = makeDiagnosisList(_.head(list)!.id || "", memoDoctorRtdbList);
        updateDisplay(diagnosisList);
    }, [queryTrigger.findQueryTrigger, searchQueryResp.data, selectedDiagnosisQueryResp.data]);

    // 진료 리스트 생성
    function makeDiagnosisList(id: string, diagnosisList: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
        const target = findTarget(id, diagnosisList);
        if (_.isUndefined(target)) return [];
        const targetFamily = findTargetFamily(target, diagnosisList);
        return _.concat(target, targetFamily);
    }

    // 진료 데이터
    function findTarget(id: string, diagnosisList: Array<IOoDiagnosis>): IOoDiagnosis | undefined {
        return _.find(diagnosisList, (d) => _.isEqual(d.id || '', id));
    }

    // 가족 진료 데이터
    function findTargetFamily(target: IOoDiagnosis, diagnosisList: Array<IOoDiagnosis>) {
        const predicate = (item: IOoDiagnosis, target: IOoDiagnosis) => isMyFamilyMember(target, item);
        const findFamilyList = (target: IOoDiagnosis) => filterFamilyDiagnosisReversed(diagnosisList, (d) => predicate(d, target));
        return findFamilyList(target);
    }

    return {
        findTarget,
        findTargetFamily,
        displayList,
        memoDoctorRtdbList,
    }
}