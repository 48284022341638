import _ from "lodash";
import defaultHours from "@assets/defaultHours.json";
import {useCallback, useState} from "react";
import {DoctorWorkHourInfo} from "../../../../interfaces";
import {IOoWorkHours} from "@models/pharmacy";

export const useDiagnosisHoursSettingHook = () => {

    const defaultTime = _.cloneDeep(defaultHours.doctorHours) as IOoWorkHours[];
    const defaultHoliday = defaultTime.splice(0, 1);
    const defaultLunchTime = defaultTime.splice(defaultTime.length - 1, defaultTime.length);
    const defaultResult = _.concat(defaultTime, defaultHoliday, defaultLunchTime);

    const [data, setData] = useState<DoctorWorkHourInfo>({
        doctorHours: defaultResult,
    });

    const onDefaultTimeChanged = useCallback((item: IOoWorkHours) => {
        const index = _.findIndex(data.doctorHours, data => _.isEqual(data.dayOfWeek, item.dayOfWeek));
        data.doctorHours[index] = item;
        let sortedList = _.sortBy(data.doctorHours, "dayOfWeek");
        setData({...data, doctorHours: sortedList});
    }, [data.doctorHours]);

    return {
        data,
        defaultResult,
        onDefaultTimeChanged
    }
}