import {AbstractOoParam} from "@api/params";

class OoParamPaypleRefund extends AbstractOoParam {

    public readonly paymentId: string;
    public readonly paymentType: number;

    constructor(paymentId: string, paymentType: number) {
        super();
        this.paymentId = paymentId;
        this.paymentType = paymentType;
    }

    getPathParams(): string {
        return super.getPathParams();
    }
}

export {
    OoParamPaypleRefund
}