import _ from "lodash";
import {IOoDiagnosis, OoDiagnosisType, OoTelemedicineType} from "../../../../../../api";
import React from "react";
import styled from "styled-components";

interface Props {
    item: IOoDiagnosis,
    flex?: number,
}

export const DiagnosisType = ({item, flex}: Props) => {
    return (
        <FlexDiv flex={flex ?? 0}>
            {
                (_.isEqual(OoDiagnosisType.offline, item.type) ||
                    _.isEqual(OoDiagnosisType.offlineReservation, item.type)) ?
                    '방문' :
                    _.isEqual(OoTelemedicineType.video, item.telemedicineType) ?
                        '화상' : '전화'
            }
        </FlexDiv>
    )
}

const FlexDiv = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
`;