import {
  IOoUnospayDelivery,
  IOoUnospaySalesMenuOption,
  OoDeliveryStatus,
  OoProvider,
} from "@api/models";
import React, { useEffect, useState } from "react";
import { ExcelExportModel } from "../model";
import { usePaymentCalendarHook } from "@hooks/unospay";
import moment from "moment";
import _, { words } from "lodash";
import FileSaver, { saveAs } from "file-saver";
import { exCodeNumToStr, listProviderFilter } from "../../ExcelDownloadModal";
import { IOoProducts } from "@api/models/productSetting/product";
const ExcelJS = require("exceljs");

/**
 * @description 정식품 발주서 양식 다운로드
 */

// 거치대 선택 가능 제품
const checkHolder = (code: string, optionCode: string) => {
  if(code === 'OF29_04' || code === 'OF29_05') {
    if(optionCode === '201') {
      return '1';
    } else if(optionCode === '202') {
      return '0';
    }
  }
};

const getProduct = (externalCode: string, productList: IOoProducts[]) => {
  const product = productList.find(p => p.externalCode === externalCode);
  return product;
}

export const jspOrder = async (list: Array<IOoUnospayDelivery>, productList: IOoProducts[]) => {
  const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.jspProvider));
  
  let data_array: Array<ExcelExportModel> = [];
  const filterDatas = excelList.filter((item) =>
  item.transportInfo.some((i) => i.providerName === "정식품")
  );

  
  filterDatas.map((data) => {
    if (
      data.status === OoDeliveryStatus.existAddress ||
      data.status == OoDeliveryStatus.sendPackage
    ) {
      data.salesMenu?.forEach((info, i) => {
        const model = new ExcelExportModel();
          const { menuNameCh, salesOption, externalCode } = info;
          const phone = data.mobile!.split('-').join('');
          if (salesOption && salesOption.length > 0) {
            
            salesOption.forEach((optionData: IOoUnospaySalesMenuOption) => {
              // const productCode = `${externalCode}_${optionData.externalCode}`;
              model.product = getProduct(externalCode!, productList)!.productName;
              model.tel1 = phone.replace("\t", "").trim();
              model.address1 = data.address;
              model.holderCnt = checkHolder(externalCode!, optionData.externalCode!);
              model.address2 = data.addressDetail;
              model.name = data.recipientName;
              model.exCode = externalCode;
              model.memo = data.memo;
              model.option = optionData.optionName === '필요' ? '1' : '';
            });
          } else {
            model.product = getProduct(info.externalCode!, productList)!.productName;
            model.tel1 = phone.replace("\t" , "").trim();
            model.address1 = data.address;
            model.address2 = data.addressDetail;
            model.name = data.recipientName;
            // model.holderCnt = checkHolder(externalCode!, optionData.externalCode!);
            model.exCode = externalCode;
            model.memo = data.memo;
          }
          data_array.push({ ...model });
        });
    }
  });
  
  const idGroupData = _(data_array).groupBy(data => data.address1).map((value) => (value)).value();
  const exCodeGroupData = idGroupData.map(item => _(item).groupBy(data => data.product).map((value => value)).value());

  const groupingExcelData = exCodeGroupData.map(item => {
    return item.map((list) => {
        const productCnt = list.length;
        return {...list[0], quantity:productCnt.toString()}
    });
}).flatMap(item => item);
  
  const excelFileExtension = ".xlsx";
  const excelFileName = `tlc헬스케어 발주서`;

  const arrayBufferTKT = await (await fetch("/assets/jsp.xlsx")).arrayBuffer();
  const workbookJSP = new ExcelJS.Workbook();

  await workbookJSP.xlsx.load(arrayBufferTKT);
  const worksheet = workbookJSP.getWorksheet("Sheet1");
  const rowNumber = 6;
  const rowDate = worksheet.getRow(3);
  const cellDate = rowDate.getCell(3);
  cellDate.value = `${moment().format("MM/DD")} 발주서`;

  groupingExcelData.forEach((data: ExcelExportModel, index: number) => {
    const row = worksheet.getRow(rowNumber + index);

    [
      ``,
      ``,
      index + 1,
      data.product,
      data.quantity,
      data.name,
      `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}`,
      "",
      "",
      `${data.address1} ${data.address2}`,
      data.memo,
      data.option,
    ].forEach((value, index) => {
      const cell = row.getCell(index + 1);
        cell.value = value;
    });
  });
  const excelBuffer = await workbookJSP.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFile,
    excelFileName + "_" + moment().format("YYYYMMDD") + "(정식품)" + excelFileExtension
  );
};

export default jspOrder;

