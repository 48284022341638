import styled from "styled-components";
import classNames from "classnames/bind";
import buttonStyles from "../../../styles/common/Button.module.scss";
import React, {useEffect} from "react";
import fontStyles from "../../../styles/common/Font.module.scss";
import _ from "lodash";
import {useRecoilValue} from "recoil";
import {editSelectorFamily} from "@recoils/status";
import {useCardEdit} from "@hooks/common";

interface Props {
    children: JSX.Element;
    buttonHidden: boolean;
    editButton?: boolean;
    submitButton?: boolean;
    reset?: boolean;
    isEdit?: (trigger: boolean) => void;
    title?: string;
    subTitle?: string;
    component?: string;
    hidden?: boolean;
}

export const CustomCard = ({
                               children,
                               buttonHidden,
                               title,
                               subTitle,
                               component,
                               hidden,
                           }: Props) => {
    const fx = classNames.bind(fontStyles);
    const bx = classNames.bind(buttonStyles);

    const {isEdit, isSubmit, isReset} = useCardEdit(
        _.isUndefined(component) ? "" : component
    );
    const edit = useRecoilValue(
        editSelectorFamily(_.isUndefined(component) ? "" : component)
    );

    const onEdit = (e: any) => {
        e.preventDefault();
        isEdit();
    };

    const onCancel = (e: any) => {
        e.preventDefault();
        isReset();
    };

    const onSubmit = (e: any) => {
        isSubmit();
    };

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        return () => {
            _.isEqual(edit.isSubmit, true) && isReset();
        };
    }, [onSubmit]);

    return (
        <Card hidden={hidden}>
            <Wrapper>
                <div className={fx("title")}>{title}</div>
                <div className={fx("title2")}>{subTitle}</div>
                <ButtonContainer>
                    {!buttonHidden && (
                        <>
                            <button type="button"
                                    hidden={edit.isCancel}
                                    onClick={onCancel}
                                    className={bx("cancel-button")}>
                                취소
                            </button>
                            <button type="button"
                                    hidden={edit.isCancel}
                                    className={bx("blue-button")}
                                    onClick={onSubmit}>
                                저장
                            </button>
                            <button type="button"
                                    onClick={onEdit}
                                    hidden={edit.isEdit}
                                    className={bx("blue-button")}>
                                편집
                            </button>
                        </>
                    )}
                </ButtonContainer>
            </Wrapper>
            {children}
        </Card>
    );
};

const Card = styled.div`
  width: 1024px;
  height: auto;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  padding-top: 28px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
  width: 300px;
`;
