import {AbstractOoParam} from "../base";
import {IOoReservationHour} from "@api/models";
import {IOoWorkHours} from "@models/pharmacy";


class OoParamDoctorUpdate extends AbstractOoParam {
    public id: string = '';

    //이름
    public name?: string = undefined;

    //성별 (male, female)
    public gender?: string = undefined;

    //전화번호
    public tel?: string = undefined;

    //핸드폰
    public mobile?: string = undefined;

    //정산여부
    isCalculateAccount: boolean = false;

    //소개
    public bio?: string = undefined;

    //학력
    public education?: string = undefined;

    //경력
    public career?: string = undefined;

    //라이센스 번호
    public license?: string = undefined;

    //서비스 타입 (b2c 노출 여부)
    public serviceType?: number = undefined;

    //진료과
    public departments?: Array<string> = undefined;

    //진료테마
    public theme?: Array<string> = undefined;

    //사진
    public pictures?: Array<string> = undefined;

    //화상 진료 가능 여부
    public isVideoDiagnosis?: boolean = undefined;

    //알림톡 수신 번호/
    public alimTalkReceivers?: Array<string> = undefined;

    //정산 은행명
    public bank?: string = undefined;

    //계좌번호
    public bankAccount?: string = undefined;

    //예금주
    public bankHolderName?: string = undefined;

    //진료시간
    public doctorHours?: Array<IOoWorkHours> = undefined;

    //비디오 링크
    public videoLink?: string = undefined;

    //비디오 아이디
    public videoId?: string = undefined;

    //비디오 패스워드
    public videoPassword?: string = undefined;

    //예약 기능 노출
    public isUseReservation?: boolean = undefined;

    //당일 예약 허용
    public isAllowTodayReservation?: boolean = undefined;

    //예약 가능 인원
    public reservationHeadCount?: number = undefined;

    //예약 가능 시간대
    public reservationTime?: Array<string> = undefined;

    //예약 시간
    public reservationHours?: Array<IOoReservationHour> = undefined;

    //지역 검색 키워드
    public regionKeywordList?: Array<string> = undefined;
}

export {
    OoParamDoctorUpdate
};
