import React from "react";
import _ from "lodash";
import styled from "styled-components";
import {DiagnosisInfoStatus} from "@api/models";
import {formatPrice} from "@hooks/format";

interface Props {
    status: DiagnosisInfoStatus,
}

export const DisplayDiagnosisInfo = ({status}: Props) => {
    return (
        <Wrapper>
            <div>진료 정보</div>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>진료비</td>
                    <td>{_.isUndefined(status.price) ? '없음' : `${formatPrice(status.price)}원`}</td>
                </tr>
                <tr>
                    <td>진료 내용</td>
                    <td>{_.isUndefined(status.comment) ? '-' : status.comment}</td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-top: 48px;

  & > div {
    font-weight: 700;
    font-size: 16px;
  }
`;

const Table = styled.table`
  margin-top: 8px;
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: 100%;
  height: auto;
  font-size: 13px;
  font-weight: 700;

  tr {
    border-bottom: 1px solid #C8D6F8;
    height: 50px;
  }

  td:nth-child(2n-1) {
    background: #F4F6FB;
    width: 10%;
    text-align: center;
  }

  td:nth-child(2n) {
    font-weight: normal;
    padding: 10px;
    font-size: 13px;
  }
`;