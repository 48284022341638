import {IOoDiagnosis, OoDiagnosisStatus, OoWapleReservationType} from "@api/models";
import _ from "lodash";
import {formatPrice} from "@hooks/format";

export interface PayStatusData {
    title: string,
    subTitle?: string,
    color: PayStatusColor,
}

export enum PayStatusColor {
    success = "#536DA3",
    fail = "#CF5D5D",
    pause = "#949390",
    default = "#000000",
}

//3월 이전 데이터 페이크 처리
export const PAYPLE_PAY_SUCCEESS_FAKE_CODE = 'ZERO PAY';
export const PAYPLE_PAY_SUCCEESS_CODE = 'SPCD0000';

export const PAYPLE_PAY_REFUND_CODE = 'PAYC0000';

export const useDiagnosisPayFilter = (diagnosis: IOoDiagnosis) => {

    // 진료비 결제 성공 여부
    function isDiagnosisPaySucceed(): boolean {
        return !isDiagnosisRefundSucceed() &&
            (_.isEqual(diagnosis.diagnosisPayCode, PAYPLE_PAY_SUCCEESS_CODE)
                || _.isEqual(diagnosis.diagnosisPayCode, PAYPLE_PAY_SUCCEESS_FAKE_CODE));
    }

    // 진료비 결제 환불 성공 여부
    function isDiagnosisRefundSucceed(): boolean {
        return _.isEqual(diagnosis.diagnosisPayCode, PAYPLE_PAY_REFUND_CODE);
    }

    // 대면 접수
    function isVisitReservationType(): boolean {
        return _.isEqual(diagnosis.reservationType || OoWapleReservationType.all, OoWapleReservationType.visitReservation) ||
            _.isEqual(diagnosis.reservationType || OoWapleReservationType.all, OoWapleReservationType.visitRegister)
    }

    // 진료 완료 또는 취소 여부
    function isDiagnosisCompleted(): boolean {
        return diagnosis.status < OoDiagnosisStatus.diagnosisComplete || diagnosis.status > OoDiagnosisStatus.medicineTakeOk;
    }

    // 진료비 0원 여부
    function isZeroPay(): boolean {
        return _.isEqual(diagnosis.price, 0);
    }

    function getDiagnosisPayStatus(): PayStatusData {
        const price = diagnosis.price || 0;

        if (isVisitReservationType()) {
            if (isDiagnosisCompleted()) {
                return {
                    title: `${formatPrice(price)}원`,
                    color: PayStatusColor.pause
                };
            }else{
                if(!isDiagnosisPaySucceed()){
                    return {
                        title: `${formatPrice(price)}원 결제실패`,
                        color: PayStatusColor.fail
                    };
                }else if(isDiagnosisRefundSucceed()){
                    return {
                        title: `${formatPrice(price)}원 환불완료`,
                        color: PayStatusColor.success
                    };
                }else if(!isDiagnosisRefundSucceed() && !isDiagnosisPaySucceed()){
                    return {
                        title: `${formatPrice(price)}원 환불실패`,
                        color: PayStatusColor.fail
                    };
                }else{
                    return {
                        title: isZeroPay() ? `${formatPrice(price)}원` : `${formatPrice(price)}원 결제완료`,
                        color: PayStatusColor.success
                    };
                }
            }
        } else {
            if (isDiagnosisCompleted()) {
                return {
                    title: `${formatPrice(price)}원`,
                    color: PayStatusColor.pause
                };
            }else{
                if(!isDiagnosisPaySucceed()){
                    return {
                        title: `${formatPrice(price)}원 결제실패`,
                        color: PayStatusColor.fail
                    };
                }else if(isDiagnosisRefundSucceed()){
                    return {
                        title: `${formatPrice(price)}원 환불완료`,
                        color: PayStatusColor.success
                    };
                }else if(!isDiagnosisRefundSucceed() && !isDiagnosisPaySucceed() ){
                    return {
                        title: `${formatPrice(price)}원 환불실패`,
                        color: PayStatusColor.fail
                    };
                }else{
                    return {
                        title: isZeroPay() ? `${formatPrice(price)}원` : `${formatPrice(price)}원 결제완료`,
                        color: PayStatusColor.success
                    };
                }
            }
        }
    }

    return {
        getDiagnosisPayStatus
    }
}