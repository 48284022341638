import {AxiosInstance} from "axios";
import {IOoDataResponse, IOoResponse} from "../reponses";
import {endPoint} from "./base";
import {
  OoDailyDiagnosisRecordRange,
  OoParamAddMedicinePrice,
  OoParamDailyDiagnosisRecord,
  OoParamDailyDiagnosisRecordById,
  OoParamDiagnosisStatus,
  OoParamId,
  OoParamPrescriptionCreate,
} from "../params";
import {IOoDiagnosis} from "../models";
import {OoDailyTargetDiagnosisRecordRange, OoParamPrescriptionModify} from "@api/params";

class DoctorOnTelemedicineApi {
  constructor(private readonly axiosInstance: AxiosInstance) {
  }

  /**
   * 진료 시작 알림톡
   */
  async sendDiagnosisStart(param: OoParamId): Promise<IOoResponse> {
    return await this.axiosInstance.post<IOoResponse>(endPoint.telemedicine.sendDiagnosisStart, param);
  }

  /**
   * 약제비 전송
   */
  async addMedicinePrice(param: OoParamAddMedicinePrice): Promise<IOoResponse> {
    return await this.axiosInstance.post<IOoResponse>(endPoint.telemedicine.addMedicinePrice, param);
  }

  /**
   * 진료 상태 변경
   */
  async diagnosisStatus(param: OoParamDiagnosisStatus): Promise<IOoResponse> {
    return await this.axiosInstance.put<IOoResponse>(endPoint.telemedicine.diagnosisStatus, param);
  }

  /**
   * 진료 삭제
   */
  async deleteDiagnosis(param: OoParamId): Promise<IOoResponse> {
    const url = `${endPoint.telemedicine.deleteDiagnosis}/${param.getPathParams()}`;
    return await this.axiosInstance.delete<IOoResponse>(url);
  }

  /**
   * 처방전 전송
   */
  async prescription(param: OoParamPrescriptionCreate): Promise<IOoResponse> {
    return await this.axiosInstance.post<IOoResponse>(endPoint.telemedicine.prescription, param);
  }

  /**
   * 처방전 수정
   */
  async prescriptionModify(param: OoParamPrescriptionModify): Promise<IOoResponse> {
    return await this.axiosInstance.post<IOoResponse>(endPoint.telemedicine.prescriptionModify, param);
  }

  /**
   * 처방전 저장
   */
  async save(param: OoParamPrescriptionCreate): Promise<IOoResponse> {
    return await this.axiosInstance.post<IOoResponse>(endPoint.telemedicine.save, param);
  }

  /**
   * 관리자, 마스터 약국 일일 진료 데이터
   */
  async dailyDiagnosisRecord(param: OoParamDailyDiagnosisRecord): Promise<IOoDataResponse<IOoDiagnosis[]>> {
    const url = `${endPoint.telemedicine.dailyDiagnosisRecord}/${param.getPathParams()}`;
    return await this.axiosInstance.get<IOoDataResponse<IOoDiagnosis[]>>(url);
  }

  /**
   * 개별 약국 일일 진료 데이터
   */
  async dailyDiagnosisPharmacyRecord(param: OoParamDailyDiagnosisRecordById): Promise<IOoDataResponse<IOoDiagnosis[]>> {
    const url = `${endPoint.telemedicine.dailyDiagnosisPharmacyRecord}/${param.getPathParams()}`;
    return await this.axiosInstance.get<IOoDataResponse<IOoDiagnosis[]>>(url);
  }

  /**
   * 개별 의사 일일 진료 데이터
   */
  async dailyDiagnosisDoctorRecord(param: OoParamDailyDiagnosisRecordById): Promise<IOoDataResponse<IOoDiagnosis[]>> {
    const url = `${endPoint.telemedicine.dailyDiagnosisPharmacyRecord}/${param.getPathParams()}`;
    return await this.axiosInstance.get<IOoDataResponse<IOoDiagnosis[]>>(url);
  }

  /**
   * 일별 진료 내역 리스트 (관리자, 마스터 약국) - 범위 선택, 최대 한달까지 조회
   */
  async dailyDiagnosisRecordRange(param: OoDailyDiagnosisRecordRange): Promise<IOoDataResponse<IOoDiagnosis[]>> {
    const url = `${endPoint.telemedicine.dailyDiagnosisRecordRange}/${param.getPathParams()}`;
    return await this.axiosInstance.get<IOoDataResponse<IOoDiagnosis[]>>(url);
  }

  /**
   * 일별 진료 내역 리스트 (의사) - 범위 선택, 최대 한달까지 조회
   */
  async dailyDoctorDiagnosisRecordRange(param: OoDailyTargetDiagnosisRecordRange): Promise<IOoDataResponse<IOoDiagnosis[]>> {
    const url = `${endPoint.telemedicine.dailyDoctorDiagnosisRecordRange}/${param.getPathParams()}`;
    return await this.axiosInstance.get<IOoDataResponse<IOoDiagnosis[]>>(url);
  }

  /**
   * 일별 진료 내역 리스트 (약국) - 범위 선택, 최대 한달까지 조회
   */
  async dailyPharmacyDiagnosisRecordRange(param: OoDailyTargetDiagnosisRecordRange): Promise<IOoDataResponse<IOoDiagnosis[]>> {
    const url = `${endPoint.telemedicine.dailyPharmacyDiagnosisRecordRange}/${param.getPathParams()}`;
    return await this.axiosInstance.get<IOoDataResponse<IOoDiagnosis[]>>(url);
  }
}

export {DoctorOnTelemedicineApi};
