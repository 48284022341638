import React from "react";
import {Navbar} from "./Navbar";
import styled from "styled-components";

interface Props {
    children: JSX.Element,
}

export const HeaderLayout = ({children}: Props) => {
    return (
        <>
            <Navbar/>
            <BodyContainer>
                {children}
            </BodyContainer>
        </>
    )
}

const BodyContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 80px);
  overflow: auto;
  background: #F8F8F8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;