import { OoUnospayChangeDeliveryStatus } from "@api/params/unospay/changeDeliveryStatus";
import { isRequestSucceed } from "@api/reponses";
import { DoctorOnAPI } from "@api/request";
import { useSuspenseHook } from "@hooks/suspense";
import { ChangeDeliveryStatus } from "pages";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useChangeDeliveryStatusQuery = () => {
    const { setLoading, setStopLoading } = useSuspenseHook();
    return useMutation(
        async (param:ChangeDeliveryStatus) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.unospay.changeDeliveryStatus(new OoUnospayChangeDeliveryStatus(param.id, param.status));
                console.log(response);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    toast.success("주문이 변경되었습니다.");
                    return response.data;
                  }
                  return undefined;
            } catch (error: any) {
                throw error;
            }
        }
    )
}