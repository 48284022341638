import {useQuery} from "react-query";
import {DoctorOnAPI, isRequestSucceed} from "../../api";
import {useSuspenseHook} from "../../hooks";
import _ from "lodash";
import {useRecoilState} from "recoil";
import {pharmacyListState} from "../../recoils";

export const usePharmacyListQuery = () => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const [list, setList] = useRecoilState(pharmacyListState);
    return useQuery(
        'operator/pharmacyList',
        async () => {
            try {
                if (!_.isEmpty(list)) return list;
                setLoading();
                const response = await DoctorOnAPI.shared.pharmacy.list();
                setStopLoading();
                if (isRequestSucceed(response)) {
                    setList(response.data || []);
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        }, {
            enabled: true,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always",  // always로 설정하면 마운트 시 마다 매번 refetch 실행,
            staleTime: 0,
            cacheTime: Infinity,
        }
    )
};
