import {AbstractOoParam} from "../base";
import {IOoHospital} from "@api/models";
import {IOoPharmacy, IOoWorkHours} from "@models/pharmacy";

class OoParamHospitalUpdate extends AbstractOoParam {
    id: string = '';

    //병원명
    public name?: string = undefined;

    //알림톡 사용여부
    public useOrderAlimtalk?: boolean = true;

    //주소
    public address?: string = undefined;

    //상세 주소
    public addressDetail?: string = undefined;

    //우편변호
    public zipCode?: string = undefined;

    //전화번호
    public tel?: string = undefined;

    //팩스번호
    public fax?: string = undefined;

    //홈페이지
    public web?: string = undefined;

    //소개
    public bio?: string = undefined;

    //추천인리스트
    public referrerList?:string [];

    //정산 은행명
    public bank?: string = undefined;

    //계좌번호
    public bankAccount?: string = undefined;

    //예금주
    public bankHolderName?: string = undefined;

    //진료과
    public departments?: Array<string> = undefined;

    //사진
    public pictures?: Array<string> = undefined;

    // 로고
    public logo?: string = undefined;

    //검색 키워드
    public regionKeywordList?: Array<string> = undefined;

    //병원 타입
    public type?: number = undefined;

    //mtm 병원 여부
    public isMtmHospital?: boolean = true;

    //코로나 검사 가능 (all: rat, pcr)
    public covidTestType?: number = undefined;

    //소아청소년 코로나 검사 전용
    public isOnlyChildrenCovid?: boolean = undefined;

    public latitude?: string = undefined;

    //경도 (카카오 응답 x)
    public longitude?: string = undefined;

    // 키오스크 사용여부
    public useKiosk?: boolean = undefined;

    // 키오스크 제공 서비스
    public kioskService?: number[] = undefined;

    // 주차서비스 URL
    public parkingUrl?: string = undefined;

    // 주차정보
    public parkingInfo?: string = undefined;

    // 키오스크 한줄 공지 문구
    public kioskNotice?: string = undefined;

    // 키오스크 제공 결제 타입
    public kioskPayType?: number[] = undefined;

    //진료시간
    public hospitalHours?: Array<IOoWorkHours> = undefined;


    /**
     * 병원약 발송 관련 추가 필드
     */

        // 픽업 아이디
    public hospitalMedicinePickupId?: string;

    // 병원약 픽업지 이름
    public hospitalMedicinePickupName?: string = undefined;

    // 병원약 픽업지 주소
    public hospitalMedicinePickupAddress?: string = undefined;

    // 병원약 픽업지 주소 상세
    public hospitalMedicinePickupAddressDetail?: string = undefined;

    // 병원약 픽업지 주소 우편번호
    public hospitalMedicinePickupZipCode?: string = undefined;

    // 병원약 픽업지 전화번호
    public hospitalMedicinePickupTel?: string = undefined;

    // 병원약 픽업지 위도
    public hospitalMedicinePickupLatitude?: string = undefined;

    // 병원약 픽업지 경도
    public hospitalMedicinePickupLongitude?: string = undefined;

    //당일배송
    //당일 배송 지역 검색 키워드
    public todayRegionKeywordList?: Array<string> = undefined;

    //당일배송 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
    public todayRegionKeywordHitCount?: number = undefined;

    //당일배송 가능시간
    public deliverTodayHours?: Array<string> = undefined;

    //당일배송 비용
    public deliverTodayPrice?: number = undefined;

    //당일배송 가능 반경
    public deliverTodayRadius?: number = undefined;

    //택배
    //택배배송 가능시간
    public deliverPackageHours?: Array<string> = undefined;

    //택배 배송 비용
    public deliverPackagePrice?: number = undefined;

    //약배송 타입 목록
    public deliveryTypeList?: Array<number> = undefined;

    //직접픽업 가능시간
    public deliverPickupHours?: Array<string> = undefined;

    updateClearAll() {
        this.hospitalMedicinePickupId = '';
        this.hospitalMedicinePickupName = '';
        this.hospitalMedicinePickupAddress = '';
        this.hospitalMedicinePickupAddressDetail = '';
        this.hospitalMedicinePickupZipCode = '';
        this.hospitalMedicinePickupTel = '';
        this.hospitalMedicinePickupLatitude = '';
        this.hospitalMedicinePickupLongitude = '';
        this.todayRegionKeywordList = [];
        this.todayRegionKeywordHitCount = 0;
        this.deliverTodayHours = [];
        this.deliverTodayPrice = 0;
        this.deliverPackageHours = [];
        this.deliverPackagePrice = 0;
        this.deliveryTypeList = [];
        this.deliverPickupHours = [];
    };

    updateHospital(data: IOoHospital) {
        this.hospitalMedicinePickupId = data.id || '';
        this.hospitalMedicinePickupName = data.name;
        this.hospitalMedicinePickupAddress = data.address;
        this.hospitalMedicinePickupAddressDetail = data.addressDetail;
        this.hospitalMedicinePickupZipCode = data.zipCode;
        this.hospitalMedicinePickupTel = data.tel;
        this.hospitalMedicinePickupLatitude = data.latitude;
        this.hospitalMedicinePickupLongitude = data.longitude;
        this.todayRegionKeywordList = [];
        this.todayRegionKeywordHitCount = 0;
        this.deliverTodayHours = [];
        this.deliverTodayPrice = 0;
        this.deliverPackageHours = [];
        this.deliverPackagePrice = 0;
        this.deliveryTypeList = [];
        this.deliverPickupHours = [];
    };

    updatePharmacy(data: IOoPharmacy) {
        this.hospitalMedicinePickupId = data.id || '';
        this.hospitalMedicinePickupName = data.name;
        this.hospitalMedicinePickupAddress = data.address || '';
        this.hospitalMedicinePickupAddressDetail = data.addressDetail || '';
        this.hospitalMedicinePickupZipCode = data.zipCode || '';
        this.hospitalMedicinePickupTel = data.tel || '';
        this.hospitalMedicinePickupLatitude = data.latitude || '';
        this.hospitalMedicinePickupLongitude = data.longitude || '';
        this.todayRegionKeywordList = data.todayRegionKeywordList || [];
        this.todayRegionKeywordHitCount = data.todayRegionKeywordHitCount || 0;
        this.deliverTodayHours = data.deliverTodayHours || [];
        this.deliverTodayPrice = data.deliverTodayPrice || 0;
        this.deliverPackageHours = data.deliverPackageHours || [];
        this.deliverPackagePrice = data.deliverPackagePrice || 0;
        this.deliveryTypeList = data.deliveryTypeList || [];
        this.deliverPickupHours = data.deliverPickupHours || [];
    }
}


export {
    OoParamHospitalUpdate
}