import React from "react";
import {HospitalState} from "@api/index";
import styled from "styled-components";

interface Props {
    status: HospitalState,
}

export const DiagnosisHospitalInfo = ({status}: Props) => {
    return (
        <Wrapper>
            <div><b>진료 병원 정보</b></div>
            <table className='diagnosisHospital-table'>
                <thead></thead>
                <tbody>
                <tr>
                    <td>병원이름</td>
                    <td>{status.hospitalName}</td>
                    <td>전화번호</td>
                    <td>{status.hospitalTel}</td>
                    <td>주소</td>
                    <td>{status.hospitalAddress}</td>
                </tr>
                </tbody>
            </table>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-top: 48px;

  .diagnosisHospital-table {
    border-top: 2px solid #C8D6F8;
    border-bottom: 1px solid #C8D6F8;
    width: 100%;
    height: 48px;
    font-size: 13px;
    margin-top: 8px;

    tr > td {
      &:nth-child(2n-1) {
        background: #F4F6FB;
        text-align: center;
        font-weight: 700;
        width: 100px;
      }

      &:nth-child(2) {
        width: 15%;
        padding: 10px;
        overflow: auto;
      }

      &:nth-child(4) {
        width: 15%;
        padding: 10px;
        overflow: auto;
      }

      &:nth-child(6) {
        padding: 10px;
        overflow: auto;
      }
    }
  }
`;