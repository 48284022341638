import {atom} from "recoil";
import {IOoDoctor} from "@models/doctor";

interface SearchProps {
    userName?: string,
    list: Array<IOoDoctor>,
}

export const doctorSearchByNameState = atom<SearchProps>({
    key: 'doctorSearchByNameState',
    default: {
        userName: '',
        list: [],
    }
});