import { useSuspenseHook } from "../../hooks";
import { useMutation } from "react-query";
import { DoctorOnAPI, isRequestSucceed } from "../../api";
import { ChangeOrderPlatform } from "../../pages/Operator/UnospayOrderDetailPage/components/RecipientInfo";
import { OoUnospayChangeOrderPlatform } from "@params/unospay/changeOrderPlatform";
import { toast } from "react-toastify";

export const useChangeOrderPlatformQuery = () => {
  const { setLoading, setStopLoading } = useSuspenseHook();
  return useMutation(
    "unospay/changeOrderPlatform",
    async (data: ChangeOrderPlatform) => {
      try {
        setLoading();
        const param = new OoUnospayChangeOrderPlatform();
        param.id = data.id;
        param.orderPlatform = data.type;
        const response = await DoctorOnAPI.shared.unospay.changeOrderPlatform(
          param
        );
        setStopLoading();
        if (isRequestSucceed(response)) {
          toast.success("구매경로가 변경되었습니다.");
          return response.data;
        }
        return undefined;
      } catch (e: any) {
        throw e;
      }
    }
  );
};
