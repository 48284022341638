import {ChangeEvent, useState} from "react";
import {DoctorBasicInfo} from "../../../../interfaces";

export const useBasicInfoSettingHook = () => {
    const [items, setItems] = useState<DoctorBasicInfo>({
        email: '',
        password: '',
        name: '',
        mobile: '',
        pictures: [],
        isCalculateAccount: true,
    });

    const onChangeEmail = (e: any) => setItems({...items, email: e.target.value});
    const onChangePassword = (e: any) => setItems({...items, password: e.target.value});
    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => setItems({...items, name: e.target.value});
    const onChangeMobile = (e: any) => setItems({...items, mobile: e.target.value});
    const onChangePicture = (target: string) => setItems({...items, pictures: [target]});
    const onChangeCheckBox = (e:ChangeEvent<HTMLInputElement>) => {
        if(e.target.value === '1'){
            return setItems({...items, isCalculateAccount:true});
        }else{
            return setItems({...items,isCalculateAccount:false});
        }
    };
    return {
        items,
        setItems,
        onChangeEmail,
        onChangePassword,
        onChangeName,
        onChangeMobile,
        onChangePicture,
        onChangeCheckBox
    }
}