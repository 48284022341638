// 전체 처방 접수
import {IOoDiagnosis, OoDiagnosisStatus, OoKakaoQuickStatus, OoMedicineDeliveryType} from "@api/models";
import _ from "lodash";

export function filterReceiveList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
    return list;
}

// 약 조제 신청
export function filterMedicineRequestList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
    const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.decidedDeliveryType);
    const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.todayDelivery);
    const predicate3 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.packageDelivery);
    const predicate4 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.pharmacyPackage);
    const predicate5 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.kaKaoMobility);

    return _.filter(list, (d) => predicate1(d) && (predicate2(d) || predicate3(d) || predicate4(d) || predicate5(d)));
}

// 약제비 결제 대기
export function filterMedicinePaymentWaitList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
    return _.filter(list, (d) => _.isEqual(d.status, OoDiagnosisStatus.medicineDeliveryPaymentWait));
}

// 약제비 결제 완료
export function filterMedicinePaymentOkList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
    return _.filter(list, (d) => _.isEqual(d.status, OoDiagnosisStatus.medicineDeliveryPaymentOk));
}

// 픽업 대기
export function filterMedicinePickupWaitList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
    const predicate1 = (d: IOoDiagnosis) =>
        _.isEqual(OoMedicineDeliveryType.pharmacyPackage, d.deliveryType) ||
        _.isEqual(OoMedicineDeliveryType.packageDelivery, d.deliveryType) ||
        _.isEqual(OoMedicineDeliveryType.kaKaoMobility, d.deliveryType);
    const predicate2 = (d: IOoDiagnosis) => _.isEqual(OoDiagnosisStatus.medicineDeliveryAccept, d.status);
    return _.filter(list, (d) => predicate1(d) && predicate2(d));
}

// 당일 배송 건
export function filterMedicineTodayDeliveryList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
    const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.medicineDeliveryAccept);
    const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.todayDelivery);
    return _.filter(list, (d) => predicate1(d) && predicate2(d));
}

// 택배 건
export function filterMedicinePackageDeliveryList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
    const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.medicineDeliveryAccept);
    const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.packageDelivery);
    return _.filter(list, (d) => predicate1(d) && predicate2(d));
}

// 방문 수령 건
export function filterMedicinePickupList(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
    const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.status, OoDiagnosisStatus.medicineDeliveryAccept);
    const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.user);
    return _.filter(list, (d) => predicate1(d) && predicate2(d));
}

// 배송 취소
export function filterMedicineDeliveryCancel(list: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
    const predicate1 = (d: IOoDiagnosis) => _.isEqual(d.deliveryType, OoMedicineDeliveryType.kaKaoMobility);
    const predicate2 = (d: IOoDiagnosis) => _.isEqual(d.kakaoQuickStatus, OoKakaoQuickStatus.cancelMatchingFailed);
    const predicate3 = (d: IOoDiagnosis) => _.isEqual(d.kakaoQuickStatus, OoKakaoQuickStatus.cancelBeforePayment);
    return _.filter(list, (d) => predicate1(d) && (predicate2(d) || predicate3(d)));
}




