import _ from "lodash";
import {fnKakaoFilter, IOoDiagnosis, OoKakaoQuickStatus, OoMedicineDeliveryType} from "@api/models";
import styled from "styled-components";
import {useEffect, useState} from "react";
import {useKakaoOrderQuery} from "@queries/kakao";
import {useSetRecoilState} from "recoil";
import {kakaoDeliveryState} from "@recoils/kakao";

interface Props {
    status: IOoDiagnosis,
}

export const CancelButtons = ({status}: Props) => {
    const [queryTrigger, setQueryTrigger] = useState<boolean>(false);
    const {getKaKaoMobilityStatus} = fnKakaoFilter();
    const setKakaoDelivery = useSetRecoilState(kakaoDeliveryState);
    const beforeMatchedCondition = _.gt(status.kakaoQuickStatus, OoKakaoQuickStatus.request) && _.lte(status.kakaoQuickStatus, OoKakaoQuickStatus.reorder);
    const afterMatchedCondition = _.gte(status.kakaoQuickStatus, OoKakaoQuickStatus.matched) && _.lte(status.kakaoQuickStatus, OoKakaoQuickStatus.cancelAfterPaymentAfterMatching);
    const resp = useKakaoOrderQuery({
        queryTrigger: queryTrigger,
        orderId: status.deliveryOrderCode ?? '',
        diagnosisId: status.id ?? ''
    });

    const onClickPresentStatus = () => {
        setQueryTrigger(true);
    };

    useEffect(() => {
        setQueryTrigger(false);
        if (_.isUndefined(resp.data)) {
            return;
        }
        if (resp.isSuccess) {
            const status = getKaKaoMobilityStatus(resp.data || '');
            setKakaoDelivery(status);
        }
    }, [getKaKaoMobilityStatus, resp, setKakaoDelivery]);


    return (
        <>
            {
                (_.isEqual(status.deliveryType, OoMedicineDeliveryType.kaKaoMobility) && _.gt(status.kakaoQuickStatus, OoKakaoQuickStatus.request))
                &&
                <ButtonContainer>
                    <button className="button" disabled={afterMatchedCondition && true}>배송 취소 수수료 재청구</button>
                    <button className="button" disabled={afterMatchedCondition && true}>배정 후 취소 환불</button>
                    <button className="button"
                            disabled={afterMatchedCondition && true}
                            hidden={beforeMatchedCondition && true}>
                        배정 전 취소
                    </button>
                    {
                        !_.isEqual(status.deliveryOrderCode, '') &&
                        <button className="button" onClick={onClickPresentStatus}>상태 조회</button>
                    }
                </ButtonContainer>
            }
        </>
    )
}

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .button {
    width: 140px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D3D9E4;
    border-radius: 8px;
    margin-left: 8px;
    font-weight: 700;
    font-size: 13px;

    &:disabled {
      color: #D3D9E4;
    }
  }
`;