import React, {useMemo} from "react";
import {useRecoilValue} from "recoil";
import styled from "styled-components";
import _ from "lodash";
import {CustomCard} from "@components/custom";
import {selectedUserState} from "@recoils/oauth";
import {DetailContainer} from "@components/detailContainer";
import {
    BankInfo,
    DoctorAlimTalk,
    DoctorCareer,
    DoctorDiagnosisTime,
    DoctorInfo,
    DoctorService,
    DoctorVideo,
    HospitalInfo,
    HospitalTargetPharmacy
} from "@components/infoForm";
import {HospitalPictures} from "@components/hospitalPictures";
import {useDoctorFunctionHook} from "@hooks/doctor";
import {useHospitalFunctionHook} from "@hooks/hospital";

export const DoctorInfoPage = () => {
    const selectedUser = useRecoilValue(selectedUserState);
    const selectedUserId = useMemo(() => {
        return selectedUser?.user.id || '';
    }, [selectedUser]);

    const {doctorList, getDoctorByUserId} = useDoctorFunctionHook();
    const {hospitalList, getHospitalById} = useHospitalFunctionHook();

    const doctorInfo = useMemo(() => {
        return getDoctorByUserId(selectedUserId);
    }, [doctorList, selectedUserId]);

    const hospitalInfo = useMemo(() => {
        if (_.isUndefined(doctorInfo)) return undefined;
        return getHospitalById(doctorInfo.hospitalId);
    }, [doctorInfo, hospitalList]);

    return (
        <DetailContainer>
            <>
                {
                    (!_.isUndefined(hospitalInfo) && !_.isUndefined(doctorInfo)) &&
                    <Contents>
                        <CardContainer>
                            <CustomCard children={<HospitalInfo data={hospitalInfo}
                                                                componentName="targetHospitalInfo"/>}
                                        buttonHidden={false}
                                        title="병원 기본 정보 [필수]"
                                        subTitle="병원 로고 [필수]"
                                        component="targetHospitalInfo"/>
                            <CustomCard children={<HospitalPictures data={hospitalInfo}
                                                                    componentName="hospitalTargetLogoInfo"/>}
                                        buttonHidden={false}
                                        title="병원 사진 [필수]"
                                        component="hospitalTargetLogoInfo"/>
                            <CustomCard children={<BankInfo data={hospitalInfo}
                                                            target="hospital"
                                                            componentName="targetHospitalBankInfo"/>}
                                        buttonHidden={true}
                                        title="병원 정산 은행 정보 [필수]"
                                        component="targetHospitalBankInfo"/>
                            <CustomCard children={<HospitalTargetPharmacy targetHospital={hospitalInfo}
                                                                          componentName="hospitalTargetPharmacy"/>}
                                        buttonHidden={true}
                                        title="지정 약국 [필수]"
                                        component="hospitalTargetPharmacy"/>
                            <CustomCard children={<DoctorInfo data={doctorInfo} componentName="doctorInfo"/>}
                                        buttonHidden={false}
                                        title="의사 기본 정보 [필수]"
                                        subTitle="프로필 이미지"
                                        component="doctorInfo"/>
                            <CustomCard children={<DoctorAlimTalk data={doctorInfo}
                                                                  componentName="doctorAlimTalkInfo"/>}
                                        buttonHidden={false}
                                        title="알림톡 수신 전화번호 [필수]"
                                        component="doctorAlimTalkInfo"/>
                            <CustomCard children={<DoctorCareer data={doctorInfo}
                                                                componentName="doctorCareerInfo"/>}
                                        buttonHidden={false}
                                        title="의사 경력 정보 [필수]"
                                        component="doctorCareerInfo"/>
                            <CustomCard children={<DoctorService data={doctorInfo}
                                                                 componentName="doctorServiceInfo"/>}
                                        buttonHidden={false}
                                        title="진료 과목 (최대 4개까지 중복 선택 가능)"
                                        component="doctorServiceInfo"/>
                            <CustomCard children={<DoctorVideo data={doctorInfo}
                                                               componentName="doctorVideoInfo"/>}
                                        buttonHidden={false}
                                        title="화상(zoom) 진료 제공 여부"
                                        component="doctorVideoInfo"/>
                            <CustomCard children={<DoctorDiagnosisTime data={doctorInfo}
                                                                       componentName="doctorDiagnosisTime"/>}
                                        buttonHidden={false}
                                        title="비대면 진료시간"
                                        component="doctorDiagnosisTime"/>
                        </CardContainer>
                    </Contents>
                }
            </>
        </DetailContainer>
    )
}

const Contents = styled.div`
  display: flex;
  justify-content: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


