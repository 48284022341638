import _ from "lodash";

// 가격 단위 , 찍어주는 로직
export const formatPrice = (price: number) => {
    const paringParam = _.toString(price);
    if (paringParam.length > 3) {
        let arr = [];
        let num = 1;
        for (let paringParamElement of paringParam) {
            arr.push(paringParamElement);
        }
        let reverseArr = [];
        for (let element of _.reverse(arr)) {
            reverseArr.push(element);
            if (_.eq(num % 3, 0)) {
                reverseArr.push(',');
            }
            num++;
        }

        if (_.isEqual(_.last(reverseArr), ',')) {
            reverseArr.splice(reverseArr.length - 1, reverseArr.length);
        }

        const result = _.reduce(reverseArr, (cur, acc) => {
            return `${acc}${cur}`
        }, '');

        return result;
    }

    return price;
};