import { IOoUnospayDelivery, IOoUnospaySalesMenu, IOoUnospaySalesMenuOption, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _ from 'lodash'
import  {mkOrderData}  from '../../../../../../data/mkOrderData';
import moment from "moment";
import FileSaver from "file-saver";
const ExcelJS = require("exceljs");

const getMkData = (externalCode:string , optionCode?:string) => {
  const {info} = mkOrderData.find(item => externalCode === item.code)!;
  if(info.length > 1){
    return info.filter(i => i.code === optionCode)!;
  }else{
    return info!;
  }
}

const productCustom = (excode:string, saleItem:IOoUnospaySalesMenu, option:IOoUnospaySalesMenuOption) => {
  
  switch(excode){
    case 'OF30_11':
      return {customProduct:`${saleItem.menuName}_${saleItem.salesOption![0].optionName?.split(' ')[0]}` , customOption: `${saleItem.salesOption![1].optionName}`}
    case 'OF30_08':
      return {customProduct:`투웨이 ${saleItem.menuNameCh}` , customOption: `${option.optionName?.split(' ')[0]} ${option.optionName?.split(' ')[1]}`}
    default:
      if(_.isUndefined(saleItem.salesOption)){
        return {customProduct:`${saleItem.menuName}` , customOption: `${option.optionName}`}
      }else{
        const optionList = saleItem.salesOption.map(item =>  !_.isUndefined(item.externalCode) &&  item.optionName);
        return {customProduct:saleItem.menuName, customOption:`${optionList.join(' / ').replace('/ 추가 안함','')}`}
      }
      
  }
}

const createModel = (e:IOoUnospayDelivery) => {
  const model = new ExcelExportModel();
                model.name = e.recipientName;
                model.address1 = e.address;
                model.address2 = e.addressDetail;
                model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                model.zipCode = e.zipCode;
                model.memo = e.memo;
                model.subMemo = e.subMemo;
                model.quantity = "1";
  return model;
}

const mkOrder = async (list:IOoUnospayDelivery[]) => {
  const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.mkProvider)); 
  let data_array: Array<ExcelExportModel> = [];
  
  let i = 0
  excelList.forEach(e => {
    if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
      e.salesMenu?.forEach((saleItem) => {
        if(_.startsWith(saleItem.externalCode , exCodeNumToStr(OoProvider.mkProvider))){
          if(!_.isUndefined(saleItem.salesOption) && saleItem.salesOption!.length > 0){
            saleItem.salesOption!.map(option=> {
              if(option.optionName !== '추가 안함'){
                    let model = createModel(e);
                    model.orderDate = `${moment(e.timestamp).format('YYYYMMDD')}${String(i+1).padStart(4,'0')}`;
                    model.product = saleItem.menuName;
                    model.option = saleItem.externalCode === "OF30_23" ? saleItem.salesOption?.map(sale => sale.optionName).join('/') : option.optionName;
                    const mkData = getMkData(saleItem.externalCode!, option.externalCode);
                    if(mkData && mkData.length > 0){
                      model.price = mkData[0].price.toString();
                      model.deliveryCost = mkData[0].deliveryPrice.toString();
                    }else{
                      return;
                    }
                    data_array.push(model)
                    console.log(model);
                  }
              })
            }else{
                const mkData = getMkData(saleItem.externalCode!);
                  let model = createModel(e);
                  model.orderDate = `${moment(e.timestamp).format('YYYYMMDD')}${String(i+1).padStart(4,'0')}`;
                  model.menu = `${saleItem.menuName}`;
                  model.product = saleItem.menuName;
                  model.price = mkData[0].price.toString();
                  model.deliveryCost = mkData[0].deliveryPrice.toString();
                  data_array.push(model);
              }
          }
        });
        i = i + 1;
      }
    })
    
    const getDeliveryMessage = (data: ExcelExportModel) => {
      console.log(data);
      
      switch (data.subMemo) {
          case "6":
            return data.memo;
          case "2":
            return "자율 출입가능";
          case "7":
            return "무인택배함";
          case "":
            return data.memo;
          default:
            return data.subMemo;
        }
  }

  const excelFileExtension = ".xlsx";
  const excelFileName = `tlc헬스케어 발주서`;

  const arrayBufferMHD = await (await fetch("/assets/mk.xlsx")).arrayBuffer();
  const workbookMK = new ExcelJS.Workbook();
  await workbookMK.xlsx.load(arrayBufferMHD);
  const ws_MK = workbookMK.getWorksheet("멜킨양식");

  data_array.map((data: ExcelExportModel, index: number) => {
    const row = ws_MK.getRow(index + 2); // 엑셀은 1부터 시작
    [
      data.orderDate,
      data.name,
      data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
      `${data.address1} ${data.address2}`,
      data.zipCode,
      data.product,
      data.option,
      Number(data.price),
      "1",
      Number(data.deliveryCost),
      data.memo,
      "",
      "",
      ].forEach((value , index) => {
        const cell = row.getCell(index + 1);
        cell.value = value;
    
        cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        };
    })
  });
  const excelBuffer = await workbookMK.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName + "_" + moment().format("YYYYMMDD") + "(멜킨)" + excelFileExtension
    );
}

export default mkOrder