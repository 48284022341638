import React from 'react'
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import { IOoUnospayDelivery, IOoUnospaySalesMenu, IOoUnospaySalesMenuOption, OoDeliveryStatus, OoProvider } from '@api/models';
import { ExcelExportModel } from '../model';
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
const ExcelJS = require("exceljs");

const addOneExcodeList = ['OF45_07','OF45_08'];

const productList = [
  {name: '뉴욕웨이 WPC' , option:'초코' , exCode:'OF45_01_201'},
  {name: '뉴욕웨이 WPC' , option:'딸기' , exCode:'OF45_01_202'},
  {name: '뉴욕웨이 WPI' , option:'초코' , exCode:'OF45_02_201'},
  {name: '뉴욕웨이 WPI' , option:'딸기' , exCode:'OF45_02_202'},
  {name: '뉴욕웨이라이트' , option:'' , exCode:'OF45_03'},
  {name: '뉴욕웨이몬스터 ' , option:'' , exCode:'OF45_04'},
  {name: '메트리톤게이너' , option:'' , exCode:'OF45_05'},
  {name: '슈퍼컷츠엑스' , option:'' , exCode:'OF45_06'},
  {name: '하드코어버닝티 1+1' , option:'' , exCode:'OF45_07'},
  {name: '하드코어버닝티블랙 1+1' , option:'' , exCode:'OF45_08'},
  {name: '니트로엑스' , option:'' , exCode:'OF45_09'},
  {name: '뉴욕웨이BCAA' , option:'' , exCode:'OF45_10'},
  {name: '닥터큐어 1+1' , option:'' , exCode:'OF45_11'},
  {name: '오츠블랙밀 1+1' , option:'' , exCode:'OF45_12'},
]

const createModel = (e:IOoUnospayDelivery, salesItem:IOoUnospaySalesMenu) => {
  
    const model = new ExcelExportModel();
                  model.name = e.recipientName;
                  model.address1 = e.address;
                  model.address2 = e.addressDetail;
                  model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                  model.zipCode = e.zipCode;
                  model.memo = e.memo;
                  model.subMemo = e.subMemo;
                  model.quantity = "1";
                  model.exCode = salesItem.externalCode;
    return model;
  }

  const findProductName = (salesItem:IOoUnospaySalesMenu) => {
    
    if(!_.isUndefined(salesItem.salesOption) && salesItem.salesOption!.length > 0){
      const mergeExcode = `${salesItem.externalCode}_${salesItem.salesOption.map(sale => sale.externalCode).join('')}`
      if(productList.find(item => item.exCode === mergeExcode)?.option.length !== 0){
        const productName = `${productList.find(item => item.exCode === mergeExcode)?.name}`
        const productOption = `${productList.find(item => item.exCode === mergeExcode)?.option}`
        return `${productName} (${productOption})`;
      }else{
        return `${productList.find(item => item.exCode === mergeExcode)?.name} `;
      }
    }else{
      return productList.find(item => item.exCode === salesItem.externalCode)?.name
    }
  }

  const addOneCheck = (exCode:string) => {

    if(addOneExcodeList.includes(exCode)){
      return true;
    }else{
      return false;
    }
  }

const nutritionOrder = async (list:IOoUnospayDelivery[]) => {
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.nutritionProvider));
    let data_array: Array<ExcelExportModel> = [];
    excelList.forEach((e, index) => {
        if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
            e.salesMenu?.forEach((salesItem, i) => {
                if(_.startsWith(salesItem.externalCode, 'OF45')){
                  
                      let model = createModel(e, salesItem);
                      model.orderDate = `${moment(e.timestamp).format('YYYYMMDD')}-${String(index+1).padStart(7,'0')}`
                      model.product = findProductName(salesItem);
                      if(addOneCheck(salesItem.externalCode!)){
                        data_array.push(model);
                      }else{
                        data_array.push(model);
                      }
                }
              })
        }
    })
    const idGroupData = _(data_array).groupBy(data => data.tel1).map((value) => (value)).value();
    
    const reOrderDateData =  idGroupData.map((data , index) => {
      const date = data[0].orderDate?.split('-')[0];
        return data.map((d) => {
          return {...d, orderDate:`${date}-${String(index+1).padStart(7,'0')}`}
        })
    });

    const excelData = _.flatMap(reOrderDateData,x => x);
    
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferNTRT = await (await fetch("/assets/ntrt1.xlsx")).arrayBuffer();
    const workbookNTRT = new ExcelJS.Workbook();
    await workbookNTRT.xlsx.load(arrayBufferNTRT);
    const ws_ntrt = workbookNTRT.getWorksheet("Sheet1");
    excelData.map((data: ExcelExportModel, index: number) => {
      const row = ws_ntrt.getRow(index + 2);
      [
        data.orderDate,
        data.name,
        data.name,
        data.product,
        data.quantity,
        `${data.address1} ${data.address2}`,
        "",
        data.tel1? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
        "",
        data.memo,
        "",
    ].forEach((value, index) => {
        const cell = row.getCell(index + 1);
        cell.value = value;
      })
    });
    const excelBuffer = await workbookNTRT.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName + "_" + moment().format("YYYYMMDD") + "(뉴트리션팩토리)" + excelFileExtension
    );
}

export default nutritionOrder