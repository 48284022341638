import React, {useEffect, useMemo, useState} from "react";
import {ListSubTitle} from "./components";
import styled from "styled-components";
import classNames from "classnames/bind";
import buttonStyles from "../../../styles/common/Button.module.scss";
import {useListSearchSettingHook} from "./hooks/useListSearchSettingHook";
import {ListTitle} from "@components/listTitle";
import {SearchBar} from "@components/searchBar";
import {useLocation, useNavigate} from "react-router-dom";
import _, {isEmpty, isEqual, last} from "lodash";
import {SearchInfo} from "@recoils/display";
import { useRecoilValue } from "recoil";
import { selectedUserState } from "@recoils/oauth";
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import { IOoCalculateMonthList } from "@api/models/calculate/calculateMonthList";
import FilteredList from "../FilteredList";
import { DoctorOnAPI } from "@api/request";
import { IOoProducts } from "@api/models/productSetting/product";

export interface MainListContainerStyle {
    maxWidth?:number;
    minWidth?:number;
}



export const ListPage = () => {
    const bx = classNames.bind(buttonStyles);
    const [target, setTarget] = useState<string>("");
    const [month, setMonth] = useState(moment().startOf('month').format("YYYYMM"));
    const [dobuleDate, setDobuleDate] = useState([moment().startOf('month').format('YYYY-MM-DD'),moment().endOf('month').format('YYYY-MM-DD')]);
    const selectedUser = useRecoilValue(selectedUserState);
    const {pathname} = useLocation();
    const [sortInfo, setSortInfo] = useState({
        name:'hospitalCode',type:true
    })
    const [searchValue, setSearchValue] = useState({
        filterName:'hospitalCode',
        value:''
    });
    const [productList, setProductList] = useState<IOoProducts[]>([])
    const {
        displayList,
        setDisplayList,
        calculateListResp,
        calculateRawListResp,
        onRegisterDoctor,
        onChangeDeleteButton,
        onClickSearchButton,
        onChangeSearchName,
        list,
        findTargetHospitalName,
        getHospitalName,
        getHospitalTel
    } = useListSearchSettingHook(target);

    const handleClickSorting = (name:'hospitalCode'|'totalPrice'|'totalHospitalCalPrice') => {
        setSortInfo(prev => ({name, type:!prev.type }))
    }

    useEffect(() => {
            calculateListResp.mutate(month)
            calculateRawListResp.mutate({startDate:dobuleDate[0] , endDate:dobuleDate[1]})
    },[month,dobuleDate])

    useEffect(() => {
        if (window.localStorage.getItem('listState') || "") {
            const storage = JSON.parse(window.localStorage.getItem('listState') || "");
            const storeArr = Array.from(storage['listState']) as SearchInfo[];
            const index = storeArr.findIndex((item) => isEqual(item.id, target));
            index > -1 && setDisplayList({id: target, list: list, userName: storeArr[index].id});
        }
    }, []);

    useEffect(() => {
        const path = last(pathname.split('/') || "") || "";
        setTarget(path);
    }, [pathname]);
    
    useEffect(() => {
        isEmpty(displayList.userName) ? setDisplayList({...displayList, id: target, list: list}) : setDisplayList({...displayList, id: target});
    }, [list, target]);

    return (
        <Wrapper>
                    <ListTitle
                        title={isEqual(target, 'doctor') ? '의사 목록' : isEqual(target, 'hospital') ? '병원 목록' : '약국 목록'}>
                        <SearchContainer>
                            <SearchBar name={displayList.userName || ""}
                                    onChangeName={onChangeSearchName}
                                    onChangeDeleteButton={onChangeDeleteButton}
                                    onChangeSubmit={onClickSearchButton}/>
                            <input type="button"
                                onClick={onRegisterDoctor}
                                value="등록"
                                className={bx("blue-button")}/>
                        </SearchContainer>
                    </ListTitle>
                    <ListSubTitle target={target} level={selectedUser && selectedUser.user.level} handleClickSorting={handleClickSorting}/>
                    {
                        (displayList.list || []).length > 0 ?
                        <FilteredList target={target} displayList={displayList} findTargetHospitalName={findTargetHospitalName} sortInfo={sortInfo} getHospitalName={getHospitalName} getHospitalTel={getHospitalTel}/>
                        :
                        <NoListContainer>표시 할 내용이 없습니다.</NoListContainer>
                    }
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
`

const SearchContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

const NoListContainer = styled.div`
height: 100%;
@media (min-height: 768px) {
    height: 585px;
  }

  @media (min-height: 1024px) {
    height: 75%;
  }
`