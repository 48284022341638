import { IOoUnospayDelivery, IOoUnospaySalesMenu, IOoUnospaySalesMenuOption, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _, { create } from 'lodash'
import FileSaver from 'file-saver';
import moment from 'moment';
const ExcelJS = require("exceljs");

const MTSCODE = ['201','202','203'];
const NUTRIENTSCODE = ['OF39_04','OF39_02','OF39_06'];
const FREEBIES_CODE = ['OF39_05','OF39_06'];

const createModel = (e:IOoUnospayDelivery) => {
  const model = new ExcelExportModel();
                model.name = e.recipientName;
                model.address1 = e.address;
                model.address2 = e.addressDetail;
                model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                model.zipCode = e.zipCode;
                model.memo = e.memo;
                model.subMemo = e.subMemo;
  return model;
}

const createProduct = (item: IOoUnospaySalesMenuOption) => {
  if(_.includes(MTSCODE,item.externalCode)){
    return item.optionNameCh?.split('/')[1]
  }else{
    return '쉐이크'
  }
}

const createOption = (salesItem: IOoUnospaySalesMenu) => {
  if(salesItem.salesOption){ 
      const optionList = salesItem.salesOption.map(item => item.optionName === '추가 안 함' ? '' : item.optionName);
      const optionFiltered = optionList.filter(item => item !== '');
      
      if(_.includes(FREEBIES_CODE, salesItem.externalCode)){
          const freeProduct = [...optionFiltered][0];
          const transformedData = _.chain([...optionFiltered,freeProduct])
          .groupBy()
          .map((group, item) => `${item}${group.length}`)
          .value(); 
          return transformedData.join(', ');
      }else{
          const transformedData = _.chain([...optionFiltered])
          .groupBy()
          .map((group, item) => `${item}${group.length}`)
          .value();
          return transformedData.join(', ');
      }
  }else{
      return salesItem.optionName?.split(' ')[0];
  }
}

const ddOrder = async (list:IOoUnospayDelivery[]) => {
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.ddProvider));
    let data_array: Array<ExcelExportModel> = [];
    excelList.forEach(e => {
      if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
            e.salesMenu?.forEach(salesItem => {
              if(_.startsWith(salesItem.externalCode, 'OF39')){
                console.log(salesItem);
                
                if(_.includes(NUTRIENTSCODE,salesItem.externalCode)){
                  salesItem.salesOption?.forEach(item => {
                    const model = createModel(e);
                    model.product = createProduct(item);
                    model.option = createOption(item);
                    model.deliveryCost = e.id;
                    console.log(model);
                    
                    data_array.push(model);
                  })
                }else{
                  //쉐이크만 있을때
                  console.log(e);
                  
                  const model = createModel(e);
                  model.product = '쉐이크';
                  model.option = createOption(salesItem);
                  model.deliveryCost = e.id;
                  console.log(model);
                  data_array.push(model);
                }
              }
            })
        }
    })
    
    const groupData = _(data_array).groupBy(data => data.deliveryCost).map((value, key) => ({id:key, data:value})).value();
    groupData.map(gData => {
      const {data} = gData
      const findData = data.find(d => d.product === '쉐이크');
      if(!_.isUndefined(findData)){
        findData.option = `${findData!.option}, 보틀1`
      }
    });

    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferDD = await (await fetch("/assets/dd.xlsx")).arrayBuffer();
    const workbookDD = new ExcelJS.Workbook();
    await workbookDD.xlsx.load(arrayBufferDD);
    const ws_SM = workbookDD.getWorksheet("sheet1");

    data_array.map((data: ExcelExportModel, index: number) => {
      console.log(data);
      const row = ws_SM.getRow(index + 2); // 엑셀은 1부터 시작
      [
        "",
        data.product,
        data.product,
        data.option,
        "1",
        "",
        "",
        data.name,
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
        "",
        "",
        `${data.address1} ${data.address2}`,
        data.memo,
        "",
        "",
        "",
      ].forEach((value , index) => {
        const cell = row.getCell(index + 1);
        cell.value = value;
      });
    });
    const excelBuffer = await workbookDD.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName + "_" + moment().format("YYYYMMDD") + "(디에트데이)" + excelFileExtension
    );
}

export default ddOrder