import { useSuspenseHook } from "../../hooks";
import { useQuery } from "react-query";
import { DoctorOnAPI, isRequestSucceed } from "../../api";
import { useRecoilState } from "recoil";
import { productListState } from "@recoils/unospay/tlcProductState";
import _ from "lodash";

export const useTlcProductList = () => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
  const [list, setList] = useRecoilState(productListState);
  return useQuery(
    "useTlcProductList", 
    async () => {
      try {
        setLoading();
        const response = 
          await DoctorOnAPI.shared.productSetting.list();
        setStopLoading();
        if (isRequestSucceed(response)) {
          setList(response.data || []);
          return response.data || [];
        }
        return null;
      } catch (e: any) {
        setErrorStatus(e);
      }
    },
    {
      retry: true,
      refetchOnWindowFocus: false,
      refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
      enabled: _.isEmpty(list) // recoil에 데이터가 없을 때만 요청
    }
  );
};
