import {
    diagnosisHospitalState,
    diagnosisPharmacyState,
    getPatientInfo,
    IOoDiagnosis,
    OoDiagnosisStatus,
} from "@api/models";
import {
    DebugInformation,
    DeliveryInfo,
    DeliveryPatientInfo,
    DiagnosisCancel,
    DiagnosisHospitalInfo,
    DiagnosisInfo,
    KakakoTest,
    KakaoFlexerInfo,
    MedicineWayToReceiveInfo,
    PatientInfo,
    PatientRequest,
    PrescriptionForm,
    ReservationInfo,
} from "../../../../../components";
import React from "react";
import _ from "lodash";

interface Props {
    diagnosisData: IOoDiagnosis,
    list: Array<IOoDiagnosis>,
}

export const DiagnosisDetailContainer = ({diagnosisData, list}: Props) => {
    const filteredCancelStatus = () => {
        const predicate1 = _.gte(diagnosisData.status, OoDiagnosisStatus.doctorCancel);
        const predicate2 = _.lte(diagnosisData.status, OoDiagnosisStatus.pharmacistCancel);
        return predicate1 && predicate2;
    };

    return (
        <>
            {
                filteredCancelStatus() &&
                <DiagnosisCancel data={diagnosisData}/>
            }
            <ReservationInfo data={diagnosisData}/>
            <PatientInfo data={diagnosisData}/>
            <PatientRequest data={diagnosisData} ownerData={_.head(list)!}/>
            <DiagnosisHospitalInfo status={diagnosisHospitalState(diagnosisData)}/>
            <PrescriptionForm status={getPatientInfo(diagnosisData)}/>
            <DiagnosisInfo diagnosis={diagnosisData}/>
            <MedicineWayToReceiveInfo status={diagnosisPharmacyState(diagnosisData)}/>
            <DeliveryInfo diagnosis={diagnosisData}/>
            <KakakoTest diagnosis={diagnosisData}/>
            <KakaoFlexerInfo diagnosis={diagnosisData}/>
            <DeliveryPatientInfo diagnosis={diagnosisData}/>
            <DebugInformation diagnosis={diagnosisData}/>
        </>
    );
};
