import { IOoCalculateList } from "@api/models/calculate/calculateList";
import { atom } from "recoil";

const calculateListState = atom<Array<IOoCalculateList>>({
    key:'calculateListState',
    default: [],
});

export {
    calculateListState
};