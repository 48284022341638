import {useRecoilValue} from "recoil";
import {editSelectorFamily} from "@recoils/status";
import {useMemo, useState} from "react";
import {IOoHospital} from "@api/models";
import _ from "lodash";
import {useCardEdit} from "@hooks/common";
import {useHospitalFunctionHook} from "@hooks/hospital";

interface Props {
    data: IOoHospital;
    componentName: string,
}

export const useHospitalInfoHook = ({data, componentName}: Props) => {
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const {isReset} = useCardEdit(componentName);
    const [color, setColor] = useState("white");
    const {updateHospital} = useHospitalFunctionHook();

    const initialData = useMemo(() => {
        return {
            address: data.address,
            zipCode: data.zipCode,
            regionKeywordList: _.isUndefined(data.regionKeywordList) ? '' : _.join(data.regionKeywordList),
            logo: data.logo || "",
            isMtmHospital: data.isMtmHospital,
            referrerList:_.isUndefined(data.referrerList) ? [''] : data.referrerList,
            type:data.type,
            useOrderAlimtalk: data.useOrderAlimtalk,
        }
    }, [data]);

    const [items, setItems] = useState(initialData);

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setItems({...items, [name]: value});
    };

    const onChangePicture = (value: string, title: string) => setItems({...items, logo: value});

    const makeKeywordList = (keyword: string) => {
        const splitArr = _.split(keyword, ",");
        return splitArr.map((item) => _.trim(item));
    };

    return {
        editStatus,
        color,
        setColor,
        items,
        setItems,
        isReset,
        onChangePicture,
        updateHospital,
        makeKeywordList,
        handleChange,
        initialData,
    };
};
