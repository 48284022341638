import React, {MouseEvent, MouseEventHandler, useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {useRecoilState} from "recoil";
import _ from "lodash";
import {useOauthQuery} from '@queries/ouath';
import {loginState} from '@recoils/oauth';

export const SignInPage = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [emailError, setEmailError] = useState<boolean>(false);
    const [login, setLogin] = useRecoilState(loginState);
    const oauth = useOauthQuery(email, password, login);
    
    const onClickLoginButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const emailRegex =
            /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        if (emailRegex.test(email)) {
            setEmailError(false);
            setLogin(true);
        } else {
            setEmailError(true);
        }
    };

    //Enter 검색 Event Trigger
    const onKeyPress = (e: any) => {
        if (_.isEqual(e.key, 'Enter')) {
            const emailRegex =
                /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
            if (emailRegex.test(email)) {
                setEmailError(false);
                setLogin(true);
            } else {
                setEmailError(true);
            }
        }
    };
    
    const handleEmail = (e: any) => setEmail(e.target.value);
    const handlePassword = (e: any) => setPassword(e.target.value);
    
    useEffect(() => {
      if(oauth.dataUpdatedAt !== 0){
        setLogin(false);
        setPassword("");
      }
    },[oauth.dataUpdatedAt])

    return (
        <Wrapper>
            <Container>
                <ImageContainer>
                    {/* <img alt="로고" src={process.env.PUBLIC_URL + 'img/doctoron_web_login_logo.png'}/> */}
                    <img alt="로고" src={process.env.PUBLIC_URL + 'img/tlc-logo.png'}/>
                </ImageContainer>
                <LoginContainer>
                    <InputContainer>
                        <label>
                            <img alt="profile-icon" src={process.env.PUBLIC_URL + 'img/profile-icon.png'}
                                 className="profile-icon"/>
                            <input type="text" placeholder="E-mail" onChange={handleEmail}/>
                        </label>
                    </InputContainer>
                    {
                        emailError && (
                            <div style={{color: "red"}}>
                                올바른 이메일 형식을 입력해 주세요
                            </div>
                        )
                    }
                    <InputContainer>
                        <label>
                            <img alt="lock-icon" src={process.env.PUBLIC_URL + 'img/lock-icon.png'}/>
                            <input type="password" placeholder="Password" onChange={handlePassword} value={password}
                                   onKeyPress={onKeyPress}/>
                        </label>
                    </InputContainer>
                </LoginContainer>
                <ButtonContainer color={email.length > 0 || password.length > 0 ? "#6E8ED1" : "#C2C1BD"}>
                    <button onClick={onClickLoginButton}>Login</button>
                </ButtonContainer>
            </Container>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 660px;
  height: 452px;
  background: #FFFFFF;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 250px;
    height: 60px;
  }
`;

const LoginContainer = styled.div`
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const InputContainer = styled.div`
  width: 60%;
  height: 30%;
  border-bottom: 0.5px solid #C2C1BD;

  & > label {
    display: flex;
    align-items: center;
  }

  & > label .profile-icon {
    color: #C2C1BD;
    width: 21px;
    height: 21px;
  }

  & > label img {
    color: #C2C1BD;
    width: 18px;
    height: 21px;
  }

  & > label input[type=text] {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    border: none;
    padding-left: 12px;

    :focus-visible {
      outline: none;
    }
  }

  & > label input[type=password] {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    border: none;
    padding-left: 12px;

    :focus-visible {
      outline: none;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    width: 300px;
    height: 44px;
    border: 1px solid #C2C1BD;
    border-radius: 8px;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    ${props =>
            css`
              border-color: ${props.color};
              color: ${props.color}
            `
    }
  }
`;

