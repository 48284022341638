import {useLocation} from "react-router-dom";
import _ from "lodash";
import {Button} from "react-bootstrap";
import React, {useMemo} from "react";
import classNames from "classnames/bind";
import buttonStyles from "../../../styles/common/Button.module.scss";
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {doctorListState} from "@recoils/doctor";
import {CustomCard} from "@components/custom";
import {
    BankInfo,
    DoctorAdditionalInfo,
    DoctorAlimTalk,
    DoctorCareer,
    DoctorDiagnosisTime,
    DoctorInfo,
    DoctorLicense,
    DoctorService,
    DoctorVideo
} from "@components/infoForm";
import {useModalHook} from "@hooks/modal";
import {useDoctorFunctionHook} from "@hooks/doctor";

export const DoctorDetailInfoDetailPage = () => {
    const bx = classNames.bind(buttonStyles);
    const list = useRecoilValue(doctorListState);

    const {deleteDoctor, getDoctorById} = useDoctorFunctionHook();

    const location = useLocation();
    const id = _.get(location.state, "data") as string;

    const doctorData = useMemo(() => {
        return getDoctorById(id);
    }, [list]);

    const {addModal} = useModalHook();

    const isDelete = (e: any) => {
        e && deleteDoctor(id);
    };

    return (
        <>
            {
                !_.isEmpty(doctorData) && (
                    <Container>
                        <Contents>
                            <CardContainer>
                                <CustomCard children={<DoctorInfo data={doctorData}
                                                                  componentName="opDoctorInfo"/>}
                                            buttonHidden={false}
                                            title="의사 기본 정보 [필수]"
                                            subTitle="프로필 이미지"
                                            component="opDoctorInfo"/>
                                <CustomCard children={<DoctorAdditionalInfo data={doctorData}
                                                                            componentName="opDoctorAdditionalInfo"/>}
                                            buttonHidden={false}
                                            title="의사 부가 정보 [필수]"
                                            component="opDoctorAdditionalInfo"/>
                                <CustomCard children={<DoctorAlimTalk data={doctorData}
                                                                      componentName="opDoctorAlimTalk"/>}
                                            buttonHidden={false}
                                            title="알림톡 수신 전화번호 [필수]"
                                            component="opDoctorAlimTalk"/>
                                <CustomCard children={<DoctorCareer data={doctorData}
                                                                    componentName="opDoctorCareer"/>}
                                            buttonHidden={false}
                                            component="opDoctorCareer"
                                            title="의사 경력 정보 [필수]"/>
                                <CustomCard children={<DoctorService data={doctorData}
                                                                     componentName="opDoctorService"/>}
                                            buttonHidden={false}
                                            component="opDoctorService"
                                            title="진료 과목 (최대 4개까지 중복 선택 가능)"/>
                                <CustomCard children={<BankInfo data={doctorData}
                                                                target="doctor"
                                                                componentName="opDoctorBankInfo"/>}
                                            buttonHidden={false}
                                            component="opDoctorBankInfo"
                                            title="정산 은행 정보 [필수]"/>
                                <CustomCard children={<DoctorLicense data={doctorData}
                                                                     componentName="opDoctorLicense"/>}
                                            buttonHidden={false}
                                            component="opDoctorLicense"
                                            title="의사 면허 [필수]"
                                            hidden={true}/>
                                <CustomCard children={<DoctorVideo data={doctorData}
                                                                   componentName="opDoctorVideo"/>}
                                            buttonHidden={false}
                                            component="opDoctorVideo"
                                            title="진료(zoom) 진료 제공 여부"/>
                                <CustomCard children={<DoctorDiagnosisTime data={doctorData}
                                                                           componentName="opDoctorDiagnosisTime"/>}
                                            buttonHidden={false}
                                            component="opDoctorDiagnosisTime"
                                            title="비대면 진료시간"/>
                            </CardContainer>
                        </Contents>
                        <ButtonContainer>
                            <Button type="button"
                                    onClick={() =>
                                        addModal({
                                            type: 'twoBtnModal',
                                            data: {
                                                title: "의사를 삭제하시겠습니까?",
                                                contents: <div>선택하신 의사가 삭제됩니다. 이 동작은 취소 할 수 없습니다.</div>,
                                                buttons: [{title: "취소"}, {title: "삭제하기", callback: (e) => isDelete(e)}],
                                                goBack: false,
                                            }
                                        })}
                                    className={bx("diagnosis-cancel-button")}>
                                삭제
                            </Button>
                        </ButtonContainer>
                    </Container>
                )}
        </>
    );
};

const Container = styled.div`
    padding: 70px 0px;
    height: 100%;
`;

const Contents = styled.div`
  display: flex;
  justify-content: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
`;
