import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import _ from "lodash";
import {DiagnosisListRecord} from "../DiagnosisListRecord";
import {selectedFilterState} from "@recoils/operator";
import {filterDisplayedDiagnosis, IOoDiagnosis} from "@models/diagnosis";
import {useFilteredStatusHook} from "@hooks/common";

interface Props {
    list: Array<IOoDiagnosis>;
}

export const DiagnosisListContainer = ({list}: Props) => {
    const selectedFilterStatus = useRecoilValue(selectedFilterState);
    const [filteredList, setFilteredList] = useState<Array<IOoDiagnosis>>(list);

    const parseNumber = parseInt(selectedFilterStatus);
    const statusList = useFilteredStatusHook({number: parseNumber, list: list});

    const memorizedList = useMemo(() => {
        return statusList;
    }, [list, parseNumber]);

    useEffect(() => {
        setFilteredList(statusList);
    }, [memorizedList]);

    return (
        <Wrapper>
            {
                _.gt(filterDisplayedDiagnosis(filteredList).length, 0) ?
                    <DiagnosisListRecord list={filterDisplayedDiagnosis(filteredList)}/>
                    :
                    <EmptyDiagnosisList>
                        <div>오늘의 진료 요청이 없습니다.</div>
                        <div>오늘 진료 요청이 들어올 경우 자동 업데이트됩니다.</div>
                    </EmptyDiagnosisList>
            }
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  height: 65%;
  overflow: auto;
  display: flex;
  justify-content: center;
`;

const EmptyDiagnosisList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
