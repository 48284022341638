import React, {useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import styled from "styled-components";
import {DoctorDetailLayout} from "./components/DoctorDetailLayout";
import {useDiagnosisDetailSettingHook} from "@hooks/common";
import {TabButton} from "@components/custom";
import {IOoDiagnosis} from "@models/diagnosis";
import {SearchParamProps} from "@recoils/doctor";
import {CalendarParamProp} from "@recoils/unospay";

export const DoctorDiagnosisDetailPage = () => {
    const location = useLocation();
    const diagnosisList = _.get(location.state, 'diagnosisList') as Array<IOoDiagnosis>;
    const calendarParam = _.get(location.state, 'calendarParam') as CalendarParamProp;
    const searchParam = _.get(location.state, 'searchParam') as SearchParamProps;

    const {
        displayList,
    } = useDiagnosisDetailSettingHook({list: diagnosisList, calendarParam: calendarParam, searchParam: searchParam});

    const [radioValue, setRadioValue] = useState<string>(_.head(diagnosisList)!.id || "");

    const targetData = useMemo(() => {
        return _.find(displayList.list, (d) => _.isEqual(d.id, radioValue || ""));
    }, [radioValue, displayList]);
    
    const onChanged = (e: any) => setRadioValue(e.currentTarget.value);

    return (
        <Wrapper>
            <PatientContainer>
                <TabButton list={displayList.list}
                           radioValue={radioValue}
                           onChanged={onChanged}/>
            </PatientContainer>
            {
                !_.isUndefined(targetData) &&
                <DoctorDetailLayout data={targetData}
                                    list={displayList.list}/>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  padding-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PatientContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;