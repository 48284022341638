import {ChangeEvent, useState} from "react";
import {DoctorAlimTalkInfo} from "../../../../interfaces/Operator/OperatorDoctorCreateInterface";

export const useAlimTalkSettingHook = () => {
    const [data, setData] = useState<DoctorAlimTalkInfo>({
        alimTalkReceivers: [],
    });

    const [alimTalk, setAlimTalk] = useState({
        alimtalk1: "",
        alimtalk2: "",
        alimtalk3: "",
    });

    const onChangeAlimtalk = (e: ChangeEvent<HTMLInputElement>) => {
        const {name , value} = e.target;
        return  setAlimTalk({...alimTalk, [name]: value});
    };

    return {
        data,
        setData,
        alimTalk,
        onChangeAlimtalk,
    }

}