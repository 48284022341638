import {useSuspenseHook} from "@hooks/suspense";
import {useSetRecoilState} from "recoil";
import {searchListState, selectedFilterState} from "../../recoils";
import {useQuery} from "react-query";
import {DoctorOnAPI, isRequestSucceed, OoPharmacySearchParam} from "../../api";
import _ from "lodash";
import {FilterStatus} from "../../pages/Operator/DiagnosisDashBoardPage/components";
import {toast} from "react-toastify";

interface Props {
    searchName: string,
    pharmacyId: string,
    searchQueryTrigger: boolean,
    target: string,
}

export const usePharmacyPatientSearchQuery = ({searchName, pharmacyId, searchQueryTrigger, target}: Props) => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const setSearchList = useSetRecoilState(searchListState(target));
    const setSelectFilterStatus = useSetRecoilState(selectedFilterState);

    return useQuery(
        'pharmacy/patientSearchQuery',
        async () => {
            try {
                setLoading()
                const prams = new OoPharmacySearchParam(searchName, pharmacyId);
                const response = await DoctorOnAPI.shared.familyTelemedicine.patientNameRecordPharmacy(prams);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    if (_.isEqual(response.data?.length, 0)) {
                        toast.error(searchName + '으로 검색된 결과가 없습니다.');
                        return null;
                    }
                    setSearchList({id: target, userName: searchName, list: response.data || []});
                    setSelectFilterStatus(_.toString(FilterStatus.total));
                    return response;
                }
                return null;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: searchQueryTrigger,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    )
}