import axios from "axios";
import {
  AllMealsScheduleListInfo,
  AllMealsScheduleMenusInfo,
} from "interfaces/Operator/OperatorMealsScheduleList";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import moment from "moment";
import * as _ from "lodash";
import { DoctorOnAPI } from "@api/request";
import { PaymentCalendar } from "../UnospayDashBoardPage/components/PaymentCalendar";
import MealsScheduleItemPopup from "../MealsScheduleItemPopup";

const AllMealsScheduleListPopUp = () => {

  const [allMealsScheduleList, setAllMealsScheduleList] = useState<AllMealsScheduleListInfo[]>();
  const [choiceMealCode, setChoiceMealCode] = useState("all");
  const [choiceMealMenus, setChoiceMealMenus] = useState<AllMealsScheduleMenusInfo[]>([]);
  const [dobuleDate, setDobuleDate] = useState([moment().format('YYYY.MM.DD'),moment().endOf('month').format('YYYY.MM.DD')])


  const handleChangeOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setChoiceMealCode(e.target.value);
  };

  const handleClickPopupItem = (mealCode:string, itemCode:string, searchDate:string) => {
    const width = 1200;
    const height = 700;
    
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height);
    const url = `https://doctor-on-react.web.app/unospayMealDetail/${mealCode}/${itemCode}/${searchDate}`;
    window.open(url,
      `_blank`,
      `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`,)
  }

  

  useEffect(() => {
    const filterMealsScheduleList = _.find(allMealsScheduleList, {
      mealCode: choiceMealCode,
    })?.menus;
    if (filterMealsScheduleList) {
      if (dobuleDate[0] === dobuleDate[1]) {
        setChoiceMealMenus(filterMealsScheduleList.filter((item) => item.menuDate === moment(dobuleDate[0]).format('YYYYMMDD')));
      } else {
        setChoiceMealMenus(filterMealsScheduleList);
      }
    }
  }, [dobuleDate,choiceMealCode, allMealsScheduleList]);
  

  useEffect(() => {
    const getAllMealsScheduleData = async () => {
      try {
          const response = await DoctorOnAPI.shared.unospay.getMealsScheduleData(
            dobuleDate[0],
            dobuleDate[1]);
            
          if (response.status === "ok") {
            setAllMealsScheduleList(response.data);
          }
        }catch (error) {
        console.log(error);
      }
    }
    getAllMealsScheduleData();
  }, [dobuleDate]);

  return (
    <AllMealsScheduleContainer>
      <ComboBoxContainer>
        <ComboListLabel>상태 :</ComboListLabel>
        <SelectContainer>
          <select className="select" onChange={handleChangeOption}>
            <option value="all">전체</option>
            {allMealsScheduleList?.map((mealsSchedule) => {
              return <option value={mealsSchedule.mealCode}>{mealsSchedule.mealName}</option>;
            })}
          </select>
        </SelectContainer>
      <PaymentCalendar type="doubleCandler" setDobuleDate={setDobuleDate} dobuleDate={dobuleDate} />
      </ComboBoxContainer>
      <MenusContainer>
        {choiceMealCode === "all" ? (
          <>
            {allMealsScheduleList?.map((mealsSchedule) => {
              const { menus, mealName, mealCode } = mealsSchedule;
              return (
                <TabMenuContainer key={mealCode}>
                  <MenuTitleH2Tag>{mealName}</MenuTitleH2Tag>
                  <AllMenuContainer>
                    {moment(dobuleDate[0]).format("YYYYMMDD") === moment(dobuleDate[1]).format("YYYYMMDD") ? (
                      <>
                        {menus?.map((item) => {
                          const { menuDate, menu } = item;
                          return (
                            <>
                              {menuDate === moment(dobuleDate[0]).format("YYYYMMDD") && (
                                <>
                                  <MenuTitleH4Tag>
                                    {moment(menuDate).format("YYYY-MM-DD")}
                                  </MenuTitleH4Tag>
                                  <MenuListContainer>
                                    {menu.map((m) => {
                                      const {
                                        calorie,
                                        imageUrl,
                                        itemCode,
                                        itemName,
                                        keepType,
                                        menuDate,
                                        status
                                      } = m;
                                      
                                      return (
                                        <ItemContainer onClick={()=>handleClickPopupItem(mealCode, itemCode, menuDate)}>
                                          <ItemBox>
                                            <ItemImage src={imageUrl} alt={imageUrl} />
                                            {status === 'Y' ? <ItemTitleH6Tag>{itemName}</ItemTitleH6Tag>:<ItemTitleH6TagRed>{itemName} (품절)</ItemTitleH6TagRed>}
                                          </ItemBox>
                                          <SeolmyeongContainer>
                                            <span>보관 : {keepType}</span>
                                            <span>calorie : {calorie}</span>
                                            <span>[식품코드 : {itemCode}]</span>
                                          </SeolmyeongContainer>
                                        </ItemContainer>
                                      );
                                    })}
                                  </MenuListContainer>
                                </>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                      {/* all 이면서 두 날짜가 다른경우 */}
                        {menus?.map((item) => {
                          const { menuDate, menu } = item;
                          return (
                            <>
                              <MenuTitleH4Tag>
                                {moment(menuDate).format("YYYY-MM-DD")}
                              </MenuTitleH4Tag>
                              <MenuListContainer>
                                {menu.map((m) => {
                                  const { calorie, imageUrl, itemCode, itemName, keepType ,status} = m;
                                  return (
                                    <ItemContainer onClick={()=>handleClickPopupItem(mealCode, itemCode, menuDate)}>
                                      <ItemBox>
                                        <ItemImage src={imageUrl} alt={imageUrl} />
                                        {status === 'Y' ? <ItemTitleH6Tag>{itemName}</ItemTitleH6Tag>:<ItemTitleH6TagRed>{itemName} (품절)</ItemTitleH6TagRed>}
                                      </ItemBox>
                                      <SeolmyeongContainer>
                                        <span>보관 : {keepType}</span>
                                        <span>calorie : {calorie}</span>
                                        <span>[식품코드 : {itemCode}]</span>
                                      </SeolmyeongContainer>
                                    </ItemContainer>
                                  );
                                })}
                              </MenuListContainer>
                            </>
                          );
                        })}
                      </>
                    )}
                  </AllMenuContainer>
                </TabMenuContainer>
              );
            })}
          </>
        ) : (
          <>
            {choiceMealMenus.map((menus) => {
              const { menu, menuDate , mealCode} = menus;
              return (
                <TabMenuContainer>
                  <MenuTitleH2Tag>{moment(menuDate).format("YYYY-MM-DD")}</MenuTitleH2Tag>
                  <MenuListContainer>
                    {menu.map((item) => {
                      const { calorie, imageUrl, itemCode, itemName, keepType ,status} = item;
                      return (
                        <ItemContainer onClick={()=>handleClickPopupItem(choiceMealCode, itemCode, menuDate)}>
                          <ItemImage src={imageUrl} alt={imageUrl} />
                          {status === 'Y' ? <ItemTitleH6Tag>{itemName}</ItemTitleH6Tag>:<ItemTitleH6TagRed>{itemName} (품절)</ItemTitleH6TagRed>}
                          <SeolmyeongContainer>
                            <span>보관 : {keepType}</span>
                            <span>calorie : {calorie}</span>
                            <span>[식품코드 : {itemCode}]</span>
                          </SeolmyeongContainer>
                        </ItemContainer>
                      );
                    })}
                  </MenuListContainer>
                </TabMenuContainer>
              );
            })}
          </>
        )}
      </MenusContainer>
    </AllMealsScheduleContainer>
  );
};

export default AllMealsScheduleListPopUp;

const SeolmyeongContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  text-wrap: balance;
  font-size: 15px;
`;

const ItemTitleH6TagRed = styled.h6`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: red;
`;
const ItemTitleH6Tag = styled.h6`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`;

const ItemImage = styled.img`
  width: 300px;
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 400px;
`;
const MenuTitleH4Tag = styled.h4`
  text-align: center;
  margin: 35px 0px 20px;
`;

const AllMenuContainer = styled.div`
  border: 3px solid #1c1c1c;
  border-radius: 10px;
  padding: 0px 50px 30px;
`;

const MenuTitleH2Tag = styled.h2`
  text-align: center;
`;
const MenuListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 50px;
`;
const TabMenuContainer = styled.div`
  &: first-child {
    margin-top: 50px;
  }
`;
const MenusContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
`;
const ComboBoxContainer = styled.div`
  position: fixed;
  top: 0;
  padding: 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  box-shadow: 0 4px 4px -4px gray;
  width: 100%;
`;
const AllMealsScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 100px auto;
  align-items: center;
`;
const ComboListLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  margin: 10px 10px;
`;

const Hr = styled.div`
  height: 40%;
  margin: 10px 10px;
  width: 2px;
  border-width: 0;
  color: #c6c7c8;
  background-color: #c6c7c8;
`;
const SelectContainer = styled.div`
  padding-right: 8px;
  font-size: 13px;

  .select {
    border-radius: 6px;
    height: 36px;
    border: 0.5px solid #c2c1bd;
    padding: 5px;
  }
`;
