import {IOoDiagnosis} from "@models/diagnosis";
import {useDisplayDiagnosisRtdbHook} from "@hooks/realtimeDatabase";
import {FilterStatus} from "../../pages/Operator/DiagnosisDashBoardPage/components";

interface Props {
    number: number;
    list: Array<IOoDiagnosis>;
}

export const useFilteredStatusHook = ({number, list}: Props) => {
    const {
        total,
        request,
        accept,
        doctorCancel,
        noMedicineChoice,
        medicineDeliveryPaymentWait,
        medicineDeliveryPaymentOk,
        pharmacyPickup,
        todayPackageDelivery,
        todayDeliveryFail,
        medicineDeliveryRequest,
        waitPickup,
        medicineDeliveryStart,
        requestReservation,
        diagnosisPayFail,
        doctorDiagnosisComplete
    } = useDisplayDiagnosisRtdbHook({list});

    switch (number) {
        case FilterStatus.total:
            return total();
        case FilterStatus.request:
            return request();
        case FilterStatus.accept:
            return accept();
        case FilterStatus.doctorCancel:
            return doctorCancel();
        case FilterStatus.medicineDeliveryRequest:
            return medicineDeliveryRequest();
        case FilterStatus.waitPickup:
            return waitPickup();
        case FilterStatus.todayDeliveryFail:
            return todayDeliveryFail();
        case FilterStatus.noMedicineChoice:
            return noMedicineChoice();
        case FilterStatus.medicinePayWait:
            return medicineDeliveryPaymentWait();
        case FilterStatus.medicinePaySuccess:
            return medicineDeliveryPaymentOk();
        case FilterStatus.pharmacyPickup:
            return pharmacyPickup();
        case FilterStatus.todayPackageDelivery:
            return todayPackageDelivery();
        case FilterStatus.medicineDeliveryStart:
            return medicineDeliveryStart();
        case FilterStatus.requestReservation:
            return requestReservation();
        case FilterStatus.diagnosisPayFail:
            return diagnosisPayFail();
        case FilterStatus.diagnosisComplete:
            return doctorDiagnosisComplete();
        default:
            return [];
    }
};
