import { atom, atomFamily, DefaultValue, selectorFamily } from "recoil";
import moment from "moment";

interface CalendarProps {
  id: string;
  date: Array<string>;
}

const selectedDateAtom = atom<Array<string>>({
  key: "selectedDateAtom",
  default: [],
});

const selectedDateAtomFamily = atomFamily<CalendarProps, string>({
  key: "selectedDateAtomFamily",
  default: (id: string) => {
    return {
      id: id,
      date: [moment().format(`YYYY.MM.DD`), moment().format(`YYYY.MM.DD`)],
    };
  },
});

export const dailyDiagnosisSelectedState = selectorFamily<
  CalendarProps,
  string
>({
  key: "dailyDiagnosisSelectedState",
  get:
    (id: string) =>
    ({ get }) =>
      get(selectedDateAtomFamily(id)),
  set:
    (id: string) =>
    ({ get, set, reset }, CalendarProps) => {
      if (CalendarProps instanceof DefaultValue) {
        reset(selectedDateAtomFamily(id));
        set(selectedDateAtom, (prevValue) =>
          prevValue.filter((item) => item !== id)
        );
        return;
      }
      set(selectedDateAtomFamily(id), CalendarProps);
      set(selectedDateAtom, (prev) => Array.from(new Set([...prev, id])));
    },
});
