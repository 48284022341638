import {useSuspenseHook} from "../../hooks";
import {useQuery} from "react-query";
import {DoctorOnAPI, isRequestSucceed, OoDailyDiagnosisRecordRange,} from "../../api";
import {useRecoilState, useSetRecoilState} from "recoil";
import {calendarParamState} from "@recoils/operator/calendarParamState";
import {isEmpty} from "lodash";
import {toast} from "react-toastify";
import {dailyDiagnosisListState, dailyDiagnosisSelectedState} from "@recoils/telemedicine";
import moment from "moment";
import {triggerState} from "@recoils/calendar";

interface DailyListProps {
    beginTimestamp: string;
    endTimestamp: string;
    count?: string;
    diagnosisId?: string;
    updateQueryTrigger: boolean;
}

export const useDailyDiagnosisList = ({
                                          beginTimestamp,
                                          endTimestamp,
                                          count,
                                          diagnosisId,
                                          updateQueryTrigger,
                                      }: DailyListProps) => {
    const target = 'operator';

    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const setCalendarParam = useSetRecoilState(calendarParamState);
    const setDailyDiagnosisList = useSetRecoilState(dailyDiagnosisListState);
    const setDailyDiagnosisSelectData = useSetRecoilState(dailyDiagnosisSelectedState(target));

    const [calendarQueryTrigger, setCalendarQueryTrigger] = useRecoilState(triggerState);

    return useQuery(
        "operator/dailyDiagnosisList",
        async () => {
            try {
                setLoading();
                setCalendarParam({
                    beginTimestamp: beginTimestamp,
                    endTimestamp: endTimestamp,
                    id: diagnosisId || "",
                    count: count
                });
                const params = new OoDailyDiagnosisRecordRange(
                    beginTimestamp,
                    endTimestamp,
                    diagnosisId,
                    count
                );
                const response = await DoctorOnAPI.shared.telemedicine.dailyDiagnosisRecordRange(params);
                setStopLoading();
                setCalendarQueryTrigger({...calendarQueryTrigger, operatorQuery: false});
                if (isRequestSucceed(response)) {
                    if (isEmpty(response.data)) {
                        toast.error("검색 결과가 없습니다");
                        setDailyDiagnosisSelectData({
                            id: target,
                            date: [moment().format(`YYYY.MM.DD`), moment().format(`YYYY.MM.DD`)]
                        });
                        setDailyDiagnosisList([]);
                        return;
                    }
                    setDailyDiagnosisList(response.data!);
                    return response.data;
                }
                return null;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: updateQueryTrigger,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    );
};
