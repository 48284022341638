import {atom} from "recoil";
import {IOoDiagnosis} from "@models/diagnosis";
import {recoilPersist} from "recoil-persist";

interface SearchProps {
    userName?: string,
    list: Array<IOoDiagnosis>,
}

const KEY = 'doctorSearchListState';

const {persistAtom} = recoilPersist({
    key: KEY,
    storage: localStorage
});

export const doctorDiagnosisSearchListState = atom<SearchProps>({
    key: KEY,
    default: {
        userName: '',
        list: [],
    },
    effects_UNSTABLE: [persistAtom]
});