import {useMutation} from "react-query";
import {useSuspenseHook} from "@hooks/suspense";
import {OoParamDoctorScheduleCreate} from "@params/reservation/doctorSchduleCreate";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";


export const useDoctorSetReservationQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'doctor/setReservation',
        async (param: OoParamDoctorScheduleCreate) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.reservation.setDoctorSchedule(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        }
    )
}