import {AbstractOoParam} from "../base";

class OoUnospayCancelOrderComment implements AbstractOoParam {
    // 주문자 식별 id
    id: string = '';

    // 주문 플랫폼
    comment: string = '';

    getPathParams(): string {
        return "";
    }

    constructor(id: string, comment: string) {
        this.id = id;
        this.comment = comment;
    }
}
export {OoUnospayCancelOrderComment};
