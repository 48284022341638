import {useSuspenseHook} from "../../hooks";
import {useMutation} from "react-query";
import {DoctorOnAPI, IOoResponse, isRequestSucceed} from "../../api";
import {OoParamReservationConfirm} from "@api/params";
import _ from "lodash";
import {toast} from "react-toastify";

export const useReservationConfirmQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'reservation/confirm',
        async (id: string) => {
            try {
                setLoading();
                const param = new OoParamReservationConfirm();
                param.id = id;
                const response = await DoctorOnAPI.shared.reservation.confirm(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        }, {
            onSuccess: async (data: IOoResponse | undefined) => {
                if (_.isUndefined(data)) return undefined;
                toast.success('예약이 확정되었습니다.');
            },
            onError: (e: any) => {
                console.log('updateReservationConfirm error ====>', e);
            }
        }
    )
}