import {AbstractOoParam} from "../base";

class OoParamReservationUpdate extends AbstractOoParam {
    id: string = '';

    reservationTime?: number = 0;
}

export {
    OoParamReservationUpdate
}