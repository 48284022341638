import {filterDisplayedDiagnosis, IOoDiagnosis} from "@models/diagnosis";
import styled from "styled-components";
import {useRecoilState} from "recoil";
import React from "react";
import {pharmacySelectedFilter} from "@recoils/pharmacy";
import {useDisplayPharmacyHook} from "@hooks/pharmacy";

interface Props {
    list: Array<IOoDiagnosis>,
}

interface ColorProps {
    backgroundColor: string,
    isChecked: boolean,
}

enum StatusColor {
    default = '#EEEEEE',
    wait = '#FFFFFF',
    cancel = '#F6CFD3',
    success = '#6E8ED1',
}

enum TextColor {
    default = '#000000',
    wait = '#536DA3',
    success = '#FFFFFF',
    cancel = '#B43831',
}


export enum PharmacyFilterStatus {
    total = 0,
    decidedDeliveryType,
    medicineDeliveryPaymentWait,
    medicineDeliveryPaymentOk,
    pickupWait,
    todayDelivery,
    pharmacyPackage,
    pharmacyPickup,
    cancelDelivery,
}

export const FilterCard = ({list}: Props) => {
    const [selectFilterStatus, setSelectNumber] = useRecoilState(pharmacySelectedFilter);
    const {
        total,
        decidedDeliveryType,
        medicineDeliveryPaymentWait,
        medicineDeliveryPaymentOk,
        pickupWait,
        todayDelivery,
        pharmacyPackage,
        pharmacyPickup,
        cancelDelivery,
    } = useDisplayPharmacyHook({list});

    const ButtonData = [
        {
            label: '전체 처방 접수',
            filterStatus: PharmacyFilterStatus.total,
            statusColor: StatusColor.default,
            textColor: TextColor.default,
            filteredCount: filterDisplayedDiagnosis(total()).length,
        },
        {
            label: '약 조제 신청',
            filterStatus: PharmacyFilterStatus.decidedDeliveryType,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(decidedDeliveryType()).length,
        },
        {
            label: '약제비 결제 대기',
            filterStatus: PharmacyFilterStatus.medicineDeliveryPaymentWait,
            statusColor: StatusColor.success,
            textColor: TextColor.success,
            filteredCount: filterDisplayedDiagnosis(medicineDeliveryPaymentWait()).length,
        },
        {
            label: '약제비 결제 완료',
            filterStatus: PharmacyFilterStatus.medicineDeliveryPaymentOk,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(medicineDeliveryPaymentOk()).length,
        },
        {
            label: '픽업 대기',
            filterStatus: PharmacyFilterStatus.pickupWait,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(pickupWait()).length,
        },
        {
            label: '당일배송 건',
            filterStatus: PharmacyFilterStatus.todayDelivery,
            statusColor: StatusColor.success,
            textColor: TextColor.success,
            filteredCount: filterDisplayedDiagnosis(todayDelivery()).length,
        },
        {
            label: '택배 건',
            filterStatus: PharmacyFilterStatus.pharmacyPackage,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(pharmacyPackage()).length,
        },
        {
            label: '방문 수령 건',
            filterStatus: PharmacyFilterStatus.pharmacyPickup,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(pharmacyPickup()).length,
        },
        {
            label: '배송 취소',
            filterStatus: PharmacyFilterStatus.cancelDelivery,
            statusColor: StatusColor.wait,
            textColor: TextColor.wait,
            filteredCount: filterDisplayedDiagnosis(cancelDelivery()).length,
        }
    ];

    const onClickFilter = (e: any) => {
        const selectedStatus = e.target.value;
        setSelectNumber(selectedStatus);
    };

    return (
        <Wrapper>
            {
                ButtonData.map((item, index) => (
                        <React.Fragment key={index}>
                            <input id={`${item.filterStatus}`}
                                   type="radio"
                                   name="radio"
                                   onClick={onClickFilter}
                                   hidden={true}
                                   value={item.filterStatus}
                                   key={index}
                            />
                            <LabelContainer htmlFor={`${item.filterStatus}`}
                                            backgroundColor={item.statusColor}
                                            isChecked={selectFilterStatus === item.filterStatus.toString()}>
                                <LabelDiv>
                                    <StyledLabel color={item.textColor}>{item.label}</StyledLabel>
                                    <StyledCount color={item.textColor}>
                                        {item.filteredCount}
                                    </StyledCount>
                                </LabelDiv>
                            </LabelContainer>
                        </React.Fragment>
                    )
                )
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  padding-top: 4px;
  display: flex;
  height: auto;
  gap: 2px;
  justify-content: space-between;
`;

const LabelContainer = styled.label<ColorProps>`
  width: 10rem;
  height: 85px;
  background: ${props => props.backgroundColor};
  margin: 0.1rem;
  border: ${(props) => props.isChecked ? "2px solid red" : "1px solid #00000020"};
  cursor: pointer;

  @media (max-width: 768px) {
    width: 8.5rem;
  }
  @media (max-width: 1024px) {
    width: 19.5%;
  }
  @media (max-width: 768px) {
    width: 19.3%;
  }
  @media (max-width: 425px) {
    width: 7.5rem;
  }
`;

const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const StyledLabel = styled.b`
  font-size: 12px;
  color: ${props => props.color};

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const StyledCount = styled.b`
  font-size: 2rem;
  color: ${props => props.color};
`;
