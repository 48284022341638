import { CustomCalendar } from "@components/custom";
import React, { Dispatch, SetStateAction } from "react";
import { usePaymentCalendarHook } from "@hooks/unospay";

export interface OrderPaymentCalendarProps {
  type?: "order"|"doubleCandler";
  setSearchDate?: Dispatch<SetStateAction<string>>;
  dobuleDate?:string[]
  setDobuleDate?: Dispatch<SetStateAction<string[]>>;
}

export const PaymentCalendar = ({ type, setSearchDate, dobuleDate, setDobuleDate }: OrderPaymentCalendarProps) => {
  const {
    modalVisible,
    setModalVisible,
    todayDate,
    setTodayDate,
    onShowCalendar,
    onClickDate,
    dailyDiagnosisSelectedDate,
    onChangeDate,
    handleTodayButton,
  } = usePaymentCalendarHook();

  return (
    <CustomCalendar
      type={type}
      dobuleDate={dobuleDate}
      setDobuleDate={setDobuleDate}
      setSearchDate={setSearchDate}
      visible={modalVisible}
      setVisible={setModalVisible}
      onChangeDate={onChangeDate}
      value={[todayDate[0], todayDate[1]]}
      onClickDate={onClickDate}
      onShowCalendar={onShowCalendar}
      selectedStartDay={dailyDiagnosisSelectedDate[0]}
      selectedEndDay={dailyDiagnosisSelectedDate[1]}
      handleTodayDate={handleTodayButton}
    />
  );
};
