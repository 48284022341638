import _ from "lodash";
import {IOoCompactHospital} from "@api/models";

export function findHospitalName(id: string, list: Array<IOoCompactHospital>): string {
    const filteredData = _.chain(list)
        .filter(item => _.isEqual(item.id, id))
        .map(item => item.name)
        .head()
        .value();
    return filteredData;
}

