import React, {useEffect, useMemo} from "react";
import classNames from "classnames/bind";
import timeTableStyle from "../../styles/common/TimeTable.module.scss";
import {OpenDropDown} from "../openDropDown";
import {useWorkHourRowSettingHook} from "./hook/useWorkHourRowSettingHook";
import {DisplayTimeTable} from "./DisplayTimeTable";
import styled from "styled-components";
import {filteredDayOfWeek, IOoWorkHours, splitCloseTime, splitOpenTime} from "@api/models/pharmacy";

interface InitialProps {
    dayOfWeekNumber: number,
    hours: string,
    breakHoursList: Array<string>,
    isOff: boolean,
    onChange: (item: IOoWorkHours) => void,
    isEdit?: boolean,
    isCancel?: boolean,
    isSubmit?: boolean,
    reservationEnabled?: boolean,
    originData?: IOoWorkHours | undefined,
}

export const WorkHourRow = ({
                                dayOfWeekNumber,
                                hours,
                                breakHoursList,
                                isOff,
                                onChange,
                                isEdit,
                                isCancel,
                                isSubmit,
                                originData,
                            }: InitialProps) => {

    const tx = classNames.bind(timeTableStyle);
    const {
        originTime,
        setOpenTime,
        setCloseTime,
        changedTime,
        setChangedTime,
        onCheckChanged,
        onCloseTimeChanged,
        onOpenTimeChanged,
    } = useWorkHourRowSettingHook({isSubmit, isOff, isCancel, isEdit, hours, originData, dayOfWeekNumber});

    const transTime = useMemo(() => {
        const time: IOoWorkHours = {
            dayOfWeek: changedTime.weekNumber,
            hours: `${changedTime.openHour}:${changedTime.openMinute},${changedTime.closeHour}:${changedTime.closeMinute}`,
            breakHoursList: breakHoursList,
            isOff: changedTime.checked,
        }
        return time;
    }, [changedTime]);

    useEffect(() => {
        onChange(transTime);
    }, [transTime]);

    useEffect(() => {
        setOpenTime(splitOpenTime(hours));
        setCloseTime(splitCloseTime(hours));
    }, [hours]);

    useEffect(() => {
        setChangedTime(originTime);
    }, [originTime]);

    return (
        <>
            {
                isEdit ?
                    <Wrapper>
                        <div className={tx('time-table', 'weekends')}>
                            {filteredDayOfWeek(dayOfWeekNumber)}
                        </div>
                        <div className={tx('time-table', 'openHours')}>
                            <OpenDropDown h={changedTime.openHour}
                                          m={changedTime.openMinute}
                                          onChanged={onOpenTimeChanged}
                                          disabled={changedTime.checked}
                                          id={`${dayOfWeekNumber}`}/>
                        </div>
                        <div className={tx('time-table', 'closeHours')}>
                            <OpenDropDown h={changedTime.closeHour}
                                          m={changedTime.closeMinute}
                                          onChanged={onCloseTimeChanged}
                                          disabled={changedTime.checked}
                                          id={`${dayOfWeekNumber}`}/>
                        </div>
                        <div className={tx('time-table')}>
                            <input type="checkbox"
                                   checked={changedTime.checked}
                                   id={`${dayOfWeekNumber}`}
                                   onChange={onCheckChanged}
                                   disabled={!isEdit}/>
                        </div>
                    </Wrapper> :
                    <Wrapper>
                        <div className={tx('time-table')}>
                            {filteredDayOfWeek(dayOfWeekNumber)}
                        </div>
                        <DisplayTimeTable checked={originTime.checked} time={originTime}/>
                        <div className={tx('time-table')}>
                            <input type="checkbox"
                                   checked={changedTime.checked}
                                   id={`${dayOfWeekNumber}`}
                                   onChange={onCheckChanged}
                                   disabled={!isEdit}/>
                        </div>
                    </Wrapper>
            }
        </>
    );
};

const Wrapper = styled.div`
  display: flex;
`;

