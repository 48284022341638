import React from "react";
import {filteredTime} from "@api/models";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {formatTel} from "@hooks/format";
import _ from "lodash";

interface Props {
    data: Array<any>
}

export const HospitalListContainer = ({data}: Props) => {
    return (
        <Wrapper>
            {
                data.map((item, index) => (
                        <Link key={index}
                              to={`/hospitalDetail`}
                              state={{data: item.id}}
                              style={{textDecoration: "none", color: "black"}}>
                            <ListContainer>
                                <div>{index + 1}</div>
                                <div>{filteredTime(item.timestamp)}</div>
                                <div>{item.name}</div>
                                <div>{item.code}</div>
                                <div>{item.address}</div>
                                <div>{_.includes(item.tel, '-') ? item.tel : formatTel(item.tel)}</div>
                                <div>{item.doctors?.length ?? 0}명</div>
                            </ListContainer>
                        </Link>
                    )
                )
            }
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 1024px;
  color: #949390;
  font-weight: 500;
  font-size: 12px;
  overflow-y: auto;

    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
  }

  @media (min-height: 768px) {
    height: 585px;
  }

  @media (min-height: 1024px) {
    height: 75%;
  }

  & > div {
    flex: 1;
    text-align: center;
  }
`;

const ListContainer = styled.div`
  display: flex;
  text-align: center;
  height: 60px;
  align-items: center;
  margin-bottom: 5px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px -2px;

  & > div {
    flex: 1;
  }
`;