import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Card, Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DoctorOnAPI } from '@api/request';
import { AddOptionInfoListType, IOoProducts } from '@api/models/productSetting/product';
import _ from 'lodash';
import { MdOutlineAddBox } from 'react-icons/md';
import ProductOrderList from '../ProductOrderList.tsx';

interface SelectedProductInfo{
    providerName:string;
    providerCode:string;
    productName:string;
    externalCode:string;
    optionCode:string;
    optionName:string;
    productPrice:number;
    menuCount:number;
}
interface Props {
    outRef: React.RefObject<HTMLDivElement>;
    ordererInfoList: SelectedProductInfo[];
    setOrdererInfoList: React.Dispatch<React.SetStateAction<SelectedProductInfo[]>>;
    isShowProductList: {
        isProviderList: boolean;
        isProductNameList: boolean;
        isProductOptionList: boolean;
    };
    setIsShowProductList: React.Dispatch<React.SetStateAction<{
        isProviderList: boolean;
        isProductNameList: boolean;
        isProductOptionList: boolean;
    }>>
}

interface LayoutStyle{
    width?:string;
    height?:string;
    padding?:string;
}

interface InputButtonLayoutStyle{
    gridTemplateColumns?:string;
}

const ProductOrderForm = ({outRef, ordererInfoList , setOrdererInfoList ,isShowProductList, setIsShowProductList}:Props) => {
    const [productList , setProductList] = useState<IOoProducts[] | undefined>([]);
    const [searchProviderList , setSearchProviderList] = useState<IOoProducts[] | undefined>([]);
    const [searchProductNameList , setSearchProductNameList] = useState<IOoProducts[] | undefined>([]);
    const [optionList , setOptionList] = useState<AddOptionInfoListType[]>([]);
    
    const [productOrderItem, setProductOrderItem] = useState<SelectedProductInfo>({providerName:'', providerCode:'',productName:'',externalCode:'',optionCode:'',optionName:'', productPrice:0 , menuCount:1});

    const handleClick = (type:'isProviderList'|'isProductNameList'|'isProductOptionList') =>{
        setIsShowProductList(prev => ({...prev , [type]:false}));
    }
    
    const handleClickAddProduct = () => {
        if(productOrderItem.providerName.length === 0){
            alert('주문할 상품들을 선택해주세요');
        }else if(productOrderItem.productName.length === 0){
            alert('주문할 제품명을 선택해주세요');
        }else if(optionList.length !== 0 && productOrderItem.optionName.length === 0){
            alert('옵션을 선택해주세요');
        }
        else{
            setOrdererInfoList(prev => ([...prev, {...productOrderItem, productPrice:productOrderItem.menuCount * productOrderItem.productPrice}]));
            setProductOrderItem({providerName:'', providerCode:'',productName:'',externalCode:'',optionCode:'',optionName:'',productPrice:0 , menuCount:1});
            const providerDeduplication = providerNameDeduplication(productList!);
            setSearchProviderList(providerDeduplication);
        }
    }


    const onTargetNameClick = (type:'provider'|'product' , data: IOoProducts) => {
        if(type === 'provider'){
            const findProductInfo = productList?.find(item => item === data);
            if(productList && findProductInfo){
                const filteredProductList = productList.filter(product => product.providerName === findProductInfo.providerName);
                setProductOrderItem(prev => ({...prev, providerName:findProductInfo.providerName, providerCode:findProductInfo.externalCode , productName:'' , optionCode:'',optionName:''}));
                setSearchProductNameList(filteredProductList);
                setIsShowProductList(prev => ({...prev , isProviderList:true , isProductNameList:true, isProductOptionList:true}));
            }
        }else if(type === 'product'){
            setProductOrderItem(prev => ({...prev, productName:data.productName , optionName:'' , productPrice:data.retailPrice}));
            setOptionList(data.optionList);
            setIsShowProductList(prev => ({...prev , isProductNameList:true}));
        }
    }

    const onOptionTagClick = (option: AddOptionInfoListType) => {
        setProductOrderItem(prev => ({...prev, optionCode:option.optionCode , optionName:option.optionName}));
        setIsShowProductList(prev => ({...prev , isProductOptionList:true}));
    }

    const getList = (list:IOoProducts[] , name:String , type:'providerList'|'productNameList') => {
        if(type === 'providerList'){
            return list.filter((item) => _.includes(item.providerName, name));
        }else if(type === 'productNameList'){
            return list.filter(product => product.providerName === productOrderItem.providerName).filter((item) => _.includes(item.productName, name));
        }
    }

    const handleChangeProvider = (e:ChangeEvent<HTMLInputElement>) => {
        const text= e.target.value;
        if(productList){
            const findProductList = getList(productList , text, 'providerList');
            setProductOrderItem(prev => ({...prev , providerName:text}));
            if (_.isEqual(text.length, 0) && _.gt(productList.length, 0)) {
                const providerDeduplication = providerNameDeduplication(productList);
                setSearchProviderList(providerDeduplication);
                setIsShowProductList(prev => ({...prev , isProviderList:false}));
            } else {
                const providerDeduplication = providerNameDeduplication(findProductList!);
                setSearchProviderList(providerDeduplication);
                setIsShowProductList(prev => ({...prev , isProviderList:false}));
            }
        }
    }

    const handleClickCount = () => {
            setProductOrderItem(prev => ({...prev, menuCount:prev.menuCount + 1}));
    }

    const handleChangeProductName = (e:ChangeEvent<HTMLInputElement>) => {
        const text= e.target.value;
        if(productList){
            const findProductList = getList(productList, e.target.value , 'productNameList');
            setProductOrderItem(prev => ({...prev , productName:text}));
            if (_.isEqual(text.length, 0) && _.gt(productList.length, 0)) {
                const providerDeduplication = providerNameDeduplication(productList);
                setSearchProductNameList(providerDeduplication);
                setIsShowProductList(prev => ({...prev , isProductNameList:false}));
            } else {
                setSearchProductNameList(findProductList);
                setIsShowProductList(prev => ({...prev , isProductNameList:false}));
            }
        }
    }


    const providerNameDeduplication = (list:IOoProducts[]) => {
        const filteredProviderNameList = Array.from(new Set(list!.map(item => item.providerName)));
        const filterdProductList:IOoProducts[] = filteredProviderNameList.map(item => list.find(data => data.providerName === item)!);
        return filterdProductList;
    }

    const getProductSettingList = async () => {
        try {
          const response = await DoctorOnAPI.shared.productSetting.list();
          if (response.status === "ok") {
            const productDataRes = response.data;
            if(!_.isUndefined(productDataRes)){
                setProductList(productDataRes);
                const providerDeduplication = providerNameDeduplication(productDataRes);
                setSearchProviderList(providerDeduplication);
            }
          }
        } catch (error: any) {
          console.log(error.message);
        }
      };

    useEffect(() => {
        getProductSettingList();
    },[]);

  return (
    <ProductOrderCard ref={outRef}>
        <CardLayout>
            <Card.Body>
                <Row>
                    <Col>
                        <Layout>
                            <span></span>
                        </Layout>
                    </Col>
                    <Col>
                    <Layout>
                        <InputLayout>
                            <label>
                            업체명
                            </label>
                            <input placeholder='' autoComplete='off' onClick={() => handleClick('isProviderList')} onChange={handleChangeProvider} name='providerName' value={productOrderItem.providerName}
                            />
                        </InputLayout>
                        {_.isUndefined(searchProviderList) ? <Spinner animation="border" variant="primary" /> : 
                        <HospitalListLayout hidden={isShowProductList.isProviderList} className="search-result-div">
                            {
                                searchProviderList!.map((item, index) => (
                                    <div key={index} className="search-result-row" onClick={() => onTargetNameClick('provider',item)}>
                                        <span className="search-result-name" id={item.id}>{item.providerName}</span>
                                    </div>
                                ))}
                        </HospitalListLayout>}
                    </Layout>
                    </Col>
                    <Col>
                    <Layout>
                        <InputLayout>
                            <label>
                            제품명
                            </label>
                            <input placeholder='' autoComplete='off' name='productName' value={productOrderItem.productName} onChange={handleChangeProductName} onClick={() => handleClick('isProductNameList')}
                            />
                        </InputLayout>
                        <HospitalListLayout hidden={isShowProductList.isProductNameList} className="search-result-div">
                            {
                            searchProductNameList?.map((item, index) => (
                                <div key={index} className="search-result-row" onClick={() => onTargetNameClick('product', item)}>
                                    <span className="search-result-name" id={item.id}>{item.productName}</span>
                                </div>
                            ))}
                        </HospitalListLayout>
                    </Layout>
                    </Col>
                    <Col>
                    <Layout>
                        <InputLayout>
                            <label>
                            옵션명
                            </label>
                            <input placeholder='' name='optionCode' autoComplete='off'  value={productOrderItem.optionName} onClick={() => handleClick('isProductOptionList')} disabled={optionList.length === 0}
                            />
                        </InputLayout>
                        <HospitalListLayout hidden={isShowProductList.isProductOptionList} className="search-result-div">
                            {
                                optionList.map(option => (
                                    <div className="search-result-row" onClick={() => onOptionTagClick(option)}>
                                        <span className="search-result-name" id={option.optionCode}>{option.optionName}</span>
                                    </div>
                            ))}
                        </HospitalListLayout>
                    </Layout>
                    </Col>
                    <Col>
                    <Layout>
                        <InputButtonLayout gridTemplateColumns='50px auto 30px;'>
                            <label>
                            수량
                            </label>
                            <input placeholder='' name='menuCount' value={productOrderItem.menuCount}
                            />
                            <button onClick={handleClickCount}>+</button>
                        </InputButtonLayout>
                    </Layout>
                    </Col>
                </Row>
                <Button onClick={handleClickAddProduct}><MdOutlineAddBox /></Button>
            </Card.Body>
            <PriceLayout>
                <span>상품 금액 : {(productOrderItem.menuCount * productOrderItem.productPrice).toLocaleString()}원</span>
            </PriceLayout>
        </CardLayout>
       
    </ProductOrderCard>
  )
}

export default ProductOrderForm

const ProductOrderCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 135px;
    padding: 20px 0px;
    border-bottom: 2px solid #cfdbf7;
    .card-body{
        display: flex;
        column-gap: 15px;
        padding: 15px;
    }
    .row {
        display: grid;
        grid-template-columns: 20px 240px 400px 190px 130px;
        align-items: center;
        grid-gap: 10px;

        & > .col{
            padding: 0;
        }
    }
`

const CardLayout = styled.div`
    padding: 5px 0px 10px;
    &:last-child {
        border: none;
    }
`

const PriceLayout = styled.div`
    text-align: right;
    padding: 2px 55px;
`

const InputLayout = styled.div`
    display: grid;
    grid-template-columns: 75px auto;
    align-items: center;

    border-radius: 5px;
    & > input {
        width: 100%;
        border:none;
        border: 1px solid #cfdbf7;
        padding: 5px 7px;
        box-sizing: border-box;
    }
    & > label {
        padding: 6px 7px;
        background-color: #cfdbf7;
    }
`

const InputButtonLayout = styled.div<InputButtonLayoutStyle>`
    display: grid;
    grid-template-columns:${props => props.gridTemplateColumns}
    align-items: center;

    border-radius: 5px;
    & > input {
        width: 100%;
        border:none;
        border: 1px solid #cfdbf7;
        padding: 5px 7px;
        box-sizing: border-box;
    }
    & > label {
        padding: 6px 7px;
        background-color: #cfdbf7;
    }
    & > button {
        height:100%;
        box-sizing: border-box;
        border: none;
    }
`

const Layout = styled.div<LayoutStyle>`
    position: relative;
    padding:${props => props.padding};
`

const Button = styled.button`
    border: none;
    background: transparent;
    & > svg {
        font-size: 35px;
        color: #0a53be;
        opacity: 0.5;
    }
    & > svg:hover {
        color: #0a53be;
        opacity: 1;
    }
`

const HospitalListLayout = styled.div`
    position: absolute;
    background: #ffffff;
    width: calc(100% - 75px);
    height: 300px;
    top: 35px;
    right: 0px;
    border: 2px solid #C8D6F8;
    overflow-y: auto;
    z-index: 1;
    div {
        padding: 5px;
    }

    & > div:hover {
        background-color: #F4F9FC;
        cursor: pointer;
    }
`