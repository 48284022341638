import {useQuery} from "react-query";
import {DoctorOnAPI, isRequestSucceed} from "../../api";
import {useSuspenseHook} from "../../hooks";
import {OoDailyTargetDiagnosisRecordRange} from "@api/params";
import {useRecoilState, useSetRecoilState} from "recoil";
import {dailyDiagnosisListState, dailyDiagnosisSelectedState} from "@recoils/telemedicine";
import {toast} from "react-toastify";
import {isEmpty} from "lodash";
import {triggerState} from "@recoils/calendar";
import moment from "moment";

interface PharmacyDailyListProps {
    pharmacyId: string;
    beginTimestamp: string;
    endTimestamp: string;
    count?: string;
    diagnosisId?: string;
    updateQueryTrigger: boolean;
}

export const usePharmacyDailyDiagnosisList = ({
                                                  pharmacyId,
                                                  beginTimestamp,
                                                  endTimestamp,
                                                  count,
                                                  diagnosisId,
                                                  updateQueryTrigger,
                                              }: PharmacyDailyListProps) => {
    const target = "pharmacy";

    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();
    const setDailyDiagnosisList = useSetRecoilState(dailyDiagnosisListState);
    const setDailyDiagnosisSelectData = useSetRecoilState(dailyDiagnosisSelectedState(target));

    const [calendarQueryTrigger, setCalendarQueryTrigger] = useRecoilState(triggerState);

    return useQuery(
        "pharmacy/dailyDiagnosisList",
        async () => {
            try {
                setLoading();
                setCalendarQueryTrigger({...calendarQueryTrigger, pharmacyQuery: false});
                const params = new OoDailyTargetDiagnosisRecordRange(
                    pharmacyId,
                    beginTimestamp,
                    endTimestamp,
                    diagnosisId,
                    count
                );
                const response = await DoctorOnAPI.shared.telemedicine.dailyPharmacyDiagnosisRecordRange(params);
                setStopLoading();
                setCalendarQueryTrigger({...calendarQueryTrigger, pharmacyQuery: false});
                if (isRequestSucceed(response)) {
                    if (isEmpty(response.data)) {
                        toast.error("검색 결과가 없습니다.");
                        setDailyDiagnosisSelectData({
                            id: target,
                            date: [moment().format(`YYYY.MM.DD`), moment().format(`YYYY.MM.DD`)]
                        });
                        setDailyDiagnosisList([]);
                        return;
                    }
                    setDailyDiagnosisList(response.data!);
                    return response.data;
                }
                return null;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: updateQueryTrigger,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: "always", // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    );
};
