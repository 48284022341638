import React from "react";
import classNames from "classnames/bind";
import timeTableStyle from "../../../styles/common/TimeTable.module.scss";
import {WorkHourRowProps} from "../hook/useWorkHourRowSettingHook";

interface Props {
    checked: boolean,
    time: WorkHourRowProps,
}

export const DisplayTimeTable = ({...props}: Props) => {
    const tx = classNames.bind(timeTableStyle);
    const {checked, time} = props;
    return (
        <>
            {
                checked ?
                    <>
                        <div className={tx('time-table', 'openHours')}>
                            -
                        </div>
                        <div className={tx('time-table', 'closeHours')}>
                            -
                        </div>
                    </>
                    :
                    <>
                        <div className={tx('time-table', 'openHours')}>
                            {time.openHour} : {time.openMinute}
                        </div>
                        <div className={tx('time-table', 'closeHours')}>
                            {time.closeHour} : {time.closeMinute}
                        </div>
                    </>
            }
        </>
    )
}