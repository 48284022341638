import {useMutation} from "react-query";
import {DoctorOnAPI, isRequestSucceed} from "../../api";
import {OoParamHospitalCreate} from "../../api/params/hospital";
import {useSuspenseHook} from "../../hooks";


export const useHospitalCreateQuery = () => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();

    return useMutation(
        'operator/hospitalCreate',
        async (paramsData: OoParamHospitalCreate) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.hospital.create(paramsData);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
    )
};