import classNames from "classnames/bind";
import layoutStyles from "../../../../styles/common/Layout.module.scss";
import React, {useEffect} from "react";
import {DoctorServiceInfo} from "../../../../interfaces";
import {DoctorRegisterDepartments, useServiceSettingHook} from "@components/registerForm";
import {DoctorRegisterTheme} from "../doctorRegisterTheme";
import { OoParamDoctorCreate } from "@api/params";

interface Props {
    onChanged: (data: DoctorServiceInfo) => void,
    param:OoParamDoctorCreate
}

export const DoctorRegisterService = ({onChanged,param}: Props) => {
    const lx = classNames.bind(layoutStyles);

    const {
        service,
        onChangedDepartmentInfo,
        onChangedThemeInfo,
    } = useServiceSettingHook();

    useEffect(() => {
        onChanged(service);
    }, [service]);

    return (
        <div className={lx('doctor-service-container')}>
            <DoctorRegisterDepartments onChanged={onChangedDepartmentInfo}  param={param}/>
            <DoctorRegisterTheme onChanged={onChangedThemeInfo} param={param}/>
        </div>
    )
}