import { IOoUnospayDelivery, IOoUnospaySalesMenu, IOoUnospaySalesMenuOption, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import { IOoProducts } from '@api/models/productSetting/product';
const ExcelJS = require("exceljs");

/**
 * @description 동아오츠카 발주서 양식 다운로드
 */

const createModel = (e:IOoUnospayDelivery, salesItem:IOoUnospaySalesMenu) => {
    const model = new ExcelExportModel();
                  model.name = e.recipientName;
                  model.address1 = e.address;
                  model.address2 = e.addressDetail;
                  model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                  model.zipCode = e.zipCode;
                  model.memo = e.memo;
                  model.subMemo = e.subMemo;
                  model.quantity = "1";
                  model.deliveryCost = e.id;
    return model;
  }

const  findProductInfo = (productList:IOoProducts[], salesItem: IOoUnospaySalesMenu) => {
    const productInfo = productList.filter(item => item.externalCode === `${salesItem.externalCode}`)[0];
    return productInfo;
}

const udLifeOrder = async (list:IOoUnospayDelivery[], productList:IOoProducts[]) => {
    
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.udLifeProvider));
    let data_array: Array<ExcelExportModel> = [];

    console.log('excelList :: ', excelList);
    
    excelList.forEach((e, index) => {
        if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
            e.salesMenu?.forEach(salesItem => {
                if(!_.isUndefined(salesItem.salesOption) && salesItem.salesOption.length > 0){
                    const findProductData = findProductInfo(productList, salesItem);
                    let model = createModel(e, salesItem);
                    model.product = findProductData.productName;
                    salesItem.salesOption.forEach((option, index) => {
                        const optionInfo = findProductData.optionList.find(item => item.optionCode === option.externalCode);
                        if(!_.isEmpty(optionInfo) && !_.isUndefined(optionInfo.optionName)){
                            model.product += ` ${optionInfo.optionOrderName}`;
                            model.price = findProductData.retailPrice.toString();
                            model.productCode =  optionInfo.optionOrderCode;
                            model.deliveryDate = moment(e.timestamp).format('YYYYMMDD');
                            data_array.push(model);
                        } else {
                            const productOptionInfo = productList.find(item => item.externalCode === option.externalCode);
                            console.log('productOptionInfo', productOptionInfo);
                            if(productOptionInfo){
                                model.productCode += _.isEmpty(model.productCode) ? `${productOptionInfo.productCode}` : ` ${productOptionInfo.productCode}`;
                                model.product += _.isEmpty(model.product) ? `${productOptionInfo.productName}` : ` ${productOptionInfo.productName}`;
                            }
    
                            // const optionName = getOptionName(product, option.externalCode);
                            // console.log('optionName', productOptionInfo?.optionName);
                            // if(productOptionInfo?.optionName){
                                
                            // }
                        }

                        // if(product && option.externalCode) {
                            
                        // }
                    })
                }else{
                    const findProductData = findProductInfo(productList, salesItem);
                    
                    if(!_.isUndefined(findProductData)){
                        let model = createModel(e, salesItem);
                        model.product = _.isEmpty(findProductData.productName) ? findProductData.productFullName : findProductData.productName;
                        model.price = findProductData.retailPrice.toString();
                        model.productCode = findProductData.productCode;
                        model.deliveryDate = moment(e.timestamp).format('YYYYMMDD');
                        data_array.push(model);
                    }
                }
            })
        }
    });

    
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferUdLife = await (await fetch("/assets/udLife.xlsx")).arrayBuffer();
    const workbookUdLife = new ExcelJS.Workbook();  
    await workbookUdLife.xlsx.load(arrayBufferUdLife);
    const ws_UdLife = workbookUdLife.getWorksheet("양식");
    console.log(data_array);
    
    data_array.map((data: ExcelExportModel, index: number) => {
        const row = ws_UdLife.getRow(index + 2);
        [
            moment().format('YYYYMMDD') + String(index + 1).padStart(4, '0'),
            data.name,
            data.product, 
            data.quantity,
            "",
            "",
            data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
            "",
            data.address1,
            data.address2,
            ""
        ].forEach((value , index) => {
            const cell = row.getCell(index + 1);
            cell.value = value;
            cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            };
        })
    });
    const excelBuffer = await workbookUdLife.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName+"_"+moment().format("YYYYMMDD")+ "(유디라이프케어)" + excelFileExtension
    );
}

export default udLifeOrder