import {useRecoilValue} from "recoil";
import {editSelectorFamily} from "@recoils/status";
import {useState} from "react";
import _ from "lodash";
import {useCardEdit} from "@hooks/common";
import {IOoPharmacy} from "@models/pharmacy";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";

export const usePharmacyAdditionalInfoHook = (data: IOoPharmacy,componentName:string) => {
    const {updatePharmacy} = usePharmacyFunctionHook();
    const {isReset} = useCardEdit(componentName);
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const [color, setColor] = useState("white");
    const [items, setItems] = useState({
        zipCode: data.zipCode,
        address: data.address,
        addressDetail: _.isUndefined(data.addressDetail) ? '' : data.addressDetail,
        tel: data.tel,
        fax: data.fax,
    });

    const onChangeZipcode = (e: any) => setItems({...items, zipCode: e.target.value});
    const onChangeAddress = (e: any) => setItems({...items, address: e.target.value});
    const onChangeAddressDetail = (e: any) => setItems({...items, addressDetail: e.target.value});
    const onChangeTel = (e: any) => setItems({...items, tel: e.target.value});
    const onChangeFax = (e: any) => setItems({...items, fax: e.target.value});

    return {
        editStatus,
        color,
        setColor,
        items,
        setItems,
        onChangeZipcode,
        onChangeAddress,
        onChangeAddressDetail,
        onChangeTel,
        onChangeFax,
        updatePharmacy,
        isReset
    }
}