import styled from "styled-components";
import React, {useMemo, useState} from "react";
import {IOoDiagnosis} from "@api/index";
import classNames from "classnames/bind";
import buttonStyles from "@styles/common/Button.module.scss";
import {useReadByUserIdQuery} from "@queries/operator";
import _ from "lodash";

interface Props {
    diagnosis: IOoDiagnosis
}

export const DebugInformation = ({diagnosis}: Props) => {
    const bx = classNames.bind(buttonStyles);
    const [queryTrigger, setQueryTrigger] = useState<boolean>(false);

    const userIdQueryResp = useReadByUserIdQuery(diagnosis.id || "", queryTrigger);
    const handleButton = () => setQueryTrigger(true);

    const data = useMemo(() => {
        return _.isUndefined(userIdQueryResp.data) ? diagnosis : userIdQueryResp.data;
    }, [diagnosis, userIdQueryResp.data]);

    return (
        <Wrapper>
            <div className="title">Debug Information</div>
            <Table>
                <thead></thead>
                <tbody>
                <tr>
                    <td>diagnodidId</td>
                    <td>{diagnosis.id || '-'}</td>
                </tr>
                <tr>
                    <td>timestamp</td>
                    <td>{diagnosis.timestamp}</td>
                </tr>
                <tr>
                    <td>doctorId</td>
                    <td>{diagnosis.doctorId}</td>
                </tr>
                <tr>
                    <td>pharmacyId</td>
                    <td>{diagnosis.pharmacyId || '-'}</td>
                </tr>
                <tr>
                    <td>patientId</td>
                    <td>{diagnosis.patientId}</td>
                </tr>
                <tr>
                    <td>familyUserId</td>
                    <td>{diagnosis.familyUserId || '-'}</td>
                </tr>
                <tr>
                    <td>familyKey</td>
                    <td>{diagnosis.familyKey || '-'}</td>
                </tr>
                <tr>
                    <td>App Version</td>
                    <td>{diagnosis.appVersion || '-'}</td>
                </tr>
                </tbody>
            </Table>
            <ButtonContainer>
                <button onClick={handleButton} className={bx("button")}>
                    조회
                </button>
            </ButtonContainer>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;

  .title {
    font-weight: 700;
    font-size: 16px;
  }
`;

const Table = styled.table`
  margin-top: 8px;
  border-top: 2px solid #C8D6F8;
  border-bottom: 1px solid #C8D6F8;
  width: 100%;
  height: auto;
  font-size: 13px;
  font-weight: 700;

  & tbody tr {
    border-bottom: 1px solid #C8D6F8;
    height: 50px;
  }

  & tbody tr td:nth-child(2n-1) {
    background: #F4F6FB;
    width: 150px;
    text-align: center;
  }

  & tbody tr td:nth-child(2n) {
    font-weight: normal;
    padding-left: 10px;
    font-size: 13px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;
