import {IOoWorkHours} from "@api/models/pharmacy";
import _ from "lodash";
import defaultHours from "../../assets/defaultHours.json";

export const useMakeTimeHook = () => {

    const normalizedTimeTable = (hours: Array<IOoWorkHours> | undefined) => {
        if (_.isUndefined(hours)) {
            return [];
        }
        const copiedHours = _.chain(hours)
            .cloneDeep()
            .sortBy('dayOfWeek')
            .value();
        const copiedHoliday = copiedHours.splice(0, 1);
        const copiedLunchTime = copiedHours.splice(copiedHours.length - 1, copiedHours.length);
        return _.concat(copiedHours, copiedHoliday, copiedLunchTime);
    };

    const normalizedDefaultTimeTable = () => {
        const defaultTime = _.cloneDeep(defaultHours.doctorHours) as IOoWorkHours[];
        const defaultHoliday = defaultTime.splice(0, 1);
        const defaultLunchTime = defaultTime.splice(defaultTime.length - 1, defaultTime.length);
        return _.concat(defaultTime, defaultHoliday, defaultLunchTime) as IOoWorkHours[];
    };

    return {
        normalizedTimeTable,
        normalizedDefaultTimeTable,

    }
}