import {
    filterDisplayedDiagnosis,
    filterFamilyDiagnosisReversed,
    getPatientInfo,
    IOoDiagnosis,
    isMyFamilyMember
} from "@models/diagnosis";
import {useDisplayPharmacyHook} from "@hooks/pharmacy";
import {useRecoilValue} from "recoil";
import {pharmacySelectedFilter} from "@recoils/pharmacy";
import React, {ForwardedRef, forwardRef, useEffect, useRef, useState} from "react";
import {PharmacyFilterStatus} from "../filterCard";
import styled, {css} from "styled-components";
import _ from "lodash";
import {displayListState} from "@recoils/display";
import {getDiagnosisStatus} from "@models/diagnosis/diagnosisProcessingState";
import {
    getDiagnosisStatusByPharmacy,
    getPharmacyMedicineDeliveryPayStatus,
    getPharmacyMedicinePayStatus
} from "@models/pharmacy";
import {getMedicineDeliveryState2} from "@models/diagnosis/medicineDeliveryState2";
import {Link} from "react-router-dom";
import {DiagnosisStatusField} from "@components/diagnosisStatusField";
import {PatientInfoField} from "@components/patientInfoField";
import {formatTel} from "@hooks/format";
import {ListStatusField} from "@components/listStatusField";
import {FlexDiv} from "@components/flexDiv";
import ReactToPrint from "react-to-print";
import {Document} from "react-pdf";
import {PrintHiddenPageList} from "@components/printHiddenPageList";
import {useSuspenseHook} from "@hooks/suspense";

interface Props {
    list: Array<IOoDiagnosis>,
}

const PrintPdf = forwardRef((props: { pdfUrl: string, index: number }, ref: ForwardedRef<HTMLDivElement>) => {
    const [pdfPageCount, setPdfPageCount] = useState<number>(0);
    const {setStopLoading} = useSuspenseHook();
    return (
        <div hidden={true}>
            <div ref={ref}>
                <Document file={props.pdfUrl}
                          onLoadSuccess={(pdf) => {
                              setPdfPageCount(pdf.numPages);
                              setStopLoading();
                          }}>
                    <PrintHiddenPageList pageCount={pdfPageCount}/>
                </Document>
            </div>
        </div>
    )
});


export const PharmacyListContainer = ({list}: Props) => {
    const componentRef = useRef<Array<HTMLDivElement>>([]);

    const selectedFilterStatus = useRecoilValue(pharmacySelectedFilter);
    const [filteredList, setFilteredList] = useState<Array<IOoDiagnosis>>(list);

    const displayList = useRecoilValue(displayListState('pharmacy'));
    const predicate = (item: IOoDiagnosis, target: IOoDiagnosis) => isMyFamilyMember(target, item);
    const findFamilyList = (target: IOoDiagnosis) => filterFamilyDiagnosisReversed(displayList.list, (d) => predicate(d, target));


    const {
        total,
        decidedDeliveryType,
        medicineDeliveryPaymentWait,
        medicineDeliveryPaymentOk,
        todayDelivery,
        pharmacyPackage
    } = useDisplayPharmacyHook({list});

    useEffect(() => {
        const parseNumber = parseInt(selectedFilterStatus);
        switch (parseNumber) {
            case PharmacyFilterStatus.total:
                setFilteredList(total());
                break;
            case PharmacyFilterStatus.decidedDeliveryType:
                setFilteredList(decidedDeliveryType());
                break;
            case PharmacyFilterStatus.medicineDeliveryPaymentWait:
                setFilteredList(medicineDeliveryPaymentWait());
                break;
            case PharmacyFilterStatus.medicineDeliveryPaymentOk:
                setFilteredList(medicineDeliveryPaymentOk());
                break;
            case PharmacyFilterStatus.todayDelivery:
                setFilteredList(todayDelivery());
                break;
            case PharmacyFilterStatus.pharmacyPackage:
                setFilteredList(pharmacyPackage());
                break;
        }
    }, [selectedFilterStatus, list]);

    return (
        <Wrapper>
            {
                _.gt(filterDisplayedDiagnosis(filteredList).length, 0) ?
                    <Container>
                        {
                            filterDisplayedDiagnosis(filteredList).map((item, index) => {
                                const status = {
                                    patientInfo: getPatientInfo(item),
                                    processStatus: getDiagnosisStatus(item),
                                    medicinePay: getPharmacyMedicinePayStatus(item),
                                    medicineDeliveryType: getMedicineDeliveryState2(item),
                                    deliveryPay: getPharmacyMedicineDeliveryPayStatus(item),
                                };
                                return (
                                    <React.Fragment key={item.id}>
                                        <StatusRow>
                                            <Link to={`/pharamcyPrescription`}
                                                  state={{data: item, familyData: findFamilyList(item)}}
                                                  key={index}>
                                                <DiagnosisStatusField status={getDiagnosisStatusByPharmacy(item)}
                                                                      flex={1.3}/>
                                                <PatientInfoField title={status.patientInfo.patientName}
                                                                  isShowTag={!_.isEqual(status.patientInfo.familyKey, 0)}
                                                                  tagTitle={"가족"}
                                                                  flex={0.7}/>
                                                <FlexDiv flex={1}>{formatTel(item.patientTel)}</FlexDiv>
                                                <FlexDiv flex={1}>
                                                    {_.isUndefined(status.patientInfo.deliveryAddress) ? '-' : status.patientInfo.deliveryAddress}
                                                </FlexDiv>
                                            </Link>
                                            <DeliveryTypeContainer flex={0.8}>
                                                <DeliveryTypeStatus id={status.medicineDeliveryType.title}>
                                                    {status.medicineDeliveryType.title}
                                                </DeliveryTypeStatus>
                                            </DeliveryTypeContainer>
                                            <Link to={`/pharamcyPrescription`}
                                                  state={{data: item, familyData: findFamilyList(item)}}>
                                                <ListStatusField color={status.medicinePay.color}
                                                                 title={status.medicinePay.title}
                                                                 flex={1}
                                                />
                                                <ListStatusField color={status.deliveryPay.color}
                                                                 title={status.deliveryPay.title}
                                                                 flex={1}
                                                />
                                                <DeliveryStatusContainer flex={1}>
                                                    <DeliveryStatus color={status.medicineDeliveryType.backgroundColor}>
                                                        {status.medicineDeliveryType.text}
                                                    </DeliveryStatus>
                                                </DeliveryStatusContainer>
                                            </Link>
                                            <FlexDiv flex={0.8}>
                                                <PrintPdf index={index}
                                                          pdfUrl={item.prescription ? item.prescription[0] : ''}
                                                          ref={(el) => componentRef.current[index] = el!}/>
                                                <ReactToPrint trigger={() =>
                                                    <PrintButton>출력 하기</PrintButton>}
                                                              content={() => componentRef.current[index]}/>{' '}
                                            </FlexDiv>
                                        </StatusRow>
                                    </React.Fragment>
                                )
                            })
                        }
                    </Container>
                    :
                    <EmptyDiagnosisList>
                        <div>오늘의 조제 요청이 없습니다.</div>
                        <div>오늘 조제 요청이 들어올 경우 자동 업데이트됩니다.</div>
                    </EmptyDiagnosisList>

            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 80%;
  max-width: 1280px;
  min-width: 1024px;
  height: 100%;
  overflow: auto;
  margin-top: 416px;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  overflow: auto;
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    width: 100%;
    max-width: 1280px;
    min-width: 1024px;
  }
`;


const StatusRow = styled.div`
  width: 100%;
  max-width: 1280px;
  min-width: 1024px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 3px -2px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 4px;

  & > a:nth-child(1) {
    height: 64px;
    color: #030303;
    text-decoration: none;
    display: flex;
    flex: 4;
    justify-content: center;
    align-items: center;

    &:hover {
      color: #030303;
      text-decoration: none;
    }
  }

  & > a:nth-child(3) {
    height: 64px;
    color: #030303;
    text-decoration: none;
    display: flex;
    flex: 3;
    justify-content: center;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: #030303;
      text-decoration: none;
    }
  }
`;

const DeliveryTypeContainer = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeliveryTypeStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 24px;
  border-radius: 4px;
  color: #FFFFFF;
  border: none;
  font-weight: 500;
  font-size: 12px;

  ${props =>
          _.includes(props.id, '방문수령') ?
                  css`
                    background: #4CA555;
                  `
                  :
                  css`
                    background: #00326A;
                  `
  }
`;

const PrintButton = styled.button`
  width: 71px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #C2C1BD;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #536DA3;
`;

const DeliveryStatusContainer = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeliveryStatus = styled.div`
  width: 60px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
`;

const EmptyDiagnosisList = styled.div`
  width: 1024px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

