import {
  filterOrderPlatformTotalList,
  filterKiosk,
  filterMtmMall,
  filterPhone,
  filterTest,
  filterNoTest,
  IOoUnospayDelivery,
} from "@models/unospay";

interface Props {
  list: Array<IOoUnospayDelivery>;
}

export const useUnospayOrderPlatformRtdbHook = ({ list }: Props) => {
  const total = () => {
    return filterOrderPlatformTotalList(list);
  };

  const kiosk = () => {
    return filterKiosk(list);
  };

  const mtmMall = () => {
    return filterMtmMall(list);
  };

  const telephone = () => {
    return filterPhone(list);
  };

  const test = () => {
    return filterTest(list);
  };

  const noTest = () => {
    return filterNoTest(list);
  };

  return {
    total,
    kiosk,
    mtmMall,
    telephone,
    test,
    noTest,
  };
};
