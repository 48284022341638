import {IOoDiagnosis, IOoReservation, OoWapleReservationType,} from "@api/models";
import {filteredIsOffArr, IOoWorkHours, reservationSplitTime} from "@api/models/pharmacy";
import _ from "lodash";

// 의사 운영 시간 현재 컴포넌트에 맞추어 재배열
export const makeWorkHourList = (data: IOoWorkHours[]) => {
    const copied = _.cloneDeep(data);

    const excludedLunchTime = copied.filter((item) => !_.isEqual(item.dayOfWeek, 8));
    const combinedDoctorHours = _.concat(_.tail(excludedLunchTime), _.head(excludedLunchTime)) as IOoWorkHours[];
    const lunchTime = _.concat(_.head(copied.filter((item) => _.isEqual((item.dayOfWeek), 8)))!.hours) as string[];
    const isOffItem = excludedLunchTime.findIndex((item) => _.isEqual(item.isOff, true));

    return {
        copied,
        combinedDoctorHours,
        lunchTime,
        isOffItem,
    }
};

// 와플 데이터 현재 데이터 구조에 맞추어 재가공
export const makeReservationHourList = (data: IOoReservation) => {
    try {
        const copiedReservationData = _.cloneDeep(data);
        if (_.isUndefined(copiedReservationData)) return undefined;
        const filteredOpenTime = copiedReservationData.startTimeArr.map((item) => reservationSplitTime(item));
        const filteredCloseTime = copiedReservationData.endTimeArr.map((item) => reservationSplitTime(item));
        const combinedHours = _.zip(filteredOpenTime, filteredCloseTime).map((item) => _.join(item));

        const firstHour = combinedHours.splice(0, 1);
        const lastHour = combinedHours.splice(combinedHours.length - 1, 1);
        const reCombinedHours = _.concat(lastHour, combinedHours, firstHour) as string[];

        let filteredLunchStartTime = "";
        let filteredLunchEndTime = "";

        if (_.isEmpty(filteredLunchStartTime)) {
            filteredLunchStartTime = "13:00";
        } else {
            filteredLunchStartTime = reservationSplitTime(copiedReservationData.lunchStartTime);
        }

        if (_.isEmpty(filteredLunchEndTime)) {
            filteredLunchEndTime = "14:00";
        } else {
            filteredLunchEndTime = reservationSplitTime(copiedReservationData.lunchEndTime);
        }


        const combinedLunchTime = [`${filteredLunchStartTime},${filteredLunchEndTime}`];
        const filteredIsOff = copiedReservationData.isOffArr.map((item) => filteredIsOffArr(item));

        const firstOff = filteredIsOff.splice(0, 1);
        const lastOff = filteredIsOff.splice(filteredIsOff.length - 1, 1);
        const reCombinedIsOff = _.concat(lastOff, filteredIsOff, firstOff);

        const makeWorkHours = reCombinedHours.map((item, index) => {
            const row: IOoWorkHours = {
                dayOfWeek: index,
                hours: item,
                breakHoursList: combinedLunchTime,
                isOff: false
            }
            return row;
        });

        reCombinedIsOff.map((item, index) => makeWorkHours[index].isOff = item);
        const sortedReservationHourList = _.concat(_.tail(makeWorkHours), _.head(makeWorkHours)) as IOoWorkHours[];

        return {
            sortedReservationHourList,
            combinedLunchTime
        }
    } catch (e) {
        console.log('makeReservationHourList error!!', e);
    }
};

// 점심시간 분리 로직
export const spiltLunchTime = (data: Array<string>) => {
    const splitData = _.head(data)!.split(',');
    const lunchOpenTime = _.head(splitData);
    const lunchCloseTime = _.last(splitData);
    return {
        lunchOpenTime,
        lunchCloseTime
    }
};

// 휴무일 여부 true 값 있는지
export const isExistTrue = (arr: Array<IOoWorkHours>): boolean => {
    const findIsOffIndex = _.findIndex(arr, (item) => _.isEqual(item.isOff, true));
    return _.isEqual(findIsOffIndex, -1)
};

// 현재 시간 데이터 와플 예약 시간 구조로 재가공
export const makeTimeParam = (list: Array<IOoWorkHours>) => {
    const copied = _.cloneDeep(list);
    const startTimeArr = copied.map((item) => _.head(_.split(item.hours, ','))) as string[];
    const startTimeResult = startTimeArr.map((item) => _.replace(item, ':', ''));

    const startSunday = startTimeResult.splice(startTimeResult.length - 1, 1);
    const arrangedStartArr = _.concat(startSunday, startTimeResult);

    const endTimeArr = copied.map((item) => _.last(_.split(item.hours, ','))) as string[];
    const endTimeResult = endTimeArr.map((item) => _.replace(item, ':', ''));

    const endSunday = endTimeResult.splice(endTimeResult.length - 1, 1);
    const arrangedEndArr = _.concat(endSunday, endTimeResult);

    return {
        arrangedStartArr,
        arrangedEndArr,
    }
};

// 현재 시간 데이터 와플 점심 예약 시간 구조로 재가공
export const makeLunchTimeParam = (list: Array<IOoWorkHours>, originList: Array<IOoWorkHours>) => {
    const copied = _.cloneDeep(list);
    const isOffArr = copied.map((item) => item.isOff ? 'Y' : 'N');

    const isOffSunday = isOffArr.splice(isOffArr.length - 1, 1);
    const arrangedIsOffSunday = _.concat(isOffSunday, isOffArr);

    return {
        arrangedIsOffSunday,
    }
};


// 같은 dayOfWeek(요일) 인지 확인
export const findDayOfWeek = (list: Array<IOoWorkHours>, target: IOoWorkHours) => {
    const copied = _.cloneDeep(list);
    const index = copied.findIndex((item) => _.isEqual(item.dayOfWeek, target.dayOfWeek));
    if (index > -1) {
        copied[index] = target;
    }
    return copied;
};

// 예약 방식 필터
export const filteredReservationType = (data: IOoDiagnosis): string => {
    let text = '';
    switch (data.wapleReservationType) {
        case OoWapleReservationType.visitReservation:
            text = '예약(대면)';
            break;
        case OoWapleReservationType.visitRegister:
            text = '바로접수(대면)';
            break;
        case OoWapleReservationType.phoneReservation :
            text = '예약(비대면)';
            break;
        case OoWapleReservationType.phoneRegister :
            text = '바로접수(비대면)';
            break;
        case OoWapleReservationType.videoReservation :
            text = '예약(비대면)';
            break;
        case OoWapleReservationType.videoRegister :
            text = '바로접수(비대면)';
            break;
        default:
            text = '바로접수(비대면)';
    }
    return text;
};