import {useRecoilState, useRecoilValue} from "recoil";
import {useMemo, useState} from "react";
import {useDisplayHook} from "@hooks/common";
import {usePharmacyHook} from "@hooks/pharmacy";
import {usePharmacyFindQuery} from "@queries/operator";
import {pharmacyReceiveRtdbState} from "@recoils/pharmacy";
import {selectedUserState} from "@recoils/oauth";
import {dailyDiagnosisListState, dailyDiagnosisSelectedState} from "@recoils/telemedicine";
import {searchListState} from "@recoils/search";

export const usePharmacyDashBoardSettingHook = () => {
    const target = 'pharmacy';

    const {connect, disconnect} = usePharmacyHook();
    const dailyDiagnosisSelectedDate = useRecoilValue(dailyDiagnosisSelectedState(target));

    const {displayList, updateDisplay} = useDisplayHook(target);
    const pharmacyRtdbList = useRecoilValue(pharmacyReceiveRtdbState);

    const searchList = useRecoilValue(searchListState(target));
    const dailyDiagnosisList = useRecoilValue(dailyDiagnosisListState);

    const [userName, setUserName] = useState<string>("");
    const selectedUser = useRecoilValue(selectedUserState);
    const selectedUserId = useMemo(() => {
        setUserName(selectedUser?.user.name ?? "");
        return selectedUser?.user.id ?? "";
    }, [selectedUser]);

    const targetPharmacy = usePharmacyFindQuery(selectedUserId);

    const pharmacyId = useMemo(() => {
        return targetPharmacy.data?.id || "";
    }, [targetPharmacy.data?.id]);

    return {
        connect,
        disconnect,
        selectedUser,
        targetPharmacy,
        searchList,
        dailyDiagnosisList,
        pharmacyRtdbList,
        updateDisplay,
        userName,
        displayList,
        pharmacyId,
        dailyDiagnosisSelectedDate,
        target,
    };
};
