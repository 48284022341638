import React, {useEffect} from "react";
import {DoctorDepartments} from "./DoctorDepartments";
import {DoctorTheme} from "./DoctorTheme";
import styled from "styled-components";
import _ from "lodash";
import {IOoDoctor} from "@models/doctor";
import {OoParamDoctorUpdate} from "@params/doctor";
import {useDoctorServiceSettingHook} from "@components/infoForm";

interface Props {
    data: IOoDoctor,
    componentName: string,
}

export const DoctorService = ({data, componentName}: Props) => {
    const {
        editStatus,
        items,
        onChangeDepartments,
        onChangeTheme,
        updateDoctor,
    } = useDoctorServiceSettingHook(data, componentName);

    // 전송 모드 일때, 쿼리 trigger
    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) {
            return;
        }
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamDoctorUpdate();
            param.id = data.id || "";
            param.departments = items.departments;
            param.theme = items.theme;
            updateDoctor(param);
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <DoctorDepartments data={data}
                               onChange={onChangeDepartments}
                               editButtonClick={editStatus.isEdit}
                               submitButtonClick={editStatus.isSubmit}/>
            <DoctorTheme data={data}
                         onChange={onChangeTheme}
                         editButtonClick={editStatus.isEdit}
                         submitButtonClick={editStatus.isSubmit}/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;