import _ from "lodash";
import {NavLink} from "react-router-dom";
import {NavDropdown} from "react-bootstrap";
import React, {useState} from "react";
import styled from "styled-components";
import {useNavbarHooks} from "../hooks/useNavbarHooks";
import {HiOutlineMenu} from "@react-icons/all-files/hi/HiOutlineMenu";

export const ManagerNavbar = () => {
    const {
        cx,
        oauthList,
        selectedUser,
        navigate,
        onClickLogout,
        GetUserList
    } = useNavbarHooks();

    const [visible, setVisible] = useState<boolean>(false);

    const onClickMenubar = () => {
        setVisible(true);
    };

    return (
        <>
            {_.gte(oauthList.length, 1) && (
                <Wrapper>
                    <div className="left-menu-container">
                        <div className="left-menu-middle-container">
                            <button type="button"
                                    className="navi-logo-container"
                                    onClick={() => navigate("/home")}>
                                {/* <img src={`${process.env.PUBLIC_URL}/img/doctor-on-logo.png`} */}
                                <img src={`${process.env.PUBLIC_URL}/img/login-logo.png`}
                                     alt="logo"
                                     className={cx("logo")}/>
                            </button>
                            <div className="navi-links-container">
                                <NavLink to={`/home`}
                                         style={({isActive}) =>
                                             isActive ? navActive : navNotActive
                                         }>
                                    진료
                                </NavLink>
                                <NavLink to={`/doctor`}
                                         style={({isActive}) =>
                                             isActive ? navActive : navNotActive
                                         }>
                                    의사 목록
                                </NavLink>
                                <NavLink to={`/pharmacy`}
                                         style={({isActive}) =>
                                             isActive ? navActive : navNotActive
                                         }>
                                    약국 목록
                                </NavLink>
                                <NavLink to={`/hospital`}
                                         style={({isActive}) =>
                                             isActive ? navActive : navNotActive
                                         }>
                                    병원 목록
                                </NavLink>
                                <NavLink to={`/unospay`}
                                         style={({isActive}) =>
                                             isActive ? navActive : navNotActive
                                         }>
                                    판매 관리
                                </NavLink>
                                <NavLink to={`/productsSettings`}
                                         style={({isActive}) =>
                                             isActive ? navActive : navNotActive
                                         }>
                                    제품 관리
                                </NavLink>
                                <NavLink to={`/calculate`}
                                         style={({isActive}) =>
                                             isActive ? navActive : navNotActive
                                         }>
                                    정산
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="right-menu-container">
                        <div className="nav-admin-container">
                            <NavDropdown id="nav-user" title={selectedUser?.user.name}>
                                <GetUserList/>
                            </NavDropdown>
                            <input type="button"
                                   value="로그아웃"
                                   onClick={onClickLogout}
                                   className="nav-logout-button"/>
                        </div>
                    </div>
                    <div className="navi-menubar-container">
                        <button type="button" onClick={onClickMenubar}>
                            <HiOutlineMenu size="40"/>
                        </button>
                    </div>
                </Wrapper>
            )}
        </>
    );
};

const navActive = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "100%",
    textDecoration: "none",
    borderBottom: "4px solid #1C6FC9",
    color: "#2176F6",
    fontWeight: "700",
    fontSize: "16px",
};

const navNotActive = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    color: "#2B4E72",
    textDecoration: "none",
    fontWeight: "700",
    fontSize: "16px",
    borderBottom: "none",
};

const Wrapper = styled.div`
  width: 100vw;
  height: 80px;
  background: #ffffff;
  border-bottom: 2px solid #d2d2d2;
  display: flex;
  z-index: 998;
  padding: 0px 50px;
  position: fixed;

  .left-menu-container {
    display: flex;
    justify-content: center;
  }

  .left-menu-middle-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .right-menu-container {
    display: flex;
    align-items: center;
  }

  .navi-logo-container {
    display: flex;
    justify-content: flex-start;
    border: none;
    background: none;
  }

  .navi-links-container {
    height: 100%;
    display: flex;
    align-items: center;

    & > Link {
      text-decoration: none;
      color: #2b4e72;
      font-weight: 700;
      font-size: 16px;
    }
  }

  .nav-admin-container {
    position: absolute;
    right: 50px;
    display: flex;
    align-items: center;

    & div > a {
      font-weight: 700;
      font-size: 16px;
      color: #949390 !important;
    }

    & > input {
      margin-left: 20px;
    }
  }

  .nav-logout-button {
    border: none;
    background: #ffffff;
    color: #2b4e72;
    font-weight: 700;
    font-size: 16px;
  }

  .navi-menubar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    right: 50px;
    position: absolute;

    & > button {
      background: none;
      border: none;
    }

  }

  @media (min-width: 1024px) {
    .navi-menubar-container {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .navi-links-container {
      display: none;
    }

    .nav-admin-container {
      display: none;
    }
  }
`;
