import { atom } from "recoil";

export type ModalType =
  | "onBtnModal"
  | "twoBtnModal"
  | "errorModal"
  | "multiBtnModal"
  | "noBtnModal";

export interface Button {
  title: string;
  callback?: (e: any) => void;
}

export interface Modal {
  type: ModalType;
  data: {
    title?: string | JSX.Element;
    contents: string | JSX.Element;
    buttons?: Array<Button>;
    closeButton?: boolean;
    goBack?: boolean;
  };
}

const modalState = atom<Modal[]>({
  key: "modalState",
  default: [],
});

export { modalState };
