import {useState} from "react";
import {DoctorAdditionalInfo} from "../../../../interfaces";
import _ from "lodash";
import {OoServiceType} from "@api/models";

export const useAdditionalInfoSettingHook = () => {
    const [data, setData] = useState<DoctorAdditionalInfo>({
        hospitalId: '',
        gender: '',
        serviceType: -1,
    });

    const onChangeHospital = (targetId: string) => {
        setData({...data, hospitalId: targetId});
    };

    const onChangeGender = (e: any) => {
        setData({...data, gender: e.target.value});
    };

    const onChangeServiceType = (e: any) => {
        let setNum = _.toNumber(e.target.value);
        if (_.isEqual(setNum, -1)) {
            setData({...data, serviceType: OoServiceType.o2o});
        } else {
            setData({...data, serviceType: setNum});
        }
    };

    return {
        data,
        onChangeHospital,
        onChangeGender,
        onChangeServiceType,
    }
}