import {useRecoilState, useResetRecoilState, useSetRecoilState} from "recoil";
import {fileState} from "@recoils/file";
import {useState} from "react";
import {doctorPrescriptionState} from "@recoils/doctor";
import _ from "lodash";
import {useSuspenseHook} from "@hooks/suspense";
import {useMakePdfHook} from "@hooks/common";
import {IOoDiagnosis} from "@api/models";
import {DoctorPrescriptionUploader} from "@utils/storage";
import {pdfUrlState} from "@recoils/common";

export const usePrescriptionsSettingHook = (data: IOoDiagnosis) => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    const {fileToImageURL, generatePdfFromImages} = useMakePdfHook();

    const setVisible = useSetRecoilState(fileState);
    const [uploadFile, setUploadFile] = useState<Array<File>>([]);
    const [pdfUrl, setPdfUrl] = useState<string | URL>('');
    const [pdfUrlArr, setPdfUrlArr] = useRecoilState(pdfUrlState(data.id || ""));
    const resetPdfUrlArr = useResetRecoilState(pdfUrlState(data.id || ""));
    const setPrescriptionButtonChecked = useSetRecoilState(doctorPrescriptionState);

    const makePdfUrlForFile = async (file: File) => {
        if (_.includes(file.type, "image")) {
            try {
                const image = await fileToImageURL(file)
                const pdfBlob = generatePdfFromImages(image);
                const newFile = new File([pdfBlob], 'prescription', {type: "application/pdf"});
                return await DoctorPrescriptionUploader.upload(newFile, data.patientId);
            } catch (e) {
                console.log(e, 'makePdfUrlForFile error!');
            }
            return "";
        }
        return await DoctorPrescriptionUploader.upload(file, data.patientId ?? '');
    };

    const onFileUpload = (e: any) => {
        const selectedFile: File = _.first(e.target.files)!;
        const copied = _.cloneDeep(uploadFile);
        const index = copied.findIndex((item) => _.isEqual(item.name, selectedFile.name));
        index < 0 ? copied.push(selectedFile) : copied[index] = selectedFile;
        setUploadFile(copied);
    };

    const onHandleRemove = (e: any) => {
        resetPdfUrlArr();
        setUploadFile([]);
        setPrescriptionButtonChecked(true);
    };

    const onClickHref = async (e: any) => {
        setVisible(true);
        setPdfUrl(pdfUrlArr.list[e.target.id]);
    };

    const getUrl = () => {
        if (uploadFile.length < 1) {
            resetPdfUrlArr();
            return;
        }

        uploadFile.forEach(async (item) => {
            try {
                setLoading();
                const url = await makePdfUrlForFile(item);
                const copied = _.cloneDeep(pdfUrlArr);
                copied.list.push(url!.toString());
                setPdfUrlArr(copied);
                setStopLoading();
            } catch (e) {
                console.log(e, 'getUrl error!!!');
            }
        })
    };

    return {
        uploadFile,
        setUploadFile,
        pdfUrl,
        setPdfUrl,
        pdfUrlArr,
        setPdfUrlArr,
        setPrescriptionButtonChecked,
        onFileUpload,
        onHandleRemove,
        onClickHref,
        getUrl,
        resetPdfUrlArr,
    }

}