import { AxiosInstance } from "axios";
import { IOoDataResponse } from "..";
import { IOoProducts } from "@api/models/productSetting/product";
import { endPoint } from "./base";
import { OoUnospayProductSettingParam } from "@api/params/productSetting/productSetting";
import { IOoProductsProvider } from "@api/models/productSetting/productsProvider";

class ProductSettingApi {
  constructor(private readonly axiosInstance: AxiosInstance) {}

  async list(): Promise<IOoDataResponse<IOoProducts[]>> {
    return await this.axiosInstance.get<IOoDataResponse<IOoProducts[]>>(
      endPoint.productSetting.list
    );
  }

  async create(param: OoUnospayProductSettingParam): Promise<IOoDataResponse<IOoProducts[]>> {
    return await this.axiosInstance.post<IOoDataResponse<IOoProducts[]>>(
      endPoint.productSetting.create,
      { list: [param] }
    );
  }

  async delete(id: string): Promise<IOoDataResponse<IOoProducts[]>> {
    const url = `${endPoint.productSetting.delete}/${id}`
    return await this.axiosInstance.delete<IOoDataResponse<IOoProducts[]>>(
      url
    );
  }
  
  async update(param: OoUnospayProductSettingParam): Promise<IOoDataResponse<IOoProducts>> {
    return await this.axiosInstance.put<IOoDataResponse<IOoProducts>>(
      endPoint.productSetting.put,
      param
    );
  }
  async providerFind(param?: number): Promise<IOoDataResponse<IOoProductsProvider>> {
    return await this.axiosInstance.get<IOoDataResponse<IOoProductsProvider>>(
      `${endPoint.productSetting.providerFind}/${param}`
    );
  }
}

export { ProductSettingApi };
