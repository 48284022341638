interface IOoAccount {
    id?: string;
    uid: string;
    email: string;
    name: string;
    birthday?: string;
    identityNumber?: string;
    mobile?: string;
    gender?: string;
    registrationTimestamp: number;
    updateTimestamp: number;
    level: number;
    deviceType: number;
    termAgreed?: number;
    picture?: string;
    secret: string;
    nationCode: string;
    tel?: string;
    zipCode?: string;
    bio?: string;
    state?: string;
    city?: string;
    address?: string;
    macrogenId?: string;
    edgcId?: string;
    nhisId?: string;
    hospitalIdList?: Array<string>;
    doctorIdList?: Array<string>;

}

export enum userLevel {
    "normal" = 0,
    "doctor" = 500,
    "operator" = 501,
    "pharmacist" = 502,
    "manager" = 510,
    "o2oHospital" = 600,
    "o2oDoctor" = 601,
    "o2oCalculater" = 610,
    "o2oCovidDoctor" = 603,
    "o2oPharmacy" = 602,
    "admin" = 999,
    "macrogen" = 800
}

export type {
    IOoAccount
}