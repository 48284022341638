import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";

const config = {
    apiKey: "AIzaSyCwEJKGXP3ekbNa7HbNx4wWQGKt_9ImCag",
    authDomain: "opusone-doctor-on.firebaseapp.com",
    databaseURL: "https://opusone-doctor-on-default-rtdb.firebaseio.com",
    projectId: "opusone-doctor-on",
    storageBucket: "gs://opusone-doctor-on.appspot.com/",
    messagingSenderId: "131480046525",
    appId: "1:131480046525:web:62740c7677e5b62ca5a0d4",
    measurementId: "G-9788LRDZ4X"
};
firebase.initializeApp(config);

console.info('+++++++++++++++ firebase config init +++++++++++++++');

const auth = firebase.auth();
const realtimeDatabase = firebase.database();
const storage = firebase.storage();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export {
    firebase,
    auth,
    storage,
    realtimeDatabase,
    googleAuthProvider
};
