import {useRecoilState} from "recoil";
import _ from "lodash";
import {progressState} from "@recoils/progressStatus";

interface FireBaseError extends Error {
    code?: string;
}

export const useSuspenseHook = () => {
    const [progress, setProgress] = useRecoilState(progressState);

    const setLoading = () => {
        const copyProgress = _.cloneDeep(progress);
        copyProgress.loading = true;
        setProgress(copyProgress);
    };

    const setStopLoading = () => {
        const copyProgress = _.cloneDeep(progress);
        copyProgress.loading = false;
        setProgress(copyProgress);
    };

    const setErrorStatus = (e: FireBaseError) => {
        const copyProgress = _.cloneDeep(progress);
        copyProgress.loading = false;
        copyProgress.errorStatus = true;
        if(e.code === 'auth/wrong-password'){
            copyProgress.error = {
                code: 501,
                message: '비밀번호가 틀렸습니다.'
            };
        }
        if(e.code === 'auth/too-many-requests'){
            copyProgress.error = {
                code: 501,
                message: '비밀번호를 여러번 틀렸습니다. 관리자에게 비밀번호 초기화를 문의해주세요.'
            };
        }
        setProgress(copyProgress);
    };

    return {
        setLoading,
        setStopLoading,
        setErrorStatus,
        progress
    };
};