import {useRecoilValue} from "recoil";
import {useMemo, useState} from "react";
import _ from "lodash";
import {useCardEdit} from "@hooks/common";
import {editSelectorFamily} from "@recoils/status";
import {IOoDoctor} from "@models/doctor";
import {useDoctorFunctionHook} from "@hooks/doctor";
import {useHospitalFunctionHook} from "@hooks/hospital";

export const useDoctorAdditionalSettingHook = (data: IOoDoctor, componentName: string) => {
    const {getHospitalName} = useHospitalFunctionHook();
    const {updateDoctor} = useDoctorFunctionHook();

    const {isReset} = useCardEdit(componentName);
    const editStatus = useRecoilValue(editSelectorFamily(componentName));

    const initialData = useMemo(() => {
        return {
            id: data.id || "",
            hospitalId: data.hospitalId,
            gender: data.gender,
            serviceType: data.serviceType,
        }
    }, [data]);

    const [items, setItems] = useState(initialData);

    const hospitalName = useMemo(() => {
        return getHospitalName(`${data.hospitalId}`);
    }, [data.hospitalId]);

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        if (_.isEqual(name, "serviceType")) {
            _.toNumber(value);
        }
        setItems({...items, [name]: value});
    };

    return {
        getHospitalName,
        updateDoctor,
        editStatus,
        items,
        setItems,
        handleChange,
        isReset,
        hospitalName,
        initialData
    }

}