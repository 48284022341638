import {OoMedicineDeliveryType} from "./diagnosis";

interface DeliveryStatus {
    title: string,
    color: DeliveryStatusColor
}

export enum DeliveryStatusColor {
    default = '#949390',
    todayDelivery = '#5385EF',
    user = '#4CA555'
}

// 약수령 방식 관련 상태
export function getMedicineDeliveryState(type: number): DeliveryStatus {
    let status = {
        title: '선택 안 함',
        color: DeliveryStatusColor.default
    };
    switch (type) {
        case OoMedicineDeliveryType.quickDelivery:
            status = {
                title: '퀵 배송',
                color: DeliveryStatusColor.default
            };
            break;
        case OoMedicineDeliveryType.todayDelivery:
            status = {
                title: '당일 배송',
                color: DeliveryStatusColor.todayDelivery
            };
            break;
        case OoMedicineDeliveryType.packageDelivery:
            status = {
                title: '택배',
                color: DeliveryStatusColor.default
            };
            break;
        case OoMedicineDeliveryType.user:
            status = {
                title: '직접 픽업',
                color: DeliveryStatusColor.user
            };
            break;
        case OoMedicineDeliveryType.hospitalDelivery:
            status = {
                title: '병원 발송',
                color: DeliveryStatusColor.default
            };
            break;
        case OoMedicineDeliveryType.pharmacyPackage:
            status = {
                title: '방문 수령',
                color: DeliveryStatusColor.user
            };
            break;
        case OoMedicineDeliveryType.kaKaoMobility:
            status = {
                title: '카카오',
                color: DeliveryStatusColor.user
            };
            break;
    }
    return status;
}

export type {
    DeliveryStatus
}