import React, {Dispatch, SetStateAction, useEffect} from "react";
import styled from "styled-components";
import _ from "lodash";
import SelectStyles from '../../../../styles/common/Select.module.scss';
import classNames from "classnames/bind";
import {HospitalSearchField} from "../../../hospitalSearchField";
import {DoctorAdditionalInfo} from "../../../../interfaces";
import {filteredServiceType, OoServiceType} from "@models/doctor";
import {useHospitalFunctionHook} from "@hooks/hospital";
import {useAdditionalInfoSettingHook} from "@components/registerForm";
import { OoParamDoctorCreate } from "@api/params";

interface Props {
    onChanged: (data: DoctorAdditionalInfo) => void,
    setParam : Dispatch<SetStateAction<OoParamDoctorCreate>>
    param:OoParamDoctorCreate
}

export const DoctorRegisterAdditionalInfo = ({onChanged , setParam ,param}: Props) => {
    const sx = classNames.bind(SelectStyles);
    useHospitalFunctionHook();
    const {
        data,
        onChangeHospital,
        onChangeGender,
        onChangeServiceType,
    } = useAdditionalInfoSettingHook();

    useEffect(() => {
        onChanged({hospitalId: data.hospitalId,
            serviceType:data.serviceType, gender:data.gender});
    }, [data]);

    return (
        <Wrapper>
            <Table>
                <tbody>
                <tr>
                    <td>
                        <HospitalSearchField onChange={onChangeHospital} param={param} setParam={setParam}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <select className={sx('select', 'large', 'blue')} onChange={onChangeGender}
                                defaultValue={data.gender}>
                            <DisabledOption value={""} disabled>성별을 선택하세요</DisabledOption>
                            <option value="male" selected={param.gender === 'male'}>남자</option>
                            <option value="female" selected={param.gender === 'female'}>여자</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        <select className={sx('select', 'large', 'blue')} onChange={onChangeServiceType}
                                defaultValue={_.toString(param.serviceType)}>
                            <DisabledOption value="-1" disabled>진료타입을 선택하세요</DisabledOption>
                            <option value={OoServiceType.b2c}>{filteredServiceType(OoServiceType.b2c)}</option>
                            <option value={OoServiceType.o2o}>{filteredServiceType(OoServiceType.o2o)}</option>
                            <option value={OoServiceType.o2o2c}>{filteredServiceType(OoServiceType.o2o2c)}</option>
                            <option
                                value={OoServiceType.testB2c}>{filteredServiceType(OoServiceType.testB2c)}</option>
                            <option
                                value={OoServiceType.testO2o2c}>{filteredServiceType(OoServiceType.testO2o2c)}</option>
                            <option value={OoServiceType.covid}>{filteredServiceType(OoServiceType.covid)}</option>
                        </select>
                    </td>
                </tr>
                </tbody>
            </Table>
        </Wrapper>
    );
};

const DisabledOption = styled.option`
  &:disabled {
    display: none;
  }
`;

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  height: 150px;
`;

const Table = styled.table`
  width: 100%;
  height: 144px;
  font-size: 13px;
  margin-top: 8px;
  white-space: pre-wrap;

  & tbody tr td {
    position: relative;

    & > td:nth-child(1) {
      z-index: 10;
    }
  }

`;