import {AbstractOoParam} from "@api/params";

class OoParamDoctorNotification extends AbstractOoParam {
    public type: string = "com.doctorOn.doctorMessage";
    public userId: string = '';
    public diagnosisId: string = '';
    public message: string = '';
    public title?: string = '';

    constructor(type: string, userId: string, diagnosisId: string, message: string, title: string) {
        super();
        this.type = type;
        this.userId = userId;
        this.diagnosisId = diagnosisId;
        this.message = message;
        this.title = title;
    }
}

export {OoParamDoctorNotification}