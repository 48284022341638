import jsPDF from "jspdf";

export interface ImageDimension {
    width: number,
    height: number,
}

export class CustomImage extends Image {
    constructor(public mimeType: string) {
        super();
    }

    get imageType(): string {
        return this.mimeType.split("/")[1];
    }
}

export const useMakePdfHook = () => {
    // The dimensions are in millimeters.
    const A4_PAPER_DIMENSIONS = {
        width: 210,
        height: 297,
    };

    const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;
    const imageDimensionsOnA4 = (dimensions: ImageDimension) => {
        const isLandscapeImage = dimensions.width >= dimensions.height;
        // if image in landscape
        if (isLandscapeImage) {
            return {
                width: A4_PAPER_DIMENSIONS.width,
                height: A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
            };
        }

        // if image in portrait and full height of A4 would skew
        const imageRatio = dimensions.width / dimensions.height;

        if (imageRatio > A4_PAPER_RATIO) {
            const imageScaleFactor =
                (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

            const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

            return {
                height: scaledImageHeight,
                width: scaledImageHeight * imageRatio,
            };
        }

        // full height of A4 without skewing the image ratio
        return {
            width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
            height: A4_PAPER_DIMENSIONS.height,
        };
    };


    const fileToImageURL = (file: File): Promise<CustomImage> => {
        return new Promise((resolve, reject) => {
            const image = new CustomImage(file.type);

            image.onload = () => {
                resolve(image);
            };

            image.onerror = () => {
                reject(new Error("Failed to convert File to Image"));
            };

            image.src = URL.createObjectURL(file);
        })
    };

    const generatePdfFromImages = (image: CustomImage) => {
        const doc = new jsPDF();
        doc.deletePage(1);
        const imageDimensions = imageDimensionsOnA4({
            width: image.width,
            height: image.height,
        });

        doc.addPage();
        doc.addImage(
            image.src,
            image.imageType,
            (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
            (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
            imageDimensions.width,
            imageDimensions.height
        );
        return doc.output('blob');

    };


    return {
        fileToImageURL,
        generatePdfFromImages
    }
}