import {useRecoilState} from "recoil";
import {displayListState} from "@recoils/display";
import {IOoDiagnosis} from "@models/diagnosis";

export const useDisplayHook = (id: string) => {
    const [displayList, setDisplayList] = useRecoilState(displayListState(id));

    const updateDisplay = (displayList: Array<IOoDiagnosis>) => {
        setDisplayList((cur) => ({...cur, list: displayList}))
    };

    return {
        displayList,
        updateDisplay
    }
};