import { IOoAccount, IOoUser } from "@api/models";
import React from "react";
import styled from "styled-components";
import sortDown from "/img/sort_down.svg";
import sortUp from "/img/sort_up.svg";
import { TiArrowSortedDown } from "react-icons/ti";

interface Props {
  target: string;
  level?: number;
  handleClickSorting?: (type: "hospitalCode" | "totalPrice" | "totalHospitalCalPrice") => void;
}
interface FlexProps {
  flex: number;
}
interface GridTemplateProps {
  gridTemplateColumns: string;
}

interface CalculateListSubTitleProps {
  level?: number;
  handleClickSorting: (type: "hospitalCode" | "totalPrice" | "totalHospitalCalPrice") => void;
}

const DoctorListSubTitle = () => {
  return (
    <Wrapper>
      <div>번호</div>
      <div>등록일</div>
      <div>병원명</div>
      <div>의사명</div>
      <div>이메일</div>
      <div>진료과</div>
      <div>전화번호</div>
    </Wrapper>
  );
};

const HospitalListSubTitle = () => {
  return (
    <Wrapper>
      <div>항목</div>
      <div>등록일</div>
      <div>병원명</div>
      <div>코드</div>
      <div>주소</div>
      <div>전화번호</div>
      <div>등록 의사</div>
    </Wrapper>
  );
};

const PharmacyListSubTitle = () => {
  return (
    <Wrapper>
      <div>항목</div>
      <div>등록일</div>
      <div>약국명</div>
      <div>전화번호</div>
      <div>주소</div>
      <div>지정병원</div>
    </Wrapper>
  );
};

export const CalculateListSubTitle = ({
  level,
  handleClickSorting,
}: CalculateListSubTitleProps) => {
  return (
    <>
      {level === 510 ? (
        <Grid gridTemplateColumns="8% 19% 6% 13% 13% 12% 12% 12%">
          <div>
            <span>거래처코드</span>
            <button onClick={() => handleClickSorting("hospitalCode")}>
              <TiArrowSortedDown />
            </button>
          </div>
          <span>거래처명</span>
          <span>거래처 구분</span>
          <div>
            <span>매출액</span>
            <button onClick={() => handleClickSorting("totalPrice")}>
              <TiArrowSortedDown />
            </button>
          </div>
          <div>
            <span>거래처 정산 금액</span>
            <button onClick={() => handleClickSorting("totalHospitalCalPrice")}>
              <TiArrowSortedDown />
            </button>
          </div>
          <span>추천인1 정산금액</span>
          <span>추천인2 정산금액</span>
          <span>추천인3 정산금액</span>
        </Grid>
      ) : (
        <Grid gridTemplateColumns="">
          <span>항목</span>
          <span>등록일</span>
          <span>정산금액</span>
          <span>상품명</span>
          <span>상품가격</span>
          <span>상품업체명</span>
        </Grid>
      )}
    </>
  );
};

export const CalculateRawListSubTitle = () => {
  return (
    <Grid gridTemplateColumns="40px 90px 100px 100px 120px 100px 75px 150px 80px 80px 130px 90px 60px 70px">
      <span>항목</span>
      <span>주문자</span>
      <span>휴대번호</span>
      <span>등록일</span>
      <span>병원명</span>
      <span>주문경로</span>
      <span>병원정산금액</span>
      <span>상품명</span>
      <span>상품가격</span>
      <span>상품업체명</span>
      <span>추천인 [퍼센트]</span>
      <span>추천인 정산금액</span>
      <span></span>
      <span></span>
    </Grid>
  );
};

export const ListSubTitle = ({ target, level, handleClickSorting }: Props) => {
  switch (target) {
    case "hospital":
      return <HospitalListSubTitle />;
    case "pharmacy":
      return <PharmacyListSubTitle />;
    case "calculate":
      return <CalculateListSubTitle level={level} handleClickSorting={handleClickSorting!} />;
    case "calculateRaw":
      return <CalculateRawListSubTitle />;
    default:
      return <DoctorListSubTitle />;
  }
};

const Wrapper = styled.div`
  max-width: 1280px;
  min-width: 1024px;
  height: 44px;
  background: #ffffff;
  border: 0.5px solid #c2c1bd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #949390;
  font-weight: 500;
  font-size: 14px;

  & > div {
    flex: 1;
    text-align: center;
  }
`;

const Title = styled.div<FlexProps>`
  flex: ${(props) => props.flex};
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const List = styled.div<GridTemplateProps>`
  padding: 0px 15px;
  font-size: 14px;
  width: 100%;
  max-width: 1400px;
  min-width: 1024px;
  height: 40px;
  background: #ffffff;
  border: 0.5px solid #c2c1bd;
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  text-align: center;
  grid-column-gap: 5px;
  align-items: center;
  font-size: 13px;
`;

const Grid = styled.div<GridTemplateProps>`
  font-size: 14px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 0.5px solid #c2c1bd;
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  text-align: center;
  grid-column-gap: 0.5%;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    column-gap: 3px;
    align-items: center;
    justify-content: center;
  }

  button {
    border: none;
    background: transparent;
    display: flex;
    font-size: large;
  }
`;
