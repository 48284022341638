import styled from "styled-components";
import React from "react";

interface Props {
    children: JSX.Element,
    title: string,
}

export const ListTitle = ({children, title}: Props) => {
    return (
        <Wrapper>
            <Container>
                <Title>{title}</Title>
                {children}
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  z-index: 10;
`;

const Container = styled.div`
  width: 1024px;
  height: 80px;
  background: #ffffff;
  border: 0.5px solid #c2c1bd;
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

const Title = styled.div`
  width: 15%;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 32px;
`;
