import {useRecoilState, useSetRecoilState} from "recoil";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {doctorFindInfoState, doctorListState} from "@recoils/doctor";
import {
    useChangeStatusQuery,
    useDoctorInfoUpdateQuery,
    useDoctorListQuery,
    useDoctorModifyPrescriptionQuery,
    useDoctorSaveTelemedicineQuery,
    useDoctorSetReservationQuery,
    useDoctorTelemedicineUpdateQuery,
    useSendWaitNotificationQuery,
    useUpdateDoctorReservationInfoQuery
} from "@queries/doctor";
import {
    TimeProps,
    useDiagnosisStatusChange,
    useDoctorCreateQuery,
    useDoctorDeleteQuery,
    useDoctorTimeUpdateQuery
} from "@queries/operator";
import {OoParamDoctorCreate, OoParamDoctorUpdate} from "@params/doctor";
import {OoParamDoctorScheduleCreate} from "@params/reservation/doctorSchduleCreate";
import {IOoDoctor, OoWapleReservationStatus} from "@api/models";
import {IOoResponse} from "@api/reponses";
import {
    OoParamDiagnosisStatus,
    OoParamPrescriptionCreate,
    OoParamPrescriptionModify,
    OoParamReservationDiagnosisStatus
} from "@api/params";
import {useSuspenseHook} from "@hooks/suspense";
import {useReservationConfirmQuery, useReservationVisitCompeteQuery} from "@queries/reservation";
import {doctorReservationState} from "@recoils/doctor/doctorReservationState";
import {useChangeReservationDiagnosisStatus} from "@queries/reservation/changeReservationDiagnosisStatus";
import {chain, cloneDeep, concat, findIndex, gte, isEqual, isNull, isUndefined} from "lodash";

export const useDoctorFunctionHook = () => {
    const {setErrorStatus} = useSuspenseHook();
    const navigate = useNavigate();
    const [doctorList, setDoctorList] = useRecoilState(doctorListState);

    useDoctorListQuery();
    const createDoctorResp = useDoctorCreateQuery();
    const updateDoctorResp = useDoctorInfoUpdateQuery();
    const updateTimeDoctorResp = useDoctorTimeUpdateQuery();
    const deleteDoctorResp = useDoctorDeleteQuery();
    const setReservationResp = useDoctorSetReservationQuery();
    const diagnosisStatusResp = useDiagnosisStatusChange();
    const updateStatusResp = useChangeStatusQuery();
    const updateTelemedicineResp = useDoctorTelemedicineUpdateQuery();
    const saveTelemedicineResp = useDoctorSaveTelemedicineQuery();
    const updateReservationResp = useUpdateDoctorReservationInfoQuery();
    const prescriptionModifyResp = useDoctorModifyPrescriptionQuery();
    const confirmVisitCompleteResp = useReservationVisitCompeteQuery();
    const sendWaitNotificationResp = useSendWaitNotificationQuery();
    const confirmReservationResp = useReservationConfirmQuery();
    const changeReservationDiagnosisStatusResp = useChangeReservationDiagnosisStatus();

    // 의사 아이디 찾기
    const getDoctorById = (id: string): IOoDoctor => {
        return chain(doctorList)
            .filter((e: IOoDoctor) => isEqual(e.id || "", id))
            .head()
            .value();
    };

    // 유저 아이디로 의사 찾기
    const getDoctorByUserId = (id: string) => {
        return chain(doctorList)
            .filter((e: IOoDoctor) => isEqual(e.userId || "", id))
            .head()
            .value();
    };

    // 의사 정보 등록
    const createDoctor = (param: OoParamDoctorCreate) => {
        createDoctorResp.mutate(param, {
            onSuccess: (data: IOoDoctor | undefined) => {
                toast.success("의사 정보 생성 완료");
                if (isUndefined(data)) return;
                const copied = cloneDeep(doctorList);
                navigate("/doctor");
                setDoctorList(concat(copied, data));
            },
        });
    };

    // 의사 정보 수정
    const updateDoctor = (param: OoParamDoctorUpdate) => {
        updateDoctorResp.mutate(param, {
            onSuccess: (data: IOoDoctor | undefined) => {
                toast.success("의사 정보 수정 완료");
                if (isUndefined(data)) return;
                const copied = cloneDeep(doctorList);
                const index = findIndex(copied, (item) => isEqual(item.id, data.id));
                if (gte(index, 0)) {
                    copied[index] = data;
                    setDoctorList(copied);
                }
            },
        });
    };

    // 의사 시간 정보 수정
    const updateTimeDoctor = ({id, param}: TimeProps) => {
        updateTimeDoctorResp.mutate(
            {id, param},
            {
                onSuccess: (data: string | undefined) => {
                    toast.success("의사 시간 수정 완료");
                    if (isUndefined(data)) {
                        return;
                    }
                    const copied = cloneDeep(doctorList);
                    const index = findIndex(copied, (item) => isEqual(item.id, id));
                    copied[index].doctorHours = param;
                    setDoctorList(copied);
                },
                onError: (e: any) => {
                    setErrorStatus(e);
                },
            }
        );
    };

    // 의사 정보 삭제
    const deleteDoctor = (id: string) => {
        deleteDoctorResp.mutate(id, {
            onSuccess: (data: string | undefined) => {
                toast.success("의사 정보 삭제 완료");
                if (isUndefined(data)) return;
                const copied = cloneDeep(doctorList);
                const index = findIndex(copied, (item) => isEqual(item.id, id));
                copied.splice(index, 1);
                if (gte(index, 0)) {
                    navigate("/doctor");
                    setDoctorList(copied);
                }
            },
            onError: (e: any) => {
                setErrorStatus(e);
            },
        });
    };

    const setFindInfoQueryTrigger = useSetRecoilState(doctorFindInfoState);
    const setReservationQueryTrigger = useSetRecoilState(doctorReservationState);

    // 의사 예약 설정
    const setReservationDoctor = (param: OoParamDoctorScheduleCreate) => {
        setReservationResp.mutate(param, {
            onSuccess: (data: IOoResponse | undefined) => {
                setFindInfoQueryTrigger(true);
                setReservationQueryTrigger(true);
                toast.success("의사 예약 스케쥴 설정 완료");
                if (isUndefined(data)) return;
            },
            onError: (e: any) => {
                setErrorStatus(e);
                console.log('setReservationDoctor error ====>', e);
            },
        })
    };

    // 의사 진료 상태 변경
    const changeStatusDiagnosis = (id: string, status: number, comment?: string) => {
        const param = new OoParamDiagnosisStatus(id, status, comment || "");
        diagnosisStatusResp.mutate(param, {
            onSuccess: (data: IOoResponse | undefined) => {
                toast.success("진료 상태 변경 완료");
                if (isUndefined(data)) return;
            },
            onError: (e: any) => {
                setErrorStatus(e);
                console.log('changeStatusDiagnosis error ====>', e);
            },
        })
    };

    // 의사 가족 진료 상태 변경
    const updateStatus = (list: Array<OoParamDiagnosisStatus>) => {
        updateStatusResp.mutate(list, {
            onSuccess: (data: IOoResponse | undefined) => {
                toast.success("진료 상태 변경 완료");
                if (isUndefined(data)) return;
            },
            onError: (e: any) => {
                setErrorStatus(e);
                console.log('updateStatus error ====>', e);
            },
        })
    }

    // 의사 진료 완료
    const updateTelemedicine = (param: Array<OoParamPrescriptionCreate>) => {
        updateTelemedicineResp.mutate(param, {
            onSuccess: (data: IOoResponse | null) => {
                if (isNull(data)) return;
                navigate('/home');
            },
            onError: (e: any) => {
                setErrorStatus(e);
                console.log('updateTelemedicine error ====>', e);
            },
        })
    };

    // 의사 처방전 저장
    const saveTelemedicine = (param: Array<OoParamPrescriptionCreate>) => {
        saveTelemedicineResp.mutate(param, {
            onSuccess: (data: IOoResponse | undefined) => {
                if (isUndefined(data)) return;
                navigate('/home');
            },
            onError: (e: any) => {
                setErrorStatus(e);
                console.log('saveTelemedicine error ====>', e);
            },
        })
    };

    // 의사 처방전 수정 
    const modifyTelemedicine = (param: OoParamPrescriptionModify) => {
        prescriptionModifyResp.mutate(param, {
            onSuccess: (data: IOoResponse | undefined) => {
                if (isUndefined(data)) return;
                toast.success("처방전 변경 완료");
            },
            onError: (e: any) => {
                setErrorStatus(e);
                console.log('modifyTelemedicine error ====>', e);
            }
        })
    };

    // 의사 내원 확인
    const confirmVisitComplete = (id: string) => {
        confirmVisitCompleteResp.mutate(id, {
            onSuccess: (data: IOoResponse | undefined) => {
                if (isUndefined(data)) return;
                toast.success("내원 확인 완료");
            },
            onError: (e: any) => {
                setErrorStatus(e);
                console.log('confirmVisitComplete error ====>', e);
            }
        })
    };

    // 접수 알림톡
    const sendWaitNotification = (id: string) => {
        sendWaitNotificationResp.mutate(id, {
            onSuccess: (data: IOoResponse | undefined) => {
                if (isUndefined(data)) return;
                toast.success("알림톡 전송 완료");
            },
            onError: (e: any) => {
                setErrorStatus(e);
                console.log('sendWaitNotification error ====>', e);
            }
        })
    };

    // 예약 확정
    const updateReservationConfirm = (id: string) => {
        confirmReservationResp.mutate(id);
    };

    // 예약일 도래 테스트
    const changeReservationDiagnosisStatus = (doctorontid: string) => {
        const param = new OoParamReservationDiagnosisStatus();
        param.doctorontid = doctorontid;
        param.status = OoWapleReservationStatus.onlineConfirm;
        changeReservationDiagnosisStatusResp.mutate(param, {
            onSuccess: (data: IOoResponse | null) => {
                if (isNull(data)) return;
                toast.success("예약일 도래 테스트 상태 변경 성공");
            },
            onError: (e: any) => {
                setErrorStatus(e);
                console.log('changeReservationDiagnosisStatus error ====>', e);
            }
        })
    }

    return {
        doctorList,
        setDoctorList,
        getDoctorByUserId,
        getDoctorById,
        createDoctor,
        updateDoctor,
        updateTimeDoctor,
        deleteDoctor,
        setReservationDoctor,
        changeStatusDiagnosis,
        updateTelemedicine,
        saveTelemedicine,
        modifyTelemedicine,
        confirmVisitComplete,
        sendWaitNotification,
        updateStatus,
        updateReservationConfirm,
        changeReservationDiagnosisStatus,
    };
};
