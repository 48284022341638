import { IOoUnospayDelivery, IOoUnospaySalesMenuOption, OoDeliveryStatus, deliveryProcessingStatus } from '@api/models'
import { DoctorOnAPI } from '@api/request';
import React, { useEffect } from 'react'
import { ExcelExportModel } from '../model';
import { IOoUnospayProvider } from '@api/models/unospay/provider';
import _, { endsWith, filter, startsWith } from "lodash";
import moment from "moment";
import FileSaver from "file-saver";
import { IOoProducts } from '@api/models/productSetting/product';
const ExcelJS = require("exceljs");

const MTS_CNT = 2;

const buyerOrder = async (list:IOoUnospayDelivery[], mtsProductList:IOoProducts[]) => {
    let data_array: Array<ExcelExportModel> = [];

    const underBarStartsWithCheck = (code:string, optionCode:string) => {
        if(!_.isUndefined(optionCode) && startsWith(optionCode,'_')){
            return `${code}_${optionCode.replaceAll('_', '')}`;
        }else if(!_.isUndefined(optionCode) && endsWith(optionCode,'_')){
            return `${code}_${optionCode.replaceAll('_', '')}`;
        }else if(optionCode.length !== 0){
            return `${code}_${optionCode}`;
        }else{
            return `${code}`
        }
    }
    
    const filterList = list.map(item => {
        if (item.salesMenu) {
            const filteredSalesMenu = item.salesMenu.filter(sale => {
                // if (!_.isUndefined(sale.salesOption) && sale.salesOption.length > 0) {
                //     const makeCode = underBarStartsWithCheck(sale.externalCode!,`${sale.salesOption.map(s => !_.isUndefined(s.externalCode) && s.externalCode).join('_')}`);
                //     const splitCode = makeCode.charAt(makeCode.length -1) === '_' ? makeCode.replace(/_\s*$/, '') : makeCode;
                //     return mtsProductList.some(product => product.externalCode === splitCode);
                // } else {
                //     return mtsProductList.some(product => product.externalCode === sale.externalCode);
                // }
                return mtsProductList.some(product => product.externalCode === sale.externalCode);
            });
            if (filteredSalesMenu.length > 0) {
                return { ...item, salesMenu: filteredSalesMenu };
            }
        }
        return null; 
    })
    .filter(item => item !== null);
    
    const findProduct = (externalCode: string) => {
        const product =  mtsProductList.find(product => product.externalCode == externalCode);
        return product;
    }

    const getOptionOrderCode = (product: IOoProducts, optionCode: string) => {
        const option = product.optionList?.find(option => option.optionCode === optionCode);
        return option?.optionOrderCode;
    }

    const getOptionName = (product: IOoProducts, optionCode: string) => {
        const option = product.optionList?.find(option => option.optionCode === optionCode);
        return option?.optionName;
    }

    const createModel = (e:IOoUnospayDelivery) => {
        const model = new ExcelExportModel();
                      model.name = e.recipientName;
                      model.address1 = e.address;
                      model.address2 = e.addressDetail;
                      model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                      model.zipCode = e.zipCode;
                      model.memo = e.memo;
                      model.subMemo = e.subMemo;
                      model.quantity = "1";
                      model.deliveryCost = e.id;
                      model.hospitalName = e.hospitalName;
        return model;
    }

    filterList.map((item) => {
        if(!_.isNull(item) && item.status === OoDeliveryStatus.existAddress){
            item.salesMenu?.map(saleItem => {
                let model = createModel(item);
                console.log(saleItem);
                if(!_.isUndefined(saleItem.salesOption) && saleItem.salesOption.length > 0){
                    const code = saleItem.externalCode!;
                    const optionCode = underBarStartsWithCheck(code, `${saleItem.salesOption.map(sale => !_.isUndefined(sale.externalCode) && sale.externalCode).join('_')}`);
                    const splitCode = optionCode.charAt(optionCode.length -1) === '_' ? optionCode.replace(/_\s*$/, '') : optionCode;
                    console.log('splitCode', splitCode, optionCode);
                    const product = findProduct(code);
                    model.product = product?.productName;
                    
                    saleItem.salesOption.forEach(option => {
                        // const foundProduct = findProduct(option.externalCode!);
                        console.log('foundProduct', product);
                        model.productCode = product?.productCode;

                        if(product && option.externalCode) {
                            const optionOrderCode = getOptionOrderCode(product, option.externalCode);
                            console.log('optionOrderCode', optionOrderCode);
                            if(optionOrderCode){
                                model.productCode += _.isEmpty(model.productCode) ? `${optionOrderCode}` : ` ${optionOrderCode}`;
                            }

                            const optionName = getOptionName(product, option.externalCode);
                            console.log('optionName', optionName);
                            if(optionName){
                                model.product += _.isEmpty(model.product) ? `${optionName}` : ` ${optionName}`;
                            }
                        }
                        //     if (optionOrderCode) {
                        // if (product && option.externalCode) {
                                
                        //         console.log('optionOrderCode', optionOrderCode);
                        //         if (optionOrderCode) {
                        //             model.productCode = model.productCode + ' ' + optionOrderCode;
                        //         }
                        //     }
                    })
                }else{
                    model.product = findProduct(saleItem.externalCode!)?.productName;
                    model.productCode = findProduct(saleItem.externalCode!)?.productCode;
                }
                data_array.push(model);
            });
        }
    })
    
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferBUYER = await (await fetch("/assets/buyer2.xlsx")).arrayBuffer();
    const workbookBUYER = new ExcelJS.Workbook();
    await workbookBUYER.xlsx.load(arrayBufferBUYER);
    const ws_BUYER = workbookBUYER.getWorksheet("발주등록 Sample");

    data_array.map((data: ExcelExportModel, index: number) => {
        const row = ws_BUYER.getRow(index + 2); // 엑셀은 1부터 시작
        [
            data.productCode,
            data.product,
            data.quantity,
            '택배',
            '',
            data.name,
            data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
            data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
            data.zipCode,
            `${data.address1}`,
            `${data.address2}`,
            data.memo,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
        ].forEach((value , index) => {
            const cell = row.getCell(index + 1);
            cell.value = value;
            cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            };
        })
    });
    const excelBuffer = await workbookBUYER.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName + "_" + moment().format("YYYYMMDD") + "(사입업체)" + excelFileExtension
    );
}

export default buyerOrder