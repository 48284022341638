import doctorInitializedInfo from "../../../../../assets/doctorInitialInfo.json";
import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import classNames from "classnames/bind";
import buttonStyles from "../../../../../styles/common/Button.module.scss";
import styled from "styled-components";
import {toast} from "react-toastify";
import {IOoDoctor} from "@models/doctor";

interface Props {
    data: IOoDoctor,
    editButtonClick: boolean,
    submitButtonClick: boolean,
    onChange: (data: Array<string>) => void,
}

export const DoctorDepartments = ({data, editButtonClick, submitButtonClick, onChange}: Props) => {
    const bx = classNames.bind(buttonStyles);
    const [checkedDepartments, setCheckedDepartments] = useState<Array<string>>([]);

    useEffect(() => {
        setCheckedDepartments(_.cloneDeep(data.departments));
    }, [editButtonClick]);


    const onCheckedDepartments = (e: any) => {
        const index = _.findIndex(checkedDepartments, (item) => _.isEqual(item, e.target.value));
        if (_.lt(checkedDepartments.length, 4)) {
            if (!_.isEqual(index, -1)) {
                checkedDepartments.splice(index, 1);
                setCheckedDepartments(checkedDepartments);
                onChange(checkedDepartments);
            } else {
                checkedDepartments.push(e.target.value);
                setCheckedDepartments(checkedDepartments);
                onChange(checkedDepartments);
            }

        } else {
            if (!_.isEqual(index, -1)) {
                checkedDepartments.splice(index, 1);
                setCheckedDepartments(checkedDepartments);
                onChange(checkedDepartments);
            } else {
                toast.error('진료 과목은 최대 4개까지 선택가능합니다.');
                e.target.checked = false;
            }
        }
    };

    const isEqualDepartments = useCallback((value: string) => {
        const targetList = checkedDepartments;
        return _.includes(targetList, value);
    }, [editButtonClick, onCheckedDepartments]);

    const isEqualDefaultDepartments = useCallback((value: string) => {
        const temp = data.departments.map(item => _.isEqual(item, value));
        return _.includes(temp, true);
    }, [onCheckedDepartments]);

    const AfterChecked = () => {
        return (
            <Wrapper>
                {
                    doctorInitializedInfo.departments.map((item, index) => (
                        <div key={index}>
                            <input
                                id={`${item.subject}`}
                                type="checkbox"
                                defaultChecked={isEqualDepartments(`${item.subject}`)}
                                value={item.subject}
                                hidden={true}
                                disabled={!editButtonClick}
                                onChange={onCheckedDepartments}
                            />
                            <label htmlFor={`${item.subject}`} className={bx('checkbox-label')}
                                   hidden={!editButtonClick}>
                                <div>{item.subject}</div>
                            </label>
                        </div>
                    ))
                }
            </Wrapper>
        )
    };

    const BeforeChecked = () => {
        return (
            <Wrapper>
                {
                    doctorInitializedInfo.departments.map((item, index) => (
                        <div key={index}>
                            <input
                                id={`${item.subject}`}
                                type="checkbox"
                                checked={isEqualDefaultDepartments(`${item.subject}`)}
                                value={item.subject}
                                hidden={true}
                                disabled={!editButtonClick}
                                onChange={onCheckedDepartments}
                            />
                            <label htmlFor={`${item.subject}`} className={bx('checkbox-label')}
                                   hidden={editButtonClick}
                            >
                                <div>{item.subject}</div>
                            </label>
                        </div>
                    ))
                }
            </Wrapper>
        )
    };

    return (
        <>
            <AfterChecked/>
            <BeforeChecked/>
        </>

    )
}

const Wrapper = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
`;
