import {DoctorOnAPI} from "@api/request";
import {useSuspenseHook} from "@hooks/suspense";
import {OoParamPrescriptionCreate} from "@params/telemedicine";
import {useMutation} from "react-query";
import {OoParamFamilyPrescriptionCreate} from "@api/params";
import _ from "lodash";
import {isRequestSucceed} from "@api/reponses";

export const useDoctorSaveTelemedicineQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'doctor/saveTelemedicine',
        async (param: Array<OoParamPrescriptionCreate>) => {
            try {
                setLoading();
                let response = undefined;
                let familyResponse = undefined;
                if (_.gte(param.length, 2)) {
                    familyResponse = await DoctorOnAPI.shared.familyTelemedicine.save(new OoParamFamilyPrescriptionCreate(param));
                } else {
                    response = await DoctorOnAPI.shared.telemedicine.save(_.head(param)!);
                }
                setStopLoading();
                if (!_.isUndefined(response)) {
                    if (isRequestSucceed(response)) {
                        return response;
                    }
                }
                if (!_.isUndefined(familyResponse)) {
                    if (isRequestSucceed(familyResponse)) {
                        return response;
                    }
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        }
    )

}