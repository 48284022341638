import {AxiosInstance} from "axios";
import {OoParamPaypleRefund} from "@api/params";
import {IOoResponse} from "../reponses";
import {endPoint} from "./base";

class DoctorOnPaypleApi {
    constructor(private readonly axiosInstance: AxiosInstance) {
    }

    async refund(param: OoParamPaypleRefund): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.payple.refund, param);
    }
}

export {DoctorOnPaypleApi}