import { IOoUnospayDelivery } from "@models/unospay";
import _ from 'lodash';
import { toast } from "react-toastify";
interface Props {
  exCode: string;
  list: Array<IOoUnospayDelivery>;
}

export const useUnospayProviderFilter = ({ exCode, list }: Props) => {
  if (exCode === "-1") {
    return list;
  }
  return list.filter((item) => {
    if(_.isUndefined(item.transportInfo)){
      console.log(item);
      toast.error(`${item.hospitalName} 의 제품코드가 잘못되었습니다. 주문자 휴대번호는 ${item.mobile} 입니다.`);
    }else{
      return item.transportInfo.some((info) => {
        return info.externalCode.startsWith(exCode);
      });
    }

    // if(item.transportInfo){
    //   return item.transportInfo.some((info) => {
    //     return info.externalCode.startsWith(exCode);
    //   });
    // }else{
    //   console.log(`ERROR :${item}`);
    // }
  });
};
