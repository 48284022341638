import {CustomInputText} from "@components/custom";
import _ from "lodash";
import React, {useEffect} from "react";
import {OoParamPharmacyUpdate} from "@api/params";
import {usePharmacyAlimTalkHook} from "../hooks/usePharmacyAlimTalkHook";
import styled from "styled-components";
import {IOoPharmacy} from "@models/pharmacy";

interface Props {
    data: IOoPharmacy,
    componentName: string,
}

export const PharmacyAlimTalk = ({data, componentName}: Props) => {
    const {
        editStatus,
        color,
        setColor,
        item,
        setItem,
        onChangeAlimTalk,
        isReset,
        updatePharmacy
    } = usePharmacyAlimTalkHook(data, componentName);

    useEffect(() => {
        isReset();
    }, []);

    useEffect(() => {
        setItem({alimTalkReceivers: data.alimTalkReceivers});
    }, [data]);

    // 편집 모드 일때, border 색상 활성화
    useEffect(() => {
        if (editStatus.isEdit) {
            setColor("blue");
        } else {
            setColor("grey");
            setItem({alimTalkReceivers: data.alimTalkReceivers});
        }
    }, [editStatus.isEdit]);

    // 전송 모드 일때, 쿼리 trigger
    useEffect(() => {
        if (_.isEqual(editStatus.isSubmit, false)) {
            return;
        }
        if (window.confirm("수정하시겠습니까?")) {
            const param = new OoParamPharmacyUpdate();
            param.id = data.id || "";
            param.alimTalkReceivers = item.alimTalkReceivers;
            updatePharmacy(param);
        }
    }, [editStatus.isSubmit]);

    return (
        <Wrapper>
            <div>
                <div>
                    <CustomInputText value={_.head(item.alimTalkReceivers) || ""}
                                     color={color}
                                     size="large"
                                     placeholder="알람톡 수신번호를 입력해주세요"
                                     onChanged={onChangeAlimTalk}
                                     disabled={editStatus.isEdit}/>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }
`;
