import _ from "lodash";
import {useRecoilValue} from "recoil";
import {useMemo, useState} from "react";
import {editSelectorFamily} from "@recoils/status";
import {useMakeTimeHook} from "@hooks/common";
import {IOoDoctor} from "@models/doctor";
import {IOoWorkHours} from "@models/pharmacy";
import {useDoctorFunctionHook} from "@hooks/doctor";

export const useDoctorDiagnosisTimeSettingHook = (data: IOoDoctor, componentName: string) => {

    /*
     *  의사의 data.doctorHours에는 점심시간이 맨 마지막 배열에 존재한다.
     */
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const {updateTimeDoctor} = useDoctorFunctionHook();
    const {
        normalizedTimeTable,
        normalizedDefaultTimeTable
    } = useMakeTimeHook();

    const initialData = useMemo(() => {
        return _.isEmpty(data.doctorHours) || _.isUndefined(data.doctorHours) ?
            normalizedDefaultTimeTable()
            : normalizedTimeTable(data.doctorHours);
    }, [data.doctorHours]);

    const [paramsData, setParamsData] = useState<Array<IOoWorkHours>>(initialData);

    const handleTime = (target: IOoWorkHours) => {
        const copied = _.cloneDeep(paramsData);
        const index = _.findIndex(copied, data => _.isEqual(data.dayOfWeek, target.dayOfWeek));
        copied[index] = target;
        setParamsData(copied);
    };

    const findTarget = (data: Array<IOoWorkHours>, item: IOoWorkHours) => {
        return data.find((i) => _.isEqual(i.dayOfWeek, item.dayOfWeek));
    };

    return {
        initialData,
        editStatus,
        paramsData,
        setParamsData,
        handleTime,
        findTarget,
        updateTimeDoctor,
    }
}