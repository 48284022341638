import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import _ from "lodash";
import {BsArrowLeft} from "@react-icons/all-files/bs/BsArrowLeft";
import moment from "moment";
import Calendar from "react-calendar";
import "../calendar.css";
import {ModalOverlay} from "@components/modal";
import {useEnabledSlotQuery, useReservationTimeUpdateQuery} from "@queries/reservation";
import {IOoDiagnosis, OoWapleReservationStatus} from "@api/models";
import {IOoDataUnit} from "@models/reservation";

interface Props {
    visible: boolean;
    unVisible: (e: boolean) => void;
    target: IOoDiagnosis;
}

interface RadioProps {
    isChecked: boolean,
}

export const ChangeDiagnosisReservationModal = ({visible, unVisible, target}: Props) => {
    const [show, setShow] = useState<boolean>(visible);

    const [todayDate, setTodayDate] = useState(new Date());
    const [yearMonthDay, setYearMonthDay] = useState<string>(moment().format("YYYYMMDD"));

    const [queryTrigger, setQueryTrigger] = useState<boolean>(true);
    const [updateQueryTrigger, setUpdateQueryTrigger] = useState<boolean>(false);

    const [activeButton, setActiveButton] = useState<number>(0);
    const [slotUnit, setSlotUnit] = useState<Array<IOoDataUnit> | undefined>(undefined);
    const [timeStamp, setTimeStamp] = useState<number>(0);

    const result = useEnabledSlotQuery({
        patientId: target.patientId,
        doctorId: target.doctorId,
        hospitalId: target.hospitalId ?? "",
        reservationType: target.wapleReservationType ?? OoWapleReservationStatus.unknown,
        reservationDate: yearMonthDay,
        updateQueryTrigger: queryTrigger,
    });

    useReservationTimeUpdateQuery({
        patientId: target.id || '',
        reservationTime: timeStamp,
        updateQueryTrigger: updateQueryTrigger,
    });

    useEffect(() => {
        _.isEqual(show, false) && unVisible(show);
    }, [show]);

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    useEffect(() => {
        queryTrigger ? setQueryTrigger(false) : setQueryTrigger(true);
    }, [yearMonthDay]);

    useEffect(() => {
        if (_.isUndefined(result.data)) {
            return;
        }
        const filteredList = result.data.filter((item) => _.gte(item.convUnit, new Date().getTime()));
        setSlotUnit(filteredList);
        setQueryTrigger(false);
    }, [result.data]);

    const onCloseChangeReservation = () => setShow(false);

    const onClickDate = (value: Date, e: any) => {
        e.preventDefault();
        setYearMonthDay(moment(value).format("YYYYMMDD"));
        !queryTrigger && setQueryTrigger(true);
    };

    const onClickTimeButton = (e: any) => {
        if (_.isUndefined(slotUnit)) return;

        const valueToNumber = Number(e.target.name);
        setActiveButton(valueToNumber);

        const formatDate = moment(yearMonthDay).format('YYYY-MM-DD');
        const time = e.target.value;
        const result = `${formatDate} ${time}`;

        setTimeStamp(Date.parse(result));
    };

    const onUpdateReservationTime = (e: any) => {
        e.preventDefault();
        setUpdateQueryTrigger(true);
    };

    return (
        <ModalOverlay visible={show}>
            <Wrapper>
                <div>
                    <CloseButtonContainer>
                        <button onClick={onCloseChangeReservation}>
                            <BsArrowLeft size={40}/>
                        </button>
                    </CloseButtonContainer>
                    <ContentsContainer>
                        <div className="left-wrapper">
                            <Calendar onChange={setTodayDate}
                                      defaultActiveStartDate={todayDate}
                                      value={todayDate}
                                      formatDay={(locale, date) => moment(date).format("DD")}
                                      prev2Label={null}
                                      next2Label={null}
                                      onClickDay={onClickDate}
                                      minDate={new Date()}
                            />
                        </div>
                        <div className="right-wrapper">
                            <div className="button-wrapper">
                                {
                                    !_.isUndefined(slotUnit) &&
                                    slotUnit.map((item, index) => (
                                        <TimeButtonContainer key={index}>
                                            <TimeButtonLabel htmlFor={`slot${index}`}
                                                             isChecked={_.isEqual(index, activeButton)}>
                                                <input name={`${index}`}
                                                       type="radio"
                                                       value={`${item.unit}`}
                                                       id={`slot${index}`}
                                                       hidden={true}
                                                       onClick={onClickTimeButton}
                                                       disabled={_.isEqual(item.cnt, item.maxCnt)}/>
                                                <div>{item.unit}</div>
                                                <div>{`${item.cnt} / ${item.maxCnt}`}</div>
                                            </TimeButtonLabel>
                                        </TimeButtonContainer>
                                    ))
                                }
                            </div>
                            <div className="submit-button-wrapper">
                                <TextContainer>
                                    날짜와 시간을 선택하여 확인을 누르면 예약이 확정됩니다.
                                </TextContainer>
                                <ConfirmButton onClick={onUpdateReservationTime}>확인</ConfirmButton>
                            </div>
                        </div>
                    </ContentsContainer>
                </div>
            </Wrapper>
        </ModalOverlay>
    );
}

const Wrapper = styled.div`
  width: 868px;
  height: 494px;
  background: #ffffff;
  border-radius: 8px;
  padding: 1%;

  & > div:nth-child(1) {
    height: 100%;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  height: 10%;

  & > button {
    background: none;
    border: none;
    color: #949390;
  }
`;

const ContentsContainer = styled.div`
  display: flex;
  height: 90%;

  .left-wrapper {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;

    /*react-calendar custom*/

    .react-calendar {
      width: 100%;
      border: none;
      height: auto;
    }

    .react-calendar__viewContainer div {
      height: 100%;

      & > abbr[title] {
        text-decoration: none;
      }
    }

    .react-calendar__month-view__days {

    }

    .react-calendar__navigation {
      height: 40px;
      font-size: 15px;
      font-weight: 800;
    }

    .react-calendar__navigation button {
      font-size: 15px;
      font-weight: 800;
    }

    .react-calendar__tile {
      background: none;
      text-align: center;
      line-height: 16px;
      font-size: 13px;
      font-weight: 800;
      padding: 1px 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 55px;
    }

    .react-calendar__tile:enabled:focus,
    .react-calendar__tile:enabled:hover {
      border-radius: 50%;
      background: #86b7fe;
      color: white;
    }

    .react-calendar__tile:disabled {
      color: #f0f0f0;
      background: none;
    }

    .react-calendar__tile--now {
      border-radius: 50%;
      color: black;
      background: none;
    }

    .react-calendar__tile--hasActive {
      background: none;
      color: cornflowerblue;
    }

    .react-calendar__tile--active {
      background: #2176f6;
      border-radius: 6px;
      font-weight: bold;
      color: white;
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      border-radius: 50%;
      color: white;
      background: #86b7fe;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background: none;
    }

    .react-calendar__navigation button:disabled {
      color: #f0f0f0;
      background: none;
    }

    .react-calendar__tile--range {
      background: #f8f8fa;
      color: #2176f6;
      border-radius: 50%;
    }

    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: #2176f6;
      color: white;
      border-radius: 50%;
    }

    .react-calendar__tile--rangeStart {
      background: #2176f6;
      color: white;
      border-radius: 50%;
    }

    .react-calendar__tile--rangeEnd {
      background: #2176f6;
      color: white;
      border-radius: 50%;
    }
  }

  .right-wrapper {
    width: 50%;
    height: 321px;
  }

  .button-wrapper {
    height: fit-content;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    max-height: 321px;
    gap: 20px;
  }

  .submit-button-wrapper {
    top: 123px;
  }
`;

const TimeButtonContainer = styled.div`
`;

const TimeButtonLabel = styled.label<RadioProps>`
  width: 120px;
  height: 56px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;

  & > div:nth-child(1) {
    font-weight: 700;
  }

  & > input[type="radio"]:disabled {
    border: 1px solid ${(props) => props.theme.colors.borderColor.grey};

    & ~ div {
      color: ${(props) => props.theme.colors.fontColor.grey};
    }
  }

  ${(props) => props.isChecked ?
          css`
            border: 1px solid ${(props) => props.theme.colors.borderColor.blue};
          ` :
          css`
            border: 1px solid ${(props) => props.theme.colors.borderColor.grey};
          `};
`;

const TextContainer = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #949390;
`;

const ConfirmButton = styled.button`
  background: #6e8ed1;
  color: #ffffff;
  width: 100%;
  height: 60px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
`;
