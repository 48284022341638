import {AbstractOoParam} from "../base";
import _ from "lodash";

class OoUnospayReadDeliveryInfoRange extends AbstractOoParam {
    // 시작 타임스탬프
    beginTimestamp: string = "0";

    // 종료 타임스탬프
    endTimestamp: string = "0";

    // 페이지네이션을 위한 아이디
    id?: string = undefined;

    count?: string = "100";

    constructor(beginTimestamp: string,
                endTimestamp: string,
                diagnosisId?: string,
                count?: string) {
        super();
        this.beginTimestamp = beginTimestamp;
        this.endTimestamp = endTimestamp;
        this.count = count;
        this.id = diagnosisId;
    }

    getPathParams() {
        return _.isEmpty(this.id)
            ? `${this.beginTimestamp}/${this.endTimestamp}/${this.count}`
            : `${this.beginTimestamp}/${this.endTimestamp}/${this.count}/${this.id}`;
    }
}

export {OoUnospayReadDeliveryInfoRange}