import React, {useEffect, useState} from "react";
import _ from "lodash";
import {PharmacyProfileUploader} from "@utils/storage";
import styled from "styled-components";
import {IOoPharmacy} from "@models/pharmacy";

interface Props {
    data: IOoPharmacy,
    isEdit: boolean,
    isCancel: boolean,
    onChange: (value: string) => void,
}

export const PharmacyPicture = ({data, isEdit, onChange, isCancel}: Props) => {
    const [imgSrc, setImgSrc] = useState<string>(_.isUndefined(data.pictures) ? "" : `${data.pictures[0]}`);
    const [fileObject, setFileObject] = useState<File>();
    const [attachment, setAttachment] = useState<string>("");
    const [edit, setEdit] = useState<boolean>(isEdit);
    const [cancel, setCancel] = useState<boolean>(isCancel);

    // Props 로 받은 데이터 변경될때마다 갱신
    useEffect(() => {
        setEdit(isEdit);
        setCancel(isCancel);
    }, [isEdit, isCancel]);

    // cancel 버튼이 클릭되었을 때, img src 주소 초기화
    useEffect(() => {
        cancel && setImgSrc(_.isUndefined(data.pictures) ? "" : `${data.pictures[0]}`);
    }, [cancel]);

    useEffect(() => {
        !_.isUndefined(data.pictures) && setImgSrc(`${data.pictures[0]}`);
    }, [data]);

    // imgSrc가 변경될때마다 상위 컴포넌트로 전달
    useEffect(() => {
        onChange(imgSrc);
    }, [imgSrc]);

    // 이미지 첨부 할 경우, request 함수 실행
    useEffect(() => {
        request();
    }, [attachment]);

    const request = async () => {
        if (!_.isUndefined(fileObject)) {
            const downloadUrl = await PharmacyProfileUploader.upload(fileObject);
            if (_.isEmpty(downloadUrl)) {
                alert('오류가 발생했습니다.');
                return;
            }
            setImgSrc(downloadUrl || '');
            setAttachment('');
        }
    };


    const onLoadPicture = (e: any) => {
        const reader = new FileReader();
        const target = e.target.files[0];
        setFileObject(target);

        reader.onload = (finishEvent) => {
            const result = finishEvent.target!.result as string
            setAttachment(result);
        };
        reader.readAsDataURL(target);
    };

    return (
        <UploadImage>
            <UploadInputContainer>
                {
                    _.isEmpty(imgSrc) && !edit ?
                        <NoImage>
                            <div>{`image +`}</div>
                        </NoImage>
                        :
                        <>
                            {
                                _.isNull(attachment)
                                    ? <>
                                        <UploadLabel htmlFor="imgUpload">
                                            <div>{`image +`}</div>
                                            <div>
                                                <div>
                                                    <img alt="" src={process.env.PUBLIC_URL + 'img/camera-icon.png'}/>
                                                </div>
                                            </div>
                                        </UploadLabel>
                                        <input type="file"
                                               id="imgUpload"
                                               accept=".jpg,.png,.jpeg"
                                               onChange={onLoadPicture}
                                        />
                                    </>
                                    :
                                    <>
                                        <ButtonContainer>
                                            <UploadLabel htmlFor="imgUpload">
                                                {
                                                    _.isEmpty(imgSrc) ?
                                                        <NoImage>
                                                            <div>{`image +`}</div>
                                                            <div>
                                                                <div>
                                                                    <img alt=""
                                                                         src={process.env.PUBLIC_URL + 'img/camera-icon.png'}/>
                                                                </div>
                                                            </div>
                                                        </NoImage>
                                                        :
                                                        <AttachedImage src={imgSrc}/>
                                                }
                                                <div hidden={!edit}>
                                                    <div>
                                                        <img alt=""
                                                             src={process.env.PUBLIC_URL + 'img/camera-icon.png'}/>
                                                    </div>
                                                </div>
                                            </UploadLabel>
                                            {/*<AttachedImage src={attachment}/>*/}
                                            <input type="file"
                                                   id="imgUpload"
                                                   accept=".jpg,.png,.jpeg"
                                                   onChange={onLoadPicture}
                                                   disabled={!edit}
                                            />
                                        </ButtonContainer>
                                    </>
                            }
                        </>

                }
            </UploadInputContainer>
        </UploadImage>
    )
}


const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  height: 100%;

  & > div {
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 4px;

    & > img {
      width: 36px;
      height: 36px;
      background: #FFFFFF;
      border-radius: 8px;
      border: none;
      padding: 4px;
    }
  }
`;

const AttachedImage = styled.img`
  width: 146px;
  height: 146px;
  border-radius: 8px;
  border: 1px solid #DCDBD6;
  object-fit: cover;
  position: absolute;
  z-index: 1;
`;

const UploadInputContainer = styled.div`
  width: 146px;
  height: 146px;
  border: none;
  margin-top: 8px;
`;

const UploadImage = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const NoImage = styled.div`
  width: 146px;
  height: 146px;
  border: 1px solid #DCDBD6;
  border-radius: 8px;
  background: #EEEEEE;
  position: relative;

  & > div:nth-child(1) {
    width: 146px;
    height: 146px;
    font-weight: 400;
    font-size: 14px;
    color: #828282;
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:nth-child(2) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 4px;

    & > div {
      width: 36px;
      height: 36px;
      background: #FFFFFF;
      border-radius: 8px;
      border-color: #FFFFFF;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 20px;
        height: 18px;
      }
    }
  }
`;

const UploadLabel = styled.label`
  width: 146px;
  height: 146px;
  cursor: pointer;
  border: 1px solid #DCDBD6;
  border-radius: 8px;
  background: #EEEEEE;
  z-index: 10;
  position: relative;

  & > div:nth-child(1) {
    width: 146px;
    height: 146px;
    font-weight: 400;
    font-size: 14px;
    color: #828282;
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:nth-child(2) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 4px;

    & > div {
      width: 36px;
      height: 36px;
      background: #FFFFFF;
      border-radius: 8px;
      border-color: #FFFFFF;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 20px;
        height: 18px;
      }
    }
  }
`;
