import _ from "lodash";
import {PDFDocument} from "pdf-lib";
import {useSuspenseHook} from "@hooks/suspense";
import {OoParamAddMedicinePrice, OoParamDiagnosisStatus} from "@params/familyTelemedicine";
import {IOoDiagnosis, OoDiagnosisStatus} from "@models/diagnosis";

interface PayProps {
    data: IOoDiagnosis,
}

interface PayListProps {
    targetData: OoParamAddMedicinePrice | undefined,
    priceDataList: Array<OoParamAddMedicinePrice>,
}

interface CopyPageProps {
    list: Array<IOoDiagnosis>,
}

interface DeliveryProps extends CopyPageProps {
    dataStatus: boolean,
}

export const usePharmacyTelemedicineHook = () => {
    const {setErrorStatus} = useSuspenseHook();

    function makeMedicinePriceParam({data}: PayProps) {
        return new OoParamAddMedicinePrice(
            data.id || '',
            _.toNumber(data.medicinePayPrice),
            data.isAgreeDUR || false,
            data.isSubstitute || false,
            data.medicineMemo || '',
        )
    }

    function makeMedicinePriceList({targetData, priceDataList}: PayListProps) {
        if (!_.isUndefined(targetData)) {
            const index = _.findIndex(priceDataList, (item) => _.isEqual(item.diagnosisId, targetData?.diagnosisId));
            return priceDataList.splice(index, 1, targetData);
        }
    }

    async function copyPrescription({list}: CopyPageProps) {
        const prescriptionList = list.map(item => _.head(item.prescription));
        if (!_.isUndefined(prescriptionList)) {
            try {
                const targetPdf = await PDFDocument.create();

                const srcDoc = [];
                for (const item of prescriptionList) {
                    const response = await fetch(item || '');
                    const doc = PDFDocument.load(await response.arrayBuffer());
                    srcDoc.push(doc);
                }

                const copied = [];
                for (const item of srcDoc) {
                    const page = await item;
                    const doc = await targetPdf.copyPages(page, page.getPageIndices());
                    copied.push(doc);
                }

                for (const item of copied) {
                    if (item.length > 1) {
                        for (const i of item) {
                            await targetPdf.addPage(i);
                        }
                    } else {
                        await targetPdf.addPage(_.head(item));
                    }
                }

                const pdfBytes = await targetPdf.save();
                return URL.createObjectURL(new Blob([pdfBytes], {type: 'application/pdf;charset=utf-8'}));
            } catch (e: any) {
                setErrorStatus(e);
            }
        }
    }

    function makeDeliveryStatusParam({dataStatus, list}: DeliveryProps) {
        let arr;
        if (dataStatus) {
            arr = list.map((item) => (
                new OoParamDiagnosisStatus(item.id || '', OoDiagnosisStatus.medicineDeliveryAccept, '')
            ));
        } else {
            arr = list.map((item) => (
                new OoParamDiagnosisStatus(item.id || '', OoDiagnosisStatus.medicineDeliveryStart, '')
            ));
        }
        return arr;
    }

    return {
        makeMedicinePriceParam,
        makeMedicinePriceList,
        copyPrescription,
        makeDeliveryStatusParam,
    }
}