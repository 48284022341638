import React, {useEffect} from "react";
import {useSearchSettingHook} from "../../hooks/useSearchSettingHook";
import {CustomSearchBar} from "@components/custom";
import _ from "lodash";
import {FilterStatus} from "../DiagnosisFilterCard";

interface Props {
    target: string,
    id?: string,
}

export const DiagnosisSearch = ({target, id}: Props) => {
    const {
        searchList,
        buttonStatus,
        setButtonStatus,
        setSearchList,
        setSelectNumber,
        onClickSearchButton,
        onChangeDeleteButton,
        onChangeSearchName,
        filteredTarget,
        resetSearchList,
    } = useSearchSettingHook({target: target, id: id || ""});

    useEffect(() => {
        resetSearchList();
        filteredTarget(false, true);
    }, []);

    useEffect(() => {
        if (_.isEqual(buttonStatus.search, false)) return;
        if (_.eq(searchList.userName, '')) return;
        filteredTarget(true);
        setButtonStatus({...buttonStatus, cancel: true, search: false});
    }, [buttonStatus.search]);

    useEffect(() => {
        if (!buttonStatus.cancel) return;
        resetSearchList();
        setButtonStatus({...buttonStatus, search: false});
        setSelectNumber(_.toString(FilterStatus.total));
    }, [buttonStatus.cancel]);

    return (
        <div>
            <CustomSearchBar target={target}
                             cancelStatus={buttonStatus.cancel}
                             searchStatus={buttonStatus.search}
                             onChangeDeleteButton={onChangeDeleteButton}
                             onChangeName={onChangeSearchName}
                             onChangeSubmit={onClickSearchButton}
            />
        </div>
    )
}