import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import _ from "lodash";
import {IOoDiagnosis, IOoDoctor, IOoHospital, IOoUnospayDelivery} from "api";
import {toast} from "react-toastify";
import {IOoPharmacy} from "@models/pharmacy";
import {IoClose} from "@react-icons/all-files/io5/IoClose";
import {IoSearch} from "@react-icons/all-files/io5/IoSearch";
import {useRecoilState} from "recoil";
import {searchListState} from "@recoils/search";

interface SearchProps {
    userName?: string;
    list:
        | Array<IOoDiagnosis>
        | Array<IOoDoctor>
        | Array<IOoPharmacy>
        | Array<IOoHospital>
        | Array<IOoUnospayDelivery>;
}

interface Props {
    list?: SearchProps;
    target: string,
    cancelStatus: boolean;
    searchStatus: boolean;
    onChangeName: (value: string) => void;
    onChangeDeleteButton: (event: boolean) => void;
    onChangeSubmit: (event: boolean) => void;
}

export const CustomSearchBar = ({
                                    list,
                                    target,
                                    cancelStatus,
                                    searchStatus,
                                    onChangeName,
                                    onChangeDeleteButton,
                                    onChangeSubmit,
                                }: Props) => {

    const [searchButton, setSearchButton] = useState(searchStatus);

    const [searchList, setSearchList] = useRecoilState(searchListState(target));
    const [cancel, setCancel] = useState<boolean>(!_.isEmpty(searchList.userName || ""));

    const onClickDeleteButton = () => {
        setSearchList({id: target, userName: ""});
        setCancel(false);
    };

    const onClickSearchButton = () => {
        if (_.isEqual(name, "")) {
            toast.error("이름을 입력하세요.");
            return;
        }
        setSearchButton(true);
    };

    const onChangeSearchName = (e: any) => {
        console.log(e.target.value);
        _.isEmpty(e.target.value) ? setCancel(false) : setCancel(true);
        setSearchList({id: target, userName: e.target.value});
    };

    //Enter 검색 Event Trigger
    const onKeyPress = (e: any) => {
        _.isEqual(e.key, "Enter") ? setSearchButton(true) : setSearchButton(false);
    };

    useEffect(() => {
        onChangeDeleteButton(cancel);
    }, [cancel]);

    useEffect(() => {
        searchButton && onChangeSubmit(searchButton);
    }, [searchButton]);

    useEffect(() => {
        setSearchButton(searchStatus);
        if (!_.isEmpty(list?.userName)) {
            setCancel(true);
            return;
        }
        setCancel(cancelStatus);
    }, [searchStatus, cancelStatus]);

    return (
        <Wrapper>
            <SearchContainer>
                <SearchInput placeholder="검색 키워드 입력"
                             value={searchList.userName || ""}
                             onChange={onChangeSearchName}
                             onKeyPress={onKeyPress}/>
                <CloseButton type="button"
                             hidden={!cancel}
                             onClick={onClickDeleteButton}>
                    <IoClose size={16}/>
                </CloseButton>
                <SearchButton type="button" onClick={onClickSearchButton}>
                    <IoSearch size={16}/>
                </SearchButton>
            </SearchContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: flex-end;
`;

const SearchContainer = styled.div`
  width: 230px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #c2c1bd;
  border-radius: 8px;
  background: #ffffff;
`;

const SearchInput = styled.input`
  width: 158px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #00326a;
  padding-left: 5%;

  &:focus-visible {
    outline: none;
  }
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 100%;
  border-width: 0.5px 0px 0.5px 0.5px;
  border-style: solid;
  border-color: #c2c1bd;
  background: #ffffff;
  color: #00326a;

  ${(props) =>
          props.hidden &&
          css`
            display: none;
          `}
`;

const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 100%;
  background: #ffffff;
  border: 0.5px solid #c2c1bd;
  border-radius: 0px 8px 8px 0px;
  color: #00326a;
`;
