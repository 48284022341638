import {fnKakaoFilter, getMedicineDeliveryPayStatus, IOoDiagnosis, OoMedicineDeliveryType} from "@api/models";
import _ from "lodash";
import styled from "styled-components";
import {CancelButtons} from "@components/diagnosisForm/deliveryInfo/cancelButtons";
import {useRecoilState} from "recoil";
import {kakaoDeliveryState} from "@recoils/kakao";
import {useEffect} from "react";

interface Props {
    diagnosis: IOoDiagnosis,
}

export const DeliveryInfo = ({diagnosis}: Props) => {
    const {filteredKaKaoStatus, getKaKaoMobilityStatus, getKaKaoDeliveryOption} = fnKakaoFilter();
    const [kakaoDelivery, setKakaoDelivery] = useRecoilState(kakaoDeliveryState);

    useEffect(() => {
        const kakaoStatus = getKaKaoMobilityStatus(diagnosis.deliveryOrderResultMessage ?? '');
        setKakaoDelivery(kakaoStatus);
    }, []);

    return (
        <Wrapper>
            {
                _.isEqual(diagnosis.deliveryType, OoMedicineDeliveryType.kaKaoMobility) || _.isEqual(diagnosis.deliveryType, OoMedicineDeliveryType.todayDelivery)
                    ?
                    <>
                        <div className="delivery-container">
                            <div className="title">배송 정보</div>
                            <CancelButtons status={diagnosis}/>
                        </div>
                        <Table>
                            <thead></thead>
                            <tbody>
                            <tr>
                                <td>오더 아이디</td>
                                <td>{diagnosis.deliveryOrderCode ?? '-'}</td>
                                <td>배송 업체</td>
                                <td>{diagnosis.deliveryCompanyName ?? '-'}</td>
                            </tr>
                            <tr>
                                <td>배송 옵션</td>
                                <td>{getKaKaoDeliveryOption(diagnosis)}</td>
                                <td>배송비</td>
                                <td>{getMedicineDeliveryPayStatus(diagnosis).title}</td>
                            </tr>
                            <tr>
                                <td>취소 수수료</td>
                                <td>
                                    {_.isEqual(diagnosis.kakaoCancelFeePrice, 0) ? '-' : diagnosis.kakaoCancelFeePrice}</td>
                                <td>기사 배정</td>
                                {
                                    _.isEqual(diagnosis.deliveryOrderResultCode, '100') ?
                                        <td>등록 완료</td> : <td>{filteredKaKaoStatus(diagnosis.kakaoQuickStatus || 0)}</td>
                                }
                            </tr>
                            <tr>
                                <td>상태 조회</td>
                                <td>{kakaoDelivery}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </>
                    :
                    null
            }
        </Wrapper>
    )
}


const Wrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;

  .delivery-container {
    display: flex;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    width: 100px;
  }
`;


const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: 100%;
  height: auto;
  font-size: 13px;
  margin-top: 8px;

  & tbody tr {
    height: 47px;
  }

  & tbody tr:nth-child(1), tr:nth-child(2), tr:nth-child(3) {
    border-bottom: 1px solid #c8d6f8;
  }

  & tbody tr td:nth-child(2n-1) {
    background: #f4f6fb;
    text-align: center;
    font-weight: 700;
    width: 100px;
    font-size: 13px;
  }

  & tbody tr td:nth-child(2) {
    padding: 10px;
    width: 224px;
    overflow: auto;
  }

  & tbody tr td:nth-child(4) {
    padding: 10px;
    width: 560px;
    overflow: auto;
  }

  & tbody tr td:nth-child(6) {
    padding: 10px;
    width: 560px;
    overflow: auto;
  }

`;