import _ from "lodash";
import {IOoUnospayDelivery, OoDeliveryStatus, filterOfflineTotal} from "@api/models";

// 전체 주문 내역
export function filterTotalList(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
    return filterOfflineTotal(list);
}

// 배송지 미입력
export function filterEmptyAddress(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
    return _.filter(list, (item) => _.isEqual(item.status, OoDeliveryStatus.emptyAddress));
}

// 배송지 입력 완료
export function filterExistAddress(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
    return _.filter(list, (item) => _.isEqual(item.status, OoDeliveryStatus.existAddress));
}

// 주문 취소
export function filterCancelOrder(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
    const predicate1 = (d: IOoUnospayDelivery) => _.isEqual(d.status, OoDeliveryStatus.cancelOrder);
    const predicate2 = (d: IOoUnospayDelivery) => _.isEmpty(d.cancelDateTime);
    return _.filter(list, (item) => predicate1(item) && predicate2(item));
}

// 발송 완료
export function filterSendPackage(list: Array<IOoUnospayDelivery>): Array<IOoUnospayDelivery> {
    return _.filter(list, (item) => _.isEqual(item.status, OoDeliveryStatus.sendPackage));
}