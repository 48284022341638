import {useState} from "react";
import {DoctorCareerInfo} from "../../../../interfaces";

export const useRegisterCareerSettingHook = () => {

    const [data, setData] = useState<DoctorCareerInfo>({
        education: "공용 계정입니다.",
        career: "공용 계정입니다.",
        bio: "공용 계정입니다.",
    });

    const onChangeEducation = (e: any) => setData({...data, education: e.target.value});
    const onChangeCareer = (e: any) => setData({...data, career: e.target.value});
    const onChangeBio = (e: any) => setData({...data, bio: e.target.value});

    return {
        data,
        onChangeEducation,
        onChangeCareer,
        onChangeBio,
    }

}