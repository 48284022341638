import {atom} from "recoil";
import {CalendarParamProp} from "@recoils/unospay";

export const calendarParamState = atom<CalendarParamProp>({
    key: 'operator/calendarParam',
    default: {
        id: "",
        beginTimestamp: "",
        endTimestamp: "",
        count: "",
    },
})