import {IOoDiagnosis, OoKakaoQuickStatus, OoMedicineDeliveryType} from "../diagnosis";
import _ from "lodash";

export const fnKakaoFilter = () => {
    function isRequest(type: number) {
        return _.isEqual(type, OoKakaoQuickStatus.request);
    }

    function isReorder(type: number) {
        return _.isEqual(type, OoKakaoQuickStatus.reorder);
    }

    function isMatching(type: number) {
        return _.isEqual(type, OoKakaoQuickStatus.matching);
    }

    function isSuccessMatched(type: number) {
        return type >= OoKakaoQuickStatus.matched && type <= OoKakaoQuickStatus.dropOffCompleted;
    }

    function isFailedMatching(type: number) {
        return _.isEqual(type, OoKakaoQuickStatus.matchingFailed);
    }

    function isCancelBeforePay(type: number) {
        return _.isEqual(type, OoKakaoQuickStatus.cancelBeforePayment);
    }

    function filteredKaKaoStatus(type: number): string {
        let text;
        if (isRequest(type)) {
            text = '배정 전';
        } else if (isReorder(type)) {
            text = '재배정 중';
        } else if (isMatching(type)) {
            text = '배정 중';
        } else if (isSuccessMatched(type)) {
            text = '배정 완료';
        } else if (isFailedMatching(type)) {
            text = '배정 실패';
        } else {
            text = '-';
        }
        return text;
    }

    function getKaKaoMobilityStatus(deliveryOrderResultMessage: string): string {
        if (_.isEqual(deliveryOrderResultMessage, 'init')) {
            return '접수';
        } else if (_.isEqual(deliveryOrderResultMessage, 'matching')) {
            return '배정중';
        } else if (_.isEqual(deliveryOrderResultMessage, 'waiting')) {
            return '배정완료';
        } else if (_.isEqual(deliveryOrderResultMessage, 'executing')) {
            return '배송시작';
        } else if (_.isEqual(deliveryOrderResultMessage, 'completed')) {
            return '배송완료';
        } else if (_.isEqual(deliveryOrderResultMessage, 'canceled')) {
            return '배송취소';
        } else if (_.isEqual(deliveryOrderResultMessage, 'matching_failed')) {
            return '배정실패';
        } else if (_.isEqual(deliveryOrderResultMessage, 'aborted')) {
            return '강제종료';
        } else {
            return '-';
        }
    }

    function getKaKaoDeliveryOption(diagnosis: IOoDiagnosis): string {
        if (!_.isEqual(diagnosis.deliveryType, OoMedicineDeliveryType.kaKaoMobility)) {
            return '-';
        } else if (_.isEqual(diagnosis.deliveryOrderOption, 'quick')) {
            return '퀵';
        } else if (_.isEqual(diagnosis.deliveryOrderOption, 'quick_express')) {
            return '퀵 익스프레스';
        } else {
            return '퀵 이코노미';
        }
    }

    return {
        isRequest,
        isReorder,
        isMatching,
        isSuccessMatched,
        isFailedMatching,
        isCancelBeforePay,
        filteredKaKaoStatus,
        getKaKaoMobilityStatus,
        getKaKaoDeliveryOption
    }
}