import React, {useEffect} from "react";
import {PharmacyAlimTalkInfo} from "../../../../interfaces";
import {CustomInputText} from "@components/custom";
import _ from "lodash";
import {useAlimTalkHook} from "../hooks/useAlimTalkHook";
import styled from "styled-components";

interface Props {
    onChanged: (data: PharmacyAlimTalkInfo) => void;
}

export const PharmacyRegisterAlimTalk = ({onChanged}: Props) => {
    const {item, onChangeValue} = useAlimTalkHook();

    useEffect(() => {
        onChanged(item);
    }, [item]);

    return (
        <Wrapper>
            <div>
                <div>
                    <CustomInputText value={item.alimTalkReceivers[0]}
                                     color={"blue"}
                                     size="large"
                                     placeholder="알람톡 수신번호를 입력해주세요"
                                     disabled={true}
                                     onChanged={onChangeValue}/>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
  }
`;
