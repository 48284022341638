import styled from "styled-components";
import React, { useEffect } from "react";
import { TransportRow } from "../TransportRow";
import { useRecoilState, useRecoilValue } from "recoil";
import _ from "lodash";
import { editSelectorFamily } from "@recoils/status";
import {
  IOoUnospayDelivery,
  IOoUnospayTransportInfo,
  OoTransportOption,
} from "@models/unospay";
import {
  checkedListAtom,
  checkedListState,
  DeliveryInfoType,
} from "@recoils/unospay/checkedListState";
import { useUnospayFunctionHook } from "@hooks/unospay";

interface Props {
  componentName: string;
  data: IOoUnospayDelivery;
}

export interface ItemType {
  id: string;
  transportList: Array<IOoUnospayTransportInfo>;
}

export const DeliveryInfo = ({ componentName, data }: Props) => {
  const editStatus = useRecoilValue(editSelectorFamily(componentName));
  const [checkedList, setCheckedList] = useRecoilState(checkedListAtom);
  const { updateTransportInfo } = useUnospayFunctionHook(data.id || "");

  function submitTransportInfo(copied: DeliveryInfoType[]) {
    const param: ItemType = {
      id: data.id || "",
      transportList: copied.map((item) => item.transport),
    };
    updateTransportInfo(param);
    setCheckedList(copied);
  }

  useEffect(() => {
    if (!editStatus.isSubmit) return;

    const copied = _.cloneDeep(checkedList);
    const index = copied.findIndex((item) => _.isEqual(item.checked, true));

    if (index > -1) {
      const isEmptyTransportName =
        _.isUndefined(copied[index].transport.selectedTransportName) ||
        _.isEmpty(copied[index].transport.selectedTransportName) ||
        _.isEqual(
          copied[index].transport.selectedTransportName,
          "택배사선택"
        ) ||
        _.isEqual(copied[index].transport.selectedTransportName, "-");

      if (
        !_.isEmpty(copied[index].transport.trackingNumber) &&
        !isEmptyTransportName
      ) {
        submitTransportInfo(copied);
      } else {
        if (
          copied[index].transport.transportOption !=
          OoTransportOption.PACKAGE_DELIVERY
        ) {
          submitTransportInfo(copied);
        } else {
          alert("택배사 선택 또는 송장 번호를 입력해주세요.");
          return;
        }
      }
    }
  }, [editStatus.isSubmit]);

  useEffect(() => {
    if (!editStatus.isCancel) return;
    const makeParam = data.transportInfo.map((item) => {
      const param: DeliveryInfoType = {
        checked: false,
        isDisabled: true,
        transport: {
          externalCode: item.externalCode,
          providerName: item.providerName,
          displayTransportNameList: item.displayTransportNameList,
          selectedTransportName: item.selectedTransportName,
          trackingNumber: item.trackingNumber,
          menuCode: item.menuCode,
          menuName: item.menuName,
          orderNum: item.orderNum,
          transportOption: item.transportOption,
        },
      };
      return param;
    });

    setCheckedList(makeParam);
  }, [editStatus.isCancel]);

  useEffect(() => {
    const makeParam = data.transportInfo.map((item) => {
      const param: DeliveryInfoType = {
        checked: false,
        isDisabled: true,
        transport: {
          externalCode: item.externalCode,
          providerName: item.providerName,
          displayTransportNameList: item.displayTransportNameList,
          selectedTransportName: item.selectedTransportName,
          trackingNumber: item.trackingNumber,
          menuCode: item.menuCode,
          menuName: item.menuName,
          orderNum: item.orderNum,
          transportOption: item.transportOption,
        },
      };
      return param;
    });

    setCheckedList(makeParam);
  }, [data]);

  return (
    <Wrapper>
      {!_.isUndefined(data.transportInfo) ? (
        <Table>
          <thead></thead>
          <tbody>
            {data.transportInfo.map((data) => (
              <TransportRow
                key={data.externalCode}
                data={data}
                isChecked={false}
                editStatus={editStatus}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <div>배송 정보가 존재하지 않습니다.</div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const DisabledOption = styled.option`
  &:disabled {
    display: none;
  }
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: 100%;
  height: auto;
  font-size: 13px;
  margin-top: 8px;
  white-space: pre-wrap;

  & tr {
    border-bottom: 1px solid #c8d6f8;
  }

  & tbody tr td:nth-child(7n-6) {
    min-width: 30px;
    background: #ffffff;
    border-right: 1px solid #c8d6f8;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    padding-left: 0;
  }

  & tbody tr td:nth-child(2n) {
    background: #f4f6fb;
    text-align: center;
    min-width: 70px;
    width: 100px;
    height: 48px;
    font-weight: 700;
  }

  & tbody tr td:nth-child(2n + 1) {
    text-align: left;

    & > input[type="text"] {
      margin-left: 10px;
    }

    & > select {
      margin-left: 10px;
    }
  }
`;
