import {AbstractOoParam} from "@api/params";

class OoParamName extends AbstractOoParam {
    constructor(public readonly name: string) {
        super();
    }

    getPathParams(): string {
        return `${this.name}`;
    }
}

export {
    OoParamName,
};


