import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useFindIdUnospayQuery } from "@queries/unospay";
import {
  IOoUnospayDelivery,
  IOoUnospaySalesMenu,
  unospayPaymentTypeFilter,
} from "@models/unospay";
import { formatMMDDEE, formatPrice, formatYyyyMMDD } from "@hooks/format";
import { SelectedMenuRow } from "../SelectedMenuRow";
import { SelectedMenuContainer } from "../SelectedMenuDate";
import { IOoUnospayGfOrder } from "@models/unospay/gfOrder";

interface Props {
  data: IOoUnospayDelivery;
  gfOrderList: Array<IOoUnospayGfOrder>;
}

export const OrderDetailInfo = ({ data, gfOrderList }: Props) => {
  const getProviderName = (code: string) => {
    if (_.isUndefined(data)) return "-";
    const target = data.transportInfo.find((item) =>
      _.isEqual(code, item.externalCode)
    );
    if (_.isUndefined(target)) return code;
    return target.providerName;
  };

  const getOptionsPrice = (item: IOoUnospaySalesMenu): number => {
    const result = item.salesOption?.reduce((acc, cur) => {
      acc = _.toNumber(acc);
      acc += _.toNumber(cur.optionPrice);
      return acc;
    }, 0);
    return result || 0;
  };

  const checkProductOrder = (
    productNum: number,
    index: number,
    gfOrderList: Array<IOoUnospayGfOrder>
  ): string => {
    console.log("productNum", productNum);
    console.log("index", index);
    console.log("list", gfOrderList);
    const found = gfOrderList?.filter((e) => {
      const temp = `${_.split(e.partOrderNo, "-")[1]}-${
        _.split(e.partOrderNo, "-")[2]
      }`;
      console.log("temp", temp);
      return _.isEqual(temp, `${productNum}-${index}`);
    });
    console.log(_.first(found)?.status);
    return _.first(found)?.status as string;
  };

  const convStatusStr = (val: string) => {
    if (_.isEqual(val, "STANDBY")) {
      return "대기";
    } else if (_.isEqual(val, "SEND")) {
      return "전송";
    } else if (_.isEqual(val, "ERROR")) {
      return "에러";
    } else if (_.isEqual(val, "CANCEL")) {
      return "취소";
    } else {
      return "";
    }
  };

  return (
    <Container>
      {!_.isUndefined(data) && !_.isUndefined(data.salesMenu) ? (
        <Contents>
          {data.salesMenu.map((item, productNum) => (
            <ProductContainer key={productNum}>
              <SubTitle>상품 {productNum + 1}</SubTitle>
              <Table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>상품명</td>
                    <td>{item.menuName || ""}</td>
                    <td>카테고리</td>
                    <td>{item.categoryName || ""}</td>
                  </tr>
                  <tr>
                    <td>제조회사</td>
                    <td>{getProviderName(item.externalCode || "")}</td>
                    <td>수량</td>
                    <td>{item.menuCount || ""}</td>
                  </tr>
                  {!_.isUndefined(item.salesOption) &&
                    item.salesOption.map((item, index) => (
                      <tr key={data?.mobile || index}>
                        <td>옵션{index + 1} </td>
                        <td>{item.groupName || ""}</td>
                        <td>선택</td>
                        <td>{item.optionName || ""}</td>
                      </tr>
                    ))}
                  {!_.isUndefined(item.selectMenu) &&
                    item.selectMenu.map((menu, index) => (
                      <tr key={data?.mobile || index}>
                        <td>선택 메뉴 {index + 1}</td>
                        <td colSpan={3}>
                          <MenuRow>
                            <SelectedMenuContainer
                              deliveryDate={formatYyyyMMDD(menu.date ?? "-")}
                              statusStr={
                                convStatusStr(
                                  checkProductOrder(
                                    productNum,
                                    index,
                                    gfOrderList
                                  )
                                ) || ""
                              }
                            ></SelectedMenuContainer>
                            <SelectedMenuRowStyle>
                              {menu.menuList?.map((item, index) => (
                                <SelectedMenuRow
                                  menuCode={item.code}
                                  menuTitle={item.name}
                                ></SelectedMenuRow>
                              ))}
                            </SelectedMenuRowStyle>
                          </MenuRow>
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td>금액</td>
                    <td>
                      {formatPrice(
                        Number(item.menuPrice || "0") *
                          Number(item.menuCount || "1") +
                          getOptionsPrice(item) * Number(item.menuCount || "0")
                      )}
                      원
                    </td>
                  </tr>
                </tbody>
              </Table>
            </ProductContainer>
          ))}
          <BottomContainer>
            <SubTitle>결제 정보</SubTitle>
            <Table>
              <thead></thead>
              <tbody>
                <tr>
                  <td>총 결제금액</td>
                  <td>{formatPrice(Number(data?.paymentPrice || "0"))}원</td>
                  <td>결제수단</td>
                  <td>
                    {unospayPaymentTypeFilter(Number(data?.paymentType || "1"))}
                  </td>
                </tr>
              </tbody>
            </Table>
          </BottomContainer>
        </Contents>
      ) : (
        <DisabledTitle>주문 상세 내역이 존재하지 않습니다.</DisabledTitle>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
`;

const ProductContainer = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 7px;
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
`;

const Contents = styled.div`
  height: auto;
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: 100%;
  font-size: 13px;
  white-space: pre-wrap;
  height: auto;

  & tbody tr {
    border-bottom: 1px solid #c8d6f8;
  }

  & tbody tr td:nth-child(2n-1) {
    background: #f4f6fb;
    text-align: center;
    padding: 20px 12px;
    min-width: 100px;
    font-weight: 700;
  }

  & tbody tr td:nth-child(2n) {
    padding-top: 15.5px;
    padding-bottom: 15.5px;
    padding-left: 20px;
    width: 1000px;
  }
`;

const BottomContainer = styled.div`
  padding-top: 17px;
`;

const DisabledTitle = styled.div`
  position: relative;
  top: 50px;
  bottom: 0;
  width: 45%;
  padding-left: 0px;
`;

const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: fit-content;
  width: 100%;
`;

const SelectedMenuRowStyle = styled.div`
  flex-flow: wrap;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
