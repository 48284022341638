import {IOoDoctor} from "../doctor";
import {IOoPharmacy, IOoWorkHours} from "../pharmacy";

//병원
interface IOoHospital {
    id?: string;

    // 사용자 아이디 (account  테이블)
    userId: string;

    //병원코드
    code: string;

    //의사코드 QR(자동 생성)
    codeQrUrl?: string;

    //병원명
    name: string;

    //주소
    address: string;

    //상세 주소
    addressDetail: string;

    //우편변호
    zipCode: string;

    //전화번호
    tel: string;

    //팩스번호
    fax?: string;

    //이메일
    email: string;

    //홈페이지
    web?: string;

    //소개
    bio?: string;

    //정산 은행명
    bank: string;

    //계좌번호
    bankAccount: string;

    //예금주
    bankHolderName: string;

    //진료과
    departments?: Array<string>;

    //로고
    logo?: string;

    //사진
    pictures: Array<string>;

    //소속 의사
    doctors?: Array<IOoDoctor>;

    //지정 약국
    pharmacies?: Array<IOoPharmacy>;

    //지역 검색 키워드
    regionKeywordList?: Array<string>;

    //병원 타입 (HospitalType)
    type?: number;

    //정산용 확인여부
    isCalculateAccount?: boolean;

    //코로나 검사 가능 (all: rat, pcr) CovidTestType
    covidTestType?: number;

    //소아청소년 코로나 검사 전용
    isOnlyChildrenCovid?: boolean;

    //위도 (카카오 응답 y)
    latitude?: string;

    //경도 (카카오 응답 x)
    longitude?: string;

    // 등록일
    timestamp: number;

    // 키오스크 사용여부
    useKiosk?: boolean;

    // 키오스크 제공 서비스
    kioskService?: number[];

    // 주차서비스 URL
    parkingUrl?: string;

    // 주차정보
    parkingInfo?: string;

    // 키오스크 한줄 공지 문구
    kioskNotice?: string;

    // 키오스크 제공 결제 타입
    kioskPayType?: number[];

    //진료시간
    hospitalHours?: Array<IOoWorkHours>;

    /**
     * 병원약 발송 관련 추가 필드
     */
    // 픽업 아이디
    hospitalMedicinePickupId?: string;

    //병원약 픽업지 이름
    hospitalMedicinePickupName?: string;

    // 병원약 픽업지 주소
    hospitalMedicinePickupAddress?: string;

    // 병원약 픽업지 주소 상세
    hospitalMedicinePickupAddressDetail?: string;

    // 병원약 픽업지 주소 우편번호
    hospitalMedicinePickupZipCode?: string;

    // 병원약 픽업지 전화번호
    hospitalMedicinePickupTel?: string;

    // 병원약 픽업지 위도
    hospitalMedicinePickupLatitude?: string;

    // 병원약 픽업지 경도
    hospitalMedicinePickupLongitude?: string;

    //당일배송
    //당일 배송 지역 검색 키워드
    todayRegionKeywordList?: Array<string>;

    //당일배송 배송 가능 지역 히트 카운드 (한개면 도, 2개면 시까지...)
    todayRegionKeywordHitCount?: number;

    //당일배송 가능시간
    deliverTodayHours?: Array<string>;

    //당일배송 비용
    deliverTodayPrice?: number;

    //당일배송 가능 반경
    deliverTodayRadius?: number;

    //택배
    //택배배송 가능시간
    deliverPackageHours?: Array<string>;

    //택배 배송 비용
    deliverPackagePrice?: number;

    //약배송 타입 목록
    deliveryTypeList?: Array<number>;

    //직접픽업 가능시간
    deliverPickupHours?: Array<string>;

    //mtm 병원 여부
    isMtmHospital?: boolean;

    //추천인/추천병원
    referrer?: string;

    //추천인/추천병원
    referrerList?: string[];

    //알림톡 사용여부
    useOrderAlimtalk?: boolean;
}

interface IOoCompactHospital {
    id?: string;
    code: string;
    name: string;
    address: string;
    tel: string;
    timestamp: number;
    doctors?: Array<IOoDoctor>;
}

export type {
    IOoHospital,
    IOoCompactHospital
};

export enum HospitalType {
    normal = 0, //일반
    orientalMedicine,
    b2b,
    fitness,

}

export enum CovidTestType {
    unknown = 0,
    rat = 1,     //RAT
    all = 2,  //PCR
}

export enum KioskService {
    receipt = 1,
    payment = 2,
    parkingService = 3
}
