import {
  filterCancelOrder,
  filterEmptyAddress,
  filterExistAddress,
  filterSendPackage,
  filterTotalList,
  IOoUnospayDelivery,
} from "@models/unospay";

interface Props {
  list: Array<IOoUnospayDelivery>;
}

export const useUnospayStatusRtdbHook = ({ list }: Props) => {
  const total = () => {
    return filterTotalList(list);
  };

  const emptyAddress = () => {
    return filterEmptyAddress(list);
  };

  const existAddress = () => {
    return filterExistAddress(list);
  };

  const cancelOrder = () => {
    return filterCancelOrder(list);
  };

  const sendPackage = () => {
    return filterSendPackage(list);
  };

  return {
    total,
    emptyAddress,
    existAddress,
    cancelOrder,
    sendPackage,
  };
};
