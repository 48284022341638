import {modalState} from "@recoils/modal";
import _ from "lodash";
import {ErrorModal, NoButtonModal, OneButtonModal, TwoButtonModal} from "@components/customModal";
import {useRecoilState} from "recoil";
import {MultiButtonModal} from "@components/customModal/multiButtonModal";

const ModalProvider = () => {
    const [modalList, setModalList] = useRecoilState(modalState);

    const filterPopup = (props: any, index: number) => {
        if (_.isEqual(props.type, 'oneBtnModal')) {
            return <OneButtonModal key={index} {...props}/>
        }
        if (_.isEqual(props.type, 'twoBtnModal')) {
            return <TwoButtonModal key={index} {...props}/>
        }
        if (_.isEqual(props.type, 'errorModal')) {
            return <ErrorModal key={index} {...props}/>
        }
        if (_.isEqual(props.type, 'multiBtnModal')) {
            return <MultiButtonModal key={index} {...props}/>
        }
        if (_.isEqual(props.type, 'noBtnModal')) {
            return <NoButtonModal key={index} {...props}/>
        }
        return;
    };

    return <>{modalList.map((props, index) => filterPopup(props, index))}</>;
}

export default ModalProvider;