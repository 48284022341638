import timeTable from '../../assets/timeTable.json';
import React, {useEffect, useState} from "react";
import classNames from "classnames/bind";
import timeTableStyle from "../../styles/common/TimeTable.module.scss";
import _ from "lodash";

interface Props {
    h: string,
    m: string,
    onChanged: (s: string, id: string) => void,
    disabled?: boolean,
    id: string,
}

export const OpenDropDown = ({h, m, onChanged, disabled, id}: Props) => {
    const tx = classNames.bind(timeTableStyle);
    const [show, setShow] = useState<boolean>(_.isUndefined(disabled) ? false : disabled);

    useEffect(() => {
        !_.isUndefined(disabled) && setShow(disabled);
    }, [disabled]);


    const onChangedHour = (e: any) => {
        //시간 만들기
        h = e.target.value;
        onChanged(`${h}:${m}`, e.target.id);
    };

    const onChangedMin = (e: any) => {
        //분 만들기
        m = e.target.value;
        onChanged(`${h}:${m}`, e.target.id);
    };

    return (
        <>
            <select onChange={onChangedHour}
                    value={h}
                    disabled={show}
                    id={id}
                    className={tx('select', show ? 'grey' : 'blue')}>
                {
                    timeTable.hour.map((item, index) => (
                        <option key={index} value={item.val}>{item.val}</option>
                    ))}
            </select>
            <div className={tx('time-divide')}> :</div>
            <select onChange={onChangedMin}
                    value={m}
                    disabled={show}
                    id={id}
                    className={tx('select', show ? 'grey' : 'blue')}>
                {
                    timeTable.minute.map((item, index) => (
                        <option key={index} value={item.val}>{item.val}</option>
                    ))}
            </select>
        </>
    );
};
