import styled from "styled-components";
import {ModalOverlay} from "../modalOverlay";
import React, {useEffect, useState} from "react";

interface Props {
    modalVisible: boolean,
    contents: string,
    onChangedVisible: (status: boolean) => void,
    onChangedCancel: (status: boolean) => void,
    cancelButtonName: string,
    submitButtonName: string,
}

export const UnospayModal = ({
                                 modalVisible,
                                 contents,
                                 onChangedVisible,
                                 onChangedCancel,
                                 cancelButtonName,
                                 submitButtonName
                             }: Props) => {
    const [handleVisible, setHandleVisible] = useState<boolean>(modalVisible);
    const [submit, setSubmit] = useState<boolean>(false);

    const handleSubmit = () => setSubmit(!submit);

    useEffect(() => {
        setHandleVisible(modalVisible);
    }, [modalVisible]);

    useEffect(() => {
        onChangedVisible(handleVisible);
    }, [handleVisible]);

    useEffect(() => {
        onChangedCancel(submit);
    }, [submit]);
    return (
        <ModalOverlay visible={handleVisible}>
            <Wrapper>
                <Contents>{contents}</Contents>
                <ButtonContainer>
                    <CancelButton type="button" onClick={() => setHandleVisible(!handleVisible)}>
                        {cancelButtonName}
                    </CancelButton>
                    <ContinueButton type="button" onClick={handleSubmit}>{submitButtonName}</ContinueButton>
                </ButtonContainer>
            </Wrapper>
        </ModalOverlay>
    )
}

const Wrapper = styled.div`
  width: 556px;
  height: 240px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const Contents = styled.div`
  width: 100%;
  height: 32px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ContinueButton = styled.button`
  width: 222px;
  height: 60px;
  background: #6E8ED1;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  border: none;
`;

const CancelButton = styled.button`
  width: 222px;
  height: 60px;
  background: #CF5D5D;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  border: none;
  margin-right: 10px;
`;
