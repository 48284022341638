import {AbstractOoParam} from "../base";
import {OoParamAddMedicinePrice} from "@api/params";

class OoParamFamilyAddMedicinePrice extends AbstractOoParam {
    public readonly familyUserId: string;
    public readonly priceList: Array<OoParamAddMedicinePrice>;

    constructor(familyUserId: string, priceList: Array<OoParamAddMedicinePrice>) {
        super();
        this.familyUserId = familyUserId;
        this.priceList = priceList;
    }
}

export {
    OoParamFamilyAddMedicinePrice
}