import {atom} from "recoil";

export interface SearchParamProps {
    searchName: string,
    doctorId: string,
}

export const doctorSearchParamState = atom<SearchParamProps>({
    key: 'doctor/doctorSearchParam',
    default: {
        searchName: "",
        doctorId: "",
    }
});