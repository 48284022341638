import {useState} from "react";
import {PharmacyAdditionalInfo} from "../../../../interfaces";

export const useAdditionalInfo = () => {
    const [items, setItems] = useState<PharmacyAdditionalInfo>({
        zipCode: '',
        address: '',
        addressDetail: '',
        tel: '',
        fax: '',
    });

    const onChangeValue = (e: any) => {
        const {name, value} = e.target;
        setItems({...items, [name]: value});
    };

    return {
        items,
        onChangeValue,
    }
}