import { IOoUnospayDelivery, IOoUnospaySalesMenu, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import { ExcelExportModel } from '../model';
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
const ExcelJS = require("exceljs");

const createModel = (e:IOoUnospayDelivery, salesItem:IOoUnospaySalesMenu) => {
    const model = new ExcelExportModel();
                  model.name = e.recipientName;
                  model.address1 = e.address;
                  model.address2 = e.addressDetail;
                  model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                  model.zipCode = e.zipCode;
                  model.memo = e.memo;
                  model.subMemo = e.subMemo;
                  model.quantity = "1";
                  model.deliveryCost = e.id;
    return model;
  }
  const splitName = (name:string) => {
    const splitName = name.split('/');
    if(splitName.length === 1){
        return splitName[0].split(' ');
    }else{
        return splitName;
    }
  }

  const createProductName = (salesItem:IOoUnospaySalesMenu) => {
    if(_.isUndefined(salesItem.salesOption)){
        const splitNameList = splitName(salesItem.menuNameCh!);
        return splitNameList[0]
    }else{
        const splitNameList = splitName(salesItem.menuNameCh!);
        const optionName = salesItem.salesOption.map(item => item.optionName);
         return `${splitNameList[0]} ${optionName} ${splitNameList[1]}`
    }
  }

const bicnuOrder = async (list:IOoUnospayDelivery[]) => {
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.bicnuProvider));
    let data_array: Array<ExcelExportModel> = [];
    
    excelList.forEach(e => {

      if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
          console.log(excelList);
            e.salesMenu?.forEach(salesItem => {
                
                if(_.startsWith(salesItem.externalCode, 'OF50')){
                    let model = createModel(e, salesItem);
                    
                    if(salesItem.salesOption!.length > 0){
                        model.product = createProductName(salesItem);
                        model.exCode = `${salesItem.externalCode}_${salesItem.salesOption![0].externalCode}`
                        data_array.push(model);
                    }else{
                        model.product = createProductName(salesItem);
                        model.exCode = `${salesItem.externalCode}`
                        data_array.push(model);
                    }
                }
              })
        }
    });

    const idGroupData = _(data_array).groupBy(data => data.deliveryCost).map((value) => (value)).value();
    const exCodeGroupData = idGroupData.map(item => _(item).groupBy(data => data.exCode).map((value => value)).value());

    const groupingExcelData = exCodeGroupData.map(item => {
        return item.map((list) => {
            const productCnt = list.length;
            return {...list[0], product:`${list[0].product} ${productCnt}통`}
        });
    }).flatMap(item => item);
    
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferBicNu = await (await fetch("/assets/bicnu.xlsx")).arrayBuffer();
    const workbookBicNu = new ExcelJS.Workbook();
    await workbookBicNu.xlsx.load(arrayBufferBicNu);
    const ws_BicNu = workbookBicNu.getWorksheet("Sheet1");

    groupingExcelData.map((data: ExcelExportModel, index: number) => {
        const row = ws_BicNu.getRow(index + 2); // 엑셀은 1부터 시작
        [
            data.name,
            data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
            `${data.address1} ${data.address2}`,
            data.product,
            "1",
            data.memo,
          ].forEach((value , index) => {
            const cell = row.getCell(index + 1);
            cell.value = value;
          });
    });
    const excelBuffer = await workbookBicNu.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName + "_" + moment().format("YYYYMMDD") + "(빅뉴트리션)" + excelFileExtension
    );
}

export default bicnuOrder