import { OoOrderPlatformType } from '@api/models';
import { IOoCalculateMonthList } from '@api/models/calculate/calculateMonthList'
import moment from 'moment';
import { ExcelExportModel } from 'pages/Operator/UnospayDashBoardPage/components/ExcelDownloadButton/model';
import React from 'react'
const ExcelJS = require("exceljs");
import FileSaver from 'file-saver';

const CalculateExcelDown = async (calculateList: IOoCalculateMonthList[]) => {
    let data_array: Array<ExcelExportModel> = [];
    
    const getOrderPlatformIndexToString = (type:number) => {
        switch(type){
          case OoOrderPlatformType.kiosk:
            return '키오스크'
          case OoOrderPlatformType.mtmMall:
            return 'MTM몰'
          case OoOrderPlatformType.telephone:
            return '전화주문'
          case OoOrderPlatformType.test:
            return 'TEST'
          default:
            return '-'
        }
      }

    calculateList.forEach(e => {
        const model = new ExcelExportModel();
        model.orderDate = moment(e.dateTime).format('YYYY-MM-DD');
        model.name = e.payerName;
        model.orderPlatform = getOrderPlatformIndexToString(e.orderPlatform);
        model.providerName = e.providerName;
        model.product = e.goods;
        model.price = e.paymentPrice.toLocaleString();
        model.hospitalCalPrice = e.hospitalCalPrice!.toLocaleString();

        data_array.push(model);
    })

    console.log(data_array);
    
    const excelFileExtension = ".xlsx";
    const excelFileName = `정산내역서`;

    const arrayBufferCalculate = await (await fetch("/assets/calculate.xlsx")).arrayBuffer();
    const workbookCalculate = new ExcelJS.Workbook();
    await workbookCalculate.xlsx.load(arrayBufferCalculate);
    const ws_mmx = workbookCalculate.getWorksheet("Sheet1");
    data_array.map((data: ExcelExportModel, index: number) => {
        const row = ws_mmx.getRow(index + 2);
      [
        data.orderDate,
        data.name,
        data.orderPlatform,
        data.providerName,
        data.product,
        data.price,
        data.hospitalCalPrice,
    ].forEach((value, index) => {
        const cell = row.getCell(index + 1);
        cell.value = value;
      })
    });
    const excelBuffer = await workbookCalculate.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName + "_" + moment().format("YYYYMMDD") + excelFileExtension
    );
}

export default CalculateExcelDown