import {OoParamPrescriptionCreate} from "@params/telemedicine";
import {useSuspenseHook} from "@hooks/suspense";
import {useMutation} from "react-query";
import {gte, head, isNull} from "lodash";
import {DoctorOnAPI} from "@api/request";
import {OoParamFamilyPrescriptionCreate} from "@api/params";
import {isRequestSucceed} from "@api/reponses";

export const useDoctorTelemedicineUpdateQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();

    return useMutation(
        'doctor/telemedicineUpdate',
        async (params: Array<OoParamPrescriptionCreate>) => {
            try {
                setLoading();
                let response = null;
                let familyResponse = null;
                if (gte(params.length, 2)) {
                    familyResponse = await DoctorOnAPI.shared.familyTelemedicine.prescription(new OoParamFamilyPrescriptionCreate(params));
                } else {
                    response = await DoctorOnAPI.shared.telemedicine.prescription(head(params)!);
                }
                setStopLoading();
                if (!isNull(response)) {
                    if (isRequestSucceed(response)) {
                        return response;
                    }
                }

                if (!isNull(familyResponse)) {
                    if (isRequestSucceed(familyResponse)) {
                        return response;
                    }
                }
                return null;
            } catch (e: any) {
                throw e;
            }
        }
    )
}