import {useMutation} from "react-query";
import {DoctorOnAPI, isRequestSucceed, OoParamId} from "../../api";
import {useSuspenseHook} from "../../hooks";

export const usePharmacyDeleteQuery = () => {
    const {setLoading, setStopLoading, setErrorStatus} = useSuspenseHook();

    return useMutation(
        'operator/pharmacyDelete',
        async (id: string) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.pharmacy.delete(new OoParamId(id));
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.status;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
    )
};