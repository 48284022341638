import {useMutation} from "react-query";
import {useSuspenseHook} from "@hooks/suspense";
import {OoParamPrescriptionModify} from "@params/telemedicine/prescriptionModify";
import {DoctorOnAPI} from "@request/doctorOnAPI";
import {isRequestSucceed} from "../../api";

export const useDoctorModifyPrescriptionQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation('doctor/modifyPrescription',
        async (param: OoParamPrescriptionModify) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.telemedicine.prescriptionModify(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        })
}