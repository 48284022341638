import { IOoProducts } from "@api/models/productSetting/product";
import { DoctorOnAPI } from "@api/request";
import { IoSearch } from "@react-icons/all-files/io5/IoSearch";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useLocation } from "react-router";
import styled, { CSSProperties } from "styled-components";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { productListState } from "@recoils/unospay/tlcProductState";
import { useRecoilState } from "recoil";

interface BodyStyle {
  display?: string;
  alignItems?: string;
  justifyContent?: string;
}

const ProductPriceList = () => {
  const { pathname } = useLocation();
  const [productType, setProductType] = useState("mtm");
  const [productList, setProductList] = useRecoilState(productListState);

  const [selectedType, setSelectedType] = useState("all");
  const [searchProductList, setSearchProductList] = useState<IOoProducts[]>([]);
  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#19ce60");

  const override: CSSProperties = {
    display: "block",
    marginTop: window.screenY / 2,
  };

  const filteredProductTypes = (pathname: string) => {
    const splitPathName = pathname.split("/");
    setProductType(splitPathName[2]);
  };

  const handleChangeSearchItem = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleClickSearchItem(); // 작성한 댓글 post 요청하는 함수
    }
  };



  const handleClickSearchItem = () => {
    if (searchName.length !== 0) {
      if (selectedType === "productFullName") {
        // const includesList = productList.filter((item) => _.includes(item.productFullName , searchName));
        const includesList = productList.filter((item) => _.includes(item.productFullName ? item.productFullName : item.productName, searchName));
        setSearchProductList(includesList);
      }
      if (selectedType === "providerName") {
        const includesList = productList.filter((item) => _.includes(item.providerName, searchName));
        setSearchProductList(includesList);
      }
      if (selectedType === "all"){
        const includesList = productList.filter((item) => item.productFullName.includes(searchName) || item.providerName.includes(searchName));
        const setList = Array.from(new Set(includesList));
        setSearchProductList(setList);
      }
    }
  };

  const handleClickReset = () => {
    setSearchName("");
    setSearchProductList(productList);
  };

  const handleChangeSelectOption = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setSelectedType(value);
  };

  const tlcProductFilter = (list: IOoProducts[]) => {
    return list.filter((item) => item.providerName !== "TLChealthcare");
  };

  const getProductSettingList = async () => {
    try {
      if(productList.length === 0) {
        setLoading(true);
        const response = await DoctorOnAPI.shared.productSetting.list();
        if (response.status === "ok") {
          const filteredList = tlcProductFilter(response.data!);
          const isShowFilteredList = filteredList.filter(item => item.isShow);
          
          setProductList(_.orderBy(isShowFilteredList, "productFullName"));
          setSearchProductList(_.orderBy(isShowFilteredList, "productFullName"));
        }
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (searchName.length === 0) {
      setSearchProductList([]);
    }
  }, [searchName]);

  useEffect(() => {
    filteredProductTypes(pathname);
    if (!_.isNull(document.getElementById("contents"))) {
      document.getElementById("contents")!.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    getProductSettingList();
  }, []);

  return (
    <Wrapper>
      <HeaderLayout>
        <Description>
          <span>제품 가격표</span>
          <img alt="" src={"/img/icon_arrow_right_slim.svg"} />
          {productType === "mtm" && <span>식단({productType.toUpperCase()})</span>}
          {productType === "mts" && <span>건기식({productType.toUpperCase()})</span>}
          {productType === "mte" && <span>운동용품({productType.toUpperCase()})</span>}
        </Description>
        <TitleLayout>
          {productType === "mtm" && <span>식단({productType.toUpperCase()})</span>}
          {productType === "mts" && <span>건기식({productType.toUpperCase()})</span>}
          {productType === "mte" && <span>운동용품({productType.toUpperCase()})</span>}
          <Layout>
            <select onChange={handleChangeSelectOption}>
              <option value="all">전체</option>
              <option value="productFullName">제품명</option>
              <option value="providerName">업체명</option>
            </select>
            <InputLayout>
              <input
                type="text"
                placeholder="제품명을 입력해주세요"
                onChange={handleChangeSearchItem}
                onKeyDown={handleKeyDown}
                value={searchName}
              />
              <SearchIcon onClick={handleClickSearchItem}>
                <IoSearch size={16} />
              </SearchIcon>
            </InputLayout>
            <ResetIcon onClick={handleClickReset}>
              <img src="/img/icon_refresh.svg" />
            </ResetIcon>
          </Layout>
        </TitleLayout>
        <LabelsLayout>
          {/* {_.groupBy(productList
            .filter(
              (product) =>
                product.productType === (productType === "mtm" ? 0 : productType === "mts" ? 2 : 1)
            ),'providerName')
            .map((item, index) => (
              <ItemLayout>
                <div>{index + 1}</div>
                <div>{item.productFullName}</div>
                <div>{item.providerName}</div>
                <div>{item.retailPrice.toLocaleString()}원</div>
                <div>{item.settleRate}%</div>
              </ItemLayout>
            ))} */}
        </LabelsLayout>
      </HeaderLayout>
      {loading ? (
        <Body display="flex" justifyContent="center" alignItems="center">
          <ClipLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Body>
      ) : (
        <Body>
          <Nav>
            <div>순번</div>
            <div>제품명</div>
            <div>업체명</div>
            <div>판매가</div>
            <div>마진율</div>
          </Nav>
          <Contents id="contents" className="scrollable">
            {searchName.length === 0
              ? productList
                  .filter(
                    (product) =>
                      product.productType ===
                      (productType === "mtm" ? 0 : productType === "mts" ? 2 : 1)
                  )
                  .map((item, index) => (
                    <ItemLayout>
                      <div>{index + 1}</div>
                      <LinkStyle to={`/productDetail/${item.externalCode}`}>
                        {!_.isUndefined(item.productFullName) ? item.productName : item.productFullName}
                      </LinkStyle>
                      {/* <div>{!_.isUndefined(item.productFullName) ? item.productName : item.productFullName}</div> */}
                      <div>{item.providerName}</div>
                      <div>{item.retailPrice.toLocaleString()}원</div>
                      <div>{item.settleRate.toFixed(2)}%</div>
                    </ItemLayout>
                  ))
              : searchName.length !== 0 && searchProductList.length !== 0
              ? searchProductList
                  .filter(
                    (product) =>
                      product.productType ===
                      (productType === "mtm" ? 0 : productType === "mts" ? 2 : 1)
                  )
                  .map((item, index) => (
                    <ItemLayout>
                      <div>{index + 1}</div>
                      <div>{!_.isUndefined(item.productFullName) ? item.productName : item.productFullName}</div>
                      <div>{item.providerName}</div>
                      <div>{item.retailPrice.toLocaleString()}원</div>
                      <div>{item.settleRate.toFixed(2)}%</div>
                    </ItemLayout>
                  ))
              : searchProductList.length === 0 && (
                  <NonItemLayout>
                    <div>검색한 제품이 없습니다.</div>
                  </NonItemLayout>
                )}
          </Contents>
        </Body>
      )}
    </Wrapper>
  );
};

export default ProductPriceList;

const Wrapper = styled.div`
  background: #ffffff;
  width: 100%;
  height: 80%;
  border-radius: 30px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
    
  @media (min-height: 800px) {
    height: 85%;
  }
    
  @media (min-height: 850px) {
    height: 87%;
  }
  
  @media (min-height: 900px) {
    height: 90%;
  }
  
  @media (min-height: 950px) {
    height: 93%;
  }

  @media (min-height: 1000px) {
    height: 95%;
  }
`;

const HeaderLayout = styled.div``;
const TitleLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    font-size: 40px;
    font-weight: bold;
  }
`;

const Layout = styled.div`
  display: flex;
  column-gap: 2px;

  select {
    height: 40px;
  }
`;

const LabelsLayout = styled.div``;

const InputLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 250px;
  height: 40px;

  input {
    width: 250px;
    height: 40px;
    padding: 0px 15px;
  }
`;

const SearchIcon = styled.button`
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: transparent;
  border: none;
`;

const ResetIcon = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
`;

const Description = styled.p`
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 3px;
`;

const Body = styled.div<BodyStyle>`
  width: 100%;
  height: 90%;
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`;
const Nav = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr 3fr 3fr 3fr;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 600;
  background: #edf2f6;
`;
const Contents = styled.div`
  height: 75%;
  overflow: auto;

    @media (min-height: 750px) {
    height: 80%;
  }

    @media (min-height: 800px) {
    height: 85%;
  }
    
  @media (min-height: 850px) {
    height: 87%;
  }
  
  @media (min-height: 900px) {
    height: 90%;
  }
  
  @media (min-height: 950px) {
    height: 93%;
  }

  @media (min-height: 1000px) {
    height: 95%;
  }
`;
const ItemLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr 3fr 3fr 3fr;
  padding: 15px 20px;
  border: 1px solid #edf2f6;
  font-size: 14px;

  &last-child {
    border-bottom: none;
  }
`;
const NonItemLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LinkStyle = styled(Link)`
  color: black;
  text-decoration: none;
  &:hover,
  &:focus {
    font-weight: bold;
  }
`;