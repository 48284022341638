import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { SelectedMenuContainer } from "pages/Operator/UnospayOrderDetailPage/components/SelectedMenuDate";
import { SelectedMenuRow } from "pages/Operator/UnospayOrderDetailPage/components/SelectedMenuRow";
import { formatPrice, formatYyyyMMDD } from "@hooks/format";
import { useFindDateHdOrderQuery } from "@queries/unospay/findHdOrderByDate";
import { IOoUnospayGfOrder } from "@api/models/unospay/gfOrder";
import moment from "moment";
import { ButtonContainer } from "@components/customModal/style";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { PaymentCalendar } from "pages/Operator/UnospayDashBoardPage/components/PaymentCalendar";

export const UnospayHdOrderPopUp = () => {
  const location = useLocation();

  // const memolizedDate = useMemo(() => {
  //   const arr = location.pathname.split("/");
  //   console.log('arr', arr)
  //   return _.last(arr);
  // }, [location.pathname]);

  // const result = useFindDateHdOrderQuery({ date: memolizedDate || '' });

  const [data, setData] = useState<IOoUnospayGfOrder[] | undefined>();

  const [searchDate, setSearchDate] = useState<string>(moment().format("YYYY-MM-DD"));

  const result = useFindDateHdOrderQuery({ date: searchDate });

  const [selectedStatus, setSelectedStatus] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await result.refetch();
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (result.isSuccess && result.data) {
      fetchData();
    }
  }, [result.isSuccess, result.data, searchDate]);

  useEffect(() => {
    if (data) {
      // 해당 날짜에 가져온 origin 데이터 기준으로 필터
      const filtered = result.data!.filter((order) => {
        console.log("status", order.status);
        return !selectedStatus || order.status === selectedStatus;
      });
      setData(filtered);
    }
  }, [selectedStatus]);

  const prevButtonClick = () => {
    const temp = moment(searchDate).subtract(1, "days").format("YYYY-MM-DD");
    setSearchDate(temp);
  };

  const postButtonClick = () => {
    const temp = moment(searchDate).add(1, "days").format("YYYY-MM-DD");
    setSearchDate(temp);
  };

  const handleStatusChange = (newStatus: string) => {
    setSelectedStatus(newStatus);
  };

  const hdOrderExcelDownload = () => {
    console.log("excel download");

    let excelList = [];
    for (let order of data!) {
      for (let item of order.itemList) {
        excelList.push({
          이름: order.buyerName,
          주소: order.address,
          상세주소: order.address2,
          우편번호: order.zipCd,
          전화번호: order.mobile,
          배송예정일: item.dueDate,
          partOrderNo: order.partOrderNo,
          메뉴코드: item.itemCode,
          메뉴이름: item.itemName,
          수량: item.qty,
          공급가: _.toNumber(item.salePrice),
          식단코드: order.mealCode,
          식단이름: order.mealTitle,
          배송타입: deliveryTypeToStr(order.shippingDiv),
          연동상태: order.status,
          연동일시: order.sendDate,
        });
      }
    }

    const worksheet = XLSX.utils.json_to_sheet(excelList!);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, `${moment().format("YYYYMMDD")}_hd_orders.xlsx`);
  };

  function deliveryTypeToStr(code: string) {
    switch (code) {
      case "E":
        return "새벽배송";
      case "B":
        return "일반배송";
      default:
        return "-";
    }
  }

  const transportTypeToStr = (order: IOoUnospayGfOrder) => {
    if (_.isEqual(order.shippingDiv, "E")) {
      return `새벽배송(${order.earlyHomePw}${order.earlyEtcMsg})`;
    } else if (_.isEqual(order.shippingDiv, "B")) {
      return `일반배송(${order.shippingMsg})`;
    } else {
      return "-";
    }
  };

  return (
    <Wrapper>
      <Container>
        <Title>
          <DownloadButtonContainer>
            <button onClick={() => hdOrderExcelDownload()}>주문내려받기</button>
          </DownloadButtonContainer>
          <ButtonContainer>
            <PaymentCalendar type="order" setSearchDate={setSearchDate} />
            <select value={selectedStatus} onChange={(e) => handleStatusChange(e.target.value)}>
              <option value="">전체</option>
              <option value="SEND">전송</option>
              <option value="STANDBY">대기</option>
            </select>
          </ButtonContainer>
        </Title>
        {!_.isUndefined(data) && !_.isEmpty(data) ? (
          <Contents>
            {data.map((order, index) => (
              <ProductContainer key={index}>
                <SubTitle> {index + 1}</SubTitle>
                <Table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>주문자</td>
                      <td>{order.buyerName}</td>
                      <td>전화번호</td>
                      <td>{order.mobile || ""}</td>
                    </tr>
                    <tr key={order.partOrderNo || index}>
                      <td>선택 메뉴</td>
                      <td colSpan={3}>
                        <MenuRow>
                          <SelectedMenuContainer
                            deliveryDate={formatYyyyMMDD(order.itemList[0].dueDate ?? "-")}
                            statusStr={""}
                          ></SelectedMenuContainer>
                          <SelectedMenuRowStyle>
                            {order.itemList?.map((item, index) => (
                              <SelectedMenuRow
                                menuCode={item.itemCode}
                                menuTitle={`${item.itemName}(${item.mealTitle})`}
                              ></SelectedMenuRow>
                            ))}
                          </SelectedMenuRowStyle>
                        </MenuRow>
                      </td>
                    </tr>
                    <tr>
                      <td>주소</td>
                      <td>
                        {order.address || ""}
                        {" | "}
                        {order.address2 || ""}
                      </td>
                      <td>상태</td>
                      <StatusColor status={order.status || ""}>{order.status || ""}</StatusColor>
                    </tr>
                    <tr>
                      <td>배송정보</td>
                      <td>
                        {"베송타입 : "}
                        {transportTypeToStr(order)}
                      </td>
                      <td>주문일</td>
                      <td>{order.orderDateTime || ""}</td>
                    </tr>
                    <tr>
                      <td>전송정보</td>
                      <td>
                        {"전송일시 : "}
                        {order.sendDate || ""} {" | partOrderNo : "}
                        {order.partOrderNo}
                      </td>
                      <td>주문아이디</td>
                      <td>{order.deliveryId || ""}<br/>({order.id})</td>
                    </tr>
                    {/* {!_.isUndefined(item.salesOption) &&
                      item.salesOption.map((item, index) => (
                        <tr key={data?.mobile || index}>
                          <td>옵션{index + 1} </td>
                          <td>{item.groupName || ""}</td>
                          <td>선택</td>
                          <td>{item.optionName || ""}</td>
                        </tr>
                      ))} */}
                  </tbody>
                </Table>
              </ProductContainer>
            ))}
            {/* <BottomContainer>
              <SubTitle>결제 정보</SubTitle>
              <Table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>결제금액</td>
                    <td>{formatPrice(Number(data?.paymentPrice || "0"))}원</td>
                    <td>결제수단</td>
                    <td>
                      {unospayPaymentTypeFilter(
                        Number(data?.paymentType || "1")
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </BottomContainer> */}
          </Contents>
        ) : (
          <DisabledTitle>주문 상세 내역이 존재하지 않습니다.</DisabledTitle>
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: scroll;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 46px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const Contents = styled.div`
  height: auto;
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
`;

const ProductContainer = styled.div`
  width: 100%;
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 2px solid #c8d6f8;
  width: 100%;

  & > tbody tr {
    border-bottom: 2px solid #c8d6f8;
    height: 48px;
  }

  & > tbody tr td:nth-child(2n-1) {
    width: 100px;
    min-width: 100px;
    background: #f4f6fb;
    text-align: center;
    font-weight: 700;
    font-size: 13px;
  }

  & > tbody tr td:nth-child(2n) {
    width: 70%;
    font-weight: 400;
    font-size: 13px;
    text-align: left;
    padding-left: 20px;
  }
`;

const BottomContainer = styled.div`
  padding-top: 17px;
`;

const DisabledTitle = styled.div`
  position: relative;
  top: 100px;
  bottom: 0;
  width: 100%;
  padding-left: 46px;
`;

const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: fit-content;
  width: 100%;
`;

const SelectedMenuRowStyle = styled.div`
  flex-flow: wrap;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const StatusColor = styled.label<{ status: string }>`
  color: ${(props) =>
    props.status === "SEND"
      ? "blue"
      : props.status === "ERROR"
      ? "red"
      : props.status === "STANDBY"
      ? "ORANGE"
      : "inherit"};
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  padding-left: 20px;
  line-height: 44px;
`;

const DownloadButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
`;
