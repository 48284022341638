import {AbstractOoParam} from "../base";
import {IOoUnospayTransportInfo} from "../../models/unospay";

class OoUnospayUpdateTransportInfo implements AbstractOoParam {
    // 주문자 식별 id
    id: string = '';

    // 배송 정보
    transportInfo?: Array<IOoUnospayTransportInfo> = [];

    getPathParams(): string {
        return "";
    }
}


export {OoUnospayUpdateTransportInfo};