import classNames from "classnames/bind";
import React, {useState} from "react";
import styles from "../../../../styles/doctor/register/DoctorBasicInfo.module.scss";
import layoutStyles from "../../../../styles/common/Layout.module.scss";
import {DoctorLicenseInfo} from "../../../../interfaces";
import {CustomInputText} from "@components/custom";
import {InitialImageUpload} from "../../../initialImageUpload";
import fontStyles from "../../../../styles/common/Font.module.scss";
import tableStyles from "../../../../styles/common/Table.module.scss";

interface Props {
    onChanged: (data: DoctorLicenseInfo) => void,
}

export const DoctorRegisterLicense = ({onChanged}: Props) => {
    const fx = classNames.bind(fontStyles);
    const tx = classNames.bind(tableStyles);
    const cx = classNames.bind(styles);
    const lx = classNames.bind(layoutStyles);

    const [data, setData] = useState({
        license: '',
        licensePictures: [],
    });

    const onChangeLicense = () => {

    };

    const onChangePicture = () => {

    };

    return (
        <div className={lx('doctor-license-container')}>
            <div>
                <div>
                    <table className={tx('table')}>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td><b>면허 번호</b></td>
                            <td>
                                <CustomInputText value={data.license}
                                                 onChanged={onChangeLicense}
                                                 color="blue"
                                                 size="middle"
                                                 placeholder="[필수]"
                                                 disabled={true}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className={fx('info-font-style')}>*면허번호와 면허증 사본은 내부 확인용으로만 사용 되오니 안심하시기 바랍니다.</div>
                </div>
                <div className={cx('image-container')}>
                    <InitialImageUpload target="doctor"
                                        hospitalId={'1'}
                                        onChanged={onChangePicture}/>
                </div>
            </div>
        </div>
    )
}