import {atom} from "recoil";

interface IOoTriggerState {
    operatorQuery: boolean,
    doctorQuery: boolean,
    pharmacyQuery: boolean,
}

const triggerState = atom<IOoTriggerState>({
    key: 'triggerState',
    default: {
        operatorQuery: false,
        doctorQuery: false,
        pharmacyQuery: false,
    }
});

export {triggerState};
export type {IOoTriggerState};