import {useState} from "react";
import {OoParamPharmacyCreate} from "@api/params";
import {
  PharmacyAdditionalInfo,
  PharmacyAlimTalkInfo,
  PharmacyBankInfo,
  PharmacyHours,
  PharmacyInfo,
  PharmacyTargetHospital,
} from "../../../../interfaces";
import {useModalHook} from "@hooks/modal";
import {usePharmacyFunctionHook} from "@hooks/pharmacy";

export const usePharmacyRegisterSettingHook = () => {
  const {createPharmacy} = usePharmacyFunctionHook();

  const [param, setParam] = useState<OoParamPharmacyCreate>(
      new OoParamPharmacyCreate()
  );

  const {addModal} = useModalHook();

  const onChangedInfo = (data: PharmacyInfo) => {
    param.createInfo(data);
    setParam(param);
  };

  const onChangedAdditionalInfo = (data: PharmacyAdditionalInfo) => {
    param.createAdditionalInfo(data);
    setParam(param);
  };

  const onChangedBankInfo = (data: PharmacyBankInfo) => {
    param.createBankInfo(data);
    setParam(param);
  };

  const onChangeTargetHospital = (data: PharmacyTargetHospital) => {
    param.createTargetHospital(data);
    setParam(param);
  };

  const onChangeWorkHours = (data: PharmacyHours) => {
    param.createWorkHour(data);
    setParam(param);
  };

  const onChangedAlimTalkInfo = (data: PharmacyAlimTalkInfo) => {
    param.createAlimTalkInfo(data);
    setParam(param);
  };

  return {
    param,
    createPharmacy,
    onChangedInfo,
    onChangedAdditionalInfo,
    onChangedBankInfo,
    onChangeTargetHospital,
    onChangeWorkHours,
    onChangedAlimTalkInfo,
    addModal,
  };
};
