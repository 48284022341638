import {useRecoilValue} from "recoil";
import {useMemo, useState} from "react";
import {IOoDoctor} from "@models/doctor";
import {useCardEdit} from "@hooks/common";
import {editSelectorFamily} from "@recoils/status";
import {useDoctorFunctionHook} from "@hooks/doctor";

export const useDoctorAlimTalkSettingHook = (data: IOoDoctor, componentName: string) => {
    const {updateDoctor} = useDoctorFunctionHook();
    const {isReset} = useCardEdit(componentName);
    const editStatus = useRecoilValue(editSelectorFamily(componentName));
    const [color, setColor] = useState("grey");
    const initialData = useMemo(() => {
        return {
            alimtalk1: data.alimTalkReceivers[0] || "",
            alimtalk2: data.alimTalkReceivers[1] || "",
            alimtalk3: data.alimTalkReceivers[2] || "",
        }
    }, [data]);
    const [items, setItems] = useState(initialData);

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setItems({...items, [name]: value});
    };

    return {
        editStatus,
        color,
        setColor,
        items,
        setItems,
        handleChange,
        updateDoctor,
        isReset,
        initialData,
    };
};
