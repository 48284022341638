import {useEffect, useState} from "react";
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {pharmacyListState} from "../../recoils";
import _ from "lodash";
import {IOoPharmacy} from "@api/models/pharmacy";

interface Props {
    targetPharmacy?: string,
    onChange: (targetId: string) => void,
}

export const PharmacySearchField = ({onChange, targetPharmacy}: Props) => {
    const list = useRecoilValue(pharmacyListState);
    const [filteredList, setFilteredList] = useState<Array<IOoPharmacy>>([]);
    const [searchResultList, setSearchResultList] = useState<boolean>(true);
    const [searchHospitalName, setSearchHospitalName] = useState<string>('');

    const getList = (list: Array<IOoPharmacy>, value: string) => {
        return list.filter((item) => _.includes(item.name, value));
    };

    const onChangeInput = (e: any) => {
        const text = e.target.value;
        setSearchHospitalName(text);
        const hospitalList = getList(list, e.target.value);
        if (_.isEqual(text.length, 0) && _.gt(hospitalList.length, 0)) {
            setFilteredList(list)
            setSearchResultList(true);
        } else {
            setFilteredList(hospitalList);
            setSearchResultList(false);
        }
    };

    const onClickInput = (e: any) => {
        setFilteredList(list);
        setSearchResultList(!searchResultList);
    };

    const onTargetNameClick = (e: any) => {
        setSearchHospitalName(e.target.innerText);
        setSearchResultList(!searchResultList);
        onChange(e.target.id);
    };


    useEffect(() => {
        if (!_.isUndefined(targetPharmacy)) {
            setSearchHospitalName(targetPharmacy);
            const targetHospitalList = getList(list, targetPharmacy);
            targetHospitalList.length > 0 && setFilteredList(targetHospitalList);
        }
    }, [targetPharmacy]);

    return (
        <Wrapper>
            <input className="search-input"
                   type="text"
                   placeholder="약국명을 검색하세요."
                   value={searchHospitalName}
                   onChange={onChangeInput}
                   onClick={onClickInput}/>
            <div hidden={searchResultList} className="search-result-div">
                {filteredList.map((item, index) => (
                    <div key={index} className="search-result-row">
                        <span className="search-result-name" id={item.id} onClick={onTargetNameClick}>{item.name}</span>
                    </div>
                ))}
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  .search-input {
    width: 472px;
    height: 36px;
    border: 1px solid #2176F6;
    border-radius: 8px;
    font-size: 12px;
    padding: 5px;
    margin-bottom: 8px;
  }

  .search-result-div {
    width: 472px;
    height: auto;
    max-height: 120px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    font-size: 13px;
    overflow: auto;
    position: absolute;
    z-index: 10;
  }

  .search-result-row {
    padding: 5px;
  }

  .search-result-name {
    cursor: pointer;
    padding: 5px;

    &:hover {
      color: #0a53be;
    }
  }

`;
