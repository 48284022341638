import {AbstractOoParam} from "../base";

class OoParamAddMedicinePrice extends AbstractOoParam {
    public readonly diagnosisId: string;
    public readonly price: number;
    public readonly isAgreeDUR: boolean;
    public readonly isSubstitute?: boolean;
    public readonly memo?: string

    constructor(diagnosisId: string, price: number, isAgreeDUR: boolean, isSubstitute: boolean, memo: string) {
        super();
        this.diagnosisId = diagnosisId;
        this.price = price;
        this.isAgreeDUR = isAgreeDUR;
        this.isSubstitute = isSubstitute;
        this.memo = memo;
    }
}

export {
    OoParamAddMedicinePrice
}