import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  IOoUnospayDelivery,
  IOoUnospaySalesMenu,
  IOoUnospaySalesMenuOption,
  OoDeliveryStatus,
  OoOrderPlatformType,
  OoProvider,
} from "@api/models";
import { ExcelExportModel } from "../model";
import _ from "lodash";
import styled from "styled-components";
import { formatPrice, formatTel } from "@hooks/format";
import moment from "moment";
import React from "react";

const ExcelJS = require("exceljs");

interface Props {
  list: Array<IOoUnospayDelivery>;
  select: OoProvider;
}
const nbNameCost = [
  {
    code: "OF26_01_101",
    menuName: "찹쌀고추장",
    subName: "장류",
    cost: 8200,
  },
  {
    code: "OF26_01_102",
    menuName: "맛간장",
    subName: "장류",
    cost: 6500,
  },
  {
    code: "OF26_01_103",
    menuName: "막장",
    subName: "장류",
    cost: 6700,
  },
  {
    code: "OF26_01_104",
    menuName: "소고기볶음고추장",
    subName: "장류",
    cost: 13400,
  },
  {
    code: "OF26_02_101",
    menuName: "파김치",
    subName: "김치",
    cost: 17200,
  },
  {
    code: "OF26_02_102",
    menuName: "맛김치",
    subName: "김치",
    cost: 5300,
  },
  {
    code: "OF26_02_103",
    menuName: "깍두기",
    subName: "김치",
    cost: 5300,
  },
  {
    code: "OF26_02_104",
    menuName: "나박김치",
    subName: "김치",
    cost: 5300,
  },
  {
    code: "OF26_02_105",
    menuName: "레몬무피클",
    subName: "절임류",
    cost: 7300,
  },
  {
    code: "OF26_03_101",
    menuName: "요오드제한식 1주/2끼",
    subName: "식단",
    cost: 135000,
  },
  {
    code: "OF26_03_102",
    menuName: "요오드제한식 2주/2끼",
    subName: "식단",
    cost: 280800,
  },
  {
    code: "OF26_03_201",
    menuName: "파김치",
    subName: "김치",
    cost: 17200,
  },
  {
    code: "OF26_03_202",
    menuName: "맛김치",
    subName: "김치",
    cost: 5300,
  },
  {
    code: "OF26_03_203",
    menuName: "깍두기",
    subName: "김치",
    cost: 5300,
  },
  {
    code: "OF26_03_204",
    menuName: "나박김치",
    subName: "김치",
    cost: 5300,
  },
  {
    code: "OF26_03_205",
    menuName: "레몬무피클",
    subName: "절임류",
    cost: 7300,
  },
  {
    code: "OF26_03_206",
    menuName: "제육덮밥",
    subName: "단품",
    cost: 7800,
  },
  {
    code: "OF26_03_207",
    menuName: "고추잡채덮밥",
    subName: "단품",
    cost: 7700,
  },
  {
    code: "OF26_03_208",
    menuName: "닭갈비덮밥",
    subName: "단품",
    cost: 8400,
  },
  {
    code: "OF26_04_101",
    menuName: "요오드제한식 1주/3끼",
    subName: "식단",
    cost: 84300,
  },
  {
    code: "OF26_04_102",
    menuName: "요오드제한식 2주/3끼",
    subName: "식단",
    cost: 170100,
  },
  {
    code: "OF26_04_201",
    menuName: "파김치",
    subName: "김치",
    cost: 17200,
  },
  {
    code: "OF26_04_202",
    menuName: "맛김치",
    subName: "김치",
    cost: 5300,
  },
  {
    code: "OF26_04_203",
    menuName: "깍두기",
    subName: "김치",
    cost: 5300,
  },
  {
    code: "OF26_04_204",
    menuName: "나박김치",
    subName: "김치",
    cost: 5300,
  },
  {
    code: "OF26_04_205",
    menuName: "레몬무피클",
    subName: "절임류",
    cost: 7300,
  },
  {
    code: "OF26_04_206",
    menuName: "제육덮밥",
    subName: "단품",
    cost: 7800,
  },
  {
    code: "OF26_04_207",
    menuName: "고추잡채덮밥",
    subName: "단품",
    cost: 7700,
  },
  {
    code: "OF26_04_208",
    menuName: "닭갈비덮밥",
    subName: "단품",
    cost: 8400,
  },
];

function addDays(date: Date, days: number) {
  const clone = new Date(date);
  clone.setDate(date.getDate() + days);
  return clone;
}

function getDeliveryCount(option: IOoUnospaySalesMenu) {
  let retCount = 1;
  if (
    _.isEqual(option.externalCode, "OF04_01") ||
    _.isEqual(option.externalCode, "OF04_11") ||
    _.isEqual(option.externalCode, "OF04_02") ||
    _.isEqual(option.externalCode, "OF04_03") ||
    _.isEqual(option.externalCode, "OF04_04") ||
    _.isEqual(option.externalCode, "OF04_05") ||
    _.isEqual(option.externalCode, "OF04_10") ||
    _.isEqual(option.externalCode, "OF04_09")
  ) {
    switch (_.first(option.salesOption)?.externalCode) {
      case "301":
      case "302":
      case "303":
      case "304":
        retCount = 1;
        break;
      case "101":
        retCount = 2;
        break;
      case "102":
      case "04":
      case "02":
        retCount = 6;
        break;
      case "103":
      case "05":
      case "03":
        retCount = 12;
        break;
    }
  } else if (
    _.isEqual(option.externalCode, "OF04_06") ||
    _.isEqual(option.externalCode, "OF04_07") ||
    _.isEqual(option.externalCode, "OF04_08") ||
    _.isEqual(option.externalCode, "OF05_01")
  ) {
    retCount = 6;
  } else if (_.isEqual(option.externalCode, "OF05_02")) {
    retCount = 12;
  } else if (
    _.isEqual(option.externalCode, "OF05_03") ||
    _.isEqual(option.externalCode, "OF05_04") ||
    _.isEqual(option.externalCode, "OF05_07") ||
    _.isEqual(option.externalCode, "OF05_08") ||
    _.isEqual(option.externalCode, "OF05_09")
  ) {
    retCount = 18;
  } else if (_.isEqual(option.externalCode, "OF05_10")) {
    retCount = 2;
  } else if (
    _.isEqual(option.externalCode, "OF05_11") ||
    _.isEqual(option.externalCode, "OF05_12") ||
    _.isEqual(option.externalCode, "OF05_13")
  ) {
    retCount = 4;
  } else if (_.isEqual(option.externalCode, "OF05_06")) {
    retCount = 24;
  } else if (
    _.isEqual(option.externalCode, "OF05_14") ||
    _.isEqual(option.externalCode, "OF05_15")
  ) {
    retCount = 21;
  }
  return retCount;
}

function getOptionDeliveryCount(optionExternalCode: string) {
  let retCount = 1;
  if (_.isEqual(optionExternalCode, "OF04_20")) {
    retCount = 1;
  }
  return retCount;
}

function convTimestampDateFormat(timestamp: number) {
  var dateFormat = new Date(timestamp);
  return (
    dateFormat.getFullYear() +
    "-" +
    (dateFormat.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    dateFormat.getDate().toString().padStart(2, "0")
  );
}

function getNewCareInfo(optionCode: string | undefined) {
  if (
    _.isEqual(optionCode, "01") ||
    _.isEqual(optionCode, "02") ||
    _.isEqual(optionCode, "03") ||
    _.isEqual(optionCode, "04") ||
    _.isEqual(optionCode, "05")
  ) {
    return "0";
  } else {
    return "";
  }
}

function getMenuPrice(exCode: string) {
  let ret = "";
  switch (exCode) {
    case "OF04_01": // 당뇨
    case "OF04_11": // 저당
    case "OF04_02": // 암환자
      ret = "7,700";
      break;
    case "OF04_03": // 퇴원후 회복식 (장수마을)
    case "OF04_10": // 암환자 회복식
      ret = "7,300";
      break;
    case "OF04_05": // 샐러드 식단
      ret = "6,300";
      break;
    case "OF04_09":
      ret = "7,700";
      break;
    case "OF04_07":
      ret = "4,300";
      break;
    case "OF04_06":
      ret = "3,950";
      break;
    case "OF04_08":
      ret = "5,250";
      break;
  }
  return ret;
}

function exCodeNumToStr(num: number) {
  let retStr = "";
  switch (num) {
    case 1:
      retStr = "OF04";
      break;
    case 2:
      retStr = "OF10";
      break;
    case 3:
      retStr = "OF05";
      break;
    case 4:
      retStr = "OF07";
      break;
    case 5:
      retStr = "OF13";
      break;
    case 6:
      retStr = "OF18";
      break;
    case 7:
      retStr = "OF26";
      break;
  }
  return retStr;
}

export const nbOrder = async (
  list: Array<IOoUnospayDelivery>,
  select: OoProvider
) => {
  let data_array: Array<ExcelExportModel> = [];
  //공휴일 적용 안됨
  const today = new Date();
  let minDate: Date;
  const dayOfWeek = today.getDay(); // 0은 일요일, 1은 월요일, ... 6은 토요일
  switch (dayOfWeek) {
    case 1: // 월요일
      minDate = addDays(today, 2);
      break;
    case 2: // 화요일
      minDate = addDays(today, 2);
      break;
    case 3: // 수요일
      minDate = addDays(today, 2);
      break;
    case 4: // 목요일
      minDate = addDays(today, 2);
      break;
    case 5: // 금요일
      minDate = addDays(today, 3);
      break;
    case 6: // 토요일
      minDate = addDays(today, 4);
      break;
    case 0: // 일요일
      minDate = addDays(today, 3);
      break;
    default:
      minDate = addDays(today, 1);
  }
  const filterDatas = list.filter((item) =>
    item.transportInfo.some((i) => i.providerName === "나비푸드")
  );
  filterDatas.forEach((e, i) => {
    console.log(filterDatas);
    
    if (e.status == OoDeliveryStatus.existAddress || e.status == OoDeliveryStatus.sendPackage) {
      const model = new ExcelExportModel();
      model.excelStatus = e.status === OoDeliveryStatus.sendPackage ? 'sendPackage' : 'existAddress';
      e.salesMenu?.forEach((salesItem, i) => {
        if (e.transportInfo[i].providerName === "나비푸드") {
          if(salesItem.salesOption){
            model.hospitalName = e.hospitalName;
            model.product = salesItem.menuName;
            model.orderDate = convTimestampDateFormat(e.timestamp);
            model.name = e.recipientName;
            model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
            model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
            model.address1 = e.address;
            model.address2 = e.addressDetail;
            model.zipCode = e.zipCode;
            model.memo = e.memo;
            model.subMemo = e.subMemo;
            model.quantity = salesItem.menuCount;
            model.price = salesItem.menuPrice;
            if (salesItem.salesOption.length > 0) {
              salesItem.salesOption.map((optionItem, index) => {
                if(optionItem.externalCode){
                  model.exCode = `${salesItem.externalCode}_${optionItem.externalCode}`
                  model.deliveryDate = salesItem.selectMenu![index] ? salesItem.selectMenu![index].date : '';
                  data_array.push({ ...model });
                }
                });
              }else{
                model.exCode = `${salesItem.externalCode}`;
                data_array.push({ ...model });

            }
            
          }else{
            model.hospitalName = e.hospitalName;
            model.product = salesItem.menuName;
            model.exCode = salesItem.externalCode;
            model.orderDate = convTimestampDateFormat(e.timestamp);
            model.name = e.recipientName;
            model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
            model.tel2 = e.phoneNumber?.replace("\t", "").split('-').join('');
            model.address1 = e.address;
            model.address2 = e.addressDetail;
            model.zipCode = e.zipCode;
            model.memo = e.memo;
            model.subMemo = e.subMemo;
            model.price = salesItem.menuPrice;
            model.quantity = salesItem.menuCount;
            data_array.push({ ...model });
          }
        }
      })
    }
  });

  const excelFileExtension = ".xlsx";
  const excelFileName = `tlc헬스케어 발주서`;

  const arrayBufferMdsl = await (
    await fetch("/assets/nb.xlsx")
  ).arrayBuffer();
  const workbookNb = new ExcelJS.Workbook();
  await workbookNb.xlsx.load(arrayBufferMdsl);
  const ws_nb = workbookNb.getWorksheet("발송처리");

  const book = XLSX.utils.book_new();

  if (select == OoProvider.nbProvider) {
    moment.locale("ko");
    let custNum = 0;
    let previousOrderName = "";
    let custNumAddition = 0;
    const rowNumber = 2;
    data_array.map((data: ExcelExportModel, index: number) => {
      if (previousOrderName !== data.name) {
        custNum++;
        previousOrderName = data.name!;
      }
      const dateTimeTemp = `${moment(data.orderDate).format(
        "YYYYMMDD"
      )}${_.padStart(custNum.toString(), 4, "0")}`;
      data.custOrderNum = dateTimeTemp;
      const row = ws_nb.getRow(rowNumber + index);
      [
        "",
        data.custOrderNum,
        "",
        "",
        "",
        data.deliveryDate,
        data.hospitalName,
        data.name,
        "",
        data.name,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        nbNameCost.find(item => item.code === data.exCode)?.menuName,
        "",
        nbNameCost.find(item => item.code === data.exCode)?.subName,
        "",
        data.quantity,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}`,
        data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}`,
        data.address1 + " " + data.address2,
        "",
        data.zipCode,
        data.memo,
      ].forEach((value, index) => {
        const cell = row.getCell(index + 1); // 엑셀은 1부터 시작
        cell.value = value;
        cell.font = {
          color: {
            style:{
              argb: '000000'
            }
          }
        }
        
      });
      // const cell = row.getCell(3);
      // cell.value = 'value';
    });
    // XLSX.utils.book_append_sheet(book, ws_mdsl, "메디쏠라");
  }
  const excelBuffer = await workbookNb.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFile,
    excelFileName +
      "_" +
      moment().format("YYYYMMDD") +
      "(나비푸드)" +
      excelFileExtension
  );
};
