import { Modal } from "antd";
import styled from "styled-components";
import { useState, useEffect, ChangeEvent } from "react";
import { useHospitalListQuery } from "@queries/hospital/useHospitalListQuery";
import { useRecoilState } from "recoil";
import { hospitalListState } from "@recoils/display";

interface Props {
  selectValue: string;
  modalVisible: boolean;
  onChangedVisible: (status: boolean) => void;
  cancelButtonName: string;
  submitButtonName: string;
  onChangedTrigger: (status: boolean, code: string) => void,
}

export const PurchasePathModal = ({
  selectValue,
  modalVisible,
  cancelButtonName,
  submitButtonName,
  onChangedVisible,
  onChangedTrigger,
}: Props) => {
  const [selectedHospitalCode, setSelectedHospitalCode] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [list] = useRecoilState(hospitalListState);

  const handleHospitalSelect = (hospital: { code: string; name: string }) => {
    setSelectedHospitalCode(hospital.code);
    setSearchKeyword(hospital.name);
  };

  const handleSubmit = () => {
    if (selectedHospitalCode) {
      onChangedTrigger(true, selectedHospitalCode);
      onChangedVisible(false);
    }
  };

  return (
    <Modal
      title="병원 정보 수정"
      open={modalVisible}
      onCancel={() => onChangedVisible(false)}
      onOk={handleSubmit}
      okText={submitButtonName}
      cancelText={cancelButtonName}
    >
      <Content>
        <p>변경할 병원을 선택해주세요</p>
        <StyledInput 
          type="text"
          placeholder="병원명 검색" 
          value={searchKeyword}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchKeyword(e.target.value)}
        />
        <HospitalList>
          {list
            .filter((hospital) => 
              hospital.name.toLowerCase().includes(searchKeyword.toLowerCase())
            )
            .map((hospital) => (
              <HospitalItem
                key={hospital.id}
                selected={selectedHospitalCode === hospital.code}
                onClick={() => handleHospitalSelect(hospital)}
              >
                {hospital.name}
              </HospitalItem>
            ))}
        </HospitalList>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  padding: 20px 0;
`;

const HospitalList = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const HospitalItem = styled.div<{ selected: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => props.selected ? "#f4f6fb" : "white"};
  
  &:hover {
    background-color: #f4f6fb;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  
  &:focus {
    outline: none;
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;