import React from "react";
import styled from "styled-components";
import {IOoDiagnosis} from "api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons/faClose";

interface Props {
    onFileUpload: (e: any) => void,
    onClickFileHref: (e: any) => void,
    onFileRemove: (e: any) => void,
    data: IOoDiagnosis,
    pdfUrlArr: Array<string>,
    uploadFile: Array<File>,
}

export const AfterAttachFileTable = ({
                                         data,
                                         onClickFileHref,
                                         onFileRemove,
                                         uploadFile,
                                         pdfUrlArr,
                                     }: Props) => {
    return (
        <>
            <Table>
                <thead>
                <tr>
                    <th>
                        <div>파일첨부</div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <Wrapper>
                            {
                                pdfUrlArr.length > 0 ?
                                    <>
                                        {
                                            pdfUrlArr.map((item, index) => (
                                                <Container key={index}>
                                                    <div className="file-name-div">
                                                        <img src={`${process.env.PUBLIC_URL}/img/pdf-icon.png`} alt=""/>
                                                        <a onClick={onClickFileHref} id={`${index}`}>
                                                            {data.patientName}_처방전.pdf
                                                        </a>
                                                    </div>
                                                    <div className='button-container'>
                                                        <button value={index} onClick={onFileRemove}>
                                                            <FontAwesomeIcon icon={faClose}/>
                                                        </button>
                                                    </div>
                                                </Container>
                                            ))
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            uploadFile.map((item, index) => (
                                                <Container key={index}>
                                                    <div className="file-name-div">
                                                        <img src={`${process.env.PUBLIC_URL}/img/pdf-icon.png`} alt=""/>
                                                        <a onClick={onClickFileHref} id={`${index}`}>
                                                            {item.name}
                                                        </a>
                                                    </div>
                                                    <div className='button-container'>
                                                        <button value={index} onClick={onFileRemove}>
                                                            <FontAwesomeIcon icon={faClose}/>
                                                        </button>
                                                    </div>
                                                </Container>
                                            ))
                                        }
                                    </>
                            }
                        </Wrapper>
                    </td>
                </tr>
                </tbody>
            </Table>
        </>
    )
}

const HiddenAttachDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow: scroll;
`;

const LabelContainer = styled.label`
  display: flex;
  border: 1px solid #D3D9E4;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg:nth-child(1) {
    color: #1C6FC9;
    width: 24px;
    height: 16px;
    padding-right: 8px;
  }

  & > div:nth-child(2) {
    font-weight: 700;
    font-size: 13px;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding-left: 14px;

  .file-name-div {
    display: flex;
    width: 90%;
    height: auto;
    align-items: center;

    & > img {
      width: 24px;
      height: 24px;
      color: gray;
    }

    & > a {
      color: #567FE7;
      font-weight: 400;
      font-size: 13px;
      padding-left: 8px;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .button-container {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-end;

    & > button {
      background: none;
      border: none;

      & > svg {
        color: #C4C4C4;
        width: 16px;
        height: 16px;
      }
    }
  }
`;

const Table = styled.table`
  margin-top: 8px;
  border-top: 2px solid #C8D6F8;
  border-bottom: 2px solid #C8D6F8;
  width: 100%;
  height: auto;

  tr:nth-child(1) th {
    width: 100%;
    height: 38px;
    border-bottom: 1px solid #C8D6F8;
    background: #F4F6FB;
  }

  tr:nth-child(1) th div {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    margin-left: 14px;
    color: #030303;
  }
`;
