import {IOoDiagnosis} from "@api/models";
import _ from "lodash";


// 마더계정 찾기
export function isFamilyOwner(diagnosis: IOoDiagnosis): boolean {
    const predicate1 = !_.isEmpty(diagnosis.familyUserId);
    const predicate2 = ((diagnosis?.familyKey || 0) > 0) && _.isEqual(diagnosis.familyKey, diagnosis.timestamp);
    return predicate1 && predicate2;
}

// 마더계정과 연동된 가족 찾기
export function isMyFamilyMember(ownerDiagnosis: IOoDiagnosis, diagnosis: IOoDiagnosis): boolean {
    const predicate1 = !_.isEmpty(diagnosis.familyUserId) && _.isEqual(ownerDiagnosis.familyUserId, diagnosis.familyUserId);
    const predicate2 = ((diagnosis?.familyKey || 0) > 0) && _.isEqual(ownerDiagnosis.familyKey, diagnosis.familyKey) && !_.isEqual(diagnosis.familyKey, diagnosis.timestamp);
    return predicate1 && predicate2;
}

export function isFamilyDiagnosis(diagnosis: IOoDiagnosis): boolean {
    const predicate1 = !_.isEmpty(diagnosis.familyUserId);
    const predicate2 = (diagnosis?.familyKey || 0) > 0;
    return predicate1 && predicate2;
}

// 마더계정과 관련있는 가족만 찾기
function isFamilyMember(diagnosis: IOoDiagnosis): boolean {
    const predicate1 = !_.isEmpty(diagnosis.familyUserId);
    const predicate2 = ((diagnosis?.familyKey || 0) > 0) && !_.isEqual(diagnosis.familyKey, diagnosis.timestamp);
    return predicate1 && predicate2;
}

export function filterDisplayedDiagnosis(diagnosisList: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
    return _.filter(diagnosisList, d => !isFamilyMember(d));
}

export function findFilteredList(diagnosisList: Array<IOoDiagnosis>, predicate: OnSingleDiagnosisPredicate): Array<IOoDiagnosis> {
    return _.filter(diagnosisList, predicate);
}

// 마더계정과 연결되어 있는 가족순서 반대
export function filterFamilyDiagnosisReversed(diagnosisList: Array<IOoDiagnosis>,
                                              predicate: OnSingleDiagnosisPredicate): Array<IOoDiagnosis> {
    return _.chain(diagnosisList)
        .filter(predicate)
        .reverse()
        .value();
}

export function findFilteredFirstItem(diagnosisList: Array<IOoDiagnosis>, predicate: OnSingleDiagnosisPredicate): IOoDiagnosis | undefined {
    const result = _.filter(diagnosisList, predicate);
    if (_.isEmpty(result)) {
        return undefined
    }
    return _.first(result);
}

type OnSingleDiagnosisPredicate = (d: IOoDiagnosis) => boolean;

