import _ from "lodash";
import defaultHours from "../../../../assets/defaultHours.json";
import {useState} from "react";
import {PharmacyHours} from "../../../../interfaces";
import {IOoWorkHours} from "@api/models/pharmacy";

export const usePharmacyHoursHook = () => {
    const defaultTime = _.cloneDeep(defaultHours.pharmacyHours) as IOoWorkHours[];
    const defaultHoliday = defaultTime.splice(0, 1);
    const defaultLunchTime = defaultTime.splice(defaultTime.length - 1, defaultTime.length);
    const defaultResult = _.concat(defaultTime, defaultHoliday, defaultLunchTime);

    const [data, setData] = useState<PharmacyHours>({
        pharmacyHours: defaultResult,
    });

    const onDefaultTimeChanged = (item: IOoWorkHours) => {
        const index = _.findIndex(data.pharmacyHours, data => _.isEqual(data.dayOfWeek, item.dayOfWeek));
        data.pharmacyHours[index] = item;
        let sortedList = _.sortBy(data.pharmacyHours, "dayOfWeek");
        setData({...data, pharmacyHours: sortedList});
    };

    return {
        defaultResult,
        data,
        onDefaultTimeChanged,
    }

}