import {firebase, realtimeDatabase} from "../../firebaseConfig";
import _ from "lodash";
import {IOoUnospayDelivery} from "@models/unospay";

export class UnospayRtdbSubscriber {
    private static instance: UnospayRtdbSubscriber;
    private TAG = 'UnospayRtdbSubscriber';
    private ref?: firebase.database.Reference = undefined;
    private dataList: Array<IOoUnospayDelivery> = [];

    private constructor() {
    }

    static shared() {
        if (_.isEmpty(UnospayRtdbSubscriber.instance)) {
            UnospayRtdbSubscriber.instance = new UnospayRtdbSubscriber();
        }
        return UnospayRtdbSubscriber.instance;
    }

    getOriginList(): Array<IOoUnospayDelivery> {
        return this.dataList;
    }

    subscribe(path: string,
              onValue: OnUnospayDataListListener) {

        if (!_.isUndefined(this.ref)) {
            console.log(this.TAG, ':subscribe', ' =====>> already connected! ');
            return;
        }

        if (_.isEmpty(path)) {
            this.unsubscribe();
            console.log(this.TAG, ':subscribe', ' =====>> path is empty! ');
            return;
        }
        this.dataList = new Array<IOoUnospayDelivery>();
        this.ref = realtimeDatabase.ref(path);
        // william: 전체 값 한번 받은 후 values 이벤트 off, 개별 이벤트 수신
        this.ref.on('value', snapshot => {
            _.chain(snapshot.val())
                .values()
                .map((d) => d as IOoUnospayDelivery)
                .each(d => this.dataList.push(d))
                .value();
            onValue(this.dataList);
            this.ref?.off('value');
            this.subscribeIndividual(onValue);
        });
    }

    unsubscribe() {
        this.ref?.off();
        this.ref = undefined;
    }

    private getUnospayIndex(data: IOoUnospayDelivery) {
        return _.findIndex(this.dataList, (d) => _.isEqual(d.id, data.id));
    }

    private subscribeIndividual(onValue: OnUnospayDataListListener) {

        this.ref?.on('child_added', snapshot => {
            const data = snapshot.val() as IOoUnospayDelivery;
            const index = this.getUnospayIndex(data);
            if (index >= 0) {
                return;
            }

            this.dataList.push(data);
            onValue(this.dataList);
        });

        this.ref?.on('child_changed', snapshot => {
            const data = snapshot.val() as IOoUnospayDelivery;
            const index = this.getUnospayIndex(data);
            if (index < 0) {
                return;
            }

            this.dataList[index] = data;
            onValue(this.dataList);
        });

        this.ref?.on('child_removed', snapshot => {
            const data = snapshot.val() as IOoUnospayDelivery;
            const index = this.getUnospayIndex(data);
            if (index < 0) {
                return;
            }
            this.dataList.splice(index, 1);
            onValue(this.dataList);
        });
    }

}

export type OnUnospayDataListListener = (delivery: Array<IOoUnospayDelivery>) => void;