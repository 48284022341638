import styled from "styled-components";
import {useEffect} from "react";
import _ from "lodash";
import {CustomCheckBox} from "@components/custom";
import {OoParamPrescriptionCreate} from "@api/params";
import {useDiagnosisPaySettingHook} from "../../hooks/useDiagnosisPaySettingHook";
import {IOoDiagnosis, OoHospitalMedicineType} from "@api/models";


interface Props {
    data: IOoDiagnosis,
    param: OoParamPrescriptionCreate,
    onChange: (price: string, hospitalMedicinePrice: string, isHospitalDelivery: boolean, hospitalMedicineType: number) => void,
}

export const DiagnosisPay = ({onChange, param}: Props) => {
    const {
        pdfUrlArr,
        items,
        setItems,
        setNoPayChecked,
        noPayChecked,
        prescription,
        setPrescription,
        onChangePrice,
        onChangeHospitalMedicinePrice,
        onChangeNoPrescription,
        onChangeNoPayChecked,
        onChangeHospitalMedicine,
        onChangeBothMedicine,
        hospitalMedicinePriceShow,
    } = useDiagnosisPaySettingHook(param);

    useEffect(() => {
        onChange(items.price, items.hospitalMedicinePrice, items.isHospitalDelivery, items.hospitalMedicineType);
    }, [items.price, items.hospitalMedicinePrice, items.isHospitalDelivery, items.hospitalMedicineType]);

    useEffect(() => {
        (_.isEqual(items.price, "0") || _.isEqual(items.price, '')) ?
            setNoPayChecked(true) : setNoPayChecked(false);
    }, [items.price]);

    useEffect(() => {
        pdfUrlArr.list.length > 0 && setPrescription(false);
    }, [pdfUrlArr.list]);

    return (
        <Wrapper>
            <Title>진료비 및 약제비</Title>
            <Contents>
                <InputWrapper>
                    <div>
                        <SubTitle>진료비</SubTitle>
                        <label>
                            <input type="text"
                                   name="price"
                                   value={items.price}
                                   placeholder="0"
                                   onChange={onChangePrice}/>
                            <div>원</div>
                        </label>
                    </div>
                    <div hidden={hospitalMedicinePriceShow}>
                        <SubTitle>약제비</SubTitle>
                        <label>
                            <input type="text"
                                   name="hospitalMedicinePrice"
                                   placeholder="0"
                                   value={items.hospitalMedicinePrice}
                                   onChange={onChangeHospitalMedicinePrice}/>
                            <div>원</div>
                        </label>
                    </div>
                </InputWrapper>
            </Contents>
            <CheckBoxWrapper>
                <div>
                    <CustomCheckBox title="진료비 없음"
                                    checked={noPayChecked}
                                    onChange={onChangeNoPayChecked}/>
                </div>
                <div hidden={_.isEqual(items.hospitalMedicineType, OoHospitalMedicineType.both)}>
                    <CustomCheckBox title="처방전 없음"
                                    checked={prescription}
                                    onChange={onChangeNoPrescription}/>
                </div>
                <div hidden={_.isEqual(items.hospitalMedicineType, OoHospitalMedicineType.both)}>
                    <CustomCheckBox title="병원약 발송"
                                    checked={items.isHospitalDelivery}
                                    name="isHospitalDelivery"
                                    onChange={onChangeHospitalMedicine}/>
                </div>
                <div hidden={prescription}>
                    <CustomCheckBox title="병원약 + 약국약 발송"
                                    checked={items.isBothDelivery}
                                    name="isBothDelivery"
                                    onChange={onChangeBothMedicine}/>
                </div>
            </CheckBoxWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  margin-top: 48px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  width: 100%;
`;

const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  padding-top: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-top: 16px;

  & > div {
    padding-right: 15px;
    display: flex;
    flex-direction: column;
  }

  & > div > label {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    padding-top: 4px;

    & > input {
      width: 212px;
      height: 44px;
      border: 2px solid #D3D9E4;
      border-radius: 8px;
      text-align: end;
      padding: 10px;
    }

    & > div {
      padding-left: 12px;
    }
  }
`;

const SubTitle = styled.div`
  font-size: 13px;
  font-weight: 700;
`;

const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 13px;

  & > div {
    padding-right: 15px;
  }

  & > div > label {
    display: flex;
    align-items: end;

    & > input {
      width: 18px;
      height: 18px;
    }

  }
`;