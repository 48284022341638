import { IOoUnospayDelivery, IOoUnospaySalesMenu, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
const ExcelJS = require("exceljs");

const createModel = (e:IOoUnospayDelivery, salesItem:IOoUnospaySalesMenu) => {
  const model = new ExcelExportModel();
                model.name = e.recipientName;
                model.address1 = e.address;
                model.address2 = e.addressDetail;
                model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                model.zipCode = e.zipCode;
                model.memo = e.memo;
                model.subMemo = e.subMemo;
                model.quantity = "1";
                model.deliveryCost = e.id;
  return model;
}

const findProductName = (salesItem: IOoUnospaySalesMenu) => {
  if(_.isUndefined(salesItem.salesOption)){
      return salesItem.menuName;
  }else{
      const productOption = salesItem.salesOption.map(item => item.optionName);
      return `${salesItem.menuName} / ${productOption.join(' / ')}`
  }
}

const overMaxOrder = async (list:IOoUnospayDelivery[]) => {
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.overMaxProvider));
    let data_array: Array<ExcelExportModel> = [];

    excelList.forEach((e, index) => {
      if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
        e.salesMenu?.forEach(salesItem => {
            if(_.startsWith(salesItem.externalCode, 'OF44')){
              let model = createModel(e, salesItem);
                model.product = findProductName(salesItem);
                data_array.push(model);
            }
        })
      }
    });
    console.log(data_array);
    
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferOverMax = await (await fetch("/assets/overmax.xlsx")).arrayBuffer();
    const workbookOverMax = new ExcelJS.Workbook();
    await workbookOverMax.xlsx.load(arrayBufferOverMax);
    const ws_OverMax = workbookOverMax.getWorksheet("택배출고요청 발주서양식");
    data_array.map((data: ExcelExportModel, index: number) => {
      const row = ws_OverMax.getRow(index + 2);
      [
          data.name,
          data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
          "",
          "",
          data.address1 + " " + data.address2,
          "",
          "",
          data.product,
          data.quantity,
          data.memo,
          "",
          "",
          ""
      ].forEach((value , index) => {
          const cell = row.getCell(index + 1);
          cell.value = value;
          cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          };
      })
  });
  const excelBuffer = await workbookOverMax.xlsx.writeBuffer();
  const excelFile = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  FileSaver.saveAs(
    excelFile,
    excelFileName+"_"+moment().format("YYYYMMDD")+ "(오버맥스)"+ excelFileExtension
  );
}

export default overMaxOrder