import TextAreaStyles from '../../../styles/common/TextArea.module.scss';
import classNames from "classnames/bind";

interface Props {
    size: string,
    color: string,
    value: string,
    name?: string,
    disabled: boolean,
    placeholder?: string,
    onChanged?: (e: any) => void,
}

export const CustomTextArea = ({
                                   size,
                                   color,
                                   value,
                                   name,
                                   disabled,
                                   placeholder,
                                   onChanged,
                               }: Props) => {
    const tx = classNames.bind(TextAreaStyles);
    return (
        <textarea className={tx('TextArea', color, size)}
                  placeholder={placeholder}
                  disabled={!disabled}
                  value={value}
                  name={name}
                  onChange={onChanged}
        />
    )
}
