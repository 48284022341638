import {useMutation} from "react-query";
import {DoctorOnAPI, isRequestSucceed} from "../../api";
import {OoParamDoctorCreate} from "@api/params";
import {useSuspenseHook} from "../../hooks";


export const useDoctorCreateQuery = () => {
    const {setLoading, setStopLoading} = useSuspenseHook();
    return useMutation(
        'operator/doctorCreate',
        async (param: OoParamDoctorCreate) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.doctor.create(param);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                throw e;
            }
        },
    )
};
