import React, {useState} from "react";
import classNames from "classnames/bind";
import buttonStyles from "../../../../../../styles/common/Button.module.scss";
import styled from "styled-components";
import {ReservationStatusChangeModal} from "../../../../Modal/ReservationStatusChangeModal";
import _ from "lodash";
import {IOoDiagnosis, OoDiagnosisStatus, OoWapleReservationStatus, useFilteredReservationStatus} from "@api/models";
import {ReservationUpdateModal} from "../../../../Modal";
import {formatHHMM} from "@hooks/format";
import {Link} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {doctorCalendarParamState, doctorSearchParamState} from "@recoils/doctor";
import {useModalHook} from "@hooks/modal";
import {OoParamDiagnosisStatus} from "@api/params";
import {useDoctorFunctionHook} from "@hooks/doctor";

interface Props {
    target: IOoDiagnosis,
    list: Array<IOoDiagnosis>,
    familyList: Array<IOoDiagnosis>,
    flex?: number,
}

export const ReservationType = ({target, list, familyList, flex}: Props) => {
    const bx = classNames.bind(buttonStyles);

    const {addModal} = useModalHook();
    const {updateStatus} = useDoctorFunctionHook();

    const calendarParam = useRecoilValue(doctorCalendarParamState);
    const searchParam = useRecoilValue(doctorSearchParamState);

    const {
        isReservation,
        isReservationComplete,
        isVisitComplete,
        isCloseReservation,
    } = useFilteredReservationStatus(target);

    const [targetId, setTargetId] = useState<string>('');
    const [reservationModalVisible, setReservationModalVisible] = useState<boolean>(false);
    const [notReservationModalVisible, setNotReservationModalVisible] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    const onClickReservation = (e: any) => {
        e.preventDefault();
        setTargetId(e.target.value);
        ((target.wapleReservationStatus ?? OoWapleReservationStatus.unknown) <= 201)
            ? setReservationModalVisible(true)
            : ((target.wapleReservationStatus ?? OoWapleReservationStatus.unknown) < 211
                || (target.wapleReservationStatus ?? OoWapleReservationStatus.unknown) > 223) &&
            setNotReservationModalVisible(true);
    };

    const onClickUpdateStatus = () => {
        if (_.gte(target.status, OoDiagnosisStatus.accept)) return;
        if (_.isUndefined(target) || _.isUndefined(target.familyDiagnosisCount)) return;

        let arr;
        if (target.familyDiagnosisCount > 1) {
            const familyArr = _.concat(target, familyList);
            arr = familyArr.map((item) => (
                new OoParamDiagnosisStatus(item.id || '', OoDiagnosisStatus.accept, '')
            ));
        } else {
            arr = _.concat(new OoParamDiagnosisStatus(target.id || '', OoDiagnosisStatus.accept, ''));
        }

        updateStatus(arr);
    };

    return (
        <Wrapper flex={flex ?? 0}>
            <ReservationUpdateModal visible={reservationModalVisible}
                                    unVisible={(e) => setReservationModalVisible(e)}
                                    list={list}
                                    id={targetId}/>
            {/*<DiagnosisConfirmModal visible={false}*/}
            {/*                       unVisible={(e) => setNotReservationModalVisible(e)}*/}
            {/*                       id={targetId}*/}
            {/*                       list={list}/>*/}
            <ReservationStatusChangeModal visible={show}/>
            {
                ((isReservation() && isReservationComplete()) || (isReservation() && isVisitComplete())) || isCloseReservation() ?
                    <>
                        {
                            _.lte(target.status, OoDiagnosisStatus.accept) ?
                                <button type="button"
                                        className={bx('reservation-button')}
                                        value={`${target.id}`}>
                                    {`예약 ${formatHHMM(target.wapleReservationTimestamp ?? 0)}`}
                                </button>
                                :
                                <button type="button"
                                        onClick={() =>
                                            addModal({
                                                type: 'twoBtnModal',
                                                data: {
                                                    title: "진료 상태 변경",
                                                    contents:
                                                        <div>
                                                            {
                                                                (target.familyDiagnosisCount ?? 0) > 1 ?
                                                                    <div>
                                                                        {target.patientName}님 외
                                                                        {(target.familyDiagnosisCount ?? 0) - 1}명의 진료
                                                                        상태를 변경합니다.
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        {target.patientName}님의 진료 상태를 변경합니다.
                                                                    </div>
                                                            }
                                                        </div>,
                                                    buttons: [
                                                        {title: "닫기"},
                                                        {
                                                            title: "접수 완료",
                                                            callback: () => onClickUpdateStatus()
                                                        }],
                                                    goBack: false,
                                                }
                                            })}
                                        className={bx('reservation-button')}
                                        value={`${target.id}`}>
                                    {`예약 ${formatHHMM(target.wapleReservationTimestamp ?? 0)}`}
                                </button>
                        }
                    </>
                    :
                    <>
                        {
                            isReservation() ?
                                <>
                                    {
                                        _.gte(target.status, OoDiagnosisStatus.accept) ?
                                            <Link to={`/prescription`}
                                                  state={{
                                                      diagnosisList: _.concat(target, familyList),
                                                      calendarParam: calendarParam,
                                                      searchParam: searchParam
                                                  }}>
                                                <button type="button"
                                                        className={bx('reservation-button')}
                                                        value={`${target.id}`}>
                                                    예약 접수
                                                </button>
                                            </Link>
                                            :
                                            <button type="button"
                                                    onClick={onClickReservation}
                                                    className={bx('reservation-button')}
                                                    value={`${target.id}`}>
                                                예약 접수
                                            </button>
                                    }
                                </>
                                :
                                <>
                                    {
                                        _.gte(target.status, OoDiagnosisStatus.accept) ?
                                            <Link to={`/prescription`}
                                                  state={{
                                                      diagnosisList: _.concat(target, familyList),
                                                      calendarParam: calendarParam,
                                                      searchParam: searchParam
                                                  }}>
                                                <button type="button"
                                                        className={bx('not-reservation-button')}
                                                        value={`${target.id}`}>
                                                    바로 접수
                                                </button>
                                            </Link>
                                            :
                                            <button type="button"
                                                    onClick={() =>
                                                        addModal({
                                                            type: 'twoBtnModal',
                                                            data: {
                                                                title: "진료 상태 변경",
                                                                contents:
                                                                    <div>
                                                                        {
                                                                            (target.familyDiagnosisCount ?? 0) > 1 ?
                                                                                <div>
                                                                                    {target.patientName}님
                                                                                    외 {(target.familyDiagnosisCount ?? 0) - 1}명의
                                                                                    진료
                                                                                    상태를 변경합니다.
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {target.patientName}님의 진료 상태를 변경합니다.
                                                                                </div>
                                                                        }
                                                                    </div>,
                                                                buttons: [
                                                                    {title: "닫기",},
                                                                    {
                                                                        title: "접수 완료",
                                                                        callback: () => onClickUpdateStatus()
                                                                    }],
                                                                goBack: false,
                                                            }
                                                        })}
                                                    className={bx('not-reservation-button')}
                                                    value={`${target.id}`}>
                                                바로 접수
                                            </button>
                                    }
                                </>
                        }
                    </>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex}
`;
