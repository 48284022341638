import {useState} from "react";
import {DoctorServiceInfo} from "../../../../interfaces/Operator/OperatorDoctorCreateInterface";

export const useServiceSettingHook = () => {
    const [service, setService] = useState<DoctorServiceInfo>({
        departments: [],
        theme: [],
    });

    const onChangedDepartmentInfo = (data: DoctorServiceInfo) => {
        setService({...service, departments: data.departments});
    };

    const onChangedThemeInfo = (data: DoctorServiceInfo) => {
        setService({...service, theme: data.theme});
    };

    return {
        service,
        onChangedDepartmentInfo,
        onChangedThemeInfo,
    }
}