import {
    BlueButton,
    ButtonContainer,
    Card,
    ContentsContainer,
    TitleContainer,
    Wrapper
} from "@components/customModal/style";
import React from "react";
import {useModalHook} from "@hooks/modal";

export const ErrorModal = ({data: {title = '', contents = '', buttonTitle = ["확인"]}}) => {
    const {removeCurrentModal} = useModalHook();
    return (
        <Wrapper>
            <Card>
                {
                    title.length > 0 &&
                    <TitleContainer>
                        {title}
                    </TitleContainer>
                }
                <ContentsContainer>
                    {contents}
                </ContentsContainer>
                <ButtonContainer align="center">
                    <BlueButton type="button" onClick={() => removeCurrentModal()}>{buttonTitle[0]}</BlueButton>
                </ButtonContainer>
            </Card>
        </Wrapper>
    )
}