import {useState} from "react";
import {PharmacyInfo} from "../../../../interfaces";

export const usePrimaryInfoHook = () => {
    const [items, setItems] = useState<PharmacyInfo>({
        email: "",
        password: "",
        name: "",
        pictures: [],
    });

    const onChangeEmail = (e: any) => setItems({...items, email: e.target.value});
    const onChangePassword = (e: any) => setItems({...items, password: e.target.value});
    const onChangeName = (e: any) => setItems({...items, name: e.target.value});
    const onChangePicture = (target: string) => setItems({...items, pictures: [target]});

    return {
        items,
        onChangeEmail,
        onChangePassword,
        onChangeName,
        onChangePicture,
    };
};
