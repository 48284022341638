import {useRecoilValue} from "recoil";
import {selectedUserState} from "@recoils/oauth";
import React from "react";
import {userLevel} from "@api/models";
import {DoctorNavbar} from "./DoctorNavbar";
import {OperatorNavbar} from "./OperatorNavbar";
import {PharmacyNavbar} from "./PharmacyNavbar";
import {LoginNavbar} from "./LoginNavbar";
import { ManagerNavbar } from "./ManagerNavbar";
import { CalculaterNavbar } from "./CalculaterNavbar";

export const Navbar = () => {
    const selectedUser = useRecoilValue(selectedUserState);
    const filteredLevel = (level: number) => {
        let resetLevel = <LoginNavbar/>;
        
        switch (level) {
            case userLevel.operator:
                resetLevel = <OperatorNavbar/>;
                break;
            case userLevel.manager:
                resetLevel = <ManagerNavbar/>;
                break;
            case userLevel.o2oPharmacy:
                resetLevel = <PharmacyNavbar/>;
                break;
            case userLevel.pharmacist:
                resetLevel = <PharmacyNavbar/>;
                break;
            case userLevel.o2oDoctor:
                resetLevel = <DoctorNavbar/>;
                break;
            case userLevel.o2oCalculater:
                resetLevel = <CalculaterNavbar />
                break;
        }
        return resetLevel;
    }

    return filteredLevel(selectedUser?.user.level ?? 0);
}

